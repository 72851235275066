import React from "react";
import { ConfirmPage } from "../components/confirm";
import { EmailVerificationPage } from "../components/email-verification";
import { Redirect, Route, Switch } from "react-router-dom";
import { OidcCallback } from "../components/oidc/oidc-callback";
import { OidcSilentRenew } from "../components/oidc/oidc-silent-renew";
import { OidcLogout } from "../components/oidc/oidc-logout";
import { SubSignup } from "modules/account/components/sub-signup";

export const Views = () => {
  return (
    <Switch>
      <Route path="/account/verify/email" component={EmailVerificationPage} />
      <Route
        path={["/account/verify/:id/:mode", "/account/verify/:id"]}
        component={ConfirmPage}
      />
      <Route path="/account/landing" component={SubSignup} />
      <Route
        path="/account"
        render={() => <Redirect to="/account/landing" />}
      />
    </Switch>
  );
};

export const OidcRoutes = () => {
  return (
    <Switch>
      <Route exact path="/oidc/signin-callback" component={OidcCallback} />
      <Route exact path="/oidc/silent-renew" component={OidcSilentRenew} />
      <Route exact path="/oidc/logout" component={OidcLogout} />
    </Switch>
  );
};
