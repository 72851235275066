import React, { useContext } from "react";
import { DropdownButton, Icon, MenuItem } from "hcss-components";
import { SchemaFieldFilter } from "modules/schemas/components/filters/SchemaFieldFilter";
import { FilterSelect } from "modules/views/components/filters/filter-select";
import { defaultFilterOperation } from "modules/schemas/components/filters/common";
import { SchemaViewFilter } from "api";
import { strings } from "localization";
import { useSelectedFilters } from "modules/views/components/filters/use-selected-filters";
import { FilterPillNoView } from "./filter-pill-noview";
import {
  ExpandFilters,
  FiltersWrapper,
  FilterControl,
  FiltersContainer,
  FilterClearOption,
  FilterAddEditOption,
  dropdownButtonStylings
} from "./filters-styles";

interface FiltersProps {
  schemaId: string;
  selectedFields: string[];
  permanentFilters?: Record<string, SchemaViewFilter>;
  pageId: string;
}

export const FiltersNoView = ({
  schemaId,
  selectedFields,
  permanentFilters,
  pageId
}: FiltersProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const filters = useContext(FiltersNoViewContext);
  const setFilters = useContext(SetFiltersNoViewContext);
  const {
    activeFilters,
    displayedFilters,
    fields,
    showFilterSelect,
    fieldsBySection,
    setDisplayedFilters,
    setShowFilterSelect
  } = useSelectedFilters(selectedFields, schemaId, filters, pageId);

  let filteredDisplayedFilters = displayedFilters;
  let filteredActiveFilters = activeFilters;
  if (permanentFilters) {
    filteredDisplayedFilters = displayedFilters.filter(
      filter => !permanentFilters[filter]
    );
    filteredActiveFilters = activeFilters.filter(
      filter => !permanentFilters[filter]
    );
  }
  const isFilterActive = filteredActiveFilters.length > 0;
  const isDisplayedFilters = filteredDisplayedFilters.length > 0;
  const currentFilterSelect: Record<string, boolean> = {};
  displayedFilters.forEach(filter => {
    currentFilterSelect[filter] = true;
  });

  const onExpandFiltersClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setIsExpanded(isExpanded => !isExpanded);
  };

  const handleFilterReset = (filter: SchemaViewFilter) => {
    const newFilterLookup = { ...filters };
    delete newFilterLookup[filter.columnName];
    setFilters(newFilterLookup);
  };

  return (
    <FiltersWrapper isFilterActive={isFilterActive}>
      <div>
        <DropdownButton
          id="filter-dropdown-button"
          className="styled-filter-dropdown-button"
          style={dropdownButtonStylings}
          title={
            <FilterControl>
              <Icon
                name="pencil"
                margin="right"
                style={{ marginBottom: "5px" }}
              />
              <span>{strings.core.filters.title}</span>
            </FilterControl>
          }
        >
          <MenuItem
            onClick={e => {
              setShowFilterSelect(true);
            }}
          >
            <FilterAddEditOption data-testid="add-edit-filter-option">
              {isDisplayedFilters ? (
                <span>
                  <Icon name="pencil" margin="right" />
                  {strings.core.filters.adjustFiltersText}
                </span>
              ) : (
                <span>
                  <Icon name="fa-solid fa-plus" margin="right" />
                  {strings.core.filters.addFiltersText}
                </span>
              )}
            </FilterAddEditOption>
          </MenuItem>
          {!isFilterActive && isDisplayedFilters && (
            <MenuItem
              onClick={e => {
                setDisplayedFilters([]);
              }}
            >
              <FilterClearOption>
                <span>
                  <Icon name="times" margin="right" />
                  {strings
                    .formatString(
                      strings.core.filters.removeDisplayedFilters,
                      filteredDisplayedFilters.length
                    )
                    .toString()}
                </span>
              </FilterClearOption>
            </MenuItem>
          )}
          {isFilterActive && (
            <MenuItem
              onClick={() => {
                setFilters(permanentFilters ? permanentFilters : {});
              }}
            >
              <FilterClearOption>
                <span>
                  <Icon name="times" margin="right" />
                  {strings
                    .formatString(
                      strings.core.filters.clearActiveFilters,
                      filteredActiveFilters.length
                    )
                    .toString()}
                </span>
              </FilterClearOption>
            </MenuItem>
          )}
        </DropdownButton>
      </div>
      <FiltersContainer>
        {filteredDisplayedFilters
          .sort((a, b) => {
            // sort filters by active status
            const filterA = filters[a];
            const filterB = filters[b];
            if (filterA && filterB) return 0;
            if (filterA && filterB === undefined) return -1;
            if (filterA === undefined && filterB) return 1;
            return 0;
          })
          .map(filterId => {
            const field = fields[filterId];
            const filter = filters[filterId];
            if (!field) {
              return null;
            }
            return isExpanded ? (
              <SchemaFieldFilter
                key={field.id}
                filter={{
                  //@ts-ignore
                  // the spread operator is intentionally after setting these two properties
                  // due to how the defaults are being applied. The spread will only occasionally
                  // overwrite these properties
                  columnName: field.id,
                  filterOperation: defaultFilterOperation[field.type],
                  ...filter
                }}
                field={field}
                onChange={newFilter =>
                  setFilters({
                    ...filters,
                    [newFilter.columnName]: newFilter
                  })
                }
              />
            ) : (
              <FilterPillNoView
                key={field.id}
                filterId={filterId}
                displayFilters={filteredDisplayedFilters}
                setDisplayedFilters={setDisplayedFilters}
                field={field}
                filter={{
                  //@ts-ignore
                  // the spread operator is intentionally after setting these two properties
                  // due to how the defaults are being applied. The spread will only occasionally
                  // overwrite these properties
                  columnName: field.id,
                  filterOperation: defaultFilterOperation[field.type],
                  ...filter
                }}
                onChange={newFilter =>
                  setFilters({
                    ...filters,
                    [newFilter.columnName]: newFilter
                  })
                }
                onReset={handleFilterReset}
              />
            );
          })}
        {isDisplayedFilters && (
          <ExpandFilters>
            <button onClick={onExpandFiltersClick}>
              {isExpanded
                ? strings.core.filters.collapseFiltersText
                : strings.core.filters.expandFiltersText}
            </button>
          </ExpandFilters>
        )}
      </FiltersContainer>

      <FilterSelect
        show={showFilterSelect}
        onHide={() => setShowFilterSelect(false)}
        selection={currentFilterSelect}
        activeFilters={activeFilters}
        onChange={newFilters => {
          setDisplayedFilters(
            Object.keys(newFilters).filter(key => newFilters[key])
          );
        }}
        fieldsBySection={fieldsBySection}
      />
    </FiltersWrapper>
  );
};

export const FiltersNoViewContext = React.createContext<
  Record<string, SchemaViewFilter>
>({});
export const SetFiltersNoViewContext = React.createContext(
  (value: Record<string, SchemaViewFilter>) => null
);

export const FiltersNoViewProvider = (
  //@ts-ignores
  { filters, setFilters, children }
) => {
  return (
    <FiltersNoViewContext.Provider value={filters}>
      <SetFiltersNoViewContext.Provider value={setFilters}>
        {children}
      </SetFiltersNoViewContext.Provider>
    </FiltersNoViewContext.Provider>
  );
};
