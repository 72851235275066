import { useState, useEffect, useCallback } from "react";
import { ExpandableContentStatus } from "../../models/enums";

export interface UseSideNavigationOptions {
  onExpanded?: () => void;
  onExpanding?: () => void;
  onCollapsed?: () => void;
  onCollapsing?: () => void;
}

export function useSideNavigation(
  startExpanded: boolean,
  options: UseSideNavigationOptions = {}
) {
  const [panelStatus, setPanelStatus] = useState(
    startExpanded
      ? ExpandableContentStatus.expanded
      : ExpandableContentStatus.collapsed
  );

  useEffect(() => {
    if (panelStatus === ExpandableContentStatus.collapsing) {
      setTimeout(() => {
        setPanelStatus(ExpandableContentStatus.collapsed);
        if (options.onCollapsed) {
          options.onCollapsed();
        }
      }, 425);
    } else if (panelStatus === ExpandableContentStatus.expanding) {
      setTimeout(() => {
        setPanelStatus(ExpandableContentStatus.expanded);
        if (options.onExpanded) {
          options.onExpanded();
        }
      }, 425);
    }
  }, [panelStatus, options]);

  const toggle = useCallback(() => {
    let status: ExpandableContentStatus;

    if (panelStatus === ExpandableContentStatus.expanded) {
      status = ExpandableContentStatus.collapsing;
      if (options.onCollapsing) {
        options.onCollapsing();
      }
    } else {
      status = ExpandableContentStatus.expanding;
      if (options.onExpanding) {
        options.onExpanding();
      }
    }

    setPanelStatus(status);
  }, [panelStatus, options]);

  return { status: panelStatus, toggle };
}
