import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { strings } from "localization";
import { HeaderContainer, ModuleTitle, ItemContainer } from "./styles";

import {
  HeaderSection,
  MenuSection,
  Item
} from "core/components/hcss-navigation/";
import { Badge } from "core";
import { selectors, actions } from "modules/contacts";
import { useSelector, useDispatch } from "react-redux";
import { SearchContactsModal } from "modules/contacts/components/search-contacts-modal";
import { useAuthorization, usePermissions } from "modules/account";

export const ContactsModule = () => {
  const path = useLocation().pathname;
  const history = useHistory();
  const showSearchModal = useSelector(selectors.getShowSearchModal);
  const dispatch = useDispatch();
  const permissions = usePermissions();
  const authorization = useAuthorization();

  return (
    <div className="estimating-navigation-module">
      <HeaderSection>
        {headerStyle => {
          return (
            <div style={headerStyle}>
              <HeaderContainer>
                <ModuleTitle>{strings.common.modules.contacts}</ModuleTitle>
              </HeaderContainer>
            </div>
          );
        }}
      </HeaderSection>
      <MenuSection>
        {menuStyle => (
          <ItemContainer style={menuStyle}>
            <Item
              key="vendor-list"
              before={<FontAwesomeIcon icon={["far", "stream"]} size="1x" />}
              text={strings.layout.menu.contacts.list}
              isSelected={path === "/contacts"}
              onClick={() => history.push("/contacts")}
              data-testid="navitem-company-list"
            />
            <SearchContactsModal />
            {authorization.canAccessLimitedPrecon &&
              permissions.contactWrite && (
                <Item
                  key="import"
                  before={
                    <FontAwesomeIcon icon={["far", "file-import"]} size="1x" />
                  }
                  text={strings.layout.menu.contacts.import}
                  isSelected={path === "/contacts/import"}
                  onClick={() => history.push("/contacts/import")}
                  data-testid="navitem-contacts-import"
                />
              )}
            {authorization.canAccessLimitedPrecon &&
              permissions.contactWrite && (
                <Item
                  key="company-setup"
                  before={
                    <FontAwesomeIcon icon={["far", "sliders-v"]} size="1x" />
                  }
                  text={strings.layout.menu.contacts.setup}
                  isSelected={path === "/contacts/setup"}
                  onClick={() => history.push("/contacts/setup")}
                  data-testid="navitem-contacts-setup"
                />
              )}
          </ItemContainer>
        )}
      </MenuSection>
    </div>
  );
};
