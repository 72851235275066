import * as React from "react";
import * as ReactDOM from "react-dom";
import { debounce } from "lodash";
import styled from "styled-components";
import { PaneledPageExtendedProps } from "./ExtendedProps";

export interface ContentMainProps extends PaneledPageExtendedProps {
  mainContainerRef: React.RefObject<any>;
  onScroll?: (event: any, updatedScrollPosition: number) => void;
  onMount?: () => void;
  id?: string;
  testId?: string;
  innerWrapperId?: string;
  innerWrapperTestId?: string;
}
export default class ContentMain extends React.PureComponent<ContentMainProps> {
  componentDidMount() {
    if (this.props.mainContainerRef && this.props.mainContainerRef.current) {
      const node: Element | Text | null = ReactDOM.findDOMNode(
        this.props.mainContainerRef.current
      );
      if (node)
        (node as Element).addEventListener("scroll", this.handleScroll, true);
    }
    if (this.props.onMount) this.props.onMount();
  }

  componentWillUnmount() {
    if (this.props.mainContainerRef && this.props.mainContainerRef.current) {
      const node: Element | Text | null = ReactDOM.findDOMNode(
        this.props.mainContainerRef.current
      );
      if (node)
        (node as Element).removeEventListener(
          "scroll",
          this.handleScroll,
          true
        );
    }
  }

  handleScroll = debounce(event => {
    if (this.props.onScroll) this.props.onScroll(event, event.target.scrollTop);
  }, 200);

  render() {
    return (
      <StyledContentMain
        className="page-content-main"
        ref={this.props.mainContainerRef}
        id={this.props.id || "paneledpage-content-main"}
        data-testid={this.props.testId || "paneledpage-content-main"}
        {...this.props.passThroughProps}
      >
        <div
          className="content-inner-wrapper"
          id={
            this.props.innerWrapperId ||
            "paneledpage-content-main-inner-wrapper-id"
          }
          data-testid={
            this.props.innerWrapperTestId ||
            "paneledpage-content-main-inner-wrapper"
          }
        >
          {this.props.children}
        </div>
      </StyledContentMain>
    );
  }
}

export const StyledContentMain = styled("main")`
  @media print {
    height: auto;
    overflow: visible !important;

    .content-inner-wrapper {
      height: auto;
      overflow: visible !important;
    }
  }

  display: inline-block;
  position: relative;
  flex: 1 0;
  z-index: 1;

  .content-inner-wrapper {
    position: relative;
    padding: 12px 16px;
    min-height: 100%;

    & > h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 6px 0;
      font-weight: 700;
    }

    & > h1 {
      font-size: 1.6em;
    }
    & > h2 {
      font-size: 1.4em;
    }
    & > h3 {
      font-size: 1.2em;
    }
  }
`;
