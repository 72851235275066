import { View, FilterOperation } from "api";
import { filterConstants } from "modules/schemas/components/filters/common";
import { filterListValues } from "modules/schemas/standard-schemas/quote-dashboard-schema";

export const quoteDashboard: View = {
  schemaId: "quoteDashboard",
  filters: {
    // TODO: re-enable this later, we can add any project filters this same way
    // name: {
    //   columnName: "name",
    //   filterOperation: FilterOperation.Contains,
    //   config: {
    //     placeholder: "Type..."
    //   }
    // },
    lastModified: {
      columnName: "lastModified",
      filterOperation: FilterOperation.Between,
      config: {
        allowedOptions: [
          filterConstants.dates.nowM7,
          filterConstants.dates.nowM30,
          filterConstants.dates.nowM365
        ],
        placeholder: "Select..."
      }
    },
    status: {
      columnName: "status",
      filterOperation: FilterOperation.Any,
      config: {
        listValues: filterListValues
      }
    },
    quoteFolders: {
      columnName: "quoteFolders",
      filterOperation: FilterOperation.Any
    }
  },
  grouping: [],
  hiddenColumns: [],
  columnSummaries: [],
  id: "quoteDashboard",
  name: "Quote Dashboard",
  ordering: [],
  sorting: [],
  chartConfigs: [],
  chartConfig: {},
  calendarConfig: {}
};
