import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where
} from "firebase/firestore";
import axios from "axios";
import config from "../../../config";
import { firebaseApp } from "../../../firebase-app";
import { isUndefined, omitBy, omit } from "lodash-es";
import { MasterSolicitation, MasterSolicitationEntity } from "core";

const db = getFirestore(firebaseApp);
const collectionId = "masterSolicitations";

export const MasterSolicitationRepo = {
  generateId: () => {
    return doc(collection(db, collectionId)).id;
  },

  getById: async (id: string) => {
    const ref = doc(collection(db, collectionId), id);

    try {
      const snap = await getDoc(ref);
      const data = snap.data() as MasterSolicitation;
      const entity: MasterSolicitationEntity = { ...data, id: ref.id };
      return entity;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  },

  getAll: async (
    companyId: string,
    estimateId?: string,
    projectId?: string
  ) => {
    const solicitations: MasterSolicitationEntity[] = [];

    const whereClauses = [];
    whereClauses.push(where("companyId", "==", companyId));

    if (estimateId) {
      whereClauses.push(where("estimateId", "==", estimateId));
    }

    if (projectId) {
      whereClauses.push(where("projectId", "==", projectId));
    }

    try {
      const q = query(collection(db, collectionId), ...whereClauses);
      const snap = await getDocs(q);
      for (const doc of snap.docs) {
        const data = doc.data() as MasterSolicitation;
        solicitations.push({ ...data, id: doc.id });
      }
    } catch (error) {
      console.error(error);
    }

    return solicitations;
  },

  subscribe: (companyId: string, estimateId?: string, projectId?: string) => {
    const whereClauses = [];
    whereClauses.push(where("companyId", "==", companyId));

    if (estimateId) {
      whereClauses.push(where("estimateId", "==", estimateId));
    }

    if (projectId) {
      whereClauses.push(where("projectId", "==", projectId));
    }

    const q = query(collection(db, collectionId), ...whereClauses);
    return q;
  },

  save: async (solicitation: MasterSolicitationEntity) => {
    const id = solicitation.id;
    const ref = doc(collection(db, collectionId), id);

    try {
      let doc = omitBy(solicitation, isUndefined);
      doc = omit(doc, ["id"]);
      await setDoc(ref, doc);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  delete: async (solicitation: MasterSolicitationEntity, token: string) => {
    const deleteFile = async (fileId: string) => {
      await axios.delete(config.endpoints.SMARTDRIVE + "Files", {
        headers: {
          Authorization: "Bearer " + token
        },
        params: {
          appId: config.keys.SMARTDRIVEAPPID,
          Id: fileId
        }
      });
    };

    const ref = doc(collection(db, collectionId), solicitation.id);

    try {
      const promises = solicitation.attachments.map(a => deleteFile(a.id));
      promises.push(deleteDoc(ref));
      await Promise.all(promises);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
};
