import * as React from "react";
import styled from "styled-components";
import { PaneledPageExtendedProps } from "./ExtendedProps";
import { SectionListModel } from "./ContentSectionDataModels";
import { TextOverflow } from "../containers";

export const isContentSectionTemplate = (
  node: any
): node is ContentSectionTemplate => {
  if (
    node &&
    node.props &&
    node.type.componentType === "ContentSectionTemplate" &&
    (node as ContentSectionTemplate).props.title !== undefined
  )
    return true;
  else return false;
};

export interface ContentSectionTemplateProps extends PaneledPageExtendedProps {
  /** Title of this section */
  title: string;
  /** Optional small toolbar at the top-right corner of the section */
  tools?: any;
  /** When set to true, allows the section to be collapsed and expanded by the user. Defaults to false. */
  collapsible?: boolean;
  /** When set to true, the section is initially rendered as collapsed and must be expanded by the user to view its contents. Defaults to false. If set to true, the 'collapsible' property must also be set to true. */
  defaultCollapsed?: boolean;
  /** When set to true, the section is marked as "flagged". If the PaneledPage's table of contents is shown, the section will have said flag next to its name. Defaults to false. */
  flagged?: boolean;
  /** When set to true, un-mounts content inside the section's body whenever the section is collapsed. Defaults to false. */
  unmountContentOnCollapse?: boolean;
  /** Optional callback to be executed when the user collapses a section. */
  onSectionCollapse?: (targetSectionListModel: SectionListModel) => void;
  /** Optional callback to be executed when the user expands a section. */
  onSectionExpand?: (targetSectionListModel: SectionListModel) => void;
  /** Automation testing id for the ContentSection component. Defaults to "paneledpage-content-section-{sectionIndex}".
   * Available wildcards:
   * %INDEX% - the section index value
   * %TITLE% - the section's title, in lowercase characters */
  testId?: string;
  /** Optional section's checkbox*/
  sectionCheckBox?: React.ReactNode;
  /** To handle the collapsed state from props, to make this be a controlled component */
  isCollapsed?: boolean;
  /** This is required when isCollapsed is set to handle isCollapsed */
  onToggleHandler?: (isSectionCollapsed: boolean) => void;
}
export class ContentSectionTemplate extends React.PureComponent<
  ContentSectionTemplateProps
> {
  static componentType = "ContentSectionTemplate";
}

export interface ContentSectionProps extends ContentSectionTemplateProps {
  index: number;
  isCurrentSection: boolean;
  isLastSection: boolean;
  onHeightChange?: (isLastSection: boolean) => void;
  tableOfContentsActive?: boolean;
}
export interface ContentSectionState {
  collapsed: boolean;
  haveContentReady: boolean;
}
export default class ContentSection extends React.PureComponent<
  ContentSectionProps,
  ContentSectionState
> {
  static componentType = "ContentSection";
  readonly componentClassIdentifier: string =
    "paneled-page-content-main-section";
  private resizeListener: React.RefObject<any> = React.createRef();
  readonly state = {
    collapsed:
      this.props.isCollapsed !== undefined
        ? this.props.isCollapsed
        : this.props.collapsible && this.props.defaultCollapsed
        ? this.props.defaultCollapsed
        : false,
    haveContentReady:
      this.props.collapsible && this.props.defaultCollapsed ? false : true
  };

  componentDidMount() {
    this.resizeListener.current.contentWindow.addEventListener(
      "resize",
      this.handleHeightChange,
      true
    );
  }

  componentWillUnmount() {
    this.resizeListener.current.contentWindow.removeEventListener(
      "resize",
      this.handleHeightChange,
      true
    );
  }

  static getDerivedStateFromProps(
    nextProps: ContentSectionProps,
    prevState: ContentSectionState
  ) {
    if (
      nextProps.isCollapsed !== undefined &&
      nextProps.isCollapsed !== prevState.collapsed
    ) {
      return {
        collapsed: nextProps.isCollapsed
      };
    }
  }

  componentDidUpdate(
    prevProps: ContentSectionProps,
    prevState: ContentSectionState
  ) {
    if (this.state.collapsed !== prevState.collapsed) {
      const targetSectionListModel: SectionListModel = {
        sectionIndex: this.props.index,
        sectionTitle: this.props.title,
        sectionFlagged: this.props.flagged
      };
      if (this.state.collapsed) {
        if (this.props.onSectionCollapse)
          this.props.onSectionCollapse(targetSectionListModel);
        if (this.props.unmountContentOnCollapse)
          this.setState({ haveContentReady: false });
      } else {
        if (this.props.onSectionExpand)
          this.props.onSectionExpand(targetSectionListModel);
        if (!this.state.haveContentReady)
          this.setState({ haveContentReady: true });
      }
    }
  }

  handleHeightChange = (event: any) => {
    // Sections can unexpectedly change height due to lazy-loaded content 'jumping' into appearance.
    if (this.props.onHeightChange)
      this.props.onHeightChange(this.props.isLastSection);
  };

  toggleCollapse = () => {
    if (this.props.onToggleHandler) {
      this.props.onToggleHandler(this.state.collapsed);
    }
    this.setState(prevState => ({
      collapsed: this.props.collapsible ? !prevState.collapsed : false
    }));
  };

  onSectionToolsClicked = (event: any) => {
    if (this.state.collapsed) {
      this.setState({
        collapsed: false
      });
    }
  };

  renderTestId = (): string => {
    if (this.props.testId)
      return this.props.testId
        .replace(/%(INDEX)%/g, `${this.props.index}`)
        .replace(/%(TITLE)%/g, `${this.props.title}`);
    else return `paneledpage-content-section-${this.props.index}`;
  };

  render() {
    const sectionId = `section-${this.props.index}`;
    const hasSectionTools: boolean =
      this.props.tools && this.props.tools !== "";

    return (
      <StyledContentSection
        className="content-section content-section-card card-flat"
        id={`section-${this.props.index}`}
        data-section-index={this.props.index}
        data-widget-collapsed={this.state.collapsed}
        data-current-section={this.props.isCurrentSection}
        data-table-of-contents={
          this.props.tableOfContentsActive ? "active" : "inactive"
        }
        data-testid={this.renderTestId()}
        {...this.props.passThroughProps}
      >
        <iframe
          className="height-resize-listener"
          data-target-section={sectionId}
          ref={this.resizeListener}
          title={`height-resize-listener-${sectionId}`}
        />
        <div className="content-section-header">
          <div className="content-at-left">
            {this.props.sectionCheckBox && (
              <div style={{ paddingRight: "1rem" }}>
                {this.props.sectionCheckBox}
              </div>
            )}
            {this.props.collapsible && (
              <button
                className="content-section-collapse-toggle"
                onClick={this.toggleCollapse}
              >
                <i className="fa fa-chevron-down" />
              </button>
            )}
            <TextOverflow
              onClick={this.toggleCollapse}
              className={`content-section-title ${
                this.props.collapsible ? "section-collapsible" : ""
              }`}
            >
              {this.props.title}
            </TextOverflow>
          </div>
          {hasSectionTools && (
            <div
              className="section-widget-tools content-at-right"
              onClick={this.onSectionToolsClicked}
            >
              {this.props.tools}
            </div>
          )}
        </div>

        <div className="content-section-body">
          {this.state.haveContentReady && (
            <div className="content-inner-container">{this.props.children}</div>
          )}
        </div>
      </StyledContentSection>
    );
  }
}

export const StyledContentSection = styled("section")`
  position: relative;
  box-sizing: border-box;
  margin-bottom: 32px;
  padding: 0;
  border-radius: 4px;
  border-left: 4px solid transparent;
  /*box-shadow: 0 0 0 0 transparent*/
  background: ${props => {
    //@ts-ignore
    if (props.disabled === "true") {
      return "#e6e6e6";
    }
    return "#ffffff";
  }};
  transition: 0.3s;

  &[data-table-of-contents="active"] {
    &[data-current-section="true"] {
      box-shadow: -4px 4px 10px -2px #d6d6d6;
    }
    &:not([data-current-section="true"]) {
      box-shadow: -4px 4px 10px -12px #d6d6d6;
    }
  }

  & > .content-section-header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px 24px 24px;

    & > .content-at-left {
      width: 100%;
      cursor: pointer;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      flex-grow: 1;
    }
    & > .content-at-right {
      flex-shrink: 0;
      margin-left: 36px;
      text-align: right;
    }

    .content-section-collapse-toggle {
      display: inline-block;
      position: relative;
      margin-right: 8px;
      font-size: 1em;
      vertical-align: middle;
      cursor: pointer;
      transition: 0.2s;
      width: 20px;
      height: 20px;

      border: none;
      background: none;
      padding: 0;

      & > i {
        transition: 0.2s;
      }

      &:hover > i,
      &:active > i {
        color: #0370f5;
      }
    }

    & .content-section-title {
      display: inline-block;
      margin: 2px 0 0 0;
      font-size: 1.2em;
      font-weight: 500;
      vertical-align: middle;

      &.section-collapsible {
        cursor: pointer;
      }
    }
  }

  & > .content-section-body {
    padding: 0 24px 28px 24px;
    height: auto;
    font-size: 0.95em;

    h4,
    h5,
    h6 {
      color: #404040;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  &[data-widget-collapsed="false"] {
    min-height: 192px;

    & > .content-section-header {
      .content-section-collapse-toggle > i {
        transform: rotate(0deg);
        margin-top: ${props =>
          props.theme.baseTheme === "Concrete" ? "2px" : "1px"};
      }
    }

    & > .content-section-body {
      & > .content-inner-container {
        height: auto;
      }
    }
  }
  &[data-widget-collapsed="true"] {
    & > .content-section-header {
      padding-bottom: 0;
      .content-section-collapse-toggle > i {
        transform: rotate(-90deg);
        margin-top: ${props =>
          props.theme.baseTheme === "Concrete" ? "4px" : "3px"};
        margin-left: -1px;
      }
    }

    & > .content-section-body {
      & > .content-inner-container {
        height: 0;
        overflow: hidden;
      }
    }
  }

  & > .height-resize-listener {
    display: block !important;
    position: absolute;
    width: 0;
    height: 100%;
    background-color: none;
    border: none;
  }
`;
