import React from "react";
import styled from "styled-components";
import { strings } from "localization";

import { Button, MenuItem, DropdownButton } from "hcss-components";
import { useAuthorization } from "../../account";

export interface InactiveOptionsButtonProps {
  templateId: string;
  onCreateReadonlyLink: (viewId: string) => void;
  onCreateNylasLink: (viewId: string) => void;
}

export const InactiveOptionsButton: React.FC<InactiveOptionsButtonProps> = ({
  templateId,
  onCreateReadonlyLink,
  onCreateNylasLink
}) => {
  const { canAccessHcssConnect } = useAuthorization();

  return (
    <>
      {canAccessHcssConnect && (
        <CreateLinkButton
          title={strings.calendars.card.createLink}
          inverted
          pullRight
          id="copyLink"
        >
          <MenuItem
            onClick={() => {
              onCreateReadonlyLink(templateId);
            }}
          >
            {strings.calendars.card.createReadonlyLink}
          </MenuItem>
          <MenuItem
            onClick={() => {
              onCreateNylasLink(templateId);
            }}
          >
            {strings.calendars.card.createNylasLinkk}
          </MenuItem>
        </CreateLinkButton>
      )}
    </>
  );
};

const CreateLinkButton = styled(DropdownButton)`
  font-size: 1.15rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.06rem;
  box-shadow: none;
  padding: 5px 20px;

  &:hover:not(:disabled) {
    box-shadow: none;
  }
`;
