import { DataColumnType, TypedDataColumn } from "hcss-tables";
import {
  ContactDto,
  IVendorDtoV1Response
} from "api/GeneratedClients/ContactsClient";
import { formatPhoneNumber } from "core";
import { SchemaFieldType } from "api";
import { sortBy } from "lodash-es";
import { strings } from "localization";

export const getContactListColumns = (
  vendor: IVendorDtoV1Response | undefined
) => {
  const FirstNameColumn: TypedDataColumn = {
    name: "firstName",
    title: strings.contact.contactColumnNames.firstName,
    type: DataColumnType.ShortText,
    hidingEnabled: false
  };

  const LastNameColumn: TypedDataColumn = {
    name: "lastName",
    title: strings.contact.contactColumnNames.lastName,
    type: DataColumnType.ShortText,
    hidingEnabled: false,
    getCellValue: (row: ContactDto) => row.lastName ?? ""
  };

  const TitleColumn: TypedDataColumn = {
    name: "title",
    title: strings.contact.contactColumnNames.title,
    type: DataColumnType.ShortText,
    getCellValue: (row: ContactDto) => row.title ?? ""
  };

  const CellPhoneNumberColumn: TypedDataColumn = {
    name: "cellPhoneNumber",
    title: strings.contact.contactColumnNames.cellNumber,
    type: DataColumnType.PhoneNumber,
    groupingEnabled: false,
    getCellValue: (row: ContactDto) =>
      formatPhoneNumber(row.cellPhoneNumber ?? "")
  };

  const PhoneNumberColumn: TypedDataColumn = {
    name: "phoneNumber",
    title: strings.contact.contactColumnNames.phoneNumber,
    groupingEnabled: false,
    type: DataColumnType.PhoneNumber,
    getCellValue: (row: ContactDto) => formatPhoneNumber(row.phoneNumber ?? "")
  };

  const EmailColumn: TypedDataColumn = {
    name: "emailAddress",
    title: strings.contact.contactColumnNames.emailAddress,
    groupingEnabled: false,
    type: DataColumnType.ShortText,
    getCellValue: (row: ContactDto) => row.emailAddress ?? ""
  };

  const MainContactColumn: TypedDataColumn = {
    name: "isMainContact",
    title: strings.contact.contactColumnNames.mainContact,
    groupingEnabled: false,
    type: DataColumnType.Boolean,
    getCellValue: (row: ContactDto) => row.isMainContact ?? false
  };

  const LocationColumn: TypedDataColumn = {
    name: "location",
    title: strings.contact.contactColumnNames.office,
    type: DataColumnType.ShortText,
    config: {
      preConType: SchemaFieldType.List
    },
    getCellValue: (row: ContactDto) => {
      return vendor?.locations?.find(l => l.id === row.vendorLocationId)
        ?.nickname;
    }
  };

  const NoteColumn: TypedDataColumn = {
    name: "note",
    title: strings.contact.contactColumnNames.notes,
    groupingEnabled: false,
    type: DataColumnType.LongText,
    getCellValue: (row: ContactDto) => row.note ?? ""
  };

  const Scope: TypedDataColumn = {
    name: "scope",
    title: strings.contact.contactColumnNames.scopes,
    groupingEnabled: true,
    type: DataColumnType.LongText,
    config: {
      preConType: SchemaFieldType.MultiSelectList
    },
    getCellValue: (row: ContactDto) => {
      const sortedProducts = sortBy(row.products, p => p.code);
      return sortedProducts.map(p => p.code).join(", ");
    }
  };

  return [
    FirstNameColumn,
    LastNameColumn,
    TitleColumn,
    EmailColumn,
    CellPhoneNumberColumn,
    PhoneNumberColumn,
    MainContactColumn,
    LocationColumn,
    Scope,
    NoteColumn
  ];
};

export const defaultColumnOrdering = [
  "firstName",
  "lastName",
  "title",
  "cellPhoneNumber",
  "phoneNumber",
  "emailAddress",
  "isMainContact",
  "location",
  "scopes",
  "note"
];
