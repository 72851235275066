import * as React from "react";
import styled from "styled-components";
import PageHeaderPanelTemplate from "./PageHeaderPanel";
import PageContentPanelTemplate from "./PageContentPanel";
import { defaultAsideWidth } from "./ContentAside";
import {
  PaneledPageContext,
  PaneledPageContextState
} from "./PaneledPageContext";
import { PaneledPageExtendedProps } from "./ExtendedProps";
import FiltersToggle from "./FiltersToggle";

export interface PaneledPageProps extends PaneledPageExtendedProps {
  /** Optional property for fixing height fit issues when the PaneledPage is inside some kind of container or wrapper. Default: 'page'. */
  fitTo?: "page" | "container";
  /** Optional property to set a maximum width for PaneledPage content, beyond which the content is centered on the page. */
  maxWidth?: number;
  /** Alignment of content on the page, if maxWidth is supplied and once maximum width is reached. Defaults to "center" */
  contentAlign?: "center" | "left";
  /** Automation testing id for the PaneledPage container component. Defaults to "paneledpage-container". */
  testId?: string;
  /** Sub-components of PaneledPage. Only the PaneledPage.Header and PaneledPage.Content components should be used as immediate children of the PaneledPage.
   *  The Header component should be used before the Content component.
   */
  children?: React.ReactNode | React.ReactNode[];
}
export interface PaneledPageState {
  filtersPanelExpanded: boolean;
  filtersHasOpenDropdown: boolean;
  activeDropdownId: string | null;
  asideWidth: number;
}
export default class PaneledPage extends React.PureComponent<
  PaneledPageProps,
  PaneledPageState
> {
  static Header = PageHeaderPanelTemplate;
  static Content = PageContentPanelTemplate;
  static FiltersToggle = FiltersToggle;
  static defaultProps: PaneledPageProps = {
    testId: "paneledpage-container"
  };
  static componentType = "PaneledPage";

  private dropdownCheckTimeout: any;
  private mounted = true;

  readonly state: PaneledPageState = {
    filtersPanelExpanded: false,
    filtersHasOpenDropdown: false,
    activeDropdownId: null,
    asideWidth: 200
  };

  componentDidMount() {
    this.checkForInvalidNodes();
  }

  componentWillUnmount() {
    this.mounted = false;
    if (!!this.dropdownCheckTimeout) clearTimeout(this.dropdownCheckTimeout);
  }

  showFilters = () => this.setState({ filtersPanelExpanded: true });
  hideFilters = () => this.setState({ filtersPanelExpanded: false });

  handleClickInsidePage = (event?: any) => {
    this.dropdownCheckTimeout = setTimeout(() => {
      if (this.mounted) this.checkForOpenDropdowns();
    }, 50);
  };

  handleClickInsideFilters = (event?: any) => {
    if (!this.state.filtersPanelExpanded) this.showFilters();
  };

  handleFiltersPanelToggled = (event?: any) => {
    if (event) event.stopPropagation();
    if (!this.state.filtersPanelExpanded) this.showFilters();
    else this.hideFilters();
  };

  // The animation is a lot smoother and more effective when the default expansion of the filters is delayed somewhat.
  // It also catches a user's attention more readily when it occurs just a fraction of a second after the page loads.
  handleDefaultExpandFilters = () => setTimeout(this.showFilters, 450);

  checkForInvalidNodes = () => {
    if (process.env.NODE_ENV === "development" && this.props.children) {
      let numHeaderPanelNodes = 0;
      let numContentPanelNodes = 0;

      React.Children.forEach(
        this.props.children,
        (node: React.ReactNode, index) => {
          if (node && (node as any).type !== undefined) {
            const nodeComponentTypeName: string | null =
              (node as any).type.name || null;
            if (nodeComponentTypeName === "PageHeaderPanelTemplate")
              numHeaderPanelNodes++;
            else if (nodeComponentTypeName === "PageContentPanelTemplate")
              numContentPanelNodes++;
            else {
              console.warn(
                "Invalid child node of <PaneledPage>\n" +
                  `<${
                    (node as any).type
                  }> is not a valid immediate child of a <PaneledPage> component. ` +
                  "We recommend using only <PaneledPage.Header> or <PaneledPage.Content> as immediate children of a <PaneledPage>."
              );
            }
          }
        }
      );

      if (numHeaderPanelNodes > 1) {
        console.warn(
          "Invalid duplicate children\n" +
            "<PaneledPage> should not have more than one <PaneledPage.Header> as a child component. " +
            "Duplicate <PaneledPage.Header> nodes will be ignored"
        );
      }

      if (numContentPanelNodes > 1) {
        console.warn(
          "Invalid duplicate children\n" +
            "<PaneledPage> should not have more than one <PaneledPage.Content> as a child component. " +
            "Duplicate <PaneledPage.Content> nodes will be ignored"
        );
      }
    }
  };

  checkForOpenDropdowns = () => {
    const activeElementId: string | null = document.activeElement
      ? document.activeElement.id || "unknown"
      : null;
    if (activeElementId && activeElementId.indexOf("react-select") !== -1) {
      if (this.state.filtersHasOpenDropdown) {
        // If there already was an open dropdown in the filters panel, check if it's the same one the user just focused.
        // If so, the dropdown will hide; otherwise, the user opened some other dropdown in that panel.
        this.setState(prevState => ({
          filtersHasOpenDropdown:
            activeElementId !== prevState.activeDropdownId,
          activeDropdownId: activeElementId
        }));
      } else
        this.setState({
          filtersHasOpenDropdown: true,
          activeDropdownId: activeElementId
        });
    } else
      this.setState({
        filtersHasOpenDropdown: false,
        activeDropdownId: null
      });
  };

  handleClickInsideContent = (filtersPanelShouldHide: boolean) => {
    if (this.mounted) {
      if (!this.state.filtersHasOpenDropdown)
        filtersPanelShouldHide && this.hideFilters();
      else this.setState({ filtersHasOpenDropdown: false });
    }
  };

  setAsideWidth = (updatedWidth: number | undefined) => {
    this.setState({ asideWidth: updatedWidth || defaultAsideWidth });
  };

  userExpandedAnyDropdown = (): boolean => {
    if (
      document.activeElement &&
      document.activeElement.id &&
      document.activeElement.id.indexOf("react-select") !== -1
    )
      return true;
    else return false;
  };

  render() {
    const maxPageWidth: number | null =
      this.props.maxWidth && this.props.maxWidth > 0
        ? this.props.maxWidth
        : null;
    const contentAlign: "center" | "left" = this.props.contentAlign || "center";
    const contextState: PaneledPageContextState = {
      maxPageWidth: maxPageWidth,
      contentAlign: contentAlign,
      asideWidth: this.state.asideWidth,
      setAsideWidth: this.setAsideWidth,
      filtersPanelExpanded: this.state.filtersPanelExpanded,
      filtersHasOpenDropdown: this.state.filtersHasOpenDropdown,
      activeDropdownId: this.state.activeDropdownId,
      onToggleFiltersPanel: this.handleFiltersPanelToggled,
      onDefaultExpandFilters: this.handleDefaultExpandFilters,
      onClickInsideFilters: this.handleClickInsideFilters,
      onClickInsideContent: this.handleClickInsideContent
    };
    return (
      <PaneledPageContext.Provider value={contextState}>
        <StyledPaneledPage
          className={`paneled-page-container fit-to-${
            this.props.fitTo || "page"
          }`}
          onClick={this.handleClickInsidePage}
          data-testid={this.props.testId}
          {...this.props.passThroughProps}
        >
          {this.props.children}
        </StyledPaneledPage>
      </PaneledPageContext.Provider>
    );
  }
}

export const StyledPaneledPage = styled("div")`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: visible;
  overflow-x: hidden;

  @media print {
    display: inline;
  }

  &.fit-to-page {
    height: calc(100vh - 45px);
  }
  &.fit-to-container {
    height: 100%;
  }

  .filters-panel-toggle-listener {
    cursor: pointer;
  }
`;
