import * as React from "react";
import { ResponsiveBar } from "@nivo/bar";

export const CheckListChart: React.FunctionComponent<{ data: any }> = ({
  data /* see data tab */
}) => (
  <ResponsiveBar
    data={data}
    keys={["checked", "notChecked"]}
    indexBy="project"
    padding={0.3}
    colors={
      data[0].checked
        ? ["rgb(151, 227, 213)", "rgb(244, 117, 96)"]
        : ["rgb(244, 117, 96)"]
    }
    axisTop={null}
    axisRight={null}
    axisBottom={null}
    axisLeft={null}
    layout="horizontal"
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    label={d =>
      ((d.value as number) / (data[0].checked + data[0].notChecked)).toString()
    }
    labelFormat={".0%"}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
    isInteractive={false}
  />
);
