import { strings } from "localization";
import { boolean } from "yup";

const LATITUDE_THRESHOLD = 90.0;
const LONGITUDE_THRESHOLD = 180.0;

export const isValidLatitude = (latitude: any) =>
  latitude !== null &&
  latitude !== undefined &&
  !isNaN(latitude) &&
  Math.abs(latitude) <= LATITUDE_THRESHOLD;

export const isValidLongitude = (longitude: any) =>
  longitude !== null &&
  longitude !== undefined &&
  !isNaN(longitude) &&
  Math.abs(longitude) <= LONGITUDE_THRESHOLD;

export const isNullIsland = (latitude: any, longitude: any) =>
  latitude === 0 && longitude === 0;

interface TryParseLatLongResult {
  isValid: boolean;
  error: string;
  value:
    | {
        lat: number;
        long: number;
      }
    | undefined;
}
export const tryParseLatLong = (value: string): TryParseLatLongResult => {
  const result: TryParseLatLongResult = {
    isValid: false,
    error: "",
    value: undefined
  };

  if (typeof value !== "string") {
    result.error = strings.import.errors.invalidColumn;
    return result;
  }

  const [lat, long] = value.split(",");
  const latitude = parseFloat(lat);
  const longitude = parseFloat(long);

  if (isNaN(latitude) || isNaN(longitude)) {
    result.error = strings.import.errors.invalidColumn;
    return result;
  }

  if (!isValidLatitude(latitude)) {
    result.error = strings.import.errors.locationType.invalidLatitude;
    return result;
  }
  if (!isValidLongitude(longitude)) {
    result.error = strings.import.errors.locationType.invalidLongitude;
    return result;
  }
  if (isNullIsland(latitude, longitude)) {
    result.error = strings.import.errors.locationType.isNullIsland;
    return result;
  }

  result.isValid = true;
  result.value = {
    lat: latitude,
    long: longitude
  };
  return result;
};
