import React from "react";
import styled from "styled-components";
import { strings } from "localization";
import { Table } from "@devexpress/dx-react-grid-bootstrap3";
import { Icon } from "hcss-components";
import { Document } from "core";

export const TableActionsCell = ({ value, row }: Table.DataCellProps) => {
  const documentRow: Document = row as Document;
  const handleCopyLink = () => {
    const textField = document.createElement("textarea");
    textField.innerText = documentRow.url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  const handleDownloadLink = () => {
    window.open(documentRow.url);
  };

  return (
    <td>
      <FileCell>
        <ActionsArea style={{ gridArea: "actions" }}>
          <TableActionButton onClick={handleCopyLink}>
            <Icon name="clone" margin="right" />
            {strings.smartdrive.copyLink}
          </TableActionButton>
          <TableActionButton onClick={handleDownloadLink}>
            <Icon name="download" margin="right" />
            {strings.smartdrive.download}
          </TableActionButton>
        </ActionsArea>
      </FileCell>
    </td>
  );
};

const TableActionButton = styled.button<{ color?: string }>`
  background-color: white;
  border: none;
  padding: none;
  color: ${props => (props.disabled ? "707070" : props.color || "#005eb8")};
`;

const ActionsArea = styled.div`
  justify-content: flex-end;
  align-items: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const FileCell = styled.div`
  padding: 4px;
`;
