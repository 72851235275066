import React from "react";
import styled from "styled-components";
import {
  HeaderSection,
  Item,
  MenuSection
} from "core/components/hcss-navigation";
import { ConcreteColors, Icon } from "hcss-components";
import { Link, useLocation } from "react-router-dom";
import { useAccount } from "modules/account";
import * as schemas from "modules/schemas";
import { useSelector } from "react-redux";
import { HeaderSectionWithBackButton } from "../../common/header-section-with-back-button";
import { DisableInSandbox } from "core/util/sandbox";
import { strings } from "localization";

export const Imports = () => {
  const path = useLocation().pathname;
  const account = useAccount();
  const isBidResultsAdminOnly = useSelector(
    schemas.selectors.getAllProjectSchemaFields
  )?.find(x => x.id === "bidResults")?.adminOnly;

  const canImportBidResults = () => {
    if (
      isBidResultsAdminOnly &&
      account.permissions &&
      !account.permissions.admin
    ) {
      return false;
    }

    return true;
  };

  return (
    <>
      <HeaderSectionWithBackButton backPath="/projects">
        <div>{strings.layout.menu.projects.import.label}</div>
      </HeaderSectionWithBackButton>
      <MenuSection>
        {menuStyle => (
          <ItemContainer style={menuStyle}>
            <Item
              data-testid="navitem-import-projects"
              key="import-projects"
              text={strings.layout.menu.projects.import.projects}
              isSelected={path === `/projects/import-projects`}
              to={`/projects/import-projects`}
            />
            {canImportBidResults() && (
              <DisableInSandbox>
                <Item
                  data-testid="navitem-import-bidding-results"
                  key="bidding-results"
                  text={strings.layout.menu.projects.import.bidResults}
                  isSelected={path === `/projects/import-bid-results`}
                  to={`/projects/import-bid-results`}
                />
              </DisableInSandbox>
            )}
          </ItemContainer>
        )}
      </MenuSection>
    </>
  );
};

const HeaderContainer = styled.div`
  padding-bottom: 26px;
  padding-left: 12px;
  padding-top: 8px;
  font-size: 16px;
  font-weight: 700;
  color: ${ConcreteColors.gray600};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BackToProjectsLink = styled(Link)`
  font-size: 12px;
  font-weight: 400;
  color: ${ConcreteColors.gray600};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ItemContainer = styled.div`
  font-size: 14px;
  margin-right: 14px;
  color: ${ConcreteColors.gray600};
`;
