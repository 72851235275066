import { EstimateItem } from "../models/IEstimateItem";
import { SubItem } from "../models/ISubItem";

export function isEstimateItem(
  item: EstimateItem | SubItem
): item is EstimateItem {
  if ((item as EstimateItem).subItems !== undefined) {
    return true;
  }

  return false;
}

export function isSubItem(item: EstimateItem | SubItem): item is SubItem {
  return !isEstimateItem(item);
}

export const isDict = (obj: any) => obj && obj.constructor == Object;
