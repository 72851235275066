import { isEqual } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PreConId, ProjectsApi, actions, selectors } from "modules/projects";
import { selectors as schemaSelectors } from "modules/schemas";
import {
  selectors as accountSelectors,
  useSelectedBusinessUnitId
} from "modules/account";
import { FieldMetadata } from "api";
import { useCallback, useMemo, useState } from "react";

export function useGetCurrentProject() {
  const { id: projectId } = useParams<{ id: string }>();
  const projectLookup = useSelector(selectors.getProjectHash);

  return projectLookup[projectId];
}

export const useProjectFieldsMetadata = (projectId: string) => {
  const dispatch = useDispatch();
  const projectsLookup = useSelector(selectors.getProjectHash);
  const projectsLookupPristine = useSelector(selectors.getProjectHashPristine);
  const projectSchemaLookup = useSelector(schemaSelectors.getProjectSchema)
    ?.fields;

  const projectFieldsMetadata = useMemo(
    () => projectsLookup[projectId]?.fieldsMetadata ?? {},
    [projectId, projectsLookup]
  );
  const projectFieldsMetadataPristine = useMemo(
    () => projectsLookupPristine[projectId]?.fieldsMetadata ?? {},
    [projectId, projectsLookupPristine]
  );

  const hasChanged = (() => {
    if (!projectId) return false;
    const oldFieldMetadata = Object.keys(projectFieldsMetadata)
      .filter(key => Object.keys(projectFieldsMetadataPristine).includes(key))
      .reduce((filteredObj, key) => {
        filteredObj[key] = projectFieldsMetadata[key];
        return filteredObj;
      }, {} as { [key: string]: any });
    const hasChangedInOldMetadata = !isEqual(
      oldFieldMetadata,
      projectFieldsMetadataPristine
    );

    const newFieldMetadataKeys = Object.keys(projectFieldsMetadata).filter(
      key => !Object.keys(projectFieldsMetadataPristine).includes(key)
    );
    const hasChangedInNewMetadata = newFieldMetadataKeys.some(
      key => projectFieldsMetadata[key]?.isLocked === true
    );
    return hasChangedInNewMetadata || hasChangedInOldMetadata;
  })();

  const checkIfFieldIsLocked = useMemo(
    () => (fieldId: string) => {
      if (!projectSchemaLookup || !projectFieldsMetadata[fieldId] || !projectId)
        return false;
      const fieldSchema = projectSchemaLookup[fieldId];
      return (
        fieldSchema.isLockable &&
        projectFieldsMetadata[fieldId].isLocked === true
      );
    },
    [projectSchemaLookup, projectFieldsMetadata, projectId]
  );

  const updateProjectFieldMetadata = useCallback(
    (fieldId: string, projectFieldMetadata: FieldMetadata) => {
      if (!projectId) return;
      dispatch(
        actions.updateProjectFieldMetadata(projectId, fieldId, {
          ...projectFieldMetadata
        })
      );
    },
    [projectId, dispatch]
  );

  const updateProjectMetadata = useCallback(
    (projectMetadata: { [key: string]: FieldMetadata }) => {
      if (!projectId) return;
      dispatch(actions.updateProjectMetadata(projectId, projectMetadata));
    },
    [projectId, dispatch]
  );

  return {
    projectFieldsMetadata,
    projectFieldsMetadataPristine,
    hasChanged,
    updateProjectMetadata,
    updateProjectFieldMetadata,
    checkIfFieldIsLocked
  };
};

export const usePreConId = () => {
  const [preConId, setPreConId] = useState<PreConId | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const token = useSelector(accountSelectors.getHcssToken);
  const businessUnitId = useSelectedBusinessUnitId();

  const projectSchema = useSelector(schemaSelectors.getProjectSchema);
  const isPreConIdDisabled =
    projectSchema?.fields?.["preconId"]?.hiddenInForm &&
    projectSchema?.fields?.["preconId"]?.hiddenInTable;

  const getAndReservePreConId = useCallback(async () => {
    if (isPreConIdDisabled) return;
    if (token) {
      try {
        const api = new ProjectsApi(token, businessUnitId);
        setIsLoading(true);
        const result = await api.getLastPreConIdAndReserveNext();
        if (Object.keys(result.data).length === 0) setPreConId(null);
        setPreConId(result.data);
        setIsLoading(false);
        return result.data;
      } catch (error) {
        setIsLoading(false);
        console.error("Failed to get and reserve PreCon Id.");
        dispatch(actions.loadPreConID.failure(error as Error));
      }
    }
  }, [token, dispatch, isPreConIdDisabled, businessUnitId]);

  const discardReservedPreConId = useCallback(
    async (preConId: string) => {
      if (isPreConIdDisabled) return;
      if (token && preConId) {
        try {
          setIsLoading(true);
          const api = new ProjectsApi(token, businessUnitId);
          await api.discardReservedPreConId(preConId);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.error("Failed to discard the reserved PreCon Id.");
          dispatch(actions.loadPreConID.failure(error as Error));
        }
      }
    },
    [dispatch, token, isPreConIdDisabled, businessUnitId]
  );

  return {
    isLoading,
    preConId,
    getAndReservePreConId,
    discardReservedPreConId
  };
};
