import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropdownList } from "react-widgets";
import { Checkbox, Modal } from "hcss-components";
import { Flex } from "hcss-core";
import {
  IContactProduct,
  IContactDto,
  IVendorDtoV1Response,
  ProductDto
} from "api/GeneratedClients/ContactsClient";
import {
  actions as contactsActions,
  selectors as contactsSelectors
} from "modules/contacts";
import {
  CompanyBadge,
  CompanyDropdownFilter,
  CompanyFieldData,
  CompanyOption
} from "modules/schemas/components/fields/CompanyInput";
import { DropdownContainer } from "modules/schemas/components/fields/MultiSelectListInput";
import Button from "@mui/material/Button";
import MuiGrid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import { formatPhoneNumber, formatFirstNameLastName } from "core";
import FormControlLabel from "@mui/material/FormControlLabel";
import { strings } from "localization";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { useVendorLocationDropDown } from "../hooks/use-vendor-location-drop-down";
import { useGetSelectedVendorFromProject } from "../hooks/use-get-selected-vendor-from-project";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import { ContactScopeOfWork } from "./contact-scopes-of-work";
import { IVendorListOption } from "../interfaces/IVendorListOption";
import { IContactListOption } from "../interfaces/IContactListOption";

interface ContactListModalProps {
  editingContact: IContactDto;
  vendor: IVendorDtoV1Response | undefined;
  selectNewContactCallback?: (data: IContactListOption) => void;
  setShowCreateContactModal?: (showModal: boolean) => void;
  showCreateContactModal?: boolean;
}
interface LocationIdOption {
  readonly value: string;
  readonly label: string;
}

export const ContactListModal = ({
  editingContact: contact,
  vendor,
  selectNewContactCallback,
  setShowCreateContactModal,
  showCreateContactModal
}: ContactListModalProps) => {
  const showModalFromList = useSelector(contactsSelectors.getShowModal);
  const isLoading = useSelector(contactsSelectors.getLoading);
  const [contactLocationId, setContactLocationId] = useState<
    string | undefined
  >();
  const [contactProducts, setContactProducts] = useState<IContactProduct[]>(
    contact.products ? contact.products : []
  );
  const [vendorProducts, setVendorProducts] = useState<ProductDto[]>(
    vendor?.products ? vendor.products : []
  );
  const [newCodes, setNewCodes] = React.useState<(string | undefined)[]>([]);
  const [
    selectedCompany,
    setSelectedCompany
  ] = useState<CompanyFieldData | null>();
  const foundVendor = useGetSelectedVendorFromProject(selectedCompany?.id);
  if (foundVendor) {
    vendor = foundVendor;
  }
  const showCompanyDropdown = !window.location.pathname.includes("/contacts/");
  const ableToSetShowCreateContactModal =
    showCompanyDropdown &&
    showCreateContactModal != undefined &&
    setShowCreateContactModal != undefined;

  const vendorDataOptions = useSelector(contactsSelectors.getVendorDataOptions);
  const options: IVendorListOption[] = vendorDataOptions
    .map(data => {
      return {
        display: `${data.code} - ${data.name}`,
        value: data,
        code: data.code,
        id: data.id,
        products: data.products
      };
    })
    .filter(op => op.id !== vendor?.id);

  useEffect(() => {
    setNewCodes([]);
    setVendorProducts(vendor?.products ? vendor.products : []);
    setContactProducts(contact.products ? contact.products : []);
    setContactLocationId(contact.vendorLocationId);
    if (ableToSetShowCreateContactModal) {
      setShowCreateContactModal(showCreateContactModal);
    }
  }, [contact, vendor]);

  const isNewContact = !!!contact.id;
  const { locationOptions } = useVendorLocationDropDown(vendor);
  const locationSelectionOptions: LocationIdOption[] =
    locationOptions?.map(l => ({
      value: l.id,
      label: l.display
    })) ?? [];
  if (contactLocationId) {
    locationSelectionOptions?.push({ value: "", label: "(clear)" });
  }

  const dispatch = useDispatch();
  const onAddHandler = (contactParams: IContactDto) => {
    contactParams = {
      ...contactParams,
      vendorLocationId: contactLocationId,
      products: contactProducts
    };
    if (vendor && contact !== undefined && !isLoading) {
      dispatch(
        contactsActions.addContact.request({
          vendor: vendor,
          contact: contactParams
        })
      );
      setNewCodes([]);
    }
  };

  const onAddFromProjectHandler = (contactParams: IContactDto) => {
    contactParams = {
      ...contactParams,
      vendorLocationId: contactLocationId,
      products: contactProducts
    };
    if (
      vendor &&
      contact !== undefined &&
      !isLoading &&
      setShowCreateContactModal
    ) {
      dispatch(
        contactsActions.addContactFromProject.request({
          vendor: vendor,
          contact: contactParams,
          updateInput: sendContactInputData,
          setShowCreateContactModal: setShowCreateContactModal,
          setSelectedCompany: setSelectedCompany
        })
      );
      setNewCodes([]);
    }
  };

  const sendContactInputData = (
    contact: IContactDto,
    vendor: IVendorDtoV1Response
  ) => {
    const fullname = formatFirstNameLastName(
      contact.firstName,
      contact.lastName
    );
    if (selectNewContactCallback) {
      const displayData: IContactListOption = {
        display: fullname,
        value: {
          id: contact.id,
          firstName: contact.firstName,
          lastName: contact.lastName,
          companyId: vendor.id,
          companyCode: vendor.code,
          companyName: vendor.name,
          email: contact.emailAddress,
          phoneNumber: contact.phoneNumber,
          products: contact.products
        },
        id: contact.id
      };
      selectNewContactCallback(displayData);
    }
  };

  const onSelectCompanyHandler = (data: IVendorListOption) => {
    setSelectedCompany(data.value);
  };

  const onEditHandler = (contactParams: IContactDto) => {
    contactParams = {
      ...contactParams,
      vendorLocationId: contactLocationId,
      products: contactProducts
    };
    if (vendor && contact !== undefined && contactParams.id && !isLoading) {
      dispatch(
        contactsActions.updateContact.request({
          contact: contactParams,
          vendor: vendor
        })
      );
      setNewCodes([]);
    }
  };

  const onCancelHandler = () => {
    setNewCodes([]);
    setVendorProducts([]);
    setContactProducts([]);
    setSelectedCompany(null);
    if (ableToSetShowCreateContactModal) {
      setShowCreateContactModal(false);
    } else {
      dispatch(contactsActions.setShowModal(false));
    }
  };

  return (
    <Modal
      show={showCompanyDropdown ? showCreateContactModal : showModalFromList}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onHide={() => {}}
      enforceFocus={false}
    >
      <Modal.Header style={{ paddingBottom: "0" }}>
        <MuiGrid container justifyContent="space-between">
          <MuiGrid item>
            <h4 style={{ margin: "0.5rem" }}>
              {isNewContact
                ? strings.contactManagement.contactListModal.modalAddTitle
                : strings.contactManagement.contactListModal.modalEditTitle}
            </h4>
          </MuiGrid>
          <MuiGrid item>
            <TimesButton
              type="button"
              className="close"
              onClick={() => {
                onCancelHandler();
              }}
            >
              <span aria-hidden="true">×</span>
            </TimesButton>
          </MuiGrid>
        </MuiGrid>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={contact}
          validationSchema={YupValidationSchema}
          onSubmit={values => {
            if (isNewContact && !isLoading) {
              if (showCompanyDropdown) {
                onAddFromProjectHandler(values);
              } else {
                onAddHandler(values);
              }
            } else if (!isLoading) {
              onEditHandler(values);
            }
          }}
        >
          {formik => (
            <StyledForm>
              <MuiGrid container>
                <MuiGrid
                  container
                  item
                  xs={12}
                  columnSpacing={2}
                  display={showCompanyDropdown ? "inline" : "none"}
                  style={{ marginBottom: "2rem" }}
                >
                  <MuiGrid item xs={12}>
                    <p>{strings.contactManagement.contactFields.company}</p>
                    <Flex
                      alignItems="center"
                      justifyContent="flex-start"
                      style={{ position: "relative" }}
                    >
                      <CompanyBadge vendor={selectedCompany} />
                      <div id="arrow-dropdown-container">
                        <DropdownContainer>
                          <DropdownList
                            id="arrow-dropdown"
                            filter={(option, search) =>
                              CompanyDropdownFilter(option, search)
                            }
                            textField="display"
                            valueField="code"
                            value={selectedCompany}
                            data={options}
                            onChange={onSelectCompanyHandler}
                            messages={{
                              emptyList:
                                strings.projects.setup.message.emptyCompanyList
                            }}
                            itemComponent={({ item }) => (
                              <CompanyOption {...item.value} />
                            )}
                          />
                        </DropdownContainer>
                      </div>
                    </Flex>
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>
              <MuiGrid container>
                <MuiGrid container item xs={12} columnSpacing={2}>
                  <MuiGrid item xs={6} textAlign="center">
                    <StyledTextField
                      id="firstName"
                      margin="normal"
                      label={
                        strings.contactManagement.contactListModal.placeholders
                          .firstName
                      }
                      required
                      error={
                        formik.touched.firstName && !!formik.errors.firstName
                      }
                      helperText={formik.errors.firstName}
                      {...formik.getFieldProps("firstName")}
                      autoFocus
                    />
                  </MuiGrid>

                  <MuiGrid item xs={6} textAlign="center">
                    <StyledTextField
                      id="lastName"
                      margin="normal"
                      label={
                        strings.contactManagement.contactListModal.placeholders
                          .lastName
                      }
                      required
                      error={
                        formik.touched.lastName && !!formik.errors.lastName
                      }
                      helperText={formik.errors.lastName}
                      {...formik.getFieldProps("lastName")}
                    />
                  </MuiGrid>
                </MuiGrid>

                <MuiGrid container item xs={12} columnSpacing={2}>
                  <MuiGrid item xs={6} textAlign="center">
                    <StyledTextField
                      id="title"
                      margin="normal"
                      label={
                        strings.contactManagement.contactListModal.placeholders
                          .title
                      }
                      {...formik.getFieldProps("title")}
                    />
                  </MuiGrid>

                  <MuiGrid item xs={6} textAlign="center">
                    <StyledTextField
                      id="emailAddress"
                      margin="normal"
                      label={
                        strings.contactManagement.contactListModal.placeholders
                          .email
                      }
                      error={
                        formik.touched.emailAddress &&
                        !!formik.errors.emailAddress
                      }
                      helperText={formik.errors.emailAddress}
                      {...formik.getFieldProps("emailAddress")}
                    />
                  </MuiGrid>
                </MuiGrid>

                <MuiGrid container item xs={12} columnSpacing={2}>
                  <MuiGrid item xs={6} textAlign="center">
                    <StyledTextField
                      id="cellPhoneNumber"
                      type="text"
                      margin="normal"
                      label={
                        strings.contactManagement.contactListModal.placeholders
                          .cell
                      }
                      error={
                        formik.touched.cellPhoneNumber &&
                        !!formik.errors.cellPhoneNumber
                      }
                      {...formik.getFieldProps("cellPhoneNumber")}
                      value={formatPhoneNumber(
                        formik.values.cellPhoneNumber
                          ? formik.values.cellPhoneNumber
                          : ""
                      )}
                      helperText={formik.errors.cellPhoneNumber}
                    />
                  </MuiGrid>

                  <MuiGrid item xs={6} textAlign="center">
                    <StyledTextField
                      id="phoneNumber"
                      type="text"
                      margin="normal"
                      label={
                        strings.contactManagement.contactListModal.placeholders
                          .phone
                      }
                      error={
                        formik.touched.phoneNumber &&
                        !!formik.errors.phoneNumber
                      }
                      {...formik.getFieldProps("phoneNumber")}
                      value={formatPhoneNumber(
                        formik.values.phoneNumber
                          ? formik.values.phoneNumber
                          : ""
                      )}
                      helperText={formik.errors.phoneNumber}
                    />
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>
              <MuiGrid container item xs={12} columnSpacing={2}>
                <MuiGrid item xs={6} style={{ margin: "auto" }}>
                  <FormControlLabel
                    style={{ marginLeft: 0 }}
                    control={
                      <Checkbox
                        type="checkbox"
                        {...formik.getFieldProps("isMainContact")}
                        checked={formik.getFieldProps("isMainContact").value}
                      />
                    }
                    label={
                      strings.contactManagement.contactListModal.placeholders
                        .mainContact
                    }
                  />
                </MuiGrid>
                <MuiGrid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="office-select-label">
                      {strings.contactManagement.contactListModal.officeLabel}
                    </InputLabel>
                    <Select
                      disabled={
                        !vendor ||
                        (showCompanyDropdown
                          ? !showCreateContactModal
                          : !showModalFromList)
                      }
                      labelId="office-select-label"
                      id="office-select"
                      defaultValue={contact.vendorLocationId ?? ""}
                      value={contactLocationId ?? ""}
                      label={
                        strings.contactManagement.contactListModal.officeLabel
                      }
                      MenuProps={{
                        disableEnforceFocus: true
                      }}
                      onChange={(value: any) => {
                        setContactLocationId(value.target.value);
                      }}
                    >
                      {locationSelectionOptions?.map(o => (
                        <MenuItem value={o.value}>{o.label}</MenuItem>
                      ))}
                      {locationSelectionOptions?.length === 0 && (
                        <FormHelperText
                          style={{ padding: "0 1rem", textAlign: "center" }}
                        >
                          {
                            strings.contactManagement.contactListModal
                              .emptyLocationsText
                          }
                        </FormHelperText>
                      )}
                    </Select>
                  </FormControl>
                </MuiGrid>
              </MuiGrid>
              <MuiGrid item xs={12} spacing={3}>
                <FormGroup>
                  <ContactScopeOfWork
                    disabled={
                      !vendor ||
                      (showCompanyDropdown
                        ? !showCreateContactModal
                        : !showModalFromList)
                    }
                    vendorProducts={vendorProducts}
                    contactProducts={contactProducts}
                    setContactProducts={setContactProducts}
                    newCodes={newCodes}
                    setNewCodes={setNewCodes}
                  />
                </FormGroup>
              </MuiGrid>
              <MuiGrid item xs={12} spacing={3} textAlign="center">
                <FormGroup>
                  <TextField
                    id="note"
                    margin="normal"
                    label={
                      strings.contactManagement.contactListModal.placeholders
                        .notes
                    }
                    multiline
                    maxRows={10}
                    {...formik.getFieldProps("note")}
                  />
                </FormGroup>
              </MuiGrid>
              <MuiGrid item xs={12} paddingTop="3rem">
                <MuiGrid container justifyContent="space-between">
                  <MuiGrid item>
                    <Button onClick={onCancelHandler} color="secondary">
                      {strings.contactManagement.contactListModal.buttonCancel}
                    </Button>
                  </MuiGrid>
                  <MuiGrid item>
                    <Button
                      type="submit"
                      disabled={
                        !vendor ||
                        (showCompanyDropdown
                          ? !showCreateContactModal
                          : !showModalFromList)
                      }
                    >
                      {isNewContact
                        ? strings.contactManagement.contactListModal.buttonAdd
                        : strings.contactManagement.contactListModal.buttonSave}
                    </Button>
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>
            </StyledForm>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

const YupValidationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  emailAddress: Yup.string().email("Invalid email address")
});

const StyledTextField = styled(TextField)`
  height: 7rem;
  width: 100%;
`;

export const StyledForm = styled(Form)`
  padding: 0 0.6rem;
`;
export const TimesButton = styled.button`
  position: absolute;
  right: 23px;
  button.close {
    color: "Black")};
    opacity: 0.4;
    font-size: 28px;
    :hover {
      opacity: 0.9;
  }
}
`;
