import { call, put, select, SelectEffect } from "redux-saga/effects";
import RestApi, { PagedResults } from "./RestApi";
import { WithId } from "core";
import { StoreState } from "modules/store";
import { Action } from "redux";

export interface ApiFactoryOptions<T> {
  stateKey?:
    | "schemas"
    | "views"
    | "projects"
    | "estimates"
    | "calendars"
    | "configurationSettings";
  apiPath: string;
  onBeforeLoadSuccess?: (
    payload?: WithId<T>[]
  ) => Generator<SelectEffect, WithId<T>[] | undefined, unknown>;
  onLoadSuccess?: (payload: WithId<T>[]) => Action;
  onLoadFail?: (payload: Error) => Action;
  onLoadedAllRecords?: () => Action;
  onLoadedFirstPage?: () => Action;
  isBusinessUnitApi?: boolean;
}

export function* getTokenSaga() {
  const accessToken = yield select(
    ({ account }: StoreState) => account.user?.idsrvAccessToken
  );
  return accessToken;
}

export function* getSelectedBusinessUnitIdSaga() {
  const selectedBusinessUnitId = yield select(
    ({ account }: StoreState) => account?.user?.selectedBusinessUnitId
  );
  return selectedBusinessUnitId;
}

export function apiSagaFactory<T>(opts: ApiFactoryOptions<T>) {
  function* getApi() {
    const accessToken = yield call(getTokenSaga);
    const selectedBusinessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    if (opts.isBusinessUnitApi)
      return new RestApi<T>(
        `/v1/businessUnits/${selectedBusinessUnitId}${opts.apiPath}`,
        accessToken
      );
    else return new RestApi<T>(opts.apiPath, accessToken);
  }
  return {
    getApi,
    getTokenSaga,
    // tslint:disable-next-line:object-literal-shorthand
    load: function* () {
      const { stateKey } = opts;
      const previouslyLoaded = stateKey
        ? yield select(
            ({ [stateKey]: stateSlice }: StoreState) => stateSlice.loaded
          )
        : false;

      const api = yield getApi();

      if (!previouslyLoaded) {
        try {
          let hasNextPage: boolean | undefined = true;
          let continuationToken: string | undefined;
          while (hasNextPage) {
            const loadingFirstPage =
              stateKey === "projects" && (!hasNextPage || !continuationToken);
            const top = loadingFirstPage ? 100 : -1;
            const response = yield call([api, api.get], "", {
              continuationToken,
              orderBy: {
                fieldId: "lastModified",
                ascending: false
              },
              top
            });
            const data: PagedResults<WithId<T>> = response.data;
            let { results } = data;

            if (opts.onBeforeLoadSuccess) {
              results = yield call(opts.onBeforeLoadSuccess, results);
            }

            if (opts.onLoadSuccess) {
              yield put(opts.onLoadSuccess(results || []));
              if (opts.onLoadedFirstPage && loadingFirstPage)
                yield put(opts.onLoadedFirstPage());
            }
            continuationToken = data.nextPageToken;
            hasNextPage = data.hasNextPage;
          }
          if (opts.onLoadedAllRecords) {
            yield put(opts.onLoadedAllRecords());
          }
        } catch (error) {
          console.error(error);
          if (opts.onLoadFail) {
            yield put(opts.onLoadFail(error));
          }
          if (opts.onLoadedAllRecords) {
            yield put(opts.onLoadedAllRecords());
          }
        }
      }
    }
  };
}
