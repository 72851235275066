import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { isNullOrWhitespace, TextOverflow, ContactListColumnName } from "core";
import { useTableContext } from "hcss-tables";
import React, { useState, useRef } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectors as contactsSelectors } from "../state";
import { PhoneNumber, VendorLocationAddress } from "./vendor-details";

const ContactLocationFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  const { column, row, value } = props;
  const vendors = useSelector(contactsSelectors.getVendorList);
  const currentVendorLocations = vendors.find(v => v.id === row.companyId)
    ?.locations;
  const contactLocation = currentVendorLocations?.find(
    vl => vl.id === row.vendorLocationId
  );
  const [show, setShow] = useState(false);
  const optionRef = useRef(null);
  const popover = (
    <Popover
      id="contact-location-popover-trigger-hover"
      title={
        <TextOverflow>
          {contactLocation?.nickname} {column.title}
        </TextOverflow>
      }
    >
      {contactLocation?.address && (
        <TextOverflow>
          <VendorLocationAddress address={contactLocation.address} />
        </TextOverflow>
      )}
      {contactLocation?.phoneNumber && (
        <TextOverflow>
          <PhoneNumber phoneNumber={contactLocation?.phoneNumber} />
        </TextOverflow>
      )}
    </Popover>
  );

  return (
    <div id="test" {...props} style={{ maxWidth: "fit-content" }}>
      <TextOverflow
        ref={optionRef}
        onMouseEnter={() => {
          setShow(true);
        }}
        onMouseLeave={() => {
          setShow(false);
        }}
        onClick={e => {
          setShow(false);
        }}
      >
        {value}
        {(!isNullAddress(contactLocation?.address) ||
          !isNullOrWhitespace(contactLocation?.phoneNumber)) && (
          <Overlay
            rootClose
            show={show}
            placement="bottom"
            target={optionRef.current ?? undefined}
          >
            {popover}
          </Overlay>
        )}
      </TextOverflow>
    </div>
  );
};
export const ContactLocationProvider = () => {
  const { columns } = useTableContext();
  return React.useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={ContactLocationFormatter}
        for={columns
          .filter(column => column.name === ContactListColumnName.Location)
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};

const ContactEmailFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  const { value } = props;
  return <a href={`mailto:${encodeURIComponent(value)}`}>{value}</a>;
};

export const ContactEmailProvider = () => {
  const { columns } = useTableContext();
  return (
    <DataTypeProvider
      formatterComponent={ContactEmailFormatter}
      for={columns
        .filter(column => column.name === ContactListColumnName.EmailAddress)
        .map(column => column.name)}
    />
  );
};

const ContactPhoneFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  const { value } = props;
  return <a href={`tel:${encodeURIComponent(value)}`}>{value}</a>;
};

export const ContactPhoneProvider = () => {
  const { columns } = useTableContext();
  return (
    <DataTypeProvider
      formatterComponent={ContactPhoneFormatter}
      for={columns
        .filter(
          column =>
            column.name === ContactListColumnName.PhoneNumber ||
            column.name === ContactListColumnName.CellPhoneNumber
        )
        .map(column => column.name)}
    />
  );
};

const isNullAddress = (address: any) => {
  if (
    !address?.addressLine1 &&
    !address?.addressLine2 &&
    !address?.city &&
    !address?.stateProvince &&
    !address?.postalCode &&
    !address?.countryRegion
  ) {
    return true;
  }
};
