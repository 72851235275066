import {
  IVendorDtoV1Response,
  ProductDto,
  ProductTypeDto
} from "api/GeneratedClients/ContactsClient";
import { isValidString } from "core";
import { ConcreteColors, ControlLabel, FormGroup } from "hcss-components";
import { strings } from "localization";
import React from "react";
import { useSelector } from "react-redux";
import { selectors as contactsSelectors } from "modules/contacts";
import Creatable from "react-select/creatable";
import styled from "styled-components";
import { sortBy } from "lodash-es";

interface ScopeOfWorkProps {
  vendor: IVendorDtoV1Response;
  setVendor: (e: any) => void;
  showErrors: boolean;
}

interface ScopeOfWorkOption {
  label: string;
  description: string;
  value: string;
  __isNew__?: boolean;
}

const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

export const VendorScopeOfWork = ({ vendor, setVendor }: ScopeOfWorkProps) => {
  const sortedProducts = sortBy(vendor.products, p => p.product?.code);

  const allProductTypes = useSelector(contactsSelectors.getProductTypes);
  const options = allProductTypes
    .map(pt => ({
      value: pt.id ?? "",
      description: pt.description ?? "",
      label: pt.code ?? ""
    }))
    .concat(
      sortedProducts
        .filter(p => !allProductTypes.find(pt => pt.id === p.product?.id))
        .map(p => ({
          value: p.product?.id ?? "",
          label: p.product?.code ?? "",
          description: p.product?.description ?? ""
        }))
    );

  const selectedOptions = sortedProducts
    .map(prod => prod.product)
    .map(pt => options.find(opt => opt.label === pt?.code));

  const productTypeToProductMapping = sortedProducts.reduce(
    (mapping, product) => {
      mapping[product.product?.id] = product.id;
      return mapping;
    },
    {}
  );

  const updateScopeOfWork = (opt: ScopeOfWorkOption[]) => {
    opt = opt ?? [];
    opt = opt.filter(o => isValidString(o.value));
    opt.forEach(option => {
      // all codes get capitalized when created by the Contacts API
      // so we change it here for any newly created scopes by the user
      option.label = option.label.toUpperCase();
      //value will be the guid for an existing product OR the newly created value
      // one way to check for newly created values will be to verify id === description
      option.value = option.value.toLowerCase();
    });

    const productTypes: ProductTypeDto[] = opt?.map(
      op =>
        new ProductTypeDto({
          id: op.__isNew__ ? EMPTY_GUID : op.value,
          code: op.label,
          description: op.__isNew__ ? op.value : op.description // created options won't have a description property
        })
    );

    const products: ProductDto[] = productTypes?.map(
      pt =>
        new ProductDto({
          id: pt.id ? productTypeToProductMapping[pt.id] : EMPTY_GUID,
          companyId: vendor.id,
          product: pt,
          region: undefined,
          date: undefined
        })
    );
    setVendor({
      ...vendor,
      products: products
    });
  };

  const isValidNewOption = (
    inputValue: string,
    value: any,
    options: any
  ): boolean => {
    const optionLabels = options?.map(o => o.label) ?? [];
    return !optionLabels.includes(inputValue.toUpperCase());
  };

  const formatCreateLabel = (inputValue: string) =>
    isValidString(inputValue)
      ? `Create "${inputValue.toUpperCase()}"`
      : "To add a new option: type a value, press Enter";

  return (
    <>
      <ScopeOfWork>
        <div className="ScopeOfWork">
          <div className="Scopes">
            <FormGroup>
              <ControlLabel>{strings.contact.scopeOfWork.select}</ControlLabel>
              <StyledSelect
                classNamePrefix="react-select"
                className="react-select-container"
                options={options}
                defaultValue={selectedOptions}
                value={selectedOptions}
                isMulti
                onChange={updateScopeOfWork}
                isValidNewOption={isValidNewOption}
                formatCreateLabel={formatCreateLabel}
              />
            </FormGroup>
          </div>
        </div>
      </ScopeOfWork>
    </>
  );
};

const ScopeOfWork = styled.div`
  .form-group[required] label:after {
    content: " *";
    color: ${ConcreteColors.red200};
  }
  div.scopes {
    grid-area: scopes;
  }
`;

const StyledSelect = styled(Creatable)`
  div.react-select__menu {
    z-index: 11;
  }

  .react-select__control {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    min-height: 34px !important;
  }

  .react-select__input {
    height: 20px;
  }

  .react-select__multi-value {
    background-color: ${ConcreteColors.gray200};
  }

  .react-select__single-value {
    padding-bottom: 5px;
    padding-left: 7px;
  }

  .react-select__value-container {
    padding: 2px;
  }
`;
