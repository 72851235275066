import { View } from "api";
import axios, { AxiosInstance } from "axios";

export class ViewsApi {
  instance: AxiosInstance;
  businessUnitId: string;

  constructor(accessToken: string, businessUnitId: string) {
    this.instance = axios.create();
    this.instance.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    });
    this.businessUnitId = businessUnitId;
  }

  claimView = (viewId: string) => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/views/${viewId}/claim`;
    return this.instance.post<View>(url);
  };
}

export default ViewsApi;
