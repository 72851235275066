import React, { useState, useMemo, useEffect } from "react";
import uuid from "uuid/v4";
import FullPageLoading from "modules/layout/components/FullPageLoading";
import { strings } from "localization";
import { sortBy } from "lodash-es";
import { minorityStatusColumns } from "./minority-status-columns";
import { EditingState, TableRow } from "@devexpress/dx-react-grid";
import { NoDataPlaceholder, ContentLoadingOverlay } from "core";

import {
  IMinorityStatusDto,
  IVendorDtoV1Response,
  MinorityTypeDto
} from "api/GeneratedClients/ContactsClient";

import {
  ColumnResizing,
  CommandComponent,
  getRowId,
  GroupingState,
  LinkProvider,
  Root,
  SearchState,
  SortingState,
  TableContainer,
  TableWrapper,
  TypedDataColumn,
  DataColumnType,
  Grid
} from "hcss-tables";

import {
  TableEditColumn,
  TableEditRow,
  TableHeaderRow
} from "@devexpress/dx-react-grid-bootstrap3";
import { useSelector } from "react-redux";
import { selectors as contactsSelectors } from "modules/contacts";
import {
  MinorityPercentProvider,
  MinorityTypeProvider
} from "modules/tables/columns/column-providers";
import {
  CustomEditCellComponent,
  CustomEditCellHeaderComponent
} from "modules/tables/cells/custom-cells-components";
import LegacyVirtualTable from "core/components/bundle-fixes/LegacyVirtualTable";

interface MinorityStatusListProps {
  vendor: IVendorDtoV1Response;
  setVendor: React.Dispatch<
    React.SetStateAction<IVendorDtoV1Response | undefined>
  >;
}

export const MinorityStatusList = ({
  vendor,
  setVendor
}: MinorityStatusListProps) => {
  const minorityTypes = useSelector(contactsSelectors.getMinorityTypes);
  const isLoading = useSelector(contactsSelectors.getLoading);
  const [editingRowIds, setEditingRowIds] = useState<(string | number)[]>([]);

  useEffect(() => {
    const ids = vendor.minorityStatuses.map(ms => ms.id);
    if (ids === undefined) return;
    setEditingRowIds(ids);
  }, [vendor]);

  const MinorityTypeColumn: TypedDataColumn = useMemo(() => {
    let listValues = minorityTypes
      ? minorityTypes.map(mt => ({
          display: mt.code ?? "",
          value: mt
        }))
      : [];

    listValues = sortBy(
      listValues,
      opt => opt.display?.toLocaleLowerCase() ?? ""
    );

    return {
      name: "type",
      title: "Code",
      type: DataColumnType.List,
      config: {
        minorityType: true,
        listValues
      },
      getCellValue: (row: IMinorityStatusDto) => row.type?.code ?? ""
    };
  }, [minorityTypes]);

  const addBlankRow = () => {
    if (vendor && minorityTypes && minorityTypes.length > 0) {
      const newStatus: IMinorityStatusDto = {
        id: uuid(),
        companyId: vendor.id,
        type: new MinorityTypeDto(minorityTypes[0]),
        certificationNumber: "",
        percent: 0
      };
      setVendor({
        ...vendor,
        minorityStatuses: [...vendor.minorityStatuses, newStatus]
      });
    }
  };

  const changeAddedRows = (value: IMinorityStatusDto[]) => {
    const updatedMinorityList = vendor.minorityStatuses.map(m => {
      if (value.find(v => v.id === m.id)) {
        return value;
      } else {
        return m;
      }
    });
    setVendor({ ...vendor, minorityStatuses: updatedMinorityList });
  };

  const handleCommit = (change: CommandButtonProps) => {
    const { tableRow, id: command } = change;
    if (vendor && minorityTypes && minorityTypes.length > 0) {
      if (command === "add") {
        addBlankRow();
      } else if (command === "delete" && tableRow) {
        if (vendor.minorityStatuses) {
          const index = vendor.minorityStatuses.findIndex(
            m => m.id === tableRow.rowId
          );
          const updatedMinorityList = [...vendor.minorityStatuses];
          updatedMinorityList.splice(index, 1);
          setVendor({ ...vendor, minorityStatuses: updatedMinorityList });
        }
      }
    }
  };

  const createRowChangeHandler = (
    row: any,
    value: string | number,
    columnName: string
  ) => {
    const updatedRow: IMinorityStatusDto = {
      ...row,
      [columnName]: value
    };
    const updatedMinorityList = vendor.minorityStatuses.map(m => {
      if (m.id === updatedRow.id) {
        return updatedRow;
      } else {
        return m;
      }
    });
    setVendor({
      ...vendor,
      minorityStatuses: updatedMinorityList
    });
    return { [columnName]: value };
  };
  if (!vendor || !minorityTypes) {
    return <FullPageLoading loading={true} />;
  }

  const renderCommandComponent = (
    props: React.PropsWithChildren<TableEditColumn.CommandProps>
  ) => {
    if (props.id === "commit") {
      return (
        <CommandComponent
          {...props}
          id="delete"
          onExecute={() =>
            handleCommit({
              ...props,
              id: "delete"
            })
          }
        />
      );
    } else if (props.id === "add") {
      return (
        <CommandComponent
          {...props}
          onExecute={() =>
            handleCommit({
              ...props
            })
          }
        />
      );
    } else {
      return <div></div>;
    }
  };
  return (
    <TableContainer
      columns={[MinorityTypeColumn, ...minorityStatusColumns]}
      sections={[]}
      templateName="contacts.vendor.minoritystatuses.list"
    >
      <TableWrapper height="530px" style={{ position: "relative" }}>
        {isLoading && (
          <ContentLoadingOverlay
            message={strings.contactManagement.minorityStatusList.loading}
          />
        )}
        {minorityTypes.length > 0 &&
        vendor.minorityStatuses &&
        vendor.minorityStatuses.length > 0 ? (
          <Grid
            rootComponent={Root}
            getRowId={getRowId}
            rows={vendor.minorityStatuses}
          >
            <SearchState />
            <SortingState />
            <GroupingState />
            <EditingState
              createRowChange={createRowChangeHandler}
              editingRowIds={editingRowIds}
              onEditingRowIdsChange={setEditingRowIds}
              onAddedRowsChange={changeAddedRows}
              columnExtensions={[
                MinorityTypeColumn,
                ...minorityStatusColumns
              ].map(column => ({
                columnName: column.name,
                editingEnabled: !column.readOnly
              }))}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onCommitChanges={() => {}}
            />
            <LinkProvider />
            <MinorityTypeProvider />
            <MinorityPercentProvider />
            <LegacyVirtualTable estimatedRowHeight={60} />
            <ColumnResizing />
            <TableHeaderRow />
            <TableEditRow />
            <TableEditColumn
              width={75}
              showDeleteCommand
              showEditCommand
              showAddCommand={isLoading ? false : true}
              commandComponent={renderCommandComponent}
              cellComponent={CustomEditCellComponent}
              headerCellComponent={CustomEditCellHeaderComponent}
            />
          </Grid>
        ) : (
          <NoDataPlaceholder
            onClick={addBlankRow}
            helpText={{
              title:
                strings.contactManagement.minorityStatusList
                  .noMinorityStatusHelpText.title,
              body:
                strings.contactManagement.minorityStatusList
                  .noMinorityStatusHelpText.body
            }}
            buttonText={
              strings.contactManagement.minorityStatusList
                .noMinorityStatusButtonText
            }
            isAddEditDisplay={true}
          />
        )}
      </TableWrapper>
    </TableContainer>
  );
};
interface CommandButtonProps extends TableEditColumn.CommandProps {
  icon?: string;
  hint?: string;
  isDanger?: boolean;
  validationFunction?: (tableRow: TableRow) => boolean;
  tableRow?: TableRow;
}

export const formatMinorityStatusPercent = (
  num: string | undefined
): string => {
  if (!num) return "0";
  const split = num.toString().split(".");
  let decimals = ("" + split[1]).replace(/\D/g, "");
  let numbers = ("" + split[0]).replace(/\D/g, "");
  if (numbers.length > 2) {
    numbers = parseInt(numbers) > 100 ? "100" : parseInt(numbers).toString();
    decimals = "";
  }

  if (decimals.length >= 3) {
    decimals = decimals.substring(0, 2);
  }

  if (!numbers && decimals) return `0.${decimals}`;
  if (!numbers) return "0";

  if (decimals) return `${numbers}.${decimals}`;
  return numbers;
};
