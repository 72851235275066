export enum HeavyBidFieldType {
  string,
  date,
  number,
  integer,
  latitudeLongitude
}

export interface HeavyBidFieldListGroup {
  label: string;
  options: HeavyBidFieldListItem[];
}

export interface HeavyBidFieldListItem {
  value: string;
  label: string;
  group: string;
  type: HeavyBidFieldType;
}

export const HBFields: HeavyBidFieldListGroup[] = [
  {
    label: "Estimate Info",
    options: [
      {
        value: "estimateName",
        label: "Estimate Name",
        group: "Estimate Info",
        type: HeavyBidFieldType.string
      },
      {
        value: "estimateDescription",
        label: "Estimate Description",
        group: "Estimate Info",
        type: HeavyBidFieldType.string
      },
      {
        value: "projectName",
        label: "Project Name",
        group: "Estimate Info",
        type: HeavyBidFieldType.string
      },
      // { value: "estimateCode", label: "Estimate Code", group: 'Estimate Info', type: HeavyBidFieldType.string } -- Business Decision to not allow this yet
      {
        value: "projectCode",
        label: "Project #",
        group: "Estimate Info",
        type: HeavyBidFieldType.string
      },
      {
        value: "status",
        label: "Status",
        group: "Estimate Info",
        type: HeavyBidFieldType.string
      },
      {
        value: "state",
        label: "State",
        group: "Estimate Info",
        type: HeavyBidFieldType.string
      },
      {
        value: "owner",
        label: "Owner",
        group: "Estimate Info",
        type: HeavyBidFieldType.string
      },
      {
        value: "jobNotes",
        label: "Job Notes",
        group: "Estimate Info",
        type: HeavyBidFieldType.string
      },
      {
        value: "reviewDate",
        label: "Review Date",
        group: "Estimate Info",
        type: HeavyBidFieldType.date
      },
      {
        value: "bidDate",
        label: "Bid Date",
        group: "Estimate Info",
        type: HeavyBidFieldType.date
      },
      {
        value: "startDate",
        label: "Start Date",
        group: "Estimate Info",
        type: HeavyBidFieldType.date
      },
      {
        value: "estimator",
        label: "Estimator",
        group: "Estimate Info",
        type: HeavyBidFieldType.string
      },
      {
        value: "typeOfWork",
        label: "Type Of Work",
        group: "Estimate Info",
        type: HeavyBidFieldType.string
      },
      {
        value: "engineer",
        label: "Engineer",
        group: "Estimate Info",
        type: HeavyBidFieldType.string
      },
      {
        value: "addendumNumber",
        label: "Addendum Number",
        group: "Estimate Info",
        type: HeavyBidFieldType.integer
      },
      {
        value: "location",
        label: "Location",
        group: "Estimate Info",
        type: HeavyBidFieldType.string
      },
      {
        value: "latitudeLongitude",
        label: "Latitude / Longitude",
        group: "Estimate Info",
        type: HeavyBidFieldType.latitudeLongitude
      }
    ]
  },
  {
    label: "Tags ",
    options: [
      {
        value: "estimateFilter1",
        label: "Tag 1",
        group: "Tags",
        type: HeavyBidFieldType.string
      },
      {
        value: "estimateFilter2",
        label: "Tag 2",
        group: "Tags",
        type: HeavyBidFieldType.string
      },
      {
        value: "estimateFilter3",
        label: "Tag 3",
        group: "Tags",
        type: HeavyBidFieldType.string
      },
      {
        value: "estimateFilter4",
        label: "Tag 4",
        group: "Tags",
        type: HeavyBidFieldType.string
      },
      {
        value: "estimateFilter5",
        label: "Tag 5",
        group: "Tags",
        type: HeavyBidFieldType.string
      },
      {
        value: "estimateFilter6",
        label: "Tag 6",
        group: "Tags",
        type: HeavyBidFieldType.string
      }
    ]
  },
  {
    label: "User Defined Dates",
    options: [
      {
        value: "userDate1",
        label: "User Defined Date 1",
        group: "User Defined Dates",
        type: HeavyBidFieldType.date
      },
      {
        value: "userDate2",
        label: "User Defined Date 2",
        group: "User Defined Dates",
        type: HeavyBidFieldType.date
      },
      {
        value: "userDate3",
        label: "User Defined Date 3",
        group: "User Defined Dates",
        type: HeavyBidFieldType.date
      },
      {
        value: "userDate4",
        label: "User Defined Date 4",
        group: "User Defined Dates",
        type: HeavyBidFieldType.date
      },
      {
        value: "userDate5",
        label: "User Defined Date 5",
        group: "User Defined Dates",
        type: HeavyBidFieldType.date
      }
    ]
  },
  {
    label: "User Defined Integers",
    options: [
      {
        value: "userInteger1",
        label: "User Defined Integer 1",
        group: "User Defined Integers",
        type: HeavyBidFieldType.integer
      },
      {
        value: "userInteger2",
        label: "User Defined Integer 2",
        group: "User Defined Integers",
        type: HeavyBidFieldType.integer
      },
      {
        value: "userInteger3",
        label: "User Defined Integer 3",
        group: "User Defined Integers",
        type: HeavyBidFieldType.integer
      },
      {
        value: "userInteger4",
        label: "User Defined Integer 4",
        group: "User Defined Integers",
        type: HeavyBidFieldType.integer
      },
      {
        value: "userInteger5",
        label: "User Defined Integer 5",
        group: "User Defined Integers",
        type: HeavyBidFieldType.integer
      }
    ]
  },
  {
    label: "User Defined Numbers",
    options: [
      {
        value: "userNumber1",
        label: "User Defined Number 1",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber2",
        label: "User Defined Number 2",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber3",
        label: "User Defined Number 3",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber4",
        label: "User Defined Number 4",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber5",
        label: "User Defined Number 5",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber6",
        label: "User Defined Number 6",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber7",
        label: "User Defined Number 7",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber8",
        label: "User Defined Number 8",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber9",
        label: "User Defined Number 9",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber10",
        label: "User Defined Number 10",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber11",
        label: "User Defined Number 11",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber12",
        label: "User Defined Number 12",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber13",
        label: "User Defined Number 13",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber14",
        label: "User Defined Number 14",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber15",
        label: "User Defined Number 15",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber16",
        label: "User Defined Number 16",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber17",
        label: "User Defined Number 17",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber18",
        label: "User Defined Number 18",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber19",
        label: "User Defined Number 19",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      },
      {
        value: "userNumber20",
        label: "User Defined Number 20",
        group: "User Defined Numbers",
        type: HeavyBidFieldType.number
      }
    ]
  },

  {
    label: "User Defined Text",
    options: [
      {
        value: "userAscii1",
        label: "User Defined Text 1",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii2",
        label: "User Defined Text 2",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii3",
        label: "User Defined Text 3",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii4",
        label: "User Defined Text 4",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii5",
        label: "User Defined Text 5",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii6",
        label: "User Defined Text 6",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii7",
        label: "User Defined Text 7",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii8",
        label: "User Defined Text 8",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii9",
        label: "User Defined Text 9",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii10",
        label: "User Defined Text 10",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii11",
        label: "User Defined Text 11",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii12",
        label: "User Defined Text 12",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii13",
        label: "User Defined Text 13",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii14",
        label: "User Defined Text 14",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii15",
        label: "User Defined Text 15",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii16",
        label: "User Defined Text 16",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii17",
        label: "User Defined Text 17",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii18",
        label: "User Defined Text 18",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii19",
        label: "User Defined Text 19",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      },
      {
        value: "userAscii20",
        label: "User Defined Text 20",
        group: "User Defined Text",
        type: HeavyBidFieldType.string
      }
    ]
  }
  // Not available to users in HeavyBid
  // {
  //     label: 'User Defined Percent', options: [
  //         { value: "userPerc1", label: "User Defined Percent 1", group: 'User Defined Percent', type: HeavyBidFieldType.number },
  //         { value: "userPerc2", label: "User Defined Percent 2", group: 'User Defined Percent' , type: HeavyBidFieldType.number}],
  // },
  // {
  //     label: 'User Defined Flag', options: [
  //         { value: "userFlag1", label: "User Defined Flag 1", group: 'User Defined Flag', type: HeavyBidFieldType.string },
  //         { value: "userFlag2", label: "User Defined Flag 2", group: 'User Defined Flag', type: HeavyBidFieldType.string },
  //         { value: "userFlag3", label: "User Defined Flag 3", group: 'User Defined Flag', type: HeavyBidFieldType.string },
  //         { value: "userFlag4", label: "User Defined Flag 4", group: 'User Defined Flag', type: HeavyBidFieldType.string },
  //         { value: "userFlag5", label: "User Defined Flag 5", group: 'User Defined Flag', type: HeavyBidFieldType.string },
  //         { value: "userFlag6", label: "User Defined Flag 6", group: 'User Defined Flag', type: HeavyBidFieldType.string },
  //         { value: "userFlag7", label: "User Defined Flag 7", group: 'User Defined Flag', type: HeavyBidFieldType.string },
  //         { value: "userFlag8", label: "User Defined Flag 8", group: 'User Defined Flag', type: HeavyBidFieldType.string },
  //         { value: "userFlag9", label: "User Defined Flag 9", group: 'User Defined Flag', type: HeavyBidFieldType.string },
  //         { value: "userFlag10", label: "User Defined Flag 10", group: 'User Defined Flag', type: HeavyBidFieldType.string },
  //         { value: "userFlag11", label: "User Defined Flag 11", group: 'User Defined Flag', type: HeavyBidFieldType.string },
  //         { value: "userFlag12", label: "User Defined Flag 12", group: 'User Defined Flag' , type: HeavyBidFieldType.string}],
  // },
  // {
  //     label: 'User Defined Vendor', options: [
  //         { value: "userVendor1", label: "User Defined Vendor 1", group: 'User Defined Vendor', type: HeavyBidFieldType.string },
  //         { value: "userVendor2", label: "User Defined Vendor 2", group: 'User Defined Vendor', type: HeavyBidFieldType.string },
  //         { value: "userVendor3", label: "User Defined Vendor 3", group: 'User Defined Vendor', type: HeavyBidFieldType.string },
  //         { value: "userVendor4", label: "User Defined Vendor 4", group: 'User Defined Vendor', type: HeavyBidFieldType.string },
  //         { value: "userVendor5", label: "User Defined Vendor 5", group: 'User Defined Vendor', type: HeavyBidFieldType.string }]
  // }
];
