import { SchemaField, SchemaFieldType } from "api";
import { PreConId } from "./helpers";

export const fieldValueExist = (
  field: Pick<SchemaField, "type">,
  value: any
) => {
  switch (field.type) {
    case SchemaFieldType.Number:
    case SchemaFieldType.Currency: {
      return !!value || value === 0;
    }

    case SchemaFieldType.Company:
    case SchemaFieldType.Contact:
      return !!value && (!Array.isArray(value) || value.length > 0);

    case SchemaFieldType.MultiSelectList:
      return !!value && value.length > 0;

    case SchemaFieldType.Boolean:
      return value != null;

    default: {
      return !!value;
    }
  }
};
const getEmptyFieldValue = (field: SchemaField) => {
  switch (field.type) {
    case SchemaFieldType.ShortText:
    case SchemaFieldType.LongText: {
      return "";
    }
    case SchemaFieldType.Checklist: {
      return field.config.checklistValues ?? null;
    }
    default: {
      return null;
    }
  }
};

export interface InitProjectFieldsData {
  columns?: SchemaField[];
  existingValues?: { [key: string]: any };
  preconId?: PreConId | null;
  isNewProject?: boolean;
}
export const initProjectFields = (data: InitProjectFieldsData) => {
  const {
    preconId,
    columns = [],
    existingValues = {},
    isNewProject = false
  } = data;
  const initialValues: { [key: string]: any } = {};

  for (const column of columns) {
    const existValue = existingValues[column.id];

    if (fieldValueExist(column, existValue)) {
      initialValues[column.id] = existValue;
    } else {
      const useDefaultValue =
        isNewProject &&
        column.useDefaultValue &&
        fieldValueExist(column, column.defaultValue);

      if (useDefaultValue) {
        initialValues[column.id] = column.defaultValue;
      } else {
        initialValues[column.id] = getEmptyFieldValue(column);
      }
    }
  }

  const pId = isNewProject ? preconId : existingValues["preconId"];

  initialValues["preconId"] = pId ?? null;

  return initialValues;
};

export const initNewProjectFields = (
  columns: SchemaField[] = [],
  preconId: PreConId | null = null
) => initProjectFields({ columns, preconId, isNewProject: true });
