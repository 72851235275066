// tslint:disable:max-classes-per-file

import * as React from "react";
import styled from "styled-components";
import { debounce } from "lodash-es";
import { ExpandableContentStatus } from "../../models/enums";

export interface ModalPanelHeaderProps {
  onHide: (event: any) => void;
  panelTitle: React.ReactNode;
}
export class ModalPanelHeader extends React.PureComponent<
  ModalPanelHeaderProps
> {
  handleHidePanelRequested = (event: any) => {
    this.props.onHide(event);
  };

  render() {
    return (
      <StyledModalPanelHeader className="modal-panel-header">
        <StyledModalPanelCloseButton
          className="modal-panel-close-button"
          onClick={this.handleHidePanelRequested}
        >
          <i className="fa fa-times" />
        </StyledModalPanelCloseButton>
        <StyledModalPanelTitle className="modal-panel-title">
          {this.props.panelTitle}
        </StyledModalPanelTitle>
      </StyledModalPanelHeader>
    );
  }
}

export class ModalPanelBody extends React.PureComponent {
  render() {
    return (
      <StyledModalPanelBody className="modal-panel-body">
        {this.props.children}
      </StyledModalPanelBody>
    );
  }
}

export class ModalPanelFooter extends React.PureComponent {
  render() {
    return (
      <StyledModalPanelFooter className="modal-panel-footer">
        {this.props.children}
      </StyledModalPanelFooter>
    );
  }
}

export interface ModalPanelProps {
  className?: string;
  isActive: boolean;
  onHide: () => void;
}
export interface ModalPanelState {
  panelStatus: ExpandableContentStatus;
}
export class ModalPanel extends React.PureComponent<
  ModalPanelProps,
  ModalPanelState
> {
  static Header = ModalPanelHeader;
  static Body = ModalPanelBody;
  static Footer = ModalPanelFooter;

  readonly state = {
    panelStatus: ExpandableContentStatus.collapsed
  };

  componentDidUpdate(prevProps: ModalPanelProps, prevState: ModalPanelState) {
    if (prevProps.isActive !== this.props.isActive) {
      document.addEventListener("keydown", this.handleKeyPressed, false);
      if (this.props.isActive) {
        this.setState(
          { panelStatus: ExpandableContentStatus.expanding },
          debounce(
            () =>
              this.setState({ panelStatus: ExpandableContentStatus.expanded }),
            50
          )
        );
      } else {
        document.removeEventListener("keydown", this.handleKeyPressed, false);
        this.setState(
          { panelStatus: ExpandableContentStatus.collapsing },
          debounce(
            () =>
              this.setState({ panelStatus: ExpandableContentStatus.collapsed }),
            425
          )
        );
      }
    }
  }

  handleHidePanelRequested = () => {
    this.props.onHide();
  };

  handleKeyPressed = (event: any) => {
    if (event.key === "Escape") {
      this.handleHidePanelRequested();
    }
  };

  render() {
    return (
      this.state.panelStatus !== ExpandableContentStatus.collapsed && (
        <StyledModalPanel
          className={`modal-panel${
            this.props.className ? ` ${this.props.className}` : ""
          }`}
          data-panel-status={this.state.panelStatus.toString()}
        >
          <StyledModalPanelOverlayScreen
            className="modal-panel-overlay-screen"
            onClick={this.handleHidePanelRequested}
          />
          <StyledModalPanelContent className="modal-panel-content">
            {this.props.children}
          </StyledModalPanelContent>
        </StyledModalPanel>
      )
    );
  }
}

export const StyledModalPanel = styled("div")`
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &[data-panel-status="expanding"] {
    & > .modal-panel-overlay-screen {
      opacity: 0;
    }
    & > .modal-panel-content {
      right: -500px;
    }
  }
  &[data-panel-status="expanded"] {
    .modal-panel-overlay-screen {
      opacity: 1;
    }
    & > .modal-panel-content {
      right: 0;
    }
  }
  &[data-panel-status="collapsing"] {
    .modal-panel-overlay-screen {
      opacity: 0;
    }
    & > .modal-panel-content {
      right: -500px;
    }
  }
  &[data-panel-status="collapsed"] {
    .modal-panel-overlay-screen {
      opacity: 0;
    }
    & > .modal-panel-content {
      right: -500px;
    }
  }
`;

const StyledModalPanelOverlayScreen = styled("div")`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1090;
  transition: 0.3s;
`;

const StyledModalPanelContent = styled("div")`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  position: absolute;
  width: 480px;
  top: 0;
  height: 100%;
  background: #ffffff;
  box-shadow: 0 0 20px -5px #000000;
  z-index: 1100;
  transition: 0.4s;
`;

const StyledModalPanelHeader = styled("div")`
  position: relative;
  padding: 36px 20px 16px 20px;
  background: #f6f6f6;
`;

const StyledModalPanelCloseButton = styled("div")`
  display: inline-block;
  position: absolute;
  top: 36px;
  right: 20px;
  font-size: 20px;
  color: #707070;
  line-height: 24px;
  vertical-align: middle;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: #1e1e1e;
  }
`;

const StyledModalPanelTitle = styled("h3")`
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  vertical-align: middle;
  margin: 0;
`;

const StyledModalPanelBody = styled("div")`
  height: 100px;
  flex-grow: 1;
  margin-bottom: 16px;
  padding: 0 20px;
  overflow: auto;
`;

const StyledModalPanelFooter = styled("div")`
  width: 100%;
  margin-bottom: 24px;
  padding: 0 20px 20px 20px;
  background: #ffffff;
  text-align: center;
`;
