import * as React from "react";

export interface PaneledPageContextState {
  maxPageWidth: number | null;
  contentAlign: "center" | "left";
  asideWidth: number;
  setAsideWidth: (updatedWidth: number | undefined) => void;
  filtersPanelExpanded: boolean;
  filtersHasOpenDropdown: boolean;
  activeDropdownId: string | null;
  onToggleFiltersPanel: (event?: any) => void;
  onDefaultExpandFilters: () => void;
  onClickInsideFilters: (event?: any) => void;
  onClickInsideContent: (filtersPanelShouldHide: boolean) => void;
}
export const PaneledPageContext = React.createContext<PaneledPageContextState>(
  {} as PaneledPageContextState
);
