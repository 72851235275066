import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import { ConcreteColors } from "hcss-components";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

export const StyledTableCell = styled(TableCell)`
  padding: 0 16px;
  color: #555;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid rgba(217, 217, 217, 0.6);
  box-sizing: border-box;

  &.sticky-left {
    position: sticky;
    left: 0;
    border-right: 1px solid rgba(217, 217, 217, 0.6);
    zindex: 9999 !important;
  }

  &.sticky-right {
    position: sticky;
    right: 0;
    border-left: 1px solid rgba(217, 217, 217, 0.6);
    zindex: 9999 !important;
  }
`;
export const TableHeaderLabel = ({
  children,
  fontSize
}: {
  children: string;
  fontSize?: number;
}) => (
  <Typography
    fontSize={`${fontSize ?? 16}px`}
    color="#555"
    textTransform="uppercase"
    fontWeight={700}
  >
    {children}
  </Typography>
);

export const StyledTableRow = styled(TableRow)`
  background: #fff;
  height: 48px;
  border: none;
`;

export const StyledTableHead = styled(TableHead)`
  height: 48px;
`;

export const StyledFixedTable = styled(Table)`
  table-layout: fixed;
  overflow: auto;
  height: 1px;
`;

export const CellContainer = ({
  children,
  tootlip,
  style
}: {
  children: React.ReactNode | React.ReactNode[];
  tootlip?: React.ReactNode | React.ReactNode[];
  style?: React.CSSProperties;
}) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!textElementRef.current) {
      return;
    }

    setIsOverflow(
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth
    );
  }, []);

  return (
    <Tooltip
      title={tootlip ?? children}
      disableHoverListener={!tootlip && !isOverflow}
    >
      <Typography
        ref={textElementRef}
        noWrap
        overflow={"hidden"}
        textOverflow={"ellipsis"}
        color={ConcreteColors.gray600}
        style={style}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};
