import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHcssToken, useHcssUser } from "modules/account";
import { useHistory, useLocation } from "react-router-dom";
import { FullPageLoading } from "modules/layout/components/FullPageLoading";
import {
  PaneledPage,
  Button,
  Icon,
  Link as HLink,
  notify
} from "hcss-components";
import { DropdownList } from "react-widgets";
import {
  Section,
  SectionHeader,
  SectionTitle,
  NoDataPlaceholder,
  ContentLoadingOverlay,
  UserType,
  isValidString,
  isNullOrWhitespace
} from "core";
import { VendorMainInfo } from "./vendor-main-info";
import { VendorScopeOfWork } from "./vendor-scopes-of-work";
import { MinorityStatusList } from "./minority-status-list";
import { strings } from "localization";
import NavigationPrompt from "react-router-navigation-prompt";
import ConfirmBack from "../../../core/components/modals/ConfirmBack";
import { isEqual, sortBy } from "lodash-es";

import {
  CommunicationMethod,
  IAddress
} from "api/GeneratedClients/ContactsClient";
import { useSaveVendorDetails } from "../hooks/use-save-vendor-details";
import { useSelector } from "react-redux";
import { selectors } from "modules/contacts";
import { IVendorLocationDto } from "../../../api/contacts-api-dto-interfaces";
import uuid from "uuid";
import { VendorLocationDetailInfo } from "./vendor-location-detail-info";
import { useVendorLocationDropDown } from "../hooks/use-vendor-location-drop-down";
import { useGetSelectedVendor } from "../hooks/use-get-selected-vendor";

export interface VendorLocationOption {
  display: string;
  value: IVendorLocationDto;
}

export const VendorEditDetails = () => {
  const token = useHcssToken();
  const user = useHcssUser();
  const history = useHistory();
  const location = useLocation();

  const [showWhitespaceError, setShowWhitespaceError] = useState<boolean>(
    false
  );
  const [showDuplicateError, setShowDuplicateError] = useState<boolean>(false);
  const [isValidNickname, setIsValidNickname] = useState<boolean>(true);
  const [nextLocation, setNextLocation] = useState<string>();
  const [vendorNickname, setVendorNickname] = useState<string>("");

  const vendor = useGetSelectedVendor();

  const {
    localVendor,
    locationOptions,
    vendorLocation,
    setLocalVendor,
    setEditingLocationId,
    setVendorLocation
  } = useVendorLocationDropDown(vendor);

  const isLoading = useSelector(selectors.getLoading);
  const { handleSaveChanges } = useSaveVendorDetails({
    vendorNickname
  });

  const hasVendorLocation: boolean =
    !!localVendor &&
    !!localVendor.locations &&
    localVendor.locations.length > 0;

  useEffect(() => {
    if (!isLoading) {
      setLocalVendor(vendor);
    }
  }, [token, isLoading, vendor, setLocalVendor]);

  if (!localVendor) {
    return <FullPageLoading loading={true} />;
  }

  const handleVendorLocationChange = (data: VendorLocationOption) => {
    setEditingLocationId(data.value.id);
  };

  const displayVendorLocation = hasVendorLocation && !!vendorLocation;

  const handleOnAddNewVendorLocation = () => {
    const nullNickNameLocation = localVendor.locations?.find(
      location =>
        !isValidString(location.nickname) &&
        !location.isPrimary &&
        !location.isAlternate
    );
    const location = shouldCreateNewEmptyLocation(
      nullNickNameLocation,
      vendorLocation
    )
      ? initializeVendorLocation(localVendor.id)
      : nullNickNameLocation;
    if (vendorLocation) {
      updateVendorLocation(vendorLocation);
    }
    if (location) {
      setEditingLocationId(location.id);
      addVendorLocation(location);
    }
  };

  const updateVendorLocation = (vendorLocation: IVendorLocationDto) => {
    const filtered = localVendor.locations?.filter(
      loc => loc.id !== vendorLocation.id
    );
    if (!filtered) return;
    const updatedLocations = [...filtered, vendorLocation];
    const sortedLocations = sortVendorLocations(updatedLocations);
    setLocalVendor({ ...localVendor, locations: sortedLocations });
  };

  const addVendorLocation = (vendorLocation: IVendorLocationDto) => {
    if (!localVendor.locations) return;
    if (localVendor.locations.find(loc => loc.id === vendorLocation.id)) return;
    const updatedLocations = [...localVendor.locations, vendorLocation];
    const sortedLocations = sortVendorLocations(updatedLocations);
    setLocalVendor({ ...localVendor, locations: sortedLocations });
  };

  const handleValidateAndSave = () => {
    const validationFlags = handleSaveChanges(
      localVendor,
      displayVendorLocation,
      vendorLocation
    );

    if (validationFlags) {
      setShowWhitespaceError(validationFlags.isNullOrWhitespace);
      setShowDuplicateError(validationFlags.isDuplicateVendorCode);
      setIsValidNickname(validationFlags.isValidVendorNickname);

      return validationFlags;
    } else {
      setShowWhitespaceError(false);
      setShowDuplicateError(false);
      setIsValidNickname(true);
    }
  };

  return (
    <>
      <PaneledPage>
        <PaneledPage.Header>
          <PaneledPage.Header.Title>
            {strings.contactManagement.vendorDetails.editTitle}
          </PaneledPage.Header.Title>
          <PaneledPage.Header.Tools>
            <HLink
              onClick={() => history.replace(`/contacts/${localVendor.id}`)}
              style={{ marginRight: "2rem" }}
            >
              <Icon name="arrow-left" /> {strings.projects.details.header.back}
            </HLink>
            <Button
              onClick={() => {
                const validationResults = handleValidateAndSave();

                if (validationResults) {
                  if (validationResults.isNullOrWhitespace) {
                    notify(
                      "danger",
                      strings.contactManagement.vendorEditDetail.error.title,
                      strings.contactManagement.vendorEditDetail.error
                        .whiteSpaceName
                    );
                  }
                  if (validationResults.isDuplicateVendorCode) {
                    notify(
                      "danger",
                      strings.contactManagement.vendorEditDetail.error.title,
                      strings.contactManagement.vendorEditDetail.error
                        .duplicateName
                    );
                  }
                  if (!validationResults.isValidVendorNickname) {
                    notify(
                      "danger",
                      strings.contactManagement.vendorEditDetail.error.title,
                      strings.contactManagement.vendorEditDetail.error.name
                    );
                  }
                }
              }}
            >
              <span>
                <Icon name="save" margin="right" />
                {strings.contactManagement.vendorDetails.save}
              </span>
            </Button>
          </PaneledPage.Header.Tools>
        </PaneledPage.Header>
        <PaneledPage.Content>
          <div style={{ display: "flex" }}>
            <InfoContainer isGC={user.type === UserType.HcssSubscription}>
              <Section style={{ marginBottom: "12px", position: "relative" }}>
                {isLoading && <ContentLoadingOverlay />}
                <VendorMainInfo
                  vendor={localVendor}
                  setVendor={setLocalVendor}
                  showErrors={showWhitespaceError}
                  showDuplicateError={showDuplicateError}
                />
              </Section>
              <Section style={{ position: "relative" }}>
                {isLoading && <ContentLoadingOverlay />}
                <SectionHeader>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <SectionTitle>
                      {
                        strings.contactManagement.vendorEditDetail
                          .officeSectionTitle
                      }
                    </SectionTitle>
                    {hasVendorLocation && (
                      <>
                        <div
                          id="office-location-dropdown"
                          style={{ width: "40%" }}
                        >
                          <DropdownList
                            filter="contains"
                            textField="display"
                            valueField="id"
                            value={
                              !isNullOrWhitespace(vendorLocation?.nickname)
                                ? { id: vendorLocation?.id }
                                : null
                            }
                            data={locationOptions}
                            disabled={!isValidNickname}
                            onChange={handleVendorLocationChange}
                            placeholder=""
                          />
                        </div>
                        <Button
                          style={{ padding: "0rem 1.2rem", height: "3rem" }}
                          disabled={!isValidNickname}
                          onClick={handleOnAddNewVendorLocation}
                        >
                          <Icon name="plus" margin="right" />
                          {
                            strings.contactManagement.vendorEditDetail
                              .addOfficeButton
                          }
                        </Button>
                      </>
                    )}
                  </div>
                </SectionHeader>
                {displayVendorLocation ? (
                  <VendorLocationDetailInfo
                    vendor={localVendor}
                    vendorLocation={vendorLocation}
                    setVendor={setLocalVendor}
                    isValidNickname={isValidNickname}
                    setEditingLocationId={setEditingLocationId}
                    setVendorLocation={setVendorLocation}
                    setVendorNickname={setVendorNickname}
                  />
                ) : (
                  <NoDataPlaceholder
                    onClick={handleOnAddNewVendorLocation}
                    helpText={{
                      title:
                        strings.contactManagement.noDataPlaceholder.office.title
                    }}
                    buttonText={
                      strings.contactManagement.noDataPlaceholder.office
                        .buttonText
                    }
                    isAddEditDisplay={true}
                  />
                )}
              </Section>
              {user.type === UserType.HcssSubscription && (
                <Section style={{ position: "relative" }}>
                  {isLoading && <ContentLoadingOverlay />}
                  <SectionHeader>
                    <SectionTitle>
                      {strings.contact.scopeOfWork.scopesOfWork}
                    </SectionTitle>
                  </SectionHeader>
                  <VendorScopeOfWork
                    vendor={localVendor}
                    setVendor={setLocalVendor}
                    showErrors={showWhitespaceError}
                  />
                </Section>
              )}
            </InfoContainer>
            {user.type === UserType.HcssSubscription && (
              <Section style={{ width: "47%" }}>
                <SectionHeader>
                  <SectionTitle>
                    {strings.contact.minorityStatus.minorityStatuses}
                  </SectionTitle>
                </SectionHeader>
                <MinorityStatusList
                  vendor={localVendor}
                  setVendor={setLocalVendor}
                />
              </Section>
            )}
          </div>
        </PaneledPage.Content>
      </PaneledPage>
      <NavigationPrompt
        when={(_, next) => {
          setNextLocation(next?.pathname);
          return (
            location.pathname !== next?.pathname &&
            !isLoading &&
            !isEqual(localVendor, vendor)
          );
        }}
        afterConfirm={() => {
          const location = {
            pathname: nextLocation ?? `/contacts`,
            state: { hasUpdated: true }
          };
          history.replace(location);
        }}
        allowGoBack
      >
        {({ onConfirm, onCancel }) => {
          return (
            <ConfirmBack
              show={true}
              handleClose={onCancel}
              handleBack={onConfirm}
              handleSave={() => {
                const validationResults = handleValidateAndSave();

                if (validationResults) {
                  if (validationResults.isNullOrWhitespace) {
                    notify(
                      "danger",
                      strings.contactManagement.vendorEditDetail.error.title,
                      strings.contactManagement.vendorEditDetail.error
                        .whiteSpaceName
                    );
                  }
                  if (validationResults.isDuplicateVendorCode) {
                    notify(
                      "danger",
                      strings.contactManagement.vendorEditDetail.error.title,
                      strings.contactManagement.vendorEditDetail.error
                        .duplicateName
                    );
                  }
                  if (!validationResults.isValidVendorNickname) {
                    notify(
                      "danger",
                      strings.contactManagement.vendorEditDetail.error.title,
                      strings.contactManagement.vendorEditDetail.error.name
                    );
                  }

                  onCancel();
                } else {
                  onConfirm();
                }
              }}
            >
              {strings.solicitations.setup.confirmBack}
            </ConfirmBack>
          );
        }}
      </NavigationPrompt>
    </>
  );
};

export default VendorEditDetails;

const InfoContainer = styled.div<{ isGC: boolean }>`
  display: flex;
  width: ${props => (props.isGC ? "53%" : "100%")};

  flex-direction: column;
  margin-right: 16px;
`;

const initializeVendorLocation = (vendorId: string): IVendorLocationDto => {
  const address: IAddress = {
    addressLine1: "",
    addressLine2: "",
    building: "",
    city: "",
    stateProvince: "",
    countryRegion: "",
    postalCode: ""
  };

  return {
    vendorId: vendorId,
    id: uuid(),
    address: address,
    phoneNumber: "",
    faxNumber: "",
    webAddress: "",
    nickname: "",
    communicationMethod: CommunicationMethod.None
  };
};

const shouldCreateNewEmptyLocation = (
  nullNickNameLocation: IVendorLocationDto | undefined,
  vendorLocation: IVendorLocationDto | undefined
) => {
  return (
    nullNickNameLocation === undefined ||
    (!isNullOrWhitespace(vendorLocation?.nickname) &&
      nullNickNameLocation.id === vendorLocation?.id)
  );
};
export const sortVendorLocations = (locations: IVendorLocationDto[]) => {
  if (!locations || locations.length === 0) return;
  return sortBy(locations, function (loc) {
    return loc.nickname.toLowerCase();
  });
};
