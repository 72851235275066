import { BusinessUnit } from "api/GeneratedClients/precon";
import { DataColumnType, TypedDataColumn } from "hcss-tables";

export interface BUSelect extends BusinessUnit {
  selected: boolean;
  home: boolean;
}
interface CompareValue {
  home: boolean;
  code: string;
}

const CodeColumn: TypedDataColumn = {
  name: "code",
  title: "Code",
  type: DataColumnType.Custom,
  getCellValue: (row: BUSelect) => {
    return { home: row.home, code: row.code };
  },
  config: {
    sortingFunction: (a: CompareValue, b: CompareValue) => {
      const priorityA = a.code.toLowerCase();
      const priorityB = b.code.toLowerCase();
      if (priorityA === priorityB) return 0;
      return priorityA < priorityB ? -1 : 1;
    },
    valueAsString: (value: CompareValue) => value.code
  }
};
const DescriptionColumn: TypedDataColumn = {
  name: "description",
  title: "Description",
  type: DataColumnType.ShortText,
  getCellValue: (row: BUSelect) => row.description ?? ""
};

export const businessUnitColumns: TypedDataColumn[] = [
  CodeColumn,
  DescriptionColumn
];
