import React, { useEffect, useState } from "react";
import { usePreferences, actions } from "modules/account";
import { useDispatch } from "react-redux";
import { languages, strings } from "localization";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import TranslationIcon from "@mui/icons-material/Translate";

export interface LanguageListItem {
  value: string;
  label: string;
}

export const LanguageMenuItem: React.FC = () => {
  const dispatch = useDispatch();
  const userPreferences = usePreferences();
  const [language, setLanguage] = useState(userPreferences.language);

  useEffect(() => {
    if (language !== userPreferences.language) {
      window.location.reload();
    }
  }, [language, userPreferences]);

  const handleInputChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setLanguage(value);
    dispatch(
      actions.updatePreferences.request({
        ...userPreferences,
        language: value
      })
    );
  };

  const options: LanguageListItem[] = Object.values(languages)
    .filter(language => language.name !== null)
    .map(
      (language): LanguageListItem => ({
        value: language.id,
        label: language.name as string
      })
    );

  const menuItems = options.map((option: LanguageListItem) => (
    <MenuItem
      key={option.value}
      value={option.value}
      data-testid={`language-select__option-${option.value}`}
    >
      {option.label}
    </MenuItem>
  ));

  const selectedOption = options.find(
    lan => lan.value === (language ? language : "en")
  );

  return (
    <div>
      <FormControl fullWidth size="small">
        <InputLabel id="language-select" data-testid="language-select-title">
          {strings.layout.profile.language}
        </InputLabel>
        <Select
          startAdornment={
            <InputAdornment position="start">
              <TranslationIcon />
            </InputAdornment>
          }
          labelId="language-select"
          id="language-select"
          data-testid="language-select"
          value={selectedOption?.value}
          label={strings.layout.profile.language}
          onChange={event => handleInputChange(event)}
        >
          {menuItems}
        </Select>
      </FormControl>
    </div>
  );
};

export default LanguageMenuItem;
