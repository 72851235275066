import createUseContext from "constate";
import { oidcService, OidcService } from "./oidc-service";

interface OidcContext {
  oidcService: OidcService;
}
export const useOidcContext = createUseContext(
  (): OidcContext => {
    return { oidcService };
  }
);

export const OidcProvider = useOidcContext.Provider;
