import React from "react";
import { IMinorityTypeDto } from "api/GeneratedClients/ContactsClient";
import { Button } from "hcss-components";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid as MuiGrid
} from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { IMinorityTypeOption } from "../../tables/columns/column-providers";
import { strings } from "localization";
import styled from "styled-components";

const validationSchema = yup.object({
  code: yup
    .string()
    .required(
      strings.contactManagement.minorityTypeModal.validation.codeRequiredText
    ),
  description: yup
    .string()
    .required(
      strings.contactManagement.minorityTypeModal.validation
        .descriptionRequiredText
    )
});

interface ModalProps {
  visible: boolean;
  setVisible: (val: boolean) => void;
  prefillValue: string;
  addMinorityType: (val: IMinorityTypeOption) => void;
}

export const CreateMinorityTypeModal = ({
  visible,
  setVisible,
  prefillValue,
  addMinorityType
}: ModalProps) => {
  const handleClose = () => {
    setVisible(false);
  };

  const handleSubmit = (values: any) => {
    const newMinorityType: IMinorityTypeDto = {
      code: values.code.toUpperCase().trim(),
      description: values.description
    };
    addMinorityType({
      value: newMinorityType,
      label: newMinorityType.code ?? ""
    });
    handleClose();
  };

  return (
    <Dialog open={visible} onClose={handleClose}>
      <Formik
        initialValues={{ code: prefillValue, description: "" }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {formik => (
          <Form>
            <DialogTitle>
              <MuiGrid container justifyContent="space-between">
                <MuiGrid item>
                  <h3 style={{ margin: "0.5rem" }}>
                    {strings.contactManagement.minorityTypeModal.title}
                  </h3>
                </MuiGrid>
                <MuiGrid item>
                  <TimesButton
                    type="button"
                    className="close"
                    onClick={handleClose}
                  >
                    <span aria-hidden="true">×</span>
                  </TimesButton>
                </MuiGrid>
              </MuiGrid>
            </DialogTitle>
            <DialogContent>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" }
                }}
              >
                <TextField
                  required
                  id="code"
                  label="Code"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}
                  {...formik.getFieldProps("code")}
                />
                <TextField
                  required
                  id="description"
                  label="Description"
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                  {...formik.getFieldProps("description")}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Box sx={{ width: "100%", padding: 1 }}>
                <MuiGrid container justifyContent="space-between">
                  <MuiGrid item>
                    <Button onClick={handleClose} hcssStyle="ThemeInverted">
                      {strings.contactManagement.minorityTypeModal.buttonCancel}
                    </Button>
                  </MuiGrid>
                  <MuiGrid item>
                    <Button type="submit">
                      {strings.contactManagement.minorityTypeModal.buttonAdd}
                    </Button>
                  </MuiGrid>
                </MuiGrid>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const TimesButton = styled.button`
  button.close {
    color: "Black")};
    opacity: 0.4;
    font-size: 28px;
    :hover {
      opacity: 0.9;
  }
}
`;
