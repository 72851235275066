import { SchemaField } from "api";
import { getFieldValueAsArray, sortValues } from "core";
import { strings } from "localization";
import { sortBy } from "lodash-es";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Multiselect } from "react-widgets";
import styled from "styled-components";
import { SchemaFormFieldInputProps } from "./common";

const CLEAR_TEXT = strings.core.dropdown.clear;
interface MultiSelectListOption {
  display: string;
  value?: string;
}
export interface MultiSelectFieldInputProps {
  schemaField: SchemaField;
  value?: string | string[];
  options?: MultiSelectListOption[];
  allowCreate?: boolean;
  autoFocus?: boolean;
  handleBlur?: React.FocusEventHandler;
  hasErrors?: boolean;
  handleChange: (data: string[]) => void;
  addFieldValue?: (value: string) => void;
  disabled?: boolean;
  keepInvalidValues?: boolean;
}

export const MultiSelectFieldInput = (props: MultiSelectFieldInputProps) => {
  const options =
    props.options ??
    (props.schemaField.config.listValues as MultiSelectListOption[]);
  const {
    schemaField,
    value,
    allowCreate,
    handleBlur,
    handleChange,
    addFieldValue,
    autoFocus,
    disabled,
    hasErrors,
    keepInvalidValues
  } = props;

  const sortedValue = useMemo(
    () =>
      sortValues(
        getFieldValueAsArray(value ?? []),
        options,
        schemaField.config.customSort
      ),
    [options, schemaField.config.customSort, value]
  );

  const displayOptions = useMemo(() => {
    let data = options;
    if (!schemaField.config.customSort) {
      data = sortBy(data, opt => opt.display.toLocaleLowerCase());
    }

    if (value?.length) {
      data = data.concat([{ display: CLEAR_TEXT }]);
    }

    return data;
  }, [options, schemaField.config.customSort, value]);

  useEffect(() => {
    if (keepInvalidValues || allowCreate || !value?.length) {
      return;
    }

    const selectedValues: string[] = [];

    if (Array.isArray(value)) {
      selectedValues.push(...value);
    } else {
      selectedValues.push(value);
    }

    const existSelectedOptions = selectedValues.filter(v =>
      options.find(o => o.value === v)
    );

    if (existSelectedOptions.length !== selectedValues.length) {
      handleChange(existSelectedOptions);
    }
  }, [options, allowCreate, value, handleChange, keepInvalidValues]);

  const onChange = useCallback(
    (dataItems: (MultiSelectListOption | string)[]) => {
      // display property can be undefined if user changed List Values in project setups
      let selectedValues = dataItems.map(item =>
        typeof item === "string" ? item : item.display
      );
      if (selectedValues.includes(CLEAR_TEXT)) {
        handleChange([]);
        return;
      }

      selectedValues = sortValues(
        selectedValues,
        options,
        schemaField.config.customSort
      );

      handleChange(selectedValues);
    },
    [handleChange]
  );

  return (
    <DropdownContainer onBlur={handleBlur} hasErrors={hasErrors}>
      <Multiselect
        value={sortedValue}
        filter="contains"
        data={displayOptions}
        textField="display"
        valueField="value"
        onChange={onChange}
        allowCreate={allowCreate}
        onCreate={addFieldValue}
        messages={{
          emptyList: "",
          createOption: "To add a new option: type a value, press Enter"
        }}
        autoFocus={autoFocus}
        disabled={disabled}
      />
    </DropdownContainer>
  );
};

export interface MultiSelectListInputState {
  readonly options: MultiSelectListOption[];
}

export const MultiSelectListInput: React.FC<SchemaFormFieldInputProps> = ({
  form,
  field,
  schemaField,
  autoFocus
}) => {
  const [options, setOptions] = useState<MultiSelectListOption[]>(
    schemaField.config.listValues
  );

  const allowCreate = schemaField.config?.dynamic ?? false;

  const handleBlur = () => {
    form.setFieldTouched(field.name, true);
  };

  const handleChange = (selectedValues: string[]) => {
    form.setFieldValue(field.name, selectedValues);
  };

  const addFieldValue = (newValue: string) => {
    if (newValue && newValue.trim() !== "") {
      newValue = newValue.trimEnd();
      const includesValue = options.some(option => {
        return option.value?.toLowerCase() === newValue.toLowerCase();
      });
      const selectedValues = options.some(value => {
        return newValue.toLowerCase() === value.display.toLowerCase();
      });
      if (includesValue && selectedValues) return;

      const newOptions = [...options, { display: newValue, newValue }];
      if (!includesValue) setOptions(newOptions);
      if (!selectedValues) {
        let newFieldValue = [
          ...getFieldValueAsArray(form.values[field.name]),
          newValue
        ];
        newFieldValue = sortValues(
          newFieldValue,
          newOptions,
          schemaField.config.customSort
        );
        form.setFieldValue(field.name, newFieldValue);
      }
    }
  };

  return (
    <MultiSelectFieldInput
      handleBlur={handleBlur}
      hasErrors={
        form.errors[field.name] && form.touched[field.name] ? true : false
      }
      value={field.value}
      handleChange={handleChange}
      allowCreate={allowCreate}
      addFieldValue={addFieldValue}
      options={options}
      autoFocus={autoFocus}
      schemaField={schemaField}
      keepInvalidValues
    />
  );
};

export default MultiSelectListInput;

export const DropdownContainer = styled.div<{ hasErrors?: boolean }>`
  & .rw-widget-input {
    height: 34px;
    ${props => (props.hasErrors ? "border: 1px solid #a94442;" : "")};
  }
  & .rw-multiselect-taglist {
      display:inline;
  }
  & .rw-input-reset {
    display: inline-block;
  }
  & .rw-widget-container {
    & div {
      display: inline-block;
    }
  }
}

`;
