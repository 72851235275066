import produce from "immer";
import { ActionType, createAsyncAction, getType } from "typesafe-actions";
import {
  IProfileDto,
  IMinorityTypeDto,
  IUpdateProfileDto
} from "api/GeneratedClients/ContactsClient";

export const STATE_KEY = "profile";

// Models
export interface State {
  profile?: IProfileDto;
  minorityTypes?: IMinorityTypeDto[];
  loading: {
    profile: boolean;
    types: boolean;
    logo: boolean;
  };
  errors: string[];
}

export interface StateSlice {
  [STATE_KEY]: State;
}

// Actions
export const actions = {
  loadProfile: createAsyncAction(
    "PROFILE/LOAD_REQUEST",
    "PROFILE/LOAD_SUCCESS",
    "PROFILE/LOAD_FAILURE"
  )<void, IProfileDto, Error>(),
  updateProfile: createAsyncAction(
    "PROFILE/UPDATE_REQUEST",
    "PROFILE/UPDATE_SUCCESS",
    "PROFILE/UPDATE_FAILURE"
  )<IUpdateProfileDto, IUpdateProfileDto, Error>(),
  addLogo: createAsyncAction(
    "PROFILE/ADD_LOGO_REQUEST",
    "PROFILE/ADD_LOGO_SUCCESS",
    "PROFILE/ADD_LOGO_FAILURE"
  )<FormData, string, Error>()
};

export type ProfileActions = ActionType<typeof actions>;

const initialState: State = {
  loading: {
    profile: false,
    types: false,
    logo: false
  },
  errors: []
};

// Reducer
export const reducer = (state = initialState, action: ProfileActions) => {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.loadProfile.request): {
        draft.loading.profile = true;
        break;
      }
      case getType(actions.loadProfile.success): {
        draft.loading.profile = false;
        draft.profile = action.payload;
        break;
      }
      case getType(actions.loadProfile.failure): {
        draft.loading.profile = false;
        draft.errors.push(`Error loading profile: ${action.payload.message}`);
        break;
      }
      case getType(actions.updateProfile.request): {
        draft.loading.profile = true;
        break;
      }
      case getType(actions.updateProfile.success): {
        draft.loading.profile = false;
        draft.profile = { ...draft.profile, ...action.payload };
        break;
      }
      case getType(actions.updateProfile.failure): {
        draft.loading.profile = false;
        break;
      }
      case getType(actions.addLogo.request): {
        draft.loading.logo = true;
        break;
      }
      case getType(actions.addLogo.success): {
        if (draft.profile) {
          draft.profile.profileLogoUrl = action.payload;
        }
        draft.loading.logo = false;
        break;
      }
      case getType(actions.addLogo.failure): {
        draft.loading.logo = false;
        break;
      }
    }
  });
};

export type SelectorState = StateSlice;

// Selectors
const getProfile = ({ profile }: SelectorState) => profile.profile;
const getLoading = ({ profile }: SelectorState) => profile.loading;
const getErrors = ({ profile }: SelectorState) => profile.errors;

export const selectors = {
  getProfile,
  getLoading,
  getErrors
};
