import constate from "constate";
import { useState, useEffect } from "react";
import { useCurrentEstimateContext } from "../../estimates/context/CurrentEstimateContext";
import { useHcssUser } from "modules/account";
import { VendorProposalRepo } from "../repository/vendor-proposal-repo";
import { MasterProposalRepo } from "../repository/master-proposal-repo";
import {
  VendorProposalEntity,
  MasterProposalEntity,
  VendorProposal,
  MasterProposal
} from "core";
import { onSnapshot } from "firebase/firestore";

export interface ProposalContext {
  outboundVendorProposals: VendorProposalEntity[];
  inboundVendorProposals: VendorProposalEntity[];
  masterProposals: MasterProposalEntity[];
  loading: boolean;
}
const useProposals = (): ProposalContext => {
  const master = useMasterProposals();
  const outbound = useOutboundProposals();
  const inbound = useInboundProposals();

  return {
    outboundVendorProposals: outbound.proposals,
    inboundVendorProposals: inbound.proposals,
    masterProposals: master.proposals,
    loading: outbound.loading || inbound.loading || master.loading
  };
};

const useMasterProposals = () => {
  const { companyId } = useHcssUser();
  const { currentEstimateId } = useCurrentEstimateContext();
  const [loading, setLoading] = useState(false);
  const [proposals, setProposals] = useState<MasterProposalEntity[]>([]);

  useEffect(() => {
    const subscribe = () => {
      setLoading(true);

      const q = MasterProposalRepo.subscribe(companyId, currentEstimateId);
      const unsubscribe = onSnapshot(
        q,
        snapshots => {
          const results: MasterProposalEntity[] = [];
          snapshots.forEach(doc =>
            results.push({ ...(doc.data() as MasterProposal), id: doc.id })
          );
          setProposals(results);
          setLoading(false);
        },
        error => {
          console.error(error);
          setLoading(false);
        }
      );

      return () => unsubscribe();
    };

    if (companyId) return subscribe();
  }, [companyId, currentEstimateId]);

  return {
    loading,
    proposals
  };
};

const useOutboundProposals = () => {
  const { companyId } = useHcssUser();
  const { currentEstimateId } = useCurrentEstimateContext();
  const [loading, setLoading] = useState(false);
  const [proposals, setProposals] = useState<VendorProposalEntity[]>([]);

  useEffect(() => {
    const subscribe = () => {
      setLoading(true);

      const q = VendorProposalRepo.getOutboundSubscription(
        companyId,
        currentEstimateId
      );
      const unsubscribe = onSnapshot(
        q,
        snapshots => {
          const results: VendorProposalEntity[] = [];
          snapshots.forEach(doc =>
            results.push({ ...(doc.data() as VendorProposal), id: doc.id })
          );
          setProposals(results);
          setLoading(false);
        },
        error => {
          console.error(error);
          setLoading(false);
        }
      );

      return () => unsubscribe();
    };

    if (companyId) return subscribe();
  }, [companyId, currentEstimateId]);

  return {
    loading,
    proposals
  };
};

const useInboundProposals = () => {
  const { companyId } = useHcssUser();
  const { currentEstimateId } = useCurrentEstimateContext();
  const [loading, setLoading] = useState(false);
  const [proposals, setProposals] = useState<VendorProposalEntity[]>([]);

  useEffect(() => {
    const subscribe = () => {
      setLoading(true);

      const q = VendorProposalRepo.getInboundSubscription(companyId);
      const unsubscribe = onSnapshot(
        q,
        snapshots => {
          const results: VendorProposalEntity[] = [];
          snapshots.forEach(doc =>
            results.push({ ...(doc.data() as VendorProposal), id: doc.id })
          );
          setProposals(results);
          setLoading(false);
        },
        error => {
          console.error(error);
          setLoading(false);
        }
      );

      return () => unsubscribe();
    };

    if (companyId) return subscribe();
  }, [companyId, currentEstimateId]);

  return {
    loading,
    proposals
  };
};

const [provider, context] = constate(useProposals);
export const ProposalProvider = provider;
export const useProposalContext = context;
