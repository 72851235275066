import React from "react";
import styled from "styled-components";
import { ConcreteColors } from "hcss-components";
import { CompanyAvatar, UserAvatar } from "../../../core/components/avatar";
import { PhoneNumber, WebAddress } from "./vendor-details";

interface ContactPopoverProps {
  name: string;
  email?: string;
  id?: string;
  companyName?: string;
  className: string;
  style: React.CSSProperties;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
}
//props come from parents without className and style, cannot assign ContactPopoverProps directly
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ContactPopover = (props: any) => {
  const {
    name,
    email,
    className,
    style,
    id,
    companyName,
    setShow
  } = props as ContactPopoverProps;
  const classes = email ? "" : "email-danger";

  return (
    <PopoverContainer
      className={className}
      style={style}
      onClick={e => {
        if (setShow) {
          setShow(false);
          e.stopPropagation();
        }
      }}
    >
      <div className="main-info">
        <div className="hb-avatar">
          <UserAvatar id={id} name={name} size="60px" />
        </div>
        <div className="info">
          <div className="name">{name}</div>
          {companyName && <div>{companyName}</div>}
          <div className={`email ${classes}`}>{email ?? "(No Email)"}</div>
        </div>
      </div>
      {/* <div className="footer">coming soon</div> */}
    </PopoverContainer>
  );
};

interface CompanyPopoverProps {
  id: string;
  code: string;
  name: string | undefined;
  webAddress: string | undefined;
  phoneNumber: string | undefined;
  className: string;
  style: React.CSSProperties;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CompanyPopover = (props: any) => {
  const {
    id,
    name,
    code,
    webAddress,
    phoneNumber,
    className,
    style,
    setShow
  } = props as CompanyPopoverProps;
  return (
    <PopoverContainer
      className={className}
      style={style}
      onClick={e => {
        setShow(false);
        e.stopPropagation();
      }}
    >
      <div className="main-info">
        <div className="hb-avatar">
          <CompanyAvatar id={id} name={name ? name : ""} size="57.5px" />
        </div>
        <div className="info">
          <div className="code" style={{ fontSize: "1.6rem" }}>
            {code}
            {name ? " - " + name : ""}
          </div>
          <WebAddress webAddress={webAddress ? webAddress : "--"} />
          <PhoneNumber phoneNumber={phoneNumber ? phoneNumber : "--"} />
        </div>
      </div>
    </PopoverContainer>
  );
};

export const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 3px;
  min-width: 250px;
  z-index: 2000;

  .main-info {
    display: flex;
    flex-direction: row;
    padding: 10px 10px 10px 10px;
    border-bottom: 1px solid ${ConcreteColors.gray300};

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      line-height: 1.5rem;
    }

    .hb-avatar {
      margin-right: 15px;
    }

    .info > .name,
    .info > .code {
      font-size: 20px;
      font-weight: 600;
      color: ${ConcreteColors.gray600};
    }

    .info > .email {
      font-size: 12px;
      font-weight: 500;
      color: ${ConcreteColors.gray500};

      &.email-danger {
        color: ${ConcreteColors.red400};
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
  }
`;
