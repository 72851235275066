import React, { useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { ConcreteColors } from "hcss-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { strings } from "localization";
import { UserType, CurrentUser } from "core";

import {
  useAuthorization,
  AccountAuthorization,
  useHcssUser
} from "modules/account";

import {
  IconName,
  IconPrefix,
  Transform
} from "@fortawesome/fontawesome-svg-core";

import {
  GlobalSideNavigation,
  GlobalItem
} from "core/components/hcss-navigation/";

interface PrimaryItem {
  key: SideNavigationOptions;
  testId: string;
  path: string;
  iconPrefix?: IconPrefix;
  iconTransform?: string | Transform;
  iconName: IconName;
  title: string;
}

export enum SideNavigationOptions {
  Projects = "projects",
  Estimates = "estimates",
  Contacts = "contacts"
}

const primaryItems: PrimaryItem[] = [
  {
    key: SideNavigationOptions.Projects,
    testId: "sidenav-item-projects",
    title: strings.common.modules.projectTracking,
    path: "/projects",
    iconName: "folder-open"
  },
  {
    key: SideNavigationOptions.Estimates,
    testId: "sidenav-item-estimates",
    title: strings.common.modules.estimating,
    path: "/estimates",
    iconName: "file-invoice-dollar"
  },
  {
    key: SideNavigationOptions.Contacts,
    testId: "sidenav-item-contacts",
    title: strings.common.modules.contacts,
    path: "/contacts",
    iconName: "address-book"
  }
];

export const SideNavigation = () => {
  const currentPath = useLocation().pathname;
  const history = useHistory();
  const auth = useAuthorization();
  const user = useHcssUser();

  // Green Styling
  const style: React.CSSProperties =
    user.type === UserType.Subcontractor
      ? { backgroundColor: "#06885d" }
      : { backgroundColor: ConcreteColors.gray200 };

  const items = useMemo(() => {
    const buttonColor =
      user.type === UserType.Subcontractor
        ? { default: "#d9d9d9", selected: "#f0f0f0", hover: "#f0f0f0" }
        : undefined;

    const buttonBackgroundColor =
      user.type === UserType.Subcontractor
        ? { default: "transparent", selected: "#006644", hover: "#006644" }
        : undefined;

    return primaryItems
      .filter(p => checkAccess(auth, user, p.key))
      .map(i => (
        <GlobalItem
          key={i.key}
          itemId={i.key}
          testId={i.testId}
          hoverTitle={i.title}
          onClick={() => history.push(i.path)}
          isSelected={currentPath.startsWith(i.path)}
          buttonColor={buttonColor}
          buttonBackgroundColor={buttonBackgroundColor}
          icon={
            <FontAwesomeIcon
              icon={[i.iconPrefix ?? "far", i.iconName]}
              transform={i.iconTransform}
              size="lg"
            />
          }
        />
      ));
  }, [auth, currentPath, history, user]);

  return (
    <GlobalSideNavigation
      testId="side-nav"
      topOffset={0}
      showCreateButton={false}
      createHoverTitle={strings.common.create}
      style={style}
      primaryItems={items}
      secondaryItems={[]}
    />
  );
};

const checkAccess = (
  auth: AccountAuthorization,
  user: CurrentUser,
  key: SideNavigationOptions
) => {
  const isPreconUser = user.type === UserType.HcssSubscription;

  switch (key) {
    case SideNavigationOptions.Projects:
      return isPreconUser;
    case SideNavigationOptions.Estimates:
      return isPreconUser;
    case SideNavigationOptions.Contacts:
      return true;
    default:
      return true;
  }
};
