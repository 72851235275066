/* Generated by restful-react */

import React from "react";
import {
  Get,
  GetProps,
  useGet,
  UseGetProps,
  Mutate,
  MutateProps,
  useMutate,
  UseMutateProps
} from "restful-react";

import config from "config";
export const SPEC_VERSION = "internal";
export interface HistoricalBidPrice {
  bid?: string | null;
  state?: string | null;
  clientNumber?: string | null;
  count?: number;
  lowBid?: number;
  highBid?: number;
  averageBid?: number;
}

export interface BidInfo {
  bid?: string | null;
  state?: string | null;
  clientNumber?: string | null;
  lowQuantity?: number;
  highQuantity?: number;
}

export interface HistoricalBiditem {
  estimateCode?: string | null;
  estimateName?: string | null;
  biditem?: string | null;
  bidQuantity?: number;
  units?: string | null;
  bidPrice?: number;
  isActiveEstimate?: boolean;
}

export interface BusinessUnitDto {
  id?: string;
  credentialsId?: string;
  code?: string | null;
  description?: string | null;
}

export interface BiditemViewModel {
  id?: string;
  bid?: string | null;
  description?: string | null;
  activities?: ActivityViewModel[] | null;
  type?: string | null;
  quantity?: number;
  engineeringQuantity?: number;
  units?: string | null;
  engineeringBid?: string | null;
  imageAttached?: string | null;
  subtotalLevel?: string | null;
  otherOptions?: string | null;
  wbsCode?: string | null;
  passThrough?: string | null;
  marineLand?: string | null;
  parent?: string | null;
  subDivided?: string | null;
  alternateUnits?: string | null;
  alternateDescription?: string | null;
  lowBid?: number;
  averageBid?: number;
  highBid?: number;
  stateCode?: string | null;
  unitCostHoldingAccount?: string | null;
  bidHistoryID?: number;
  taxPercent?: number;
  taxable?: string | null;
  bidPrice?: number;
  summaryGroup1?: string | null;
  summaryGroup2?: string | null;
  summaryGroup3?: string | null;
  summaryGroup4?: string | null;
  summaryGroup5?: string | null;
  summaryGroup6?: string | null;
  reviewFlag?: string | null;
  estInitials?: string | null;
  sortCode?: string | null;
  costNotes?: string | null;
}

export interface ActivityViewModel {
  id?: string;
  activityCode?: string | null;
  description?: string | null;
  resources?: ResourceViewModel[] | null;
  quantity?: number;
  units?: string | null;
  factorable?: string | null;
  saveSkipCost?: string | null;
  workersCompCode?: string | null;
  marineWorkersComp?: string | null;
  summaryCode?: string | null;
  calendar?: string | null;
  hoursPerDay?: number;
  cpmPriorItem?: string | null;
  sortOption?: string | null;
  skipCost?: string | null;
  crew?: string | null;
  crewHours?: number;
  crewPercent?: number;
  productionType?: string | null;
  productionRate?: number;
  user1?: number;
  user2?: number;
  user3?: number;
  user4?: number;
  accountingJCCode1?: string | null;
  alternateCostCode?: string | null;
  tagged?: string | null;
  imageAttached?: string | null;
  resourceFactorRiskPercent?: number;
  efficientPercent?: number;
  calculatedDuration?: number;
  modifiedCrew?: string | null;
  userFlag1?: string | null;
  userFlag2?: string | null;
  manHours?: number;
  directTotal?: number;
  notes?: string | null;
}

export interface ResourceViewModel {
  id?: string;
  resourceCode?: string | null;
  description?: string | null;
  typeCost?: string | null;
  typeOfEquipmentRent?: string | null;
  subType?: string | null;
  quantity?: number;
  units?: string | null;
  percent?: number;
  unitPrice?: number;
  currency?: string | null;
  subTypeCost?: number;
  total?: number;
  factorable?: string | null;
  factor?: number;
  supplementalDescription?: string | null;
  skipCost?: string | null;
  pieces?: number;
  operatingPercent?: number;
  rentPercent?: number;
  productionCode?: string | null;
  production?: number;
  crewCode?: string | null;
  equipmentOperatorCode?: string | null;
  escalationPercent?: number;
  plugIndicator?: string | null;
  calendar?: string | null;
  startDay?: string | null;
  specialSchedule?: string | null;
  manHourAdjustmentPerShift?: number;
  selectedVendor?: string | null;
  manHourUnit?: number;
  escalationOverride?: string | null;
  unusedFlag3?: string | null;
  linkflag?: number;
  class?: string | null;
  alternateQuantity?: number;
  alternateUnits?: string | null;
  resourceText1?: string | null;
  resourceText2?: string | null;
}

export interface CopyStructure {
  id?: string;
  activityIds?: string[] | null;
}

export interface CopyEstimateViewModel {
  id?: string;
  code?: string | null;
  name?: string | null;
  description?: string | null;
  biditems?: CopyBiditemViewModel[] | null;
}

export interface CopyBiditemViewModel {
  id?: string;
  bid?: string | null;
  description?: string | null;
  activities?: CopyActivityViewModel[] | null;
  type?: string | null;
  quantity?: number;
  engineeringQuantity?: number;
  units?: string | null;
  engineeringBid?: string | null;
  subDivided?: string | null;
  parent?: string | null;
  wbsCode?: string | null;
}

export interface CopyActivityViewModel {
  id?: string;
  activityCode?: string | null;
  description?: string | null;
  resources?: CopyResourceViewModel[] | null;
  quantity?: number;
  units?: string | null;
  skipCost?: string | null;
}

export interface CopyResourceViewModel {
  id?: string;
  resourceCode?: string | null;
  description?: string | null;
  quantity?: number;
  units?: string | null;
  type_Cost?: string | null;
}

export type ODataQueryOptionsOfBiditem = ODataQueryOptions & {
  ifMatch?: ETagOfBiditem | null;
  ifNoneMatch?: ETagOfBiditem | null;
};

export type ETagOfBiditem = ETag & { [key: string]: any };

export type ETag = DynamicObject & {
  Item?: {} | null;
  IsWellFormed?: boolean;
  EntityType?: string | null;
  IsAny?: boolean;
  IsIfNoneMatch?: boolean;
  ConcurrencyProperties?: { [key: string]: any } | null;
};

export interface DynamicObject {
  [key: string]: any;
}

export interface ODataQueryOptions {
  request?: HttpRequest | null;
  context?: ODataQueryContext | null;
  rawValues?: ODataRawQueryOptions | null;
  selectExpand?: SelectExpandQueryOption | null;
  apply?: ApplyQueryOption | null;
  filter?: FilterQueryOption | null;
  orderBy?: OrderByQueryOption | null;
  skip?: SkipQueryOption | null;
  skipToken?: SkipTokenQueryOption | null;
  top?: TopQueryOption | null;
  count?: CountQueryOption | null;
  validator?: ODataQueryValidator | null;
  ifMatch?: ETag | null;
  ifNoneMatch?: ETag | null;
}

export interface HttpRequest {
  bodyReader?: PipeReader | null;
  routeValues?: RouteValueDictionary | null;
}

export interface PipeReader {
  [key: string]: any;
}

export interface RouteValueDictionary {
  _arrayStorage?: KeyValuePairOfStringAndObject[] | null;
  _propertyStorage?: PropertyStorage | null;
  Item?: {} | null;
  Comparer?: IEqualityComparerOfString | null;
  Count?: number;
  Keys?: string[] | null;
  Values?: {}[] | null;
}

export interface KeyValuePairOfStringAndObject {
  key?: string;
  value?: {};
}

export interface PropertyStorage {
  value?: {} | null;
  properties?: PropertyHelper[] | null;
}

export interface PropertyHelper {
  property?: PropertyInfo | null;
  name?: string | null;
  valueGetter?: FuncOfObjectAndObject | null;
  valueSetter?: ActionOfObjectAndObject | null;
}

export type PropertyInfo = MemberInfo & {
  memberType?: MemberTypes;
  isSpecialName?: boolean;
  getMethod?: MethodInfo | null;
  setMethod?: MethodInfo | null;
};

export type MemberTypes = number;

export type MethodInfo = MethodBase & {
  memberType?: MemberTypes;
  returnParameter?: ParameterInfo;
  returnType?: string;
};

export interface ParameterInfo {
  attributes?: ParameterAttributes;
  member?: MemberInfo;
  name?: string | null;
  parameterType?: string;
  position?: number;
  isIn?: boolean;
  isLcid?: boolean;
  isOptional?: boolean;
  isOut?: boolean;
  isRetval?: boolean;
  defaultValue?: {} | null;
  rawDefaultValue?: {} | null;
  hasDefaultValue?: boolean;
  customAttributes?: CustomAttributeData[];
  metadataToken?: number;
}

export type ParameterAttributes = number;

export interface MemberInfo {
  module?: Module;
  customAttributes?: CustomAttributeData[];
  isCollectible?: boolean;
  metadataToken?: number;
}

export interface Module {
  assembly?: Assembly;
  fullyQualifiedName?: string;
  name?: string;
  mdStreamVersion?: number;
  moduleVersionId?: string;
  scopeName?: string;
  moduleHandle?: ModuleHandle;
  customAttributes?: CustomAttributeData[];
  metadataToken?: number;
}

export interface Assembly {
  definedTypes?: string[];
  exportedTypes?: string[];
  codeBase?: string | null;
  entryPoint?: MethodInfo | null;
  fullName?: string | null;
  imageRuntimeVersion?: string;
  isDynamic?: boolean;
  location?: string;
  reflectionOnly?: boolean;
  isCollectible?: boolean;
  isFullyTrusted?: boolean;
  customAttributes?: CustomAttributeData[];
  escapedCodeBase?: string;
  manifestModule?: Module;
  modules?: Module[];
  globalAssemblyCache?: boolean;
  hostContext?: number;
  securityRuleSet?: SecurityRuleSet;
}

export interface CustomAttributeData {
  attributeType?: string;
  constructor?: ConstructorInfo;
  constructorArguments?: CustomAttributeTypedArgument[];
  namedArguments?: CustomAttributeNamedArgument[];
}

export type ConstructorInfo = MethodBase & {
  memberType?: MemberTypes;
};

export type MethodBase = MemberInfo & {
  methodImplementationFlags?: MethodImplAttributes;
  callingConvention?: CallingConventions;
  isAbstract?: boolean;
  isConstructor?: boolean;
  isFinal?: boolean;
  isHideBySig?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isVirtual?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isConstructedGenericMethod?: boolean;
  isGenericMethod?: boolean;
  isGenericMethodDefinition?: boolean;
  containsGenericParameters?: boolean;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
};

export type MethodImplAttributes = number;

export type CallingConventions = number;

export interface CustomAttributeTypedArgument {
  argumentType?: string;
  value?: {} | null;
}

export interface CustomAttributeNamedArgument {
  memberInfo?: MemberInfo;
  typedValue?: CustomAttributeTypedArgument;
  memberName?: string;
  isField?: boolean;
}

export type SecurityRuleSet = number;

export interface ModuleHandle {
  mdStreamVersion?: number;
}

export type FuncOfObjectAndObject = MulticastDelegate & { [key: string]: any };

export type MulticastDelegate = Delegate & { [key: string]: any };

export interface Delegate {
  target?: {} | null;
  method?: MethodInfo;
}

export type ActionOfObjectAndObject = MulticastDelegate & {
  [key: string]: any;
};

export interface IEqualityComparerOfString {
  [key: string]: any;
}

export interface ODataQueryContext {
  defaultQuerySettings?: DefaultQuerySettings | null;
  model?: IEdmModel | null;
  elementType?: IEdmType | null;
  navigationSource?: IEdmNavigationSource | null;
  elementClrType?: string | null;
  path?: ODataPath | null;
  requestContainer?: IServiceProvider | null;
}

export interface DefaultQuerySettings {
  enableExpand?: boolean;
  enableSelect?: boolean;
  enableCount?: boolean;
  enableOrderBy?: boolean;
  enableFilter?: boolean;
  maxTop?: number | null;
  enableSkipToken?: boolean;
}

export interface IEdmModel {
  schemaElements?: IEdmSchemaElement[] | null;
  vocabularyAnnotations?: IEdmVocabularyAnnotation[] | null;
  referencedModels?: IEdmModel[] | null;
  declaredNamespaces?: string[] | null;
  directValueAnnotationsManager?: IEdmDirectValueAnnotationsManager | null;
  entityContainer?: IEdmEntityContainer | null;
}

export interface IEdmSchemaElement {
  schemaElementKind?: EdmSchemaElementKind;
  namespace?: string | null;
}

export type EdmSchemaElementKind = number;

export interface IEdmVocabularyAnnotation {
  qualifier?: string | null;
  term?: IEdmTerm | null;
  target?: IEdmVocabularyAnnotatable | null;
  value?: IEdmExpression | null;
}

export interface IEdmTerm {
  type?: IEdmTypeReference | null;
  appliesTo?: string | null;
  defaultValue?: string | null;
}

export interface IEdmTypeReference {
  isNullable?: boolean;
  definition?: IEdmType | null;
}

export interface IEdmType {
  typeKind?: EdmTypeKind;
}

export type EdmTypeKind = number;

export interface IEdmVocabularyAnnotatable {
  [key: string]: any;
}

export interface IEdmExpression {
  expressionKind?: EdmExpressionKind;
}

export type EdmExpressionKind = number;

export interface IEdmDirectValueAnnotationsManager {
  [key: string]: any;
}

export interface IEdmEntityContainer {
  elements?: IEdmEntityContainerElement[] | null;
}

export interface IEdmEntityContainerElement {
  containerElementKind?: EdmContainerElementKind;
  container?: IEdmEntityContainer | null;
}

export type EdmContainerElementKind = number;

export interface IEdmNavigationSource {
  navigationPropertyBindings?: IEdmNavigationPropertyBinding[] | null;
  path?: IEdmPathExpression | null;
  type?: IEdmType | null;
}

export interface IEdmNavigationPropertyBinding {
  navigationProperty?: IEdmNavigationProperty | null;
  target?: IEdmNavigationSource | null;
  path?: IEdmPathExpression | null;
}

export interface IEdmNavigationProperty {
  partner?: IEdmNavigationProperty | null;
  onDelete?: EdmOnDeleteAction;
  containsTarget?: boolean;
  referentialConstraint?: IEdmReferentialConstraint | null;
}

export type EdmOnDeleteAction = number;

export interface IEdmReferentialConstraint {
  propertyPairs?: EdmReferentialConstraintPropertyPair[] | null;
}

export interface EdmReferentialConstraintPropertyPair {
  dependentProperty?: IEdmStructuralProperty | null;
  principalProperty?: IEdmStructuralProperty | null;
}

export interface IEdmStructuralProperty {
  defaultValueString?: string | null;
}

export interface IEdmPathExpression {
  pathSegments?: string[] | null;
  path?: string | null;
}

export interface ODataPath {
  edmType?: IEdmType | null;
  navigationSource?: IEdmNavigationSource | null;
  segments?: ODataPathSegment[] | null;
  pathTemplate?: string | null;
  path?: ODataPathSegment[] | null;
}

export interface ODataPathSegment {
  identifier?: string | null;
}

export interface IServiceProvider {
  [key: string]: any;
}

export interface ODataRawQueryOptions {
  filter?: string | null;
  apply?: string | null;
  orderBy?: string | null;
  top?: string | null;
  skip?: string | null;
  select?: string | null;
  expand?: string | null;
  count?: string | null;
  format?: string | null;
  skipToken?: string | null;
  deltaToken?: string | null;
}

export interface SelectExpandQueryOption {
  context?: ODataQueryContext | null;
  rawSelect?: string | null;
  rawExpand?: string | null;
  validator?: SelectExpandQueryValidator | null;
  selectExpandClause?: SelectExpandClause | null;
  levelsMaxLiteralExpansionDepth?: number;
}

export interface SelectExpandQueryValidator {
  [key: string]: any;
}

export interface SelectExpandClause {
  selectedItems?: SelectItem[] | null;
  allSelected?: boolean;
}

export interface SelectItem {
  [key: string]: any;
}

export interface ApplyQueryOption {
  context?: ODataQueryContext | null;
  resultClrType?: string | null;
  applyClause?: ApplyClause | null;
  rawValue?: string | null;
}

export interface ApplyClause {
  transformations?: TransformationNode[] | null;
}

export interface TransformationNode {
  [key: string]: any;
}

export interface FilterQueryOption {
  context?: ODataQueryContext | null;
  validator?: FilterQueryValidator | null;
  filterClause?: FilterClause | null;
  rawValue?: string | null;
}

export interface FilterQueryValidator {
  [key: string]: any;
}

export interface FilterClause {
  expression?: SingleValueNode | null;
  rangeVariable?: RangeVariable | null;
  itemType?: IEdmTypeReference | null;
}

export type SingleValueNode = QueryNode & {
  kind?: QueryNodeKind;
};

export type QueryNodeKind = number;

export interface QueryNode {
  [key: string]: any;
}

export interface RangeVariable {
  [key: string]: any;
}

export interface OrderByQueryOption {
  context?: ODataQueryContext | null;
  orderByNodes?: OrderByNode[] | null;
  rawValue?: string | null;
  validator?: OrderByQueryValidator | null;
  orderByClause?: OrderByClause | null;
}

export interface OrderByNode {
  direction?: OrderByDirection;
}

export type OrderByDirection = number;

export interface OrderByQueryValidator {
  [key: string]: any;
}

export interface OrderByClause {
  thenBy?: OrderByClause | null;
  expression?: SingleValueNode | null;
  direction?: OrderByDirection;
  rangeVariable?: RangeVariable | null;
  itemType?: IEdmTypeReference | null;
}

export interface SkipQueryOption {
  context?: ODataQueryContext | null;
  rawValue?: string | null;
  value?: number;
  validator?: SkipQueryValidator | null;
}

export interface SkipQueryValidator {
  [key: string]: any;
}

export interface SkipTokenQueryOption {
  rawValue?: string | null;
  context?: ODataQueryContext | null;
  validator?: SkipTokenQueryValidator | null;
  querySettings?: ODataQuerySettings | null;
  queryOptions?: ODataQueryOptions | null;
}

export interface SkipTokenQueryValidator {
  [key: string]: any;
}

export interface ODataQuerySettings {
  ensureStableOrdering?: boolean;
  handleNullPropagation?: HandleNullPropagationOption;
  enableConstantParameterization?: boolean;
  enableCorrelatedSubqueryBuffering?: boolean;
  pageSize?: number | null;
  handleReferenceNavigationPropertyExpandFilter?: boolean;
}

export type HandleNullPropagationOption = number;

export interface TopQueryOption {
  context?: ODataQueryContext | null;
  rawValue?: string | null;
  value?: number;
  validator?: TopQueryValidator | null;
}

export interface TopQueryValidator {
  [key: string]: any;
}

export interface CountQueryOption {
  context?: ODataQueryContext | null;
  rawValue?: string | null;
  value?: boolean;
  validator?: CountQueryValidator | null;
}

export interface CountQueryValidator {
  [key: string]: any;
}

export interface ODataQueryValidator {
  [key: string]: any;
}

export interface BiditemParent {
  bid?: string | null;
  id?: string;
}

export interface EstimateSearchFlattened {
  estimateId?: string;
  estimateCode?: string | null;
  estimateName?: string | null;
  biditemId?: string;
  bid_Date?: string | null;
  biditemCode?: string | null;
  biditemDescription?: string | null;
  biditemClientNo?: string | null;
  activityId?: string;
  activityCode?: string | null;
  activityDescription?: string | null;
  activityQuantity?: number;
  modified_Date?: string | null;
  created_Date?: string | null;
  isActiveEstimate?: boolean;
}

export type ODataQueryOptionsOfEstimate = ODataQueryOptions & {
  ifMatch?: ETagOfEstimate | null;
  ifNoneMatch?: ETagOfEstimate | null;
};

export type ETagOfEstimate = ETag & { [key: string]: any };

export interface CostHistoryActivity {
  estimateId?: string | null;
  estimateCode?: string | null;
  estimateName?: string | null;
  activityId?: string | null;
  activityCode?: string | null;
  activityDescription?: string | null;
  manHours?: number;
  cost?: number;
  crewCost?: number;
  quantity?: number;
  bid_Date?: string | null;
  units?: string | null;
  clientNumber?: string | null;
  isActiveEstimate?: boolean;
}

export type DatabasePartition = Entity & {
  /**
   * The system info id.
   */
  systemInfoId?: string;
  /**
   * The system info.
   */
  systemInfo?: SystemInfo | null;
  /**
   * The business unit id.
   */
  businessUnitId?: string;
  /**
   * The business unit.
   */
  businessUnit?: BusinessUnit | null;
  /**
   * The estimates.
   */
  estimates?: Estimate[] | null;
};

export type SystemInfo = Entity & {
  /**
   * The HeavyBid system id.
   */
  heavyBidSystemId?: string;
  /**
   * The database partitions.
   */
  partitions?: DatabasePartition[] | null;
};

export interface Entity {
  id?: string;
}

export type BusinessUnit = Entity & {
  /**
   * The code.
   */
  code?: string;
  /**
   * The partitions.
   */
  partitions?: DatabasePartition[] | null;
  /**
   * The setups business unit id.
   */
  businessUnitId?: string;
  /**
   * The setups business unit code.
   */
  businessUnitCode?: string | null;
};

export type Estimate = EstimateIntegrationDto;

export type StatusCode = number;

export interface EstimateFilters {
  project_Name?: string;
  project_Number?: string;
  status?: string;
  engineer?: string;
  resp_Estimator?: string;
  owner?: string;
  bid_Date?: string | null;
  start_Date?: string | null;
  review_Date?: string | null;
  created_Date?: string | null;
  modified_Date?: string | null;
  modified_Time?: number | null;
  bid_Amount?: number | null;
  takeoff_Amount?: number | null;
  profit_Bidquan?: number | null;
  profit_Takeoff?: number | null;
  tot_Cost?: number | null;
  wc_State?: string;
  type_Work?: string;
  filter_1?: string;
  filter_2?: string;
  filter_3?: string;
  filter_4?: string;
  filter_5?: string;
  filter_6?: string;
  user_Ascii1?: string;
  user_Ascii2?: string;
  user_Ascii3?: string;
  user_Ascii4?: string;
  user_Ascii5?: string;
  user_Ascii6?: string;
  user_Ascii7?: string;
  user_Ascii8?: string;
  user_Ascii9?: string;
  user_Ascii10?: string;
  user_Ascii11?: string;
  user_Ascii12?: string;
  user_Ascii13?: string;
  user_Ascii14?: string;
  user_Ascii15?: string;
  user_Ascii16?: string;
  user_Ascii17?: string;
  user_Ascii18?: string;
  user_Ascii19?: string;
  user_Ascii20?: string;
  user_Vendor1?: string;
  user_Vendor2?: string;
  user_Vendor3?: string;
  user_Vendor4?: string;
  user_Vendor5?: string;
  user_Vendor6?: string;
  user_Flag1?: string;
  user_Flag2?: string;
  user_Flag3?: string;
  user_Flag4?: string;
  user_Flag5?: string;
  user_Flag6?: string;
  user_Flag7?: string;
  user_Flag8?: string;
  user_Flag9?: string;
  user_Flag10?: string;
  user_Flag11?: string;
  user_Flag12?: string;
  user_Code1?: string;
  user_Name2?: string;
  user_Integer1?: number | null;
  user_Integer2?: number | null;
  user_Integer3?: number | null;
  user_Integer4?: number | null;
  user_Integer5?: number | null;
  user_Number1?: number | null;
  user_Number2?: number | null;
  user_Number3?: number | null;
  user_Number4?: number | null;
  user_Number5?: number | null;
  user_Number6?: number | null;
  user_Number7?: number | null;
  user_Number8?: number | null;
  user_Number9?: number | null;
  user_Number10?: number | null;
  user_Number11?: number | null;
  user_Number12?: number | null;
  user_Number13?: number | null;
  user_Number14?: number | null;
  user_Number15?: number | null;
  user_Number16?: number | null;
  user_Number17?: number | null;
  user_Number18?: number | null;
  user_Number19?: number | null;
  user_Number20?: number | null;
  user_Perc1?: number | null;
  user_Perc2?: number | null;
  user_Def_Date?: string | null;
  user_Date2?: string | null;
  user_Date3?: string | null;
  user_Date4?: string | null;
  user_Date5?: string | null;
  user_Date6?: string | null;
  user_Date7?: string | null;
  user_Date8?: string | null;
  user_Date9?: string | null;
  user_Date10?: string | null;
  user_Date11?: string | null;
  address?: string;
  latitude?: number | null;
  longitude?: number | null;
}

export interface EstimateTotals {
  baseLabor_Direct?: number;
  baseLabor_Indirect?: number;
  baseLabor_Total?: number;
  burden_Direct?: number;
  burden_Indirect?: number;
  burden_Total?: number;
  totalLabor_Direct?: number;
  totalLabor_Indirect?: number;
  totalLabor_Total?: number;
  permanentMaterial_Direct?: number;
  permanentMaterial_Indirect?: number;
  permanentMaterial_Total?: number;
  constructionMaterial_Direct?: number;
  constructionMaterial_Indirect?: number;
  constructionMaterial_Total?: number;
  subcontract_Direct?: number;
  subcontract_Indirect?: number;
  subcontract_Total?: number;
  equipmentOperatingExpense_Direct?: number;
  equipmentOperatingExpense_Indirect?: number;
  equipmentOperatingExpense_Total?: number;
  companyEquipment_Direct?: number;
  companyEquipment_Indirect?: number;
  companyEquipment_Total?: number;
  rentedEquipment_Direct?: number;
  rentedEquipment_Indirect?: number;
  rentedEquipment_Total?: number;
  misc1_Direct?: number;
  misc1_Indirect?: number;
  misc1_Total?: number;
  misc2_Direct?: number;
  misc2_Indirect?: number;
  misc2_Total?: number;
  misc3_Direct?: number;
  misc3_Indirect?: number;
  misc3_Total?: number;
  totalEntryCost_Takeoff_Direct?: number;
  totalEntryCost_Takeoff_Indirect?: number;
  totalEntryCost_Takeoff_Total?: number;
  totalEntryCost_Bid_Direct?: number;
  totalEntryCost_Bid_Indirect?: number;
  totalEntryCost_Bid_Total?: number;
  balMarkup_Bid?: number;
  actualMarkup_Bid?: number;
  balancedBid_Bid?: number;
  bidTotal_Bid?: number;
  addonBondTotal?: number;
  totalEqp_Direct?: number;
  totalEqp_Indirect?: number;
  totalEqp_Total?: number;
  manhours_Direct?: number;
  manhours_Indirect?: number;
  manhours_Total?: number;
  addon_Cost?: number;
  bond?: number;
  addon_Markup?: number;
  addon_Total?: number;
  totalCost_Takeoff?: number;
  job_Duration?: number;
  balMarkup_Takeoff?: number;
  actualMarkup_Takeoff?: number;
  balancedBid_Takeoff?: number;
  passThrough_Cost?: number;
  passThrough_Adjustment?: number;
  passThrough_Total?: number;
  totalCost_Bid_LessPassThrough?: number;
  totalCost_Bid?: number;
  total_User_Adjustments?: number;
  total_User_Adjustments_Cost?: number;
  desired_Bid?: number;
  desired_Bid_Diff?: number;
  bidTotal_Takeoff?: number;
  totalCost_Takeoff_LessPassThrough?: number;
  markupPercentCost_Takeoff?: number;
  markupPercentCost_Bid?: number;
  markupPercentSales_Takeoff?: number;
  markupPercentSales_Bid?: number;
  totalCost_Takeoff_LessPassThroughAndAdjustments?: number;
  totalCost_Bid_LessPassThroughAndAdjustments?: number;
  totalEntryCost_Bid_Spread_Indirect?: number;
  addonBond_Cost_Bid_Spread_Total?: number;
  totalCost_Bid_Spread?: number;
  balMarkup_Bid_Spread?: number;
  actualMarkup_Bid_Spread?: number;
  actv_User_Quan1?: number;
  actv_User_Quan2?: number;
  actv_User_Quan3?: number;
  actv_User_Quan4?: number;
  open_Biditems?: number;
  minority_Goal_Percent?: number;
  minority_Goal_Total?: number;
  minority_Current_Percent?: number;
  minority_Current_Total?: number;
  summary_Date?: string | null;
  spread_Date?: string | null;
}

export type ProcessedEstimateFailureCode = number;

export type ActiveEstimateBackup = Entity & {
  estimateId?: string;
  blobUrl?: string;
  uploadedTimestamp?: string;
};

export type EstimateSummaryExtended = EstimateSummary & {
  blobUrl?: string | null;
  description?: string | null;
  filter1?: string | null;
  filter2?: string | null;
  filter3?: string | null;
  filter4?: string | null;
  filter5?: string | null;
  filter6?: string | null;
  address?: string | null;
  resp_Estimator?: string | null;
  tot_Cost?: number;
  bid_Amount?: number;
  status?: string | null;
  owner?: string | null;
  wc_State?: string | null;
  project_Name?: string | null;
  project_Number?: string | null;
  type_Work?: string | null;
  availableReports?: ReportViewModel[] | null;
};

export interface ReportViewModel {
  estimateId: string;
  type: ReportType;
  createdAt: string;
  reportName: string | null;
  displayName: string | null;
}

export enum ReportType {
  cost,
  costAndPrice,
  estimateRecap
}

export interface EstimateSummary {
  id?: string;
  code: string;
  name: string;
  processedStatus?: StatusCode;
  processedStatusFailureCode?: ProcessedEstimateFailureCode;
  estimateVersion?: string | null;
  bid_Date?: string | null;
  start_Date?: string | null;
  review_Date?: string | null;
  created_Date?: string | null;
  modified_Date?: string | null;
  uploaded_Datetime?: string | null;
  isExcludedEstimate?: boolean;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  extensions?: { [key: string]: any } | null;
  [key: string]: string | {} | undefined | null;
}

export interface EstimateExcluded {
  estimateId?: string;
  excluded?: boolean;
}

export interface DeleteEstimateViewModel {
  found?: boolean;
  success?: boolean;
  id?: string;
  deletionError?: string | null;
}

export interface EstimateExistViewModel {
  code?: string | null;
  exist?: boolean;
}

export interface EstimateIndex {
  index?: number;
}

export interface IntegrationResponseOfEstimateWithDivision {
  data?: EstimateWithDivision[] | null;
  currentTopValue?: number | null;
  currentSkipValue?: number | null;
  nextSkipValue?: number | null;
}

export type EstimateWithDivision = Estimate & {
  division?: string | null;
};

export type ODataQueryOptionsOfEstimateWithDivision = ODataQueryOptions & {
  ifMatch?: ETagOfEstimateWithDivision | null;
  ifNoneMatch?: ETagOfEstimateWithDivision | null;
};

export type ETagOfEstimateWithDivision = ETag & { [key: string]: any };

export interface EstimateFlattened {
  id?: string;
  processedStatus?: StatusCode;
  partitionId?: string;
  lastModified?: string;
  lastProcessed?: string | null;
  code?: string | null;
  name?: string | null;
  blobUrl?: string | null;
  description?: string | null;
  estimateVersion?: string | null;
  division?: string | null;
  isExcludedEstimate?: boolean;
  isActiveEstimate?: boolean;
  filters_Project_Name?: string | null;
  filters_Project_Number?: string | null;
  filters_Address?: string | null;
  filters_Latitude?: number | null;
  filters_Longitude?: number | null;
  filters_Status?: string | null;
  filters_Engineer?: string | null;
  filters_Resp_Estimator?: string | null;
  filters_Owner?: string | null;
  filters_Bid_Date?: string | null;
  filters_Start_Date?: string | null;
  filters_Review_Date?: string | null;
  filters_Created_Date?: string | null;
  filters_Modified_Date?: string | null;
  filters_Modified_Time?: number | null;
  filters_Bid_Amount?: number | null;
  filters_Takeoff_Amount?: number | null;
  filters_Profit_Bidquan?: number | null;
  filters_Profit_Takeoff?: number | null;
  filters_Tot_Cost?: number | null;
  filters_Wc_State?: string | null;
  filters_Type_Work?: string | null;
  filters_Filter_1?: string | null;
  filters_Filter_2?: string | null;
  filters_Filter_3?: string | null;
  filters_Filter_4?: string | null;
  filters_Filter_5?: string | null;
  filters_Filter_6?: string | null;
  filters_User_Ascii1?: string | null;
  filters_User_Ascii2?: string | null;
  filters_User_Ascii3?: string | null;
  filters_User_Ascii4?: string | null;
  filters_User_Ascii5?: string | null;
  filters_User_Ascii6?: string | null;
  filters_User_Ascii7?: string | null;
  filters_User_Ascii8?: string | null;
  filters_User_Ascii9?: string | null;
  filters_User_Ascii10?: string | null;
  filters_User_Ascii11?: string | null;
  filters_User_Ascii12?: string | null;
  filters_User_Ascii13?: string | null;
  filters_User_Ascii14?: string | null;
  filters_User_Ascii15?: string | null;
  filters_User_Ascii16?: string | null;
  filters_User_Ascii17?: string | null;
  filters_User_Ascii18?: string | null;
  filters_User_Ascii19?: string | null;
  filters_User_Ascii20?: string | null;
  filters_User_Vendor1?: string | null;
  filters_User_Vendor2?: string | null;
  filters_User_Vendor3?: string | null;
  filters_User_Vendor4?: string | null;
  filters_User_Vendor5?: string | null;
  filters_User_Vendor6?: string | null;
  filters_User_Flag1?: string | null;
  filters_User_Flag2?: string | null;
  filters_User_Flag3?: string | null;
  filters_User_Flag4?: string | null;
  filters_User_Flag5?: string | null;
  filters_User_Flag6?: string | null;
  filters_User_Flag7?: string | null;
  filters_User_Flag8?: string | null;
  filters_User_Flag9?: string | null;
  filters_User_Flag10?: string | null;
  filters_User_Flag11?: string | null;
  filters_User_Flag12?: string | null;
  filters_User_Code1?: string | null;
  filters_User_Name2?: string | null;
  filters_User_Integer1?: number | null;
  filters_User_Integer2?: number | null;
  filters_User_Integer3?: number | null;
  filters_User_Integer4?: number | null;
  filters_User_Integer5?: number | null;
  filters_User_Number1?: number | null;
  filters_User_Number2?: number | null;
  filters_User_Number3?: number | null;
  filters_User_Number4?: number | null;
  filters_User_Number5?: number | null;
  filters_User_Number6?: number | null;
  filters_User_Number7?: number | null;
  filters_User_Number8?: number | null;
  filters_User_Number9?: number | null;
  filters_User_Number10?: number | null;
  filters_User_Number11?: number | null;
  filters_User_Number12?: number | null;
  filters_User_Number13?: number | null;
  filters_User_Number14?: number | null;
  filters_User_Number15?: number | null;
  filters_User_Number16?: number | null;
  filters_User_Number17?: number | null;
  filters_User_Number18?: number | null;
  filters_User_Number19?: number | null;
  filters_User_Number20?: number | null;
  filters_User_Perc1?: number | null;
  filters_User_Perc2?: number | null;
  filters_User_Def_Date?: string | null;
  filters_User_Date2?: string | null;
  filters_User_Date3?: string | null;
  filters_User_Date4?: string | null;
  filters_User_Date5?: string | null;
  filters_User_Date6?: string | null;
  filters_User_Date7?: string | null;
  filters_User_Date8?: string | null;
  filters_User_Date9?: string | null;
  filters_User_Date10?: string | null;
  filters_User_Date11?: string | null;
  totals_BaseLabor_Direct?: number;
  totals_BaseLabor_Indirect?: number;
  totals_BaseLabor_Total?: number;
  totals_Burden_Direct?: number;
  totals_Burden_Indirect?: number;
  totals_Burden_Total?: number;
  totals_TotalLabor_Direct?: number;
  totals_TotalLabor_Indirect?: number;
  totals_TotalLabor_Total?: number;
  totals_PermanentMaterial_Direct?: number;
  totals_PermanentMaterial_Indirect?: number;
  totals_PermanentMaterial_Total?: number;
  totals_ConstructionMaterial_Direct?: number;
  totals_ConstructionMaterial_Indirect?: number;
  totals_ConstructionMaterial_Total?: number;
  totals_Subcontract_Direct?: number;
  totals_Subcontract_Indirect?: number;
  totals_Subcontract_Total?: number;
  totals_EquipmentOperatingExpense_Direct?: number;
  totals_EquipmentOperatingExpense_Indirect?: number;
  totals_EquipmentOperatingExpense_Total?: number;
  totals_CompanyEquipment_Direct?: number;
  totals_CompanyEquipment_Indirect?: number;
  totals_CompanyEquipment_Total?: number;
  totals_RentedEquipment_Direct?: number;
  totals_RentedEquipment_Indirect?: number;
  totals_RentedEquipment_Total?: number;
  totals_Misc1_Direct?: number;
  totals_Misc1_Indirect?: number;
  totals_Misc1_Total?: number;
  totals_Misc2_Direct?: number;
  totals_Misc2_Indirect?: number;
  totals_Misc2_Total?: number;
  totals_Misc3_Direct?: number;
  totals_Misc3_Indirect?: number;
  totals_Misc3_Total?: number;
  totals_TotalEntryCost_Takeoff_Direct?: number;
  totals_TotalEntryCost_Takeoff_Indirect?: number;
  totals_TotalEntryCost_Takeoff_Total?: number;
  totals_TotalEntryCost_Bid_Direct?: number;
  totals_TotalEntryCost_Bid_Indirect?: number;
  totals_TotalEntryCost_Bid_Total?: number;
  totals_BalMarkup_Bid?: number;
  totals_ActualMarkup_Bid?: number;
  totals_BalancedBid_Bid?: number;
  totals_BidTotal_Bid?: number;
  totals_AddonBondTotal?: number;
  totals_TotalEqp_Direct?: number;
  totals_TotalEqp_Indirect?: number;
  totals_TotalEqp_Total?: number;
  totals_Manhours_Direct?: number;
  totals_Manhours_Indirect?: number;
  totals_Manhours_Total?: number;
  totals_Addon_Cost?: number;
  totals_Bond?: number;
  totals_Addon_Markup?: number;
  totals_Addon_Total?: number;
  totals_TotalCost_Takeoff?: number;
  totals_Job_Duration?: number;
  totals_BalMarkup_Takeoff?: number;
  totals_ActualMarkup_Takeoff?: number;
  totals_BalancedBid_Takeoff?: number;
  totals_PassThrough_Cost?: number;
  totals_PassThrough_Adjustment?: number;
  totals_PassThrough_Total?: number;
  totals_TotalCost_Bid_LessPassThrough?: number;
  totals_TotalCost_Bid?: number;
  totals_Total_User_Adjustments?: number;
  totals_Total_User_Adjustments_Cost?: number;
  totals_Desired_Bid?: number;
  totals_Desired_Bid_Diff?: number;
  totals_BidTotal_Takeoff?: number;
  totals_TotalCost_Takeoff_LessPassThrough?: number;
  totals_MarkupPercentCost_Takeoff?: number;
  totals_MarkupPercentCost_Bid?: number;
  totals_MarkupPercentSales_Takeoff?: number;
  totals_MarkupPercentSales_Bid?: number;
  totals_TotalCost_Takeoff_LessPassThroughAndAdjustments?: number;
  totals_TotalCost_Bid_LessPassThroughAndAdjustments?: number;
  totals_TotalEntryCost_Bid_Spread_Indirect?: number;
  totals_AddonBond_Cost_Bid_Spread_Total?: number;
  totals_TotalCost_Bid_Spread?: number;
  totals_BalMarkup_Bid_Spread?: number;
  totals_ActualMarkup_Bid_Spread?: number;
  totals_Actv_User_Quan1?: number;
  totals_Actv_User_Quan2?: number;
  totals_Actv_User_Quan3?: number;
  totals_Actv_User_Quan4?: number;
  totals_Open_Biditems?: number;
  totals_Minority_Goal_Percent?: number;
  totals_Minority_Goal_Total?: number;
  totals_Minority_Current_Percent?: number;
  totals_Minority_Current_Total?: number;
  empId?: string | null;
}

export type ODataQueryOptionsOfEstimateFlattened = ODataQueryOptions & {
  ifMatch?: ETagOfEstimateFlattened | null;
  ifNoneMatch?: ETagOfEstimateFlattened | null;
};

export type ETagOfEstimateFlattened = ETag & { [key: string]: any };

export interface ReportsDto {
  reports?: ReportViewModel[] | null;
}

export interface UniqueEstimateFilters {
  businessUnitCode?: string[] | null;
  filter1?: string[] | null;
  filter2?: string[] | null;
  filter3?: string[] | null;
  filter4?: string[] | null;
  filter5?: string[] | null;
  filter6?: string[] | null;
  estimator?: string[] | null;
  status?: string[] | null;
  owner?: string[] | null;
  wcState?: string[] | null;
  typeWork?: string[] | null;
  engineer?: string[] | null;
  projectName?: string[] | null;
  projectNumber?: string[] | null;
  [key: string]: string[] | null | undefined;
}
export interface ActivityTotal {
  estimateId: string;
  activityCode: string;
  units: string;
  cost: number;
  quantity: number;
}

export interface ActivityFormulaDto {
  match: FormulaMatchOptions;
  expression: string;
  unit: string;
}

export enum FormulaMatchOptions {
  StartsWith,
  Exact
}

export type Activity = Entity & {
  biditemId?: string;
  /**
   * The activity code.
   */
  activityCode?: string;
  /**
   * The bid item code.
   */
  biditemCode?: string;
  /**
   * The description.
   */
  description?: string;
  /**
   * The quantity.
   */
  quantity?: number;
  /**
   * The unit of measure.
   */
  units?: string;
  /**
   * The factorable.
   */
  factorable?: string;
  /**
   * The save skip cost.
   */
  saveSkipCost?: string;
  /**
   * The worker's comp code.
   */
  workersCompCode?: string;
  /**
   * The marine worker's comp.
   */
  marineWorkersComp?: string;
  /**
   * The summary code.
   */
  summaryCode?: string;
  /**
   * The calendar.
   */
  calendar?: string;
  /**
   * The hours per day.
   */
  hoursPerDay?: number;
  /**
   * The CPM prior item.
   */
  cpmPriorItem?: string;
  /**
   * The sort option.
   */
  sortOption?: string;
  /**
   * The skip cost.
   */
  skipCost?: string;
  /**
   * The crew.
   */
  crew?: string;
  /**
   * The crew hours.
   */
  crewHours?: number;
  /**
   * The crew percent.
   */
  crewPercent?: number;
  /**
   * The production type.
   */
  productionType?: string;
  /**
   * The production rate.
   */
  productionRate?: number;
  /**
   * The user1.
   */
  user1?: number;
  /**
   * The user2.
   */
  user2?: number;
  /**
   * The user3.
   */
  user3?: number;
  /**
   * The user4.
   */
  user4?: number;
  /**
   * The accounting code 1.
   */
  accountingJCCode1?: string;
  /**
   * The accounting code 2.
   */
  accountingJCCode2?: string;
  /**
   * The alternate cost code.
   */
  alternateCostCode?: string;
  /**
   * The tagged.
   */
  tagged?: string;
  /**
   * The image attached.
   */
  imageAttached?: string;
  /**
   * The resoirce factor risk percent.
   */
  resourceFactorRiskPercent?: number;
  /**
   * The efficient percent.
   */
  efficientPercent?: number;
  /**
   * The calculated duration.
   */
  calculatedDuration?: number;
  /**
   * The modified crew.
   */
  modifiedCrew?: string;
  /**
   * The user flag 1.
   */
  userFlag1?: string;
  /**
   * The user flag 2.
   */
  userFlag2?: string;
  /**
   * The man hours.
   */
  manHours?: number;
  /**
   * The direct total.
   */
  directTotal?: number;
  /**
   * The crew cost.
   */
  crewCost?: number;
  /**
   * The crew cost with material.
   */
  crewCost_WithMaterial?: number;
  /**
   * The notes.
   */
  notes?: string;
  /**
   * The report group1.
   */
  reportGroup1?: string;
  /**
   * The report group2.
   */
  reportGroup2?: string;
  /**
   * The report group3.
   */
  reportGroup3?: string;
  /**
   * The report group4.
   */
  reportGroup4?: string;
  /**
   * The report group5.
   */
  reportGroup5?: string;
  /**
   * The report group6.
   */
  reportGroup6?: string;
  /**
   * The factor.
   */
  factor?: number;
  /**
   * The days.
   */
  days?: number;
  /**
   * The labor.
   */
  labor?: number;
  /**
   * The burden.
   */
  burden?: number;
  /**
   * The permanent material.
   */
  permanentMaterial?: number;
  /**
   * The construction material.
   */
  constructionMaterial?: number;
  /**
   * The subcontract.
   */
  subcontract?: number;
  /**
   * The equipment operating expense.
   */
  equipmentOperatingExpense?: number;
  /**
   * The company equipment.
   */
  companyEquipment?: number;
  /**
   * The rented equipment
   */
  rentedEquipment?: number;
  /**
   * The misc1.
   */
  misc1?: number;
  /**
   * The misc2.
   */
  misc2?: number;
  /**
   * The misc3.
   */
  misc3?: number;
  /**
   * The account revenue code.
   */
  accountingRevenueCode?: string;
  /**
   * The accounting description.
   */
  accountingDescription?: string;
  /**
   * The accounting quantity.
   */
  accountingQuantity?: number;
  /**
   * The accounting units.
   */
  accountingUnits?: string;
  /**
   * The accounting change order.
   */
  accountingChangeOrder?: string;
  /**
   * The HeavyJob code.
   */
  heavyJobCode?: string;
  /**
   * The HeavyJob description
   */
  heavyJobDescription?: string;
  /**
   * The HeavyJob quantity.
   */
  heavyJobQuantity?: number;
  /**
   * The HeavyJob unit.
   */
  heavyJobUnit?: string;
  /**
   * The link flag.
   */
  linkFlag?: number;
  /**
   * The add quantity.
   */
  addQuantity?: string;
  /**
   * The alternate description.
   */
  alternateDescription?: string;
  /**
   * The safety code.
   */
  safetyCode?: string;
  /**
   * The scheduling equivalent CPM item.
   */
  schedulingEquivalentCPMItem?: string;
  /**
   * The scheduling start1.
   */
  schedulingStart1?: number;
  /**
   * The scheduling duration1.
   */
  schedulingDuration1?: number;
  /**
   * The scheduling percent1.
   */
  schedulingPercent1?: number;
  /**
   * The schduling start1.
   */
  schedulingStart2?: number;
  /**
   * The scheduling duration2.
   */
  schedulingDuration2?: number;
  /**
   * The scheduling percent2.
   */
  schedulingPercent2?: number;
  /**
   * The scheduling start3.
   */
  schedulingStart3?: number;
  /**
   * The scheduling duration3.
   */
  schedulingDuration3?: number;
  /**
   * The scheduling percent3.
   */
  schedulingPercent3?: number;
  /**
   * The scheduling start4.
   */
  schedulingStart4?: number;
  /**
   * The scheduling duration4.
   */
  schedulingDuration4?: number;
  /**
   * The scheduling percent4.
   */
  schedulingPercent4?: number;
  /**
   * The scheduling CPM lag.
   */
  schedulingCPMLag?: number;
  /**
   * The scheduling bar option.
   */
  schedulingBarOption?: string;
  /**
   * The scheduling sub net.
   */
  schedulingSubNet?: string;
  /**
   * The scheduling bar line number.
   */
  schedulingBarLineNum?: number;
  /**
   * The scheduling CPM export description.
   */
  schedulingCPMExportDescription?: string;
  /**
   * The scheduling CPM sequential.
   */
  schedulingCPMSequential?: string;
};

export type Biditem = Entity & {
  estimateId?: string;
  /**
   * The bid.
   */
  bid?: string;
  /**
   * The description.
   */
  description?: string;
  /**
   * The activities.
   */
  activities?: Activity[] | null;
  /**
   * The type.
   */
  type?: string;
  /**
   * The quantity.
   */
  quantity?: number;
  /**
   * The engineering quantity.
   */
  engineeringQuantity?: number;
  /**
   * The unit of meaure.
   */
  units?: string;
  /**
   * The engineering bid.
   */
  engineeringBid?: string;
  /**
   * The image attached.
   */
  imageAttached?: string;
  /**
   * The subtotal level.
   */
  subtotalLevel?: string;
  /**
   * The other option.
   */
  otherOptions?: string;
  /**
   * The WBS code.
   */
  wbsCode?: string;
  /**
   * The pass through.
   */
  passThrough?: string;
  /**
   * The marine land.
   */
  marineLand?: string;
  /**
   * The parent.
   */
  parent?: string;
  /**
   * The subdivided.
   */
  subDivided?: string;
  /**
   * The alternate units.
   */
  alternateUnits?: string;
  /**
   * The alternate description.
   */
  alternateDescription?: string;
  /**
   * The low bid.
   */
  lowBid?: number;
  /**
   * The average bid.
   */
  averageBid?: number;
  /**
   * The high bid.
   */
  highBid?: number;
  /**
   * The state code.
   */
  stateCode?: string;
  /**
   * The unit cost holding account.
   */
  unitCostHoldingAccount?: string;
  /**
   * The bid history id.
   */
  bidHistoryID?: number;
  /**
   * The tax percent.
   */
  taxPercent?: number;
  /**
   * The taxable.
   */
  taxable?: string;
  /**
   * The bid price.
   */
  bidPrice?: number;
  /**
   * The summary group1.
   */
  summaryGroup1?: string;
  /**
   * The summary group2.
   */
  summaryGroup2?: string;
  /**
   * The summary group3.
   */
  summaryGroup3?: string;
  /**
   * The summary group4.
   */
  summaryGroup4?: string;
  /**
   * The summary group5.
   */
  summaryGroup5?: string;
  /**
   * The summary group6.
   */
  summaryGroup6?: string;
  /**
   * The review flag.
   */
  reviewFlag?: string;
  /**
   * The estimate initials.
   */
  estInitials?: string;
  /**
   * The sort code.
   */
  sortCode?: string;
  /**
   * The cost notes.
   */
  costNotes?: string;
  /**
   * The bid notes.
   */
  bidNotes?: string;
  /**
   * The factor.
   */
  factor?: number;
  /**
   * The scheduling start1.
   */
  schedulingStart1?: number;
  /**
   * The scheduling duration1.
   */
  schedulingDuration1?: number;
  /**
   * The scheduling percent1.
   */
  schedulingPercent1?: number;
  /**
   * The scheduling start2.
   */
  schedulingStart2?: number;
  /**
   * The scheduling duration2.
   */
  schedulingDuration2?: number;
  /**
   * The scheduling percent2.
   */
  schedulingPercent2?: number;
  /**
   * The scheduling start3.
   */
  schedulingStart3?: number;
  /**
   * The scheduling duration3.
   */
  schedulingDuration3?: number;
  /**
   * The scheduling percent3.
   */
  schedulingPercent3?: number;
  /**
   * The scheduling start4.
   */
  schedulingStart4?: number;
  /**
   * The scheduling duration4.
   */
  schedulingDuration4?: number;
  /**
   * The scheduling percent4.
   */
  schedulingPercent4?: number;
  /**
   * The bid item percent value.
   */
  biditemPercentValue?: number;
  /**
   * The pricing status.
   */
  pricingStatus?: string;
  /**
   * The user activity1.
   */
  userActivity1?: number;
  /**
   * The user activity2.
   */
  userActivity2?: number;
  /**
   * The user activity3.
   */
  userActivity3?: number;
  /**
   * The user activity4.
   */
  userActivity4?: number;
  /**
   * The labor.
   */
  labor?: number;
  /**
   * The burden.
   */
  burden?: number;
  /**
   * The permanent material.
   */
  permanentMaterial?: number;
  /**
   * The construction material.
   */
  constructionMaterial?: number;
  /**
   * The subcontract.
   */
  subcontract?: number;
  /**
   * The equipment operating expense.
   */
  equipmentOperatingExpense?: number;
  /**
   * The company equipment.
   */
  companyEquipment?: number;
  /**
   * The rented equipment.
   */
  rentedEquipment?: number;
  /**
   * Misc1.
   */
  misc1?: number;
  /**
   * Misc2.
   */
  misc2?: number;
  /**
   * Misc3.
   */
  misc3?: number;
  /**
   * The direct total.
   */
  directTotal?: number;
  /**
   * The equipment account dollars.
   */
  equipmentAccountDollars?: number;
  /**
   * The indirect total.
   */
  indirectTotal?: number;
  /**
   * The addon bond.
   */
  addonBond?: number;
  /**
   * The total cost.
   */
  totalCost?: number;
  /**
   * The total cost adjustment to bid quantity.
   */
  totalCostAdjToBidQuantity?: number;
  /**
   * The markup.
   */
  markup?: number;
  /**
   * The total takeoff.
   */
  totalTakeoff?: number;
  /**
   * The total balanced.
   */
  totalBalanced?: number;
  /**
   * The man-hours.
   */
  manhours?: number;
  /**
   * The escalation total cost.
   */
  escalationTotalCost?: number;
  /**
   * The fixed cost.
   */
  fixedCost?: number;
  /**
   * The freeze bid.
   */
  freezeBid?: string;
  /**
   * The sequence item number.
   */
  sequenceItemNumber?: number;
  /**
   * The holding account activity quantity.
   */
  holdingAccountActivityQuantity?: number;
  /**
   * The account revenue code.
   */
  accountingRevenueCode?: string;
  /**
   * The scheduling CPM code.
   */
  schedulingCPMCode?: string;
  /**
   * The scheduling CPM overlap.
   */
  schedulingCPMOverlap?: number;
  /**
   * The schduling calculated days.
   */
  schedulingCalc_Days?: number;
  /**
   * The folder.
   */
  folder?: string;
  /**
   * The link flag.
   */
  linkFlag?: number;
  /**
   * The single alternate.
   */
  singleAlternate?: string;
  /**
   * The package alternate.
   */
  packageAlternate?: string;
  /**
   * The user flag1.
   */
  userFlag1?: string;
  /**
   * The user flag2.
   */
  userFlag2?: string;
  /**
   * The child bid price.
   */
  childBidPrice?: number;
  /**
   * The zero price text.
   */
  zeroPriceText?: string;
  /**
   * The unit cost item.
   */
  unitCostItem?: string;
};

export type ODataQueryOptionsOfBusinessUnit = ODataQueryOptions & {
  ifMatch?: ETagOfBusinessUnit | null;
  ifNoneMatch?: ETagOfBusinessUnit | null;
};

export type ETagOfBusinessUnit = ETag & { [key: string]: any };

export type ODataQueryOptionsOfDatabasePartition = ODataQueryOptions & {
  ifMatch?: ETagOfDatabasePartition | null;
  ifNoneMatch?: ETagOfDatabasePartition | null;
};

export type ETagOfDatabasePartition = ETag & { [key: string]: any };

export type KeyIndicator = Entity & {
  /**
   * The partition id.
   */
  partitionId?: string;
  /**
   * The partition.
   */
  partition?: DatabasePartition | null;
  /**
   * The heavybid id.
   */
  heavyBidId?: string | null;
  /**
   * The key indicator origin type.
   */
  origin?: KeyIndicatorOriginType;
  /**
   * The result name.
   */
  resultName?: string | null;
  /**
   * The result decimal length.
   */
  resultDecimalLength?: number;
  /**
   * The left display name.
   */
  leftDisplayName?: string | null;
  /**
   * The left operand type.
   */
  leftOperandType?: KeyIndicatorOperandType;
  /**
   * The left operand value.
   */
  leftOperandValue?: string | null;
  /**
   * The left operand decimal length.
   */
  leftOperandDecimalLength?: number;
  /**
   * The operator.
   */
  operator?: string | null;
  /**
   * The right display name.
   */
  rightDisplayName?: string | null;
  /**
   * The right operand type.
   */
  rightOperandType?: KeyIndicatorOperandType;
  /**
   * The right operand value.
   */
  rightOperandValue?: string | null;
  /**
   * The right operand decimal length.
   */
  rightOperandDecimalLength?: number;
};

export type KeyIndicatorOriginType = number;

export type KeyIndicatorOperandType = number;

export type ODataQueryOptionsOfKeyIndicator = ODataQueryOptions & {
  ifMatch?: ETagOfKeyIndicator | null;
  ifNoneMatch?: ETagOfKeyIndicator | null;
};

export type ETagOfKeyIndicator = ETag & { [key: string]: any };

export type Resource = Entity & {
  /**
   * The activity id.
   */
  activityId?: string;
  /**
   * The resource code.
   */
  resourceCode?: string;
  /**
   * The activity code.
   */
  activityCode?: string;
  /**
   * The bid item code.
   */
  biditemCode?: string;
  /**
   * The description.
   */
  description?: string;
  /**
   * The type cost.
   */
  typeCost?: string;
  /**
   * The type of equipment rent.
   */
  typeOfEquipmentRent?: string;
  /**
   * The sub type.
   */
  subType?: string;
  /**
   * The quantity.
   */
  quantity?: number;
  /**
   * The unit of measure.
   */
  units?: string;
  /**
   * The percent.
   */
  percent?: number;
  /**
   * The unit price.
   */
  unitPrice?: number;
  /**
   * The currency.
   */
  currency?: string;
  /**
   * The sub type cost.
   */
  subTypeCost?: number;
  /**
   * The total.
   */
  total?: number;
  /**
   * The factorable.
   */
  factorable?: string;
  /**
   * The factor.
   */
  factor?: number;
  /**
   * The supplemental description.
   */
  supplementalDescription?: string;
  /**
   * The skip cost.
   */
  skipCost?: string;
  /**
   * The number of pieces.
   */
  pieces?: number;
  /**
   * The operating percent.
   */
  operatingPercent?: number;
  /**
   * The rent percent.
   */
  rentPercent?: number;
  /**
   * The production code.
   */
  productionCode?: string;
  /**
   * The production.
   */
  production?: number;
  /**
   * The crew code.
   */
  crewCode?: string;
  /**
   * The equipment operator code.
   */
  equipmentOperatorCode?: string;
  /**
   * The escalation percent.
   */
  escalationPercent?: number;
  /**
   * The plug indicator.
   */
  plugIndicator?: string;
  /**
   * The calendar.
   */
  calendar?: string;
  /**
   * The start day,
   */
  startDay?: string;
  /**
   * The special schedule.
   */
  specialSchedule?: string;
  /**
   * The man hour adjustment per shift.
   */
  manHourAdjustmentPerShift?: number;
  /**
   * The selected vendor.
   */
  selectedVendor?: string;
  /**
   * The man hour unit.
   */
  manHourUnit?: number;
  /**
   * The escalation override.
   */
  escalationOverride?: string;
  /**
   * The unused flag3.
   */
  unusedFlag3?: string;
  /**
   * The link flag.
   */
  linkflag?: number;
  /**
   * The class.
   */
  class?: string;
  /**
   * The alternate quantity.
   */
  alternateQuantity?: number;
  /**
   * The alternate unit.
   */
  alternateUnits?: string;
  /**
   * The resource text1.
   */
  resourceText1?: string;
  /**
   * The resource text2.
   */
  resourceText2?: string;
};

export type ODataQueryOptionsOfSystemInfo = ODataQueryOptions & {
  ifMatch?: ETagOfSystemInfo | null;
  ifNoneMatch?: ETagOfSystemInfo | null;
};

export type ETagOfSystemInfo = ETag & { [key: string]: any };

export interface EstimateKPIs {
  kpis?: Kpi[] | null;
  estimate?: EstimateSummaryExtended | null;
}

export interface Kpi {
  id: string;
  name: string;
  leftField: string;
  rightField: string;
  operator: string;
  values: KPIValue[];
  stats: KPIStats;
  currentValues: KPICurrentValues;
  resultDecimals: number;
  rightDecimals: number;
  leftDecimals: number;
  originType: KeyIndicatorOriginType;
}

export interface KPIValue {
  value: number;
  estimateCode: string;
  estimateName: string;
}

export interface KPIStats {
  iqr: number;
  min: number;
  max: number;
  q1: number;
  q2: number;
  q3: number;
  lower: number;
  lowerActual: number;
  upper: number;
  upperActual: number;
}

export interface KPICurrentValues {
  result: number;
  left: number;
  right: number;
}

export interface GenericResponse {
  success?: boolean;
  errorMessage?: string | null;
}

export interface KeyIndicatorViewModel {
  heavyBidId?: string | null;
  origin?: KeyIndicatorOriginType;
  resultName?: string | null;
  resultDecimalLength?: number;
  leftDisplayName?: string | null;
  leftOperandType?: KeyIndicatorOperandType;
  leftOperandValue?: string | null;
  leftOperandDecimalLength?: number;
  operator?: string | null;
  rightDisplayName?: string | null;
  rightOperandType?: KeyIndicatorOperandType;
  rightOperandValue?: string | null;
  rightOperandDecimalLength?: number;
}

export interface PingViewModel {
  success?: boolean;
  pingMessage?: string | null;
  user?: CurrentUser | null;
}

export interface CurrentUser {
  companyId?: string;
  userId?: string;
  companyName?: string | null;
  businessUnitName?: string | null;
  connectionString?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  insightsAccess?: boolean;
  isAzureClient?: boolean;
  businessUnits?: string[] | null;
  canViewAllBusinessUnits?: boolean;
}

export interface QuoteFolderSummaryDto {
  id?: string;
  folder?: string | null;
  description?: string | null;
  type?: string | null;
  estimatorInitials?: string | null;
  selectedVendor?: string | null;
  folderTotal?: number;
  hasPluggedPrices?: boolean;
}

export interface QuoteFolderDto {
  id: string;
  quoteMasterId?: string;
  folder: string;
  description?: string | null;
  type?: string | null;
  estimatorInitials?: string | null;
  notes?: string | null;
  breakout?: string | null;
  selectedVendor?: string | null;
  showBiditem?: string | null;
  showActivity?: string | null;
  showResource?: string | null;
  useSelfPerform?: string | null;
  totalAdditiveFactored?: number;
  totalAdditiveNonFactored?: number;
  totalNonAdditiveFactored?: number;
  totalNonAdditiveNonFactored?: number;
  updatedSelectedVendorTotal?: number;
  syncRequired?: boolean;
  folderTotal?: number;
  quoteItems?: QuoteItemDto[] | null;
  quoteVendors?: QuoteVendorDto[] | null;
  adHocAdjustmentItems?: QuoteItemDto[] | null;
}

export interface QuoteItemDto {
  id?: string;
  quoteFolderId?: string;
  biditem?: string | null;
  activity?: string | null;
  resource?: string | null;
  plugPrice?: number;
  unit?: string | null;
  quantityAdditiveFactored?: number;
  quantityAdditiveNonFactored?: number;
  quantityNonAdditiveFactored?: number;
  quantityNonAdditiveNonFactored?: number;
  currency?: string | null;
  isSelfPerform?: string | null;
  selfAmount?: number;
  selectedVendor?: string | null;
  alternateSort?: string | null;
  alternateDescription?: string | null;
  addendumNumber?: string | null;
  actualPlug?: number;
  alternateQuantity?: number;
  unitCostInEstimate?: number;
  clientNo?: string | null;
  isAdhocAdjustmentItem?: boolean;
  syncRequired?: boolean;
  quotes?: QuoteDto[] | null;
}

export interface QuoteDto {
  id?: string;
  quoteItemId?: string;
  quoteVendorId?: string;
  quoteVendorCode?: string | null;
  plugIndicator?: string | null;
  previousPrice?: number;
  previousUnit?: string | null;
  quantityAdditiveFactored?: number;
  quantityAdditiveNonFactored?: number;
  quantityNonAdditiveFactored?: number;
  quantityNonAdditiveNonFactored?: number;
  totalAdditiveFactored?: number;
  totalAdditiveNonFactored?: number;
  totalNonAdditiveFactored?: number;
  totalNonAdditiveNonFactored?: number;
  quoteUnit?: string | null;
  quotePrice?: number;
  quoteCurrency?: string | null;
  lastModified?: string;
  syncRequired?: boolean;
}

export interface QuoteVendorDto {
  id?: string;
  quoteFolderId?: string;
  code?: string | null;
  name?: string | null;
  totalAdditiveFactored?: number;
  totalAdditiveNonFactored?: number;
  totalNonAdditiveFactored?: number;
  totalNonAdditiveNonFactored?: number;
  plugAdditiveFactored?: number;
  plugAdditiveNonFactored?: number;
  plugNonAdditiveFactored?: number;
  plugNonAdditiveNonFactored?: number;
  adHocAdditiveFactored?: number;
  adHocAdditiveNonFactored?: number;
  adHocNonAdditiveFactored?: number;
  adHocNonAdditiveNonFactored?: number;
  vendorContact?: string | null;
  jobTitle?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  cellPhone?: string | null;
  fax?: string | null;
  address1?: string | null;
  address2?: string | null;
  address3?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  bondable?: string | null;
  bondRate?: number;
  mobilizationAmount?: number;
  adjustmentPercent?: number;
  adjustmentAmount?: number;
  minorityStatuses?: MinorityClass[] | null;
  minority1?: MinorityClass | null;
  minority2?: MinorityClass | null;
  minority3?: MinorityClass | null;
  minority4?: MinorityClass | null;
  minority5?: MinorityClass | null;
  excluded?: string | null;
  notes?: string | null;
  pluggedAmount?: number;
  quotedAmount?: number;
  totalAdditive?: number;
  syncRequired?: boolean;
  lastModified?: string;
  totalAdjustments?: number;
  vendorTotal?: number;
}

export interface MinorityClass {
  /**
   * HeavyBid Field: MIN_CLASS
   */
  code?: string;
  /**
   * HeavyBid Field: MIN_PERCENT
   */
  percent?: number;
  /**
   * HeavyBid Field: CERT#
   */
  certificateNumber?: string;
}

export interface QuoteMasterDto {
  id?: string;
  estimateId?: string;
  adjustToBidQuantity?: string | null;
  syncRequired?: boolean;
  quoteFolders?: QuoteFolderDto[] | null;
}

export interface AcceptQuoteDto {
  estimateId?: string;
  vendorCode?: string | null;
  prices?: QuotePriceDto[] | null;
  adhocAdjustments?: QuoteAdhocAdjustmentDto[] | null;
  notes?: QuoteVendorNotesDto[] | null;
}

export interface QuotePriceDto {
  quoteItemId?: string;
  quoteFolderId?: string;
  quotePrice?: number;
}

export interface QuoteAdhocAdjustmentDto {
  id?: string;
  quoteFolderId?: string;
  description?: string | null;
  total?: number;
}

export interface QuoteVendorNotesDto {
  quoteFolderId?: string;
  notes?: string | null;
}

export interface QuoteVendorSummaryDto {
  id?: string;
  quoteFolderId?: string;
  code?: string | null;
  name?: string | null;
  vendorContact?: string | null;
  jobTitle?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  cellPhone?: string | null;
  fax?: string | null;
  address1?: string | null;
  address2?: string | null;
  address3?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  adjustmentPercent?: number;
  adjustmentAmount?: number;
  minorityStatuses?: MinorityClass[] | null;
  pluggedAmount?: number;
  quotedAmount?: number;
  totalAdditive?: number;
}

export interface EstimateViewModel {
  id?: string;
  partitionId?: string;
  code?: string | null;
  name?: string | null;
  blobUrl?: string | null;
  processedStatus?: StatusCode;
  description?: string | null;
  estimateVersion?: string | null;
  isActiveEstimate?: boolean;
  filters?: EstimateFilters | null;
  totals?: EstimateTotals | null;
  biditems?: BiditemViewModel[] | null;
  attachments?: AttachmentViewModel[] | null;
}

export interface AttachmentViewModel {
  estimateId?: string;
  lookupId?: string;
  blobUrl?: string | null;
  heavyBidFileName?: string | null;
  attachmentType?: AttachmentType;
}

export type AttachmentType = number;

export interface UploadViewModel {
  success?: number;
  estimateId?: string;
  blobURL?: string | null;
  blobHash?: string | null;
  msg?: string | null;
}

export interface IntegrationResponseOfActivityIntegrationDto {
  data?: ActivityIntegrationDto[] | null;
  currentTopValue?: number | null;
  currentSkipValue?: number | null;
  nextSkipValue?: number | null;
}

export interface ActivityIntegrationDto {
  /**
   * The unique activity id.
   */
  id?: string;
  /**
   * The unique estimate id.
   */
  estimateId?: string;
  /**
   * The HeavyBid estimate code.
   */
  estimateCode?: string | null;
  /**
   * The last modified date.
   */
  lastModified?: string;
  /**
   * The unique biditem id.
   */
  biditemId?: string;
  /**
   * The HeavyBid activity code.
   */
  activityCode?: string | null;
  /**
   * The HeavyBid biditem code.
   */
  biditemCode?: string | null;
  /**
   * The description.
   */
  description?: string | null;
  /**
   * The quantity.
   */
  quantity?: number;
  /**
   * The unit of measure.
   */
  units?: string | null;
  /**
   * The factorable.
   */
  factorable?: string | null;
  /**
   * The worker's comp code.
   */
  workersCompCode?: string | null;
  /**
   * The marine worker's comp code.
   */
  marineWorkersComp?: string | null;
  /**
   * The summary code.
   */
  summaryCode?: string | null;
  /**
   * The calendar.
   */
  calendar?: string | null;
  /**
   * The hours per day.
   */
  hoursPerDay?: number;
  /**
   * The crew code.
   */
  crew?: string | null;
  /**
   * The crew hours.
   */
  crewHours?: number;
  /**
   * The crew percent.
   */
  crewPercent?: number;
  /**
   * The production type.
   */
  productionType?: string | null;
  /**
   * The production rate.
   */
  productionRate?: number;
  /**
   * The user defined quantity 1.
   */
  user1?: number;
  /**
   * The user defined quantity 2.
   */
  user2?: number;
  /**
   * The user defined quantity 3.
   */
  user3?: number;
  /**
   * The user defined quantity 4.
   */
  user4?: number;
  /**
   * The accounting code 1.
   */
  accountingJCCode1?: string | null;
  /**
   * The accounting code 2.
   */
  accountingJCCode2?: string | null;
  /**
   * The alternate cost code.
   */
  alternateCostCode?: string | null;
  /**
   * The resource factor risk percent.
   */
  resourceFactorRiskPercent?: number;
  /**
   * The efficiency percent.
   */
  efficientPercent?: number;
  /**
   * The calculated duration.
   */
  calculatedDuration?: number;
  /**
   * The modified crew.
   */
  modifiedCrew?: string | null;
  /**
   * The user flag 1.
   */
  userFlag1?: string | null;
  /**
   * The user flag 2.
   */
  userFlag2?: string | null;
  /**
   * The total man hours.
   */
  manHours?: number;
  /**
   * The direct total.
   */
  directTotal?: number;
  /**
   * The total crew cost.
   */
  crewCost?: number;
  /**
   * The total crew cost with material.
   */
  crewCost_WithMaterial?: number;
  /**
   * The notes.
   */
  notes?: string | null;
  /**
   * The report group1.
   */
  reportGroup1?: string | null;
  /**
   * The report group2.
   */
  reportGroup2?: string | null;
  /**
   * The report group3.
   */
  reportGroup3?: string | null;
  /**
   * The report group4.
   */
  reportGroup4?: string | null;
  /**
   * The report group5.
   */
  reportGroup5?: string | null;
  /**
   * The report group6.
   */
  reportGroup6?: string | null;
  /**
   * The factor.
   */
  factor?: number;
  /**
   * The days.
   */
  days?: number;
  /**
   * The total labor.
   */
  labor?: number;
  /**
   * The total burden.
   */
  burden?: number;
  /**
   * The total permanent material.
   */
  permanentMaterial?: number;
  /**
   * The total construction material.
   */
  constructionMaterial?: number;
  /**
   * The total subcontract.
   */
  subcontract?: number;
  /**
   * The total equipment operating expense.
   */
  equipmentOperatingExpense?: number;
  /**
   * The total company equipment.
   */
  companyEquipment?: number;
  /**
   * The total rented equipment
   */
  rentedEquipment?: number;
  /**
   * The total misc1.
   */
  misc1?: number;
  /**
   * The total misc2.
   */
  misc2?: number;
  /**
   * The total misc3.
   */
  misc3?: number;
  /**
   * The accounting revenue code.
   */
  accountingRevenueCode?: string | null;
  /**
   * The accounting description.
   */
  accountingDescription?: string | null;
  /**
   * The accounting quantity.
   */
  accountingQuantity?: number;
  /**
   * The accounting units.
   */
  accountingUnits?: string | null;
  /**
   * The accounting change order.
   */
  accountingChangeOrder?: string | null;
  /**
   * The HeavyJob code.
   */
  heavyJobCode?: string | null;
  /**
   * The HeavyJob description
   */
  heavyJobDescription?: string | null;
  /**
   * The HeavyJob quantity.
   */
  heavyJobQuantity?: number;
  /**
   * The HeavyJob unit.
   */
  heavyJobUnit?: string | null;
  /**
   * The add quantity.
   */
  addQuantity?: string | null;
  /**
   * The alternate description.
   */
  alternateDescription?: string | null;
  /**
   * The safety code.
   */
  safetyCode?: string | null;
  /**
   * The CPM prior item.
   */
  cpmPriorItem?: string | null;
  /**
   * The scheduling equivalent CPM item.
   */
  schedulingEquivalentCPMItem?: string | null;
  /**
   * The scheduling start1.
   */
  schedulingStart1?: number;
  /**
   * The scheduling duration1.
   */
  schedulingDuration1?: number;
  /**
   * The scheduling percent1.
   */
  schedulingPercent1?: number;
  /**
   * The schduling start1.
   */
  schedulingStart2?: number;
  /**
   * The scheduling duration2.
   */
  schedulingDuration2?: number;
  /**
   * The scheduling percent2.
   */
  schedulingPercent2?: number;
  /**
   * The scheduling start3.
   */
  schedulingStart3?: number;
  /**
   * The scheduling duration3.
   */
  schedulingDuration3?: number;
  /**
   * The scheduling percent3.
   */
  schedulingPercent3?: number;
  /**
   * The scheduling start4.
   */
  schedulingStart4?: number;
  /**
   * The scheduling duration4.
   */
  schedulingDuration4?: number;
  /**
   * The scheduling percent4.
   */
  schedulingPercent4?: number;
  /**
   * The scheduling CPM lag.
   */
  schedulingCPMLag?: number;
  /**
   * The scheduling bar option.
   */
  schedulingBarOption?: string | null;
  /**
   * The scheduling sub net.
   */
  schedulingSubNet?: string | null;
  /**
   * The scheduling bar line number.
   */
  schedulingBarLineNum?: number;
  /**
   * The scheduling CPM export description.
   */
  schedulingCPMExportDescription?: string | null;
  /**
   * The scheduling CPM sequential.
   */
  schedulingCPMSequential?: string | null;
}

export type ODataQueryOptionsOfActivityIntegrationDto = ODataQueryOptions & {
  ifMatch?: ETagOfActivityIntegrationDto | null;
  ifNoneMatch?: ETagOfActivityIntegrationDto | null;
};

export type ETagOfActivityIntegrationDto = ETag & { [key: string]: any };

export interface IntegrationResponseSingleOfActivityIntegrationDto {
  data?: ActivityIntegrationDto | null;
}

export interface IntegrationResponseOfBiditemIntegrationDto {
  data?: BiditemIntegrationDto[] | null;
  currentTopValue?: number | null;
  currentSkipValue?: number | null;
  nextSkipValue?: number | null;
}

export interface BiditemIntegrationDto {
  /**
   * The unique biditem id.
   */
  id?: string;
  /**
   * The unique estimate id.
   */
  estimateId?: string;
  /**
   * The HeavyBid estimate code.
   */
  estimateCode?: string | null;
  /**
   * The last modified date.
   */
  lastModified?: string;
  /**
   * The HeavyBid biditem code.
   */
  biditemCode?: string | null;
  /**
   * The description.
   */
  description?: string | null;
  /**
   * The type.
   */
  type?: string | null;
  /**
   * The takeoff quantity - this is what costs inside of HeavyBid are based on, not what is submitted to the owner
   */
  quantity?: number;
  /**
   * Bid Quantity - this is the quantity that will be priced and given to the owner when the bid is submitted
   */
  bidQuantity?: number;
  /**
   * The unit of meaure.
   */
  units?: string | null;
  /**
   * The client number or owners number that sometimes is used for bidforms instead of biditem number
   */
  clientNumber?: string | null;
  /**
   * The subtotal level.
   */
  subtotalLevel?: string | null;
  /**
   * The other option.
   */
  otherOptions?: string | null;
  /**
   * The WBS code.
   */
  wbsCode?: string | null;
  /**
   * A flag to represent if the biditem is a passthrough item.
   */
  passThrough?: string | null;
  /**
   * A flag to represent if the biditem is marine / land.
   */
  marineLand?: string | null;
  /**
   * The parent biditem code.
   */
  parent?: string | null;
  /**
   * A flag to represent if the biditem is subdivided.
   */
  subDivided?: string | null;
  /**
   * The alternate units.
   */
  alternateUnits?: string | null;
  /**
   * The alternate description.
   */
  alternateDescription?: string | null;
  /**
   * The low bid.
   */
  lowBid?: number;
  /**
   * The average bid.
   */
  averageBid?: number;
  /**
   * The high bid.
   */
  highBid?: number;
  /**
   * The state code.
   */
  stateCode?: string | null;
  /**
   * The unit cost holding account.
   */
  unitCostHoldingAccount?: string | null;
  /**
   * The tax percent.
   */
  taxPercent?: number;
  /**
   * A flag representing if the biditem is taxable.
   */
  taxable?: string | null;
  /**
   * The bid price.
   */
  bidPrice?: number;
  /**
   * The summary group1.
   */
  summaryGroup1?: string | null;
  /**
   * The summary group2.
   */
  summaryGroup2?: string | null;
  /**
   * The summary group3.
   */
  summaryGroup3?: string | null;
  /**
   * The summary group4.
   */
  summaryGroup4?: string | null;
  /**
   * The summary group5.
   */
  summaryGroup5?: string | null;
  /**
   * The summary group6.
   */
  summaryGroup6?: string | null;
  /**
   * A flag representing if the biditem has been reviewed.
   */
  reviewFlag?: string | null;
  /**
   * The estimator initials.
   */
  estInitials?: string | null;
  /**
   * The biditem sort code.
   */
  sortCode?: string | null;
  /**
   * The cost notes.
   */
  costNotes?: string | null;
  /**
   * The bid notes.
   */
  bidNotes?: string | null;
  /**
   * The factor.
   */
  factor?: number;
  /**
   * The scheduling start1.
   */
  schedulingStart1?: number;
  /**
   * The scheduling duration1.
   */
  schedulingDuration1?: number;
  /**
   * The scheduling percent1.
   */
  schedulingPercent1?: number;
  /**
   * The scheduling start2.
   */
  schedulingStart2?: number;
  /**
   * The scheduling duration2.
   */
  schedulingDuration2?: number;
  /**
   * The scheduling percent2.
   */
  schedulingPercent2?: number;
  /**
   * The scheduling start3.
   */
  schedulingStart3?: number;
  /**
   * The scheduling duration3.
   */
  schedulingDuration3?: number;
  /**
   * The scheduling percent3.
   */
  schedulingPercent3?: number;
  /**
   * The scheduling start4.
   */
  schedulingStart4?: number;
  /**
   * The scheduling duration4.
   */
  schedulingDuration4?: number;
  /**
   * The scheduling percent4.
   */
  schedulingPercent4?: number;
  /**
   * The bid item percent value.
   */
  biditemPercentValue?: number;
  /**
   * The pricing status.
   */
  pricingStatus?: string | null;
  /**
   * The total user activity quantity 1.
   */
  userActivity1?: number;
  /**
   * The total user activity quantity 2.
   */
  userActivity2?: number;
  /**
   * The total user activity quantity 3.
   */
  userActivity3?: number;
  /**
   * The total user activity quantity 4.
   */
  userActivity4?: number;
  /**
   * The total labor.
   */
  labor?: number;
  /**
   * The total burden.
   */
  burden?: number;
  /**
   * The total permanent material.
   */
  permanentMaterial?: number;
  /**
   * The total construction material.
   */
  constructionMaterial?: number;
  /**
   * The total subcontract.
   */
  subcontract?: number;
  /**
   * The total equipment operating expense.
   */
  equipmentOperatingExpense?: number;
  /**
   * The total company equipment.
   */
  companyEquipment?: number;
  /**
   * The total rented equipment.
   */
  rentedEquipment?: number;
  /**
   * The total Misc1.
   */
  misc1?: number;
  /**
   * The total Misc2.
   */
  misc2?: number;
  /**
   * The total Misc3.
   */
  misc3?: number;
  /**
   * The direct total.
   */
  directTotal?: number;
  /**
   * The total equipment account dollars.
   */
  equipmentAccountDollars?: number;
  /**
   * The indirect total.
   */
  indirectTotal?: number;
  /**
   * The addon bond total.
   */
  addonBond?: number;
  /**
   * The total cost.
   */
  totalCost?: number;
  /**
   * The total cost adjustment to bid quantity.
   */
  totalCostAdjToBidQuantity?: number;
  /**
   * The markup.
   */
  markup?: number;
  /**
   * The total takeoff.
   */
  totalTakeoff?: number;
  /**
   * The total balanced.
   */
  totalBalanced?: number;
  /**
   * The man-hours.
   */
  manhours?: number;
  /**
   * The escalation total cost.
   */
  escalationTotalCost?: number;
  /**
   * The fixed cost.
   */
  fixedCost?: number;
  /**
   * A flag representing if the biditem is frozen for pricing purposes.
   */
  freezeBid?: string | null;
  /**
   * The sequence item number.
   */
  sequenceItemNumber?: number;
  /**
   * The holding account activity quantity.
   */
  holdingAccountActivityQuantity?: number;
  /**
   * The account revenue code.
   */
  accountingRevenueCode?: string | null;
  /**
   * Scheduling Critical Path Method Code (CPM) assigns a code to each Biditem for export to another scheduling system.
   */
  schedulingCPMCode?: string | null;
  /**
   * The number of days between the start of this Biditem and the end of this items Predecessor biditem.
   */
  schedulingCPMOverlap?: number;
  /**
   * The scheduling calculated days.
   */
  schedulingCalc_Days?: number;
  /**
   * The folder.
   */
  folder?: string | null;
  /**
   * The single alternate.
   */
  singleAlternate?: string | null;
  /**
   * The package alternate.
   */
  packageAlternate?: string | null;
  /**
   * The user flag1.
   */
  userFlag1?: string | null;
  /**
   * The user flag2.
   */
  userFlag2?: string | null;
  /**
   * The child bid price.
   */
  childBidPrice?: number;
  /**
   * A note field for the users to write notes about why this Biditem has 0 price.
   */
  zeroPriceText?: string | null;
  /**
   * The unit cost item.
   */
  unitCostItem?: string | null;
}

export type ODataQueryOptionsOfBiditemIntegrationDto = ODataQueryOptions & {
  ifMatch?: ETagOfBiditemIntegrationDto | null;
  ifNoneMatch?: ETagOfBiditemIntegrationDto | null;
};

export type ETagOfBiditemIntegrationDto = ETag & { [key: string]: any };

export interface IntegrationResponseSingleOfBiditemIntegrationDto {
  data?: BiditemIntegrationDto | null;
}

export interface IntegrationResponseOfApiBusinessUnitRead {
  data?: ApiBusinessUnitRead[] | null;
  currentTopValue?: number | null;
  currentSkipValue?: number | null;
  nextSkipValue?: number | null;
}

export interface ApiBusinessUnitRead {
  /**
   * The setups business unit id.
   */
  id: string;
  /**
   * The heavybid division code.
   */
  division?: string | null;
  /**
   * The setups business unit code.
   */
  businessUnitCode?: string | null;
}

export interface IntegrationResponseOfEstimateIntegrationDto {
  data?: EstimateIntegrationDto[] | null;
  currentTopValue?: number | null;
  currentSkipValue?: number | null;
  nextSkipValue?: number | null;
}

export interface EstimateIntegrationDto {
  /**
   * The estimate id.
   */
  id?: string;
  /**
   * The partition id.
   */
  partitionId?: string;
  /**
   * The business unit id.
   */
  businessUnitId?: string;
  /**
   * The business unit code.
   */
  businessUnitCode?: string | null;
  /**
   * The HeavyBid division.
   */
  heavyBidDivision?: string | null;
  /**
   * The estimate code.
   */
  code: string;
  /**
   * The name.
   */
  name?: string | null;
  /**
   * The processed status of the estimate.
   * 0 = queued
   * 1 = processing
   * 2 = success
   * 3 = failure
   */
  processedStatus?: StatusCode;
  /**
   * The description.
   */
  description?: string | null;
  /**
   * The estimate version.
   */
  estimateVersion?: string | null;
  /**
   * The filters.
   */
  filters?: EstimateFiltersIntegrationDto | null;
  /**
   * The estimate totals.
   */
  totals?: EstimateTotals | null;
  /**
   * The last modified date.
   */
  lastModified?: string;
  /**
   * The last processed date.
   */
  lastProcessed?: string | null;
  /**
   * Whether the estimate is excluded.
   */
  isExcludedEstimate?: boolean;
  /**
   * Whether the estimate is active.
   */
  isActiveEstimate?: boolean;
  /**
   * Whether the estimate is deleted.
   */
  isDeleted?: boolean;
  /**
   * The deleted date.
   */
  deletedTimestamp?: string | null;
}

export interface EstimateFiltersIntegrationDto {
  projectName?: string | null;
  projectNumber?: string | null;
  status?: string | null;
  engineer?: string | null;
  estimator?: string | null;
  owner?: string | null;
  bidDate?: string | null;
  startDate?: string | null;
  reviewDate?: string | null;
  createdDate?: string | null;
  modifiedDate?: string | null;
  modifiedTime?: number | null;
  state?: string | null;
  typeOfWork?: string | null;
  estimateTag1?: string | null;
  estimateTag2?: string | null;
  estimateTag3?: string | null;
  estimateTag4?: string | null;
  estimateTag5?: string | null;
  estimateTag6?: string | null;
  userDefinedText1?: string | null;
  userDefinedText2?: string | null;
  userDefinedText3?: string | null;
  userDefinedText4?: string | null;
  userDefinedText5?: string | null;
  userDefinedText6?: string | null;
  userDefinedText7?: string | null;
  userDefinedText8?: string | null;
  userDefinedText9?: string | null;
  userDefinedText10?: string | null;
  userDefinedText11?: string | null;
  userDefinedText12?: string | null;
  userDefinedText13?: string | null;
  userDefinedText14?: string | null;
  userDefinedText15?: string | null;
  userDefinedText16?: string | null;
  userDefinedText17?: string | null;
  userDefinedText18?: string | null;
  userDefinedText19?: string | null;
  userDefinedText20?: string | null;
  userDefinedInteger1?: number | null;
  userDefinedInteger2?: number | null;
  userDefinedInteger3?: number | null;
  userDefinedInteger4?: number | null;
  userDefinedInteger5?: number | null;
  userDefinedNumber1?: number | null;
  userDefinedNumber2?: number | null;
  userDefinedNumber3?: number | null;
  userDefinedNumber4?: number | null;
  userDefinedNumber5?: number | null;
  userDefinedNumber6?: number | null;
  userDefinedNumber7?: number | null;
  userDefinedNumber8?: number | null;
  userDefinedNumber9?: number | null;
  userDefinedNumber10?: number | null;
  userDefinedNumber11?: number | null;
  userDefinedNumber12?: number | null;
  userDefinedNumber13?: number | null;
  userDefinedNumber14?: number | null;
  userDefinedNumber15?: number | null;
  userDefinedNumber16?: number | null;
  userDefinedNumber17?: number | null;
  userDefinedNumber18?: number | null;
  userDefinedNumber19?: number | null;
  userDefinedNumber20?: number | null;
  userDefinedDate1?: string | null;
  userDefinedDate2?: string | null;
  userDefinedDate3?: string | null;
  userDefinedDate4?: string | null;
  userDefinedDate5?: string | null;
  address?: string | null;
  latitude?: number | null;
  longitude?: number | null;
}

export type ODataQueryOptionsOfEstimateIntegrationDto = ODataQueryOptions & {
  ifMatch?: ETagOfEstimateIntegrationDto | null;
  ifNoneMatch?: ETagOfEstimateIntegrationDto | null;
};

export type ETagOfEstimateIntegrationDto = ETag & { [key: string]: any };

export interface IntegrationResponseSingleOfEstimateIntegrationDto {
  data?: EstimateIntegrationDto | null;
}

export interface IntegrationResponseOfKPI {
  data?: Kpi[] | null;
  currentTopValue?: number | null;
  currentSkipValue?: number | null;
  nextSkipValue?: number | null;
}

export interface IntegrationResponseOfApiDatabasePartitionRead {
  data?: ApiDatabasePartitionRead[] | null;
  currentTopValue?: number | null;
  currentSkipValue?: number | null;
  nextSkipValue?: number | null;
}

export interface ApiDatabasePartitionRead {
  /**
   * The id.
   */
  id: string;
  /**
   * The heavybid system id.
   */
  systemId?: string;
  /**
   * The setups business unit id.
   */
  businessUnitId?: string;
}

export interface IntegrationResponseSingleOfApiDatabasePartitionRead {
  data?: ApiDatabasePartitionRead | null;
}

export interface IntegrationResponseOfResourceIntegrationDto {
  data?: ResourceIntegrationDto[] | null;
  currentTopValue?: number | null;
  currentSkipValue?: number | null;
  nextSkipValue?: number | null;
}

export interface ResourceIntegrationDto {
  /**
   * The unique resource id.
   */
  id?: string;
  /**
   * The estimate id.
   */
  estimateId?: string;
  /**
   * The estimate code.
   */
  estimateCode?: string | null;
  /**
   * The biditem id.
   */
  biditemId?: string;
  /**
   * The activity id.
   */
  activityId?: string;
  /**
   * The last modified date.
   */
  lastModified?: string;
  /**
   * The resource code.
   */
  resourceCode?: string | null;
  /**
   * The activity code.
   */
  activityCode?: string | null;
  /**
   * The biditem code.
   */
  biditemCode?: string | null;
  /**
   * The description.
   */
  description?: string | null;
  /**
   * The type cost.
   */
  typeCost?: string | null;
  /**
   * The type of equipment rent.
   */
  typeOfEquipmentRent?: string | null;
  /**
   * The sub type.
   */
  subType?: string | null;
  /**
   * The quantity.
   */
  quantity?: number;
  /**
   * The unit of measure.
   */
  units?: string | null;
  /**
   * The percent.
   */
  percent?: number;
  /**
   * The unit price.
   */
  unitPrice?: number;
  /**
   * The currency.
   */
  currency?: string | null;
  /**
   * The sub type cost.
   */
  subTypeCost?: number;
  /**
   * The total.
   */
  total?: number;
  /**
   * The factorable.
   */
  factorable?: string | null;
  /**
   * The factor.
   */
  factor?: number;
  /**
   * The supplemental description.
   */
  supplementalDescription?: string | null;
  /**
   * The skip cost.
   */
  skipCost?: string | null;
  /**
   * The number of pieces.
   */
  pieces?: number;
  /**
   * The operating percent.
   */
  operatingPercent?: number;
  /**
   * The rent percent.
   */
  rentPercent?: number;
  /**
   * The production code.
   */
  productionCode?: string | null;
  /**
   * The production.
   */
  production?: number;
  /**
   * The crew code.
   */
  crewCode?: string | null;
  /**
   * The equipment operator code.
   */
  equipmentOperatorCode?: string | null;
  /**
   * The escalation percent.
   */
  escalationPercent?: number;
  /**
   * The plug indicator.
   */
  plugIndicator?: string | null;
  /**
   * The calendar.
   */
  calendar?: string | null;
  /**
   * The start day,
   */
  startDay?: string | null;
  /**
   * The special schedule.
   */
  specialSchedule?: string | null;
  /**
   * The man hour adjustment per shift.
   */
  manHourAdjustmentPerShift?: number;
  /**
   * The selected vendor.
   */
  selectedVendor?: string | null;
  /**
   * The man hour unit.
   */
  manHourUnit?: number;
  /**
   * The escalation override.
   */
  escalationOverride?: string | null;
  /**
   * The class.
   */
  class?: string | null;
  /**
   * The alternate quantity.
   */
  alternateQuantity?: number;
  /**
   * The alternate unit.
   */
  alternateUnits?: string | null;
  /**
   * The resource text1.
   */
  resourceText1?: string | null;
  /**
   * The resource text2.
   */
  resourceText2?: string | null;
}

export type ODataQueryOptionsOfResourceIntegrationDto = ODataQueryOptions & {
  ifMatch?: ETagOfResourceIntegrationDto | null;
  ifNoneMatch?: ETagOfResourceIntegrationDto | null;
};

export type ETagOfResourceIntegrationDto = ETag & { [key: string]: any };

export interface IntegrationResponseSingleOfResourceIntegrationDto {
  data?: ResourceIntegrationDto | null;
}

export interface BidHistoryGetBidHistoryPricesQueryParams {
  requestType?: number;
  beginDate?: string | null;
  endDate?: string | null;
}

export type BidHistoryGetBidHistoryPricesProps = Omit<
  MutateProps<
    HistoricalBidPrice[],
    void,
    BidHistoryGetBidHistoryPricesQueryParams,
    BidInfo[],
    void
  >,
  "path" | "verb"
>;

export const BidHistoryGetBidHistoryPrices = (
  props: BidHistoryGetBidHistoryPricesProps
) => (
  <Mutate<
    HistoricalBidPrice[],
    void,
    BidHistoryGetBidHistoryPricesQueryParams,
    BidInfo[],
    void
  >
    verb="POST"
    path={`/api/v1/bidhistory/prices`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseBidHistoryGetBidHistoryPricesProps = Omit<
  UseMutateProps<
    HistoricalBidPrice[],
    void,
    BidHistoryGetBidHistoryPricesQueryParams,
    BidInfo[],
    void
  >,
  "path" | "verb"
>;

export const useBidHistoryGetBidHistoryPrices = (
  props: UseBidHistoryGetBidHistoryPricesProps
) =>
  useMutate<
    HistoricalBidPrice[],
    void,
    BidHistoryGetBidHistoryPricesQueryParams,
    BidInfo[],
    void
  >("POST", `/api/v1/bidhistory/prices`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface BidHistoryGetBidHistoryBiditemsQueryParams {
  beginDate?: string | null;
  endDate?: string | null;
  includeActiveEstimates?: boolean;
}

export type BidHistoryGetBidHistoryBiditemsProps = Omit<
  MutateProps<
    HistoricalBiditem[],
    void,
    BidHistoryGetBidHistoryBiditemsQueryParams,
    BidInfo[],
    void
  >,
  "path" | "verb"
>;

export const BidHistoryGetBidHistoryBiditems = (
  props: BidHistoryGetBidHistoryBiditemsProps
) => (
  <Mutate<
    HistoricalBiditem[],
    void,
    BidHistoryGetBidHistoryBiditemsQueryParams,
    BidInfo[],
    void
  >
    verb="POST"
    path={`/api/v1/bidhistory/biditems`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseBidHistoryGetBidHistoryBiditemsProps = Omit<
  UseMutateProps<
    HistoricalBiditem[],
    void,
    BidHistoryGetBidHistoryBiditemsQueryParams,
    BidInfo[],
    void
  >,
  "path" | "verb"
>;

export const useBidHistoryGetBidHistoryBiditems = (
  props: UseBidHistoryGetBidHistoryBiditemsProps
) =>
  useMutate<
    HistoricalBiditem[],
    void,
    BidHistoryGetBidHistoryBiditemsQueryParams,
    BidInfo[],
    void
  >("POST", `/api/v1/bidhistory/biditems`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface BidResultsSendToBidResultsQueryParams {
  estimateId?: string;
}

export type BidResultsSendToBidResultsProps = Omit<
  GetProps<string, void, BidResultsSendToBidResultsQueryParams, void>,
  "path"
>;

export const BidResultsSendToBidResults = (
  props: BidResultsSendToBidResultsProps
) => (
  <Get<string, void, BidResultsSendToBidResultsQueryParams, void>
    path={`/api/v1/bidresults`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseBidResultsSendToBidResultsProps = Omit<
  UseGetProps<string, void, BidResultsSendToBidResultsQueryParams, void>,
  "path"
>;

export const useBidResultsSendToBidResults = (
  props: UseBidResultsSendToBidResultsProps
) =>
  useGet<string, void, BidResultsSendToBidResultsQueryParams, void>(
    `/api/v1/bidresults`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export type BusinessUnitGetProps = Omit<
  GetProps<BusinessUnitDto[], void, void, void>,
  "path"
>;

export const BusinessUnitGet = (props: BusinessUnitGetProps) => (
  <Get<BusinessUnitDto[], void, void, void>
    path={`/api/v1/businessunits`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseBusinessUnitGetProps = Omit<
  UseGetProps<BusinessUnitDto[], void, void, void>,
  "path"
>;

export const useBusinessUnitGet = (props: UseBusinessUnitGetProps) =>
  useGet<BusinessUnitDto[], void, void, void>(`/api/v1/businessunits`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface CompareEstimatesDirectQueryParams {
  partitionId?: string;
  estimateCode?: string | null;
}

export type CompareEstimatesDirectProps = Omit<
  GetProps<string, void, CompareEstimatesDirectQueryParams, void>,
  "path"
>;

export const CompareEstimatesDirect = (props: CompareEstimatesDirectProps) => (
  <Get<string, void, CompareEstimatesDirectQueryParams, void>
    path={`/api/v1/compare`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseCompareEstimatesDirectProps = Omit<
  UseGetProps<string, void, CompareEstimatesDirectQueryParams, void>,
  "path"
>;

export const useCompareEstimatesDirect = (
  props: UseCompareEstimatesDirectProps
) =>
  useGet<string, void, CompareEstimatesDirectQueryParams, void>(
    `/api/v1/compare`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export type CopyPostCopyStructureProps = Omit<
  MutateProps<BiditemViewModel[], void, void, CopyStructure[], void>,
  "path" | "verb"
>;

export const CopyPostCopyStructure = (props: CopyPostCopyStructureProps) => (
  <Mutate<BiditemViewModel[], void, void, CopyStructure[], void>
    verb="POST"
    path={`/api/v1/copy`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseCopyPostCopyStructureProps = Omit<
  UseMutateProps<BiditemViewModel[], void, void, CopyStructure[], void>,
  "path" | "verb"
>;

export const useCopyPostCopyStructure = (
  props: UseCopyPostCopyStructureProps
) =>
  useMutate<BiditemViewModel[], void, void, CopyStructure[], void>(
    "POST",
    `/api/v1/copy`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export interface CopyGetEstimatePathParams {
  id: string;
}

export type CopyGetEstimateProps = Omit<
  GetProps<CopyEstimateViewModel, void, void, CopyGetEstimatePathParams>,
  "path"
> &
  CopyGetEstimatePathParams;

export const CopyGetEstimate = ({ id, ...props }: CopyGetEstimateProps) => (
  <Get<CopyEstimateViewModel, void, void, CopyGetEstimatePathParams>
    path={`/api/v1/copy/estimates/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseCopyGetEstimateProps = Omit<
  UseGetProps<CopyEstimateViewModel, void, void, CopyGetEstimatePathParams>,
  "path"
> &
  CopyGetEstimatePathParams;

export const useCopyGetEstimate = ({ id, ...props }: UseCopyGetEstimateProps) =>
  useGet<CopyEstimateViewModel, void, void, CopyGetEstimatePathParams>(
    (paramsInPath: CopyGetEstimatePathParams) =>
      `/api/v1/copy/estimates/${paramsInPath.id}`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface CopyGetBiditemsForEstimateQueryParams {
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export interface CopyGetBiditemsForEstimatePathParams {
  id: string;
}

export type CopyGetBiditemsForEstimateProps = Omit<
  GetProps<
    CopyBiditemViewModel[],
    void,
    CopyGetBiditemsForEstimateQueryParams,
    CopyGetBiditemsForEstimatePathParams
  >,
  "path"
> &
  CopyGetBiditemsForEstimatePathParams;

export const CopyGetBiditemsForEstimate = ({
  id,
  ...props
}: CopyGetBiditemsForEstimateProps) => (
  <Get<
    CopyBiditemViewModel[],
    void,
    CopyGetBiditemsForEstimateQueryParams,
    CopyGetBiditemsForEstimatePathParams
  >
    path={`/api/v1/copy/estimates/${id}/biditems`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseCopyGetBiditemsForEstimateProps = Omit<
  UseGetProps<
    CopyBiditemViewModel[],
    void,
    CopyGetBiditemsForEstimateQueryParams,
    CopyGetBiditemsForEstimatePathParams
  >,
  "path"
> &
  CopyGetBiditemsForEstimatePathParams;

export const useCopyGetBiditemsForEstimate = ({
  id,
  ...props
}: UseCopyGetBiditemsForEstimateProps) =>
  useGet<
    CopyBiditemViewModel[],
    void,
    CopyGetBiditemsForEstimateQueryParams,
    CopyGetBiditemsForEstimatePathParams
  >(
    (paramsInPath: CopyGetBiditemsForEstimatePathParams) =>
      `/api/v1/copy/estimates/${paramsInPath.id}/biditems`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface CopyGetActivitiesForBiditemPathParams {
  id: string;
}

export type CopyGetActivitiesForBiditemProps = Omit<
  GetProps<
    CopyActivityViewModel[],
    void,
    void,
    CopyGetActivitiesForBiditemPathParams
  >,
  "path"
> &
  CopyGetActivitiesForBiditemPathParams;

export const CopyGetActivitiesForBiditem = ({
  id,
  ...props
}: CopyGetActivitiesForBiditemProps) => (
  <Get<
    CopyActivityViewModel[],
    void,
    void,
    CopyGetActivitiesForBiditemPathParams
  >
    path={`/api/v1/copy/biditems/${id}/activities`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseCopyGetActivitiesForBiditemProps = Omit<
  UseGetProps<
    CopyActivityViewModel[],
    void,
    void,
    CopyGetActivitiesForBiditemPathParams
  >,
  "path"
> &
  CopyGetActivitiesForBiditemPathParams;

export const useCopyGetActivitiesForBiditem = ({
  id,
  ...props
}: UseCopyGetActivitiesForBiditemProps) =>
  useGet<
    CopyActivityViewModel[],
    void,
    void,
    CopyGetActivitiesForBiditemPathParams
  >(
    (paramsInPath: CopyGetActivitiesForBiditemPathParams) =>
      `/api/v1/copy/biditems/${paramsInPath.id}/activities`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface CopyGetResourcesForActivityPathParams {
  id: string;
}

export type CopyGetResourcesForActivityProps = Omit<
  GetProps<
    CopyResourceViewModel[],
    void,
    void,
    CopyGetResourcesForActivityPathParams
  >,
  "path"
> &
  CopyGetResourcesForActivityPathParams;

export const CopyGetResourcesForActivity = ({
  id,
  ...props
}: CopyGetResourcesForActivityProps) => (
  <Get<
    CopyResourceViewModel[],
    void,
    void,
    CopyGetResourcesForActivityPathParams
  >
    path={`/api/v1/copy/activities/${id}/resources`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseCopyGetResourcesForActivityProps = Omit<
  UseGetProps<
    CopyResourceViewModel[],
    void,
    void,
    CopyGetResourcesForActivityPathParams
  >,
  "path"
> &
  CopyGetResourcesForActivityPathParams;

export const useCopyGetResourcesForActivity = ({
  id,
  ...props
}: UseCopyGetResourcesForActivityProps) =>
  useGet<
    CopyResourceViewModel[],
    void,
    void,
    CopyGetResourcesForActivityPathParams
  >(
    (paramsInPath: CopyGetResourcesForActivityPathParams) =>
      `/api/v1/copy/activities/${paramsInPath.id}/resources`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface CopyGetParentIdsForBiditemPathParams {
  id: string;
}

export type CopyGetParentIdsForBiditemProps = Omit<
  GetProps<BiditemParent[], void, void, CopyGetParentIdsForBiditemPathParams>,
  "path"
> &
  CopyGetParentIdsForBiditemPathParams;

export const CopyGetParentIdsForBiditem = ({
  id,
  ...props
}: CopyGetParentIdsForBiditemProps) => (
  <Get<BiditemParent[], void, void, CopyGetParentIdsForBiditemPathParams>
    path={`/api/v1/copy/biditems/${id}/parents`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseCopyGetParentIdsForBiditemProps = Omit<
  UseGetProps<
    BiditemParent[],
    void,
    void,
    CopyGetParentIdsForBiditemPathParams
  >,
  "path"
> &
  CopyGetParentIdsForBiditemPathParams;

export const useCopyGetParentIdsForBiditem = ({
  id,
  ...props
}: UseCopyGetParentIdsForBiditemProps) =>
  useGet<BiditemParent[], void, void, CopyGetParentIdsForBiditemPathParams>(
    (paramsInPath: CopyGetParentIdsForBiditemPathParams) =>
      `/api/v1/copy/biditems/${paramsInPath.id}/parents`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export type CopyGetAttachmentsProps = Omit<
  MutateProps<void, void, void, string[], void>,
  "path" | "verb"
>;

export const CopyGetAttachments = (props: CopyGetAttachmentsProps) => (
  <Mutate<void, void, void, string[], void>
    verb="POST"
    path={`/api/v1/copy/GetAttachments`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseCopyGetAttachmentsProps = Omit<
  UseMutateProps<void, void, void, string[], void>,
  "path" | "verb"
>;

export const useCopyGetAttachments = (props: UseCopyGetAttachmentsProps) =>
  useMutate<void, void, void, string[], void>(
    "POST",
    `/api/v1/copy/GetAttachments`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export interface CopyGetCopySearchByEstimateQueryParams {
  searchTerms?: string | null;
  primarySortColumn?: string | null;
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export type CopyGetCopySearchByEstimateProps = Omit<
  GetProps<
    EstimateSearchFlattened[],
    void,
    CopyGetCopySearchByEstimateQueryParams,
    void
  >,
  "path"
>;

export const CopyGetCopySearchByEstimate = (
  props: CopyGetCopySearchByEstimateProps
) => (
  <Get<
    EstimateSearchFlattened[],
    void,
    CopyGetCopySearchByEstimateQueryParams,
    void
  >
    path={`/api/v1/copy/search/estimate`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseCopyGetCopySearchByEstimateProps = Omit<
  UseGetProps<
    EstimateSearchFlattened[],
    void,
    CopyGetCopySearchByEstimateQueryParams,
    void
  >,
  "path"
>;

export const useCopyGetCopySearchByEstimate = (
  props: UseCopyGetCopySearchByEstimateProps
) =>
  useGet<
    EstimateSearchFlattened[],
    void,
    CopyGetCopySearchByEstimateQueryParams,
    void
  >(`/api/v1/copy/search/estimate`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface CopyGetCopySearchByBiditemQueryParams {
  searchTerms?: string | null;
  primarySortColumn?: string | null;
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export type CopyGetCopySearchByBiditemProps = Omit<
  GetProps<
    EstimateSearchFlattened[],
    void,
    CopyGetCopySearchByBiditemQueryParams,
    void
  >,
  "path"
>;

export const CopyGetCopySearchByBiditem = (
  props: CopyGetCopySearchByBiditemProps
) => (
  <Get<
    EstimateSearchFlattened[],
    void,
    CopyGetCopySearchByBiditemQueryParams,
    void
  >
    path={`/api/v1/copy/search/biditem`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseCopyGetCopySearchByBiditemProps = Omit<
  UseGetProps<
    EstimateSearchFlattened[],
    void,
    CopyGetCopySearchByBiditemQueryParams,
    void
  >,
  "path"
>;

export const useCopyGetCopySearchByBiditem = (
  props: UseCopyGetCopySearchByBiditemProps
) =>
  useGet<
    EstimateSearchFlattened[],
    void,
    CopyGetCopySearchByBiditemQueryParams,
    void
  >(`/api/v1/copy/search/biditem`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface CopyGetCopySearchByActivityQueryParams {
  searchTerms?: string | null;
  primarySortColumn?: string | null;
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export type CopyGetCopySearchByActivityProps = Omit<
  GetProps<
    EstimateSearchFlattened[],
    void,
    CopyGetCopySearchByActivityQueryParams,
    void
  >,
  "path"
>;

export const CopyGetCopySearchByActivity = (
  props: CopyGetCopySearchByActivityProps
) => (
  <Get<
    EstimateSearchFlattened[],
    void,
    CopyGetCopySearchByActivityQueryParams,
    void
  >
    path={`/api/v1/copy/search/activity`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseCopyGetCopySearchByActivityProps = Omit<
  UseGetProps<
    EstimateSearchFlattened[],
    void,
    CopyGetCopySearchByActivityQueryParams,
    void
  >,
  "path"
>;

export const useCopyGetCopySearchByActivity = (
  props: UseCopyGetCopySearchByActivityProps
) =>
  useGet<
    EstimateSearchFlattened[],
    void,
    CopyGetCopySearchByActivityQueryParams,
    void
  >(`/api/v1/copy/search/activity`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface CostHistoryGetCostHistoryActivitiesQueryParams {
  SearchTerms?: string | null;
  FilterDates?: boolean;
  BeginDate?: string;
  EndDate?: string;
  QuanLow?: number;
  QuanHigh?: number;
  MaxRecords?: number;
}

export type CostHistoryGetCostHistoryActivitiesProps = Omit<
  GetProps<
    CostHistoryActivity[],
    void,
    CostHistoryGetCostHistoryActivitiesQueryParams,
    void
  >,
  "path"
>;

export const CostHistoryGetCostHistoryActivities = (
  props: CostHistoryGetCostHistoryActivitiesProps
) => (
  <Get<
    CostHistoryActivity[],
    void,
    CostHistoryGetCostHistoryActivitiesQueryParams,
    void
  >
    path={`/api/v1/costhistory/activities`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseCostHistoryGetCostHistoryActivitiesProps = Omit<
  UseGetProps<
    CostHistoryActivity[],
    void,
    CostHistoryGetCostHistoryActivitiesQueryParams,
    void
  >,
  "path"
>;

export const useCostHistoryGetCostHistoryActivities = (
  props: UseCostHistoryGetCostHistoryActivitiesProps
) =>
  useGet<
    CostHistoryActivity[],
    void,
    CostHistoryGetCostHistoryActivitiesQueryParams,
    void
  >(`/api/v1/costhistory/activities`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export type DashboardGetTotalActiveEstimatesProps = Omit<
  GetProps<string, void, void, void>,
  "path"
>;

export const DashboardGetTotalActiveEstimates = (
  props: DashboardGetTotalActiveEstimatesProps
) => (
  <Get<string, void, void, void>
    path={`/api/v1/insights/dashboard/active/count`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseDashboardGetTotalActiveEstimatesProps = Omit<
  UseGetProps<string, void, void, void>,
  "path"
>;

export const useDashboardGetTotalActiveEstimates = (
  props: UseDashboardGetTotalActiveEstimatesProps
) =>
  useGet<string, void, void, void>(`/api/v1/insights/dashboard/active/count`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export type DashboardGetAverageActiveEstimateAmountProps = Omit<
  GetProps<string, void, void, void>,
  "path"
>;

export const DashboardGetAverageActiveEstimateAmount = (
  props: DashboardGetAverageActiveEstimateAmountProps
) => (
  <Get<string, void, void, void>
    path={`/api/v1/insights/dashboard/active/totalamount`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseDashboardGetAverageActiveEstimateAmountProps = Omit<
  UseGetProps<string, void, void, void>,
  "path"
>;

export const useDashboardGetAverageActiveEstimateAmount = (
  props: UseDashboardGetAverageActiveEstimateAmountProps
) =>
  useGet<string, void, void, void>(
    `/api/v1/insights/dashboard/active/totalamount`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export type DashboardGetArchivedEstimatesMarkupByTypeProps = Omit<
  GetProps<string, void, void, void>,
  "path"
>;

export const DashboardGetArchivedEstimatesMarkupByType = (
  props: DashboardGetArchivedEstimatesMarkupByTypeProps
) => (
  <Get<string, void, void, void>
    path={`/api/v1/insights/dashboard/archived/markupbytype`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseDashboardGetArchivedEstimatesMarkupByTypeProps = Omit<
  UseGetProps<string, void, void, void>,
  "path"
>;

export const useDashboardGetArchivedEstimatesMarkupByType = (
  props: UseDashboardGetArchivedEstimatesMarkupByTypeProps
) =>
  useGet<string, void, void, void>(
    `/api/v1/insights/dashboard/archived/markupbytype`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export type DashboardGetArchivedEstimatesKpiByTypeProps = Omit<
  GetProps<string, void, void, void>,
  "path"
>;

export const DashboardGetArchivedEstimatesKpiByType = (
  props: DashboardGetArchivedEstimatesKpiByTypeProps
) => (
  <Get<string, void, void, void>
    path={`/api/v1/insights/dashboard/archived/kpibytype`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseDashboardGetArchivedEstimatesKpiByTypeProps = Omit<
  UseGetProps<string, void, void, void>,
  "path"
>;

export const useDashboardGetArchivedEstimatesKpiByType = (
  props: UseDashboardGetArchivedEstimatesKpiByTypeProps
) =>
  useGet<string, void, void, void>(
    `/api/v1/insights/dashboard/archived/kpibytype`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export type DashboardGetRecentActiveEstimatesProps = Omit<
  GetProps<string, void, void, void>,
  "path"
>;

export const DashboardGetRecentActiveEstimates = (
  props: DashboardGetRecentActiveEstimatesProps
) => (
  <Get<string, void, void, void>
    path={`/api/v1/insights/dashboard/active/recent`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseDashboardGetRecentActiveEstimatesProps = Omit<
  UseGetProps<string, void, void, void>,
  "path"
>;

export const useDashboardGetRecentActiveEstimates = (
  props: UseDashboardGetRecentActiveEstimatesProps
) =>
  useGet<string, void, void, void>(`/api/v1/insights/dashboard/active/recent`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export type DashboardGetTotalArchivedEstimatesProps = Omit<
  GetProps<string, void, void, void>,
  "path"
>;

export const DashboardGetTotalArchivedEstimates = (
  props: DashboardGetTotalArchivedEstimatesProps
) => (
  <Get<string, void, void, void>
    path={`/api/v1/insights/dashboard/archived/count`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseDashboardGetTotalArchivedEstimatesProps = Omit<
  UseGetProps<string, void, void, void>,
  "path"
>;

export const useDashboardGetTotalArchivedEstimates = (
  props: UseDashboardGetTotalArchivedEstimatesProps
) =>
  useGet<string, void, void, void>(
    `/api/v1/insights/dashboard/archived/count`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export type DashboardGetAverageArchivedEstimateAmountProps = Omit<
  GetProps<string, void, void, void>,
  "path"
>;

export const DashboardGetAverageArchivedEstimateAmount = (
  props: DashboardGetAverageArchivedEstimateAmountProps
) => (
  <Get<string, void, void, void>
    path={`/api/v1/insights/dashboard/archived/averageamount`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseDashboardGetAverageArchivedEstimateAmountProps = Omit<
  UseGetProps<string, void, void, void>,
  "path"
>;

export const useDashboardGetAverageArchivedEstimateAmount = (
  props: UseDashboardGetAverageArchivedEstimateAmountProps
) =>
  useGet<string, void, void, void>(
    `/api/v1/insights/dashboard/archived/averageamount`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export type DashboardGetRecentArchivedEstimatesProps = Omit<
  GetProps<string, void, void, void>,
  "path"
>;

export const DashboardGetRecentArchivedEstimates = (
  props: DashboardGetRecentArchivedEstimatesProps
) => (
  <Get<string, void, void, void>
    path={`/api/v1/insights/dashboard/archived/recent`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseDashboardGetRecentArchivedEstimatesProps = Omit<
  UseGetProps<string, void, void, void>,
  "path"
>;

export const useDashboardGetRecentArchivedEstimates = (
  props: UseDashboardGetRecentArchivedEstimatesProps
) =>
  useGet<string, void, void, void>(
    `/api/v1/insights/dashboard/archived/recent`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export type DashboardGetRecentEstimatesProps = Omit<
  GetProps<string, void, void, void>,
  "path"
>;

export const DashboardGetRecentEstimates = (
  props: DashboardGetRecentEstimatesProps
) => (
  <Get<string, void, void, void>
    path={`/api/v1/insights/dashboard/all/recent`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseDashboardGetRecentEstimatesProps = Omit<
  UseGetProps<string, void, void, void>,
  "path"
>;

export const useDashboardGetRecentEstimates = (
  props: UseDashboardGetRecentEstimatesProps
) =>
  useGet<string, void, void, void>(`/api/v1/insights/dashboard/all/recent`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export type DashboardGetEstimateLocationsProps = Omit<
  GetProps<string, void, void, void>,
  "path"
>;

export const DashboardGetEstimateLocations = (
  props: DashboardGetEstimateLocationsProps
) => (
  <Get<string, void, void, void>
    path={`/api/v1/insights/dashboard/all/geo`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseDashboardGetEstimateLocationsProps = Omit<
  UseGetProps<string, void, void, void>,
  "path"
>;

export const useDashboardGetEstimateLocations = (
  props: UseDashboardGetEstimateLocationsProps
) =>
  useGet<string, void, void, void>(`/api/v1/insights/dashboard/all/geo`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export type DatabasePartitionGetProps = Omit<
  GetProps<DatabasePartition[], void, void, void>,
  "path"
>;

export const DatabasePartitionGet = (props: DatabasePartitionGetProps) => (
  <Get<DatabasePartition[], void, void, void>
    path={`/api/v1/partition/all`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseDatabasePartitionGetProps = Omit<
  UseGetProps<DatabasePartition[], void, void, void>,
  "path"
>;

export const useDatabasePartitionGet = (props: UseDatabasePartitionGetProps) =>
  useGet<DatabasePartition[], void, void, void>(`/api/v1/partition/all`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface DatabasePartitionGetOrCreateQueryParams {
  systemId?: string;
  businessUnitCode?: string | null;
}

export type DatabasePartitionGetOrCreateProps = Omit<
  GetProps<string, void, DatabasePartitionGetOrCreateQueryParams, void>,
  "path"
>;

export const DatabasePartitionGetOrCreate = (
  props: DatabasePartitionGetOrCreateProps
) => (
  <Get<string, void, DatabasePartitionGetOrCreateQueryParams, void>
    path={`/api/v1/partition`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseDatabasePartitionGetOrCreateProps = Omit<
  UseGetProps<string, void, DatabasePartitionGetOrCreateQueryParams, void>,
  "path"
>;

export const useDatabasePartitionGetOrCreate = (
  props: UseDatabasePartitionGetOrCreateProps
) =>
  useGet<string, void, DatabasePartitionGetOrCreateQueryParams, void>(
    `/api/v1/partition`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export interface EstimatesGetEstimatesQueryParams {
  dto?: string | null;
  activeEstimates?: boolean;
  hideExcludedEstimates?: boolean;
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export type EstimatesGetEstimatesProps = Omit<
  GetProps<
    EstimateSummaryExtended[],
    void,
    EstimatesGetEstimatesQueryParams,
    void
  >,
  "path"
>;

export const EstimatesGetEstimates = (props: EstimatesGetEstimatesProps) => (
  <Get<EstimateSummaryExtended[], void, EstimatesGetEstimatesQueryParams, void>
    path={`/api/v1/estimates`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseEstimatesGetEstimatesProps = Omit<
  UseGetProps<
    EstimateSummaryExtended[],
    void,
    EstimatesGetEstimatesQueryParams,
    void
  >,
  "path"
>;

export const useEstimatesGetEstimates = (
  props: UseEstimatesGetEstimatesProps
) =>
  useGet<
    EstimateSummaryExtended[],
    void,
    EstimatesGetEstimatesQueryParams,
    void
  >(`/api/v1/estimates`, { base: config.endpoints.HBINSIGHTS, ...props });

export interface EstimatesGetEstimateQueryParams {
  dto?: string | null;
}

export interface EstimatesGetEstimatePathParams {
  id: string;
}

export type EstimatesGetEstimateProps = Omit<
  GetProps<
    EstimateSummaryExtended,
    void | ProblemDetails,
    EstimatesGetEstimateQueryParams,
    EstimatesGetEstimatePathParams
  >,
  "path"
> &
  EstimatesGetEstimatePathParams;

export const EstimatesGetEstimate = ({
  id,
  ...props
}: EstimatesGetEstimateProps) => (
  <Get<
    EstimateSummaryExtended,
    void | ProblemDetails,
    EstimatesGetEstimateQueryParams,
    EstimatesGetEstimatePathParams
  >
    path={`/api/v1/estimates/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseEstimatesGetEstimateProps = Omit<
  UseGetProps<
    EstimateSummaryExtended,
    void | ProblemDetails,
    EstimatesGetEstimateQueryParams,
    EstimatesGetEstimatePathParams
  >,
  "path"
> &
  EstimatesGetEstimatePathParams;

export const useEstimatesGetEstimate = ({
  id,
  ...props
}: UseEstimatesGetEstimateProps) =>
  useGet<
    EstimateSummaryExtended,
    void | ProblemDetails,
    EstimatesGetEstimateQueryParams,
    EstimatesGetEstimatePathParams
  >(
    (paramsInPath: EstimatesGetEstimatePathParams) =>
      `/api/v1/estimates/${paramsInPath.id}`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface EstimatesGetEstimateBlobPathParams {
  id: string;
}

export type EstimatesGetEstimateBlobProps = Omit<
  GetProps<
    void,
    void | ProblemDetails,
    void,
    EstimatesGetEstimateBlobPathParams
  >,
  "path"
> &
  EstimatesGetEstimateBlobPathParams;

export const EstimatesGetEstimateBlob = ({
  id,
  ...props
}: EstimatesGetEstimateBlobProps) => (
  <Get<void, void | ProblemDetails, void, EstimatesGetEstimateBlobPathParams>
    path={`/api/v1/estimates/download/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseEstimatesGetEstimateBlobProps = Omit<
  UseGetProps<
    void,
    void | ProblemDetails,
    void,
    EstimatesGetEstimateBlobPathParams
  >,
  "path"
> &
  EstimatesGetEstimateBlobPathParams;

export const useEstimatesGetEstimateBlob = ({
  id,
  ...props
}: UseEstimatesGetEstimateBlobProps) =>
  useGet<void, void | ProblemDetails, void, EstimatesGetEstimateBlobPathParams>(
    (paramsInPath: EstimatesGetEstimateBlobPathParams) =>
      `/api/v1/estimates/download/${paramsInPath.id}`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface EstimatesPutEstimatePathParams {
  id: string;
  estimate: string;
}

export type EstimatesPutEstimateProps = Omit<
  MutateProps<void, void, void, Estimate, EstimatesPutEstimatePathParams>,
  "path" | "verb"
> &
  EstimatesPutEstimatePathParams;

export const EstimatesPutEstimate = ({
  id,
  estimate,
  ...props
}: EstimatesPutEstimateProps) => (
  <Mutate<void, void, void, Estimate, EstimatesPutEstimatePathParams>
    verb="PUT"
    path={`/api/v1/estimates/${id}/${estimate}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseEstimatesPutEstimateProps = Omit<
  UseMutateProps<void, void, void, Estimate, EstimatesPutEstimatePathParams>,
  "path" | "verb"
> &
  EstimatesPutEstimatePathParams;

export const useEstimatesPutEstimate = ({
  id,
  estimate,
  ...props
}: UseEstimatesPutEstimateProps) =>
  useMutate<void, void, void, Estimate, EstimatesPutEstimatePathParams>(
    "PUT",
    (paramsInPath: EstimatesPutEstimatePathParams) =>
      `/api/v1/estimates/${paramsInPath.id}/${paramsInPath.estimate}`,
    {
      base: config.endpoints.HBINSIGHTS,
      pathParams: { id, estimate },
      ...props
    }
  );

export type EstimatesPostExcludedProps = Omit<
  MutateProps<Estimate, void, void, EstimateExcluded, void>,
  "path" | "verb"
>;

export const EstimatesPostExcluded = (props: EstimatesPostExcludedProps) => (
  <Mutate<Estimate, void, void, EstimateExcluded, void>
    verb="POST"
    path={`/api/v1/estimates/excluded`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseEstimatesPostExcludedProps = Omit<
  UseMutateProps<Estimate, void, void, EstimateExcluded, void>,
  "path" | "verb"
>;

export const useEstimatesPostExcluded = (
  props: UseEstimatesPostExcludedProps
) =>
  useMutate<Estimate, void, void, EstimateExcluded, void>(
    "POST",
    `/api/v1/estimates/excluded`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export interface EstimatesDeleteEstimateByIdPathParams {
  id: string;
}

export type EstimatesDeleteEstimateByIdProps = Omit<
  GetProps<
    DeleteEstimateViewModel,
    void,
    void,
    EstimatesDeleteEstimateByIdPathParams
  >,
  "path"
> &
  EstimatesDeleteEstimateByIdPathParams;

export const EstimatesDeleteEstimateById = ({
  id,
  ...props
}: EstimatesDeleteEstimateByIdProps) => (
  <Get<
    DeleteEstimateViewModel,
    void,
    void,
    EstimatesDeleteEstimateByIdPathParams
  >
    path={`/api/v1/estimates/delete/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseEstimatesDeleteEstimateByIdProps = Omit<
  UseGetProps<
    DeleteEstimateViewModel,
    void,
    void,
    EstimatesDeleteEstimateByIdPathParams
  >,
  "path"
> &
  EstimatesDeleteEstimateByIdPathParams;

export const useEstimatesDeleteEstimateById = ({
  id,
  ...props
}: UseEstimatesDeleteEstimateByIdProps) =>
  useGet<
    DeleteEstimateViewModel,
    void,
    void,
    EstimatesDeleteEstimateByIdPathParams
  >(
    (paramsInPath: EstimatesDeleteEstimateByIdPathParams) =>
      `/api/v1/estimates/delete/${paramsInPath.id}`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface EstimatesDeleteEstimateByCodeQueryParams {
  code?: string | null;
}

export type EstimatesDeleteEstimateByCodeProps = Omit<
  GetProps<
    DeleteEstimateViewModel,
    void,
    EstimatesDeleteEstimateByCodeQueryParams,
    void
  >,
  "path"
>;

export const EstimatesDeleteEstimateByCode = (
  props: EstimatesDeleteEstimateByCodeProps
) => (
  <Get<
    DeleteEstimateViewModel,
    void,
    EstimatesDeleteEstimateByCodeQueryParams,
    void
  >
    path={`/api/v1/estimates/delete`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseEstimatesDeleteEstimateByCodeProps = Omit<
  UseGetProps<
    DeleteEstimateViewModel,
    void,
    EstimatesDeleteEstimateByCodeQueryParams,
    void
  >,
  "path"
>;

export const useEstimatesDeleteEstimateByCode = (
  props: UseEstimatesDeleteEstimateByCodeProps
) =>
  useGet<
    DeleteEstimateViewModel,
    void,
    EstimatesDeleteEstimateByCodeQueryParams,
    void
  >(`/api/v1/estimates/delete`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface EstimatesDoesEstimateCodesExistQueryParams {
  codes?: string[] | null;
  searchActiveEstimates?: boolean;
}

export type EstimatesDoesEstimateCodesExistProps = Omit<
  GetProps<
    EstimateExistViewModel[],
    void,
    EstimatesDoesEstimateCodesExistQueryParams,
    void
  >,
  "path"
>;

export const EstimatesDoesEstimateCodesExist = (
  props: EstimatesDoesEstimateCodesExistProps
) => (
  <Get<
    EstimateExistViewModel[],
    void,
    EstimatesDoesEstimateCodesExistQueryParams,
    void
  >
    path={`/api/v1/estimates/exist`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseEstimatesDoesEstimateCodesExistProps = Omit<
  UseGetProps<
    EstimateExistViewModel[],
    void,
    EstimatesDoesEstimateCodesExistQueryParams,
    void
  >,
  "path"
>;

export const useEstimatesDoesEstimateCodesExist = (
  props: UseEstimatesDoesEstimateCodesExistProps
) =>
  useGet<
    EstimateExistViewModel[],
    void,
    EstimatesDoesEstimateCodesExistQueryParams,
    void
  >(`/api/v1/estimates/exist`, { base: config.endpoints.HBINSIGHTS, ...props });

export interface EstimatesGetEstimateIndexQueryParams {
  code?: string | null;
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export type EstimatesGetEstimateIndexProps = Omit<
  GetProps<EstimateIndex, void, EstimatesGetEstimateIndexQueryParams, void>,
  "path"
>;

export const EstimatesGetEstimateIndex = (
  props: EstimatesGetEstimateIndexProps
) => (
  <Get<EstimateIndex, void, EstimatesGetEstimateIndexQueryParams, void>
    path={`/api/v1/estimates/estimateindex`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseEstimatesGetEstimateIndexProps = Omit<
  UseGetProps<EstimateIndex, void, EstimatesGetEstimateIndexQueryParams, void>,
  "path"
>;

export const useEstimatesGetEstimateIndex = (
  props: UseEstimatesGetEstimateIndexProps
) =>
  useGet<EstimateIndex, void, EstimatesGetEstimateIndexQueryParams, void>(
    `/api/v1/estimates/estimateindex`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export interface EstimatesGetEstimateBackupHashQueryParams {
  estimateId?: string;
}

export type EstimatesGetEstimateBackupHashProps = Omit<
  GetProps<string, void, EstimatesGetEstimateBackupHashQueryParams, void>,
  "path"
>;

export const EstimatesGetEstimateBackupHash = (
  props: EstimatesGetEstimateBackupHashProps
) => (
  <Get<string, void, EstimatesGetEstimateBackupHashQueryParams, void>
    path={`/api/v1/estimates/hash`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseEstimatesGetEstimateBackupHashProps = Omit<
  UseGetProps<string, void, EstimatesGetEstimateBackupHashQueryParams, void>,
  "path"
>;

export const useEstimatesGetEstimateBackupHash = (
  props: UseEstimatesGetEstimateBackupHashProps
) =>
  useGet<string, void, EstimatesGetEstimateBackupHashQueryParams, void>(
    `/api/v1/estimates/hash`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export interface HBWebGetAllEstimatesQueryParams {
  includeDeletedEstimates?: boolean;
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export type HBWebGetAllEstimatesProps = Omit<
  GetProps<
    IntegrationResponseOfEstimateWithDivision,
    void,
    HBWebGetAllEstimatesQueryParams,
    void
  >,
  "path"
>;

export const HBWebGetAllEstimates = (props: HBWebGetAllEstimatesProps) => (
  <Get<
    IntegrationResponseOfEstimateWithDivision,
    void,
    HBWebGetAllEstimatesQueryParams,
    void
  >
    path={`/api/v1/hbweb/estimates`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseHBWebGetAllEstimatesProps = Omit<
  UseGetProps<
    IntegrationResponseOfEstimateWithDivision,
    void,
    HBWebGetAllEstimatesQueryParams,
    void
  >,
  "path"
>;

export const useHBWebGetAllEstimates = (props: UseHBWebGetAllEstimatesProps) =>
  useGet<
    IntegrationResponseOfEstimateWithDivision,
    void,
    HBWebGetAllEstimatesQueryParams,
    void
  >(`/api/v1/hbweb/estimates`, { base: config.endpoints.HBINSIGHTS, ...props });

export interface InsightsEstimatesGetEstimateQueryParams {
  liveEstimates?: boolean;
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export type InsightsEstimatesGetEstimateProps = Omit<
  GetProps<
    EstimateFlattened[],
    void,
    InsightsEstimatesGetEstimateQueryParams,
    void
  >,
  "path"
>;

export const InsightsEstimatesGetEstimate = (
  props: InsightsEstimatesGetEstimateProps
) => (
  <Get<EstimateFlattened[], void, InsightsEstimatesGetEstimateQueryParams, void>
    path={`/api/v1/insights/estimates`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseInsightsEstimatesGetEstimateProps = Omit<
  UseGetProps<
    EstimateFlattened[],
    void,
    InsightsEstimatesGetEstimateQueryParams,
    void
  >,
  "path"
>;

export const useInsightsEstimatesGetEstimate = (
  props: UseInsightsEstimatesGetEstimateProps
) =>
  useGet<
    EstimateFlattened[],
    void,
    InsightsEstimatesGetEstimateQueryParams,
    void
  >(`/api/v1/insights/estimates`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface InsightsEstimatesGetReportsForEstimateQueryParams {
  estimateId?: string;
}

export type InsightsEstimatesGetReportsForEstimateProps = Omit<
  GetProps<
    ReportsDto,
    void,
    InsightsEstimatesGetReportsForEstimateQueryParams,
    void
  >,
  "path"
>;

export const InsightsEstimatesGetReportsForEstimate = (
  props: InsightsEstimatesGetReportsForEstimateProps
) => (
  <Get<
    ReportsDto,
    void,
    InsightsEstimatesGetReportsForEstimateQueryParams,
    void
  >
    path={`/api/v1/insights/estimates/reports`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseInsightsEstimatesGetReportsForEstimateProps = Omit<
  UseGetProps<
    ReportsDto,
    void,
    InsightsEstimatesGetReportsForEstimateQueryParams,
    void
  >,
  "path"
>;

export const useInsightsEstimatesGetReportsForEstimate = (
  props: UseInsightsEstimatesGetReportsForEstimateProps
) =>
  useGet<
    ReportsDto,
    void,
    InsightsEstimatesGetReportsForEstimateQueryParams,
    void
  >(`/api/v1/insights/estimates/reports`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export type InsightsEstimatesGetUniqueEstimateFiltersProps = Omit<
  GetProps<UniqueEstimateFilters, void, void, void>,
  "path"
>;

export const InsightsEstimatesGetUniqueEstimateFilters = (
  props: InsightsEstimatesGetUniqueEstimateFiltersProps
) => (
  <Get<UniqueEstimateFilters, void, void, void>
    path={`/api/v1/insights/estimates/filters`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseInsightsEstimatesGetUniqueEstimateFiltersProps = Omit<
  UseGetProps<UniqueEstimateFilters, void, void, void>,
  "path"
>;

export const useInsightsEstimatesGetUniqueEstimateFilters = (
  props: UseInsightsEstimatesGetUniqueEstimateFiltersProps
) =>
  useGet<UniqueEstimateFilters, void, void, void>(
    `/api/v1/insights/estimates/filters`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export type InsightsEstimatesPostExcludedProps = Omit<
  MutateProps<Estimate, void, void, EstimateExcluded, void>,
  "path" | "verb"
>;

export const InsightsEstimatesPostExcluded = (
  props: InsightsEstimatesPostExcludedProps
) => (
  <Mutate<Estimate, void, void, EstimateExcluded, void>
    verb="POST"
    path={`/api/v1/insights/estimates/excluded`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseInsightsEstimatesPostExcludedProps = Omit<
  UseMutateProps<Estimate, void, void, EstimateExcluded, void>,
  "path" | "verb"
>;

export const useInsightsEstimatesPostExcluded = (
  props: UseInsightsEstimatesPostExcludedProps
) =>
  useMutate<Estimate, void, void, EstimateExcluded, void>(
    "POST",
    `/api/v1/insights/estimates/excluded`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export interface IntegrationsGetAllEstimatesQueryParams {
  includeExcludedEstimates?: boolean;
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export type IntegrationsGetAllEstimatesProps = Omit<
  GetProps<Estimate[], void, IntegrationsGetAllEstimatesQueryParams, void>,
  "path"
>;

export const IntegrationsGetAllEstimates = (
  props: IntegrationsGetAllEstimatesProps
) => (
  <Get<Estimate[], void, IntegrationsGetAllEstimatesQueryParams, void>
    path={`/api/v1/integration/estimates/all`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsGetAllEstimatesProps = Omit<
  UseGetProps<Estimate[], void, IntegrationsGetAllEstimatesQueryParams, void>,
  "path"
>;

export const useIntegrationsGetAllEstimates = (
  props: UseIntegrationsGetAllEstimatesProps
) =>
  useGet<Estimate[], void, IntegrationsGetAllEstimatesQueryParams, void>(
    `/api/v1/integration/estimates/all`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export interface IntegrationsGetEstimatePathParams {
  id: string;
}

export type IntegrationsGetEstimateProps = Omit<
  GetProps<Estimate, void, void, IntegrationsGetEstimatePathParams>,
  "path"
> &
  IntegrationsGetEstimatePathParams;

export const IntegrationsGetEstimate = ({
  id,
  ...props
}: IntegrationsGetEstimateProps) => (
  <Get<Estimate, void, void, IntegrationsGetEstimatePathParams>
    path={`/api/v1/integration/estimates/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsGetEstimateProps = Omit<
  UseGetProps<Estimate, void, void, IntegrationsGetEstimatePathParams>,
  "path"
> &
  IntegrationsGetEstimatePathParams;

export const useIntegrationsGetEstimate = ({
  id,
  ...props
}: UseIntegrationsGetEstimateProps) =>
  useGet<Estimate, void, void, IntegrationsGetEstimatePathParams>(
    (paramsInPath: IntegrationsGetEstimatePathParams) =>
      `/api/v1/integration/estimates/${paramsInPath.id}`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface IntegrationsGetAllActivityQueryParams {
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export type IntegrationsGetAllActivityProps = Omit<
  GetProps<Activity[], void, IntegrationsGetAllActivityQueryParams, void>,
  "path"
>;

export const IntegrationsGetAllActivity = (
  props: IntegrationsGetAllActivityProps
) => (
  <Get<Activity[], void, IntegrationsGetAllActivityQueryParams, void>
    path={`/api/v1/integration/activity/all`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsGetAllActivityProps = Omit<
  UseGetProps<Activity[], void, IntegrationsGetAllActivityQueryParams, void>,
  "path"
>;

export const useIntegrationsGetAllActivity = (
  props: UseIntegrationsGetAllActivityProps
) =>
  useGet<Activity[], void, IntegrationsGetAllActivityQueryParams, void>(
    `/api/v1/integration/activity/all`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export interface IntegrationsGetActivityPathParams {
  id: string;
}

export type IntegrationsGetActivityProps = Omit<
  GetProps<Activity, void, void, IntegrationsGetActivityPathParams>,
  "path"
> &
  IntegrationsGetActivityPathParams;

export const IntegrationsGetActivity = ({
  id,
  ...props
}: IntegrationsGetActivityProps) => (
  <Get<Activity, void, void, IntegrationsGetActivityPathParams>
    path={`/api/v1/integration/activity/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsGetActivityProps = Omit<
  UseGetProps<Activity, void, void, IntegrationsGetActivityPathParams>,
  "path"
> &
  IntegrationsGetActivityPathParams;

export const useIntegrationsGetActivity = ({
  id,
  ...props
}: UseIntegrationsGetActivityProps) =>
  useGet<Activity, void, void, IntegrationsGetActivityPathParams>(
    (paramsInPath: IntegrationsGetActivityPathParams) =>
      `/api/v1/integration/activity/${paramsInPath.id}`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface IntegrationsGetAllBidItemsQueryParams {
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export type IntegrationsGetAllBidItemsProps = Omit<
  GetProps<Biditem[], void, IntegrationsGetAllBidItemsQueryParams, void>,
  "path"
>;

export const IntegrationsGetAllBidItems = (
  props: IntegrationsGetAllBidItemsProps
) => (
  <Get<Biditem[], void, IntegrationsGetAllBidItemsQueryParams, void>
    path={`/api/v1/integration/biditem/all`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsGetAllBidItemsProps = Omit<
  UseGetProps<Biditem[], void, IntegrationsGetAllBidItemsQueryParams, void>,
  "path"
>;

export const useIntegrationsGetAllBidItems = (
  props: UseIntegrationsGetAllBidItemsProps
) =>
  useGet<Biditem[], void, IntegrationsGetAllBidItemsQueryParams, void>(
    `/api/v1/integration/biditem/all`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export interface IntegrationsGetBidItemPathParams {
  id: string;
}

export type IntegrationsGetBidItemProps = Omit<
  GetProps<Biditem, void, void, IntegrationsGetBidItemPathParams>,
  "path"
> &
  IntegrationsGetBidItemPathParams;

export const IntegrationsGetBidItem = ({
  id,
  ...props
}: IntegrationsGetBidItemProps) => (
  <Get<Biditem, void, void, IntegrationsGetBidItemPathParams>
    path={`/api/v1/integration/biditem/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsGetBidItemProps = Omit<
  UseGetProps<Biditem, void, void, IntegrationsGetBidItemPathParams>,
  "path"
> &
  IntegrationsGetBidItemPathParams;

export const useIntegrationsGetBidItem = ({
  id,
  ...props
}: UseIntegrationsGetBidItemProps) =>
  useGet<Biditem, void, void, IntegrationsGetBidItemPathParams>(
    (paramsInPath: IntegrationsGetBidItemPathParams) =>
      `/api/v1/integration/biditem/${paramsInPath.id}`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface IntegrationsGetAllBusinessUnitsQueryParams {
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export type IntegrationsGetAllBusinessUnitsProps = Omit<
  GetProps<
    BusinessUnit[],
    void,
    IntegrationsGetAllBusinessUnitsQueryParams,
    void
  >,
  "path"
>;

export const IntegrationsGetAllBusinessUnits = (
  props: IntegrationsGetAllBusinessUnitsProps
) => (
  <Get<BusinessUnit[], void, IntegrationsGetAllBusinessUnitsQueryParams, void>
    path={`/api/v1/integration/businessunit/all`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsGetAllBusinessUnitsProps = Omit<
  UseGetProps<
    BusinessUnit[],
    void,
    IntegrationsGetAllBusinessUnitsQueryParams,
    void
  >,
  "path"
>;

export const useIntegrationsGetAllBusinessUnits = (
  props: UseIntegrationsGetAllBusinessUnitsProps
) =>
  useGet<
    BusinessUnit[],
    void,
    IntegrationsGetAllBusinessUnitsQueryParams,
    void
  >(`/api/v1/integration/businessunit/all`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface IntegrationsGetBusinessUnitByIdPathParams {
  id: string;
}

export type IntegrationsGetBusinessUnitByIdProps = Omit<
  GetProps<BusinessUnit, void, void, IntegrationsGetBusinessUnitByIdPathParams>,
  "path"
> &
  IntegrationsGetBusinessUnitByIdPathParams;

export const IntegrationsGetBusinessUnitById = ({
  id,
  ...props
}: IntegrationsGetBusinessUnitByIdProps) => (
  <Get<BusinessUnit, void, void, IntegrationsGetBusinessUnitByIdPathParams>
    path={`/api/v1/integration/businessunit/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsGetBusinessUnitByIdProps = Omit<
  UseGetProps<
    BusinessUnit,
    void,
    void,
    IntegrationsGetBusinessUnitByIdPathParams
  >,
  "path"
> &
  IntegrationsGetBusinessUnitByIdPathParams;

export const useIntegrationsGetBusinessUnitById = ({
  id,
  ...props
}: UseIntegrationsGetBusinessUnitByIdProps) =>
  useGet<BusinessUnit, void, void, IntegrationsGetBusinessUnitByIdPathParams>(
    (paramsInPath: IntegrationsGetBusinessUnitByIdPathParams) =>
      `/api/v1/integration/businessunit/${paramsInPath.id}`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface IntegrationsUpdateBusinessUnitQueryParams {
  buId?: string;
  buCode?: string | null;
}

export interface IntegrationsUpdateBusinessUnitPathParams {
  id: string;
}

export type IntegrationsUpdateBusinessUnitProps = Omit<
  MutateProps<
    void,
    void,
    IntegrationsUpdateBusinessUnitQueryParams,
    void,
    IntegrationsUpdateBusinessUnitPathParams
  >,
  "path" | "verb"
> &
  IntegrationsUpdateBusinessUnitPathParams;

export const IntegrationsUpdateBusinessUnit = ({
  id,
  ...props
}: IntegrationsUpdateBusinessUnitProps) => (
  <Mutate<
    void,
    void,
    IntegrationsUpdateBusinessUnitQueryParams,
    void,
    IntegrationsUpdateBusinessUnitPathParams
  >
    verb="POST"
    path={`/api/v1/integration/businessunit/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsUpdateBusinessUnitProps = Omit<
  UseMutateProps<
    void,
    void,
    IntegrationsUpdateBusinessUnitQueryParams,
    void,
    IntegrationsUpdateBusinessUnitPathParams
  >,
  "path" | "verb"
> &
  IntegrationsUpdateBusinessUnitPathParams;

export const useIntegrationsUpdateBusinessUnit = ({
  id,
  ...props
}: UseIntegrationsUpdateBusinessUnitProps) =>
  useMutate<
    void,
    void,
    IntegrationsUpdateBusinessUnitQueryParams,
    void,
    IntegrationsUpdateBusinessUnitPathParams
  >(
    "POST",
    (paramsInPath: IntegrationsUpdateBusinessUnitPathParams) =>
      `/api/v1/integration/businessunit/${paramsInPath.id}`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface IntegrationsGetAllDatabasePartitionsQueryParams {
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export type IntegrationsGetAllDatabasePartitionsProps = Omit<
  GetProps<
    DatabasePartition[],
    void,
    IntegrationsGetAllDatabasePartitionsQueryParams,
    void
  >,
  "path"
>;

export const IntegrationsGetAllDatabasePartitions = (
  props: IntegrationsGetAllDatabasePartitionsProps
) => (
  <Get<
    DatabasePartition[],
    void,
    IntegrationsGetAllDatabasePartitionsQueryParams,
    void
  >
    path={`/api/v1/integration/databasepartition/all`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsGetAllDatabasePartitionsProps = Omit<
  UseGetProps<
    DatabasePartition[],
    void,
    IntegrationsGetAllDatabasePartitionsQueryParams,
    void
  >,
  "path"
>;

export const useIntegrationsGetAllDatabasePartitions = (
  props: UseIntegrationsGetAllDatabasePartitionsProps
) =>
  useGet<
    DatabasePartition[],
    void,
    IntegrationsGetAllDatabasePartitionsQueryParams,
    void
  >(`/api/v1/integration/databasepartition/all`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface IntegrationsGetDatabasePartitionByIdPathParams {
  id: string;
}

export type IntegrationsGetDatabasePartitionByIdProps = Omit<
  GetProps<
    DatabasePartition,
    void,
    void,
    IntegrationsGetDatabasePartitionByIdPathParams
  >,
  "path"
> &
  IntegrationsGetDatabasePartitionByIdPathParams;

export const IntegrationsGetDatabasePartitionById = ({
  id,
  ...props
}: IntegrationsGetDatabasePartitionByIdProps) => (
  <Get<
    DatabasePartition,
    void,
    void,
    IntegrationsGetDatabasePartitionByIdPathParams
  >
    path={`/api/v1/integration/databasepartition/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsGetDatabasePartitionByIdProps = Omit<
  UseGetProps<
    DatabasePartition,
    void,
    void,
    IntegrationsGetDatabasePartitionByIdPathParams
  >,
  "path"
> &
  IntegrationsGetDatabasePartitionByIdPathParams;

export const useIntegrationsGetDatabasePartitionById = ({
  id,
  ...props
}: UseIntegrationsGetDatabasePartitionByIdProps) =>
  useGet<
    DatabasePartition,
    void,
    void,
    IntegrationsGetDatabasePartitionByIdPathParams
  >(
    (paramsInPath: IntegrationsGetDatabasePartitionByIdPathParams) =>
      `/api/v1/integration/databasepartition/${paramsInPath.id}`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface IntegrationsGetAllKeyIndicatorsQueryParams {
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export type IntegrationsGetAllKeyIndicatorsProps = Omit<
  GetProps<
    KeyIndicator[],
    void,
    IntegrationsGetAllKeyIndicatorsQueryParams,
    void
  >,
  "path"
>;

export const IntegrationsGetAllKeyIndicators = (
  props: IntegrationsGetAllKeyIndicatorsProps
) => (
  <Get<KeyIndicator[], void, IntegrationsGetAllKeyIndicatorsQueryParams, void>
    path={`/api/v1/integration/keyindicator/all`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsGetAllKeyIndicatorsProps = Omit<
  UseGetProps<
    KeyIndicator[],
    void,
    IntegrationsGetAllKeyIndicatorsQueryParams,
    void
  >,
  "path"
>;

export const useIntegrationsGetAllKeyIndicators = (
  props: UseIntegrationsGetAllKeyIndicatorsProps
) =>
  useGet<
    KeyIndicator[],
    void,
    IntegrationsGetAllKeyIndicatorsQueryParams,
    void
  >(`/api/v1/integration/keyindicator/all`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface IntegrationsGetKeyIndicatorByIdPathParams {
  id: string;
}

export type IntegrationsGetKeyIndicatorByIdProps = Omit<
  GetProps<KeyIndicator, void, void, IntegrationsGetKeyIndicatorByIdPathParams>,
  "path"
> &
  IntegrationsGetKeyIndicatorByIdPathParams;

export const IntegrationsGetKeyIndicatorById = ({
  id,
  ...props
}: IntegrationsGetKeyIndicatorByIdProps) => (
  <Get<KeyIndicator, void, void, IntegrationsGetKeyIndicatorByIdPathParams>
    path={`/api/v1/integration/keyindicator/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsGetKeyIndicatorByIdProps = Omit<
  UseGetProps<
    KeyIndicator,
    void,
    void,
    IntegrationsGetKeyIndicatorByIdPathParams
  >,
  "path"
> &
  IntegrationsGetKeyIndicatorByIdPathParams;

export const useIntegrationsGetKeyIndicatorById = ({
  id,
  ...props
}: UseIntegrationsGetKeyIndicatorByIdProps) =>
  useGet<KeyIndicator, void, void, IntegrationsGetKeyIndicatorByIdPathParams>(
    (paramsInPath: IntegrationsGetKeyIndicatorByIdPathParams) =>
      `/api/v1/integration/keyindicator/${paramsInPath.id}`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface IntegrationsGetAllResourcesQueryParams {
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export type IntegrationsGetAllResourcesProps = Omit<
  GetProps<Resource[], void, IntegrationsGetAllResourcesQueryParams, void>,
  "path"
>;

export const IntegrationsGetAllResources = (
  props: IntegrationsGetAllResourcesProps
) => (
  <Get<Resource[], void, IntegrationsGetAllResourcesQueryParams, void>
    path={`/api/v1/integration/resource/all`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsGetAllResourcesProps = Omit<
  UseGetProps<Resource[], void, IntegrationsGetAllResourcesQueryParams, void>,
  "path"
>;

export const useIntegrationsGetAllResources = (
  props: UseIntegrationsGetAllResourcesProps
) =>
  useGet<Resource[], void, IntegrationsGetAllResourcesQueryParams, void>(
    `/api/v1/integration/resource/all`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export interface IntegrationsGetResourceByIdPathParams {
  id: string;
}

export type IntegrationsGetResourceByIdProps = Omit<
  GetProps<Resource, void, void, IntegrationsGetResourceByIdPathParams>,
  "path"
> &
  IntegrationsGetResourceByIdPathParams;

export const IntegrationsGetResourceById = ({
  id,
  ...props
}: IntegrationsGetResourceByIdProps) => (
  <Get<Resource, void, void, IntegrationsGetResourceByIdPathParams>
    path={`/api/v1/integration/resource/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsGetResourceByIdProps = Omit<
  UseGetProps<Resource, void, void, IntegrationsGetResourceByIdPathParams>,
  "path"
> &
  IntegrationsGetResourceByIdPathParams;

export const useIntegrationsGetResourceById = ({
  id,
  ...props
}: UseIntegrationsGetResourceByIdProps) =>
  useGet<Resource, void, void, IntegrationsGetResourceByIdPathParams>(
    (paramsInPath: IntegrationsGetResourceByIdPathParams) =>
      `/api/v1/integration/resource/${paramsInPath.id}`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface IntegrationsGetAllSystemInfoQueryParams {
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export type IntegrationsGetAllSystemInfoProps = Omit<
  GetProps<SystemInfo[], void, IntegrationsGetAllSystemInfoQueryParams, void>,
  "path"
>;

export const IntegrationsGetAllSystemInfo = (
  props: IntegrationsGetAllSystemInfoProps
) => (
  <Get<SystemInfo[], void, IntegrationsGetAllSystemInfoQueryParams, void>
    path={`/api/v1/integration/systeminfo/all`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsGetAllSystemInfoProps = Omit<
  UseGetProps<
    SystemInfo[],
    void,
    IntegrationsGetAllSystemInfoQueryParams,
    void
  >,
  "path"
>;

export const useIntegrationsGetAllSystemInfo = (
  props: UseIntegrationsGetAllSystemInfoProps
) =>
  useGet<SystemInfo[], void, IntegrationsGetAllSystemInfoQueryParams, void>(
    `/api/v1/integration/systeminfo/all`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export interface IntegrationsGetSystemInfoByIdPathParams {
  id: string;
}

export type IntegrationsGetSystemInfoByIdProps = Omit<
  GetProps<SystemInfo, void, void, IntegrationsGetSystemInfoByIdPathParams>,
  "path"
> &
  IntegrationsGetSystemInfoByIdPathParams;

export const IntegrationsGetSystemInfoById = ({
  id,
  ...props
}: IntegrationsGetSystemInfoByIdProps) => (
  <Get<SystemInfo, void, void, IntegrationsGetSystemInfoByIdPathParams>
    path={`/api/v1/integration/systeminfo/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsGetSystemInfoByIdProps = Omit<
  UseGetProps<SystemInfo, void, void, IntegrationsGetSystemInfoByIdPathParams>,
  "path"
> &
  IntegrationsGetSystemInfoByIdPathParams;

export const useIntegrationsGetSystemInfoById = ({
  id,
  ...props
}: UseIntegrationsGetSystemInfoByIdProps) =>
  useGet<SystemInfo, void, void, IntegrationsGetSystemInfoByIdPathParams>(
    (paramsInPath: IntegrationsGetSystemInfoByIdPathParams) =>
      `/api/v1/integration/systeminfo/${paramsInPath.id}`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface KeyIndicatorsGetAllCalulatedKPIsQueryParams {
  currentEstimateId?: string | null;
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export type KeyIndicatorsGetAllCalulatedKPIsProps = Omit<
  GetProps<
    EstimateKPIs,
    void,
    KeyIndicatorsGetAllCalulatedKPIsQueryParams,
    void
  >,
  "path"
>;

export const KeyIndicatorsGetAllCalulatedKPIs = (
  props: KeyIndicatorsGetAllCalulatedKPIsProps
) => (
  <Get<EstimateKPIs, void, KeyIndicatorsGetAllCalulatedKPIsQueryParams, void>
    path={`/api/v1/insights/keyindicators`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseKeyIndicatorsGetAllCalulatedKPIsProps = Omit<
  UseGetProps<
    EstimateKPIs,
    void,
    KeyIndicatorsGetAllCalulatedKPIsQueryParams,
    void
  >,
  "path"
>;

export const useKeyIndicatorsGetAllCalulatedKPIs = (
  props: UseKeyIndicatorsGetAllCalulatedKPIsProps
) =>
  useGet<EstimateKPIs, void, KeyIndicatorsGetAllCalulatedKPIsQueryParams, void>(
    `/api/v1/insights/keyindicators`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export type KeyIndicatorsImportKeyIndicatorsProps = Omit<
  MutateProps<GenericResponse, void, void, KeyIndicatorViewModel[], void>,
  "path" | "verb"
>;

export const KeyIndicatorsImportKeyIndicators = (
  props: KeyIndicatorsImportKeyIndicatorsProps
) => (
  <Mutate<GenericResponse, void, void, KeyIndicatorViewModel[], void>
    verb="POST"
    path={`/api/v1/insights/keyindicators/import`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseKeyIndicatorsImportKeyIndicatorsProps = Omit<
  UseMutateProps<GenericResponse, void, void, KeyIndicatorViewModel[], void>,
  "path" | "verb"
>;

export const useKeyIndicatorsImportKeyIndicators = (
  props: UseKeyIndicatorsImportKeyIndicatorsProps
) =>
  useMutate<GenericResponse, void, void, KeyIndicatorViewModel[], void>(
    "POST",
    `/api/v1/insights/keyindicators/import`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export type PingGetProps = Omit<
  GetProps<PingViewModel, void, void, void>,
  "path"
>;

export const PingGet = (props: PingGetProps) => (
  <Get<PingViewModel, void, void, void>
    path={`/api/v1/ping`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UsePingGetProps = Omit<
  UseGetProps<PingViewModel, void, void, void>,
  "path"
>;

export const usePingGet = (props: UsePingGetProps) =>
  useGet<PingViewModel, void, void, void>(`/api/v1/ping`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface QuoteFoldersGetQuoteFoldersQueryParams {
  estimateId?: string | null;
}

export type QuoteFoldersGetQuoteFoldersProps = Omit<
  GetProps<
    QuoteFolderSummaryDto[],
    void,
    QuoteFoldersGetQuoteFoldersQueryParams,
    void
  >,
  "path"
>;

export const QuoteFoldersGetQuoteFolders = (
  props: QuoteFoldersGetQuoteFoldersProps
) => (
  <Get<
    QuoteFolderSummaryDto[],
    void,
    QuoteFoldersGetQuoteFoldersQueryParams,
    void
  >
    path={`/api/v1/quoteFolders`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuoteFoldersGetQuoteFoldersProps = Omit<
  UseGetProps<
    QuoteFolderSummaryDto[],
    void,
    QuoteFoldersGetQuoteFoldersQueryParams,
    void
  >,
  "path"
>;

export const useQuoteFoldersGetQuoteFolders = (
  props: UseQuoteFoldersGetQuoteFoldersProps
) =>
  useGet<
    QuoteFolderSummaryDto[],
    void,
    QuoteFoldersGetQuoteFoldersQueryParams,
    void
  >(`/api/v1/quoteFolders`, { base: config.endpoints.HBINSIGHTS, ...props });

export type QuoteFoldersCreateQuoteFolderProps = Omit<
  MutateProps<string, void, void, void, void>,
  "path" | "verb"
>;

export const QuoteFoldersCreateQuoteFolder = (
  props: QuoteFoldersCreateQuoteFolderProps
) => (
  <Mutate<string, void, void, void, void>
    verb="POST"
    path={`/api/v1/quoteFolders`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuoteFoldersCreateQuoteFolderProps = Omit<
  UseMutateProps<string, void, void, void, void>,
  "path" | "verb"
>;

export const useQuoteFoldersCreateQuoteFolder = (
  props: UseQuoteFoldersCreateQuoteFolderProps
) =>
  useMutate<string, void, void, void, void>("POST", `/api/v1/quoteFolders`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface QuoteFoldersGetQuoteFolderPathParams {
  id: string;
}

export type QuoteFoldersGetQuoteFolderProps = Omit<
  GetProps<QuoteFolderDto, void, void, QuoteFoldersGetQuoteFolderPathParams>,
  "path"
> &
  QuoteFoldersGetQuoteFolderPathParams;

export const QuoteFoldersGetQuoteFolder = ({
  id,
  ...props
}: QuoteFoldersGetQuoteFolderProps) => (
  <Get<QuoteFolderDto, void, void, QuoteFoldersGetQuoteFolderPathParams>
    path={`/api/v1/quoteFolders/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuoteFoldersGetQuoteFolderProps = Omit<
  UseGetProps<QuoteFolderDto, void, void, QuoteFoldersGetQuoteFolderPathParams>,
  "path"
> &
  QuoteFoldersGetQuoteFolderPathParams;

export const useQuoteFoldersGetQuoteFolder = ({
  id,
  ...props
}: UseQuoteFoldersGetQuoteFolderProps) =>
  useGet<QuoteFolderDto, void, void, QuoteFoldersGetQuoteFolderPathParams>(
    (paramsInPath: QuoteFoldersGetQuoteFolderPathParams) =>
      `/api/v1/quoteFolders/${paramsInPath.id}`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface QuoteFoldersUpdateQuoteFolderPathParams {
  id: string;
}

export type QuoteFoldersUpdateQuoteFolderProps = Omit<
  MutateProps<
    string,
    void,
    void,
    void,
    QuoteFoldersUpdateQuoteFolderPathParams
  >,
  "path" | "verb"
> &
  QuoteFoldersUpdateQuoteFolderPathParams;

export const QuoteFoldersUpdateQuoteFolder = ({
  id,
  ...props
}: QuoteFoldersUpdateQuoteFolderProps) => (
  <Mutate<string, void, void, void, QuoteFoldersUpdateQuoteFolderPathParams>
    verb="PUT"
    path={`/api/v1/quoteFolders/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuoteFoldersUpdateQuoteFolderProps = Omit<
  UseMutateProps<
    string,
    void,
    void,
    void,
    QuoteFoldersUpdateQuoteFolderPathParams
  >,
  "path" | "verb"
> &
  QuoteFoldersUpdateQuoteFolderPathParams;

export const useQuoteFoldersUpdateQuoteFolder = ({
  id,
  ...props
}: UseQuoteFoldersUpdateQuoteFolderProps) =>
  useMutate<string, void, void, void, QuoteFoldersUpdateQuoteFolderPathParams>(
    "PUT",
    (paramsInPath: QuoteFoldersUpdateQuoteFolderPathParams) =>
      `/api/v1/quoteFolders/${paramsInPath.id}`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export type QuoteFoldersDeleteQuoteFolderProps = Omit<
  MutateProps<string, void, void, string, void>,
  "path" | "verb"
>;

export const QuoteFoldersDeleteQuoteFolder = (
  props: QuoteFoldersDeleteQuoteFolderProps
) => (
  <Mutate<string, void, void, string, void>
    verb="DELETE"
    path={`/api/v1/quoteFolders`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuoteFoldersDeleteQuoteFolderProps = Omit<
  UseMutateProps<string, void, void, string, void>,
  "path" | "verb"
>;

export const useQuoteFoldersDeleteQuoteFolder = (
  props: UseQuoteFoldersDeleteQuoteFolderProps
) =>
  useMutate<string, void, void, string, void>(
    "DELETE",
    `/api/v1/quoteFolders`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export interface QuoteFoldersGetQuoteFoldersDetailsQueryParams {
  estimateId?: string | null;
}

export type QuoteFoldersGetQuoteFoldersDetailsProps = Omit<
  GetProps<
    QuoteFolderDto[],
    void,
    QuoteFoldersGetQuoteFoldersDetailsQueryParams,
    void
  >,
  "path"
>;

export const QuoteFoldersGetQuoteFoldersDetails = (
  props: QuoteFoldersGetQuoteFoldersDetailsProps
) => (
  <Get<
    QuoteFolderDto[],
    void,
    QuoteFoldersGetQuoteFoldersDetailsQueryParams,
    void
  >
    path={`/api/v1/quoteFolders/details`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuoteFoldersGetQuoteFoldersDetailsProps = Omit<
  UseGetProps<
    QuoteFolderDto[],
    void,
    QuoteFoldersGetQuoteFoldersDetailsQueryParams,
    void
  >,
  "path"
>;

export const useQuoteFoldersGetQuoteFoldersDetails = (
  props: UseQuoteFoldersGetQuoteFoldersDetailsProps
) =>
  useGet<
    QuoteFolderDto[],
    void,
    QuoteFoldersGetQuoteFoldersDetailsQueryParams,
    void
  >(`/api/v1/quoteFolders/details`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface QuoteMastersGetQuoteMasterByEstimateCodePathParams {
  estimateCode: string | null;
}

export type QuoteMastersGetQuoteMasterByEstimateCodeProps = Omit<
  GetProps<
    QuoteMasterDto,
    void,
    void,
    QuoteMastersGetQuoteMasterByEstimateCodePathParams
  >,
  "path"
> &
  QuoteMastersGetQuoteMasterByEstimateCodePathParams;

export const QuoteMastersGetQuoteMasterByEstimateCode = ({
  estimateCode,
  ...props
}: QuoteMastersGetQuoteMasterByEstimateCodeProps) => (
  <Get<
    QuoteMasterDto,
    void,
    void,
    QuoteMastersGetQuoteMasterByEstimateCodePathParams
  >
    path={`/api/v1/quoteMasters/${estimateCode}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuoteMastersGetQuoteMasterByEstimateCodeProps = Omit<
  UseGetProps<
    QuoteMasterDto,
    void,
    void,
    QuoteMastersGetQuoteMasterByEstimateCodePathParams
  >,
  "path"
> &
  QuoteMastersGetQuoteMasterByEstimateCodePathParams;

export const useQuoteMastersGetQuoteMasterByEstimateCode = ({
  estimateCode,
  ...props
}: UseQuoteMastersGetQuoteMasterByEstimateCodeProps) =>
  useGet<
    QuoteMasterDto,
    void,
    void,
    QuoteMastersGetQuoteMasterByEstimateCodePathParams
  >(
    (paramsInPath: QuoteMastersGetQuoteMasterByEstimateCodePathParams) =>
      `/api/v1/quoteMasters/${paramsInPath.estimateCode}`,
    {
      base: config.endpoints.HBINSIGHTS,
      pathParams: { estimateCode },
      ...props
    }
  );

export type QuoteMastersSyncQuotesMasterProps = Omit<
  MutateProps<GenericResponse, void, void, QuoteMasterDto, void>,
  "path" | "verb"
>;

export const QuoteMastersSyncQuotesMaster = (
  props: QuoteMastersSyncQuotesMasterProps
) => (
  <Mutate<GenericResponse, void, void, QuoteMasterDto, void>
    verb="POST"
    path={`/api/v1/quoteMasters`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuoteMastersSyncQuotesMasterProps = Omit<
  UseMutateProps<GenericResponse, void, void, QuoteMasterDto, void>,
  "path" | "verb"
>;

export const useQuoteMastersSyncQuotesMaster = (
  props: UseQuoteMastersSyncQuotesMasterProps
) =>
  useMutate<GenericResponse, void, void, QuoteMasterDto, void>(
    "POST",
    `/api/v1/quoteMasters`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export type QuotesGetQuotesProps = Omit<
  GetProps<string, void, void, void>,
  "path"
>;

export const QuotesGetQuotes = (props: QuotesGetQuotesProps) => (
  <Get<string, void, void, void>
    path={`/api/v1/quotes`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuotesGetQuotesProps = Omit<
  UseGetProps<string, void, void, void>,
  "path"
>;

export const useQuotesGetQuotes = (props: UseQuotesGetQuotesProps) =>
  useGet<string, void, void, void>(`/api/v1/quotes`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export type QuotesCreateQuoteProps = Omit<
  MutateProps<void, void, void, void, void>,
  "path" | "verb"
>;

export const QuotesCreateQuote = (props: QuotesCreateQuoteProps) => (
  <Mutate<void, void, void, void, void>
    verb="POST"
    path={`/api/v1/quotes`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuotesCreateQuoteProps = Omit<
  UseMutateProps<void, void, void, void, void>,
  "path" | "verb"
>;

export const useQuotesCreateQuote = (props: UseQuotesCreateQuoteProps) =>
  useMutate<void, void, void, void, void>("POST", `/api/v1/quotes`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface QuotesGetQuotePathParams {
  id: string;
}

export type QuotesGetQuoteProps = Omit<
  GetProps<string, void, void, QuotesGetQuotePathParams>,
  "path"
> &
  QuotesGetQuotePathParams;

export const QuotesGetQuote = ({ id, ...props }: QuotesGetQuoteProps) => (
  <Get<string, void, void, QuotesGetQuotePathParams>
    path={`/api/v1/quotes/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuotesGetQuoteProps = Omit<
  UseGetProps<string, void, void, QuotesGetQuotePathParams>,
  "path"
> &
  QuotesGetQuotePathParams;

export const useQuotesGetQuote = ({ id, ...props }: UseQuotesGetQuoteProps) =>
  useGet<string, void, void, QuotesGetQuotePathParams>(
    (paramsInPath: QuotesGetQuotePathParams) =>
      `/api/v1/quotes/${paramsInPath.id}`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface QuotesUpdateQuotePathParams {
  id: string;
}

export type QuotesUpdateQuoteProps = Omit<
  MutateProps<void, void, void, void, QuotesUpdateQuotePathParams>,
  "path" | "verb"
> &
  QuotesUpdateQuotePathParams;

export const QuotesUpdateQuote = ({ id, ...props }: QuotesUpdateQuoteProps) => (
  <Mutate<void, void, void, void, QuotesUpdateQuotePathParams>
    verb="PUT"
    path={`/api/v1/quotes/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuotesUpdateQuoteProps = Omit<
  UseMutateProps<void, void, void, void, QuotesUpdateQuotePathParams>,
  "path" | "verb"
> &
  QuotesUpdateQuotePathParams;

export const useQuotesUpdateQuote = ({
  id,
  ...props
}: UseQuotesUpdateQuoteProps) =>
  useMutate<void, void, void, void, QuotesUpdateQuotePathParams>(
    "PUT",
    (paramsInPath: QuotesUpdateQuotePathParams) =>
      `/api/v1/quotes/${paramsInPath.id}`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export type QuotesDeleteQuoteProps = Omit<
  MutateProps<void, void, void, string, void>,
  "path" | "verb"
>;

export const QuotesDeleteQuote = (props: QuotesDeleteQuoteProps) => (
  <Mutate<void, void, void, string, void>
    verb="DELETE"
    path={`/api/v1/quotes`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuotesDeleteQuoteProps = Omit<
  UseMutateProps<void, void, void, string, void>,
  "path" | "verb"
>;

export const useQuotesDeleteQuote = (props: UseQuotesDeleteQuoteProps) =>
  useMutate<void, void, void, string, void>("DELETE", `/api/v1/quotes`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export type QuotesAcceptQuoteProps = Omit<
  MutateProps<string, void, void, AcceptQuoteDto, void>,
  "path" | "verb"
>;

export const QuotesAcceptQuote = (props: QuotesAcceptQuoteProps) => (
  <Mutate<string, void, void, AcceptQuoteDto, void>
    verb="POST"
    path={`/api/v1/quotes/accept`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuotesAcceptQuoteProps = Omit<
  UseMutateProps<string, void, void, AcceptQuoteDto, void>,
  "path" | "verb"
>;

export const useQuotesAcceptQuote = (props: UseQuotesAcceptQuoteProps) =>
  useMutate<string, void, void, AcceptQuoteDto, void>(
    "POST",
    `/api/v1/quotes/accept`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export interface QuoteVendorsGetVendorsQueryParams {
  Code?: string | null;
  EstimateId?: string | null;
  FolderId?: string | null;
}

export type QuoteVendorsGetVendorsProps = Omit<
  GetProps<
    QuoteVendorSummaryDto[],
    void,
    QuoteVendorsGetVendorsQueryParams,
    void
  >,
  "path"
>;

export const QuoteVendorsGetVendors = (props: QuoteVendorsGetVendorsProps) => (
  <Get<QuoteVendorSummaryDto[], void, QuoteVendorsGetVendorsQueryParams, void>
    path={`/api/v1/quoteVendors`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuoteVendorsGetVendorsProps = Omit<
  UseGetProps<
    QuoteVendorSummaryDto[],
    void,
    QuoteVendorsGetVendorsQueryParams,
    void
  >,
  "path"
>;

export const useQuoteVendorsGetVendors = (
  props: UseQuoteVendorsGetVendorsProps
) =>
  useGet<
    QuoteVendorSummaryDto[],
    void,
    QuoteVendorsGetVendorsQueryParams,
    void
  >(`/api/v1/quoteVendors`, { base: config.endpoints.HBINSIGHTS, ...props });

export type QuoteVendorsCreateVendorProps = Omit<
  MutateProps<void, void, void, void, void>,
  "path" | "verb"
>;

export const QuoteVendorsCreateVendor = (
  props: QuoteVendorsCreateVendorProps
) => (
  <Mutate<void, void, void, void, void>
    verb="POST"
    path={`/api/v1/quoteVendors`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuoteVendorsCreateVendorProps = Omit<
  UseMutateProps<void, void, void, void, void>,
  "path" | "verb"
>;

export const useQuoteVendorsCreateVendor = (
  props: UseQuoteVendorsCreateVendorProps
) =>
  useMutate<void, void, void, void, void>("POST", `/api/v1/quoteVendors`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export type QuoteVendorsUpdateVendorProps = Omit<
  MutateProps<void, void, void, void, void>,
  "path" | "verb"
>;

export const QuoteVendorsUpdateVendor = (
  props: QuoteVendorsUpdateVendorProps
) => (
  <Mutate<void, void, void, void, void>
    verb="PUT"
    path={`/api/v1/quoteVendors`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuoteVendorsUpdateVendorProps = Omit<
  UseMutateProps<void, void, void, void, void>,
  "path" | "verb"
>;

export const useQuoteVendorsUpdateVendor = (
  props: UseQuoteVendorsUpdateVendorProps
) =>
  useMutate<void, void, void, void, void>("PUT", `/api/v1/quoteVendors`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export type QuoteVendorsDeleteVendorProps = Omit<
  MutateProps<void, void, void, void, void>,
  "path" | "verb"
>;

export const QuoteVendorsDeleteVendor = (
  props: QuoteVendorsDeleteVendorProps
) => (
  <Mutate<void, void, void, void, void>
    verb="DELETE"
    path={`/api/v1/quoteVendors`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuoteVendorsDeleteVendorProps = Omit<
  UseMutateProps<void, void, void, void, void>,
  "path" | "verb"
>;

export const useQuoteVendorsDeleteVendor = (
  props: UseQuoteVendorsDeleteVendorProps
) =>
  useMutate<void, void, void, void, void>("DELETE", `/api/v1/quoteVendors`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface QuoteVendorsGetVendorPathParams {
  id: string;
}

export type QuoteVendorsGetVendorProps = Omit<
  GetProps<QuoteVendorDto, void, void, QuoteVendorsGetVendorPathParams>,
  "path"
> &
  QuoteVendorsGetVendorPathParams;

export const QuoteVendorsGetVendor = ({
  id,
  ...props
}: QuoteVendorsGetVendorProps) => (
  <Get<QuoteVendorDto, void, void, QuoteVendorsGetVendorPathParams>
    path={`/api/v1/quoteVendors/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseQuoteVendorsGetVendorProps = Omit<
  UseGetProps<QuoteVendorDto, void, void, QuoteVendorsGetVendorPathParams>,
  "path"
> &
  QuoteVendorsGetVendorPathParams;

export const useQuoteVendorsGetVendor = ({
  id,
  ...props
}: UseQuoteVendorsGetVendorProps) =>
  useGet<QuoteVendorDto, void, void, QuoteVendorsGetVendorPathParams>(
    (paramsInPath: QuoteVendorsGetVendorPathParams) =>
      `/api/v1/quoteVendors/${paramsInPath.id}`,
    { base: config.endpoints.HBINSIGHTS, pathParams: { id }, ...props }
  );

export interface StorageReportsGetReportQueryParams {
  estimateId?: string;
  reportType?: string | null;
}

export type StorageReportsGetReportProps = Omit<
  GetProps<string, void, StorageReportsGetReportQueryParams, void>,
  "path"
>;

export const StorageReportsGetReport = (
  props: StorageReportsGetReportProps
) => (
  <Get<string, void, StorageReportsGetReportQueryParams, void>
    path={`/api/v1/storage/reports`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseStorageReportsGetReportProps = Omit<
  UseGetProps<string, void, StorageReportsGetReportQueryParams, void>,
  "path"
>;

export const useStorageReportsGetReport = (
  props: UseStorageReportsGetReportProps
) =>
  useGet<string, void, StorageReportsGetReportQueryParams, void>(
    `/api/v1/storage/reports`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export type UploadPostMasterRecordProps = Omit<
  MutateProps<string, void, void, EstimateViewModel, void>,
  "path" | "verb"
>;

export const UploadPostMasterRecord = (props: UploadPostMasterRecordProps) => (
  <Mutate<string, void, void, EstimateViewModel, void>
    verb="POST"
    path={`/api/v1/upload/master`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseUploadPostMasterRecordProps = Omit<
  UseMutateProps<string, void, void, EstimateViewModel, void>,
  "path" | "verb"
>;

export const useUploadPostMasterRecord = (
  props: UseUploadPostMasterRecordProps
) =>
  useMutate<string, void, void, EstimateViewModel, void>(
    "POST",
    `/api/v1/upload/master`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export interface UploadPostEstimateQueryParams {
  addToProcessingQueue?: boolean;
}

export type UploadPostEstimateProps = Omit<
  MutateProps<UploadViewModel, void, UploadPostEstimateQueryParams, void, void>,
  "path" | "verb"
>;

/**
 * Endpoing for uploading an estimate backup
 *
 * Must use form-data as the content-type, and provide a single zip file in the body (the key doesn't matter).
 * 200 mb max upload size set by attributes.
 */
export const UploadPostEstimate = (props: UploadPostEstimateProps) => (
  <Mutate<UploadViewModel, void, UploadPostEstimateQueryParams, void, void>
    verb="POST"
    path={`/api/v1/upload`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseUploadPostEstimateProps = Omit<
  UseMutateProps<
    UploadViewModel,
    void,
    UploadPostEstimateQueryParams,
    void,
    void
  >,
  "path" | "verb"
>;

/**
 * Endpoing for uploading an estimate backup
 *
 * Must use form-data as the content-type, and provide a single zip file in the body (the key doesn't matter).
 * 200 mb max upload size set by attributes.
 */
export const useUploadPostEstimate = (props: UseUploadPostEstimateProps) =>
  useMutate<UploadViewModel, void, UploadPostEstimateQueryParams, void, void>(
    "POST",
    `/api/v1/upload`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export type UserGetProps = Omit<GetProps<string, void, void, void>, "path">;

export const UserGet = (props: UserGetProps) => (
  <Get<string, void, void, void>
    path={`/api/v1/user`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseUserGetProps = Omit<
  UseGetProps<string, void, void, void>,
  "path"
>;

export const useUserGet = (props: UseUserGetProps) =>
  useGet<string, void, void, void>(`/api/v1/user`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface BidHistoryV2GetBidHistoryPricesQueryParams {
  requestType?: number;
  beginDate?: string | null;
  endDate?: string | null;
  includeActiveEstimates?: boolean;
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export type BidHistoryV2GetBidHistoryPricesProps = Omit<
  MutateProps<
    HistoricalBidPrice[],
    void,
    BidHistoryV2GetBidHistoryPricesQueryParams,
    BidInfo[],
    void
  >,
  "path" | "verb"
>;

export const BidHistoryV2GetBidHistoryPrices = (
  props: BidHistoryV2GetBidHistoryPricesProps
) => (
  <Mutate<
    HistoricalBidPrice[],
    void,
    BidHistoryV2GetBidHistoryPricesQueryParams,
    BidInfo[],
    void
  >
    verb="POST"
    path={`/api/v2/bidhistory/prices`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseBidHistoryV2GetBidHistoryPricesProps = Omit<
  UseMutateProps<
    HistoricalBidPrice[],
    void,
    BidHistoryV2GetBidHistoryPricesQueryParams,
    BidInfo[],
    void
  >,
  "path" | "verb"
>;

export const useBidHistoryV2GetBidHistoryPrices = (
  props: UseBidHistoryV2GetBidHistoryPricesProps
) =>
  useMutate<
    HistoricalBidPrice[],
    void,
    BidHistoryV2GetBidHistoryPricesQueryParams,
    BidInfo[],
    void
  >("POST", `/api/v2/bidhistory/prices`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface CostHistoryV2GetCostHistoryActivitiesQueryParams {
  SearchTerms?: string | null;
  FilterDates?: boolean;
  BeginDate?: string;
  EndDate?: string;
  QuanLow?: number;
  QuanHigh?: number;
  MaxRecords?: number;
  includeActiveEstimates?: boolean;
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export type CostHistoryV2GetCostHistoryActivitiesProps = Omit<
  GetProps<
    CostHistoryActivity[],
    void,
    CostHistoryV2GetCostHistoryActivitiesQueryParams,
    void
  >,
  "path"
>;

export const CostHistoryV2GetCostHistoryActivities = (
  props: CostHistoryV2GetCostHistoryActivitiesProps
) => (
  <Get<
    CostHistoryActivity[],
    void,
    CostHistoryV2GetCostHistoryActivitiesQueryParams,
    void
  >
    path={`/api/v2/costhistory/activities`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseCostHistoryV2GetCostHistoryActivitiesProps = Omit<
  UseGetProps<
    CostHistoryActivity[],
    void,
    CostHistoryV2GetCostHistoryActivitiesQueryParams,
    void
  >,
  "path"
>;

export const useCostHistoryV2GetCostHistoryActivities = (
  props: UseCostHistoryV2GetCostHistoryActivitiesProps
) =>
  useGet<
    CostHistoryActivity[],
    void,
    CostHistoryV2GetCostHistoryActivitiesQueryParams,
    void
  >(`/api/v2/costhistory/activities`, {
    base: config.endpoints.HBINSIGHTS,
    ...props
  });

export interface IntegrationsV2GetAllActivityQueryParams {
  /**
   * Whether the pull biditems that have been marked as "excluded" and are not used in calculations.
   */
  includeExcludedEstimates?: boolean;
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export interface IntegrationsV2GetAllActivityPathParams {
  /**
   * The required business unit id.
   */
  businessUnitId: string;
}

export type IntegrationsV2GetAllActivityProps = Omit<
  GetProps<
    IntegrationResponseOfActivityIntegrationDto,
    void,
    IntegrationsV2GetAllActivityQueryParams,
    IntegrationsV2GetAllActivityPathParams
  >,
  "path"
> &
  IntegrationsV2GetAllActivityPathParams;

/**
 * Retrieve all activities for a business unit.
 */
export const IntegrationsV2GetAllActivity = ({
  businessUnitId,
  ...props
}: IntegrationsV2GetAllActivityProps) => (
  <Get<
    IntegrationResponseOfActivityIntegrationDto,
    void,
    IntegrationsV2GetAllActivityQueryParams,
    IntegrationsV2GetAllActivityPathParams
  >
    path={`/api/v2/integration/businessunits/${businessUnitId}/activities`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsV2GetAllActivityProps = Omit<
  UseGetProps<
    IntegrationResponseOfActivityIntegrationDto,
    void,
    IntegrationsV2GetAllActivityQueryParams,
    IntegrationsV2GetAllActivityPathParams
  >,
  "path"
> &
  IntegrationsV2GetAllActivityPathParams;

/**
 * Retrieve all activities for a business unit.
 */
export const useIntegrationsV2GetAllActivity = ({
  businessUnitId,
  ...props
}: UseIntegrationsV2GetAllActivityProps) =>
  useGet<
    IntegrationResponseOfActivityIntegrationDto,
    void,
    IntegrationsV2GetAllActivityQueryParams,
    IntegrationsV2GetAllActivityPathParams
  >(
    (paramsInPath: IntegrationsV2GetAllActivityPathParams) =>
      `/api/v2/integration/businessunits/${paramsInPath.businessUnitId}/activities`,
    {
      base: config.endpoints.HBINSIGHTS,
      pathParams: { businessUnitId },
      ...props
    }
  );

export interface IntegrationsV2GetActivityPathParams {
  /**
   * The required business unit id.
   */
  businessUnitId: string;
  /**
   * The required activity id.
   */
  id: string;
}

export type IntegrationsV2GetActivityProps = Omit<
  GetProps<
    IntegrationResponseSingleOfActivityIntegrationDto,
    void,
    void,
    IntegrationsV2GetActivityPathParams
  >,
  "path"
> &
  IntegrationsV2GetActivityPathParams;

/**
 * Retrieves a specific activity by its id.
 */
export const IntegrationsV2GetActivity = ({
  businessUnitId,
  id,
  ...props
}: IntegrationsV2GetActivityProps) => (
  <Get<
    IntegrationResponseSingleOfActivityIntegrationDto,
    void,
    void,
    IntegrationsV2GetActivityPathParams
  >
    path={`/api/v2/integration/businessunits/${businessUnitId}/activities/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsV2GetActivityProps = Omit<
  UseGetProps<
    IntegrationResponseSingleOfActivityIntegrationDto,
    void,
    void,
    IntegrationsV2GetActivityPathParams
  >,
  "path"
> &
  IntegrationsV2GetActivityPathParams;

/**
 * Retrieves a specific activity by its id.
 */
export const useIntegrationsV2GetActivity = ({
  businessUnitId,
  id,
  ...props
}: UseIntegrationsV2GetActivityProps) =>
  useGet<
    IntegrationResponseSingleOfActivityIntegrationDto,
    void,
    void,
    IntegrationsV2GetActivityPathParams
  >(
    (paramsInPath: IntegrationsV2GetActivityPathParams) =>
      `/api/v2/integration/businessunits/${paramsInPath.businessUnitId}/activities/${paramsInPath.id}`,
    {
      base: config.endpoints.HBINSIGHTS,
      pathParams: { businessUnitId, id },
      ...props
    }
  );

export interface IntegrationsV2GetAllBidItemsQueryParams {
  /**
   * Whether to pull biditems that have been marked as "excluded" and are not used in calculations.
   */
  includeExcludedEstimates?: boolean;
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export interface IntegrationsV2GetAllBidItemsPathParams {
  /**
   * The required business unit id.
   */
  businessUnitId: string;
}

export type IntegrationsV2GetAllBidItemsProps = Omit<
  GetProps<
    IntegrationResponseOfBiditemIntegrationDto,
    void,
    IntegrationsV2GetAllBidItemsQueryParams,
    IntegrationsV2GetAllBidItemsPathParams
  >,
  "path"
> &
  IntegrationsV2GetAllBidItemsPathParams;

/**
 * Retrieves all bid items for a business unit.
 */
export const IntegrationsV2GetAllBidItems = ({
  businessUnitId,
  ...props
}: IntegrationsV2GetAllBidItemsProps) => (
  <Get<
    IntegrationResponseOfBiditemIntegrationDto,
    void,
    IntegrationsV2GetAllBidItemsQueryParams,
    IntegrationsV2GetAllBidItemsPathParams
  >
    path={`/api/v2/integration/businessunits/${businessUnitId}/biditems`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsV2GetAllBidItemsProps = Omit<
  UseGetProps<
    IntegrationResponseOfBiditemIntegrationDto,
    void,
    IntegrationsV2GetAllBidItemsQueryParams,
    IntegrationsV2GetAllBidItemsPathParams
  >,
  "path"
> &
  IntegrationsV2GetAllBidItemsPathParams;

/**
 * Retrieves all bid items for a business unit.
 */
export const useIntegrationsV2GetAllBidItems = ({
  businessUnitId,
  ...props
}: UseIntegrationsV2GetAllBidItemsProps) =>
  useGet<
    IntegrationResponseOfBiditemIntegrationDto,
    void,
    IntegrationsV2GetAllBidItemsQueryParams,
    IntegrationsV2GetAllBidItemsPathParams
  >(
    (paramsInPath: IntegrationsV2GetAllBidItemsPathParams) =>
      `/api/v2/integration/businessunits/${paramsInPath.businessUnitId}/biditems`,
    {
      base: config.endpoints.HBINSIGHTS,
      pathParams: { businessUnitId },
      ...props
    }
  );

export interface IntegrationsV2GetBidItemPathParams {
  /**
   * The required business unit id.
   */
  businessUnitId: string;
  /**
   * The required biditem id.
   */
  id: string;
}

export type IntegrationsV2GetBidItemProps = Omit<
  GetProps<
    IntegrationResponseSingleOfBiditemIntegrationDto,
    void,
    void,
    IntegrationsV2GetBidItemPathParams
  >,
  "path"
> &
  IntegrationsV2GetBidItemPathParams;

/**
 * Retrieves a specific biditem by its id.
 */
export const IntegrationsV2GetBidItem = ({
  businessUnitId,
  id,
  ...props
}: IntegrationsV2GetBidItemProps) => (
  <Get<
    IntegrationResponseSingleOfBiditemIntegrationDto,
    void,
    void,
    IntegrationsV2GetBidItemPathParams
  >
    path={`/api/v2/integration/businessunits/${businessUnitId}/biditems/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsV2GetBidItemProps = Omit<
  UseGetProps<
    IntegrationResponseSingleOfBiditemIntegrationDto,
    void,
    void,
    IntegrationsV2GetBidItemPathParams
  >,
  "path"
> &
  IntegrationsV2GetBidItemPathParams;

/**
 * Retrieves a specific biditem by its id.
 */
export const useIntegrationsV2GetBidItem = ({
  businessUnitId,
  id,
  ...props
}: UseIntegrationsV2GetBidItemProps) =>
  useGet<
    IntegrationResponseSingleOfBiditemIntegrationDto,
    void,
    void,
    IntegrationsV2GetBidItemPathParams
  >(
    (paramsInPath: IntegrationsV2GetBidItemPathParams) =>
      `/api/v2/integration/businessunits/${paramsInPath.businessUnitId}/biditems/${paramsInPath.id}`,
    {
      base: config.endpoints.HBINSIGHTS,
      pathParams: { businessUnitId, id },
      ...props
    }
  );

export type IntegrationsV2GetAllBusinessUnitsProps = Omit<
  GetProps<IntegrationResponseOfApiBusinessUnitRead, void, void, void>,
  "path"
>;

/**
 * Retrieves all business units.
 */
export const IntegrationsV2GetAllBusinessUnits = (
  props: IntegrationsV2GetAllBusinessUnitsProps
) => (
  <Get<IntegrationResponseOfApiBusinessUnitRead, void, void, void>
    path={`/api/v2/integration/businessunits`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsV2GetAllBusinessUnitsProps = Omit<
  UseGetProps<IntegrationResponseOfApiBusinessUnitRead, void, void, void>,
  "path"
>;

/**
 * Retrieves all business units.
 */
export const useIntegrationsV2GetAllBusinessUnits = (
  props: UseIntegrationsV2GetAllBusinessUnitsProps
) =>
  useGet<IntegrationResponseOfApiBusinessUnitRead, void, void, void>(
    `/api/v2/integration/businessunits`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );

export interface IntegrationsV2GetAllEstimatesQueryParams {
  /**
   * Whether to include excluded estimates.
   */
  includeExcludedEstimates?: boolean;
  /**
   * Whether to include deleted estimates.
   */
  includeDeletedEstimates?: boolean;
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export interface IntegrationsV2GetAllEstimatesPathParams {
  /**
   * The required business unit id.
   */
  businessUnitId: string;
}

export type IntegrationsV2GetAllEstimatesProps = Omit<
  GetProps<
    IntegrationResponseOfEstimateIntegrationDto,
    void,
    IntegrationsV2GetAllEstimatesQueryParams,
    IntegrationsV2GetAllEstimatesPathParams
  >,
  "path"
> &
  IntegrationsV2GetAllEstimatesPathParams;

/**
 * Retrieves all estimates for a business unit.
 */
export const IntegrationsV2GetAllEstimates = ({
  businessUnitId,
  ...props
}: IntegrationsV2GetAllEstimatesProps) => (
  <Get<
    IntegrationResponseOfEstimateIntegrationDto,
    void,
    IntegrationsV2GetAllEstimatesQueryParams,
    IntegrationsV2GetAllEstimatesPathParams
  >
    path={`/api/v2/integration/businessunits/${businessUnitId}/estimates`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsV2GetAllEstimatesProps = Omit<
  UseGetProps<
    IntegrationResponseOfEstimateIntegrationDto,
    void,
    IntegrationsV2GetAllEstimatesQueryParams,
    IntegrationsV2GetAllEstimatesPathParams
  >,
  "path"
> &
  IntegrationsV2GetAllEstimatesPathParams;

/**
 * Retrieves all estimates for a business unit.
 */
export const useIntegrationsV2GetAllEstimates = ({
  businessUnitId,
  ...props
}: UseIntegrationsV2GetAllEstimatesProps) =>
  useGet<
    IntegrationResponseOfEstimateIntegrationDto,
    void,
    IntegrationsV2GetAllEstimatesQueryParams,
    IntegrationsV2GetAllEstimatesPathParams
  >(
    (paramsInPath: IntegrationsV2GetAllEstimatesPathParams) =>
      `/api/v2/integration/businessunits/${paramsInPath.businessUnitId}/estimates`,
    {
      base: config.endpoints.HBINSIGHTS,
      pathParams: { businessUnitId },
      ...props
    }
  );

export interface IntegrationsV2GetEstimatePathParams {
  /**
   * The required business unit id.
   */
  businessUnitId: string;
  /**
   * The required id.
   */
  id: string;
}

export type IntegrationsV2GetEstimateProps = Omit<
  GetProps<
    IntegrationResponseSingleOfEstimateIntegrationDto,
    void,
    void,
    IntegrationsV2GetEstimatePathParams
  >,
  "path"
> &
  IntegrationsV2GetEstimatePathParams;

/**
 * Retrieve a specific estimate by its id.
 */
export const IntegrationsV2GetEstimate = ({
  businessUnitId,
  id,
  ...props
}: IntegrationsV2GetEstimateProps) => (
  <Get<
    IntegrationResponseSingleOfEstimateIntegrationDto,
    void,
    void,
    IntegrationsV2GetEstimatePathParams
  >
    path={`/api/v2/integration/businessunits/${businessUnitId}/estimates/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsV2GetEstimateProps = Omit<
  UseGetProps<
    IntegrationResponseSingleOfEstimateIntegrationDto,
    void,
    void,
    IntegrationsV2GetEstimatePathParams
  >,
  "path"
> &
  IntegrationsV2GetEstimatePathParams;

/**
 * Retrieve a specific estimate by its id.
 */
export const useIntegrationsV2GetEstimate = ({
  businessUnitId,
  id,
  ...props
}: UseIntegrationsV2GetEstimateProps) =>
  useGet<
    IntegrationResponseSingleOfEstimateIntegrationDto,
    void,
    void,
    IntegrationsV2GetEstimatePathParams
  >(
    (paramsInPath: IntegrationsV2GetEstimatePathParams) =>
      `/api/v2/integration/businessunits/${paramsInPath.businessUnitId}/estimates/${paramsInPath.id}`,
    {
      base: config.endpoints.HBINSIGHTS,
      pathParams: { businessUnitId, id },
      ...props
    }
  );

export interface IntegrationsV2GetAllCalulatedKPIsQueryParams {
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export interface IntegrationsV2GetAllCalulatedKPIsPathParams {
  /**
   * The required business unit id.
   */
  businessUnitId: string;
  /**
   * The required estimate id.
   */
  id: string;
}

export type IntegrationsV2GetAllCalulatedKPIsProps = Omit<
  GetProps<
    IntegrationResponseOfKPI,
    void,
    IntegrationsV2GetAllCalulatedKPIsQueryParams,
    IntegrationsV2GetAllCalulatedKPIsPathParams
  >,
  "path"
> &
  IntegrationsV2GetAllCalulatedKPIsPathParams;

/**
 * Retrieves key indicators for an estimate
 */
export const IntegrationsV2GetAllCalulatedKPIs = ({
  businessUnitId,
  id,
  ...props
}: IntegrationsV2GetAllCalulatedKPIsProps) => (
  <Get<
    IntegrationResponseOfKPI,
    void,
    IntegrationsV2GetAllCalulatedKPIsQueryParams,
    IntegrationsV2GetAllCalulatedKPIsPathParams
  >
    path={`/api/v2/integration/businessunits/${businessUnitId}/estimates/${id}/kpis`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsV2GetAllCalulatedKPIsProps = Omit<
  UseGetProps<
    IntegrationResponseOfKPI,
    void,
    IntegrationsV2GetAllCalulatedKPIsQueryParams,
    IntegrationsV2GetAllCalulatedKPIsPathParams
  >,
  "path"
> &
  IntegrationsV2GetAllCalulatedKPIsPathParams;

/**
 * Retrieves key indicators for an estimate
 */
export const useIntegrationsV2GetAllCalulatedKPIs = ({
  businessUnitId,
  id,
  ...props
}: UseIntegrationsV2GetAllCalulatedKPIsProps) =>
  useGet<
    IntegrationResponseOfKPI,
    void,
    IntegrationsV2GetAllCalulatedKPIsQueryParams,
    IntegrationsV2GetAllCalulatedKPIsPathParams
  >(
    (paramsInPath: IntegrationsV2GetAllCalulatedKPIsPathParams) =>
      `/api/v2/integration/businessunits/${paramsInPath.businessUnitId}/estimates/${paramsInPath.id}/kpis`,
    {
      base: config.endpoints.HBINSIGHTS,
      pathParams: { businessUnitId, id },
      ...props
    }
  );

export interface IntegrationsV2GetAllDatabasePartitionsPathParams {
  /**
   * The required business unit id.
   */
  businessUnitId: string;
}

export type IntegrationsV2GetAllDatabasePartitionsProps = Omit<
  GetProps<
    IntegrationResponseOfApiDatabasePartitionRead,
    void,
    void,
    IntegrationsV2GetAllDatabasePartitionsPathParams
  >,
  "path"
> &
  IntegrationsV2GetAllDatabasePartitionsPathParams;

/**
 * Retrieves all database partitions for a business unit.
 */
export const IntegrationsV2GetAllDatabasePartitions = ({
  businessUnitId,
  ...props
}: IntegrationsV2GetAllDatabasePartitionsProps) => (
  <Get<
    IntegrationResponseOfApiDatabasePartitionRead,
    void,
    void,
    IntegrationsV2GetAllDatabasePartitionsPathParams
  >
    path={`/api/v2/integration/businessunits/${businessUnitId}/partitions`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsV2GetAllDatabasePartitionsProps = Omit<
  UseGetProps<
    IntegrationResponseOfApiDatabasePartitionRead,
    void,
    void,
    IntegrationsV2GetAllDatabasePartitionsPathParams
  >,
  "path"
> &
  IntegrationsV2GetAllDatabasePartitionsPathParams;

/**
 * Retrieves all database partitions for a business unit.
 */
export const useIntegrationsV2GetAllDatabasePartitions = ({
  businessUnitId,
  ...props
}: UseIntegrationsV2GetAllDatabasePartitionsProps) =>
  useGet<
    IntegrationResponseOfApiDatabasePartitionRead,
    void,
    void,
    IntegrationsV2GetAllDatabasePartitionsPathParams
  >(
    (paramsInPath: IntegrationsV2GetAllDatabasePartitionsPathParams) =>
      `/api/v2/integration/businessunits/${paramsInPath.businessUnitId}/partitions`,
    {
      base: config.endpoints.HBINSIGHTS,
      pathParams: { businessUnitId },
      ...props
    }
  );

export interface IntegrationsV2GetDatabasePartitionByIdPathParams {
  /**
   * The required business unit id.
   */
  businessUnitId: string;
  /**
   * The required id.
   */
  id: string;
}

export type IntegrationsV2GetDatabasePartitionByIdProps = Omit<
  GetProps<
    IntegrationResponseSingleOfApiDatabasePartitionRead,
    void,
    void,
    IntegrationsV2GetDatabasePartitionByIdPathParams
  >,
  "path"
> &
  IntegrationsV2GetDatabasePartitionByIdPathParams;

/**
 * Retrieves a specific database partition by its id.
 */
export const IntegrationsV2GetDatabasePartitionById = ({
  businessUnitId,
  id,
  ...props
}: IntegrationsV2GetDatabasePartitionByIdProps) => (
  <Get<
    IntegrationResponseSingleOfApiDatabasePartitionRead,
    void,
    void,
    IntegrationsV2GetDatabasePartitionByIdPathParams
  >
    path={`/api/v2/integration/businessunits/${businessUnitId}/partitions/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsV2GetDatabasePartitionByIdProps = Omit<
  UseGetProps<
    IntegrationResponseSingleOfApiDatabasePartitionRead,
    void,
    void,
    IntegrationsV2GetDatabasePartitionByIdPathParams
  >,
  "path"
> &
  IntegrationsV2GetDatabasePartitionByIdPathParams;

/**
 * Retrieves a specific database partition by its id.
 */
export const useIntegrationsV2GetDatabasePartitionById = ({
  businessUnitId,
  id,
  ...props
}: UseIntegrationsV2GetDatabasePartitionByIdProps) =>
  useGet<
    IntegrationResponseSingleOfApiDatabasePartitionRead,
    void,
    void,
    IntegrationsV2GetDatabasePartitionByIdPathParams
  >(
    (paramsInPath: IntegrationsV2GetDatabasePartitionByIdPathParams) =>
      `/api/v2/integration/businessunits/${paramsInPath.businessUnitId}/partitions/${paramsInPath.id}`,
    {
      base: config.endpoints.HBINSIGHTS,
      pathParams: { businessUnitId, id },
      ...props
    }
  );

export interface IntegrationsV2GetAllResourcesQueryParams {
  /**
   * Whether the pull biditems that have been marked as "excluded" and are not used in calculations.
   */
  includeExcludedEstimates?: boolean;
  /**
   * The max number of records. (e.g. $top=10)
   */
  $top?: string;
  /**
   * The number of records to skip. (e.g. $skip=5)
   */
  $skip?: string;
  /**
   * A function that must evaluate to true for a record to be returned. (e.g. $filter=estimateId eq c9804e19-acbf-4db2-adaa-04d344dbcc1b)
   */
  $filter?: string;
  /**
   * Determines what values are used to order a collection of records. (e.g. $orderby=filters/typeOfWork desc)
   */
  $orderby?: string;
}

export interface IntegrationsV2GetAllResourcesPathParams {
  /**
   * The required business unit id.
   */
  businessUnitId: string;
}

export type IntegrationsV2GetAllResourcesProps = Omit<
  GetProps<
    IntegrationResponseOfResourceIntegrationDto,
    void,
    IntegrationsV2GetAllResourcesQueryParams,
    IntegrationsV2GetAllResourcesPathParams
  >,
  "path"
> &
  IntegrationsV2GetAllResourcesPathParams;

/**
 * Retrieves all resources for a business unit.
 */
export const IntegrationsV2GetAllResources = ({
  businessUnitId,
  ...props
}: IntegrationsV2GetAllResourcesProps) => (
  <Get<
    IntegrationResponseOfResourceIntegrationDto,
    void,
    IntegrationsV2GetAllResourcesQueryParams,
    IntegrationsV2GetAllResourcesPathParams
  >
    path={`/api/v2/integration/businessunits/${businessUnitId}/resources`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsV2GetAllResourcesProps = Omit<
  UseGetProps<
    IntegrationResponseOfResourceIntegrationDto,
    void,
    IntegrationsV2GetAllResourcesQueryParams,
    IntegrationsV2GetAllResourcesPathParams
  >,
  "path"
> &
  IntegrationsV2GetAllResourcesPathParams;

/**
 * Retrieves all resources for a business unit.
 */
export const useIntegrationsV2GetAllResources = ({
  businessUnitId,
  ...props
}: UseIntegrationsV2GetAllResourcesProps) =>
  useGet<
    IntegrationResponseOfResourceIntegrationDto,
    void,
    IntegrationsV2GetAllResourcesQueryParams,
    IntegrationsV2GetAllResourcesPathParams
  >(
    (paramsInPath: IntegrationsV2GetAllResourcesPathParams) =>
      `/api/v2/integration/businessunits/${paramsInPath.businessUnitId}/resources`,
    {
      base: config.endpoints.HBINSIGHTS,
      pathParams: { businessUnitId },
      ...props
    }
  );

export interface IntegrationsV2GetResourceByIdPathParams {
  /**
   * The required business unit id.
   */
  businessUnitId: string;
  /**
   * The required resource id.
   */
  id: string;
}

export type IntegrationsV2GetResourceByIdProps = Omit<
  GetProps<
    IntegrationResponseSingleOfResourceIntegrationDto,
    void,
    void,
    IntegrationsV2GetResourceByIdPathParams
  >,
  "path"
> &
  IntegrationsV2GetResourceByIdPathParams;

/**
 * Retrieves a specific resource by its id.
 */
export const IntegrationsV2GetResourceById = ({
  businessUnitId,
  id,
  ...props
}: IntegrationsV2GetResourceByIdProps) => (
  <Get<
    IntegrationResponseSingleOfResourceIntegrationDto,
    void,
    void,
    IntegrationsV2GetResourceByIdPathParams
  >
    path={`/api/v2/integration/businessunits/${businessUnitId}/resources/${id}`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseIntegrationsV2GetResourceByIdProps = Omit<
  UseGetProps<
    IntegrationResponseSingleOfResourceIntegrationDto,
    void,
    void,
    IntegrationsV2GetResourceByIdPathParams
  >,
  "path"
> &
  IntegrationsV2GetResourceByIdPathParams;

/**
 * Retrieves a specific resource by its id.
 */
export const useIntegrationsV2GetResourceById = ({
  businessUnitId,
  id,
  ...props
}: UseIntegrationsV2GetResourceByIdProps) =>
  useGet<
    IntegrationResponseSingleOfResourceIntegrationDto,
    void,
    void,
    IntegrationsV2GetResourceByIdPathParams
  >(
    (paramsInPath: IntegrationsV2GetResourceByIdPathParams) =>
      `/api/v2/integration/businessunits/${paramsInPath.businessUnitId}/resources/${paramsInPath.id}`,
    {
      base: config.endpoints.HBINSIGHTS,
      pathParams: { businessUnitId, id },
      ...props
    }
  );

export type UploadV2PostMasterRecordProps = Omit<
  MutateProps<string, void, void, EstimateViewModel, void>,
  "path" | "verb"
>;

export const UploadV2PostMasterRecord = (
  props: UploadV2PostMasterRecordProps
) => (
  <Mutate<string, void, void, EstimateViewModel, void>
    verb="POST"
    path={`/api/v2/upload/master`}
    base={config.endpoints.HBINSIGHTS}
    {...props}
  />
);

export type UseUploadV2PostMasterRecordProps = Omit<
  UseMutateProps<string, void, void, EstimateViewModel, void>,
  "path" | "verb"
>;

export const useUploadV2PostMasterRecord = (
  props: UseUploadV2PostMasterRecordProps
) =>
  useMutate<string, void, void, EstimateViewModel, void>(
    "POST",
    `/api/v2/upload/master`,
    { base: config.endpoints.HBINSIGHTS, ...props }
  );
