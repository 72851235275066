import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { strings } from "localization";
import {
  Button,
  Card,
  Grid as MuiGrid,
  Popover,
  Typography,
  styled
} from "@mui/material";
import moment from "moment";
import { filterConstants } from "modules/schemas/components/filters/common";
import {
  ConcreteColors,
  OverlayTrigger,
  Select,
  Tooltip
} from "hcss-components";
import { DateTimePicker } from "core";
import { actions } from "../state";
import { useCodebooksContext } from "../context/CodebooksContext";
import {
  CodebooksDateRange,
  GetDateValues
} from "../hooks/use-codebooks-daterange";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { createGlobalStyle } from "styled-components";

interface DateRangeDisplayProps {
  settings: CodebooksDateRange;
  onChange: (settings: CodebooksDateRange) => void;
  showOverflowIcon: boolean;
}
export interface DateRangePickerProps {
  settings: CodebooksDateRange;
  onChange: (settings: CodebooksDateRange) => void;
}

const rangeOptions: Array<{ label: string; value: string }> = [
  {
    label: strings.estimates.codebooks.datePicker.last3Months,
    value: filterConstants.dates.nowM3month
  },
  {
    label: strings.estimates.codebooks.datePicker.last6Months,
    value: filterConstants.dates.nowM6month
  },
  {
    label: strings.estimates.codebooks.datePicker.lastYear,
    value: filterConstants.dates.nowM365
  },
  {
    label: strings.estimates.codebooks.datePicker.custom,
    value: filterConstants.dates.custom
  }
];

export const CodebooksDateRangeDisplay = ({
  settings,
  onChange,
  showOverflowIcon
}: DateRangeDisplayProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [dateTouched, setDateTouched] = useState<boolean>(false);
  const { currentDivisionId, selectedUtilizedCode } = useCodebooksContext();

  const dispatch = useDispatch();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setShowPopover(!showPopover);
  };

  const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(null);
    setShowPopover(false);
    if (dateTouched) {
      dispatch(actions.loadActivityCodebookMissing.request());
      dispatch(actions.loadActivityCodebookUtilized.request());
      if (currentDivisionId && selectedUtilizedCode !== "") {
        dispatch(
          actions.loadActivityCodebookUtilizedDetails.request({
            partitionId: currentDivisionId,
            activityCode: selectedUtilizedCode
          })
        );
      }
      dispatch(actions.loadActivityCodebookUnused.request());
    }
    setDateTouched(false);
  };

  const handleChange = (value: CodebooksDateRange) => {
    setDateTouched(true);
    onChange(value);
  };

  const dateValues = GetDateValues(settings);

  return (
    <div>
      <MuiGrid
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <MuiGrid item>
          <DropdownLabel>
            {strings.estimates.codebooks.datePicker.title}
          </DropdownLabel>
        </MuiGrid>
        <MuiGrid item>
          <DateRangeDisplayButtonContainer>
            <Button
              onClick={handleClick}
              data-testid="codebooks-daterange-button"
            >
              <Typography>
                {moment(dateValues.from).format("L") ?? ""}
                {" - "}
                {moment(dateValues.to).format("L") ?? ""}
              </Typography>
            </Button>
          </DateRangeDisplayButtonContainer>
        </MuiGrid>
        {showOverflowIcon && (
          <MuiGrid item>
            <OverflowIconContainer>
              <OverflowErrorIcon />
            </OverflowIconContainer>
          </MuiGrid>
        )}
      </MuiGrid>
      <DateRangePickerPopover
        id={"codebooks-date-range-picker"}
        open={showPopover}
        anchorEl={anchorEl}
        onClose={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          handleClose(e)
        }
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        disableRestoreFocus
      >
        <DateRangePicker settings={settings} onChange={handleChange} />
      </DateRangePickerPopover>
    </div>
  );
};

const DateRangePicker = ({ settings, onChange }: DateRangePickerProps) => {
  const options = rangeOptions;

  const setSelectedRangeOption = (value?: string) => {
    if (value && value in filterConstants.dates) {
      onChange({ ...settings, range: value });
    } else {
      onChange({ ...settings, range: filterConstants.dates.nowM6month });
    }
  };

  const includeTime = false;

  const updateLowerValue = (value: Date | undefined) => {
    onChange({
      ...settings,
      range: filterConstants.dates.custom,
      from: value?.toISOString()
    });
  };

  const updateUpperValue = (value: Date | undefined) => {
    onChange({
      ...settings,
      range: filterConstants.dates.custom,
      to: value?.toISOString()
    });
  };

  const values = GetDateValues(settings);

  return (
    <DateRangePickerContainer>
      <RangeOptionsContainer>
        <Select
          placeholder={strings.estimates.codebooks.datePicker.title}
          options={options}
          value={options.find(o => o.value === settings.range)}
          onChange={opt => {
            const selectedOption = opt as {
              label: string;
              value: string;
            } | null;
            setSelectedRangeOption(selectedOption?.value ?? undefined);
          }}
          inputId="codebooks-datepicker-dropdown"
        />
      </RangeOptionsContainer>
      <DateTimeContainer>
        <DateTimePickerContainer>
          <DateTimePicker
            time={includeTime}
            value={values.from}
            onChange={updateLowerValue}
            inputProps={{
              "data-testid": "codebooks-datepicker-from"
            }}
          />
        </DateTimePickerContainer>
        <span>-</span>
        <DateTimePickerContainer>
          <DateTimePicker
            time={includeTime}
            value={values.to}
            onChange={updateUpperValue}
            inputProps={{
              "data-testid": "codebooks-datepicker-to"
            }}
          />
        </DateTimePickerContainer>
      </DateTimeContainer>
    </DateRangePickerContainer>
  );
};

const OverflowErrorIcon = () => {
  const tooltip = (
    <Tooltip
      style={{ position: "absolute", zIndex: 10000 }}
      id="overflow_tooltip"
    >
      <TooltipStyling />
      {strings.estimates.codebooks.reportOverflow}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="right" overlay={tooltip}>
      <WarningAmberOutlinedIcon color="warning" />
    </OverlayTrigger>
  );
};

const DateRangeDisplayButtonContainer = styled(Card)(
  ({ theme: { palette } }) => ({
    backgroundColor: palette.background.paper
  })
);

const DateRangePickerPopover = styled(Popover)(() => ({
  "& .MuiPopover-paper": {
    overflow: "initial",
    borderRadius: "12px"
  }
}));

const DateRangePickerContainer = styled("div")`
  padding: 1rem 1.5rem;
  margin: 0.7rem;
  background-color: ${ConcreteColors.blue100};
  border-radius: 12px;
`;

const RangeOptionsContainer = styled("div")`
  margin: 8px 4px;
`;

const DateTimeContainer = styled("div")`
  display: flex;
  align-items: center;
`;

const DateTimePickerContainer = styled("div")`
  width: 150px;
  margin: 4px;
`;
const DropdownLabel = styled("div")`
  font-size: 14px;
  font-weight: 700;
  margin-right: 1rem;
`;
const OverflowIconContainer = styled("div")`
  margin-left: 1rem;
  display: flex;
  align-items: center;
`;
const TooltipStyling = createGlobalStyle`
  .tooltip {
    background-color: ${ConcreteColors.gray600};
    margin: 0;
    border-radius: 7px;
    pointer-events:none;
    left:100;
    margin-left:50px;
    .tooltip-arrow {
      display: none;
    }
    
    .tooltip-inner {
      background-color: transparent;
      font-weight: 500;
      font-size: 13px;
    }
  }
`;
