import * as React from "react";
import { Creatable } from "hcss-components";
import { FilterControlProps } from "./common";
import { strings } from "localization";
import styled from "styled-components";

const components = {
  DropdownIndicator: null
};

const createOption = (label: string) => ({
  label,
  value: label
});

export interface CreatableSelectProps {
  onChange: any;
  value: any;
  placeholder?: string;
}

export const CreatableSelect: React.FunctionComponent<CreatableSelectProps> = ({
  onChange,
  value,
  placeholder = strings.core.filters.textPlaceholder
}) => {
  const [inputValue, setInputValue] = React.useState("");
  // const [value, setValue] = React.useState<
  //   Array<{ label: string; value: string }>
  // >([]);

  const handleKeyDown = (event: any) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        handleChange([...value, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };

  const handleChange = (value: any) => {
    onChange(value);
  };

  return (
    <div data-testid="text-filter">
      <Creatable
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={(newValue: any) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={value}
      />
    </div>
  );
};

export const TextFilter: React.FunctionComponent<FilterControlProps> = ({
  filter,
  onChange
}) => {
  const value = filter.value && Array.isArray(filter.value) ? filter.value : [];
  return (
    <SelectContainer>
      <CreatableSelect
        placeholder={filter.config?.placeholder}
        value={value}
        onChange={(value: any) => {
          onChange({
            ...filter,
            value: value && value.length ? value : undefined
          });
        }}
      />
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  width: 400px;
  max-width: 800px;
  flex-grow: 1;
  word-wrap: break-word;
`;
