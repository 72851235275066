import { List, ListItem, Box } from "@mui/material";
import React from "react";
import { NoDataPlaceholder } from "core";
import { Flex } from "hcss-core";
import { CompanyData } from "./modal";
import { CompanyCard } from "./company-card";
import { strings } from "localization";
export interface TempContactDto {
  id?: string;
  companyId?: string;
  companyCode?: string;
  companyName?: string;
  firstName?: string | undefined;
  lastName?: string | undefined;
  title?: string | undefined;
  phoneNumber?: string | undefined;
  cellPhoneNumber?: string | undefined;
  emailAddress?: string | undefined;
}

export interface CompanyListProps {
  companies: CompanyData[];
}

export const CompanyList = ({ companies }: CompanyListProps) => {
  return (
    <List
      sx={{
        width: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center"
      }}
    >
      <>
        {companies.length === 0 && (
          <NoDataPlaceholder
            isAddEditDisplay={true}
            buttonText={""}
            showButton={false}
            helpText={{
              title: strings.contactManagement.contactSearchModal.noData.title,
              body: strings.contactManagement.contactSearchModal.noData.body
            }}
          />
        )}
        {companies.map(company => (
          <ListItem disablePadding key={company.id}>
            <CompanyItem company={company} />
          </ListItem>
        ))}
      </>
    </List>
  );
};

export interface ContactListItemProps {
  company: CompanyData;
}

const CompanyItem = ({ company }: ContactListItemProps) => {
  return (
    <Flex width="100%">
      <Flex
        flexDirection="row"
        borderRadius="4"
        padding="4"
        width="100%"
        justifyContent="space-between"
        borderBottom="1px solid lightgray"
      >
        <CompanyCard
          companyId={company.id}
          companyName={company.companyName}
          companyCode={company.companyCode}
        />
      </Flex>
    </Flex>
  );
};
