import React from "react";
import { useDispatch } from "react-redux";
import { Modal, Button } from "hcss-components";
import { actions as contactsActions } from "modules/contacts";
import MuiGrid from "@mui/material/Grid";
import { TimesButton } from "./contact-list-modal";
import { IContactDto } from "api/GeneratedClients/ContactsClient";
import { Typography } from "@mui/material";
import { strings } from "localization";

interface SyncContactModalProps {
  contactToSync: IContactDto;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export const SyncContactModal = ({
  contactToSync,
  showModal,
  setShowModal
}: SyncContactModalProps) => {
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSync = () => {
    dispatch(contactsActions.syncContactToProjects.request(contactToSync));
    setShowModal(false);
  };
  return (
    <Modal show={showModal} onHide={handleCloseModal} enforceFocus={false}>
      <Modal.Header style={{ paddingBottom: "0" }}>
        <MuiGrid container justifyContent="space-between">
          <MuiGrid item>
            <h4>
              {
                strings.formatString(
                  strings.contactManagement.contactListSyncContactModal.title,
                  contactToSync?.firstName ?? "",
                  contactToSync?.lastName ?? ""
                ) as string
              }
            </h4>
          </MuiGrid>
          <MuiGrid item>
            <TimesButton
              type="button"
              className="close"
              onClick={handleCloseModal}
            >
              <span aria-hidden="true">×</span>
            </TimesButton>
          </MuiGrid>
        </MuiGrid>
      </Modal.Header>
      <Modal.Body>
        <Typography>
          {strings.contactManagement.contactListSyncContactModal.message}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            gap: "1.5rem"
          }}
        >
          <Button hcssStyle="ThemeInverted" onClick={handleCloseModal}>
            {
              strings.contactManagement.contactListSyncContactModal.button
                .cancel
            }
          </Button>
          <Button onClick={handleSync}>
            {strings.contactManagement.contactListSyncContactModal.button.sync}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
