import { Select } from "hcss-components";
import * as React from "react";
import { SchemaFieldType } from "../../../../api";
import { statesObjectList } from "../../../../core/util/states";
import { FilterControlProps } from "./common";
import { intersection, sortBy } from "lodash-es";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectors as contactsSelector } from "modules/contacts";

export const ListFilter: React.FunctionComponent<FilterControlProps> = ({
  field,
  filter,
  onChange
}) => {
  const vendorList = useSelector(contactsSelector.getVendorListSortedByCode);
  const vendorDataOptions = useSelector(
    contactsSelector.getVendorListSortedByCode
  );
  let options: any[] = [];
  if (field.type === SchemaFieldType.States) {
    options = statesObjectList;
  } else if (field.type === SchemaFieldType.Company) {
    const allOptions = vendorList.map(vendor => {
      return { value: vendor.code, display: vendor.code, type: vendor.type };
    });
    if (field.config.filterEnabled && field.config.filters.length > 0) {
      options = allOptions.filter(op => {
        return field.config.filters.includes(op.type?.code);
      });
    } else {
      options = allOptions;
    }
  } else if (field.type === SchemaFieldType.Contact) {
    options = [];
    vendorDataOptions.forEach(vendor => {
      const contacts = vendor.contacts.map(contact => {
        return {
          display: `${contact.firstName} ${contact.lastName} [${vendor.name}]`,
          value: contact.id,
          products: contact.products
        };
      });
      options = options.concat(contacts);
    });
    if (field.config.filterEnabled && field.config.filters.length > 0) {
      options = options.filter(op => {
        if (!op.products || op.products.length === 0) return false;
        return (
          intersection(
            field.config.filters,
            op.products.map((p: any) => p.productTypeId)
          ).length > 0
        );
      });
    }
  } else {
    options = field.config.listValues;
    if (!field.config.customSort) {
      options = sortBy(options, opt => opt.display.toLocaleLowerCase());
    }
  }

  return (
    <SelectContainer>
      <Select
        isMulti
        value={filter.value || []}
        onChange={(value: any) => {
          onChange({
            ...filter,
            value: value && value.length ? value : undefined
          });
        }}
        getOptionLabel={(option: any) => option.display}
        options={options}
      />
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  width: 400px;
  max-width: 500px;
  flex-grow: 1;
`;
