import axios, { AxiosInstance } from "axios";
import { envService } from "./env-service";
import { BrowserFingerPrint } from "../components/confirm/confirm-panel";
import { SharedResourceType } from "core";

class AccountService {
  instance: AxiosInstance;
  baseUrl: string;

  constructor(baseUrl: string) {
    this.instance = axios.create();
    this.baseUrl = baseUrl;
  }

  confirmInvitation(id: string, email: string, token: string) {
    return this.instance.post<string>(
      `${this.baseUrl}/api/account/confirm/${id}`,
      {
        email,
        token
      }
    );
  }

  declineInvitation(id: string, token: string) {
    return this.instance.post<void>(
      `${this.baseUrl}/api/account/decline/${id}`,
      {
        token
      }
    );
  }

  logEulaAcceptance(fingerPrint: BrowserFingerPrint, email: string) {
    return axios.post<void>(`${this.baseUrl}/api/account/eula`, {
      email,
      fingerPrint
    });
  }

  startEmailVerification(email: string, accessToken: string) {
    const data = { email };
    const config = { headers: { Authorization: `bearer ${accessToken}` } };

    return axios.post<void>(
      `${this.baseUrl}/api/account/verification/start`,
      data,
      config
    );
  }

  completeEmailVerification(token: string) {
    return axios.post<void>(
      `${this.baseUrl}/api/account/verification/complete`,
      { token }
    );
  }

  getEmailVerificationStatus(accessToken: string) {
    const config = { headers: { Authorization: `bearer ${accessToken}` } };
    return axios.get<boolean>(
      `${this.baseUrl}/api/account/verification/status`,
      config
    );
  }

  canAccessResource(
    resourceId: string,
    resourceType: SharedResourceType,
    accessToken: string
  ) {
    const config = { headers: { Authorization: `bearer ${accessToken}` } };
    return axios.get<boolean>(
      `${this.baseUrl}/api/account/access/resource/${resourceId}?type=${resourceType}`,
      config
    );
  }

  checkExistingUser(id: string, email: string, token: string) {
    return this.instance.post<string>(
      `${this.baseUrl}/api/account/checkExisting/${id}`,
      {
        email,
        token
      }
    );
  }
}

export const accountService = new AccountService(envService.baseUrl);
