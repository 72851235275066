import React from "react";
import { ConcreteColors } from "hcss-components";
import styled from "styled-components";

export interface RoadblockProps {
  as?: React.ElementType;
}

export const Roadblock = (props: RoadblockProps) => (
  <StyledRoadblockImage
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
    {...props}
  >
    <g id="Roadblock" transform="translate(-112 -75)">
      <g id="backdrop">
        <path
          id="bg"
          d="M150,0A150,150,0,1,1,0,150,150,150,0,0,1,150,0Z"
          transform="translate(112 75)"
        />
        <path
          id="bg-highlight"
          d="M112.977-1786.741a150.294,150.294,0,0,1,37.023-4.6,150,150,0,0,1,150,150,150.065,150.065,0,0,1-98.536,140.939Z"
          transform="translate(112 1866.345)"
        />
      </g>
      <g id="roadblock-main">
        <path
          id="l_m"
          d="M207-3277a3,3,0,0,1-3-3v-114a3,3,0,0,1,3-3h10a3,3,0,0,1,3,3v114a3,3,0,0,1-3,3Zm-132,0a3,3,0,0,1-3-3v-114a3,3,0,0,1,3-3H85a3,3,0,0,1,3,3v114a3,3,0,0,1-3,3Z"
          transform="translate(116 3568)"
        />
        <path
          id="l_sh"
          d="M204-3305v-12h16v12Zm-132,0v-12H88v12Zm132-56v-12h16v12Zm-132,0v-12H88v12Z"
          transform="translate(116 3568)"
        />
        <g id="p_b">
          <path
            id="c-1"
            d="M3,0H177a3,3,0,0,1,3,3V21a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0Z"
            transform="translate(172 227)"
          />
          <path
            id="stripes-1"
            d="M252-3373l10-24h7a3,3,0,0,1,3,3v18a3,3,0,0,1-3,3Zm-60,0,10-24h30l-10,24Zm-60,0,10-24h30l-10,24Zm-37,0a3,3,0,0,1-3-3v-18a3,3,0,0,1,3-3h17l-10,24Z"
            transform="translate(80 3624)"
          />
          <path
            id="sh-1"
            d="M249-3365H75a3,3,0,0,1-3-3v-3.89a2.992,2.992,0,0,0,3,2.89H249a3,3,0,0,0,3-3v4A3,3,0,0,1,249-3365Z"
            transform="translate(100 3616)"
          />
        </g>
        <g id="p_t" transform="translate(0 -16)">
          <path
            id="c-2"
            d="M3,0H177a3,3,0,0,1,3,3V21a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0Z"
            transform="translate(172 187)"
          />
          <path
            id="stripes-2"
            d="M252-3373l10-24h7a3,3,0,0,1,3,3v18a3,3,0,0,1-3,3Zm-60,0,10-24h30l-10,24Zm-60,0,10-24h30l-10,24Zm-37,0a3,3,0,0,1-3-3v-18a3,3,0,0,1,3-3h17l-10,24Z"
            transform="translate(80 3584)"
          />
          <path
            id="sh-2"
            data-name="sh"
            d="M249-3365H75a3,3,0,0,1-3-3v-3.89a2.992,2.992,0,0,0,3,2.89H249a3,3,0,0,0,3-3v4A3,3,0,0,1,249-3365Z"
            transform="translate(100 3576)"
          />
        </g>
      </g>
    </g>
  </StyledRoadblockImage>
);

const StyledRoadblockImage = styled.svg`
  & #bg,
  & #l_sh {
    fill: ${ConcreteColors.blue200};
    opacity: 0.2;
  }
  & #l_m,
  & #sh-1,
  & #sh-2 {
    fill: ${ConcreteColors.blue200};
    opacity: 0.3;
  }
  & #bg-highlight {
    fill: #ffffff;
    opacity: 0.5;
  }
  & #c-1,
  & #c-2 {
    fill: #ffffff;
  }
  & #stripes-1,
  & #stripes-2 {
    fill: #ff7861;
  }
`;
