export enum SANDBOX_CONSTANTS {
  COMPANY_ID = "be819ffa-5f9e-4608-a40c-5dfab19188cb",
  USER_ID = "c34cfb5f-d453-4273-a7e5-4dbf49818004",
  USER_FIRST_NAME = "John",
  USER_LAST_NAME = "Doe",
  COMPANY_NAME = "Sandbox Company",
  BUSINESS_UNIT_ID = "37682b0b-f27f-42b9-83e4-1b086a72716f",
  BUSINESS_UNIT_NAME = "MANAGER",
  USER_NAME = "JohnDoe@SandboxCo",
  USER_EMAIL = "johnDoe@sandbox.com"
}
