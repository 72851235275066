import React, { ReactNode } from "react";
import styled from "styled-components";
import { HelpMenuOverride } from "./help-menu";
import { RegionMenuItem } from "./region-menu-item";
import { LanguageMenuItem } from "./language-menu-item";
import { Link, Icon } from "hcss-components";
import { strings } from "localization";
import { useDispatch, useSelector } from "react-redux";
import { useOidcContext } from "modules/account/services/oidc-context";
import { actions } from "modules/settings";
import { useHcssUser, usePermissions, useAuthorization } from "modules/account";
import { FeatureFlag, UserType } from "core";
import { selectors } from "modules/account";
import { useFeatureFlags } from "../../../configurationSettings/use-feature-flags";
import {
  GlobalTopNavigation,
  ProfileMenu,
  DropdownMenuItem,
  DropdownMenuDivider,
  DropdownMenu,
  DropdownButton,
  Dropdown,
  DropdownIcon
} from "core/components/hcss-navigation";

import {
  DisableInSandbox,
  HideInSandbox,
  ResetSandboxButton,
  DisableSandboxButton,
  capitalizeWords
} from "core/util";

import moment, { Moment } from "moment-timezone";

export const TopNavigation = () => {
  const user = useHcssUser();
  const secondaryItems: ReactNode[] = [];

  if (!user) {
    return null;
  }

  if (user.type === UserType.Guest) {
    secondaryItems.push(
      <FeedbackComponent key="feedback" />,
      <HelpMenuOverride key="help" />,
      <GuestProfileMenuComponent key="profile" />
    );
  } else if (user.type === UserType.Subcontractor) {
    secondaryItems.push(
      <FeedbackComponent key="feedback" />,
      <HelpMenuOverride key="help" />,
      <SettingsComponent key="settings" />,
      <ProfileMenuComponent key="profile" />
    );
  } else {
    secondaryItems.push(
      <SandboxComponent key="sandbox" />,
      <FeedbackComponent key="feedback" />,
      <ChatComponent key="chat" />,
      <HelpMenuOverride key="help" />,
      <SettingsComponent key="settings" />,
      <ProfileMenuComponent key="profile" />
    );
  }

  return (
    <GlobalTopNavigation
      testId="top-nav"
      productHomeComponent={ProductHomeComponent}
      moreLabel="More"
      primaryItems={[]}
      secondaryItems={secondaryItems}
    />
  );
};

const getSiteBrandTitle = (user: any) => {
  if (user.type === UserType.Subcontractor || user.type === UserType.Guest) {
    return (
      <span>
        <b style={{ fontFamily: "ProximaNova Heavy" }}>ProSubs</b> Quoting
      </span>
    );
  }

  return (
    <span>
      Heavy<b style={{ fontFamily: "ProximaNova Heavy" }}>Bid</b> |
      Pre-Construction
    </span>
  );
};

export const getExpertChatAvailability = () => {
  const centralDateTime: Moment = moment().tz("America/Chicago");
  const centralDay: number = centralDateTime.day();
  const centralHour: number = centralDateTime.hours();
  const chatAvailable: boolean =
    centralDay >= 1 && centralDay <= 5 && centralHour >= 8 && centralHour < 18;
  return chatAvailable;
};

const ProductHomeComponent = () => {
  const user = useHcssUser();
  const to = user.type === UserType.Guest ? "/account/landing" : "/";
  return (
    <StyledSiteTitle>
      {user.type === UserType.Subcontractor ||
      user.type === UserType.Guest ? null : (
        <Icon
          name="product-heavybid"
          style={{
            marginRight: "0.5em",
            color: "rgb(0, 150, 57)",
            fontSize: "30px"
          }}
        />
      )}
      <Link
        to={to}
        style={{ fontSize: "20px" }}
        className={`${
          user.type === UserType.Subcontractor || user.type === UserType.Guest
            ? null
            : "heavyBidClr"
        }`}
      >
        {getSiteBrandTitle(user)}
      </Link>
    </StyledSiteTitle>
  );
};

const FeedbackComponent = () => {
  return (
    <HideInSandbox>
      <TertiaryLink
        href="https://docs.google.com/forms/d/e/1FAIpQLSeQY9yw5kjqWhAwTA9qlhaogHpegctuEZyychtyMpWestIl6w/viewform"
        data-testid="top-nav-feedback"
      >
        {strings.layout.feedback}
      </TertiaryLink>
    </HideInSandbox>
  );
};

const SandboxComponent = () => {
  return (
    <>
      <DisableSandboxButton />
      <ResetSandboxButton />
    </>
  );
};

const ChatComponent = () => {
  return (
    <HideInSandbox>
      <CustomChatButton
        onClick={() => window?.embedded_svc?.bootstrapEmbeddedService?.()}
        data-testid="top-nav-chat"
        availability={getExpertChatAvailability()}
      >
        <CustomIcon className="fa fa-comments" />
        {getExpertChatAvailability()
          ? strings.layout.chat.online
          : strings.layout.chat.offline}
      </CustomChatButton>
    </HideInSandbox>
  );
};

const AlphaHelpComponent = () => {
  const { isFeatureEnabled } = useFeatureFlags("Beta");
  const auth = useAuthorization();
  const user = useHcssUser();
  const link =
    user.type === UserType.HcssSubscription
      ? "https://hcssdev.atlassian.net/wiki/spaces/EX/overview?homepageId=334136167"
      : "https://hcssdev.atlassian.net/wiki/spaces/EXHBSUB/overview?homepageId=351076652";

  if (!auth.canAccessQuoteManagement) {
    return null;
  }

  return (
    <TertiaryLink href={link} data-testid="top-nav-alphaHelp">
      {isFeatureEnabled ? "Beta Help" : strings.layout.alphaHelp}
    </TertiaryLink>
  );
};

export const SettingsComponent = () => {
  const user = useHcssUser();
  const permissions = usePermissions();
  const auth = useAuthorization();

  const isCompanyNameValidForSandbox =
    user &&
    user.companyName &&
    (user.companyName.toLowerCase().includes("hcss") ||
      user.companyName.toLowerCase().includes("ugm"));

  if (!user || user.type === UserType.Guest) {
    return null;
  }

  if (
    (!permissions.admin || !auth.canAccessFullPrecon) &&
    !auth.canAccessQuoteManagement &&
    !isCompanyNameValidForSandbox
  ) {
    return null;
  }

  return (
    <Dropdown data-testid="top-nav-settings-component">
      <DropdownButton data-testid="top-nav-settings-button">
        <DropdownIcon className="fa fa-cog" />
      </DropdownButton>
      <SettingsDropdownMenu right data-testid="top-nav-settings">
        <DisableInSandbox>
          <DropdownMenuItem
            data-testid="top-nav-settings-companyProfile"
            iconClassName="fa fa-building"
            to="/settings/profile"
          >
            {strings.layout.menu.settings.companyProfile}
          </DropdownMenuItem>
        </DisableInSandbox>

        {permissions.admin && auth.canAccessFullPrecon && (
          <DropdownMenuItem
            data-testid="top-nav-settings-permissions"
            iconClassName="fa fa-users"
            to="/settings/permissions"
          >
            {strings.layout.menu.settings.userAdmin}
          </DropdownMenuItem>
        )}

        {isCompanyNameValidForSandbox && (
          <HideInSandbox>
            <DropdownMenuItem
              data-testid="top-nav-settings-sandbox"
              iconClassName="fa fa-cog"
              onClick={() => {
                window.localStorage.setItem("sandbox", "true");
                window.location.reload();
              }}
            >
              {strings.layout.profile.enableSandbox}
            </DropdownMenuItem>
          </HideInSandbox>
        )}
      </SettingsDropdownMenu>
    </Dropdown>
  );
};

export const ProfileMenuComponent = () => {
  const dispatch = useDispatch();
  const user = useHcssUser();
  const auth = useAuthorization();
  const { isFeatureEnabled } = useFeatureFlags(FeatureFlag.LanguageSelector);

  const accessibleBusinessUnits =
    useSelector(selectors.getAccessibleBusinessUnits) ?? [];
  const { oidcService } = useOidcContext();

  if (!user) return null;

  return (
    <ProfileMenu
      key="profile"
      firstName={capitalizeWords(user.firstName)}
      lastName={capitalizeWords(user.lastName)}
      subtext={user.companyName}
    >
      {accessibleBusinessUnits?.length > 1 && (
        <DropdownMenuItem
          data-testid="top-nav-profile-calendar"
          iconClassName="fa fa-address-card"
          onClick={() => dispatch(actions.toggleBUPanel())}
        >
          {strings.layout.profile.bu}
        </DropdownMenuItem>
      )}
      {auth.canAccessHcssConnect && (
        <DropdownMenuItem
          data-testid="top-nav-profile-calendar"
          iconClassName="fa fa-calendar"
          onClick={() => dispatch(actions.toggleCalendarPanel())}
        >
          {strings.layout.profile.calendar}
        </DropdownMenuItem>
      )}
      <DropdownMenuItem
        onClick={() => oidcService.signoutRedirect()}
        iconClassName="fa fa-power-off"
        data-testid="top-nav-profile-logout"
      >
        <div style={{ display: "inline-block", width: "175px" }}>
          {strings.layout.profile.logout}
        </div>
      </DropdownMenuItem>

      {user.type === UserType.HcssSubscription &&
        auth.canAccessFullReadonlyPrecon && (
          <>
            <DropdownMenuDivider style={{ marginBottom: "15px" }} />
            <RegionMenuItem />
            {isFeatureEnabled && <LanguageMenuItem />}
          </>
        )}
    </ProfileMenu>
  );
};

const GuestProfileMenuComponent = () => {
  const user = useHcssUser();
  const { oidcService } = useOidcContext();

  if (!user) return null;

  return (
    <ProfileMenu
      key="profile"
      firstName="Guest"
      subtext={user.email}
      guest={true}
    >
      <DropdownMenuItem
        onClick={() => oidcService.signinRedirect("/")}
        iconClassName="fa fa-power-off"
        data-testid="top-nav-profile-logout"
      >
        {strings.layout.profile.logout}
      </DropdownMenuItem>
    </ProfileMenu>
  );
};

const StyledSiteTitle = styled.span`
  font-size: 18px;
  font-family: "ProximaNova";
  display: flex;
  align-items: center;

  .heavyBidClr {
    color: hsla(150, 95%, 26%, 1);
  }

  .headerLogo {
    width: 20%;
  }

  .heavyBidClr:hover {
    color: hsla(150, 95%, 22%, 1);
  }

  .heavyBidClr:focus {
    color: hsla(150, 95%, 22%, 1);
  }

  a {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    text-decoration-line: none;
  }

  a:hover,
  a:focus {
    color: inherit;
  }

  & strong {
    font-family: "ProximaNova Heavy";
  }

  & .darktext {
    color: #444;
  }
`;
const TertiaryLink = styled.a.attrs(() => ({
  target: "_blank",
  rel: "noopener noreferrer"
}))`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 16px;

  text-decoration: none;
  color: #333;
  font-family: sans-Arial, Helvetica, sans-serif;
  font-size: 1em;

  &:visited,
  &:focus,
  &:hover {
    color: #333;
    text-decoration: none;
  }

  &:hover {
    color: ${props => props.theme.colors.blue[2]};
  }

  transition: all 0.2s ease;

  @media (max-width: 499px) {
    display: none;
  }
`;

const CustomChatButton = styled.span`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  pointer-events: ${props => (props.availability ? "auto" : "none")};

  text-decoration: none;
  color: ${props =>
    props.availability ? "#333" : props.theme.colors.neutral[2]};
  font-family: sans-Arial, Helvetica, sans-serif;
  font-size: 1em;

  &:visited,
  &:focus,
  &:hover {
    color: #333;
    text-decoration: none;
  }

  &:hover {
    color: ${props => props.theme.colors.blue[2]};
  }

  transition: all 0.2s ease;

  @media (max-width: 499px) {
    display: none;
  }
`;

const SettingsDropdownMenu = styled(DropdownMenu)`
  padding: 20px 16px 12px 16px;

  & .dropdown-menu-item > a {
    padding: 10px 0;
  }
`;

const CustomIcon = styled.i`
  margin-right: 6px;
`;
