import { sortBy } from "lodash-es";

interface ListOption {
  display: string;
  value?: string;
}

/**
 * This will return the values in the multi-select list sorted by alphabetical or custom depending on the column config
 * @param selectedValues
 * @param options
 * @param isCustomSort
 * @returns
 */
export const sortValues = (
  selectedValues: string[],
  options: ListOption[],
  isCustomSort: boolean
): string[] => {
  let result: string[];
  if (!isCustomSort) {
    result = sortBy(selectedValues, val => val.toLocaleLowerCase());
  } else {
    result = options
      .map(val => val.display)
      .filter(val => selectedValues.includes(val));

    result = [...new Set([...result, ...selectedValues])];
  }
  return result;
};

/**
 * Some operations expect a field value to come in as an array
 * but depending on when/how a field was created/modified that
 * field can be null, string, or an array of strings.
 * This funciton will return an empty array or a string of arrays
 * @param values field.value
 * @returns an empty array or array of strings
 */
export function getFieldValueAsArray(
  values: null | string | string[]
): string[] {
  if (values === null || values === undefined) return [];
  if (typeof values === "string") return [values];
  return values;
}
