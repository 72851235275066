import { useEffect } from "react";
import { useOidcContext } from "../../services/oidc-context";

export const OidcSilentRenew = () => {
  const { oidcService } = useOidcContext();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        await oidcService.signinSilentCallback();
      } catch (error) {
        console.error("[OIDC Client] Renewal Callback:", error);
      }
    };

    handleCallback();
  }, [oidcService]);

  return null;
};
