import * as projects from "./projects";
import * as views from "./views";
import * as schemas from "./schemas";
import * as configurationSettings from "./configurationSettings";
import * as account from "./account";
import { createSelector } from "reselect";

export const getErrorsState = createSelector(
  [
    account.selectors.getErrors,
    configurationSettings.selectors.getErrors,
    projects.selectors.getErrors,
    schemas.selectors.getErrors,
    views.selectors.getErrors
  ],
  (
    accountErrors: string[],
    configErrors: string[],
    projectsErrors: string[],
    schemasErrors: string[],
    viewsErrors: string[]
  ) => {
    return [
      ...accountErrors,
      ...configErrors,
      ...projectsErrors,
      ...schemasErrors,
      ...viewsErrors
    ];
  }
);

export const getSiteBreakingErrors = createSelector(
  [getErrorsState],
  errors => {
    return errors.filter(e => !errorShouldBeIgnored(e));
  }
);

const errorShouldBeIgnored = (error: string) => {
  return errorsToIgnore.some(code => error.includes(code));
};

const errorsToIgnore = ["400", "403", "404"];
