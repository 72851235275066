import { ActionType } from "typesafe-actions";
import { oidcService } from "../services/oidc-service";
import { User } from "oidc-client";
import { actions } from "../state";
import { call, put } from "redux-saga/effects";
import { signInWithHcss } from "./auth-signin";

export function* getUser(action: ActionType<typeof actions.getUser.request>) {
  try {
    const oidcUser: User = yield call([oidcService, oidcService.getUser]);

    if (oidcUser && !oidcUser.expired) {
      yield call(
        signInWithHcss,
        actions.signInWithHcssCredentials.request(oidcUser)
      );
    } else {
      const redirect = action.payload;
      yield call([oidcService, oidcService.signinRedirect], redirect);
    }

    yield put(actions.getUser.success());
  } catch (error) {
    console.error(error);
    yield put(actions.getUser.failure(error));
  }
}
