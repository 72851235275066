import { NoDataPlaceholder, TextOverflowVertically } from "core";
import { Button, Icon, Modal } from "hcss-components";
import PaneledPage from "core/components/paneled-page/PaneledPage";
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import {
  DataColumnType,
  SummaryType,
  TableSection,
  TypedDataColumn,
  useTableContext
} from "hcss-tables";
import { cloneDeep, Dictionary, keyBy } from "lodash";
import * as React from "react";
import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useMemo, useState, useEffect } from "react";
import {
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/CancelSharp";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { sortBy } from "lodash-es";
import { useLocalStorage } from "hcss-hooks";
import {
  useSelectedBusinessUnitId,
  useUserAndCompanyLicense
} from "modules/account";
import { strings } from "localization";
import { schemaFieldTypeOptions } from "modules/schemas/components/SchemaSetupModels";
import { SchemaFieldType } from "api";
import { constantSections } from "modules/projects";

enum SortType {
  Alphabetically,
  FieldType,
  Default
}
const sortByOptions = [
  {
    label:
      strings.modals.columnChooserModal.inputs.dropdown.sortByOptions
        .alphabetically,
    value: SortType.Alphabetically
  },
  {
    label:
      strings.modals.columnChooserModal.inputs.dropdown.sortByOptions.fieldType,
    value: SortType.FieldType
  },
  {
    label:
      strings.modals.columnChooserModal.inputs.dropdown.sortByOptions.default,
    value: SortType.Default
  }
];
interface CustomColumnChooserModalProps {
  showProductIcons?: boolean;
}
export const CustomColumnChooserModal = ({
  showProductIcons = false
}: CustomColumnChooserModalProps) => {
  const {
    columnLookup,
    columns,
    sections,
    visibility: { hiddenColumns, setHiddenColumns },
    columnSelect: { showModal, setShowModal }
  } = useTableContext();
  const { companyLicense } = useUserAndCompanyLicense();

  const disabledColumnList: TypedDataColumn[] = useMemo(() => {
    const result: TypedDataColumn[] = [];
    if (!companyLicense.isLimitedLicense) {
      return result;
    }

    const hJJobCodeColumn = columns.find(col => col.name === "hJJob.Code");
    const hJJobDescription = columns.find(
      col => col.name === "hJJob.Description"
    );
    if (hJJobCodeColumn) {
      result.push(hJJobCodeColumn);
    }
    if (hJJobDescription) {
      result.push(hJJobDescription);
    }
    const quickPriceTotalPriceColumn = columns.find(
      col => col.name === "quickPriceSheet.TotalPrice"
    ) as TypedDataColumn;
    const quickPriceCountColumn = columns.find(
      col => col.name === "quickPriceSheet.ProjectPayItemCount"
    ) as TypedDataColumn;
    if (quickPriceTotalPriceColumn) {
      result.push(quickPriceTotalPriceColumn);
    }
    if (quickPriceCountColumn) {
      result.push(quickPriceCountColumn);
    }
    return result;
  }, [columns, companyLicense]);

  useEffect(() => {
    for (const column of disabledColumnList) {
      if (!hiddenColumns.includes(column.name)) {
        changeHiddenColumns(column, false);
      }
    }
  }, [disabledColumnList]);

  const hiddenColumnLookup = keyBy(hiddenColumns);
  const [searchFieldsValue, setSearchFieldsValue] = useState("");
  const [sortBySelection, setSortBySelection] = useState(2);
  const businessUnitId = useSelectedBusinessUnitId();
  const [collapsed, setCollapsed] = useLocalStorage<string[]>(
    `${businessUnitId}-column-chooser-sections-collapsed`,
    []
  );
  // This flag is to deal with modals that don't have section;
  const hasSection = sections.length > 1;

  const isTableOrDisabled = (col: TypedDataColumn) => {
    const isTable = col.config?.preConType
      ? col.config.preConType === SchemaFieldType.Table
      : false;
    if (isTable) return true;
    return disabledColumnList.includes(col);
  };

  const showAllColumns = React.useCallback(() => {
    setHiddenColumns(
      columns.filter(isTableOrDisabled).map(column => column.name)
    );
  }, [setHiddenColumns, companyLicense]);
  const hideAllColumns = React.useCallback(() => {
    setHiddenColumns(
      columns.filter(column => column.hidingEnabled).map(column => column.name)
    );
  }, [setHiddenColumns, columns]);

  const handleSearchFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchFieldsValue(event.target.value);
  };
  const handleClearFieldsSearch = () => {
    setSearchFieldsValue("");
  };
  const handleSortBySelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSortBySelection(+event.target.value);
  };

  const filteredSectionsOrColumns = useMemo(() => {
    const sectionsToFilter = constantSections.filter(
      s => s !== "operations" && s !== "quickPrice"
    );
    let newSections = cloneDeep(
      sections.filter(s => !sectionsToFilter.includes(s.id))
    );
    let newColumns = cloneDeep(columns);
    // filtering out table field for now
    const fieldIdsToExclude = newColumns
      .filter(col => col.config?.preConType === SchemaFieldType.Table)
      .map(col => col.id);
    newSections.forEach(section => {
      section.fields = section.fields.filter(
        field => !fieldIdsToExclude.includes(field)
      );
    });
    if (searchFieldsValue) {
      if (hasSection) {
        newSections.forEach(section => {
          section.fields = section.fields.filter(field =>
            columnLookup[field]?.title
              ?.toLowerCase()
              .includes(searchFieldsValue.toLowerCase())
          );
        });
        const updatedCollapsed = collapsed.filter(
          sectionId => !newSections.map(s => s.id).includes(sectionId)
        );
        setCollapsed(updatedCollapsed);
      } else {
        newColumns = newColumns.filter(col =>
          col.title?.toLowerCase().includes(searchFieldsValue.toLowerCase())
        );
      }
    }
    if (sortBySelection !== SortType.Default) {
      switch (sortBySelection) {
        case SortType.Alphabetically:
          if (hasSection) {
            newSections.forEach(section => {
              section.fields = sortBy(section.fields, c =>
                columnLookup[c]?.title?.trim().toLowerCase()
              );
            });
          } else {
            newColumns = sortBy(newColumns, c => c.title);
          }
          break;
        case SortType.FieldType:
          if (hasSection) {
            newSections.forEach(section => {
              section.fields = sortBy(
                section.fields,
                c =>
                  columnLookup[c]?.config?.preConType || columnLookup[c]?.type
              );
            });
          } else {
            newColumns = sortBy(newColumns, [
              c => c.config?.preConType ?? c.type,
              c => c.title
            ]);
          }
          break;
        default:
          break;
      }
    }
    newSections = newSections.filter(s => s.fields.length !== 0);
    return hasSection ? newSections : newColumns;
  }, [searchFieldsValue, sortBySelection, sections]);

  const isCollapsedAll = collapsed.length === sections.length;
  const handleToggleAll = () => {
    if (isCollapsedAll) {
      setCollapsed([]);
    } else {
      setCollapsed(sections.map(s => s.id));
    }
  };
  const onToggleHandler = (sectionId: string, isSectionCollapsed: boolean) => {
    let newCollapsed = [...collapsed];
    if (isSectionCollapsed) {
      newCollapsed = newCollapsed.filter(s => s !== sectionId);
    } else {
      newCollapsed.push(sectionId);
    }
    setCollapsed(newCollapsed);
  };

  const changeHiddenColumns = React.useCallback(
    (column: TypedDataColumn, show: boolean) => {
      let newHiddenColumns = [...hiddenColumns];
      column.hidingEnabled &&
      !show &&
      newHiddenColumns.indexOf(column.name) === -1
        ? newHiddenColumns.push(column.name)
        : (newHiddenColumns = newHiddenColumns.filter(
            columnName => columnName !== column.name
          ));
      setHiddenColumns(newHiddenColumns);
    },
    [hiddenColumns, setHiddenColumns]
  );

  const handleCheckAll = (fields: any) => {
    const fieldsLookup = keyBy(fields);
    let newHiddenColumns = [...hiddenColumns];
    const atLeastOneFieldIsHidden =
      fields.findIndex((f: any) => f in hiddenColumnLookup) !== -1;
    if (!atLeastOneFieldIsHidden) {
      newHiddenColumns.push(...fields);
    } else {
      newHiddenColumns = hiddenColumns.filter(c => !(c in fieldsLookup));
    }
    setHiddenColumns(newHiddenColumns);
  };

  return (
    <StyledModal
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setSearchFieldsValue("");
      }}
      // this is the required class name to prevent the ability to drag the projects table while you editing the modal.
      className="drag-ignore"
      enforceFocus={false}
    >
      <Modal.Header style={{ backgroundColor: "#f0f0f0" }} closeButton>
        <Modal.Title>{strings.modals.columnChooserModal.title}</Modal.Title>
      </Modal.Header>
      <ModalBodyContainer
        style={{
          overflowY: `${hasSection ? "clip" : "auto"}`,
          height: `${hasSection ? "70vh" : "auto"}`
        }}
        data-testid="columnchooser-modal"
      >
        <Grid
          container
          justifyContent="space-between"
          style={{ paddingBottom: "1.5rem" }}
        >
          <Grid item xs={6}>
            <TextField
              id="column-chooser-searchbox"
              fullWidth
              label={strings.modals.columnChooserModal.inputs.search.label}
              placeholder={
                strings.modals.columnChooserModal.inputs.search.placeholder
              }
              style={{ backgroundColor: "white" }}
              value={searchFieldsValue}
              onChange={handleSearchFieldChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: searchFieldsValue.length > 0 && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearFieldsSearch}>
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label={
                strings.modals.columnChooserModal.inputs.dropdown.sortBy.label
              }
              select
              placeholder={
                strings.modals.columnChooserModal.inputs.dropdown.sortBy
                  .placeHolder
              }
              style={{ backgroundColor: "white" }}
              value={sortBySelection}
              onChange={handleSortBySelection}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ImportExportIcon />
                  </InputAdornment>
                )
              }}
            >
              {sortByOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <ScrollableModalContent>
          {hasSection ? (
            <ColumnChooserPaneledPage
              sections={filteredSectionsOrColumns as TableSection[]}
              columnLookup={columnLookup}
              hiddenColumnLookup={hiddenColumnLookup}
              changeHiddenColumns={changeHiddenColumns}
              collapsedSections={collapsed}
              onToggleHandler={onToggleHandler}
              handleCheckAll={handleCheckAll}
              showProductIcons={showProductIcons}
            />
          ) : (
            <SectionColumnContainer>
              {filteredSectionsOrColumns.length === 0 ? (
                <NoDataPlaceholder
                  isAddEditDisplay={true}
                  buttonText={""}
                  showButton={false}
                  helpText={{
                    title: strings.modals.columnChooserModal.noData.title
                  }}
                />
              ) : (
                <ColumnListContainer>
                  <ColumnList>
                    {filteredSectionsOrColumns.map(column => {
                      return (
                        <ColumnCheckbox
                          column={column as TypedDataColumn}
                          hiddenColumnLookup={hiddenColumnLookup}
                          changeHiddenColumns={changeHiddenColumns}
                          showProductIcons={showProductIcons}
                        />
                      );
                    })}
                  </ColumnList>
                </ColumnListContainer>
              )}
            </SectionColumnContainer>
          )}
        </ScrollableModalContent>
      </ModalBodyContainer>
      <Modal.Footer style={{ backgroundColor: "#f0f0f0", borderTop: "none" }}>
        <>
          <div style={{ float: "left" }}>
            <Button hcssStyle="ThemeInverted" onClick={showAllColumns}>
              {strings.modals.columnChooserModal.buttons.show}
            </Button>
            <Button hcssStyle="ThemeInverted" onClick={hideAllColumns}>
              {strings.modals.columnChooserModal.buttons.hide}
            </Button>
            {hasSection && (
              <Button
                onClick={handleToggleAll}
                hcssStyle="ThemeInverted"
                style={{ marginRight: "2rem" }}
              >
                <span>
                  <Icon
                    name={isCollapsedAll ? "expand" : "compress"}
                    margin="right"
                  />
                  {isCollapsedAll
                    ? `${strings.modals.columnChooserModal.buttons.expandAll}`
                    : `${strings.modals.columnChooserModal.buttons.collapseAll}`}
                </span>
              </Button>
            )}
          </div>
          <div style={{ float: "right" }}>
            <Button
              onClick={() => {
                setShowModal(false);
                setSearchFieldsValue("");
              }}
            >
              {`${strings.modals.columnChooserModal.buttons.close}`}
            </Button>
          </div>
        </>
      </Modal.Footer>
    </StyledModal>
  );
};

interface ColumnChooserPaneledPageProps {
  sections: TableSection[];
  columnLookup: Dictionary<TypedDataColumn>;
  hiddenColumnLookup: Dictionary<string>;
  collapsedSections: string[];
  showProductIcons: boolean;
  onToggleHandler: (sectionId: string, isSectionCollapsed: boolean) => void;
  changeHiddenColumns: (column: TypedDataColumn, show: boolean) => void;
  handleCheckAll: (fields: any) => void;
}

export const ColumnChooserPaneledPage = ({
  sections,
  columnLookup,
  hiddenColumnLookup,
  collapsedSections,
  showProductIcons,
  onToggleHandler,
  changeHiddenColumns,
  handleCheckAll
}: ColumnChooserPaneledPageProps) => {
  const { companyLicense } = useUserAndCompanyLicense();
  return (
    <PaneledPage fitTo="container">
      {sections.length === 0 ? (
        <NoDataPlaceholder
          isAddEditDisplay={true}
          buttonText={""}
          showButton={false}
          helpText={{
            title: strings.modals.columnChooserModal.noData.title
          }}
        />
      ) : (
        <PaneledPage.Content
          contentInnerWrapperId="column-chooser-modal-inner-wrapper"
          mainContainerId="column-chooser-main-content"
        >
          <PaneledPage.Content.TableOfContents />
          {sections.map(section => {
            if (!section.fields) return null;
            const checkedStatuses: boolean[] = section.fields
              .filter(f => f in columnLookup)
              .map(f => {
                if (f in hiddenColumnLookup) return false;
                return true;
              });
            if (checkedStatuses.length === 0) return null;
            const areAllChecked = checkedStatuses.findIndex(s => !s) === -1;
            const atLeastOneChecked = checkedStatuses.findIndex(s => s) !== -1;
            const isCollapsed = collapsedSections.includes(section.id);
            const isHJSectionInLimitedCompany =
              companyLicense.isLimitedLicense && section.id == "operations";
            const isQuickPriceSectionInLimitedCompany =
              companyLicense.isLimitedLicense && section.id == "quickPrice";
            return (
              <PaneledPage.Content.Section
                key={section.id}
                title={section.name}
                collapsible={true}
                isCollapsed={isCollapsed}
                testId={section.name}
                passThroughProps={{
                  disabled:
                    isHJSectionInLimitedCompany ||
                    isQuickPriceSectionInLimitedCompany
                      ? "true"
                      : undefined
                }}
                onToggleHandler={isSectionCollapsed =>
                  onToggleHandler(section.id, isSectionCollapsed)
                }
                sectionCheckBox={
                  <div
                    style={{
                      cursor:
                        isHJSectionInLimitedCompany ||
                        isQuickPriceSectionInLimitedCompany
                          ? "not-allowed"
                          : "pointer"
                    }}
                  >
                    <Checkbox
                      checked={areAllChecked}
                      indeterminate={atLeastOneChecked && !areAllChecked}
                      onClick={() => handleCheckAll(section.fields)}
                      disabled={
                        isHJSectionInLimitedCompany ||
                        isQuickPriceSectionInLimitedCompany
                      }
                      style={{
                        padding: 0
                      }}
                    />
                  </div>
                }
              >
                {isHJSectionInLimitedCompany && (
                  <div className="body" style={{ marginBottom: "10px" }}>
                    {strings.projects.heavyJob.limitedPreConDescription}
                  </div>
                )}
                {isQuickPriceSectionInLimitedCompany && (
                  <div className="body" style={{ marginBottom: "10px" }}>
                    {
                      strings.projects.quickPricing
                        .limitedDescriptionColumnChooser
                    }
                  </div>
                )}
                {section.fields.map(fieldId => {
                  const column = columnLookup[fieldId];
                  return (
                    column && (
                      <ColumnCheckbox
                        key={column.name}
                        column={column}
                        hiddenColumnLookup={hiddenColumnLookup}
                        changeHiddenColumns={changeHiddenColumns}
                        showProductIcons={showProductIcons}
                        isDisabled={
                          isHJSectionInLimitedCompany ||
                          isQuickPriceSectionInLimitedCompany
                        }
                      />
                    )
                  );
                })}
              </PaneledPage.Content.Section>
            );
          })}
        </PaneledPage.Content>
      )}
    </PaneledPage>
  );
};

interface ColumnCheckboxProps {
  column: TypedDataColumn;
  hiddenColumnLookup: Record<string, string>;
  changeHiddenColumns: (column: TypedDataColumn, show: boolean) => void;
  showProductIcons: boolean;
  isDisabled?: boolean;
}

const ColumnCheckbox = ({
  column,
  hiddenColumnLookup,
  changeHiddenColumns,
  showProductIcons,
  isDisabled = false
}: ColumnCheckboxProps) => {
  const isVisible = !(column.name in hiddenColumnLookup);
  const leadingIcon = getLeadingIcon(column);
  const trailingIcon = getTrailingIcon(column);
  const disableField = !column.hidingEnabled || isDisabled;

  return (
    <FormControl fullWidth>
      <FormGroup>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          wrap="nowrap"
        >
          <Grid item>
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  width: "100%"
                }
              }}
              label={
                <Grid
                  container
                  wrap="nowrap"
                  style={{ cursor: disableField ? "not-allowed" : "pointer" }}
                >
                  <Grid item>
                    <div
                      style={{
                        width: "1.6rem",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Icon
                        name={leadingIcon.name}
                        style={{
                          margin: "auto",
                          color: leadingIcon.color,
                          paddingBottom: ".2rem"
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <TextOverflowVertically
                      style={{
                        paddingLeft: ".6rem",
                        paddingRight: ".4rem",
                        overflowWrap: "anywhere"
                      }}
                    >
                      <ColumnTitle column={column}></ColumnTitle>
                    </TextOverflowVertically>
                  </Grid>
                  {showProductIcons && trailingIcon && (
                    <Grid item>
                      <div
                        style={{
                          width: "1.6rem",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <Icon
                          name={trailingIcon.name}
                          style={{
                            margin: "auto",
                            width: "1.2rem",
                            color: trailingIcon.color
                          }}
                        />
                      </div>
                    </Grid>
                  )}
                </Grid>
              }
              control={
                <div
                  style={{
                    cursor: disableField ? "not-allowed" : "pointer",
                    padding: "0 .8rem 0 1.1rem"
                  }}
                  data-testid={`columnchooser-checkbox-${column.name}`}
                >
                  <Checkbox
                    disabled={disableField}
                    style={{
                      padding: 0
                    }}
                    checked={isVisible}
                    onChange={() => {
                      changeHiddenColumns(column, !isVisible);
                    }}
                  />
                </div>
              }
            />
          </Grid>
          <Grid item>
            <NumericOptionsToggle column={column} disabled={isDisabled} />
          </Grid>
        </Grid>
      </FormGroup>
    </FormControl>
  );
};

interface NumericOptionsProps {
  column: TypedDataColumn;
  disabled: boolean;
}

const NumericOptionsToggle = ({ column, disabled }: NumericOptionsProps) => {
  const {
    summary: { setSummaryItems, summaryItems, showOptionToggle }
  } = useTableContext();

  if (!showOptionToggle(column)) return null;
  if (
    column.id === "values.filters.latitude" ||
    column.id === "values.filters.longitude"
  )
    return null;
  const selected =
    summaryItems.find(summ => column.name === summ.columnName)?.type ??
    SummaryType.Sum;
  return (
    <ToggleButtonGroup
      style={{ minWidth: "70px" }}
      type="radio"
      bsSize="sm"
      value={selected}
      name="options"
      onChange={(e: any) => {
        setSummaryItems(e, column);
      }}
    >
      <StyledButton
        type="radio"
        value={SummaryType.Sum}
        disabled={disabled}
        style={{
          pointerEvents: disabled ? "none" : "auto"
        }}
      >
        {strings.modals.columnChooserModal.inputs.radio.summaryType.label.sum}
      </StyledButton>
      <StyledButton
        type="radio"
        value={SummaryType.Avg}
        disabled={disabled}
        style={{ pointerEvents: disabled ? "none" : "auto" }}
      >
        {strings.modals.columnChooserModal.inputs.radio.summaryType.label.avg}
      </StyledButton>
    </ToggleButtonGroup>
  );
};

const getLeadingIcon = (column: TypedDataColumn) => {
  const icon = { name: "", color: "black" };
  if (
    column.type === DataColumnType.Custom ||
    (column.config && column.config.preConType)
  ) {
    icon.name = schemaFieldTypeOptions[column.config?.preConType]?.icon ?? null;
  } else {
    icon.name = schemaFieldTypeOptions[column.type]?.icon ?? null;
  }
  return icon;
};

const getTrailingIcon = (column: TypedDataColumn) => {
  const icon = { name: "", color: "" };
  if (column.id === "hJJob.Code" || column.id === "hJJob.Description") {
    icon.color = "rgb(0, 94, 184)";
    icon.name = "product-heavyjob";
  } else if (
    column.id === "values.code" ||
    column.id?.includes("values.totals") ||
    column.id?.includes("values.customTotals")
  ) {
    icon.name = "product-heavybid";
    icon.color = "rgb(0, 150, 57)";
  } else {
    return null;
  }
  return icon;
};

export const ModalBodyContainer = styled(Modal.Body)`
  max-height: 70vh;
  overflow: auto;
`;

export const ScrollableModalContent = styled.div`
  height: calc(100% - 57px);
`;

export const StyledModal = styled(Modal)`
  & .modal-content {
    border: none;
    background-color: #f0f0f0;
  }
`;

const StyledButton = styled(ToggleButton)`
  padding: 2px 5px !important;
`;

const SectionColumnContainer = styled.div`
  break-inside: avoid;
`;

const ColumnList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;

const ColumnListContainer = styled.div`
  background-color: #ffffff;
  padding: 8px 0;
  border-radius: 4px;
  box-shadow: -4px 4px 10px -2px #d6d6d6;
`;

const ColumnTitleContainer = styled.div`
  &.required:after {
    content: " *";
  }
`;

const ColumnTitle = ({ column }: { column: TypedDataColumn }) => (
  <ColumnTitleContainer className={column.required ? "required" : undefined}>
    {column.title}
  </ColumnTitleContainer>
);
