import produce from "immer";
import { find } from "lodash-es";
import {
  ActionType,
  createAction,
  createAsyncAction,
  getType
} from "typesafe-actions";
import uuid from "uuid/v4";
import { Schema, SchemaField, SchemaSection } from "api";
import { WithId } from "core";
import { estimateExtendedSchema } from "./standard-schemas/estimate-extended-schema";
import { bidResultsSchema } from "./standard-schemas/bidresults-schema";
import { quoteDashboardSchema } from "./standard-schemas/quote-dashboard-schema";
import { generateColumnConfig } from "./selectors";
import { activityCodebooksSchema } from "./standard-schemas/activity-codebooks-schema";

export const STATE_KEY = "schemas";

// Models
export interface State {
  allIds: string[];
  projectSchemaId?: string;
  workingCopy: { [key: string]: WithId<Schema> };
  original: { [key: string]: WithId<Schema> };
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  saved: boolean;
  errors: string[];
}

export interface StateSlice {
  [STATE_KEY]: State;
}

// Actions
export const actions = {
  loadSchemas: createAsyncAction(
    "SCHEMAS/LOAD_REQUEST",
    "SCHEMAS/LOAD_SUCCESS",
    "SCHEMAS/LOAD_FAILURE"
  )<void, WithId<Schema>[], Error>(),

  saveSchema: createAsyncAction(
    "SCHEMAS/SAVE_REQUEST",
    "SCHEMAS/SAVE_SUCCESS",
    "SCHEMAS/SAVE_FAILURE"
  )<
    {
      schema: WithId<Schema>;
      meta?: {
        type?: string;
        message?: string;
        silent?: boolean;
        inRedux?: boolean;
      };
    },
    WithId<Schema>,
    Error
  >(),

  reloadStandardSchemas: createAction("SCHEMAS/RELOAD_STANDARD", resolve => {
    return () => resolve();
  }),

  moveSchemaField: createAction("SCHEMAS/MOVE_FIELD", resolve => {
    return (
      schemaId: string,
      fieldId: string,
      original: { sectionId: string; index: number },
      destination: { sectionId: string; index: number }
    ) =>
      resolve({
        schemaId,
        fieldId,
        original,
        destination
      });
  }),

  addSchemaField: createAction("SCHEMAS/ADD_FIELD", resolve => {
    return (
      schemaId: string,
      fieldType: number,
      destination: { sectionId: string; index: number },
      config?: { [key: string]: any }
    ) => resolve({ schemaId, fieldType, destination, config });
  }),

  saveSchemaField: createAction("SCHEMAS/SAVE_FIELD", resolve => {
    return (
      schemaId: string,
      field: SchemaField,
      meta?: { autosave: boolean }
    ) => resolve({ schemaId, field, meta });
  }),

  saveDataSyncPreference: createAction(
    "SCHEMAS/SAVE_DATA_SYNC_PREFERENCE",
    resolve => {
      return (
        schemaId: string,
        dataSyncPreference: string,
        meta?: { autosave: boolean }
      ) => resolve({ schemaId, dataSyncPreference, meta });
    }
  ),

  addSchemaFieldOption: createAction("SCHEMAS/ADD_FIELD_OPTION", resolve => {
    return (
      schemaId: string,
      fieldId: string,
      listValue: { display: string; value: string },
      saveSchema?: boolean
    ) => resolve({ schemaId, fieldId, listValue }, { saveSchema });
  }),

  removeSchemaField: createAction("SCHEMAS/REMOVE_FIELD", resolve => {
    return (schemaId: string, fieldId: string) =>
      resolve({ schemaId, fieldId });
  }),

  saveSchemaSection: createAction("SCHEMAS/SAVE_SECTION", resolve => {
    return (schemaId: string, section: SchemaSection) =>
      resolve({ schemaId, section });
  }),

  removeSchemaSection: createAction("SCHEMAS/REMOVE_SECTION", resolve => {
    return (schemaId: string, id: string) => resolve({ schemaId, id });
  }),

  moveSchemaSection: createAction("SCHEMAS/MOVE_SECTION", resolve => {
    return (
      schemaId: string,
      sectionId: string,
      originalIndex: number,
      destinationIndex: number
    ) =>
      resolve({
        schemaId,
        sectionId,
        originalIndex,
        destinationIndex
      });
  }),

  addSchemaSection: createAction("SCHEMAS/ADD_SECTION", resolve => {
    return (schemaId: string, index: number) => resolve({ schemaId, index });
  }),

  resetSchema: createAction("SCHEMAS/RESET", resolve => {
    return (schemaId: string) => resolve({ schemaId });
  }),

  updateSchemas: createAction("SCHEMAS/UPDATE", resolve => {
    return (schemas: WithId<Schema>[]) => resolve({ schemas });
  })
};

export type SchemaActions = ActionType<typeof actions>;

const estimatesextended = estimateExtendedSchema();
const bidresults = bidResultsSchema();
const quoteDashboard = quoteDashboardSchema();
const activityCodebooks = activityCodebooksSchema();

const initialState: State = {
  allIds: [
    estimatesextended.id,
    bidresults.id,
    quoteDashboard.id,
    activityCodebooks.id
  ],
  workingCopy: {
    estimatesextended,
    bidresults,
    quoteDashboard,
    activityCodebooks
  },
  original: {
    estimatesextended,
    bidresults,
    quoteDashboard,
    activityCodebooks
  },
  loading: false,
  loaded: false,
  saving: false,
  saved: true,
  errors: []
};

export const bidResultsLookupFields = [
  "winningBidCompany",
  "winningBidAmount",
  "fromLowBid",
  "percentFromLowBid",
  "leftOnTable",
  "percentLeftOnTable"
];

export const estimateLookupFields = [
  "values.code",
  "values.totals.totalCost_Takeoff",
  "values.totals.bidTotal_Bid",
  "values.totals.totalLabor_Total",
  "values.totals.permanentMaterial_Total",
  "values.totals.constructionMaterial_Total",
  "values.totals.subcontract_Total",
  "values.totals.totalEqp_Total",
  "values.totals.bond",
  "values.totals.addon_Cost",
  "values.totals.addon_Total",
  "values.totals.manhours_Total",
  "values.totals.markupPercentCost_Takeoff",
  "values.totals.markupPercentCost_Bid",
  "values.totals.markupPercentSales_Takeoff",
  "values.totals.markupPercentSales_Bid",
  "values.totals.balMarkup_Bid",
  "values.totals.balMarkup_Takeoff",
  "values.totals.actualMarkup_Bid",
  "values.totals.actualMarkup_Takeoff"
];

const hJJobLookUpFields = ["hJJob.Code", "hJJob.Description"];
//This field should show as one single field in Customize Setup, therefore we should
// have logic for this field similar to the estimateLookupFields and bid results fields
export const lastModifiedByFieldId = "lastModifiedBy";

// Reducer
export const reducer = (state = initialState, action: SchemaActions) => {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.reloadStandardSchemas): {
        const estimatesextended = estimateExtendedSchema();
        const bidresults = bidResultsSchema();
        draft.original.estimatesextended = estimatesextended;
        draft.workingCopy.estimatesextended = estimatesextended;
        draft.original.bidresults = bidresults;
        draft.workingCopy.bidresults = bidresults;
        draft.original.quoteDashboard = quoteDashboard;
        draft.workingCopy.quoteDashboard = quoteDashboard;
        draft.original.activityCodebooks = activityCodebooks;
        draft.workingCopy.activityCodebooks = activityCodebooks;
        break;
      }
      case getType(actions.loadSchemas.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }
      case getType(actions.loadSchemas.success): {
        action.payload.forEach(schema => {
          if (!(schema.id in draft.original)) {
            draft.allIds.push(schema.id);
          }
          draft.workingCopy[schema.id] = schema;
          draft.original[schema.id] = schema;
        });

        draft.loading = false;
        draft.loaded = true;

        const projectSchema = find(
          action.payload,
          schema => schema.schemaName === "projects"
        );
        if (projectSchema) {
          draft.projectSchemaId = projectSchema.id;
        }
        break;
      }
      case getType(actions.loadSchemas.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(`Error loading schemas: ${action.payload.message}`);
        break;
      }

      case getType(actions.saveSchema.request): {
        draft.saving = true;
        draft.saved = false;
        break;
      }

      case getType(actions.saveSchema.success): {
        draft.original[action.payload.id] = action.payload;
        draft.workingCopy[action.payload.id] = action.payload;
        draft.saving = false;
        draft.saved = true;
        const currentIndex = draft.allIds.indexOf(action.payload.id);
        if (currentIndex === -1) {
          draft.allIds.push(action.payload.id);
        }
        break;
      }

      case getType(actions.saveSchema.failure): {
        draft.saving = false;
        draft.saved = false;
        break;
      }

      case getType(actions.updateSchemas): {
        const { schemas } = action.payload;
        schemas.forEach(schema => {
          const currentIndex = draft.allIds.indexOf(schema.id);

          if (schema.deleted) {
            if (currentIndex > -1) {
              draft.allIds.splice(currentIndex, 1);
            }
            if (schema.id in draft.workingCopy) {
              delete draft.workingCopy[schema.id];
            }
            if (schema.id in draft.original) {
              delete draft.original[schema.id];
            }
          } else {
            draft.workingCopy[schema.id] = schema;
            draft.original[schema.id] = schema;
            if (currentIndex === -1) {
              draft.allIds.push(schema.id);
            }
          }
        });
        break;
      }
      case getType(actions.resetSchema): {
        draft.workingCopy[action.payload.schemaId] =
          state.original[action.payload.schemaId];
        break;
      }
      case getType(actions.moveSchemaField): {
        const { original, destination, fieldId, schemaId } = action.payload;
        const sections = draft.workingCopy[action.payload.schemaId].sections;
        sections[original.sectionId].fields.splice(original.index, 1);
        sections[destination.sectionId].fields.splice(
          destination.index,
          0,
          fieldId
        );

        reorderFields(draft.workingCopy[schemaId]);
        break;
      }
      case getType(actions.addSchemaField): {
        const { destination, fieldType, schemaId, config } = action.payload;
        const schema = draft.workingCopy[schemaId];
        const newField: SchemaField = {
          id: uuid(),
          hiddenInForm: false,
          hiddenInTable: false,
          lookup: false,
          name: "",
          readOnly: false,
          required: false,
          standard: false,
          protected: false,
          adminOnly: false,
          type: fieldType,
          config: generateColumnConfig(fieldType, config)
        };

        schema.fields[newField.id] = newField;
        schema.sections[destination.sectionId].fields.splice(
          destination.index,
          0,
          newField.id
        );

        reorderFields(schema);
        break;
      }

      case getType(actions.saveSchemaField): {
        const { schemaId, field } = action.payload;
        const schema = draft.workingCopy[schemaId];
        schema.fields[field.id] = field;
        if (field.id === "hJJob") {
          // Job field is always hidden from the table
          const heavyJobFields = ["hJJob.Code", "hJJob.Description"];
          heavyJobFields.forEach(hJField => {
            schema.fields[hJField].hiddenInTable = field.hiddenInForm;
            schema.fields[hJField].hiddenInForm = true;
          });
          schema.fields["hJJob"].hiddenInTable = true;
        }
        break;
      }

      case getType(actions.saveDataSyncPreference): {
        const { schemaId, dataSyncPreference } = action.payload;
        const schema = draft.workingCopy[schemaId];
        schema.dataSyncPreference = dataSyncPreference;
        break;
      }

      case getType(actions.addSchemaFieldOption): {
        const { schemaId, fieldId, listValue } = action.payload;
        const field = draft.workingCopy[schemaId].fields[fieldId];
        if (field.config && field.config.listValues) {
          const values: string[] = field.config.listValues.map(
            (listObject: any) => listObject.value
          );
          if (!values.includes(listValue.value)) {
            field.config.listValues.push(listValue);
          }
        }
        break;
      }

      case getType(actions.removeSchemaField): {
        const { schemaId, fieldId } = action.payload;
        const schema = draft.workingCopy[schemaId];
        if (!schema.fields[fieldId].protected) {
          Object.keys(schema.sections).forEach(sectionId => {
            schema.sections[sectionId].fields = schema.sections[
              sectionId
            ].fields.filter(id => id !== fieldId);
          });

          delete schema.fields[fieldId];
        }
        break;
      }

      case getType(actions.saveSchemaSection): {
        const { schemaId, section } = action.payload;
        const schema = draft.workingCopy[schemaId];
        schema.sections[section.id] = section;
        break;
      }

      case getType(actions.removeSchemaSection): {
        const { schemaId, id } = action.payload;
        const schema = draft.workingCopy[schemaId];
        if (schema.sections[id].fields.length === 0) {
          delete schema.sections[id];
          schema.orderedSections = schema.orderedSections.filter(
            sectionId => sectionId !== id
          );
        }
        break;
      }

      case getType(actions.moveSchemaSection): {
        const sections =
          draft.workingCopy[action.payload.schemaId].orderedSections;
        sections.splice(action.payload.originalIndex, 1);
        sections.splice(
          action.payload.destinationIndex,
          0,
          action.payload.sectionId
        );
        break;
      }

      case getType(actions.addSchemaSection): {
        const { schemaId, index } = action.payload;
        const schema = draft.workingCopy[schemaId];
        const newSection: SchemaSection = {
          id: uuid(),
          name: "",
          fields: []
        };
        schema.sections[newSection.id] = newSection;
        schema.orderedSections.splice(index, 0, newSection.id);
        break;
      }
    }
  });
};

function reorderFields(schema: Schema) {
  // remove fields from all sections
  Object.values(schema.sections).forEach(section => {
    estimateLookupFields.forEach(fieldId => {
      const fieldIndex = section.fields.indexOf(fieldId);
      if (fieldIndex > -1) {
        section.fields.splice(fieldIndex, 1);
      }
    });

    bidResultsLookupFields.forEach(fieldId => {
      const fieldIndex = section.fields.indexOf(fieldId);
      if (fieldIndex > -1) {
        section.fields.splice(fieldIndex, 1);
      }
    });

    hJJobLookUpFields.forEach(fieldId => {
      const fieldIndex = section.fields.indexOf(fieldId);
      if (fieldIndex > -1) {
        section.fields.splice(fieldIndex, 1);
      }
    });
    //remove lastModifiedBy
    const fieldIndex = section.fields.indexOf(lastModifiedByFieldId);
    if (fieldIndex > -1) section.fields.splice(fieldIndex, 1);
  });

  // add estimate fields back in
  const estimateLocation = findField(schema, "estimates");
  if (estimateLocation) {
    const section = schema.sections[estimateLocation.sectionId];
    estimateLookupFields.forEach((fieldId, index) => {
      section.fields.splice(
        estimateLocation.fieldIndex + index + 1,
        0,
        fieldId
      );
    });
  }

  // add bid results fields back in
  const bidResultsLocation = findField(schema, "bidResults");
  if (bidResultsLocation) {
    const section = schema.sections[bidResultsLocation.sectionId];
    bidResultsLookupFields.forEach((fieldId, index) => {
      section.fields.splice(
        bidResultsLocation.fieldIndex + index + 1,
        0,
        fieldId
      );
    });
  }
  // add HeavyJob Job fields back in
  const hJJobFieldLocation = findField(schema, "hJJob");
  if (hJJobFieldLocation) {
    const section = schema.sections[hJJobFieldLocation.sectionId];
    hJJobLookUpFields.forEach((fieldId, index) => {
      section.fields.splice(
        hJJobFieldLocation.fieldIndex + index + 1,
        0,
        fieldId
      );
    });
  }
  //add lastModifiedBy field back in
  const lastModifiedLocation = findField(schema, "lastModified");
  if (lastModifiedLocation) {
    const section = schema.sections[lastModifiedLocation.sectionId];
    section.fields.splice(
      lastModifiedLocation.fieldIndex + 1,
      0,
      lastModifiedByFieldId
    );
  }

  return schema;
}

function findField(schema: Schema, fieldId: string) {
  const sections = Object.values(schema.sections);
  const section = sections.find(section =>
    section.fields.find(field => field === fieldId)
  );

  if (!section) {
    return undefined;
  }

  return {
    sectionId: section.id,
    fieldIndex: section.fields.findIndex(field => field === fieldId)
  };
}
