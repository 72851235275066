import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import { Schema, View } from "api";
import * as notify from "core/components/notify";
import {
  selectors as accountSelectors,
  actions as accountActions
} from "modules/account";
import { selectors as schemaSelectors } from "modules/schemas";
import {
  apiSagaFactory,
  getSelectedBusinessUnitIdSaga,
  getTokenSaga
} from "api/api-saga-factory";
import { actions, StateSlice } from "./state";
import { UserPreferences } from "api/index";
import { sortBy } from "lodash-es";
import { strings } from "localization";
import RestApi from "api/RestApi";
import { AxiosResponse } from "axios";
import ViewsApi from "api/ViewsApi";

const genericSagas = apiSagaFactory<View>({
  isBusinessUnitApi: true,
  apiPath: "/views",
  stateKey: "views",
  onLoadSuccess: data => actions.loadViews.success(data),
  onLoadFail: err => actions.loadViews.failure(err)
});

function* saveViews(action: ActionType<typeof actions.saveView.request>) {
  const { view, meta } = action.payload;

  if (meta?.setAsSelected && meta?.tableId) {
    yield put(actions.setSelectedView(meta.tableId, view.id));
  }

  try {
    const api: RestApi<View> = yield call(genericSagas.getApi);
    const data: AxiosResponse<View> = yield call(
      [api, meta?.isNewView ? api.create : api.update],
      view
    );
    const savedView = data.data;
    yield put(actions.saveView.success(savedView));
    meta?.isNewView ? notify.add(savedView.name) : notify.save(savedView.name);
    if (
      view.createdByUserId !== meta?.userId &&
      !view.isPublic &&
      meta?.tableId
    ) {
      yield put(actions.setDefaultView(view, meta?.tableId));
      yield put(actions.hideView(view.id));
    }
  } catch (error) {
    console.error(error);
    yield put(actions.saveView.failure(view));
    notify.error(
      strings.formatString(strings.views.errors.saveView, view.name) as string
    );
  }
}

function* claimView(action: ActionType<typeof actions.claimView.request>) {
  const { viewId } = action.payload;
  const token = yield call(getTokenSaga);
  const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);

  try {
    const api = new ViewsApi(token, businessUnitId);
    const data: AxiosResponse<View> = yield call(api.claimView, viewId);
    const savedView = data.data;
    yield put(actions.claimView.success(savedView));
    notify.save(savedView.name);
  } catch (error) {
    console.error(error);
    yield put(actions.claimView.failure(viewId));
    notify.error(strings.views.errors.claimView);
  }
}

function* deleteView(action: ActionType<typeof actions.deleteView.request>) {
  const viewId = action.payload.viewId;
  try {
    const api: RestApi<View> = yield call(genericSagas.getApi);
    const view: View = yield select(
      ({ views }: StateSlice) => views.original[viewId]
    );
    yield call([api, api.delete], viewId);
    yield put(actions.deleteView.success(viewId));
    yield put(actions.setDefaultView(view, action.payload.meta?.tableId ?? ""));
    notify.remove(view.name);
  } catch (error) {
    console.error(error);
    yield put(actions.deleteView.failure(viewId));
  }
}

function* setDefaultView(action: ActionType<typeof actions.setDefaultView>) {
  const { view, tableId } = action.payload;
  //Change Preferences when template with default deleted
  const userPreferences: UserPreferences = yield select(
    accountSelectors.getPreferences
  );

  const schemas: Record<string, Schema> = yield select(
    schemaSelectors.getSchemaHash
  );

  if (tableId && userPreferences?.tablePreferences?.[tableId]) {
    const tablePreferences = userPreferences.tablePreferences[tableId];
    if (tablePreferences.selectedView === view.id) {
      const views: Record<string, View> = yield select(
        ({ views }: StateSlice) => views.workingCopy
      );
      const values = Object.values(views);
      const viewsUnSorted = values.filter(
        v =>
          schemas[v.schemaId].schemaName === schemas[view.schemaId].schemaName
      );
      const viewsSorted = sortBy(viewsUnSorted, (view: View) =>
        view.name.toLocaleLowerCase()
      );
      yield put(
        actions.setSelectedView(tableId, viewsSorted[0].id) //always first if default project deleted
      );
      const { lastModified, ...existingPreferences } = userPreferences;
      yield put(
        accountActions.updatePreferences.request({
          ...existingPreferences,
          tablePreferences: {
            project: { id: tableId, selectedView: viewsSorted[0].id }
          }
        })
      );
    }
  }
}

export const sagas = [
  takeLatest(getType(actions.loadViews.request), genericSagas.load),
  takeEvery(getType(actions.saveView.request), saveViews),
  takeEvery(getType(actions.deleteView.request), deleteView),
  takeEvery(getType(actions.claimView.request), claimView),
  takeEvery(getType(actions.setDefaultView), setDefaultView)
];
