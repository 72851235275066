const NUM_ITEMS_PER_PAGE = 4;

export const calculateNumPages = (
  results: any[],
  numItemsPerPage = NUM_ITEMS_PER_PAGE
) => {
  return Math.ceil(results.length / numItemsPerPage);
};

export const getCurrentPage = (
  currentPage: number,
  results: any[],
  numItemsPerPage = NUM_ITEMS_PER_PAGE
) => {
  if (results.length === 0) return [];
  if (results.length <= numItemsPerPage) return results;
  const firstItemInCurrentPageIndex =
    numItemsPerPage * currentPage - results.length;
  if (firstItemInCurrentPageIndex > 0) {
    return results.slice(-(numItemsPerPage - firstItemInCurrentPageIndex));
  }
  return results.slice(
    (currentPage - 1) * numItemsPerPage,
    numItemsPerPage * currentPage
  );
};
