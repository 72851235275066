import React from "react";
import styled from "styled-components";
import {
  HeaderSection,
  Item,
  MenuSection
} from "core/components/hcss-navigation";
import { ConcreteColors } from "hcss-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { strings } from "localization";

export const ProfileDetailsContext = () => {
  const path = useLocation().pathname;

  return (
    <>
      <HeaderSection>
        {headerStyle => {
          return (
            <div style={headerStyle}>
              <HeaderContainer>Company Profile</HeaderContainer>
            </div>
          );
        }}
      </HeaderSection>
      <MenuSection>
        {menuStyle => (
          <ItemContainer style={menuStyle}>
            <Item
              data-testid="navitem-companyProfile-mainDetails"
              before={<FontAwesomeIcon icon={["fal", "building"]} size="1x" />}
              key="main-details"
              text={strings.layout.menu.contacts.mainDetails}
              isSelected={path === `/settings/profile`}
              to={`/settings/profile`}
            />
          </ItemContainer>
        )}
      </MenuSection>
    </>
  );
};

const HeaderContainer = styled.div`
  padding-bottom: 26px;
  padding-left: 12px;
  padding-top: 8px;
  font-size: 16px;
  font-weight: 700;
  color: ${ConcreteColors.gray600};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ItemContainer = styled.div`
  font-size: 14px;
  margin-right: 14px;
  color: ${ConcreteColors.gray600};
`;
