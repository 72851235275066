import constate from "constate";
import { CommunicationLogRepo } from "../../quote-management/repository/communication-log-repo";
import { useState, useEffect } from "react";
import { useCurrentEstimateContext } from "../../estimates/context/CurrentEstimateContext";
import { CommunicationLogEntity } from "core";
import { useHcssUser } from "modules/account";
import { onSnapshot } from "firebase/firestore";

export interface VendorCommunicationContext {
  vendorCommunicationLogs: CommunicationLogEntity[];
  loading: boolean;
}
export const useVendorCommunication = (): VendorCommunicationContext => {
  const { companyId } = useHcssUser();
  const { currentEstimateId } = useCurrentEstimateContext();
  const [loading, setLoading] = useState(false);
  const [communicationLogs, setCommunicationLogs] = useState<
    CommunicationLogEntity[]
  >([]);

  useEffect(() => {
    const subscribe = () => {
      setLoading(true);

      const q = CommunicationLogRepo.subscribe(companyId, currentEstimateId);
      const unsubscribe = onSnapshot(
        q,
        snapshots => {
          const results: any[] = [];
          snapshots.forEach(doc => results.push({ ...doc.data(), id: doc.id }));
          setCommunicationLogs(results);
          setLoading(false);
        },
        error => {
          console.error(error);
          setLoading(false);
        }
      );

      return () => unsubscribe();
    };

    if (companyId) return subscribe();
  }, [currentEstimateId, companyId]);

  return {
    vendorCommunicationLogs: communicationLogs,
    loading: loading
  };
};

const [provider, context] = constate(useVendorCommunication);
export const VendorCommunicationProvider = provider;
export const useVendorCommunicationContext = context;
