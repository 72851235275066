import constate from "constate";
import { useState, useEffect, useMemo } from "react";
import { useCurrentEstimateContext } from "../../estimates/context/CurrentEstimateContext";
import { useHcssToken, useHcssUser } from "modules/account";
import { VendorSolicitationRepo } from "../repository/vendor-solicitation-repo";
import { MasterSolicitationRepo } from "../repository/master-solicitation-repo";
import { onSnapshot } from "firebase/firestore";

import {
  VendorSolicitationStatus,
  VendorSolicitationEntity,
  MasterSolicitationEntity
} from "core";
import { EstimateHistoryApi } from "api/EstimateHistoryApi";
import { EstimateSyncStatusDto } from "api/GeneratedClients/EstimateHistoryClient";

export interface SolicitationContext {
  outboundVendorSolicitations: VendorSolicitationEntity[];
  inboundVendorSolicitations: VendorSolicitationEntity[];
  masterSolicitations: MasterSolicitationEntity[];
  outboundNewSolicitationCount: number;
  loading: boolean;
  estimatesSyncStatus: EstimateSyncStatusDto[];
}
const useSolicitations = (): SolicitationContext => {
  const master = useMasterSolicitations();
  const outbound = useOutboundSolicitations();
  const inbound = useInboundSolicitations();
  const accessToken = useHcssToken();
  const [estimatesSyncStatus, setEstimatesSyncStatus] = useState<
    EstimateSyncStatusDto[]
  >([]);

  async function getEstimatesSyncStatus() {
    const api = new EstimateHistoryApi(accessToken);
    try {
      const syncStatus = await api.quoteMasters_GetEstimatesPriceSyncStatus();
      setEstimatesSyncStatus(syncStatus);
      return syncStatus;
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    void getEstimatesSyncStatus();
  }, []);

  const outboundNewSolicitationCount = useMemo(
    () =>
      outbound.solicitations.filter(
        v => v.status === VendorSolicitationStatus.SentToGC
      ).length,
    [outbound.solicitations]
  );

  return {
    outboundNewSolicitationCount,
    estimatesSyncStatus,
    outboundVendorSolicitations: outbound.solicitations,
    inboundVendorSolicitations: inbound.solicitations,
    masterSolicitations: master.solicitations,
    loading: outbound.loading || inbound.loading || master.loading
  };
};

const useMasterSolicitations = () => {
  const { companyId } = useHcssUser();
  const { currentEstimateId } = useCurrentEstimateContext();
  const [loading, setLoading] = useState(false);
  const [solicitations, setSolicitations] = useState<
    MasterSolicitationEntity[]
  >([]);

  useEffect(() => {
    const subscribe = () => {
      setLoading(true);

      const q = MasterSolicitationRepo.subscribe(companyId, currentEstimateId);
      const unsubscribe = onSnapshot(
        q,
        snapshots => {
          const results: any[] = [];
          snapshots.forEach(doc => results.push({ ...doc.data(), id: doc.id }));
          setSolicitations(results);
          setLoading(false);
        },
        error => {
          console.error(error);
          setLoading(false);
        }
      );

      return () => unsubscribe();
    };

    if (companyId) return subscribe();
  }, [companyId, currentEstimateId]);

  return {
    loading,
    solicitations
  };
};

const useOutboundSolicitations = () => {
  const { companyId } = useHcssUser();
  const { currentEstimateId } = useCurrentEstimateContext();
  const [loading, setLoading] = useState(false);
  const [solicitations, setSolicitations] = useState<
    VendorSolicitationEntity[]
  >([]);

  useEffect(() => {
    const subscribe = () => {
      setLoading(true);

      const q = VendorSolicitationRepo.getOutboundSubscription(
        companyId,
        currentEstimateId
      );
      const unsubscribe = onSnapshot(
        q,
        snapshots => {
          const results: any[] = [];
          snapshots.forEach(doc => results.push({ ...doc.data(), id: doc.id }));
          setSolicitations(results);
          setLoading(false);
        },
        error => {
          console.error(error);
          setLoading(false);
        }
      );

      return () => unsubscribe();
    };

    if (companyId) return subscribe();
  }, [companyId, currentEstimateId]);

  return {
    loading,
    solicitations
  };
};

const useInboundSolicitations = () => {
  const { companyId } = useHcssUser();
  const { currentEstimateId } = useCurrentEstimateContext();
  const [loading, setLoading] = useState(false);
  const [solicitations, setSolicitations] = useState<
    VendorSolicitationEntity[]
  >([]);

  useEffect(() => {
    const subscribe = () => {
      setLoading(true);

      const q = VendorSolicitationRepo.getInboundSubscription(companyId);
      const unsubscribe = onSnapshot(
        q,
        snapshots => {
          const results: any[] = [];
          snapshots.forEach(doc => results.push({ ...doc.data(), id: doc.id }));
          setSolicitations(results);
          setLoading(false);
        },
        error => {
          console.error(error);
          setLoading(false);
        }
      );

      return () => unsubscribe();
    };

    if (companyId) return subscribe();
  }, [companyId, currentEstimateId]);

  return {
    loading,
    solicitations
  };
};

const [provider, context] = constate(useSolicitations);
export const SolcitationProvider = provider;
export const useSolicitationContext = context;
