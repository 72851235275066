import React, { SetStateAction, Dispatch } from "react";
import { Modal, Button, notify, Icon } from "hcss-components";
import { FormGroup, TextField } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { useHistory } from "react-router-dom";
import {
  actions as contactsActions,
  selectors as contactsSelectors
} from "modules/contacts";
import { useDispatch, useSelector } from "react-redux";
import {
  Address,
  CommunicationMethod,
  IAddress,
  IVendorDtoV1Response,
  VendorDtoV1Response
} from "api/GeneratedClients/ContactsClient";
import * as Yup from "yup";
import styled from "styled-components";
import MuiGrid from "@mui/material/Grid";
import { strings } from "localization";

export enum CreateCompanyMode {
  Stay,
  Redirect
}

interface HandlerProps {
  code: string;
  companyName: string;
  formik: FormikProps<{ code: string; companyName: string }>;
}
export const AddVendorModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const showModal = useSelector(contactsSelectors.getShowModal);
  const vendorCodeList = useSelector(contactsSelectors.getVendorCodeList);

  const onCancelHandler = () => {
    dispatch(contactsActions.setShowModal(false));
  };
  const onSaveHandler = ({ code, companyName, formik }: HandlerProps) => {
    code = code ? code.toUpperCase().trim() : code;
    formik
      .validateForm(formik.values)
      .then(values => {
        const isNoValidationErrors = Object.keys(values).length === 0;
        if (isNoValidationErrors) {
          dispatch(
            contactsActions.createVendor.request({
              vendor: initializeVendor(code, companyName),
              history: history,
              mode: CreateCompanyMode.Stay
            })
          );
        }
      })
      .catch(e => console.log(e));
  };

  const onSaveAndEditHandler = ({
    code,
    companyName,
    formik
  }: HandlerProps) => {
    code = code ? code.toUpperCase().trim() : code;
    formik
      .validateForm(formik.values)
      .then(values => {
        const isNoValidationErrors = Object.keys(values).length === 0;
        if (isNoValidationErrors) {
          dispatch(
            contactsActions.createVendor.request({
              vendor: initializeVendor(code, companyName),
              history: history,
              mode: CreateCompanyMode.Redirect
            })
          );
        }
      })
      .catch(e => console.log(e));
  };

  const YupValidationSchema = Yup.object({
    code: Yup.string()
      .required("Code is required")
      .test(
        "duplicate-code-not-allowed",
        "Duplicate Code is not allowed",
        value => {
          if (value) value = value.toUpperCase();
          else if (value === undefined) value = "";
          return !vendorCodeList.some(vendor => vendor.trim() === value.trim());
        }
      )
  });

  return (
    <Modal
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onHide={() => {}}
      show={showModal}
    >
      <Formik
        enableReinitialize
        initialValues={{ code: "", companyName: "" }}
        validationSchema={YupValidationSchema}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit={() => {}}
      >
        {formik => (
          <Form>
            <Modal.Header>
              <div></div>
              <MuiGrid container justifyContent="space-between">
                <MuiGrid item>
                  <Modal.Title>
                    {strings.contactManagement.addCompanyModal.title}
                  </Modal.Title>
                </MuiGrid>
                <MuiGrid item>
                  <TimesButton
                    type="button"
                    className="close"
                    onClick={() => {
                      onCancelHandler();
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </TimesButton>
                </MuiGrid>
              </MuiGrid>
            </Modal.Header>
            <Modal.Body>
              <FormGroup>
                <TextField
                  id="code"
                  margin="normal"
                  label={strings.contactManagement.addCompanyModal.companyCode}
                  required
                  {...formik.getFieldProps("code")}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  error={!!formik.errors.code}
                  helperText={formik.errors.code}
                />
                <TextField
                  id="companyName"
                  margin="normal"
                  label={strings.contactManagement.addCompanyModal.companyName}
                  {...formik.getFieldProps("companyName")}
                />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <MuiGrid container justifyContent="space-between" xs={12}>
                <MuiGrid item>
                  <Button
                    hcssStyle="ThemeInverted"
                    onClick={() => onCancelHandler()}
                  >
                    <Icon name="times" margin="right" />
                    {strings.contactManagement.addCompanyModal.cancel}
                  </Button>
                </MuiGrid>
                <MuiGrid item>
                  <Button
                    onClick={() => onSaveHandler({ ...formik.values, formik })}
                  >
                    <Icon name="floppy-o" margin="right" />
                    {strings.contactManagement.addCompanyModal.save}
                  </Button>
                  <Button
                    onClick={() =>
                      onSaveAndEditHandler({ ...formik.values, formik })
                    }
                  >
                    <Icon name="floppy-o" margin="right" />
                    {strings.contactManagement.addCompanyModal.saveAnd}
                    <Icon name="pencil" margin="both" />
                    {strings.contactManagement.addCompanyModal.Edit}
                  </Button>
                </MuiGrid>
              </MuiGrid>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const initializeVendor = (code: string, companyName: string) => {
  const vendor: IVendorDtoV1Response = {
    id: "",
    code: code,
    name: companyName,
    type: undefined,
    primaryAddress: initializeAddress(),
    alternateAddress: initializeAddress(),
    phoneNumber: "",
    faxNumber: "",
    webAddress: "",
    region: undefined,
    communicationMethod: CommunicationMethod.None,
    isBonded: false,
    bondRate: 0,
    note: "",
    minorityStatuses: [],
    products: [],
    contacts: []
  };
  return new VendorDtoV1Response(vendor);
};

const initializeAddress = () => {
  const address: IAddress = {
    addressLine1: "",
    addressLine2: "",
    building: "",
    city: "",
    stateProvince: "",
    countryRegion: "",
    postalCode: ""
  };
  return new Address(address);
};
const TimesButton = styled.button`
  button.close {
    color: "Black")};
    opacity: 0.4;
    font-size: 28px;
    :hover {
      opacity: 0.9;
  }
}
`;
