import { currentEnvironment, Environment } from "../config";

export function shouldLoadDevTools() {
  // this allows you to explicitly disable it in development for example
  const explicitlyDisabled =
    window.location.search.includes("dev-tools=false") ||
    window.localStorage.getItem("dev-tools") === "false";
  const explicitlyEnabled =
    window.location.search.includes("dev-tools=true") ||
    window.localStorage.getItem("dev-tools") === "true";
  // we want it enabled by default everywhere but production and we also want
  // to support the dev tools in production (to make us more productive triaging production issues).
  // you can enable the DevTools via localStorage or the query string.

  if (explicitlyDisabled) return false;
  if (explicitlyEnabled) return true;
  if (
    currentEnvironment !== Environment.Production &&
    currentEnvironment !== Environment.PreProd
  )
    return true;

  return false;
}
