import React, { useEffect, useRef, useState } from "react";
import { SchemaFormFieldInputProps } from "./common";
import { preconIdToString, parsePreConId, PreConId } from "modules/projects";
import { useAuthorization, usePermissions } from "modules/account";

export interface PreConIdInputBaseProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "value" | "onChange" | "disabled"
  > {
  value: PreConId | null;
  onChange: (value: PreConId | null) => void;
  disabled?: boolean;
}

export const PreConIdInputBase = ({
  value,
  onChange,
  disabled,
  ...other
}: PreConIdInputBaseProps) => {
  const [cursor, setCursor] = useState(null);
  const ref = useRef<HTMLInputElement>(null);

  //const handleChange = (stringValue: string, uppercase: boolean) => {
  //  const normalizedValue = uppercase ? stringValue.toUpperCase() : stringValue;
  //  const parsedId = parsePreConId(normalizedValue);
  //  onChange(parsedId);
  //};

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value]);

  const handleChange = (e: any) => {
    setCursor(e.target.selectionStart);
    const parsedId = parsePreConId(e.target.value);
    onChange(parsedId);
  };

  return (
    <input
      ref={ref}
      value={preconIdToString(value)}
      className="form-control"
      type="text"
      onChange={handleChange}
      disabled={disabled}
      {...other}
    />
  );
};

export const PreConIdInput = (props: SchemaFormFieldInputProps) => {
  const {
    form: { setFieldValue },
    field,
    schemaField: { adminOnly },
    isLocked
  } = props;

  const permissions = usePermissions();
  const authorization = useAuthorization();
  const adminFailure = !permissions.admin && adminOnly;
  const fieldIsEditable =
    !isLocked &&
    permissions.write &&
    authorization.canAccessLimitedPrecon &&
    !adminFailure;

  return (
    <PreConIdInputBase
      {...field}
      disabled={!fieldIsEditable}
      value={field.value}
      onChange={(preconId: PreConId | null) =>
        setFieldValue("preconId", preconId)
      }
    />
  );
};
