import { ReportType } from "api/GeneratedClients/insights";

export const getPathForEstimate = (estimateId: string) =>
  `/estimates/${estimateId}`;

export const getPathForFolder = (estimateId: string, folderId: string) =>
  `/estimates/${estimateId}/folders/${folderId}`;

export const getPathForQuote = (
  estimateId: string,
  vendorSolicitationId: string
) => `/estimates/${estimateId}/quotes/${vendorSolicitationId}`;

export const getPathForSolicitation = (
  estimateId: string,
  solicitationId: string
) => `/estimates/${estimateId}/solicitation/details/${solicitationId}`;

export const getQuoteSolicitationPath = (solicitationId: string) => `/quotes/solicitations/master/${solicitationId}`;

export const getPathForVendorCommunication = (
  estimateId: string,
  vendorCode: string
) => `/estimates/${estimateId}/communication/${vendorCode}`;

export const getPathForQMVendorCommunication = (
  projectId: string,
  vendorCode: string
) => `/quotes/${projectId}/communication/${vendorCode}`;

export const getPathForReportType = (
  estimateId: string,
  reportType: string | ReportType
) => `/estimates/${estimateId}/reporting/${reportType}`;

export const getPathForIndicators = (estimateId: string) => `/estimates/${estimateId}/indicators`

export const paths = {
  base: "/estimates",
  codebooks: "/estimates/codebooks",
  list: "/estimates/list",
  edit: "/estimates/edit",
  detail: getPathForEstimate(":estimateId"),
  indicators: getPathForIndicators(":estimateId"),
  reporting: getPathForReportType(":estimateId", ":reportType"),
  reports: "/estimates/:estimateId/reports",
  materials: "/estimates/:estimateId/materials",
  estimateDashbaord: "/estimates/:estimateId/dashboard",
  customizeReporting: "/estimates/customize",
  quoteSummary: "/estimates/:estimateId/quotes/summary",
  quoteInbox: "/estimates/:estimateId/quotes/inbox",
  quoteDetails: getPathForQuote(":estimateId", ":solicitationId"),
  folderDetails: getPathForFolder(":estimateId", ":folderId"),
  solicitationDetails: "/estimates/:estimateId/solicitation/details/:solicitationId?",
  proposalDetails: "/estimates/:estimateId/proposal/details",
  masterSolicitationTable: "/estimates/:estimateId/solicitation/:filter(drafts|sent)",
  vendorCommunications: "/estimates/:estimateId/communication",
  vendorCommunicationsDetails: "/estimates/:estimateId/communication/:vendorCode",
  lookup: "/estimates/lookup",
};
