import styled from "styled-components";

export const DownloadButton = styled.button`
  background-color: white;
  border: none;
  padding: none;
  color: #005eb8;

  & :disabled {
    color: #707070;
  }
`;

// export interface DownloadButtonProps {
//   onClick?: () => void;
//   disabled?: boolean;
//   showText?: boolean;
// }

// export default function DownloadButton({
//   onClick,
//   disabled,
//   showText
// }: DownloadButtonProps) {
//   if (disabled) {
//     onClick = undefined;
//   }
//   return (
//     <Link
//       onClick={onClick}
//       style={{ color: disabled ? "#DDD" : undefined }}
//       disabled={disabled}
//     >
//       <Icon name="arrow-circle-o-down" margin="right" />
//       {showText && "Download"}
//     </Link>
//   );
// }
