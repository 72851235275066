import { Button, Icon } from "hcss-components";
import * as React from "react";
import { strings } from "localization";
import styled from "styled-components";

export interface DeleteButtonProps {
  onClick?: () => void;
  showText?: boolean;
  disabled?: boolean;
  testId?: string;
  children?: React.ReactNode;
}

export default function DeleteButton({
  onClick,
  disabled,
  showText,
  testId = "Button",
  children
}: DeleteButtonProps) {
  return (
    <StyledButton
      testId={testId}
      data-testid={testId}
      hcssStyle="DeleteInverted"
      disabled={disabled}
      onClick={onClick}
      style={{ margin: "auto 0" }}
    >
      <Icon name="trash-o" margin="right" size={100} disabled={disabled} />
      <div>{children || (showText && strings.core.buttons.delete)}</div>
    </StyledButton>
  );
}

const StyledButton = styled(Button)`
  align-items: baseline;
  border: none !important;
  background-color: transparent !important;
  font-weight: unset !important;
  text-transform: capitalize !important;
  font-size: 1.5rem !important;
  letter-spacing: normal !important;
  padding: 0 !important;
  div:hover {
    text-decoration: underline;
  }
  i:hover {
    text-decoration: none;
  }
  i {
    font-size: initial;
  }
`;
