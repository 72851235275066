import { onAuthStateChanged } from "firebase/auth";
import { User as FirebaseUser } from "firebase/auth";
import { auth } from "firebase-app";
import { getType, ActionType } from "typesafe-actions";
import { eventChannel } from "redux-saga";
import { oidcService } from "../services/oidc-service";
import { actions, StateSlice } from "../state";
import { call, put, takeEvery, take, fork, select } from "redux-saga/effects";
import { UserDto } from "api/GeneratedClients/precon";
import { User } from "oidc-client";

import {
  GuestUserClaims,
  AuthenticationMethod,
  getUserClaimsFromProfile
} from "core";

import {
  createHcssUser,
  getUserInfo,
  createHcssUserPayload,
  createGuestUser,
  createGuestUserPayload
} from "./helpers";

import { isSandboxEnabled } from "core/util/sandbox";

export function* updateOidcUserState({
  payload: oidcUser
}: ActionType<typeof actions.updateOidcUser>) {
  const firUser: FirebaseUser | undefined = yield select(
    ({ account }: StateSlice) => account.firUser
  );

  yield call(publishHcssUser, oidcUser, firUser);
}

export function* startAuthListener() {
  const channel = yield call(createAuthListener);
  yield fork(cancelAuthListener, channel);

  try {
    yield takeEvery(channel, handleFirebaseAuthState);
  } catch (error) {
    console.error(error);
    yield put(actions.unsubscribeFromAuthState());
  }
}

export function* cancelAuthListener(channel: any) {
  yield take(getType(actions.unsubscribeFromAuthState));
  channel.close();
}

function createAuthListener() {
  return eventChannel(emitter => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      emitter({ user });
    });

    return () => {
      unsubscribe();
    };
  });
}

function* handleFirebaseAuthState({ user: firUser }: { user?: FirebaseUser }) {
  const method: AuthenticationMethod = yield select(
    ({ account }: StateSlice) => account.authenticationMethod
  );

  if (method === AuthenticationMethod.OneClickLink) {
    yield call(updateGuestUserState, firUser);
  } else if (method === AuthenticationMethod.HcssCredentials) {
    const oidcUser: User | null = yield call([
      oidcService,
      oidcService.getUser
    ]);

    if (oidcUser) yield call(publishHcssUser, oidcUser, firUser);
    else yield put(actions.updateUserAuthState(undefined));
  }
}

function* updateGuestUserState(firUser?: FirebaseUser) {
  if (!firUser) {
    yield put(actions.updateGuestAuthState(undefined));
    return;
  }

  const decoded = yield call([firUser, firUser.getIdTokenResult]);
  if (!decoded) {
    yield put(actions.updateGuestAuthState(undefined));
    return;
  }

  const claims = decoded.claims as GuestUserClaims;
  const user = createGuestUser(claims);
  const payload = createGuestUserPayload(user, firUser, claims);
  yield put(actions.updateGuestAuthState(payload));
}

function* publishHcssUser(oidcUser: User, firUser?: FirebaseUser) {
  if (!firUser && !isSandboxEnabled()) {
    yield put(actions.updateUserAuthState(undefined));
    return;
  }

  const claims = getUserClaimsFromProfile(oidcUser.profile);
  const userInfo: UserDto = yield call(
    getUserInfo,
    oidcUser.access_token,
    claims.hcssUserId,
    claims.businessUnitName
  );

  const user = createHcssUser(
    oidcUser.access_token,
    claims,
    userInfo.businessUnit,
    userInfo.accessibleBusinessUnits,
    userInfo.userName
  );

  const payload = createHcssUserPayload(user, firUser, userInfo, claims);
  yield put(actions.updateUserAuthState(payload));
}
