import axios, { AxiosInstance, AxiosPromise } from "axios";
import { JobCostTypeCostDto, JobDto } from ".";

export default class HeavyJobApi {
  apiVersion = 1;
  baseUrl = `/api/v${this.apiVersion}`;
  instance: AxiosInstance;

  constructor(token: string) {
    const instance = axios.create({
      baseURL: this.baseUrl
    });

    // Set the AUTH token for any request
    instance.interceptors.request.use(config => {
      const auth = token ? `Bearer ${token}` : "";
      config.headers.common.Authorization = auth;
      return config;
    });

    instance.interceptors.response.use(
      response => response,
      error => {
        console.error(error);
        return Promise.reject(error);
      }
    );

    this.instance = instance;
  }

  getJobs = (businessUnitId: string): AxiosPromise<JobDto[]> => {
    return this.instance.get(`/businessUnits/${businessUnitId}/jobs`);
  };

  getJobCostTypeCosts = (
    businessUnitId: string,
    jobIds: string[] = []
  ): AxiosPromise<JobCostTypeCostDto[]> => {
    const query = new URLSearchParams();

    jobIds.forEach(id => query.append("jobIds", id));

    return this.instance.get(
      `/businessUnits/${businessUnitId}/jobs/metrics?${query.toString()}`
    );
  };
}
