import {
  BusinessUnitReadDto,
  CredentialsUserDto,
  EmailSubscriptionTemplatesDto
} from "api/GeneratedClients/precon";
import produce from "immer";
import {
  ActionType,
  createAction,
  createAsyncAction,
  getType
} from "typesafe-actions";
import {
  ValidationResult,
  validateSubscription
} from "./common/validation-helper";

export interface Subscription {
  id: string;
  name?: string;
  description?: string;
  emailSubject?: string;
  status: SubscriptionStatus;
  viewIds: string[];
  recipientIds: string[];
  settings: SubscriptionSettings;
}

export enum SubscriptionStatus {
  PENDING = 0,
  ACTIVE,
  PAUSED,
  STOPPED
}

export type DayOfWeek =
  | "sunday"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday";
export interface SubscriptionSettings {
  scheduledTimes: Partial<Record<DayOfWeek, string>>;
  timeZoneId: string;
}

export const STATE_KEY = "emailSubscriptions";

export interface StateSlice {
  [STATE_KEY]: State;
}
export interface State {
  projectTemplates: EmailSubscriptionTemplatesDto;
  recipients: CredentialsUserDto[];
  businessUnits: BusinessUnitReadDto[];
  workingCopies: Subscription[];
  originals: Subscription[];
  isLoading: boolean;
  errors: string[];
}
export type SubscriptionActions = ActionType<typeof actions>;

export const actions = {
  // ASYNC ACTIONS
  loadDataForSubscriptionPage: createAsyncAction(
    "SUBSCRIPTIONS/LOAD_DATA_REQUEST",
    "SUBSCRIPTIONS/LOAD_DATA_SUCCESS",
    "SUBSCRIPTIONS/LOAD_DATA_FAILURE"
  )<void, void, { error: Error }>(),

  loadSubscriptions: createAsyncAction(
    "SUBSCRIPTIONS/LOAD_REQUEST",
    "SUBSCRIPTIONS/LOAD_SUCCESS",
    "SUBSCRIPTIONS/LOAD_FAILURE"
  )<void, Subscription[], { error: Error }>(),

  loadTemplates: createAsyncAction(
    "SUBSCRIPTIONS/LOAD_PROJECT_TEMPLATES_REQUEST",
    "SUBSCRIPTIONS/LOAD_PROJECT_TEMPLATES_SUCCESS",
    "SUBSCRIPTIONS/LOAD_PROJECT_TEMPLATES_FAILURE"
  )<void, EmailSubscriptionTemplatesDto, { error: Error }>(),

  loadRecipients: createAsyncAction(
    "SUBSCRIPTIONS/LOAD_RECIPIENTS_REQUEST",
    "SUBSCRIPTIONS/LOAD_RECIPIENTS_SUCCESS",
    "SUBSCRIPTIONS/LOAD_RECIPIENTS_FAILURE"
  )<void, CredentialsUserDto[], { error: Error }>(),

  loadBusinessUnits: createAsyncAction(
    "SUBSCRIPTIONS/LOAD_BUSINESS_UNITS_REQUEST",
    "SUBSCRIPTIONS/LOAD_BUSINESS_UNITS_SUCCESS",
    "SUBSCRIPTIONS/LOAD_BUSINESS_UNITS_FAILURE"
  )<void, BusinessUnitReadDto[], { error: Error }>(),

  saveSubscription: createAsyncAction(
    "SUBSCRIPTIONS/SAVE_REQUEST",
    "SUBSCRIPTIONS/SAVE_SUCCESS",
    "SUBSCRIPTIONS/SAVE_FAILURE"
  )<
    {
      subscription: Subscription;
      setPristineSubscription: React.Dispatch<
        React.SetStateAction<Subscription>
      >;
      setValidationResult: React.Dispatch<
        React.SetStateAction<ValidationResult>
      >;
      isNewSubscription: boolean;
    },
    Subscription,
    { error: Error }
  >(),

  pauseSubscription: createAsyncAction(
    "SUBSCRIPTIONS/PAUSE_REQUEST",
    "SUBSCRIPTIONS/PAUSE_SUCCESS",
    "SUBSCRIPTIONS/PAUSE_FAILURE"
  )<
    {
      subscriptionId: string;
    },
    void,
    { error: Error }
  >(),

  resumeSubscription: createAsyncAction(
    "SUBSCRIPTIONS/RESUME_REQUEST",
    "SUBSCRIPTIONS/RESUME_SUCCESS",
    "SUBSCRIPTIONS/RESUME_FAILURE"
  )<
    {
      subscriptionId: string;
    },
    void,
    { error: Error }
  >(),

  deleteSubscription: createAsyncAction(
    "SUBSCRIPTIONS/DELETE_REQUEST",
    "SUBSCRIPTIONS/DELETE_SUCCESS",
    "SUBSCRIPTIONS/DELETE_FAILURE"
  )<
    { deletingSubscription: Subscription },
    { deletingId: string },
    { error: Error }
  >(),
  addNewSubscription: createAction("SUBSCRIPTIONS/ADD_NEW", resolve => {
    return (newSubscription: Subscription) => resolve(newSubscription);
  }),
  updateSubscription: createAction("SUBSCRIPTIONS/UPDATE", resolve => {
    return (
      newSubscription: Subscription,
      setValidationResult: React.Dispatch<
        React.SetStateAction<ValidationResult>
      >
    ) => resolve({ newSubscription, setValidationResult });
  }),
  removeSubscription: createAction("SUBSCRIPTIONS/REMOVE", resolve => {
    return (deletingId: string) => resolve(deletingId);
  }),
  resetToOriginals: createAction(
    "SUBSCRIPTIONS/RESET_TO_ORIGINALS",
    resolve => {
      return () => resolve();
    }
  ),
  updateStatus: createAction("SUBSCRIPTIONS/UPDATE_STATUS", resolve => {
    return (subscriptionId: string, status: SubscriptionStatus) =>
      resolve({ subscriptionId, status });
  })
};

const initialState: State = {
  projectTemplates: {
    hiddenTemplates: [],
    visibleTemplates: []
  },
  recipients: [],
  businessUnits: [],
  workingCopies: [],
  originals: [],
  isLoading: false,
  errors: []
};

export const reducer = (state = initialState, action: SubscriptionActions) => {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.loadSubscriptions.request):
        draft.isLoading = true;
        break;

      case getType(actions.loadDataForSubscriptionPage.failure):
      case getType(actions.loadSubscriptions.failure):
      case getType(actions.loadTemplates.failure):
      case getType(actions.loadRecipients.failure):
      case getType(actions.loadBusinessUnits.failure):
      case getType(actions.saveSubscription.failure):
      case getType(actions.deleteSubscription.failure):
        const { error } = action.payload;
        draft.isLoading = false;
        draft.errors.push(`Error: ${error}`);
        break;

      case getType(actions.loadSubscriptions.success):
        draft.workingCopies = action.payload;
        draft.originals = action.payload;
        draft.isLoading = false;
        break;

      case getType(actions.loadTemplates.success):
        draft.projectTemplates = action.payload;
        break;

      case getType(actions.loadRecipients.success):
        draft.recipients = action.payload;
        break;

      case getType(actions.loadBusinessUnits.success):
        draft.businessUnits = action.payload;
        break;

      case getType(actions.saveSubscription.success):
        const savedSub = action.payload;
        draft.workingCopies = draft.workingCopies.map(sub => {
          if (sub.id === savedSub.id) return savedSub;
          return sub;
        });
        draft.originals = draft.workingCopies;
        draft.isLoading = false;
        break;

      case getType(actions.deleteSubscription.success):
        const deletingId = action.payload.deletingId;
        draft.workingCopies = draft.workingCopies.filter(
          sub => sub.id !== deletingId
        );
        draft.originals = draft.originals.filter(sub => sub.id !== deletingId);
        break;

      case getType(actions.addNewSubscription):
        const newSub = action.payload;
        draft.workingCopies.unshift(newSub);
        break;

      case getType(actions.removeSubscription): {
        const deletingId = action.payload;
        draft.workingCopies = draft.workingCopies.filter(
          copy => copy.id !== deletingId
        );
        break;
      }

      case getType(actions.updateSubscription):
        const updatedSub = action.payload.newSubscription;
        const setValidationResult = action.payload.setValidationResult;
        draft.workingCopies = draft.workingCopies.map(sub => {
          const { isFieldValid, fieldErrorMessages } = validateSubscription(
            updatedSub,
            draft.workingCopies.filter(sub => sub.id !== updatedSub.id),
            state.recipients
          );
          setValidationResult({
            isFieldValid,
            fieldErrorMessages
          });
          if (sub.id === updatedSub.id) return updatedSub;
          return sub;
        });
        break;
      case getType(actions.resetToOriginals):
        draft.workingCopies = draft.originals;
        break;
      case getType(actions.updateStatus):
        draft.workingCopies = draft.workingCopies.map(sub => {
          if (sub.id === action.payload.subscriptionId)
            return { ...sub, status: action.payload.status };
          return sub;
        });
        break;
    }
  });
};

export type SelectorState = StateSlice;

// Selectors
const getProjectTemplates = ({ emailSubscriptions }: SelectorState) =>
  emailSubscriptions.projectTemplates;
const getRecipients = ({ emailSubscriptions }: SelectorState) =>
  emailSubscriptions.recipients;
const getBusinessUnits = ({ emailSubscriptions }: SelectorState) =>
  emailSubscriptions.businessUnits;
const getSubscriptions = ({ emailSubscriptions }: SelectorState) =>
  emailSubscriptions.workingCopies;
const getPristineSubscriptions = ({ emailSubscriptions }: SelectorState) =>
  emailSubscriptions.originals;
const getErrors = ({ emailSubscriptions }: SelectorState) =>
  emailSubscriptions.errors;
const getIsLoading = ({ emailSubscriptions }: SelectorState) =>
  emailSubscriptions.isLoading;

export const selectors = {
  getProjectTemplates,
  getRecipients,
  getBusinessUnits,
  getSubscriptions,
  getPristineSubscriptions,
  getErrors,
  getIsLoading
};
