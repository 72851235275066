export function initializePrintHelper() {
  window.addEventListener("beforeprint", () => {
    // add a class of print to the body tag so that the styles are applied
    const isProjectListDisplayed = document.querySelector(
      '[data-testid="projects-list"]'
    );
    if (isProjectListDisplayed) {
      const map = document.querySelector(
        '[data-testid="projects-map-container"]'
      );
      if (map) {
        map.classList.add("display-none");
        map.closest("react-grid-item")?.classList.add("display-none");
      }
      document.body.classList.add("print");
    }
  });
  window.addEventListener("afterprint", () => {
    document.body.classList.remove("print");

    const map = document.querySelector(
      '[data-testid="projects-map-container"]'
    );
    if (map) {
      map.closest("react-grid-item")?.classList.remove("display-none");
      map.classList.remove("display-none");
    }
  });
}
