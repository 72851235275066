import { takeEvery, call, put, takeLatest } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import { DetailedPayItemLibrary, PayItemLibrary, actions } from "./state";
import * as notify from "core/components/notify";
import { strings } from "localization";
import { WithId } from "core";
import RestApi from "api/RestApi";
import {
  QuickPriceSheetCreateDto,
  QuickPriceSheetReadDto
} from "modules/projects/components/quick-pricing/models/quick-price-sheet.model";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  apiSagaFactory,
  getSelectedBusinessUnitIdSaga,
  getTokenSaga
} from "../../api/api-saga-factory";
import { EstimatesApi } from "modules/estimates";
import {
  CalculatedPriceDto,
  ClientNumberDto,
  FilterOptionsDto
} from "./models";

export class QuickPriceApi {
  instance: AxiosInstance;
  businessUnitId: string;

  constructor(accessToken: string, businessUnitId: string) {
    this.instance = axios.create();
    this.instance.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    });
    this.businessUnitId = businessUnitId;
  }
  createQuickPriceSheet = (quickPriceSheet: QuickPriceSheetCreateDto) => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/quickPriceSheets`;
    return this.instance.post<QuickPriceSheetReadDto>(url, quickPriceSheet);
  };
  loadQuickPriceSheet = (id: string) => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/quickPriceSheets/${id}`;
    return this.instance.get<QuickPriceSheetReadDto>(url);
  };
  deleteQuickPriceSheet = (id: string) => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/quickPriceSheets/${id}`;
    return this.instance.delete(url);
  };
}

const genericSagas = apiSagaFactory<WithId<PayItemLibrary>>({
  isBusinessUnitApi: true,
  apiPath: "/payItemLibraries"
});

function* loadPayItemLibraries() {
  try {
    const api: RestApi<WithId<PayItemLibrary>> = yield call(
      genericSagas.getApi
    );
    const response: AxiosResponse<WithId<PayItemLibrary>[]> = yield call([
      api,
      api.get
    ]);
    yield put(actions.loadPayItemLibraries.success(response.data || []));
  } catch (error) {
    yield put(actions.loadPayItemLibraries.failure(error as Error));
  }
}

function* loadPayItemLibrary(
  action: ReturnType<typeof actions.loadSelectedPayItemLibrary.request>
) {
  try {
    const api: RestApi<WithId<DetailedPayItemLibrary>> = yield call(
      genericSagas.getApi
    );
    const response: AxiosResponse<WithId<DetailedPayItemLibrary>> = yield call(
      [api, api.get],
      `/${action.payload}`
    );
    yield put(actions.loadSelectedPayItemLibrary.success(response.data));
  } catch (error) {
    yield put(actions.loadSelectedPayItemLibrary.failure(error as Error));
  }
}
function* loadWizardPayItemLibrary(
  action: ReturnType<typeof actions.loadWizardPayItemLibrary.request>
) {
  try {
    const api: RestApi<WithId<DetailedPayItemLibrary>> = yield call(
      genericSagas.getApi
    );
    const response: AxiosResponse<WithId<DetailedPayItemLibrary>> = yield call(
      [api, api.get],
      `${action.payload}`
    );
    yield put(actions.loadWizardPayItemLibrary.success(response.data));
  } catch (error) {
    yield put(actions.loadWizardPayItemLibrary.failure(error as Error));
  }
}

function* createPayItemLibrary(
  action: ActionType<typeof actions.createPayItemLibrary.request>
) {
  const newLibrary = action.payload;
  try {
    const api: RestApi<WithId<DetailedPayItemLibrary>> = yield call(
      genericSagas.getApi
    );
    const response: AxiosResponse<WithId<DetailedPayItemLibrary>> = yield call(
      [api, api.create],
      newLibrary
    );
    yield put(actions.createPayItemLibrary.success(response.data));
    notify.save(response.data.versionName);
  } catch (error) {
    notify.error(strings.quickPricing.notification.saveFailed);
    yield put(actions.createPayItemLibrary.failure(error as Error));
    yield put(actions.loadPayItemLibraries.failure(error as Error));
  }
}

function* deletePayItemLibrary(
  action: ReturnType<typeof actions.deletePayItemLibrary.request>
) {
  try {
    const api: RestApi<WithId<PayItemLibrary>> = yield call(
      genericSagas.getApi
    );
    yield call([api, api.delete], `/${action.payload}`);
    yield put(actions.deletePayItemLibrary.success(action.payload));
  } catch (error) {
    yield put(actions.deletePayItemLibrary.failure(error as Error));
  }
}

function* loadQuickPriceSheet(
  action: ActionType<typeof actions.loadQuickPriceSheet.request>
) {
  try {
    const token: string = yield call(getTokenSaga);
    const businessUnitId: string = yield call(getSelectedBusinessUnitIdSaga);
    const api = new QuickPriceApi(token, businessUnitId);
    const response: AxiosResponse<WithId<QuickPriceSheetReadDto>> = yield call(
      api.loadQuickPriceSheet,
      action.payload
    );
    yield put(actions.loadQuickPriceSheet.success(response.data));
  } catch (error) {
    yield put(actions.loadQuickPriceSheet.failure(error as Error));
  }
}

function* deleteQuickPriceSheet(
  action: ActionType<typeof actions.deleteQuickPriceSheet.request>
) {
  try {
    const token: string = yield call(getTokenSaga);
    const businessUnitId: string = yield call(getSelectedBusinessUnitIdSaga);
    const api = new QuickPriceApi(token, businessUnitId);
    const response: AxiosResponse<string> = yield call(
      api.deleteQuickPriceSheet,
      action.payload
    );
    yield put(actions.deleteQuickPriceSheet.success(response.data));
  } catch (error) {
    yield put(actions.deleteQuickPriceSheet.failure(error as Error));
  }
}

function* loadClientNumbers() {
  const token: string = yield call(getTokenSaga);
  const businessUnitId: string = yield call(getSelectedBusinessUnitIdSaga);
  try {
    if (token && businessUnitId) {
      const api = new EstimatesApi(token, businessUnitId);
      const response: AxiosResponse<ClientNumberDto[]> = yield call(
        api.loadQuickPriceClientNumbers
      );
      yield put(actions.loadClientNumbers.success(response.data));
    }
  } catch (error) {
    yield put(actions.loadClientNumbers.failure(error as Error));
  }
}

function* loadFilterOptions() {
  const token: string = yield call(getTokenSaga);
  const businessUnitId: string = yield call(getSelectedBusinessUnitIdSaga);
  try {
    if (token && businessUnitId) {
      const api = new EstimatesApi(token, businessUnitId);
      const response: AxiosResponse<FilterOptionsDto> = yield call(
        api.getQuickPriceFilterOptions
      );
      yield put(actions.loadFilterOptions.success(response.data));
    }
  } catch (error) {
    yield put(actions.loadFilterOptions.failure(error as Error));
  }
}

function* loadCalculatedPrice(
  action: ReturnType<typeof actions.loadCalculatedPrices.request>
) {
  const token: string = yield call(getTokenSaga);
  const businessUnitId: string = yield call(getSelectedBusinessUnitIdSaga);
  try {
    if (token && businessUnitId) {
      const api = new EstimatesApi(token, businessUnitId);
      const response: AxiosResponse<CalculatedPriceDto[]> = yield call(
        api.getQuickPriceCalculatedPrice,
        action.payload
      );
      yield put(actions.loadCalculatedPrices.success(response.data));
    }
  } catch (error) {
    yield put(actions.loadCalculatedPrices.failure(error as Error));
  }
}

export const sagas = [
  takeEvery(
    getType(actions.loadPayItemLibraries.request),
    loadPayItemLibraries
  ),
  takeEvery(
    getType(actions.createPayItemLibrary.request),
    createPayItemLibrary
  ),
  takeEvery(
    getType(actions.loadSelectedPayItemLibrary.request),
    loadPayItemLibrary
  ),
  takeEvery(
    getType(actions.loadWizardPayItemLibrary.request),
    loadWizardPayItemLibrary
  ),
  takeEvery(
    getType(actions.deletePayItemLibrary.request),
    deletePayItemLibrary
  ),
  takeEvery(getType(actions.loadQuickPriceSheet.request), loadQuickPriceSheet),
  takeEvery(
    getType(actions.deleteQuickPriceSheet.request),
    deleteQuickPriceSheet
  ),
  takeLatest(getType(actions.loadClientNumbers.request), loadClientNumbers),
  takeLatest(getType(actions.loadFilterOptions.request), loadFilterOptions),
  takeLatest(getType(actions.loadCalculatedPrices.request), loadCalculatedPrice)
];
