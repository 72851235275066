
interface Configuration {
  endpoints: {
    HBINSIGHTS: string;
    SMARTDRIVE: string;
    OUTBOXAUTH: string;
    PRECON: string;
    CONTACTS: string;
    HCSSCONNECT: string;
    REMARKABLE: string;
    HEAVYJOB: string;
    FENIX: string;
  };
  mapBox: {
    BASESTYLEURI: string;
    SATELLITE: string;
    BASIC: string;
  },
  // NON-SECRET KEYS ONLY!!
  keys: {
    MIXPANELKEY: string;
    SMARTDRIVEAPPID: string;
    MAPBOX: string;
    APPINSIGHTS: string;
  };
  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    baseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
  oidc: {
    authority: string;
    client_id: string;
    client_secret: string;
    redirect_uri: string;
    silent_redirect_uri: string;
    post_logout_redirect_uri: string;
    response_type: string;
    scope: string;
  };
  flags: {
    subs_showImportFromExcel: boolean;
  };
  rum?: {
    rumEnabled: boolean;
    rumConfigOptions: any;
  } | null;
}

const local: Configuration = {
  endpoints: {
    // HCSSCONNECT: "https://localhost:6001",
    HCSSCONNECT: "https://dev-connect.hcssapps.com",
    HBINSIGHTS: "https://hbinsights-dev.hcssapps.com",
    HEAVYJOB: "https://dev-heavyjob.hcssapps.com",
    SMARTDRIVE: "https://smartdrive-dev.hcssapps.com/api/",
    OUTBOXAUTH: "https://dev-outbox-auth.azurewebsites.net",
    PRECON: "https://localhost:5001",
    CONTACTS: "https://dev-hcss-contacts-api.hcssapps.com",
    REMARKABLE: "https://remarkable-dev.hcssapps.com",
    FENIX: "https://dev-fenix.hcssapps.com"
  },
  mapBox: {
    BASESTYLEURI: "mapbox://styles/hcssitdev",
    BASIC: "ck8j7zxm219oo1hleu44ugx2z",
    SATELLITE: "ckym8srpx2i4h15pe3egyxwub"
  },
  keys: {
    MIXPANELKEY: "6a8c6498436634e5554f8d0cd4b18d4f",
    SMARTDRIVEAPPID: "3EF4C10A-746E-454D-A69A-798E00513C31",
    MAPBOX: "pk.eyJ1IjoiaGNzc2l0ZGV2IiwiYSI6ImNrOGYwNnE3bzAxOW8zcm42YjZtNDVobDEifQ.RcIH6fHyx1TfvhjSuCH_EQ",
    APPINSIGHTS: "64108244-0233-48b7-9777-d47932172372"
  },
  firebaseConfig: {
    apiKey: "AIzaSyDw_2ZaUAJKoMbjOKHFmXf6eFssR8G_1tM",
    authDomain: "hcss-subcontractor-dev.firebaseapp.com",
    databaseURL: "https://hcss-subcontractor-dev.firebaseio.com",
    baseURL: "https://us-central1-hcss-subcontractor-dev.cloudfunctions.net",
    projectId: "hcss-subcontractor-dev",
    storageBucket: "hcss-subcontractor-dev.appspot.com",
    messagingSenderId: "193738124309",
    appId: "1:193738124309:web:6840f01da9da68fe"
  },
  oidc: {
    authority: "https://dev-identity.hcssapps.com",
    client_id: "empspa",
    client_secret: "dummy", // IdentityServer 3 Workaround: This value will not be used for AuthCodePKCE
    redirect_uri: "https://localhost:3000/oidc/signin-callback",
    silent_redirect_uri: "https://localhost:3000/oidc/silent-renew",
    post_logout_redirect_uri: "https://localhost:3000/oidc/logout",
    response_type: "code",
    scope: "openid profile company heavybid heavybid:read heavybid:insights contacts:read contacts:write setups cred:read heavyjob:read"
  },
  flags: {
    subs_showImportFromExcel: true
  },
  rum: {
    rumEnabled: true,
    rumConfigOptions: {
      applicationId: "8dffaa09-403f-4138-b687-b01cdf2fc11e",
      clientToken: "pubc345de44e4d6660f8cfc29b94acead6e",
      site: "datadoghq.com",
      service: "hb_precon",
      env: "local",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "allow"
    }
  },
};

const dev: Configuration = {
  endpoints: {
    HCSSCONNECT: "https://dev-connect.hcssapps.com",
    HBINSIGHTS: "https://hbinsights-dev.hcssapps.com",
    HEAVYJOB: "https://dev-heavyjob.hcssapps.com",
    SMARTDRIVE: "https://smartdrive-dev.hcssapps.com/api/",
    OUTBOXAUTH: "https://dev-outbox-auth.azurewebsites.net",
    PRECON: "https://precon-dev.hcssapps.com",
    CONTACTS: "https://dev-hcss-contacts-api.hcssapps.com",
    REMARKABLE: "https://remarkable-dev.hcssapps.com",
    FENIX: "https://dev-fenix.hcssapps.com"
  },
  mapBox: {
    BASESTYLEURI: "mapbox://styles/hcssitdev",
    BASIC: "ck8j7zxm219oo1hleu44ugx2z",
    SATELLITE: "ckym8srpx2i4h15pe3egyxwub"
  },
  keys: {
    MIXPANELKEY: "6a8c6498436634e5554f8d0cd4b18d4f",
    SMARTDRIVEAPPID: "3EF4C10A-746E-454D-A69A-798E00513C31",
    MAPBOX: "pk.eyJ1IjoiaGNzc2l0ZGV2IiwiYSI6ImNrOGYwN3N0bjAxdW0zZW81bWV1cmJsM28ifQ.5p552n_VlgxtlcK90FCRbg",
    APPINSIGHTS: "64108244-0233-48b7-9777-d47932172372"
  },
  firebaseConfig: {
    apiKey: "AIzaSyDw_2ZaUAJKoMbjOKHFmXf6eFssR8G_1tM",
    authDomain: "hcss-subcontractor-dev.firebaseapp.com",
    databaseURL: "https://hcss-subcontractor-dev.firebaseio.com",
    baseURL: "https://us-central1-hcss-subcontractor-dev.cloudfunctions.net",
    projectId: "hcss-subcontractor-dev",
    storageBucket: "hcss-subcontractor-dev.appspot.com",
    messagingSenderId: "193738124309",
    appId: "1:193738124309:web:6840f01da9da68fe"
  },
  oidc: {
    authority: "https://dev-identity.hcssapps.com",
    client_id: "empspa",
    client_secret: "dummy", // IdentityServer 3 Workaround: This value will not be used for AuthCodePKCE
    redirect_uri: `https://${window.location.hostname.toLowerCase()}/oidc/signin-callback`,
    silent_redirect_uri: `https://${window.location.hostname.toLowerCase()}/oidc/silent-renew`,
    post_logout_redirect_uri: `https://${window.location.hostname.toLowerCase()}/oidc/logout`,
    response_type: "code",
    scope: "openid profile company heavybid heavybid:read heavybid:insights contacts:read contacts:write setups cred:read heavyjob:read"
  },
  flags: {
    subs_showImportFromExcel: true
  },
  rum: {
    rumEnabled: true,
    rumConfigOptions: {
      applicationId: "8dffaa09-403f-4138-b687-b01cdf2fc11e",
      clientToken: "pubc345de44e4d6660f8cfc29b94acead6e",
      site: "datadoghq.com",
      service: "hb_precon",
      env: "dev",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "allow"
    }
  },
};

const staging: Configuration = {
  endpoints: {
    HCSSCONNECT: "https://staging-connect.hcssapps.com",
    HBINSIGHTS: "https://hbinsights-staging.hcssapps.com",
    HEAVYJOB: "https://staging-heavyjob.hcssapps.com",
    SMARTDRIVE: "https://smartdrive-staging.hcssapps.com/api/",
    OUTBOXAUTH: "https://staging-outbox-auth.azurewebsites.net",
    PRECON: "https://precon-staging.hcssapps.com",
    CONTACTS: "https://staging-hcss-contacts-api.hcssapps.com",
    REMARKABLE: "https://remarkable-staging.hcssapps.com",
    FENIX: "https://staging-fenix.hcssapps.com"
  },
  mapBox: {
    BASESTYLEURI: "mapbox://styles/hcssitdev",
    BASIC: "ck8j7zxm219oo1hleu44ugx2z",
    SATELLITE: "ckym8srpx2i4h15pe3egyxwub"
  },
  keys: {
    MIXPANELKEY: "5eccbce7962949eca4026bb2d1df854e",
    SMARTDRIVEAPPID: "3EF4C10A-746E-454D-A69A-798E00513C31",
    MAPBOX: "pk.eyJ1IjoiaGNzc2l0ZGV2IiwiYSI6ImNrOGYwdG43MjAyMHozbHJ3bDRmeWluam8ifQ.Un5tRwXoeYGtF1cclh-qoA",
    APPINSIGHTS: "dfc00b85-1996-4b45-b170-b95d462cb46c"
  },
  firebaseConfig: {
    apiKey: "AIzaSyDDaLS3mZDF1Jr7ZdEUKikUy0EWjQVifxo",
    authDomain: "hcss-subcontractor-staging.firebaseapp.com",
    databaseURL: "https://hcss-subcontractor-staging.firebaseio.com",
    baseURL: "https://us-central1-hcss-subcontractor-staging.cloudfunctions.net",
    projectId: "hcss-subcontractor-staging",
    storageBucket: "hcss-subcontractor-staging.appspot.com",
    messagingSenderId: "913072698382",
    appId: "1:913072698382:web:25ae61e202261fa3"
  },
  oidc: {
    authority: "https://staging-identity.hcssapps.com",
    client_id: "empspa",
    client_secret: "dummy", // IdentityServer 3 Workaround: This value will not be used for AuthCodePKCE
    redirect_uri: `https://${window.location.hostname.toLowerCase()}/oidc/signin-callback`,
    silent_redirect_uri: `https://${window.location.hostname.toLowerCase()}/oidc/silent-renew`,
    post_logout_redirect_uri: `https://${window.location.hostname.toLowerCase()}/oidc/logout`,
    response_type: "code",
    scope: "openid profile company heavybid heavybid:read heavybid:insights contacts:read contacts:write setups cred:read heavyjob:read"
  },
  flags: {
    subs_showImportFromExcel: true
  },
  rum: {
    rumEnabled: true,
    rumConfigOptions: {
      applicationId: "8dffaa09-403f-4138-b687-b01cdf2fc11e",
      clientToken: "pubc345de44e4d6660f8cfc29b94acead6e",
      site: "datadoghq.com",
      service: "hb_precon",
      env: "staging",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "allow"
    }
  },
};

const preProd: Configuration = {
  endpoints: {
    HCSSCONNECT: "https://connect.hcssapps.com",
    HBINSIGHTS: "https://hbinsights.hcssapps.com",
    HEAVYJOB: "https://heavyjob.hcssapps.com",
    SMARTDRIVE: "https://smartdrive.hcssapps.com/api/",
    OUTBOXAUTH: "https://outbox-auth.azurewebsites.net",
    PRECON: "https://precon-preprod.hcssapps.com",
    CONTACTS: "https://hcss-contacts-api.hcssapps.com",
    REMARKABLE: "https://remarkable.hcssapps.com",
    FENIX: "https://fenix.hcssapps.com"
  },
  mapBox: {
    BASESTYLEURI: "mapbox://styles/hcssitdev",
    BASIC: "ck8j7zxm219oo1hleu44ugx2z",
    SATELLITE: "ckym8srpx2i4h15pe3egyxwub"
  },
  keys: {
    MIXPANELKEY: "2af6e2627e7c92fcacbb05bdfc7a3136",
    SMARTDRIVEAPPID: "3EF4C10A-746E-454D-A69A-798E00513C31",
    MAPBOX: "pk.eyJ1IjoiaGNzc2l0ZGV2IiwiYSI6ImNrOGYwdTNncjAyMTIzZnBsM2g3ZHI3dGcifQ.08bBNmj7v7PVQL3K33-qiw",
    APPINSIGHTS: "71c6d29e-9923-4576-8350-965e834a82fc"

  },
  firebaseConfig: {
    apiKey: "AIzaSyAa5n_LTlNTz2Qzm1ebGVRvv7ZUHnBI0gg",
    authDomain: "hcss-subcontractor.firebaseapp.com",
    databaseURL: "https://hcss-subcontractor.firebaseio.com",
    baseURL: "https://us-central1-hcss-subcontractor.cloudfunctions.net",
    projectId: "hcss-subcontractor",
    storageBucket: "hcss-subcontractor.appspot.com",
    messagingSenderId: "755698692439",
    appId: "1:755698692439:web:a29d9b87fcd0f401"
  },
  oidc: {
    authority: "https://identity.hcssapps.com",
    client_id: "empspa",
    client_secret: "dummy", // IdentityServer 3 Workaround: This value will not be used for AuthCodePKCE
    redirect_uri: `https://${window.location.hostname.toLowerCase()}/oidc/signin-callback`,
    silent_redirect_uri: `https://${window.location.hostname.toLowerCase()}/oidc/silent-renew`,
    post_logout_redirect_uri: `https://${window.location.hostname.toLowerCase()}/oidc/logout`,
    response_type: "code",
    scope: "openid profile company heavybid heavybid:read heavybid:insights contacts:read contacts:write setups cred:read heavyjob:read"
  },
  flags: {
    subs_showImportFromExcel: true
  },
  rum: {
    rumEnabled: true,
    rumConfigOptions: {
      applicationId: "8dffaa09-403f-4138-b687-b01cdf2fc11e",
      clientToken: "pubc345de44e4d6660f8cfc29b94acead6e",
      site: "datadoghq.com",
      service: "hb_precon",
      env: "preProd",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "allow"
    }
  },
};

const preProdEstimateHistory: Configuration = {
  ...preProd,
  endpoints: {
    ...preProd.endpoints,
    HBINSIGHTS: "https://hbinsights-preprod.hcssapps.com",
  }
}

const prod: Configuration = {
  endpoints: {
    HCSSCONNECT: "https://connect.hcssapps.com",
    HBINSIGHTS: "https://hbinsights.hcssapps.com",
    HEAVYJOB: "https://heavyjob.hcssapps.com",
    SMARTDRIVE: "https://smartdrive.hcssapps.com/api/",
    OUTBOXAUTH: "https://outbox-auth.azurewebsites.net",
    PRECON: "https://precon.hcssapps.com",
    CONTACTS: "https://hcss-contacts-api.hcssapps.com",
    REMARKABLE: "https://remarkable.hcssapps.com",
    FENIX: "https://fenix.hcssapps.com"
  },
  mapBox: {
    BASESTYLEURI: "mapbox://styles/hcssitdev",
    BASIC: "ck8j7zxm219oo1hleu44ugx2z",
    SATELLITE: "ckym8srpx2i4h15pe3egyxwub"
  },
  keys: {
    MIXPANELKEY: "2af6e2627e7c92fcacbb05bdfc7a3136",
    SMARTDRIVEAPPID: "3EF4C10A-746E-454D-A69A-798E00513C31",
    MAPBOX: "pk.eyJ1IjoiaGNzc2l0ZGV2IiwiYSI6ImNrOGYwdTNncjAyMTIzZnBsM2g3ZHI3dGcifQ.08bBNmj7v7PVQL3K33-qiw",
    APPINSIGHTS: "71c6d29e-9923-4576-8350-965e834a82fc"
  },
  firebaseConfig: {
    apiKey: "AIzaSyAa5n_LTlNTz2Qzm1ebGVRvv7ZUHnBI0gg",
    authDomain: "hcss-subcontractor.firebaseapp.com",
    databaseURL: "https://hcss-subcontractor.firebaseio.com",
    baseURL: "https://us-central1-hcss-subcontractor.cloudfunctions.net",
    projectId: "hcss-subcontractor",
    storageBucket: "hcss-subcontractor.appspot.com",
    messagingSenderId: "755698692439",
    appId: "1:755698692439:web:a29d9b87fcd0f401"
  },
  oidc: {
    authority: "https://identity.hcssapps.com",
    client_id: "empspa",
    client_secret: "dummy", // IdentityServer 3 Workaround: This value will not be used for AuthCodePKCE
    redirect_uri: `https://${window.location.hostname.toLowerCase()}/oidc/signin-callback`,
    silent_redirect_uri: `https://${window.location.hostname.toLowerCase()}/oidc/silent-renew`,
    post_logout_redirect_uri: `https://${window.location.hostname.toLowerCase()}/oidc/logout`,
    response_type: "code",
    scope: "openid profile company heavybid heavybid:read heavybid:insights contacts:read contacts:write setups cred:read heavyjob:read"
  },
  flags: {
    subs_showImportFromExcel: true
  },
  rum: {
    rumEnabled: true,
    rumConfigOptions: {
      applicationId: "8dffaa09-403f-4138-b687-b01cdf2fc11e",
      clientToken: "pubc345de44e4d6660f8cfc29b94acead6e",
      site: "datadoghq.com",
      service: "hb_precon",
      env: "prod",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask"
    }
  },
};

export enum Environment {
  Local,
  Dev,
  Staging,
  PreProd,
  PreProdEstimateHistory,
  Production
}

export const getCurrentEnvironment = () => {
  const hostname = window.location.hostname.toLowerCase();
  if (hostname.includes("localhost")) return Environment.Local;
  else if (hostname.includes("-dev")) return Environment.Dev;
  else if (hostname.includes("-staging")) return Environment.Staging;
  else if (hostname.includes("-preprod")) return Environment.PreProd;
  return Environment.Production;
}

export const getOverrideEnvironment = (): Environment | null => {
  const localStorageValue = window.localStorage.getItem("override-environment")
  if (localStorageValue === null || localStorageValue === "null") return null;
  return parseInt(localStorageValue);
}

export const currentEnvironment = getCurrentEnvironment();
export const currentEnvironmentName = Environment[currentEnvironment]
const overrideEnvironment = getOverrideEnvironment();
export const isLocal = currentEnvironment === Environment.Local;

const getEnvironmentConfig = () => {
  const selectedEnvironment = overrideEnvironment ?? currentEnvironment;
  switch (selectedEnvironment) {
    case (Environment.Local):
      return local;
    case (Environment.Dev):
      return dev;
    case (Environment.Staging):
      return staging;
    case (Environment.PreProd):
      return preProd;
    case (Environment.PreProdEstimateHistory):
      return preProdEstimateHistory;
    default:
      return prod;
  }
};

const config = getEnvironmentConfig();

const estimateHistoryEndpointOverride = window.localStorage.getItem("estimate-history-endpoint-override")
if (estimateHistoryEndpointOverride && estimateHistoryEndpointOverride !== "null") {
  config.endpoints.HBINSIGHTS = JSON.parse(estimateHistoryEndpointOverride);
}

export default {
  ...config,
  defaults: {
    // MEMO: This value must remain constant for verification of persisted fingerprints
    fingerPrintHashSeed: 88,
    bingApiKey: "AmqssH6s6KfS3IjcQb6lSLTCTW1VDziPB9pkUBJUphptL8mVUw0R8yB5ANciOl9k"
  }
};
