import * as React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import styled from "styled-components";
import { strings } from "localization";

export interface FullPageLoadingProps {
  loading: boolean;
  showTitle?: boolean;
}

export class FullPageLoading extends React.PureComponent<
  FullPageLoadingProps,
  {}
> {
  render() {
    return (
      <FullFlexContainer data-testid="full-page-loading">
        {this.props.showTitle && (
          <TitleContainer>
            <Title data-testid="heavybid-title">
              Heavy
              <b>Bid</b>
            </Title>
          </TitleContainer>
        )}
        <ClipLoader loading={this.props.loading} />
        <Light>{strings.layout.loading}</Light>
      </FullFlexContainer>
    );
  }
}

export default FullPageLoading;

export const TitleContainer = styled.div`
  font-size: 30px;
  display: flex;
  align-items: baseline;
  justify-content: center;
`;

const Light = styled.div`
  font-family: "ProximaNova";
`;

const Title = styled.span`
  font-family: "ProximaNova";
  color: rgb(0, 150, 57);

  & strong {
    font-family: "ProximaNova Heavy";
  }

  & .darktext {
    color: #444;
  }
`;

const FullFlexContainer = styled.div`
  height: 175px;
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
`;
