import {
  ApplicationInsights,
  IExceptionTelemetry,
  SeverityLevel
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import config, { isLocal } from "config";
import { ErrorInfo } from "react";
import { history } from "./history";

let appInsights: ApplicationInsights;
const reactPlugin = new ReactPlugin();
const initializeAppInsights = () => {
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: `${config.keys.APPINSIGHTS}`,
      disableAjaxTracking: isLocal,
      disableExceptionTracking: isLocal, // disable for local dev
      enableAutoRouteTracking: false,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: history }
      }
    }
  });

  appInsights.loadAppInsights();

  return appInsights;
};

const formatErrorText = (error: string, stack: string) => {
  return `Unexpected error: ${error} \n Occured at: ${stack}`;
};

const trackReactError = (error: Error, errorInfo: ErrorInfo) => {
  if (isLocal) {
    console.error(error);
    console.info(
      "Errors are not reported to app insights during local development"
    );
    return;
  }

  const exception: IExceptionTelemetry = {
    exception: new Error(
      formatErrorText(error.toString(), errorInfo.componentStack)
    ),
    severityLevel: SeverityLevel.Critical
  };

  appInsights.trackException(exception);
};

export { appInsights, initializeAppInsights, trackReactError, reactPlugin };
