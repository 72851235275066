import React from "react";
import { strings } from "localization";
import { Sizes } from "react-bootstrap";
import { Button, Modal, Icon } from "hcss-components";
import styled from "styled-components";
interface ConfirmSubmitModalProps {
  show: boolean;
  title: string;
  submitButtonText?: string;
  children?: React.ReactNode;
  size?: Sizes;
  handleConfirm: () => void;
  handleClose: () => void;
}
export const ConfirmSubmitModal = (props: ConfirmSubmitModalProps) => {
  return (
    <StyledModalWrapper
      bsSize={props.size}
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button
          className="inverted"
          hcssStyle="ThemeInverted"
          onClick={props.handleClose}
        >
          <Icon name="times" margin="right" />
          {strings.genericModal.cancel}
        </Button>
        &nbsp;
        <Button onClick={props.handleConfirm}>
          <Icon name="check" margin="right" />
          {props.submitButtonText ?? strings.genericModal.confirm}
        </Button>
      </Modal.Footer>
    </StyledModalWrapper>
  );
};

const StyledModalWrapper = styled(Modal)`
  z-index: 12000;
`;
