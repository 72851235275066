import React, { useState, ReactNode } from "react";
import styled from "styled-components";
import { MenuItem } from "react-bootstrap";
import { Box } from "hcss-core";
import { Icon, Link } from "hcss-components";
import { GLOBAL_SIDE_NAV_WIDTH } from "core/components/hcss-navigation/common/constants";

interface Option {
  key: string;
  mainText: string;
  subText: string;
}

interface Dropdown {
  options: Option[];
  optionHeader: string;
  singlePath: (param: string) => string;
  allPath: string;
}

interface QuickSelectProps {
  children: ReactNode;
  dropDown?: Dropdown;
}

export const QuickSelect = ({ children, dropDown }: QuickSelectProps) => {
  const [show, setShow] = useState(false);

  return (
    <Box className="quick-select-container" height="inherit">
      <HoverContainer p={2} onClick={() => setShow(prev => !prev)}>
        {children}
        {dropDown && (
          <Box fontSize="8px" position="absolute" right="50px" zIndex={10}>
            <Icon name="chevron-down" />
          </Box>
        )}
      </HoverContainer>

      {show && dropDown && (
        <Cover onClick={() => setShow(false)}>
          <Box
            position="absolute"
            display="initial"
            top="116px"
            left={`${GLOBAL_SIDE_NAV_WIDTH + 18}px`}
            width="260px"
            as="ul"
            className="dropdown-menu open"
          >
            <MenuItem header>{dropDown.optionHeader}</MenuItem>
            {dropDown.options.map(option => (
              <Link
                key={option.key}
                to={
                  typeof dropDown.singlePath === "string"
                    ? dropDown.singlePath
                    : dropDown.singlePath(option.key)
                }
                style={{ textDecoration: "none" }}
              >
                <EstimateLink>
                  <Box fontWeight={600} color="neutral.1">
                    {option.mainText}
                  </Box>
                  <Box
                    fontSize={0}
                    color="neutral.1"
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden"
                    }}
                  >
                    {option.subText}
                  </Box>
                </EstimateLink>
              </Link>
            ))}
            <MenuItem divider />
            <Link to={dropDown.allPath} style={{ textDecoration: "none" }}>
              <EstimateLink>View All</EstimateLink>
            </Link>
          </Box>
        </Cover>
      )}
    </Box>
  );
};

const EstimateLink = styled(Box)`
  color: ${props => props.theme.colors.neutral[0]};
  text-decoration: none;
  padding: 4px 20px;

  &:hover {
    color: ${props => props.theme.colors.neutral[0]};
    text-decoration: none;
    background-color: ${props => props.theme.colors.neutral[6]};
  }
`;

const HoverContainer = styled(Box)`
  margin-right: 14px;
  background-color: ${props => props.theme.colors.neutral[6]};
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  height: inherit;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Cover = styled(Box)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 100;
`;
