import React, { useMemo } from "react";
import moment from "moment";
import styled from "styled-components";
import { Select } from "hcss-components";
import { keyBy } from "lodash-es";
import { DateTimePicker } from "core";
import { filterConstants, FilterControlProps } from "./common";
import { calculateValuesFromRange } from "./dateTimeUtils";

const rangeOptions: Array<{ label: string; value: string }> = [
  { label: "Last 7 Days", value: filterConstants.dates.nowM7 },
  { label: "Next 7 Days", value: filterConstants.dates.nowP7 },
  { label: "Last 30 Days", value: filterConstants.dates.nowM30 },
  { label: "Next 30 Days", value: filterConstants.dates.nowP30 },
  { label: "Last Year", value: filterConstants.dates.nowM365 },
  { label: "Next Year", value: filterConstants.dates.nowP365 },
  { label: "Custom", value: filterConstants.dates.custom }
];
const rangeOptionsLookup = keyBy(rangeOptions, "value") as {
  [key: string]: { label: string; value: string };
};

export const DateTimeFilter = ({ filter, onChange }: FilterControlProps) => {
  const options = useMemo(
    () =>
      rangeOptions.filter(
        opt => filter.config?.allowedOptions.includes(opt.value) ?? true
      ),
    [filter.config]
  );
  const allowCustom =
    options.find(opt => opt.value === filterConstants.dates.custom) !==
    undefined;

  const setSelectedRangeOption = (value?: string) => {
    if (value && value in filterConstants.dates) {
      onChange({
        ...filter,
        value: { range: filterConstants.dates[value] }
      });
    } else {
      onChange({
        ...filter,
        value: undefined
      });
    }
  };

  const includeTime = false;

  const normalizeDate = (
    value: Date | undefined,
    start: boolean
  ): string | undefined => {
    if (!value) {
      return undefined;
    }
    const date = moment(value);
    const unit = includeTime ? "minute" : "day";

    if (start) {
      date.startOf(unit);
    } else {
      date.endOf(unit);
    }

    return value.toISOString();
  };

  const updateLowerValue = (value: any) => {
    onChange({
      ...filter,
      value: {
        ...filter.value,
        range: filterConstants.dates.custom,
        from: normalizeDate(value, true)
      }
    });
  };

  const updateUpperValue = (value: any) => {
    onChange({
      ...filter,
      value: {
        ...filter.value,
        range: filterConstants.dates.custom,
        to: normalizeDate(value, false)
      }
    });
  };

  const values = {
    range:
      filter.value && filter.value.range && filter.value.range
        ? rangeOptionsLookup[filter.value.range]
        : undefined,
    from:
      filter.value && filter.value.from
        ? moment(filter.value.from).toDate()
        : undefined,
    to:
      filter.value && filter.value.to
        ? moment(filter.value.to).toDate()
        : undefined
  };

  const calcValuesFromRange =
    values.range?.value !== filterConstants.dates.custom;

  if (calcValuesFromRange && values.range) {
    const calculatedValues = calculateValuesFromRange(values.range.value);
    values.from = calculatedValues?.from.toDate();
    values.to = calculatedValues?.to.toDate();
  }

  return (
    <div>
      <RangeOptionsContainer allowCustom={allowCustom}>
        <Select
          isClearable
          placeholder={filter.config?.placeholder ?? "Select a date range..."}
          options={options}
          value={values.range ?? null}
          onChange={opt => {
            const selectedOption = opt as {
              label: string;
              value: string;
            } | null;
            setSelectedRangeOption(selectedOption?.value ?? undefined);
          }}
        />
      </RangeOptionsContainer>

      {allowCustom && (
        <DateTimeContainer>
          <DateTimePickerContainer>
            <DateTimePicker
              time={includeTime}
              value={values.from}
              onChange={updateLowerValue}
            />
          </DateTimePickerContainer>
          <span>-</span>
          <DateTimePickerContainer>
            <DateTimePicker
              time={includeTime}
              value={values.to}
              onChange={updateUpperValue}
            />
          </DateTimePickerContainer>
        </DateTimeContainer>
      )}
    </div>
  );
};

const RangeOptionsContainer = styled.div<{ allowCustom?: boolean }>`
  margin: ${props => (props.allowCustom ? "8px 4px" : 0)};
`;

const DateTimeContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DateTimePickerContainer = styled.div`
  width: 150px;
  margin: 4px;
`;
