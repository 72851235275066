import React, { useState } from "react";
import styled from "styled-components";
import fp from "fingerprintjs2";
import config from "config";

import { strings } from "localization";
import { FormControl } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectors } from "../../state";
import { TermsAndConditions } from "../../../../core/terms-and-conditions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  FormGroup,
  HelpBlock,
  Icon,
  Checkbox,
  Modal
} from "hcss-components";

export interface BrowserFingerPrint {
  acceptedAt: number;
  components: string;
  hash: string;
}

interface Props {
  validationState: "error" | null;
  email: string;
  errorMessage: string;
  onEmailChange: (email: string) => void;
  onConfirm: (fingerPrint?: BrowserFingerPrint) => void;
  onSignIn: (e: any) => void;
}
export const ConfirmationPanel = ({
  validationState,
  email,
  errorMessage,
  onEmailChange,
  onConfirm,
  onSignIn
}: Props) => {
  const { confirming } = useSelector(selectors.getAccount);
  const [showTC, setShowTC] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const getFingerprint = async (): Promise<BrowserFingerPrint | undefined> => {
    try {
      const components = await fp.getPromise();
      const acceptedAt = Date.now();
      const raw = JSON.stringify(components);
      const values = components.map(c => c.value);
      const hash = fp.x64hash128(
        values.join(""),
        config.defaults.fingerPrintHashSeed
      );

      return {
        acceptedAt,
        hash,
        components: raw
      };
    } catch (error) {
      console.error(error);
    }

    return undefined;
  };

  const handleConfirm = async () => {
    const fingerPrint = await getFingerprint();
    onConfirm(fingerPrint);
  };

  const handleKeyDown = (e: React.KeyboardEvent<FormControl>) => {
    if (e.keyCode === 13 && isChecked) {
      handleConfirm();
    }
  };

  const handleChange = (e: React.FormEvent<FormControl>) => {
    e.persist();
    if (e && e.currentTarget) {
      onEmailChange((e.target as HTMLInputElement).value);
    }
  };

  const renderButtonContent = () => {
    if (confirming) {
      return (
        <span style={{ width: "100%" }}>
          <i className="fa fa-spinner fa-spin fa-fw" />
          &nbsp;{strings.account.confirm.confirming}
        </span>
      );
    }

    return (
      <span style={{ width: "100%" }}>
        <Icon name="check" />
        &nbsp;{strings.account.confirm.confirm}
      </span>
    );
  };

  return (
    <>
      <ConfirmContainer
        className="confirm-container"
        data-testid="confirm-container"
      >
        <div className="email-input">
          <FormGroup style={{ width: 240 }} validationState={validationState}>
            <HelpBlock>{errorMessage}</HelpBlock>
            <FormControl
              type="text"
              name="email"
              value={email}
              placeholder="Please enter your email address"
              onKeyDown={handleKeyDown}
              onChange={handleChange}
            />
          </FormGroup>
        </div>
        <div
          style={{ marginTop: "10px", fontSize: "12px", marginBottom: "14px" }}
        >
          <Checkbox
            value={isChecked ? "checked" : ""}
            onClick={() => setIsChecked(isChecked => !isChecked)}
          />
          I agree to the{" "}
          <a
            href="_blank"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault();
              setShowTC(true);
            }}
          >
            terms and conditions.
          </a>
        </div>
        <div className="confirm-button">
          <Button
            disabled={!isChecked}
            onClick={() => handleConfirm()}
            title={
              !isChecked ? "You must agree to the terms and conditions" : ""
            }
          >
            {renderButtonContent()}
          </Button>
        </div>

        {/* <SignInLinkContainer className="signin-link-container">
        Already have an account?
        <StyledLink onClick={onSignIn}>Sign-In</StyledLink>
      </SignInLinkContainer> */}
        {showTC && (
          <div>
            <StyledModal onHide={() => setShowTC(false)} show={showTC}>
              <Modal.Header closeButton>
                <Modal.Title>{`Terms & Conditions`}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <TermsAndConditions />
              </Modal.Body>

              <Modal.Footer>
                <Button
                  hcssStyle="ThemeInverted"
                  onClick={() => setShowTC(false)}
                >
                  Close
                </Button>
              </Modal.Footer>
            </StyledModal>
          </div>
        )}
      </ConfirmContainer>
    </>
  );
};

const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 420px;
  max-width: 500px;
  padding: 40px 50px 50px 50px;
  margin-bottom: 40px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.2);

  .email-input {
    margin-top: 25px;

    .form-group {
      margin-bottom: 0px;
    }
  }

  .confirm-button {
    margin-top: 10px;

    .btn {
      width: 160px;
      font-size: 11px;
      padding: 8px 24px;
    }
  }
`;

const IconContainer = styled.div`
  .icon .fa-primary {
    fill: rgb(181, 181, 181);
    opacity: 0.9;
  }

  .icon .fa-secondary {
    fill: rgb(221, 221, 221);
    opacity: 0.9;
  }
`;

// const SignInLinkContainer = styled.div`
//   margin-top: 60px;
//   font-size: 13px;
//   font-weight: 500;
//   color: ${ConcreteColors.gray500};
// `;

// const StyledLink = styled.a`
//   cursor: pointer;
//   font-weight: 600;
//   margin-left: 3px;
// `;

const StyledModal = styled(Modal)`
  .modal-dialog {
    overflow: hidden;
    border-radius: 8px;
  }
`;
