import React from "react";
import { isValidEmail, TextOverflow } from "core";
import { copyToClipboard } from "core/util/clipboard";

interface EmailFieldProps {
  emailAddress: string;
}

export const EmailField = ({ emailAddress }: EmailFieldProps) => {
  return (
    <div>
      {isValidEmail(emailAddress) ? (
        <a
          href={`mailto: ${emailAddress}`}
          onClick={() => copyToClipboard(emailAddress)}
        >
          <TextOverflow textAlign="right" style={{ whiteSpace: "nowrap" }}>
            {emailAddress}
          </TextOverflow>
        </a>
      ) : (
        <TextOverflow textAlign="right" style={{ whiteSpace: "nowrap" }}>
          {emailAddress}
        </TextOverflow>
      )}
    </div>
  );
};
