import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { selectors as contactsSelectors } from "modules/contacts";

export function useContactBUContextError() {
  const vendorList = useSelector(contactsSelectors.getVendorList);
  const loading = useSelector(contactsSelectors.getLoading);
  const vendorDetailsMatch = useRouteMatch<{ vendorId: string }>({
    path: "/contacts/:vendorId"
  });

  if (
    vendorDetailsMatch?.params.vendorId === "new" ||
    vendorDetailsMatch?.params.vendorId === "import" ||
    vendorDetailsMatch?.params.vendorId === "setup"
  )
    return false;

  const idFromParams = vendorDetailsMatch
    ? vendorDetailsMatch.params.vendorId
    : undefined;

  const existsInBUError =
    !!idFromParams &&
    !loading &&
    !vendorList.find(vendor => vendor.id === idFromParams);

  return existsInBUError;
}
