import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { RouteComponentProps, Redirect, withRouter } from "react-router";
import { PageLoadingOverlay } from "core/components/loaders";
import { AuthenticationMethod, CurrentUser, UserType } from "core";
import { selectors, AccountAuthorization } from "../state";
import { useOidcContext } from "../services/oidc-context";
import FullPageLoading from "modules/layout/components/FullPageLoading";
import { useAuthorization, useHcssUser } from "../hooks";
interface PrivateRouteProps extends RouteComponentProps<any> {
  path: string;
  exact?: boolean;
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}
export const PrivateRoute = withRouter(
  ({ component, location, path, exact = false }: PrivateRouteProps) => {
    const account = useSelector(selectors.getAccount);
    const auth = useAuthorization();
    const user = useHcssUser();
    const { oidcService } = useOidcContext();

    const renderFn = (Component: any) => (props: any) => {
      return <Component {...props} />;
    };

    if (account.loading) {
      return <PageLoadingOverlay />;
    }

    if (!account.isAuthenticated) {
      oidcService.signinRedirect(location.pathname);
      return <FullPageLoading loading showTitle />;
    }

    if (account.authenticationMethod === AuthenticationMethod.OneClickLink) {
      const authorizedRequest = `/quotes/solicitations/${account.authorizedResource}/requests`;
      const authorizeProposal = `/quotes/proposals/${account.authorizedResource}/review`;

      if (
        !location.pathname.startsWith(authorizedRequest) &&
        !location.pathname.startsWith(authorizeProposal)
      ) {
        return <Redirect to="/notfound" />;
      }
    }

    if (!checkAccess(user, auth, location.pathname)) {
      return <Redirect to="/notfound" />;
    }

    return <Route exact={exact} path={path} render={renderFn(component)} />;
  }
);

/**
 * This methods checks whether a user can go to a specific route.
 * Only users who have access to project tracking can go there.
 * Only HCSS users can go to Estimating, if they do not have the proper
 * subscription, they will see instructios when they get there.
 * Only users who have access to Quote management can go there.
 * All users should be able to see contacts.
 * @param {AccountAuthorization} auth
 * @param {string} path
 * @returns
 */
const checkAccess = (
  user: CurrentUser,
  auth: AccountAuthorization,
  path: string
) => {
  if (path.startsWith("/projects")) {
    return user.type === UserType.HcssSubscription;
  }

  if (path.startsWith("/estimates")) {
    return user.type === UserType.HcssSubscription;
  }

  if (path.startsWith("/contacts")) {
    return true;
  }

  if (path.startsWith("/settings")) {
    if (user.type !== UserType.Guest) {
      return checkSettings(auth, path);
    }
  }

  return false;
};

const checkSettings = (auth: AccountAuthorization, path: string) => {
  if (path.startsWith("/settings/permissions")) {
    return auth.canAccessFullPrecon;
  }

  if (path.startsWith("/settings/smtp")) {
    return auth.canAccessQuoteManagement;
  }

  if (path.startsWith("/settings/profile")) {
    return true;
  }
  return false;
};
