/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { selectors } from "./state";
import { useSelector } from "react-redux";
import { useMemo } from "react";

export function useAccount() {
  const account = useSelector(selectors.getAccount);
  return account;
}

export function useHcssUser() {
  const user = useSelector(selectors.getHcssUser)!;
  return user;
}

export function useHcssToken() {
  const token = useSelector(selectors.getHcssToken)!;
  return token;
}

export function useAuthorization() {
  const authorization = useSelector(selectors.getAuthorization);
  return authorization;
}

export function usePermissions() {
  const permissions = useSelector(selectors.getPermissions)!;
  const user = useSelector(selectors.getHcssUser);

  const permisions = useMemo(
    () => ({ ...permissions, credentialAdmin: user?.isCredentialAdmin }),
    [permissions, user]
  );

  return permisions;
}

export function usePreferences() {
  const preferences = useSelector(selectors.getPreferences)!;
  const businessUnit = useSelector(selectors.getSelectedBusinessUnit);
  return { ...preferences, businessUnitId: businessUnit.id };
}

export function useSelectedBusinessUnitId() {
  const id = useSelector(selectors.getSelectedBusinessUnitId)!;
  return id;
}

export function useUserAndCompanyLicense() {
  const {
    canAccessLimitedPrecon,
    canAccessFullPrecon,
    canAccessFullReadonlyPrecon
  } = useSelector(selectors.getAuthorization);

  const {
    admin,
    credentialAdmin,
    write,
    contactWrite,
    estimateInsights,
    heavyBidNumbers
  } = usePermissions();
  const isLimitedLicense = canAccessLimitedPrecon && !canAccessFullPrecon;
  const isFullLicense = canAccessFullPrecon;
  const isFullReadOnlyLicense = canAccessFullReadonlyPrecon;

  const isFullCompanyAdmin = credentialAdmin && isFullLicense;
  const isFullPreConAdmin = !credentialAdmin && admin && isFullLicense;
  const isAdmin = isFullCompanyAdmin || isFullPreConAdmin;

  const isFullEditableUser =
    write &&
    contactWrite &&
    estimateInsights &&
    heavyBidNumbers &&
    isFullLicense;
  const isFullReadOnlyUser =
    !write && !contactWrite && !admin && isFullReadOnlyLicense;
  return {
    companyLicense: { isLimitedLicense, isFullLicense, isFullReadOnlyLicense },
    userLicense: {
      isAdmin,
      isFullCompanyAdmin,
      isFullEditableUser,
      isFullReadOnlyUser,
      isFullPreConAdmin
    }
  };
}
