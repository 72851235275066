import appconfig from "../config";
import getDefaultInstance from "./AxiosInstance";
import { EstimateHistoryClient } from "./GeneratedClients/EstimateHistoryClient";

const baseUrl: string = appconfig.endpoints.HBINSIGHTS;

export class EstimateHistoryApi extends EstimateHistoryClient {
  constructor(accessToken: string) {
    super(baseUrl, getDefaultInstance(accessToken));
  }
}
