import { createAsyncAction, getType, ActionType } from "typesafe-actions";
import produce from "immer";

export const STATE_KEY = "signalR";

export const actions = {
  startSignalRConnection: createAsyncAction(
    "SIGNALR/CONNECT_REQUEST",
    "SIGNALR/CONNECT_SUCCESS",
    "SIGNALR/CONNECT_FAILURE"
  )<void, void, void>()
};

export type SignalRActions = ActionType<typeof actions>;

export interface State {
  connected: boolean;
  connecting: boolean;
  error: boolean;
  reconnectCount: number;
}

export interface StateSlice {
  [STATE_KEY]: State;
}

const initialState: State = {
  connecting: false,
  connected: false,
  error: false,
  reconnectCount: 0
};

export const reducer = (state = initialState, action: SignalRActions) => {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.startSignalRConnection.request): {
        draft.connecting = true;
        draft.connected = false;
        break;
      }
      case getType(actions.startSignalRConnection.success): {
        draft.connecting = false;
        draft.connected = true;
        draft.error = false;
        draft.reconnectCount = 0;
        break;
      }
      case getType(actions.startSignalRConnection.failure): {
        draft.connecting = false;
        draft.connected = false;
        draft.error = true;
        draft.reconnectCount += 1;
        break;
      }
    }
  });
};
