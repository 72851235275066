import React, { ComponentType, Fragment } from "react";
import { GLOBAL_SIDE_NAV_WIDTH } from "../../common/constants";
import { Shadow } from "../../common/shadow";

interface ComposedGlobalSideNavigationProps {
  topOffset?: number;
  showGlobalSideNavShadow?: boolean;
  contextNavigation?: ComponentType<{}>;
  globalSideNavigation: ComponentType<{}>;
}

export const ComposedGlobalSideNavigation = ({
  showGlobalSideNavShadow = true,
  contextNavigation,
  globalSideNavigation
}: ComposedGlobalSideNavigationProps) => {
  const GlobalNavigation = globalSideNavigation;

  return (
    <div className="composed-global-side-navigation">
      <Fragment>
        {showGlobalSideNavShadow && (
          <Shadow
            direction="to left"
            isBold={!!contextNavigation}
            isOverDarkBackground={true}
            style={{ marginLeft: GLOBAL_SIDE_NAV_WIDTH }}
          />
        )}
        <GlobalNavigation />
      </Fragment>
    </div>
  );
};
