import axios, { AxiosInstance } from "axios";

export class EmailSubscriptionApi {
  instance: AxiosInstance;

  constructor(accessToken: string) {
    this.instance = axios.create();
    this.instance.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    });
  }

  pauseSubscription = (emailId: string) => {
    const url = `/api/v1/emails/projects/${emailId}/pause`;
    return this.instance.post(url);
  };

  resumeSubscription = (emailId: string) => {
    const url = `/api/v1/emails/projects/${emailId}/resume`;
    return this.instance.post(url);
  };

  stopSubscription = (emailId: string) => {
    const url = `/api/v1/emails/projects/${emailId}/stop`;
    return this.instance.post(url);
  };

  sendEmail = (emailId: string) => {
    const url = `/api/v1/emails/projects/${emailId}/sendEmail`;
    return this.instance.post(url);
  };

  previewEmail = (viewIds: string[], timeZoneId: string) => {
    const url = `/api/v1/emails/projects/preview`;
    return this.instance.post(url, { viewIds, timeZoneId });
  };
}

export default EmailSubscriptionApi;
