import { call, put, takeLatest, select } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import { actions } from "./state";
import { StateSlice as AccountStateSlice } from "modules/account";
import ContactsApi from "api/ContactsApi";
import { CurrentUser, UserType } from "core";
import axios, { AxiosError } from "axios";
import { Address, UpdateProfileDto } from "api/GeneratedClients/ContactsClient";
import { ProfileService } from "./services/profile-service";
import config from "config";
import { notify } from "hcss-components";
import { strings } from "localization";

function* createProfile() {
  const user: CurrentUser = yield select(
    (state: AccountStateSlice) => state.account.user
  );
  const accessToken = yield select(
    (state: AccountStateSlice) => state.account.user?.idsrvAccessToken
  );
  let resp;
  if (user && user.idsrvAccessToken) {
    const svc = new ProfileService();
    const newProfile = svc.createProfileFromCompanyInfo(user);
    try {
      const url = `${config.endpoints.CONTACTS}/api/v1/profiles`;
      const opts = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-Requested-Business-Unit-Id": user.selectedBusinessUnitId
        }
      };
      resp = yield call(axios.post, url, newProfile, opts);

      //CreateProfileDto does not contain subtypes (minority statuses), so we must manually initialize these
      yield put(
        actions.loadProfile.success({
          ...newProfile,
          resp,
          minorityStatuses: [],
          products: [],
          contacts: []
        })
      );
    } catch (error) {
      console.error(error);
      yield put(
        actions.loadProfile.success({
          ...newProfile,
          resp,
          minorityStatuses: [],
          products: [],
          contacts: []
        })
      );
    }
  }
}

function* loadProfile(action: ActionType<typeof actions.loadProfile.request>) {
  try {
    const user: CurrentUser = yield select(
      (state: AccountStateSlice) => state.account.user
    );
    if (user?.idsrvAccessToken && user?.type !== UserType.Guest) {
      const api = new ContactsApi(
        user.idsrvAccessToken,
        user.selectedBusinessUnitId
      );
      const res = yield call([api, api.getMyProfile]);
      yield put(actions.loadProfile.success({ ...res }));
    }
  } catch (error) {
    if ("response" in error && "status" in error.response) {
      const exception = error as AxiosError;
      // if this profile doesn't exist, then create a new one
      // based on company name only
      if (exception.response?.status === 404) yield call(createProfile);
      else {
        console.error(error);
        yield put(actions.loadProfile.failure(error));
      }
    }
  }
}

function* updateProfile(
  action: ActionType<typeof actions.updateProfile.request>
) {
  try {
    const user: CurrentUser = yield select(
      (state: AccountStateSlice) => state.account.user
    );
    const accessToken = yield select(
      (state: AccountStateSlice) => state.account.user?.idsrvAccessToken
    );

    const primaryAddress = new Address({ ...action.payload.primaryAddress });
    const alternateAddress = new Address({
      ...action.payload.alternateAddress
    });
    const update = new UpdateProfileDto({
      ...action.payload,
      primaryAddress,
      alternateAddress
    });
    const url = `${config.endpoints.CONTACTS}/api/v1/profiles`;
    const opts = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-Requested-Business-Unit-Id": user.selectedBusinessUnitId
      }
    };
    yield call(axios.put, url, update, opts);
    yield put(actions.updateProfile.success({ ...update }));
    notify("success", strings.profile.mainDetails.updated);
  } catch (error) {
    yield put(actions.updateProfile.failure(error));
    notify("danger", strings.profile.mainDetails.errorUpdated);
    console.error(error);
  }
}

function* addLogo(action: ActionType<typeof actions.addLogo.request>) {
  try {
    const user: CurrentUser = yield select(
      (state: AccountStateSlice) => state.account.user
    );
    const accessToken = yield select(
      (state: AccountStateSlice) => state.account.user?.idsrvAccessToken
    );
    const url = `${config.endpoints.CONTACTS}/api/v1/profiles/logo`;
    const opts = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-Requested-Business-Unit-Id": user.selectedBusinessUnitId
      }
    };
    const response = yield call(axios.post, url, action.payload, opts);

    if (response.status === 200) {
      const profileLogoUrl = response.data;
      yield put(actions.addLogo.success(profileLogoUrl));
      notify("success", strings.profile.logo.updated);
    }
  } catch (error) {
    yield put(actions.addLogo.failure(error));
    if ("response" in error && "status" in error.response) {
      const exception = error as AxiosError;
      if (exception.response?.status === 413) {
        notify(
          "danger",
          strings.profile.logo.errorUpdated,
          strings.profile.logo.exceedSizeError
        );
      } else if (exception.response?.status === 415) {
        notify(
          "danger",
          strings.profile.logo.errorUpdated,
          strings.profile.logo.imageTypeError
        );
      } else {
        notify("danger", strings.profile.logo.errorUpdated);
      }
    }
  }
}

export const sagas = [
  takeLatest(getType(actions.loadProfile.request), loadProfile),
  takeLatest(getType(actions.updateProfile.request), updateProfile),
  takeLatest(getType(actions.addLogo.request), addLogo)
];
