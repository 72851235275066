import { FeatureFlag } from "api";
import { useSelector } from "react-redux";
import { selectors } from "./state";

export interface FeatureFlags {
  isFeatureEnabled: boolean;
  featureFlags: FeatureFlag[];
}

export function useFeatureFlags(key: string) {
  const configurationSettings = useSelector(selectors.getConfigurationSettings);
  const featureFlags = configurationSettings?.featureFlags ?? [];
  const isFeatureEnabled = !!featureFlags.find(
    flag => flag.active && flag.key === key
  );
  return { isFeatureEnabled, featureFlags };
}
