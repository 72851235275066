const data: Record<string, any> = {
  errorBoundary: {
    title: "Une erreur inattendue s'est produite.",
    body:
      "Nous avons enregistré l'erreur et nous nous excusons pour la gêne occasionnée. Veuillez réessayer.",
    button: "Retour à la page d'accueil",
    sandbox: {
      title: "BAC À SABLE : Une erreur inattendue s'est produite.",
      body:
        "Le mode bac à sable a rencontré une erreur inattendue. Veuillez réinitialiser ou désactiver le mode bac à sable.",
      resetButton: "Réinitialiser le mode bac à sable",
      disableButton: "Désactiver le bac à sable"
    }
  },
  profile: {
    minorityStatusList: {
      added: "Certification ajoutée",
      errorAdded: "Erreur lors de l'ajout d'une certification",
      saved: "Certifications sauvegardées",
      errorSaved:
        "Erreur lors de l'enregistrement des certifications. Réessayez plus tard.",
      updated: "Certifications mises à jour",
      errorUpdated:
        "Erreur lors de la mise à jour des certifications. Réessayez plus tard.",
      deleted: "Certifications supprimées",
      errorDeleted:
        "Erreur lors de la suppression de certifications. Réessayez plus tard.",
      loading: "Chargement...",
      noMinorityStatusHelpText: {
        title: "Pas de certifications",
        body:
          "Les certifications ajoutées à un profil peuvent être utilisées pour inclure des informations minoritaires sur votre entreprise."
      },
      noMinorityStatusButtonText: "Ajouter une certification",
      deleteConfirmation: "Êtes-vous sûr de vouloir supprimer?",
      minorityStatus: {
        updated: "Mise à jour du statut de minorité",
        errorUpdated: "Erreur de mise à jour du statut de minorité",
        deleted: "Statut de minorité supprimé",
        errorDeleted: "Erreur de suppression du statut de minorité"
      }
    },
    mainDetails: {
      updated: "Le profil a été mis à jour avec succès.",
      errorUpdated: "Erreur de mise à jour du profil."
    },
    logo: {
      updated: "Image chargée avec succès",
      errorUpdated: "Erreur de chargement de l'image",
      exceedSizeError: "La taille de l'image ne doit pas dépasser 2 Mo.",
      imageTypeError:
        "Les formats d'image pris en charge sont .bmp, .jpeg et .png."
    }
  },
  contactManagement: {
    contactSearchModal: {
      noData: {
        title: "Aucun résultat trouvé",
        body:
          "Essayez de rechercher un contact par prénom, nom, téléphone ou courriel. Vous pouvez également effectuer une recherche par nom et code d'entreprise."
      },
      tabs: {
        contacts: "CONTACTS",
        companies: "ENTREPRISES"
      },
      searchBarPlaceholder: "Recherchez des contacts..."
    },
    vendorDetails: {
      title: "Détails principaux",
      newTitle: "Créer une nouvelle entreprise",
      editTitle: "Modifier les détails",
      save: "Sauvegarder",
      edit: "Modifier",
      minorityStatusesHeading: "Statuts des minorités",
      scopesHeading: "Champs d'application",
      notesHeading: "Notes",
      officeHeading: "Bureau"
    },
    vendorEditDetail: {
      addOfficeButton: "Ajouter un nouveau bureau",
      officeSectionTitle: "Bureaux",
      error: {
        title: "Erreur lors de l'enregistrement de la société",
        name: "Le surnom du bureau est requis"
      }
    },
    vendorLocationDetails: {
      nickname: "Surnom"
    },
    vendorList: {
      title: "Liste des entreprises",
      addCompany: "Ajouter une entreprise",
      searchPlaceholder: "Rechercher des entreprises..."
    },
    contactFields: {
      firstName: "Prénom",
      lastName: "Nom de famille",
      title: "Titre",
      email: "Adresse courriel",
      phone: "Numéro de téléphone",
      cell: "Numéro de portable",
      mainContact: "Contact principal",
      notes: "Notes"
    },
    contactList: {
      saved: "Contact ajouté!",
      errorSaved:
        "Erreur lors de l'enregistrement du contact. Réessayez plus tard.",
      errorMoved: "Erreur lors du déplacement du contact. Réessayez plus tard.",
      moveContact: "Contact déplacé!",
      updated: "Contact sauvegardé!",
      errorUpdated:
        "Erreur lors de la mise à jour du contact. Réessayez plus tard.",
      deleted: "Contact supprimé!",
      errorDeleted:
        "Erreur lors de la suppression d'un contact. Réessayez plus tard.",
      emailError: "Veuillez saisir une adresse courriel valide.",
      title: "Liste de contacts",
      loading: "Chargement...",
      noContactsHelpText: {
        title: "Aucun contact",
        body:
          "Les contacts ajoutés à une entreprise peuvent être liés à un projet."
      },
      noContactsButtonText: "Ajouter un contact",
      deleteConfirmation: "Êtes-vous sûr de vouloir supprimer {0}?",
      commandButton: {
        edit: "Modifier",
        move: "Déplacer",
        delete: "Supprimer"
      },
      visitContact: "Visiter le contact"
    },
    contactListModal: {
      modalAddTitle: "Ajouter un contact",
      modalEditTitle: "Modifier le contact",
      buttonAdd: "Ajouter",
      buttonSave: "Enregistrer",
      buttonCancel: "Annuler",
      emptyLocationsText:
        "Veuillez modifier les détails de l'entreprise pour ajouter un bureau",
      emptyScopeOfWorksText:
        "Veuillez modifier les détails de l'entreprise pour ajouter des champs d'application",
      officeLabel: "Bureau",
      scopesLabel: "Champs d'application"
    },
    contactListMoveContactModal: {
      moveContact: "Déplacer",
      toCompany: "Vers l'entreprise :",
      fromCompany: "De l'entreprise :",
      buttonMove: "Déplacer",
      buttonCancel: "Annuler",
      removingProductsAlert:
        "Les champs d'application suivants seront supprimés du contact parce que l'entreprise destinataire n'a pas défini ces champs d'application :",
      removingOneProductAlert:
        "Les champs d'application suivants seront supprimés du contact car l'entreprise de destination n'a pas défini ces champs d'application :"
    },
    minorityStatusList: {
      saved: "Statut de minorité sauvegardé",
      errorSaved:
        "Erreur lors de l'enregistrement du statut de minorité. Réessayez plus tard.",
      updated: "Statut de minorité mis à jour",
      errorUpdated:
        "Erreur lors de la mise à jour du statut de minorité. Réessayez plus tard.",
      deleted: "Statut de minorité supprimé",
      errorDeleted:
        "Erreur lors de la suppression du statut de minorité. Réessayez plus tard.",
      loading: "Chargement...",
      noMinorityStatusHelpText: {
        title: "Pas de statut de minorité",
        body:
          "Les statuts de minorité ajoutés à une entreprise peuvent être utilisés pour suivre les objectifs de minorité sur les projets et les estimations."
      },
      noMinorityStatusButtonText: "Ajouter un statut de minorité",
      deleteConfirmation: "Êtes-vous sûr de vouloir supprimer?"
    },
    minorityTypeModal: {
      title: "Créer un type de minorité",
      buttonAdd: "Ajouter",
      buttonCancel: "Annuler",
      validation: {
        codeRequiredText: "Le code est obligatoire",
        descriptionRequiredText: "La description est requise"
      },
      errorUpdated:
        "Erreur de mise à jour du type de minorité. Réessayez plus tard.",
      errorCreated:
        "Erreur lors de la création d'un type de minorité. Réessayez plus tard.",
      confirmBack:
        "Vous avez des modifications non enregistrées. Êtes-vous sûr de vouloir continuer?"
    },
    notInBU:
      "L'entreprise à laquelle vous avez essayé d'accéder ne fait pas partie de votre unité commerciale actuelle.",
    noDataPlaceholder: {
      office: {
        title: "Aucun bureau",
        buttonText: "Ajouter un nouveau bureau"
      },
      minorityStatus: {
        title: "Pas de statuts de minorité",
        body:
          "Pour ajouter un statut de minorité à cette entreprise, cliquez d'abord sur « Enregistrer » sur le nouveau fournisseur avec le champ « Code » rempli."
      }
    },
    companySetup: {
      title: "Configuration de l'entreprise",
      minorityTypeHeader: "Types de minorités",
      minorityType: {
        success: {
          added: "Type de minorité ajouté!",
          updated: "Type de minorité sauvé!"
        },
        errors: {
          created:
            "Erreur dans la création du type de produit. Réessayez plus tard.",
          updated:
            "Erreur de mise à jour du type de produit. Réessayez plus tard.",
          deleted:
            "Erreur de suppression du type de produit. Réessayez plus tard.",
          duplicated: "Le type de minorité avec le code « {0} » existe déjà."
        }
      },
      productType: {
        header: "Portée du travail",
        success: {
          added: "Portée du travail ajoutée!",
          updated: "Étendue des travaux enregistrée!"
        },
        errors: {
          created:
            "Erreur lors de la création du type de produit. Réessayez plus tard.",
          updated:
            "Erreur lors de la mise à jour du type de produit. Réessayez plus tard.",
          deleted:
            "Erreur lors de la suppression du type de produit. Réessayez plus tard.",
          duplicated: "L'étendue du travail avec le code '{0}' existe déjà."
        }
      }
    },
    import: {
      validationErrors: {
        email:
          "La ligne #{0} a une mauvaise adresse de courriel, si elle n'est pas corrigée, elle ne sera pas importée."
      }
    }
  },
  estimates: {
    active: "Actif",
    archived: "Archivé",
    recent: "Récent",
    synced: "Synchronisé",
    title: "Estimations",
    editTitle: "Modifier les estimations archivées",
    estimateAnalysis: "Analyse de l'estimation",
    keyIndicators: "Indicateurs-clés",
    estimateDetails: "Détails de l'estimation",
    estimateInformation: "Informations sur l'estimation",
    estimateLocation: "Estimer l'emplacement",
    estimateExcluded: "Exclure l'estimation",
    quoteManagement: "Gestion des devis",
    quoteSummary: "Résumé du devis",
    dashboard: "Tableau de bord",
    solicitationDashboard: "Tableau de bord de sollicitation",
    quoteInbox: "Boîte de réception de devis",
    createSolicitation: "Créer une sollicitation",
    createQuoteProposal: "Créer une proposition de devis",
    drafts: "Brouillons",
    sent: "Envoyé",
    vendorCommunication: "Communication fournisseur",
    noReports:
      "Les rapports ne sont pas encore disponibles pour cette estimation",
    noEstimatesTitle:
      "Nous n'avons trouvé aucune sollicitation pour cette estimation",
    noEstimatesHelperText:
      "Sélectionnez « Créer une sollicitation » dans le menu de gauche pour commencer à remplir\nce Tableau de bord de gestion des devis",
    reporting: "Rapports",
    allReports: {
      unavailable: {
        title: "Pas de rapports disponibles",
        text:
          "L'estimation est en cours de synchronisation, une fois terminée, les rapports seront prêts."
      },
      notFound: {
        title: "Aucun rapport trouvé",
        text:
          "Aucun rapport n'a pu être trouvé pour l'estimation actuelle. Essayez de resynchroniser cette estimation."
      }
    },
    reportDownload: "Télécharger le fichier PDF",
    cost: "Coût",
    costDescription:
      "Le rapport des coûts affiche les coûts de chaque élément d'enchères, ventilés par activité et type de coût.",
    costAndPrice: "Coût et prix",
    costAndPriceDescription:
      "Un rapport récapitulatif affichant une ventilation complète des coûts et des prix pour chaque élément d'enchères.",
    estimateRecap: "Récapitulatif de l'estimation",
    estimateRecapDescription:
      "Le rapport Récapitulatif de l'estimation vous montre comment les différences d'enchères/départs et le déséquilibre des prix des enchères affectent l'estimation. Il vous permet également de consulter les totaux et les pourcentages des coûts pour chaque type de coût.",
    reportsHelpText: "Les rapports sont à jour au",
    showAll: "Afficher tout",
    hideAll: "Cacher tout",
    close: "Fermer",
    showHideColumns: "Afficher/Masquer les colonnes",
    adjustFilters: "Ajuster les filtres",
    addFilters: "Ajouter des filtres",
    selectFilters: "Sélectionner les filtres",
    activeEstimates: "Estimations actives",
    activeEstimateTotal: "Total des estimations actives",
    archivedEstimates: "Estimations archivées",
    archivedEstimateTotal: "Estimation moyenne archivée",
    activeEstimateLocations: "Emplacements d'estimation active",
    noLocationFound: "Aucun emplacement trouvé",
    noLocationFoundHelpText:
      "Soit votre estimation ne contient aucune information de localisation, soit nous n'avons pas pu obtenir la latitude et la longitude avec les informations fournies.",
    gettingStarted: {
      title: "Accéder aux données d'estimation",
      text1:
        "Pour accéder aux données d'estimation, il est maintenant nécessaire que vous ayez l'abonnement 'HeavyBid Insights' dans HCSS Credentials. Les étapes pour commencer sont accessibles dans le centre d'aide HCSS.",
      instructions:
        "Vous n'êtes pas autorisé à afficher les informations sur les estimations. Cela peut être configuré par un administrateur dans la page Utilisateur Administrateur.",
      buttonIconTitle: "Commencez maintenant"
    },
    notInBU:
      "L'estimation à laquelle vous avez essayé d'accéder n'est pas dans votre unité commerciale actuelle.",
    customizeReporting: {
      customizeActivityTotals: "Personnaliser les totaux d'activité",
      save: "Sauvegarder",
      activityTotals: "Totaux des activités",
      placeholder: {
        button: "Ajouter un total",
        title: "Ajouter des totaux d'activité",
        body:
          "Cette page vous permettra d'ajouter une formule personnalisée de total d'activité. En spécifiant une expression, une unité et un style correspondant, vous pourrez voir les totaux de quantité et de coût pour les codes d'activité qui correspondent à ce que vous entrez dans toutes vos estimations HeavyBid synchronisées et archivées."
      },
      name: "Nom",
      nameRequired: "Le nom est requis",
      expression: "Expression",
      expressionRequired: "L'expression est requise",
      unit: "Unité",
      unitRequired: "L'unité est requise",
      add: "Ajouter",
      formulaMatch: {
        startsWith: "Commence avec",
        exactlyMatches: "Correspond exactement"
      }
    }
  },
  keyIndicators: {
    placeholder: "Rechercher des IRC",
    settings: "Paramètres",
    kpiDisplay: "Afficher les IRC marqués en tant que",
    classification: "Classification",
    search: "Recherche",
    normal: "Normal",
    warning: "Avertissement",
    outlier: "Valeur aberrante",
    na: "S/O",
    allKpis: "IRC totaux",
    kpisByCategory: "IRC par catégorie",
    help: "Aide",
    noCustomBody:
      "Synchronisez vos IRC personnalisés depuis HeavyBid pour les voir ici.",
    table: {
      currentValue: "Valeur actuelle",
      normalRange: "Plage normale",
      plot: "Parcelle",
      name: "Nom",
      calculationDetails: "Détails du calcul",
      noVariance: "Impossible de tracer. Pas d'écart dans la plage normale.",
      noData: "Il n'y a aucun IRC à afficher avec les filtres actuels.",
      allKpisHidden:
        "Aucun IRC n'est sélectionné. Veuillez sélectionner un ou plusieurs IRC pour afficher les données IRC."
    },
    categories: {
      labor: "Main-d'œuvre",
      equipment: "Équipement",
      material: "Matériel",
      subcontract: "Sous-traitance",
      markup: "Balisage",
      other: "Autre",
      custom: "Personnalisé",
      laborAndEquipment: "Main-d'œuvre et équipement"
    }
  },
  isRequired: "{0} est requis.",
  estimateInfo: {
    state: "État :",
    lastSync: "Dernière synchronisation :",
    keyDates: "Dates clés :",
    reviewDate: "Date de révision :",
    bidDate: "Date d'enchère :",
    startDate: "Date de début :",
    totals: "Totaux",
    totalCost: "Coût total :",
    bidTotal: "Total de l'enchère :",
    title: "Informations sur l'estimation",
    lastSummaryRunOn: "Dernière exécution récapitulative :",
    lastSpreadRunOn: "Dernière diffusion sur :"
  },
  estimateTable: {
    linkEstimates: "Relier les estimations",
    noLinkedEstimates: "Aucune estimation liée à afficher"
  },
  estimateModal: {
    title: "Relier les estimations",
    activeEstimates: "Estimations actives",
    errorLoading:
      "Un problème est survenu lors du chargement de vos estimations. Veuillez actualiser la page.",
    loading:
      "Les estimations sont en cours de chargement. Cela peut prendre quelques minutes la première fois.",
    columns: {
      code: "Code d'estimation",
      name: "Nom de l'estimation",
      bidDate: "Date d'enchère",
      lastModified: "Dernière mise à jour",
      isActiveEstimate: "Estimation active"
    }
  },
  quoteProposal: {
    notifications: {
      drafts: {
        success: "Suppression réussie du brouillon",
        error: "Impossible de supprimer le projet"
      }
    },
    deleteConfirmationName: "Êtes-vous sûr de vouloir supprimer {0}?",
    deleteConfirmationNoName: "Êtes-vous sûr de vouloir supprimer (sans nom)?",
    draftQuotes: "Devis du brouillon",
    sentQuotes: "Devis envoyés",
    columns: {
      name: "Nom de l'auteur",
      createdDate: "Date de création",
      dueDate: "Date d'échéance",
      vendors: "Vendeurs",
      type: "Type d'offre",
      code: "Code",
      description: "Description",
      email: "Courriel",
      minorityType: "Type de minorité",
      url: "URL",
      estimator: "Estimateur",
      vendorCode: "Code vendeur",
      vendorName: "Nom du vendeur",
      lastName: "Nom de famille",
      firstName: "Prénom",
      phoneNumber: "Numéro de téléphone"
    },
    links: {
      noDataText: "Ajouter des liens externes"
    },
    contacts: {
      removeContact: "Supprimer un contact",
      chooseContact: "Choisir un contact",
      addContact: "Ajouter un contact"
    },
    preview: {
      notifications: {
        emailError: "Erreur dans l'envoi des courriels. Veuillez réessayer."
      },
      personalEmail: "Compte courriel personnel",
      viaBusinessUnitEmail: "via le compte courriel de l'unité commerciale",
      viaOtherEmail: "via <{0}>",
      loading: "Chargement...",
      sendingEmail: "Envoi de courriels...",
      useCompanyEmail:
        "Utiliser le compte courriel personnel de votre entreprise",
      personalEmailNeedsAttention:
        "Votre compte courriel personnel a besoin d'attention",
      sendPreview: "Envoyer l'aperçu de la proposition",
      cancel: "Annuler",
      sendAll: "Envoyer tout",
      sendingFrom: "Envoi de",
      sendingTo: "Envoi à",
      emailSubject: "Objet du courriel",
      emailSubjectRequired: "L'objet du courriel est obligatoire.",
      emailPreview: "Aperçu du courriel",
      proposalPreview: "Aperçu de la proposition",
      loadingPreview: "Chargement de l'aperçu...",
      quoteProposalFor: "Proposition de devis pour « {0} »",
      quoteProposal: "Proposition de devis"
    },
    projectDetails: {
      code: "Code du projet",
      codeRequired: "Le code du projet est obligatoire.",
      name: "Nom du projet",
      nameRequired: "Le nom du projet est obligatoire.",
      location: "Localisation du projet"
    },
    setup: {
      emailsSentSuccessfully: "Les courriels ont été envoyés avec succès!",
      createProposal: "Créer une proposition",
      bidDate: "Date de l'offre",
      startDate: "Date de début",
      previewAndSend: "Prévisualisation et envoi",
      copyProposal: "Copier la proposition",
      contactDetails: "Détails du contact",
      proposalDetails: "Détails de la proposition",
      enterPrices: "Entrez les prix",
      projectDetails: "Détails du projet",
      folders: "Dossiers",
      chooseFolders: "Choisissez des dossiers",
      recipients: "Destinataires",
      chooseVendors: "Choisissez vos fournisseurs",
      atLeastOneVendor: "Vous devez sélectionner au moins un fournisseur",
      atLeastOnePrice: "Vous devez saisir les prix d'au moins un article",
      externalLinks: "Liens externes",
      addHCSSPlans: "Ajouter des plans HCSS",
      addLinks: "Ajouter des liens",
      attachments: "Pièces jointes",
      uploadFiles: "Charger des fichiers",
      excelDropdownPlaceHolder: "Exporter",
      confirmBack:
        "Vous avez des modifications non enregistrées. Êtes-vous sûr de vouloir continuer?",
      savingDraft: "Sauvegarde du brouillon",
      saveDraft: "Sauvegarder le brouillon",
      proposalItems: "Eléments de la proposition",
      noPricesHelpText: {
        title: "Aucun élément",
        body: "Au moins un élément doit être ajouté à la proposition de devis."
      },
      noAttachmentsHelpText: {
        title: "Pas de pièces jointes",
        body: "L'ajout de pièces jointes est facultatif."
      }
    },
    attachments: {
      notifications: {
        linkCopied: {
          title: "Copié!",
          message: "Lien copié dans le presse-papiers"
        }
      },
      uploadFiles: "Charger les fichiers"
    },
    details: {
      initialProposal: "Proposition initiale",
      followUp: "Suivi",
      addendum: "Addendum",
      quoteNotes: "Notes sur la proposition",
      typeRequired: "Le type de devis est obligatoire.",
      quoteName: "Nom de la proposition",
      nameRequired: "Le nom du devis est obligatoire.",
      priceExpiration: "Date d'expiration du prix",
      priceExpirationRequired: "La date d'expiration du prix est obligatoire.",
      firstName: "Prénom",
      firstNameRequired: "Le prénom du contact est obligatoire.",
      lastName: "Nom de famille",
      lastNameRequired: "Le nom de famille du contact est obligatoire.",
      email: "Courriel",
      phone: "Téléphone",
      recipients: "Destinataires",
      contactsRequired:
        "Au moins un contact est requis. Tous les contacts doivent avoir des courriels valides.",
      contactsPlaceholder: "Cliquez ici...",
      addContact: "Ajouter un contact",
      sendRepliesTo: "Envoyer les réponses à...",
      noEmailProvided: "(pas de courriel)",
      receiveProposal: "Marquer comme lu",
      statusTitleQuestion:
        "Faites savoir à {0} que vous avez reçu cette proposition de devis?",
      statusTitleReceived: "Un accusé de réception a été envoyé à {0}.",
      statusToolTipReceived:
        "L'expéditeur a été informé que vous avez lu la proposition qu'il vous a envoyée."
    },
    import: {
      importExcel: "Importer des prix depuis Excel",
      importExcelQP: "Importer des articles à partir d'Excel",
      step1: "ÉTAPE 1 : Téléchargez le modèle d'importation.",
      step2: "ÉTAPE 2 : Importez le fichier Excel.",
      subTextQP1:
        "Saisissez les articles et leurs prix unitaires dans Excel. Enregistrez le fichier.",
      subTextQP2:
        "Sélectionnez le fichier pour importer les articles et leurs prix unitaires.",
      subText1:
        "Saisissez les prix unitaires dans Excel pour tous les articles que vous souhaitez coter.",
      subText3:
        "Ajoutez de nouveaux articles sur les lignes vierges, si nécessaire. Enregistrez le fichier.",
      subText2:
        "Sélectionnez le fichier pour importer les prix unitaires des articles demandés et tous les champs des articles nouvellement ajoutés.",
      close: "Fermer",
      selectFile: "SÉLECTIONNER LE FICHIER",
      download: "TÉLÉCHARGER",
      importXlHeader1:
        "Saisissez le prix unitaire des articles demandés pour lesquels vous avez l'intention de faire un devis. NE modifiez PAS la description des articles demandés.",
      importXlHeader2:
        "Saisissez la description, la quantité, l'unité et le prix unitaire pour tous les NOUVEAUX articles.",
      importXlHeader3:
        "Lorsque vous importerez ce fichier sauvegardé, tous ces articles remplaceront les articles énumérés dans la proposition."
    },
    prices: {
      addPrices: "Ajouter un prix"
    },
    review: {
      title: "Révision de la proposition de devis",
      priceExpiration: "Date d'expiration du prix",
      sentTo: "Envoyé à",
      sentFrom: "Envoyé de",
      code: "Code",
      name: "Nom",
      location: "Localisation",
      jobDetails: "Détails de l'emploi"
    }
  },
  quotes: {
    banner:
      "HeavyBid 2022.0.0 ou une version plus récente est nécessaire pour mapper et synchroniser les prix des articles supplémentaires de n'importe quel vendeur avec les dossiers de devis de HeavyBid lorsque ce vendeur n'est pas actuellement répertorié.",
    overwrittenMessage:
      "Pour un ou plusieurs articles de ce devis, la description, la quantité, l'unité et/ou le prix unitaire ont été remplacés.",
    folderSelectionClear: "(Aucun)",
    moduleName: "Gestion des citations",
    awaitingResponse: "En attente d'une réponse",
    invitationAccepted: "Invitation acceptée",
    invitationDeclined: "Invitation refusée",
    receivedNewPrices: "Nouveaux prix reçus",
    reviewed: "Examiné",
    MarkedAsUnread: "Marquer comme non lu",
    rejected: "Rejeté",
    status: "Statut",
    folderDetails: "Détails du dossier",
    folderNoItemsNoVendors:
      "Ce dossier ne contient pas d'éléments de devis ni de fournisseurs.",
    folderNoItems: "Ce dossier ne contient aucun élément de devis.",
    folderNoVendors: "Ce dossier ne contient aucun fournisseur.",
    quotedAmount: "Montant coté",
    pluggedAmount: "Montant bouché",
    adjustments: "Ajustements",
    totals: "Totaux",
    item: "Article",
    resource: "Code",
    cancel: "Annuler",
    continue: "Continuer",
    confirmRevertChnges: "Rétablir les modifications",
    quantity: "Quantité",
    description: "Description",
    disabledTooltipForEditing:
      "Désactiver le mode édition pour sélectionner ce bouton.",
    code: "Code",
    unit: "Unité",
    folder: "Dossier",
    unitPrice: "Prix unitaire",
    extendedPrice: "Prix étendu",
    plugPrice: "Prix de la prise",
    s: "S",
    p: "P",
    subContractor: "Sous-traitant",
    material: "Matériel",
    estimator: "Estimateur",
    notes: "Notes",
    noDescription: "Aucune description",
    noResource: "Aucune ressource",
    quoteHasBeenReviewed: "Le devis a été examiné.",
    quoteReviewedError:
      "Une erreur s'est produite lors de la mise à jour du devis.",
    quoteHasBeenMarkedAsUnread: "Le devis a été marqué comme non lu.",
    quoteHasBeenRejected: "Le devis a été rejeté.",
    quoteHasBeenManuallyEntered:
      "Le devis sera saisi manuellement dans HeavyBid.",
    quoteChangesHaveBeenReverted: "Les changements de devis ont été annulés.",
    quoteChangesRevertError:
      "Une erreur s'est produite lors de l'annulation des modifications apportées aux devis.",
    quoteValidationError:
      "Veuillez sélectionner un dossier pour au moins 1 article.",
    quoteVendorFolderValidationError:
      "Il y a un article qui ne peut pas être mappé au bon dossier de devis parce que ce fournisseur n'est pas encore dans le dossier de devis HeavyBid pour cet article. Allez dans HeavyBid, ajoutez ce vendeur au dossier de devis correct et sélectionnez Sync Quote Prices dans la fenêtre HeavyBid Quote Summary. Une fois que vous aurez actualisé cette fenêtre, la sélection du dossier sera disponible pour les éléments du devis. Procédez au mappage des éléments dans le dossier de devis approprié avant de marquer comme révisé.",
    quoteVendorFolderValidationErrorTitle: "Le vendeur a besoin d'un dossier",
    quoteRejectedError:
      "Une erreur s'est produite lors de la mise à jour du devis.",
    updatingPrices: "Mise à jour des prix...",
    ignoringPrices: "Ignorer les prix...",
    quoteDetails: "Détails du devis",
    closeQuote: "Fermer le devis",
    revertingPrices: "Revenir aux prix initiaux.",
    revertModalContentLine1:
      "Tous les champs de l'élément de devis modifiés seront ramenés à leur valeur d'origine.",
    revertModalContentLine2: "Voulez-vous continuer?",
    queueForSync: {
      button: "File d'attente pour la synchronisation",
      disabledTitle:
        "Ce bouton est désactivé lorsque les prix sont tous des zéros. Activer l'édition pour ajouter les prix du fournisseur dans le devis ci-dessous.",
      enableTitle:
        "En sélectionnant Queue for Sync, les prix des vendeurs seront mis en file d'attente pour être synchronisés avec HeavyBid lors de la prochaine synchronisation à partir de la fenêtre Résumé du devis de HeavyBid"
    },
    vendorDetails: "Détails du vendeur",
    prices: "Prix",
    validThrough: "Valable pour {0}",
    vendorNotes: "Notes du vendeur",
    total: "Total",
    internalNotes: "Notes internes",
    company: "Entreprise",
    vendorContact: "Contact du vendeur",
    folderNotFound: "Le dossier de cet article n'a pas été trouvé.",
    na: "S/O",
    chooseFolder: "Élément de carte...",
    syncRequired: "Synchronisation requise",
    vendor: "Vendeur",
    viewDetails: "Voir les détails",
    type: "Type",
    selectedVendors: "Vendeur(s) sélectionné(s)",
    folderTotal: "Total du dossier",
    name: "Nom",
    minorityStatus: "Statut de minorité",
    selected: "Sélectionné",
    vendorTotal: "Total du vendeur",
    selectedVendor: "Vendeur sélectionné",
    folderNotes: "Notes du dossier",
    quoteInbox: "Boîte de réception des devis",
    new: "Nouveau",
    pending: "En attente",
    all: "Tout",
    itemsBidOfTotal: "{0} de {1}",
    itemsBid: "Articles proposés",
    invitedOn: "Invité sur",
    lastActivity: "Dernière activité",
    receivedOn: "Reçu le",
    reviewedOn: "Révisé le",
    rejectedOn: "Rejeté le",
    extraItems: "Articles supplémentaires",
    quoteSummary: "Résumé du devis",
    gridView: "Vue de la grille",
    listView: "Vue de la liste",
    details: {
      address: "KT TAPE",
      adjExtendedPrice: "Prix étendu ajusté",
      adjUnitPrice: "Prix unitaire ajusté",
      adjustment: "Ajustement :",
      anticipatedStartDate: "Date de début prévue",
      attachments: "Pièces jointes",
      bidDate: "Date de l'offre",
      bidding: "Appel d'offres",
      blanks: {
        description: "La description est obligatoire.",
        unit: "L'unité est requise.",
        unitPrice: "Le prix est requis.",
        quantity: "La quantité est requise."
      },
      changeStatus: "Modifier l'état",
      clientNo: "Numéro de client",
      company: "Société",
      contactInformation: "Coordonnées",
      contacts: "Contact",
      description: "Description",
      downloadAll: "Téléchargez tout",
      extendedPrice: "Prix étendu",
      headerMessage: "{0} vous a invité à faire une offre sur {1} à {2}",
      importantDates: "Dates importantes",
      itemDetails: "Détails de l'article",
      enterPrices: "Entrez les prix",
      guestUserBannerText: [
        "Voulez-vous voir vos devis précédents? Créez votre compte HCSS gratuit!",
        "Vous voulez gérer vos contacts et envoyer des propositions professionnelles? Je vous ai dit que c'était gratuit?"
      ],
      guestUserBannerLinkText: "En savoir plus",
      job: "Emploi",
      jobSite: "Site de travail",
      lastSubmissionMessage:
        "Un devis a déjà été soumis le {0} à {1} pour {2}.",
      location: "Emplacement",
      mainContact: "Contact principal",
      name: "Nom",
      noItems: "Pas d'éléments",
      noItemsMessage:
        "Cette invitation a été envoyée sans éléments de devis. Veuillez examiner les informations sur le projet, les liens externes ou les fichiers inclus, et faire savoir à {0} si vous êtes intéressé par la soumission de ce projet. Vous pouvez éventuellement ajouter des éléments supplémentaires en cliquant ci-dessous.",
      noItemsAddButton: "Ajouter des éléments supplémentaires",
      notBidding: "Ne pas faire d'offre",
      notes: "Remarques",
      notesTooLong:
        "Veuillez raccourcir les notes, car elles ont dépassé les 900 000 caractères.",
      projectDetails: "Détails du projet",
      pageTitle: "Détails de la demande de devis",
      print: "Imprimer",
      projectId: "Identifiant du projet",
      projectInformation: "Informations sur le projet",
      projectName: "Nom du projet",
      quantity: "Quantité",
      quoteDueDate: "Date d'échéance du devis",
      roundingDiff: "Différence d'arrondi :",
      reviewSubmit: "Révisez et soumettez",
      copyProposal: "Copier la proposition",
      statusTitleNo: "Vous n'êtes pas en train de faire une offre!",
      statusTitleQuestion: "Allez-vous faire une offre pour ce projet?",
      statusTitleYes: "Vous faites une offre!",
      statusTooltipNo:
        "L'entrepreneur saura ainsi que vous n'avez pas l'intention de lui soumettre des prix.",
      statusTooltipQuestion:
        "L'entrepreneur saura ainsi que vous avez l'intention de soumettre des prix, même si vous revenez plus tard.",
      statusTooltipYes:
        "L'entrepreneur saura ainsi que vous allez lui soumettre des prix.",
      submitPromptMessage:
        "Vous êtes sur le point de soumettre des prix en votre nom ou au nom de votre entreprise. Après avoir soumis vos prix, vous ne pourrez plus les modifier. Voulez-vous continuer?",
      submitPromptTitle: "Soumettre un prix des devis?",
      submitting: "Soumission...",
      subtotal: "Sous-total :",
      subItem: {
        addSubitem: "Ajoutez votre premier sous-article",
        add: "Ajouter",
        save: "Sauvegarder",
        cancel: "Annuler",
        removeSubitem: "Supprimer le sous-article",
        noSubItemsHelpText: {
          title: "Pas de sous-items",
          body:
            "Créez des sous-postes pour diviser rapidement des articles plus importants en éléments plus petits. Les totaux seront regroupés en une valeur finale."
        }
      },
      total: "Total :",
      unit: "Unité",
      unitPrice: "Prix unitaire",
      urlLinks: "Liens des URL",
      unitMismatchMessage:
        "Il y a une différence d'unité entre l'article sélectionné dans le devis HeavyBid et l'article supplémentaire de ce vendeur. Corrigez l'unité afin de synchroniser le prix unitaire de cet article avec HeavyBid.",
      confirmModal: {
        cancel: "Retourner",
        continue: "Continuer"
      },
      closeQuoteModal: {
        title: "Fermer le devis",
        warning:
          "En sélectionnant « Fermer le devis », les prix du vendeur NE SERONT PAS synchronisés avec les devis HeavyBid.",
        prompt: "Quelle est l'affirmation la plus appropriée?",
        overlayMessage: "Fermeture du devis.",
        reject: {
          label:
            "Je rejette les prix de ces fournisseurs et ne les prendrai pas en considération.",
          text:
            "La fiche d'appel d'offres de ce devis passera dans la colonne « Terminé » avec le statut « Rejeté », pourra être consultée et pourra être rouverte ultérieurement."
        },
        manual: {
          label:
            "Je saisirai manuellement les prix des fournisseurs dans HeavyBid.",
          text:
            "La fiche de sollicitation de ce devis passera dans la colonne « Terminé » avec le statut « Saisi manuellement »."
        }
      }
    },
    quoteReview: {
      title: "Révision du devis",
      back: "Retour aux détails",
      submit: "Soumettre le devis",
      date: "Date",
      project: "Projet",
      validThrough: "Valable jusqu'au",
      total: "Total",
      submitTo: "Soumettre à",
      contactUs: "Contactez-nous",
      clientNo: "Nº du client",
      description: "Description",
      quantity: "Quantité",
      unit: "Unité",
      unitPrice: "Prix unitaire",
      extendedPrice: "Prix étendu",
      totalAmount: "MONTANT TOTAL",
      notes: "Notes"
    },
    submission: {
      title: "Soumission réussie",
      viewQuote: "Voir le devis",
      signupTitle: "maintenant disponible pour",
      free: "GRATUIT",
      discover: "Découvrez-en plus"
    },
    emptyState: {
      title: "Aucun dossier trouvé",
      text:
        "Pour commencer, synchronisez les données à partir de l'écran « Résumé du devis » dans HeavyBid."
    },
    pricesLastSynced: "Derniers prix synchronisés :"
  },
  vendorCommunication: {
    columns: {
      vendorCode: "Code du fournisseur",
      vendorName: "Nom de fournisseur",
      action: "Dernière activité",
      timeStamp: "Date de la dernière activité",
      minorityType: "Type de minorité",
      email: "Contact",
      solicitationName: "Nom de la demande de soumissions",
      projectName: "Nom du projet"
    },
    manualModal: {
      phoneCallMade: "A effectué un appel téléphonique",
      phoneCallReceived: "A reçu un appel téléphonique",
      emailSent: "A envoyé un courriel",
      emailReceived: "A reçu un courriel",
      faxSent: "A envoyé un fax",
      faxReceived: "A reçu un fax",
      mailSent: "A envoyé un courrier",
      mailReceived: "A reçu un courrier",
      inPerson: "Réunion en personne",
      communicationSuccess: "Communication enregistrée avec succès",
      communicationError: "Impossible d'enregistrer la communication",
      title: "Enregistrer la communication",
      type: "Type de communication",
      date: "Date de la communication",
      vendor: "Fournisseur",
      estimate: "Estimation",
      projectName: "Nom du projet",
      contactInfo: "Coordonnées",
      firstName: "Prénom",
      lastName: "Nom",
      email: "Courriel",
      message: "Message",
      cancel: "Annuler",
      submit: "Envoyer",
      previewReport: "Prévisualiser le rapport"
    },
    details: {
      recordCommunication: "Enregistrer la communication",
      print: "Imprimer",
      vendor: "Fournisseur",
      project: "Projet",
      minorityType: "Type de minorité",
      communicationDetails: "Détails de la communication"
    },
    timeline: {
      eventHistory: "Historique de l'événement",
      filters: "Filtres",
      filterContent: "Filtrer le contenu",
      clickToExpand: "Cliquez pour agrandir...",
      initial: "une demande de soumissions initiale",
      followUp: "une demande de soumissions de suivi",
      addendum: "un addenda",
      noName: "aucun nom",
      solicitation: {
        initial: {
          base:
            "{0} {1} a envoyé {2} {3} à partir {4} d'une demande de soumissions initiale ({5}).",
          special:
            "{0} {1} a envoyé à {2} {3} à partir {4} d'une demande de soumissions initiale (sans nom)."
        },
        followUp: {
          base:
            "{0} {1} a envoyé à {2} {3} à partir {4} d'une demande de soumissions de suivi ({5}).",
          special:
            "{0} {1} a envoyé à {2} {3} à partir {4} d'une demande de soumissions de suivi (sans nom)."
        },
        addendum: {
          base: "{0} {1} a envoyé à {2} {3} à partir {4} d'un addenda ({5}).",
          special:
            "{0} {1} a envoyé à {2} {3} à partir {4} d'un addenda (sans nom)."
        }
      },
      sub: {
        accepted: "{0} a indiqué qu'il soumissionnera pour ce projet",
        rejected: "{0} a indiqué qu'il ne soumissionnera pas pour ce projet",
        submittedPrices: "{0} a soumis un devis pour {1}"
      },
      gc: {
        reviewed: "{0} {1} a évalué un devis pour {2}",
        markedAsUnread: "{0} {1} a indiqué qu'un devis n'était pas lu",
        rejected: "{0} {1} a rejeté un devis pour {2}",
        manuallyRejected:
          "{0} {1} a choisi de saisir manuellement ces prix directement dans HeavyBid.",
        queuedForSync:
          "{0} {1} a mis en file d'attente un devis pour {2} à synchroniser avec HeavyBid."
      },
      manual: {
        phoneCall: {
          sent: "{0} {1} a fait un appel téléphonique à {2} {3}",
          received: "{0} {1} a reçu un appel téléphonique de {2} {3}"
        },
        email: {
          sent: {
            base: "{0} {1} a envoyé un courriel à {2} {3} ({4})",
            special:
              "{0} {1} a envoyé un courriel à {2} {3} (aucun courriel n'a été fourni)"
          },
          received: {
            base: "{0} {1} a reçu un courriel de {2} {3} ({4})",
            special:
              "{0} {1} a reçu un courriel de {2} {3} (aucun courriel n'a été fourni)"
          }
        },
        fax: {
          sent: "{0} {1} a envoyé une télécopie à {2} {3}",
          received: "{0} {1} a reçu une télécopie de {2} {3}"
        },
        mail: {
          sent: "{0} {1} a envoyé un courrier à {2} {3}",
          received: "{0} {1} a reçu du courrier de {2} {3}"
        },
        meeting: "{0} {1} rencontré en personne avec {2} {3}",
        gcEnteredPricesForSub:
          "{0} {1} a saisi les prix au nom du sous-traitant"
      },
      unknownAction: "Action d'événement inconnue",
      will: "fera",
      willNot: "ne fera pas"
    },
    report: {
      vendor: "Fournisseur :",
      bidding: "Soumission? :",
      yes: "Oui",
      no: "Non",
      unknown: "Inconnu",
      minority: "Minorité :",
      action: "Action",
      actions: {
        sentInitialSolicitation:
          "A envoyé l'invitation initiale à soumissionner",
        sentFollowUpSolicitation:
          "Envoi d'une invitation à soumissionner de suivi",
        sentAddendumSolicitation:
          "Envoyé une invitation à soumissionner par addenda",
        subAcceptedInvite: "Invitation acceptée par le sous-traitant",
        subRejectedInvite: "Invitation rejetée par le sous-traitant",
        subSubmittedPrices: "Prix soumis par le sous-traitant",
        gcRejectedPrices: "Prix refusés par l'entrepreneur général",
        gcReviewedPrices: "Prix examinés par l'entrepreneur général",
        madePhoneCall: "A effectué un appel téléphonique",
        receivedPhoneCall: "A reçu un appel téléphonique",
        sentEmail: "A envoyé un courriel",
        receivedEmail: "A reçu un courriel",
        sentFax: "A envoyé une télécopie",
        receivedFax: "A reçu une télécopie",
        sentMail: "A envoyé un courrier",
        receivedMail: "A reçu un courrier",
        inPersonMeeting: "Se sont rencontrés en personne",
        gcEnteredPricesForSub:
          "L'entrepreneur général a inscrit les prix pour le compte du sous-traitant",
        gcReconsideredPrices:
          "L'entrepreneur général a marqué le devis rejeté comme Non lu",
        gcReconsideredManuallyEnteredPrices:
          "Entrepreneur général marqué le devis saisi manuellement comme Non lu",
        gcManuallyEnteredPrices:
          "L'entrepreneur général a saisi manuellement les prix de soumission dans HeavyBid",
        gcQueuedForSync:
          "L'entrepreneur général a mis le devis en file d'attente pour le synchroniser avec HeavyBid.",
        default: "Dernière communication"
      },
      description: "Description / message",
      dateTime: "Date / heure",
      vendorContact: "Contact du fournisseur",
      user: "Utilisateur",
      code: "Code estimatif :",
      linkedEstimate: "Estimation couplée :",
      name: "Nom de l'estimation :",
      owner: "Propriétaire :",
      bidDate: "Date de la soumission :",
      title: "Rapport de communication",
      vendorComTitle: "Rapport de communication avec le fournisseur",
      preview: "Rapport de communication - Aperçu",
      vendorPreview: "Rapport de communication avec le fournisseur - Aperçu",
      back: "Retour"
    }
  },
  solicitations: {
    notifications: {
      drafts: {
        success: "Brouillon supprimé avec succès",
        error: "Impossible de supprimer le brouillon"
      }
    },
    deleteConfirmationName: "Voulez-vous vraiment supprimer {0}?",
    deleteConfirmationNoName: "Voulez-vous vraiment supprimer (sans nom)?",
    draftSolicitations: "Brouillon des demandes de soumissions",
    sentSolicitations: "A envoyé des demandes de soumissions",
    columns: {
      solName: "Nom de la demande de soumission",
      name: "Nom",
      createdDate: "Date de création",
      dueDate: "Date d'échéance",
      estimateId: "Code d'estimation",
      projectName: "Nom du projet",
      vendors: "Vendeurs",
      type: "Type",
      code: "Code",
      description: "Description",
      email: "Courriel",
      minorityType: "Type de minorité",
      url: "Adresse URL",
      folder: "Dossier",
      estimator: "Estimateur",
      folderNotes: "Notes du dossier",
      vendorCode: "Code du vendeur",
      vendorName: "Nom du vendeur",
      lastName: "Nom de famille",
      firstName: "Prénom",
      phoneNumber: "Numéro de téléphone"
    },
    links: {
      noDataText: "Ajouter des liens externes"
    },
    vendors: {
      removeVendor: "Supprimer un vendeur",
      chooseVendors: "Choisir les vendeurs"
    },
    folders: {
      noFolders: "Aucun dossier",
      noFolderInfo:
        "Les invitations envoyées sans dossiers/articles comprendront des informations sur le projet, des liens externes, des fichiers et les détails de l'appel d'offres saisis ci-dessus.",
      chooseFolders: "Choisir les dossiers",
      removeFolder: "Supprimer un dossier",
      material: "Matériel",
      subcontractor: "Sous-traitant"
    },
    preview: {
      notifications: {
        emailError:
          "Une erreur s'est produite lors de l'envoi des courriels. Veuillez réessayer."
      },
      personalEmail: "Boîte aux lettres électronique personnelle",
      viaBusinessUnitEmail:
        "via le compte de messagerie de l'unité commerciale",
      viaOtherEmail: "via <{0}>",
      genericEmail: "Courriel générique ({0})",
      loading: "Chargement...",
      sendingEmail: "Envoi du courriel...",
      useCompanyEmail:
        "Utilisez la boîte aux lettres électronique personnelle de votre entreprise",
      personalEmailNeedsAttention:
        "Votre boîte aux lettres électronique personnelle a besoin d'attention",
      sendPreview: "Envoyer un avis de sollicitation",
      cancel: "Annuler",
      sendAll: "Tout envoyer",
      sendingFrom: "Envoyer depuis",
      sendingTo: "Envoyer à",
      emailSubject: "Objet du courriel",
      emailSubjectRequired: "L'objet du courriel est obligatoire.",
      emailPreview: "Visualisation du courriel",
      loadingPreview: "Chargement de la visualisation...",
      quoteRequestFor: "Demande du devis pour « {0} »",
      quoteRequest: "Demande du devis",
      emailNote: "Note du courriel",
      emailNoteLimit: "Nombre de caractères limité à 200.",
      emailNotePlaceholder:
        "Saisissez une brève note pour tous les vendeurs de cet appel d'offres.\nCette note apparaîtra dans le courriel d'invitation à soumissionner et est limitée à 200 caractères."
    },
    solicitationSetup: {
      code: "Code du projet",
      codeRequired: "Le code du projet est obligatoire.",
      name: "Nom du projet",
      nameRequired: "Le nom du projet est obligatoire.",
      location: "Localisation du projet",
      header1: "Projet",
      header2: "Estimation",
      addNewProject: "Ajouter un nouveau projet",
      detailsBelow:
        "Les détails du projet ci-dessous s'afficheront dans la demande envoyée...",
      projectHelp1:
        "Cette liste est constituée des projets issus du suivi des projets.",
      projectHelp2:
        "Vous pouvez rapidement ajouter un projet en sélectionnant le lien à droite.",
      projectHelp3:
        "Un nom de projet est nécessaire pour envoyer une demande du devis.",
      estHelp1:
        "La sélection d'un devis est facultative. Toutefois, si vous souhaitez envoyer des éléments de dossier du devis dans l'appel d'offres, vous devez sélectionner le devis pour que ces dossiers soient disponibles.",
      estHelp2:
        "Cette liste est constituée des estimations actives dont vous disposez.",
      estHelp3:
        "Un devis actif : est un devis HeavyBid pour lequel vous avez activé la préférence de devis pour l'historique des devis et synchronisé les éléments du devis sur ce site web.",
      helpText3Est:
        "L'estimation actuelle et le projet doivent être liés pour que l'appel d'offres soit envoyé.",
      addProjectButtonText: "Ajouter un nouveau projet",
      linkWarning:
        "Le projet que vous avez sélectionné n'est pas encore lié au devis sélectionné. Souhaitez-vous les lier?",
      linkWarningEst:
        "Le nouveau projet que vous avez créé n'est pas encore lié au devis en cours. Souhaitez-vous les lier?"
    },
    setup: {
      emailsSentSuccessfully: "Envoi du courriel réussi!",
      createSolicitation: "Créer un appel d'offres",
      bidDate: "Durée de l'offre",
      startDate: "Date de début",
      previewAndSend: "Visualisation et envoi",
      solicitationDetails: "Détails de l'appel d'offres",
      solicitationSetup: "Configuration de l'appel d'offres",
      folders: "Dossiers",
      chooseFolders: "Choisir un dossier",
      vendors: "Vendeurs",
      chooseVendors: "Sélectionnez les vendeurs",
      atLeastOneVendor: "Vous devez sélectionner au moins un vendeur",
      requiredFields:
        "Les champs suivants sont obligatoires : code vendeur, nom du vendeur, prénom, nom de famille et courriel.",
      externalLinks: "Liens externes",
      addHCSSPlans: "Ajouter des plans HCSS",
      addLinks: "Ajouter des liens",
      attachments: "Pièces jointes",
      uploadFiles: "Téléchargez les fichiers",
      confirmBack:
        "Vous avez des modifications non sauvegardées. Êtes-vous sûr de vouloir continuer?",
      savingDraft: "Sauvegarde du brouillon",
      saveDraft: "Sauvegarder le brouillon",
      noFoldersHelpText: {
        title: "Aucun dossier",
        body:
          "Les appels d'offres envoyés sans dossiers ou articles comprendront toujours les informations relatives à l'appel d'offres et du projet, les liens externes et les pièces jointes."
      },
      noEstimateFoldersHelpText: {
        title: "Aucune estimation sélectionnée",
        body:
          "Pour sélectionner les dossiers à solliciter, choisissez d'abord une estimation dans le menu déroulant de la partie « Configuration de la sollicitation » de cette page. Vous pouvez toujours solliciter sans sélectionner de dossiers."
      },
      noVendorsHelpText: {
        title: "Aucun vendeur",
        body: "Un vendeur au moins doit être sélectionné."
      },
      noLinksHelpText: {
        title: "Aucun lien",
        body: "Il est facultatif d'ajouter des liens vers des sites externes."
      },
      noAttachmentsHelpText: {
        title: "Aucune pièce jointe",
        body: "L'ajout de pièces jointes est facultatif."
      }
    },
    attachments: {
      notifications: {
        linkCopied: {
          title: "Copié!",
          message: "Lien copié dans la presse-papier"
        }
      },
      uploadFiles: "Téléchargez les fichiers"
    },
    details: {
      initialSolicitation: "Appel d'offres initial",
      followUp: "Suivi",
      addendum: "Addenda",
      solicitationNotes: "Notes de l'appel d'offres",
      MessageType: "Type d'appel d'offres",
      typeRequired: "Le type d'appel d'offres est obligatoire.",
      solicitationName: "Intitulé de l'appel d'offres",
      nameRequired: "Le nom de l'appel d'offres est obligatoire.",
      quoteDueBy: "Date d'échéance de l'offre",
      quoteDueByRequired: "La date d'échéance du devis est obligatoire.",
      firstName: "Prénom",
      firstNameRequired: "Le prénom du contact est obligatoire.",
      lastName: "Nom de famille",
      lastNameRequired: "Le nom de famille du contact est obligatoire.",
      email: "Courriel",
      phone: "Téléphone"
    },
    selectFolders: {
      title: "Sélectionnez les dossiers"
    },
    selectVendors: {
      any: "N'importe lequel",
      unsolicited: "Non sollicité",
      repliedWillBid: "A répondu : fera une offre",
      repliedWillNotBid: "A répondu : Ne fera pas d'offre",
      submittedQuote: "Devis envoyé",
      title: "Sélectionnez les vendeurs",
      solicitationStatus: "Statut de l'appel d'offres",
      inSelectedFolders: "Dans les dossiers sélectionnés",
      inEstimate: "Dans l'estimation",
      vendorList: "Liste des fournisseurs"
    },
    dashboard: {
      noDataTitle: "Pas de sollicitations",
      noDataHelpText:
        "Envoyez une sollicitation ou modifiez vos filtres. Les sollicitations apparaîtront sous le projet auquel elles appartiennent."
    }
  },
  userAdmin: {
    adminRequired: "Vous devez être un administrateur pour visiter cette page",
    writeRequired:
      "Vous devez avoir le droit d'ajouter/modifier des projets pour visiter cette page",
    contactRequired:
      "Vous devez avoir le droit d'ajouter/modifier des contacts pour visiter cette page.",
    fullPreconRequired:
      "Vous devez avoir un accès complet à « Pré-Construction » pour visiter cette page",
    unauthorized: {
      jumbotron: {
        title: "Vous n'avez pas accès à « Pre-Construction ».",
        heavyBid: "Pré-construction de HeavyBid",
        text1: "est la maison de :",
        listItem1: "avec le suivi des résultats des appels d'offres",
        listItem2: "avec analyse des ICP",
        listItem3: "Centralisé",
        text2: "Contactez votre administrateur et partagez",
        text3: "cet article d'aide",
        text4: "si vous avez besoin d'un accès.",
        text5:
          "Si vous avez déjà mis à jour votre compte, déconnectez-vous, puis réessayez. Si le problème persiste, veuillez contacter votre administrateur système ou cliquez sur les liens ci-dessous pour plus d'informations.",
        logout: "déconnexion"
      },
      projectTracking: {
        title: "Suivi du projet",
        body:
          "L'accès au suivi des projets nécessite un abonnement aux modules complémentaires HeavyBid : suivi du projet. Ce changement peut être effectué dans HCSS Credentials. Pour plus d'informations, cliquez sur le lien ci-dessous.",
        help: "Commencez à utiliser le suivi de projet"
      },
      heavybidInsights: {
        title: "Perspectives d'estimation",
        body:
          "Pour accéder aux données d'Estimate Insights (anciennement HeavyBid Insights), vous devez configurer les paramètres dans HeavyBid et Credentials. Les étapes pour commencer sont disponibles dans le centre d'aide HCSS.",
        help: "Commencer à utiliser Estimate Insights"
      },
      contactManagement: {
        title: "Gestion des contacts"
      },
      quoteManagement: {
        title: "Gestion des devis",
        body:
          "La fonction de gestion des devis est actuellement ouverte aux utilisateurs alpha uniquement. Si vous souhaitez devenir un utilisateur alpha ou bêta, veuillez nous contacter.",
        help: "Envoyez-nous un courriel"
      },
      supportPortal: {
        title: "Aide et assistance",
        body:
          "Vous avez d'autres questions ou préoccupations? Posez des questions concernant votre logiciel HCSS et obtenez des réponses. Les membres de l'équipe d'assistance sont prêts à répondre à vos questions en direct.",
        help: "Accédez au portail d'aide HCSS"
      },
      sandbox: {
        title: "Vous voulez essayer Pre-Con?",
        body:
          "Explorez un environnement bac à sable où vous pourrez avoir une idée du produit!",
        help: "Explorez maintenant"
      }
    },
    permissionsUpdated: "Autorisations mises à jour",
    errors: {
      adminRequired: "Au moins un administrateur est requis",
      permissions: "Erreur lors de l'enregistrement des autorisations"
    },
    revoke: "Révoquer",
    cancel: "Annuler",
    title: "Administration des utilisateurs",
    save: "Sauvegarder",
    newPermissions:
      "Autorisations accordées aux utilisateurs nouvellement ajoutés :",
    addEdit: "Ajouter et modifier des projets dans Suivi de projet",
    addEditContact:
      "Ajouter et modifier des sociétés ou des contacts dans la gestion des contacts",
    seeHeavyBid: "Voir les chiffres des estimations HeavyBid",
    admin:
      "Administrateur (définir les autorisations des utilisateurs, personnaliser les champs du projet, gérer les modèles de projet et d'estimation)",
    estimateInsights: "Accès aux informations sur les estimations",
    adminTooltip:
      "Les utilisateurs disposant d'un rôle d'utilisateur « Administrateur » dans les informations d'identification seront intégrés à cette application en tant qu'administrateur de l'entreprise.",
    companyAdminTooltip:
      "Les administrateurs de l'entreprise conservent tous les droits dans l'application Pre-Con. Leur accès à l'application et leur niveau de rôle d'utilisateur sont gérés dans les informations d'identification HCSS.",
    limitedUserTooltip:
      "Non disponible pour les utilisateurs en lecture seule. Pour l'activer, accédez aux informations d'identification HCSS et ajoutez cet utilisateur à un groupe d'abonnement avec HeavyBid | Préconstruction.",
    dataAccessTooltip:
      "Accès aux informations sur les estimations et visualisation des valeurs de coût/prix estimées",
    writeTooltip: "L'administrateur doit d'abord être décoché.",
    userPermissions: "Autorisations utilisateur",
    usersWithAccess:
      "Ces comptes d'utilisateurs ont reçu l'autorisation d'accéder à la pré-construction dans les informations d'identification HCSS.",
    revokeAdmin: "Révoquer les droits d'administrateur",
    revokeAdminConfirmation:
      "Êtes-vous sûr de vouloir révoquer vos droits d'administrateur?",
    defaultNewUserPermissions: {
      projectTracking: "SUIVI DU PROJET",
      contactManagement: "GESTION DES CONTACTS",
      estimateInsights: "INFORMATIONS SUR LES ESTIMATIONS"
    },
    permissionsTable: {
      firstName: "Prénom",
      lastName: "Nom de famille",
      userId: "ID de l'utilisateur",
      addEdit: "Ajouter et modifier",
      seeHeavyBid: "Voir les numéros HeavyBid",
      admin: "Administrateur",
      estimateInsights: "Informations sur les estimations",
      projectTracking: "Suivi du projet",
      contactManagement: "Gestion des contacts",
      dataAccess: "Accès aux données"
    }
  },
  layout: {
    title: {
      heavy: "Heavy",
      bid: "Bid",
      preConstruction: "Suivi du projet",
      combined: "Suivi de projet HeavyBid",
      quotes: ""
    },
    loading: "Chargement",
    menu: {
      title: "Menu",
      projectTracking: {
        title: "Suivi du projet",
        list: "Liste des projets",
        import: "Importer",
        importProjects: "Importer des projets",
        customize: "Personnaliser la configuration",
        customizeOld: "Personnaliser les projets",
        estimateMapping: {
          title: "Cartographie des estimations",
          hover: "Nombre total d'estimations non liées"
        }
      },
      importBidResults: {
        title: "Importer les résultats de l'enchère",
        title1:
          "Avant de commencer, il est important de savoir que les résultats des enchères ne peuvent être importés que dans des projets existants.",
        title2:
          "Utilisez <strong>Importer des projets</strong> pour créer d'abord les projets manquants avant d'importer les résultats de l'enchère.",
        title3:
          "Les données de l'historique des enchères exportées depuis HeavyBid peuvent également être importées en tant que résultats d'enchères.",
        bidResultsXMLHeader1:
          "Utilisez une ligne pour chaque soumission d'offre pour chaque projet.",
        bidResultsXMLHeader2:
          "Entrez le nom du projet, le nom de l'entreprise et le total de l'offre. Marquez le gagnant pour chaque projet en saisissant #Y pour Oui.",
        bidResultsXMLHeader3:
          "Le nom de votre entreprise est #companyName. Utilisez ce nom de société pour vos soumissions d'offres.",
        projectName: "Nom du projet",
        winner: "Gagnant?",
        companyName: "Nom de l'entreprise",
        bidTotal: "Total de l'enchère",
        downloadXML: "Télécharger",
        learnMore: "En savoir plus",
        selectFile: "Choisir le dossier",
        step1:
          "<strong>Étape 1.</strong> Téléchargez le modèle d'importation des résultats d'enchères.",
        subtitle1:
          "Saisissez tous les résultats des enchères dans Excel. Enregistrez le fichier.",
        step2: "<strong>Étape 2.</strong> Importez le fichier Excel.",
        subtitle2:
          "Sélectionnez le fichier pour importer les résultats des enchères.",
        cancel: "Annuler",
        preview: "Aperçu",
        import: "Importer",
        wizardStep1: "Choisir le dossier",
        wizardStep2: "Aperçu",
        wizardStep3: "Résultats"
      },
      estimating: {
        title: "Informations sur les estimations",
        list: "Liste des estimations",
        edit: "Modifier les estimations archivées",
        divToBuErrorTitle:
          "Nous n'avons trouvé aucune estimation pour votre unité commerciale actuelle.",
        divToBuErrorBody:
          "Cliquez sur le lien ci-dessous pour obtenir de l'aide pour démarrer, ou contactez HCSS en ouvrant un ticket sur https://community.hcss.com/s/contactsupport ou en appelant le 1-855-231-7876.",
        divToBuFilteredErrorTitle:
          "Aucune estimation n'est disponible avec les filtres actuels",
        unauthorizedErrorTitle:
          "Veuillez contacter votre administrateur ou le service de soutien HCSS",
        unauthorizedErrorBody:
          "Votre compte n'a pas accès aux estimations de l'unité commerciale demandée"
      },
      contacts: {
        title: "Informations sur l'entreprise",
        list: "Liste des entreprises",
        search: "Rechercher des contacts",
        import: "Importer les contacts",
        setup: "Configuration de l'entreprise"
      },
      settings: {
        title: "Paramètres",
        email: "Adresse électronique de l'unité commerciale",
        email2: "Adresse électronique de l'entreprise",
        userAdmin: "Administrateur utilisateur",
        companyProfile: "Profil de l'entreprise"
      },
      quoteManagement: {
        title: "Gestion des devis",
        dashboard: "Tableau de bord",
        createProposal: "Créer une proposition",
        createSolicitation: "Créer une sollicitation",
        incomingHeader: "Devis entrants",
        incomingSubHeader: "",
        outgoingHeader: "Propositions sortantes",
        outgoingSubHeader: "Aux entrepreneurs généraux",
        vendorCommunication: "Communication avec le fournisseur",
        filters: "Filtres",
        noVendorCommsFoundTitle:
          "Nous n'avons trouvé aucune sollicitation ni aucun enregistrement de communication avec le fournisseur associé dans aucun de vos projets en cours.",
        noVendorCommsFoundBody:
          "Sélectionnez « Créer une sollicitation » dans le menu de gauche pour envoyer une sollicitation et commencer automatiquement à suivre vos communications avec les fournisseurs."
      }
    },
    profile: {
      bu: "Changer d'unité commerciale",
      email: "Configurer les paramètres de messagerie",
      calendar: "Configurer les paramètres du calendrier",
      logout: "Se déconnecter"
    },
    feedback: "Rétroaction",
    chat: {
      online: "Discutez avec un expert",
      offline: "Agent hors ligne"
    },
    help: {
      helpCenter: "Centre d'aide",
      chat: "Clavardage en direct avec le soutien",
      contact: "Contactez le service de soutien",
      phone: "Ligne d'aide : 1-855-231-7876",
      suggestion: "Enregistrer une suggestion de produit",
      about: "À propos de HCSS",
      privacy: "Politique de confidentialité de HCSS",
      tutorial: "Tutoriel de lancement"
    },
    alphaHelp: "Aide Alpha"
  },
  core: {
    dropdown: {
      clear: "(Clair)",
      placeholder: "Sélectionner",
      new:
        "Pour ajouter une nouvelle option : saisissez une valeur, appuyez sur Entrée"
    },
    filters: {
      title: "Filtres",
      removeDisplayedFilters: "Supprimer les filtres ({0} affiché)",
      clearActiveFilters: "Supprimer les filtres actifs ({0} appliqués)",
      textPlaceholder: "Tapez quelque chose et appuyez sur Entrée...",
      addFiltersText: "Ajouter des filtres",
      adjustFiltersText: "Ajuster les filtres",
      collapseFiltersText: "Réduire les filtres",
      expandFiltersText: "Développer les filtres"
    },
    modals: {
      ok: "Ok",
      cancel: "Annuler",
      close: "Fermer",
      confirmBack: {
        unsavedChanges:
          "Vous avez des modifications non enregistrés. Êtes-vous sûr de vouloir continuer?"
      },
      confirmDelete: {
        confirm: "Confirmer la suppression",
        projectTitle: "Supprimer le projet",
        fieldTitle: "Supprimer le champ",
        draft: "Supprimer le brouillon",
        message: "Êtes-vous sûr de vouloir supprimer {0}?"
      },
      linkHeavyJob: {
        linkName: "Lier à HeavyJob",
        modal: "Lier {0} à HeavyJob",
        linkJobButton: "Lier cet emploi",
        cancel: "Annuler",
        deleteLinkJobButton: "Supprimer le lien"
      }
    },
    buttons: {
      back: "Retour",
      delete: "Supprimer",
      deleteAll: "Supprimer toutes",
      import: "Importer",
      close: "Fermer"
    },
    notifications: {
      saved: "{0} enregistrés!",
      added: "{0} ajoutés!",
      removed: "{0} supprimés!"
    },
    loaders: {
      loading: "Chargement...",
      updating: "Mise à jour..."
    }
  },
  tables: {
    group: {
      placeholder:
        "Faites glisser l'en-tête d'une colonne ici pour regrouper les données en fonction de cette colonne",
      checklist: {
        true: "Vérifié",
        false: "Non vérifié"
      }
    },
    add: {
      text: "Ajouter",
      hint: "Ajouter une entrée"
    },
    edit: {
      text: "",
      hint: "Modifier l'entrée"
    },
    delete: {
      text: "",
      hint: "Supprimer l'entrée"
    },
    commit: {
      text: "",
      hint: "Enregistrer les modifications"
    },
    cancel: {
      text: "",
      hint: "Annuler les modifications"
    },
    export: {
      checklist: {
        true: "Vérifié",
        false: "Non vérifié"
      },
      pdf: {
        currentPage: "Page {0} sur {1}",
        filename: "{0}-{1}.pdf"
      },
      csv: {
        filename: "{0}-{1}.csv"
      }
    },
    options: {
      optionsHeader: "Options",
      exportHeader: "Exporter",
      exportCSV: "CSV",
      exportCSVFull: "Exporter au format CSV",
      exportPDF: "PDF",
      exportPDFFull: "Exporter au format PDF",
      bidResults: "Résultats des enchères au format CSV",
      showColumns: "Afficher/masquer les colonnes",
      highlight: "Souligner des projets",
      expandAll: "Tout développer",
      collapseAll: "Tout réduire",
      exportDisabledMessage:
        "L'exportation est désactivée tant que tous les projets et toutes les estimations n'ont pas été chargés.",
      stillFetchingData: "Chargement de projets et de devis"
    },
    rightClickColumn: {
      sort: {
        asc: "Trier par ordre croissant",
        desc: "Trier par ordre décroissant"
      },
      group: "Grouper",
      groupBy: "Grouper par",
      highlight: {
        on: "Souligner",
        off: "Effacer le surlignage"
      },
      hide: "Masquer",
      showColumns: "Afficher/masquer les colonnes"
    },
    columnChooser: {
      title: "Afficher/masquer les colonnes",
      showAll: "Tout afficher",
      hideAll: "Cacher tout",
      close: "Fermer"
    },
    highlightModal: {
      title: "Souligner des projets"
    },
    search: {
      placeholder: "Chercher...",
      checklist: {
        true: "Vérifié",
        false: "Non vérifié"
      }
    },
    visibility: {
      allHidden: "Toutes les colonnes sont cachées",
      addColumns: "Ajouter des colonnes à la grille"
    },
    summary: {
      count: "Nombre",
      sum: "Somme"
    }
  },
  views: {
    templateSelect: {
      create: "Créer un nouveau modèle nommé {0}"
    },
    templateOptions: {
      save: "Enregistrer",
      undo: "Annuler",
      options: {
        title: "Options",
        new: "Enregistrer comme nouveau modèle",
        rename: "Renommer le modèle",
        remove: "Supprimer le modèle",
        setAsDefault: "Définir par défaut",
        claim: "Réclamer le modèle"
      }
    },
    renameModal: {
      title: "Renommer le modèle",
      desc: "Nouveau nom (le nom actuel est {0})"
    },
    newModal: {
      title: "Enregistrer le nouveau modèle",
      desc: "Nom du modèle"
    },
    claimModal: {
      title: "Réclamer le modèle",
      desc: "Êtes-vous sûr de vouloir réclamer {0}?"
    },
    savePrivateModal: {
      title: "Enregistrer le modèle privé",
      desc:
        "Êtes-vous sûr de vouloir enregistrer {0} comme privé? Vous ne pourrez pas annuler cette action car vous n'êtes pas propriétaire de ce modèle."
    },
    confirmDelete: {
      title: "Supprimer le modèle",
      desc: "Êtes-vous sûr de vouloir supprimer {0}?"
    },
    accessTooltip: {
      errors: {
        unclaimed:
          "Ce modèle doit être reclamé par un administrateur avant d'être rendu privé.",
        requiredPublic: "Vous devez avoir au moins un modèle public {0}",
        lackPermissions:
          "Seuls les administrateurs peuvent rendre privés les modèles d'autres utilisateurs",
        readOnly:
          "Vous devez avoir accès à l'ajout/la modification de projet pour créer vos modèles {0}"
      },
      accessType: {
        public: "public",
        private: "privé"
      },
      schemaTypes: {
        estimateInsights: "aperçu des estimations",
        bidResults: "résultats des enchères",
        projectList: "liste de projets"
      }
    },
    errors: {
      uniqueName: {
        title: "Erreur lors de l'enregistrement du modèle {0}",
        desc: "Un modèle existe déjà avec ce nom."
      },
      saveView: "Erreur lors de l'enregistrement de {0}",
      claimView: "Erreur lors de la réclamation du modèle"
    }
  },
  errors: {
    invalidValue: "{0} a une valeur non valide",
    required: "{0} est requis"
  },
  projects: {
    loading: "Chargement",
    warnings: {
      titles: {
        locked: "Champs verrouillés",
        default: "Avertissement"
      },
      messages: {
        locked:
          "Le champ '{0}' est verrouillé. Impossible de mettre à jour sa valeur en '{1}'.",
        unableToAutomaticallyGeocodePlace:
          "Impossible de géocoder automatiquement les coordonnées par lieu '{1}' dans le champ '{0}'."
      }
    },
    errors: {
      save: "Erreur lors de l'enregistrement du projet – {0}",
      duplicateName: "Un projet existe déjà sous le nom {0}",
      requiredField: "{0} est requis",
      invalidValue: "Le champ '{0}' a une valeur non valide.",
      notInBU:
        "Le projet auquel vous avez essayé d'accéder ne fait pas partie de votre unité d'affaires actuelle.",
      linkSameLocationId: "Cet emploi est déjà lié au projet"
    },
    details: {
      title: "Détail du projet",
      files: "Pièces jointes",
      estimateSelectionTable: {
        noEstimates: "Aucune estimation n'a été téléversée.",
        noEstimatesLink:
          "Visitez le site https://help.hcss.com/s/article/Overview-Estimate-History pour obtenir de l'aide sur la configuration de l'archive d'estimation."
      },
      header: {
        back: "Retour",
        save: "Enregistrer",
        expand: "Tout développer",
        collapse: "Tout réduire",
        downloadReport: "Télécharger le PDF",
        downloadReportSpinner: "Téléchargement..."
      },
      error: {
        report: "Erreur de téléchargement du rapport pour le projet - {0}"
      }
    },
    import: {
      button: "Importer des projets"
    },
    list: {
      title: "Projets",
      tutorial:
        "Souhaitez-vous un tutoriel rapide sur le suivi de projet HeavyBid?",
      header: {
        add: "Ajouter un projet",
        template: "Modèle",
        viewType: {
          list: "Liste",
          calendar: "Calendrier",
          charts: "Graphiques",
          map: "Carte"
        }
      },
      filters: {
        noFilters:
          "Vous n'avez aucun filtre. Cliquez sur le bouton Ajouter un filtre ci-dessous!",
        addFilters: "Ajouter un filtre",
        adjustFilters: "Ajuster les filtres",
        select: "Sélectionnez les filtres"
      },
      table: {
        noData: {
          filters: "Aucun projet n'est visible avec les filtres actuels",
          noProjects: "Cliquez sur Ajouter un projet pour commencer",
          noProjectsReadOnly: "Aucun projet à afficher",
          noProjectsDueToEstimateTotalsFilters:
            "Vous n’avez pas accès aux Informations sur les estimations. Veuillez supprimer tous les filtres d'estimation pour voir les projets!"
        },
        quickEntryCreate: "Créer '{0}'",
        quickEntryDisabled:
          "La saisie rapide est désactivée pour cette colonne",
        noOptions: "Aucune option",
        createNewOptions:
          "Pour ajouter une nouvelle option : saisissez une valeur, appuyez sur Entrée"
      },
      calendar: {
        options: {
          subscribe: "Abonnez-vous au calendrier",
          month: "Mois",
          week: "Semaine",
          day: "Jour",
          agenda: "Agenda",
          viewHeader: "Aperçu du calendrier"
        },
        eventWidget: {
          detailsButton: "Détails",
          closeButton: "Fermer"
        }
      }
    },
    setup: {
      header: {
        save: "Sauvegarder"
      },
      title: "Personnaliser les projets",
      save: "Configuration du projet",
      errors: {
        sectionName: "Il manque des noms dans {0} section.s.",
        fieldNameMissingSingular: "Il manque un nom dans un champ dans {0}",
        excessCustomFields:
          "Seuls trois champs personnalisés sont autorisés pour le suivi de projet de base. Champs personnalisés actuels : {0}",
        fullProjectTrackingOnly:
          "Les emplacements, tableaux, hyperliens et listes de contrôle ne sont disponibles que pour le suivi complet du projet. Champs concernés : {0}",
        fieldNameMissingPlural: "Il manque des noms dans {0} champs dans {1}",
        fieldNameDuplicated:
          "Nom de champ en double, {0}, dans la section {1}.",
        blankWebValue:
          "Valeur Web vide dans le champ '{0}'. Les valeurs Web ne peuvent pas être vides.",
        blankChecklistValue:
          "Valeur de liste de contrôle vide dans le champ '{0}'. Les valeurs de la liste de contrôle ne peuvent pas être vides.",
        blankDesktopValue:
          "Valeur de bureau vide dans le champ '{0}'. Les valeurs du bureau ne peuvent pas être vides.",
        duplicateWebValue:
          "Valeur d'affichage Web en double, '{0}', dans le champ '{1}'. Les valeurs Web doivent être uniques et les valeurs HeavyBid doivent être uniques.",
        duplicateDesktopValue:
          "Valeur d'affichage HeavyBid en double, '{0}', dans le champ '{1}'. Les valeurs Web doivent être uniques et les valeurs HeavyBid doivent être uniques.",
        duplicatedSelectionValueForTableField:
          "Valeur en double, '{0}', dans le champ '{1}'. Les valeurs doivent être uniques.",
        blankSelectionValueForTableColumn:
          "Le champ '{0}' comporte une valeur vide en option",
        generic: "Erreur lors de l'enregistrement de la configuration du projet"
      },
      message: {
        emptyCompanyList:
          "Veuillez accéder à Gestion des contacts et créer une entreprise pour commencer à utiliser ce champ",
        emptyCompanyListWithFilter:
          "Aucune entreprise ne correspond au filtre Type d'entreprise de ce champ",
        emptyContactList:
          "Veuillez accéder à Gestion des contacts et créer un contact pour commencer à utiliser ce champ"
      }
    },
    estimateMapping: {
      title: "Cartographie des estimations",
      buttons: {
        hide: "Masquer les estimations",
        unhide: "Afficher les estimation.s",
        showHidden: "Afficher les estimations masquées",
        undo: "Annuler",
        undoLoading: "Chargement"
      },
      projectSection: {
        linkProject: "Lien vers le projet",
        selectProject:
          "Sélectionnez le projet pour établir un lien avec les estimations sélectionnées.",
        searchProjects: "Rechercher des projets...",
        hideProjects: "Masquer les projets avec des estimations liées",
        addProject: "Ajouter un projet"
      },
      projectCard: {
        linkedEstimatesOne: "Une estimation liée",
        linkedEstimates: "{0} estimations liées",
        cancelButton: "Annuler",
        linkButton: "Lien"
      },
      projectDialog: {
        title: "Estimations liées pour",
        noLinkedEstimates: "Ce projet n'a pas d'estimations liées",
        unlinkButton: "Dissocier",
        showTotalButton: "Afficher les totaux"
      },
      estimateTable: {
        searchEstimates: "Rechercher des estimations...",
        selectedEstimates: "{0} estimation.s sélectionnée.s",
        estimatesLinked: "{0} Estimation.s liée.s à {1}"
      },
      addProjectModal: {
        title: "Ajouter un projet",
        cancel: "Annuler",
        save: "Sauvegarder",
        saveHint: "Enregistrez le projet sans lier les estimations",
        saveAndLink1: "Enregistrer et",
        saveAndLink2: "Lien",
        saveAndLinkHint:
          "Enregistrez le projet et liez les estimations sélectionnées"
      },
      notification: {
        title: "Estimations non liées"
      },
      sagas: {
        errors: {
          loadHiddenEstimateIds:
            "Erreur lors du chargement des estimations masquées non liées",
          updateHiddenEstimateIds:
            "Erreur lors de la mise à jour des estimations masquées non liées"
        },
        success: {
          hide: "Estimation.s masquée.s avec succès",
          unhide: "Estimation.s affichée.s avec succès"
        }
      },
      errors: {
        estimatesMapped: "Toutes les estimations ont été cartographiées.",
        estimatesMappedOrHidden:
          "Toutes les estimations ont été cartographiées ou masquées.",
        noEstimatesMatchedOrHidden:
          "Aucune estimation n'est visible avec les filtres appliqués ou elles sont masquées",
        noEstimatesMatched:
          "Aucune estimation n'est visible avec les filtres appliqués",
        noEstimateInsightsAccess:
          "Vous n’avez pas accès aux Informations sur les estimations. Veuillez supprimer le filtre Total de l'enchère pour voir les estimations.",
        errorDuringUndoLinkingTittle: "Impossible d'annuler",
        errorDuringUndoLinkingDescription:
          "Veuillez réessayer ou accéder aux détails du projet pour dissocier manuellement les estimations.",
        errorDuringUndoLinkingLinkText: "Ouvrir les détails du projet",
        errorDuringUndoLinkingNotification:
          "Impossible d'annuler la liaison des estimations"
      }
    }
  },
  schemas: {
    errors: {
      save: "Erreur lors de l'enregistrement de la configuration du projet"
    },
    form: {
      lockedField: "Verrouillé",
      required: "{0} est obligatoire",
      containsNotListedOptions:
        "Le champ '{0}' contient une valeur non répertoriée dans les options",
      multiSelectListError:
        "Une erreur s'est produite lors de l'enregistrement du champ de sélection multiple"
    },
    yupValidationErrors: {
      tableField: {
        valueNotInTheList:
          "Le champ du tableau '{0}' contient une valeur non répertoriée dans les options pour la ou les colonnes suivantes : {1}"
      },
      singleSelect: {
        valueNotInTheList:
          "Le champ '{0}' a une valeur non répertoriée dans les options"
      },
      company:
        "Le champ '{0}' est associé à une société dont le type de société ne satisfait pas au filtre actuel défini pour le champ.",
      contact:
        "Le champ '{0}' a un contact attribué dont les étendues de travail ne satisfont pas au filtre actuel défini pour le champ."
    },
    setup: {
      fieldName: "",
      columnName: "Nom de colonne",
      standardValues: "Valeurs standard",
      listValues: "Valeurs dans la liste",
      required: "Requis",
      editableByAdmin: "Modifiable par l'administrateur uniquement",
      managedByCompanyAdmin: "Géré par l'administrateur de l'entreprise",
      displaySum: "Afficher la somme",
      lockable: "Verrouillable",
      fieldNamePlaceholder: "Nom du champs",
      columnNamePlaceholder: "Nom de colonne",
      quickEntry: "Entrée rapide",
      quickEntryInfo:
        "Autorisez les utilisateurs à ajouter de nouvelles valeurs si nécessaire.",
      columnLimit: "Seules six colonnes sont autorisées pour ce champ.",
      addColumn: "Ajouter une colonne",
      multiSelect: "Sélection multiple",
      multiSelectInfo:
        "Autoriser les utilisateurs à sélectionner plusieurs valeurs. Le champ ne pourra pas revenir à une sélection unique une fois les modifications enregistrées.",
      multiSelectModal: {
        title: "Activer la liste de sélections multiples",
        body:
          "Le champ ne pourra pas revenir à une sélection unique une fois les modifications enregistrées."
      },
      sort: "Trier",
      sortAlpha: "Trier les valeurs par ordre alphabétique",
      sortAlphaShort: "Trier par ordre alphabétique",
      customSort: "Valeurs de tri personnalisées",
      customSortShort: "Tri personnalisé",
      fullProjectTrackingOnly:
        "Disponible avec la version complète de Suivi de projet",
      fieldList: {
        section: "Section",
        addFields: "Ajouter des champs",
        help: "Aidez-moi à choisir",
        dragSectionTooltip:
          "Faites-le glisser vers la droite pour créer une nouvelle section!",
        dragFieldTooltip:
          "Faites-le glisser vers la section dans laquelle vous souhaitez l'ajouter!"
      },
      sections: {
        name: "Nom de la section",
        fields: "Champs",
        dragFields: "Faire glisser les champs ici"
      },
      examples: {
        close: "Fermer",
        types: "Types de champs",
        text: {
          name: "Texte",
          description:
            "À utiliser pour des informations courtes pouvant dont des chiffres, des lettres ou les deux",
          examples: ["Code", "Nom du contact", "Titre", "Adresse e-mail"],
          alt: "Champ Texte"
        },
        note: {
          name: "Note",
          description:
            "À utiliser pour les descriptions longues ou les lignes d'information multiples",
          examples: ["Notes", "Description du poste"],
          alt: "Champ de notes"
        },
        number: {
          name: "Nombre",
          description:
            "Utilisation pour des informations numériques (mais pas pour de l'argent)",
          examples: ["Quantité", "Âge"],
          alt: "Champ des nombres"
        },
        currency: {
          name: "Devise",
          description: "Utiliser pour les montants en dollars",
          examples: ["Coût", "Prix"],
          alt: "Champ Devise"
        },
        date: {
          name: "Date",
          description:
            "Utiliser pour les événements qui ont une date, mais où l'heure de la journée n'est pas importante",
          examples: ["Date de la soumission", "Date d'échéance"],
          alt: "Champ Date"
        },
        dateTime: {
          name: "Date/heure",
          description:
            "Utilisé pour les événements qui nécessitent une date et une heure",
          examples: ["Réunions", "Dates limites"],
          alt: "Champ Date et heure"
        },
        selectionList: {
          name: "Sélectionnez la liste",
          description:
            "Aimez un champ de texte, mais choisissez des options à partir d'une liste limitée au lieu de taper n'importe quelle valeur",
          examples: ["État", "Unité d'affaires", "Région"],
          alt: "Champ Liste de sélection"
        },
        hyperlink: {
          name: "Hyperlien(s)",
          description: "Utiliser pour créer des liens vers d'autres sites Web",
          examples: [
            "Dropbox",
            "Lecteur Google",
            "Annonces de soumission du DOT"
          ],
          alt: "Champ Liens"
        },
        yesNo: {
          name: "Oui/Non",
          description:
            "À utiliser pour les options on/off simples, telles que on/off, oui/non, vrai/faux",
          examples: ["Approbation requise ", "Révisé"],
          alt: "Champ Case à cocher"
        },
        checklist: {
          name: "Liste de contrôle",
          description: "Permet de suivre les options simples de marche/arrêt",
          examples: ["Terminé", "Suivi"],
          alt: "Champ Liste de contrôle"
        },
        location: {
          name: "Emplacement",
          description:
            "Permet de déposer une épingle pour indiquer l'emplacement d'un projet",
          alt: "Champ Emplacement"
        },
        states: {
          name: "Liste des États"
        },
        custom: {
          name: "Personnaliser"
        },
        bidResults: {
          name: "Résultats de la soumission"
        },
        estimates: {
          name: "Estimations"
        },
        preconId: {
          name: "ID pré-con"
        },
        company: {
          name: "Entreprise",
          description: "Permet de sélectionner une entreprise pour un projet",
          examples: ["Sous-traitant sur site", "Sous-traitant en camionnage"],
          alt: "Champ Entreprise"
        },
        contact: {
          name: "Contact",
          description: "Permet de sélectionner un contact pour un projet",
          examples: ["Admin du projet", "répartiteur"],
          alt: "Champ Contact"
        },
        hJJob: {
          name: "Emploi",
          description: "Permet de lier un emploi HeavyJob à un projet",
          alt: "Champ Emploi",
          empty: "Aucun emploi disponible pour cette unité d'entreprise",
          noAccess: "Vous n'avez pas accès à cet emploi.",
          noAccessCode: "----"
        },
        table: {
          name: "Tableau",
          description: "Permet de créer un tableau dans un projet",
          alt: "Champ Tableau"
        }
      },
      selectionList: {
        deleteAllDescription:
          "Supprimer toutes les valeurs de la liste de visualisation ?",
        import: {
          importValues: "Importer des valeurs",
          selectFile: "Choisir le dossier",
          preview: "Aperçu",
          filesDescription:
            "Importez des valeurs dans la liste de sélection à partir d'un fichier Excel ou CSV.",
          pleaseNote: "Veuillez noter",
          noteDescription:
            "Seules les deux premières colonnes sont importées dans la liste de sélection",
          skipFirstRow: "La première ligne contient les en-têtes de colonnes",
          previewColumn: "Colonne",
          webValue: "Valeur Web",
          desktopValue: "Valeur du bureau",
          previewDescription:
            "Si une valeur reste vide, la valeur Web ou Desktop correspondante dans la ligne sera dupliquée pour remplir le vide.",
          notSupportedFile: "Formats de fichiers acceptés: {0}",
          importSucceed:
            "Les valeurs ont été importées avec succès et ajoutées à la liste de sélection!",
          importAgain: "Importer à nouveau"
        }
      },
      canBeImported: "Peut être importé",
      canBeSyncedWithHB: "Peut être synchronisé avec HeavyBid",
      contact: {
        filterBy: "Filtrer par champ de travail",
        filterPlaceholderText:
          "Acccédez à Gestion des contacts et créez un champ de travail pour commencer à utiliser ce filtre"
      },
      company: {
        filterBy: "Filtrer par type d'entreprise",
        filterPlaceHolderText:
          "Accédez à la Gestion des contacts et créez un type d'entreprise pour commencer à utiliser ce filtre"
      },
      errors: {
        tableField: {
          atLeastOneColumn: "Veuillez ajouter au moins une colonne.",
          exceedColumnNumberLimit:
            "Un tableau ne peut contenir que {0} colonnes.",
          duplicatedColumName:
            "Les noms de colonnes en double ne sont pas autorisés. Veuillez vous assurer que les noms des colonnes sont uniques.",
          columnMustHasName: "Une colonne doit avoir un nom."
        }
      },
      banner: {
        copied:
          "Copie des paramètres du champ « {0} » de l'unité d'entreprise {1}.",
        multiSelectWarning:
          "Le champ ne pourra pas revenir à la sélection unique une fois les modifications enregistrées.",
        hbMappingsWarning:
          "Remarque : les mappages de l'intégration HeavyBid ne seront pas copiés.",
        copiedFromTable: "{0} copié à partir du tableau {1} dans {2}.",
        copiedFrom: "{0} copié à partir de {1}."
      },
      buttons: {
        undo: "Annuler",
        copyFrom: "Copier à partir"
      }
    },
    location: {
      geocoder: {
        placeholder: "Rechercher une adresse ou une adresse latitude/longitude",
        dropLatLongPin:
          "Déposer une épingle à latitude : {0} et longitude : {1}",
        errorFetchingData:
          "Une erreur s'est produite lors de l'extraction des données. Veuillez réessayer."
      }
    }
  },
  import: {
    supportedProjectsFileFormats:
      "Seuls les formats .xls et .xlsx sont pris en charge",
    acceptableValues: "Valeurs acceptables",
    adminOnly: "Ce champ n'est accessible qu'aux administrateurs.",
    acceptableValuesOptions: "O/N, Oui/Non, Vrai ou Faux, T/F, 1/0",
    acceptableLocationValues: "#.###,#.###",
    acceptableLocationValuesWithGeocoding:
      "Remarque : Seules les latitudes/longitudes valides (au format ##.###,##.###) ou les adresses valides (au format adresse postale, ville, état, code postal) s'afficheront sur la carte.",
    acceptableLocationValuesDescription:
      "Une virgule séparée la valeur Lat,Long avec des plages valides [-90, 90] pour la latitude et [-180, 180] pour la longitude",
    exampleValueList: "Exemple de liste de valeurs :",
    multiSelectSeparators: {
      title:
        "Choisir un séparateur pour les champs à sélection multiple contenant plusieurs valeurs",
      comma: "Virgule (A,B)",
      semicolon: "Point-virgule (A;B)",
      pipe: "Tuyau (A|B)"
    },
    importResults: {
      title: "Importer les résultats",
      emptyMessage: "Commencez l'importation pour afficher les résultats"
    },
    required: "Requis",
    title: "Importer des projets",
    input: {
      title: "Importation de fichiers",
      noFile: "Aucun fichier sélectionné",
      supportedFormat: "Format pris en charge : .xlsx, .xls"
    },
    settings: "Paramètres",
    recordExists: {
      title: "S'il existe déjà un dossier dans le fichier",
      skip: "Ignorer l'enregistrement",
      overwrite: "Écraser l'enregistrement"
    },
    firstRowHeadings: "La première ligne contient les en-têtes de colonne",
    fields: "Champs",
    excelColumn: "Colonne Excel",
    columnMapping: {
      title: "Mappage des colonnes"
    },
    clear: "Effacer",
    preview: {
      title: "Prévisualiser",
      emptyMessage: "Chargez le fichier pour le prévisualiser"
    },
    import: "Importer",
    cancel: "Annuler",
    finish: "Terminer",
    projectsCreated: "{0} projet(s) ajouté(s)",
    projectsUpdated: "{0} projet(s) mis à jour",
    created:
      "{0} projet(s) auquel(s) les résultats de la soumission ont été ajoutés",
    updated: "{0} projet(s) dont les résultats des soumissions sont mis à jour",
    importSpinner: "Importation...",
    importComplete: "Terminé!",
    importFailStartAgain: "Veuillez recommencez!",
    validateSpinner: "Validation",
    validationSuccess: "Succès de la validation",
    skipped: "{0} ignoré",
    viewErrors: "Afficher les erreurs",
    validationWarnings: "Avertissements de validation",
    row: "Ligne",
    project: "Projet",
    errors: {
      mappingsErrorNotificationTitle: "Impossible d'importer des projets.",
      mappingsErrorNotificationMessage:
        "Veuillez vérifier le mappage de vos colonnes.",
      read: {
        title: "Erreur de lecture du fichier",
        message:
          "Une erreur s'est produite lors de la lecture du fichier sélectionné."
      },
      required: {
        title: "Champ obligatoire non attribué",
        message:
          "{0} est un champ obligatoire et doit être attribué à une colonne Excel"
      },
      invalid: {
        title: "Entrée invalide",
        message: "La valeur doit être une lettre"
      },
      duplicate: {
        title: "Valeur en double",
        message: "{0} a la même affectation de colonne Excel"
      },
      rowValidation: "{0} et a été ignoré",
      duplicateRow: "a été ignoré car {0} existe déjà.",
      genericImport: "L'importation d'un nom de projet {0} a échoué.",
      invalidColumn: "{0}, a une valeur {1} non valide.",
      lockedField: "{0}, impossible d'importer dans un champ verrouillé.",
      requiredColumn: "Il manque un champ obligatoire à {0},",
      missingValue:
        "{0}, a une valeur {1} qui ne correspond pas aux options actuelles de la liste de sélection,",
      missingMultiValue:
        "{0}, a des valeurs {1} qui ne correspondent pas aux options actuelles de la liste de sélection,",
      fileType: {
        title: "Type de fichier incorrect ({0})",
        message: "Seuls les types de fichiers .xlsx et .xls sont pris en charge"
      },
      locationType: {
        invalidLatitude:
          "{0} a une valeur de latitude non valide. Il doit être compris entre -90 et 90.",
        invalidLongitude:
          "{0} a une valeur de longitude non valide. Il doit être compris entre -180 et 180.",
        isNullIsland:
          "{0} a une valeur non valide. L'emplacement est défini sur Null Island (0,0)."
      }
    },
    notes: {
      warning: "Avertissement",
      note: "Note",
      preConIdDisabled: "Le champ {0} est défini sur 'désactivé'.",
      preConIdEnable: "Le champ {0} est défini sur « activé ».",
      preConIdWillBeSet:
        "Par conséquent, {0} valeurs de champ pour le(s) nouveau(s) projet(s) seront générées automatiquement",
      preConIdWillNotBeSet:
        "Par conséquent, {0} valeurs de champ pour le(s) nouveau(s) projet(s) ne seront pas générées automatiquement",
      noProjectsWithPreConId:
        "Cependant, aucun projet n'a actuellement une valeur pour le\n       champ {0}. Par conséquent, les valeurs de champ {0} pour le(s) nouveau(s) projet(s) ne seront pas générées automatiquement.",
      pleaseEnablePreConId:
        "Si vous souhaitez que ces valeurs soient générées automatiquement, veuillez activer le champ {0} dans Personnaliser la configuration > Projets.",
      createProjectWithPreConId:
        "Si vous souhaitez que ces valeurs soient générées automatiquement, veuillez d'abord enregistrer un projet avec une valeur {0}.",
      pleaseDisablePreConId:
        "Si vous ne souhaitez pas que ces valeurs soient générées automatiquement, veuillez désactiver le champ {0}\n       dans Personnaliser la configuration > Projets",
      nextPreConId: "La prochaine valeur du champ {0} est",
      allCurrentProjectsDontHavePreConId:
        "Cependant, aucun projet n'a actuellement une valeur pour le\n       champ {0}. Par conséquent, les valeurs de champ {0} pour le(s) nouveau(s) projet(s) ne seront pas générées automatiquement."
    }
  },
  charts: {
    types: {
      pie: "À secteurs",
      line: "Linéaire",
      bar: "En barres"
    },
    options: {
      title: "Options du graphique",
      type: "Type de graphique  :",
      layout: {
        title: "Mise en page :",
        vertical: "Verticale",
        horizontal: "Horizontale"
      },
      projectCount: "Nombre de projets",
      total: "Total",
      xAxis: "Axe X :",
      groupBy: "Grouper par :",
      yAxis: "Axe Y :",
      totalBy: "Total par :",
      selectField: "Sélectionnez un champ :",
      sliceOptions: "Options de tranche :",
      inputPlaceholder: "Sélectionner",
      clear: "(clair)"
    }
  },
  bidResults: {
    title: "Résultats des enchères",
    table: {
      noData: "Ajouter les résultats des enchères",
      noDataReadOnly: "Aucun résultat d'enchère à afficher",
      leftOnTable: "Laissé sur la table",
      percentFromLowBid: "% de l'enchère la plus basse",
      fromLowBid: "À partir de l'enchère la plus basse",
      winner: "Gagnant",
      companyName: "Nom de l'entreprise",
      amount: "Total de l'offre",
      share: "Partager",
      copySuccess: "Ajouté au presse-papier!",
      copySuccessTwo:
        "Collez les résultats de votre enchère dans Excel ou dans le corps d'un courriel.",
      copyFailure:
        "Un problème est survenu lors de la copie des résultats de votre enchère.",
      copyFailureTwo:
        "Veuillez vous assurer que vous utilisez un navigateur compatible avec cette application."
    },
    view: {
      toolTip: {
        statsFor: "Statistiques pour",
        won: "Gagné",
        lost: "Perdu",
        dollars: "Dollars",
        percent: "Pour cent",
        hitRatio: "Taux de réussite",
        fromLowBid: "À partir de l'enchère la plus basse",
        leftOnTable: "Laissé sur la table"
      }
    }
  },
  calendars: {
    subscribePanel: {
      title: "Abonnez-vous aux calendriers",
      links: "Ces liens sont uniques à votre compte",
      visible:
        "Les calendriers iCal sont visibles par toute personne disposant du lien",
      openAccountSettingsButton: "Ouvrir les paramètres du calendrier",
      openAccountSettingsAfter: "pour lier des comptes externes"
    },
    card: {
      copy: {
        title: "Copié dans le presse-papier",
        message: "Lien du calendrier copié pour {0}"
      },
      readlonlyLinkLabel: "Lien iCal partageable (lecture seule)",
      nylasLinkLabel: "Calendrier Outlook",
      createLink: "Créer un lien",
      createReadonlyLink: "Créer un lien iCal partageable",
      createNylasLinkk: "Créer une connexion Outlook",
      deleteReadonlyLink: "Supprimer le lien iCal",
      deleteNylasLink: "Supprimer la connexion Outlook",
      options: "Options",
      deleteReadonlyConfirmation:
        "Êtes-vous sûr de vouloir supprimer votre lien iCal existant? Cela rompra les intégrations existantes.",
      deleteHcssConnectConfirmation:
        "Êtes-vous sûr de vouloir supprimer le lien de votre calendrier connecté existant? Cela supprimera les événements existants de votre calendrier lié.",
      deleteReadonlyWarning:
        "Cela supprimera votre lien iCal existant, ce qui rompra les intégrations existantes.",
      deleteNewReadonlyLink:
        "Un nouveau lien sera créé pour remplacer l'ancien.",
      deleteHcssConnectWarning:
        "Cela supprimera vos événements de calendrier existants du calendrier actuellement lié.",
      deleteNewHcssConnectLink:
        "De nouveaux événements seront créés sur le calendrier nouvellement lié.",
      deleteTitle: "Supprimer le lien",
      continue: "Continuer",
      cancel: "Annuler",
      lastSync: "Dernière synchronisation : {0}",
      none: "aucun",
      syncWarning:
        "La mise à jour de votre client de calendrier peut prendre entre quelques minutes et quelques heures. Vérifiez les paramètres de votre client si vous avez besoin de mises à jour plus rapides."
    }
  },
  hcssConnect: {
    subscribePanel: {
      title: "Connectez vos comptes de calendrier",
      connectAccountButton: "Connecter un nouveau compte",
      linkedAccounts: "Comptes liés"
    },
    card: {
      delete: "Supprimer",
      deleteConfirmation:
        "Êtes-vous sûr de vouloir supprimer votre compte de calendrier existant? Cela rompra les intégrations existantes.",
      deleteTitle: "Supprimer le lien",
      continue: "Continuer",
      cancel: "Annuler",
      deletePopover:
        "Supprimez toutes les connexions au calendrier Outlook existantes dans le panneau « S'abonner au calendrier » avant de supprimer ce compte."
    }
  },
  tutorials: {
    close: {
      message:
        "Vous pouvez toujours accéder à ce tutoriel en allant dans le menu d'aide!",
      button: "Fermer"
    },
    banner: {
      yes: "Oui",
      no: "Non"
    },
    projectTutorial: {
      create: "Cela vous permet de créer un nouveau projet.",
      details:
        "Cliquer sur le nom du projet vous permet de modifier le projet une fois qu'il a déjà été créé.",
      rearrange:
        "Les colonnes peuvent être réorganisées en cliquant et en faisant glisser les en-têtes de colonnes vers la gauche ou la droite.",
      group:
        "Cliquez et faites glisser l'en-tête de colonne dans cette zone pour regrouper les résultats par cette colonne.",
      other:
        "D'autres personnalisations et rapports sont disponibles dans le menu d'options ou en cliquant avec le bouton droit sur un en-tête de colonne.",
      save:
        "Une fois que vous avez configuré le tableau comme vous le souhaitez, vous pouvez l'enregistrer en tant que modèle. Utilisez le menu d'options pour enregistrer de nouveaux modèles ou pour en sélectionner un par défaut.",
      customizable:
        "N’oubliez pas que tout est personnalisable dans le suivi de projet! Utilisez le menu Suivi de projet pour accéder à la page Personnaliser les projets ou pour importer des projets existants à partir d’Excel.",
      complete: "Terminez le tutoriel!"
    }
  },
  smtp: {
    title: "Paramètres du serveur de messagerie",
    subtitle:
      "Configurer les paramètres de messagerie pour l’unité commerciale : {0}",
    subtitle2: "Configurer les paramètres de courriel de l’entreprise",
    cancel: "Annuler",
    save: "Sauvegarder et tester",
    oauth:
      "Des paramètres personnels de courriel ont été configurés pour l’utilisateur actuel. Ces paramètres remplaceront toutes les autres configurations lors de l’envoi de courriels.",
    home: "Accueil",
    previouslyConfigured:
      "Les paramètres SMTP ont été configurés pour cette unité commerciale. Si nécessaire, vous pouvez définir de nouveaux paramètres.",
    changeSettings: "Modifier les paramètres",
    resend: "Renvoyer le message de test",
    username: "Nom d’utilisateur",
    server: "Serveur",
    port: "Port",
    password: "Mot de passe",
    validations: {
      serverAddressBlank: "L’adresse du serveur ne peut pas être vide.",
      portBlank: "Le champ port ne peut pas être vide.",
      usernameBlank: "Le nom d’utilisateur ne peut pas être vide.",
      passwordBlank: "Le mot de passe ne peut pas être vide."
    },
    messages: {
      saved: {
        success: {
          title: "Sauvegardé",
          message:
            "Les paramètres SMTP de cette unité commerciale ont été enregistrés."
        },
        error: {
          title: "Impossible de sauvegarder",
          message:
            "Une erreur s’est produite lors de l’envoi du message de test."
        }
      },
      test: {
        success: {
          title: "Message de test envoyé",
          message: "Un message test a été envoyé à « {0} »."
        },
        unable: {
          title: "Impossible d’envoyer",
          message:
            "Une erreur s’est produite lors de l’envoi du message de test."
        },
        failure: {
          title: "Échec de l’envoi",
          message: "L’utilisateur actuel n’a pas d’adresse de courriel valide."
        }
      }
    }
  },
  users: {
    messages: {
      saved: {
        success: "Préférences de l’utilisateur",
        error: "Erreur d’enregistrement des préférences de l’utilisateur"
      }
    },
    oAuthConfig: {
      getUserError: {
        title: "Erreur",
        message: "Impossible de récupérer vos paramètres de messagerie"
      },
      testMessageError: {
        title: "Échec du test",
        message:
          "Impossible d’envoyer un message de test. Vérifiez que votre login n’a pas expiré."
      },
      testMessageSuccess: {
        message: "Message de test envoyé avec succès {0}.",
        title: "Test réussi !"
      }
    }
  },
  heavyBid: {
    code: "Code",
    name: "Nom",
    description: "Description",
    lastActivity: "Dernière activité",
    activeEstimate: "Estimation synchronisée",
    excludedEstimate: "Estimation exclue",
    division: "Division",
    projectName: "Nom du projet",
    projectNumber: "Numéro du projet",
    status: "Statut",
    engineer: "Ingénieur",
    estimator: "Estimateur",
    owner: "Propriétaire",
    bidDate: "Date de l’offre",
    startDate: "Date de début",
    reviewDate: "Date de révision",
    createdDate: "Date de création",
    bidTotal: "Total de l’offre",
    totalCost: "Coût total",
    state: "État",
    processedStatus: {
      0: "En attente",
      1: "En cours de traitement",
      2: "Succès",
      3: "Échec",
      4: "File d’attente Large",
      title: "Statut traité"
    },
    typeOfWork: "Type de travail",
    filter1: "Étiquette 1",
    filter2: "Étiquette 2",
    filter3: "Étiquette 3",
    filter4: "Étiquette 4",
    filter5: "Étiquette 5",
    filter6: "Étiquette 6",
    text1: "Texte 1",
    text2: "Texte 2",
    text3: "Texte 3",
    text4: "Texte 4",
    text5: "Texte 5",
    text6: "Texte 6",
    text7: "Texte 7",
    text8: "Texte 8",
    text9: "Texte 9",
    text10: "Texte 10",
    text11: "Texte 11",
    text12: "Texte 12",
    text13: "Texte 13",
    text14: "Texte 14",
    text15: "Texte 15",
    text16: "Texte 16",
    text17: "Texte 17",
    text18: "Texte 18",
    text19: "Texte 19",
    text20: "Texte 20",
    integer1: "Entier 1",
    integer2: "Entier 2",
    integer3: "Entier 3",
    integer4: "Entier 4",
    integer5: "Entier 5",
    number1: "Numéro 1",
    number2: "Numéro 2",
    number3: "Numéro 3",
    number4: "Numéro 4",
    number5: "Numéro 5",
    number6: "Numéro 6",
    number7: "Numéro 7",
    number8: "Numéro 8",
    number9: "Numéro 9",
    number10: "Numéro 10",
    number11: "Numéro 11",
    number12: "Numéro 12",
    number13: "Numéro 13",
    number14: "Numéro 14",
    number15: "Numéro 15",
    number16: "Numéro 16",
    number17: "Numéro 17",
    number18: "Numéro 18",
    number19: "Numéro 19",
    number20: "Numéro 20",
    date1: "Date 1",
    date2: "Date 2",
    date3: "Date 3",
    date4: "Date 4",
    date5: "Date 5",
    address: "Localisation",
    latitude: "Latitude",
    longitude: "Longitude",
    estimatesExtended: "EstimationÉtendue",
    estimateInfo: "Informations sur l’estimation",
    tags: "Balises",
    userDefinedText: "Texte défini par l’utilisateur",
    userDefinedIntegers: "Entiers définis par l’utilisateur",
    userDefinedNumbers: "Numéros définis par l'utilisateur",
    userDefinedDates: "Dates définies par l'utilisateur",
    projectTrackingField: "Champ de suivi de projet",
    heavyBidField: "Champ HeavyBid",
    heavyBidInfo:
      'Les systèmes HeavyBid 2019.1 et 2020.0 afficheront " Nouvelle estimation de HeavyBid Pre-Construction ". Cette intégration est disponible pour tous les systèmes à partir de HeavyBid 2020.2.',
    multiSelectSyncInfo:
      "Si un champ MultiSelect List est associé à un champ HeavyBid et que la préférence de synchronisation des données de suivi de projet est choisie, seule la première valeur de la liste sera synchronisée avec HeavyBid. La première valeur est déterminée par l'ordre de tri choisi dans les options du champ."
  },
  region: {
    us: "États-Unis",
    au: "Australie",
    fr: "France",
    gb: "Royaume-Uni",
    es: "ESpagne",
    de: "Allemagne",
    ca: "Canada"
  },
  account: {
    confirm: {
      confirm: "Continuer",
      confirming: "Confirmation",
      discover: "En savoir plus",
      enterEmail: "Saisissez votre courriel pour voir les détails du devis.",
      invalidEmail: "Un courriel valide est requis.",
      invalidMessage: "Courriel ou lien incorrect.",
      verifyTitleRfq: "Vérifiez votre courriel pour voir les détails du devis",
      verifyTitleProposal:
        "Vérifiez votre adresse courriel pour voir la proposition de devis",
      signIn: "Avez-vous déjà un compte ? S'identifier",
      signUp: "S'inscrire maintenant",
      slogan:
        "Un seul site web pour tous vos besoins en matière de gestion des appels d'offres",
      welcome: "Bienvenue",
      modules: {
        projects: "Suivre les projets du début à la fin",
        estimates:
          "Soumettre des offres plus rapidement et plus intelligemment",
        quotes: "Gérer facilement le processus d'appel d'offres et de devis",
        messages: "Recevoir des notifications en temps réel",
        contacts: "Gérez tous vos contacts en un seul endroit"
      }
    },
    login: {
      discover: "En savoir plus",
      login: "Se connecter",
      feature1:
        "Estimez votre devis une seule fois et envoyez-le à plusieurs entrepreneurs.",
      feature2: "Accédez à votre devis en ligne.",
      feature3: "Assurer le suivi des communications.",
      feature4: "Envoyer des devis à n'importe quel entrepreneur général.",
      feature5: "Générer des propositions professionnelles pour vos devis.",
      feature6: "Gérez vos contacts en ligne.",
      signUp: "En savoir plus",
      subTitle1: "Votre devis polyvalent",
      subTitle2: "solution de gestion."
    },
    emailVerification: {
      success: "Vous êtes prêt!",
      successMsg:
        "Nous avons pu vérifier votre courriel avec succès. Accordez-nous quelques minutes pour rechercher d'autres devis liés à votre courriel. Cliquez sur le bouton ci-dessous pour revenir à la page d'accueil.",
      successBtn: "Aller à la page d'accueil",
      fail: "OUPS!",
      failMsg:
        "Un problème s'est produit lors de la vérification de votre courriel. Veuillez réessayer de cliquer sur le lien dans notre courriel de confirmation."
    }
  },
  breadcrumbs: {
    dashboard: "Tableau de bord",
    quotes: "Devis",
    quoteDetails: "Devis actuel"
  },
  genericModal: {
    cancel: "Annuler",
    close: "Fermer",
    confirm: "Confirmer",
    continue: "Continuer"
  },
  loaders: {
    loading: "Chargement...",
    updating: "Mise à jour..."
  },
  maps: {
    default: "Défaut",
    satellite: "Satellite"
  },
  notFound: {
    back: "Retour à la page d'accueil",
    message: "Nous ne parvenons pas à trouver la page que vous avez demandée.",
    title: "Il n'y a rien ici"
  },
  genericError: {
    back: "Retour à la page d'accueil",
    message:
      "Un problème s'est produit de notre côté. Nous travaillons aussi vite que possible pour rétablir les choses et les faire fonctionner. Contactez le support HCSS si vous avez besoin d'aide immédiatement.",
    title: "Quelque chose n'a pas fonctionné"
  },
  smartdrive: {
    copyLink: "Copier le lien",
    download: "Télécharger",
    size: "Taille:",
    uploaded: "Charger",
    deleteFile: "Supprimer le fichier?",
    deleteMessage: "Êtes-vous sûr de vouloir supprimer",
    warning:
      "Avertissement : Cette action est immédiate et ne peut être annulée.",
    confirm: "Confirmer Supprimer",
    cancel: "Annuler",
    table: {
      uploadFiles: "",
      download: "Télécharger",
      selected: "Sélectionné",
      all: "Tout",
      delete: "Supprimer",
      noFiles: "Aucun fichier à afficher"
    }
  },
  groupSubMenu: {
    week: "Semaine",
    month: "Mois",
    year: "Année",
    day: "Jour",
    quarter: "Trimestre"
  },
  common: {
    create: "Créer",
    modules: {
      projectTracking: "Suivi des projets",
      estimating: "Aperçu des estimations",
      quotes: "Gestion de devis",
      contacts: "Gestion des contacts",
      notifications: "Notifications"
    }
  },
  comingSoon: {
    title: "Bientôt disponible",
    contactsText:
      "Gérez tous vos contacts en un seul endroit, maintenez automatiquement les informations à jour, accédez aux contacts dans les applications HCSS et plus encore. Tirez le meilleur parti de votre expérience grâce à une multitude d'intégrations puissantes et transparentes.",
    notificationsText:
      "Restez informé des mises à jour importantes de l'application. Affichez et gérez plusieurs notifications à la fois, ce qui vous permet de réduire les interruptions tout au long de la journée."
  },
  contact: {
    vendorList: {
      confirmDelete: "Supprimer l'entreprise"
    },
    contactList: {
      confirmDelete: "Supprimer le contact"
    },
    mainInfo: {
      code: "Code",
      name: "Nom",
      email: "Courriel",
      phoneNumber: "Téléphone",
      communicationMethod: "Méthode de communication",
      webAddress: "Siteweb",
      companyType: "Type d'entreprise"
    },
    detailInfo: {
      addressLine1: "Ligne d'adresse 1",
      addressLine2: "Ligne d'adresse 2",
      city: "Ville",
      postalCode: "Code postal",
      countryRegion: "Pays",
      faxNumber: "Fax",
      note: "Notes",
      clear: "(Clair)"
    },
    scopeOfWork: {
      select: "Sélectionner les champs d'application",
      scopesOfWork: "Champ d'application des travaux"
    },
    minorityStatus: {
      confirmDelete: "Supprimer le statut de minorité",
      minorityStatuses: "Statuts des minorités",
      notice:
        "enregistrez d'abord votre vendeur avant de pouvoir ajouter des MS"
    },
    setup: {
      errors: {
        companyTypeFilterRequired:
          "Au moins un filtre est requis pour le filtre Type d'entreprise pour le champ « {0} »",
        contactTypeFilterRequired:
          "Au moins un filtre est requis pour le filtre sur l'étendue des travaux pour le champ « {0} »",
        tableTypeEmptyColumn:
          "Le champ de tableau « {0} » nécessite au moins une colonne.",
        tableTypeMoreThan6Columns:
          "Le champ de tableau « {0} » ne peut avoir plus de 6 colonnes."
      }
    }
  },
  unsupportedBrowser: {
    iFrameFallback:
      "Impossible d'afficher le contenu. Ce navigateur n'est pas pris en charge.",
    bannerTitle:
      "Il se peut que vous rencontriez des problèmes de performance et des bogues dans votre navigateur actuel.",
    bannerDetail:
      "Voir la liste complète des navigateurs recommandés par le HCSS",
    here: "ici",
    dismiss: "Ignorer"
  },
  autoSaveIndicator: {
    saving: "Sauvegarde...",
    saved: "Sauvegardé",
    errorText: "Nous avons rencontré un problème. Cliquez ici pour réessayer."
  },
  estimatesLoadIndicator: {
    loading: "Chargement..."
  },
  remarkable: {
    estimateInsights: {
      textHeader:
        "Vous avez été identifié dans un commentaire sur une Estimation ({0}) :"
    },
    projectTracking: {
      textHeader:
        "Vous avez été identifié dans un commentaire sur un projet ({0}) :"
    },
    quoteManagement: {
      textHeader:
        "Vous avez été identifié dans un commentaire sur un devis envoyée à {0} {1} par {2} :"
    }
  },
  modals: {
    columnChooserModal: {
      title: "Afficher/masquer les colonnes",
      kpisTitle: "Afficher/masquer les ICP",
      buttons: {
        hide: "Cacher tout",
        show: "Tout afficher",
        collapseAll: "Réduire tout",
        expandAll: "Tout développer",
        close: "Fermer"
      },
      inputs: {
        search: {
          label: "Rechercher",
          placeholder: "Champs de recherche..."
        },
        dropdown: {
          sortBy: {
            label: "Trier par",
            placeHolder: "Trier par..."
          }
        },
        radio: {
          summaryType: {
            label: {
              sum: "Somme",
              avg: "Moyenne"
            }
          }
        }
      },
      noData: {
        title: "Aucun résultat trouvé"
      }
    }
  }
};
export default data;
