import { datadogRum } from "@datadog/browser-rum";
import config from "../config";

export const initializeDatadogRum = () => {
  const { rumEnabled, rumConfigOptions } = config.rum || {
    rumEnabled: false,
    rumConfigOptions: null
  };

  if (!rumEnabled || !rumConfigOptions) {
    console.info("Datadog RUM is disabled or not properly configured.");
    return;
  }

  try {
    datadogRum.init(rumConfigOptions);
    console.info("Datadog RUM initialized successfully.");
  } catch (error) {
    console.error("Failed to initialize Datadog RUM:", error);
  }
};
