import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useMemo,
  useState
} from "react";
import {
  Badge,
  Box,
  DialogContent,
  DialogTitle,
  InputBase,
  Slide,
  Tab,
  Tabs,
  Dialog,
  BadgeProps,
  Pagination,
  DialogActions,
  CircularProgress
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { ContactList } from "./contact-list";
import { AccountCircle, Business } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useDebounce } from "core";
import { useDispatch, useSelector } from "react-redux";
import { selectors, actions } from "modules/contacts";
import { CompanyList } from "./company-list";
import { Flex } from "hcss-core";
import { IGetContactSearchDto } from "api/GeneratedClients/ContactsClient";
import { strings } from "localization";
import { ThemeProvider } from "@hcss/theme";
import { calculateNumPages, getCurrentPage } from "core/util/pagination";
import { isSandboxEnabled } from "core/util/sandbox";

export const SearchContactsModal = () => {
  const dispatch = useDispatch();
  const showSearchModal = useSelector(selectors.getShowSearchModal);
  const isSearching = useSelector(selectors.getIsSearching);
  const [currentTab, setCurrentTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [searchValue, setSearchValue] = useState("");
  const debouncedSearch = useDebounce(searchValue, 1000).toLocaleLowerCase();

  const results = useSelector(selectors.getSearchResultsSorted);

  const uniqueCompanies = useMemo(() => {
    const companyDict: Record<string, CompanyData> = {};
    results.forEach(contact => {
      if (contact?.companyId && !companyDict[contact.companyId])
        companyDict[contact?.companyId] = {
          id: contact.companyId,
          companyName: contact?.companyName ?? "",
          companyCode: contact?.companyCode ?? ""
        };
    });
    return Object.values(companyDict);
  }, [results]);

  const numPagesContacts = calculateNumPages(results);
  const numPagesCompanies = calculateNumPages(uniqueCompanies, 6);
  const numPages = currentTab === 1 ? numPagesCompanies : numPagesContacts;

  const pagedResults: IGetContactSearchDto[] = useMemo(() => {
    return getCurrentPage(currentPage, results);
  }, [currentPage, results]);

  const pagedUniqueCompanies: CompanyData[] = useMemo(() => {
    return getCurrentPage(currentPage, uniqueCompanies, 6);
  }, [currentPage, uniqueCompanies]);

  useEffect(() => {
    dispatch(actions.searchContacts.request(debouncedSearch));
  }, [debouncedSearch, dispatch]);

  useEffect(() => {
    setCurrentPage(1);
  }, [currentTab]);

  const toggleModal = () =>
    dispatch(actions.setShowSearchModal(!showSearchModal));

  return (
    <ThemeProvider baseFontSize={10}>
      <Dialog
        id="contact-search-modal"
        open={showSearchModal}
        onClose={() => toggleModal()}
        TransitionComponent={Transition}
        maxWidth="sm"
      >
        <DialogTitle>
          <Flex>
            <InputBase
              autoComplete="off"
              autoFocus
              placeholder={
                strings.contactManagement.contactSearchModal
                  .searchBarPlaceholder
              }
              fullWidth
              id="standard-basic"
              value={searchValue}
              onChange={e => {
                setSearchValue(e.currentTarget.value);
              }}
            />
            {isSearching && <CircularProgress color="info" size={28} />}
          </Flex>
          {isSandboxEnabled() && (
            <Banner>
              <MainInfo>
                <WarningIconContainer>
                  <FontAwesomeIcon
                    style={warningIconStyle}
                    icon={["fad", "exclamation-triangle"]}
                    size="lg"
                  />
                </WarningIconContainer>
                <Title className="">
                  Search functionality will not work as expected in Sandbox mode
                </Title>
              </MainInfo>
            </Banner>
          )}
        </DialogTitle>
        <DialogContent sx={{ height: "60vh", maxHeight: "560px" }}>
          <Tabs
            value={currentTab}
            onChange={(_: any, newValue: number) => setCurrentTab(newValue)}
            variant="fullWidth"
          >
            <Tab
              icon={
                <StyledBadge badgeContent={results.length} max={99}>
                  <AccountCircle />
                </StyledBadge>
              }
              label={strings.contactManagement.contactSearchModal.tabs.contacts}
            />
            <Tab
              icon={
                <StyledBadge badgeContent={uniqueCompanies.length} max={99}>
                  <Business />
                </StyledBadge>
              }
              label={
                strings.contactManagement.contactSearchModal.tabs.companies
              }
            />
          </Tabs>
          {currentTab === 0 && <ContactList contacts={pagedResults} />}
          {currentTab === 1 && <CompanyList companies={pagedUniqueCompanies} />}
        </DialogContent>
        {numPages >= 1 && (
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              count={numPages}
              page={currentPage}
              onChange={(_: any, page: number) => setCurrentPage(page)}
            />
          </DialogActions>
        )}
      </Dialog>
    </ThemeProvider>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px"
  }
}));

const warningIconStyle: object = {
  "--fa-primary-color": "#2c4369"
};

const Banner = styled("div")({
  backgroundColor: "#ffc400",
  borderRadius: "3px",
  boxSizing: "border-box",
  boxShadow:
    "0 0 1px rgba(9, 30, 66, 0.31), 0 20px 32px -8px rgba(9, 30, 66, 0.25)",
  color: "#2c4369",
  padding: "5px",
  transition: "background-color 200ms",
  width: "100%",
  zIndex: "600",
  marginTop: "10px"
});

const MainInfo = styled("div")({
  display: "flex",
  alignItems: "center",
  height: "32px",
  marginLeft: "10px"
});

const WarningIconContainer = styled("div")({
  flex: "0 0 auto",
  width: "40px",
  height: "24px"
});

const Title = styled("span")({
  fontWeight: "600",
  fontSize: "14px",
  flex: "1",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
});

export interface CompanyData {
  id: string;
  companyName: string;
  companyCode: string;
}
