import { IVendorLocationDto } from "api/contacts-api-dto-interfaces";
import {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  CancelToken
} from "axios";
import appconfig from "../config";
import getDefaultInstance from "./AxiosInstance";
import {
  CompanyTypeDto,
  CreateCompanyTypeDto,
  CreateProductTypeDto,
  CreateVendorContactDto,
  CreateVendorDto,
  CreateVendorMinorityStatusDto,
  CreateVendorProductDto,
  MinorityTypeDto,
  ProductTypeDto,
  UpdateVendorContactDto,
  UpdateVendorDto,
  UpdateVendorMinorityStatusDto,
  VendorDto
} from "./GeneratedClients/contacts";
import {
  IMinorityTypeDto,
  IProductTypeDto
} from "./GeneratedClients/ContactsClient";

const baseUrl: string = appconfig.endpoints.CONTACTS;

export default class ContactsApi {
  private instance: AxiosInstance;
  private baseUrl: string;
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;

  constructor(token: string, selectedBusinessUnitId: string) {
    this.instance = getDefaultInstance(token);
    this.instance.interceptors.request.use(config => {
      config.headers["X-Requested-Business-Unit-Id"] = selectedBusinessUnitId;
      return config;
    });
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  getDetailedVendors = (
    cancelToken?: CancelToken | undefined
  ): Promise<VendorDto[]> => {
    let url = this.baseUrl + "/api/v1/vendors/detailed";
    url = url.replace(/[?&]$/, "");

    const options: AxiosRequestConfig = {
      method: "GET",
      url: url,
      headers: {
        Accept: "application/json"
      },
      cancelToken
    };
    return this.instance
      .request(options)
      .then(response => {
        response.data.forEach((vendor: any) => {
          const { products } = vendor;
          const filteredProducts = products.filter(
            (p: any) => p.product?.code.trim() !== "*"
          );
          vendor.products = filteredProducts;
        });
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  getMinorityTypes = (
    cancelToken?: CancelToken | undefined
  ): Promise<MinorityTypeDto[]> => {
    let url = this.baseUrl + "/api/v1/minorityTypes";
    url = url.replace(/[?&]$/, "");

    const options: AxiosRequestConfig = {
      method: "GET",
      url: url,
      headers: {
        Accept: "application/json"
      },
      cancelToken
    };
    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  getProductTypes = (
    cancelToken?: CancelToken | undefined
  ): Promise<ProductTypeDto[]> => {
    let url_ = this.baseUrl + "/api/v1/productTypes";
    url_ = url_.replace(/[?&]$/, "");

    const options: AxiosRequestConfig = {
      method: "GET",
      url: url_,
      headers: {
        Accept: "application/json"
      },
      cancelToken
    };
    return this.instance
      .request(options)
      .then(response => {
        return response.data.filter((p: any) => p.code?.trim() !== "*");
      })
      .catch(error => {
        throw error;
      });
  };

  getVendor = (
    id: string,
    cancelToken?: CancelToken | undefined
  ): Promise<VendorDto> => {
    let url = this.baseUrl + "/api/v1/vendors/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url = url.replace("{id}", encodeURIComponent("" + id));
    url = url.replace(/[?&]$/, "");

    const options: AxiosRequestConfig = {
      method: "GET",
      url: url,
      headers: {
        Accept: "application/json"
      },
      cancelToken
    };
    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  getVendorsCsvReport = (
    cancelToken?: CancelToken | undefined
  ): Promise<string> => {
    const url = this.baseUrl + "/api/v2/vendors/report/csv";

    const options: AxiosRequestConfig = {
      method: "GET",
      url: url,
      headers: {
        Accept: "text/csv"
      },
      cancelToken
    };

    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  getVendorsExcelReport = (
    cancelToken?: CancelToken | undefined
  ): Promise<Blob> => {
    const url = this.baseUrl + "/api/v2/vendors/report/excel";

    const options: AxiosRequestConfig = {
      method: "GET",
      url: url,
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      },
      responseType: "blob",
      cancelToken
    };

    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  createVendor = (
    body?: CreateVendorDto | undefined,
    cancelToken?: CancelToken | undefined
  ): AxiosPromise<string> => {
    const url = this.baseUrl + "/api/v2/vendors";
    const content_ = JSON.stringify(body);

    const options: AxiosRequestConfig = {
      data: content_,
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      cancelToken
    };
    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  updateVendor = (
    id: string,
    body?: UpdateVendorDto | undefined,
    cancelToken?: CancelToken | undefined
  ): AxiosPromise<void> => {
    let url = this.baseUrl + "/api/v2/vendors/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url = url.replace("{id}", encodeURIComponent("" + id));
    url = url.replace(/[?&]$/, "");

    const content = JSON.stringify(body);

    const options: AxiosRequestConfig = {
      data: content,
      method: "PUT",
      url: url,
      headers: {
        "Content-Type": "application/json"
      },
      cancelToken
    };

    return this.instance
      .request(options)
      .then(reponse => {
        return reponse.data;
      })
      .catch(error => {
        throw error;
      });
  };
  deleteVendor = (
    id: string,
    cancelToken?: CancelToken | undefined
  ): Promise<void> => {
    let url = this.baseUrl + "/api/v1/vendors/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url = url.replace("{id}", encodeURIComponent("" + id));
    url = url.replace(/[?&]$/, "");

    const options: AxiosRequestConfig = {
      method: "DELETE",
      url: url,
      headers: {},
      cancelToken
    };
    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  createVendorContact = (
    vendorId: string,
    body?: CreateVendorContactDto | undefined,
    cancelToken?: CancelToken | undefined
  ): Promise<string> => {
    let url = this.baseUrl + "/api/v1/vendors/{vendorId}/contacts";
    url = url.replace("{vendorId}", encodeURIComponent("" + vendorId));
    url = url.replace(/[?&]$/, "");

    const content_ = JSON.stringify(body);

    const options: AxiosRequestConfig = {
      data: content_,
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      cancelToken
    };
    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  updateVendorContact = (
    vendorId: string,
    contactId: string,
    body?: UpdateVendorContactDto | undefined,
    moveVendorContact?: boolean,
    cancelToken?: CancelToken | undefined
  ): Promise<void> => {
    let url = this.baseUrl + "/api/v1/vendors/{vendorId}/contacts/{contactId}";
    url = url.replace("{vendorId}", encodeURIComponent("" + vendorId));
    url = url.replace("{contactId}", encodeURIComponent("" + contactId));
    url = url.replace(/[?&]$/, "");

    const content_ = JSON.stringify(body);

    const options: AxiosRequestConfig = {
      data: content_,
      method: "PUT",
      url: url,
      headers: {
        "Content-Type": "application/json"
      },
      params: {
        moveVendorContact: moveVendorContact
      },
      cancelToken
    };
    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  deleteVendorContact = (
    vendorId: string,
    contactId: string,
    cancelToken?: CancelToken | undefined
  ): Promise<void> => {
    let url = this.baseUrl + "/api/v1/vendors/{vendorId}/contacts/{contactId}";
    url = url.replace("{vendorId}", encodeURIComponent("" + vendorId));
    url = url.replace("{contactId}", encodeURIComponent("" + contactId));
    url = url.replace(/[?&]$/, "");

    const options: AxiosRequestConfig = {
      method: "DELETE",
      url: url,
      headers: {},
      cancelToken
    };
    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  searchVendorContacts = (
    searchTerm: string,
    cancelToken?: CancelToken | undefined
  ): Promise<void> => {
    const url =
      this.baseUrl +
      `/api/v1/contacts/search?filter=${encodeURIComponent(searchTerm)}`;

    const options: AxiosRequestConfig = {
      method: "GET",
      url: url,
      headers: {},
      cancelToken
    };
    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  createVendorMinorityStatus = (
    vendorId: string,
    body?: CreateVendorMinorityStatusDto | undefined,
    cancelToken?: CancelToken | undefined
  ): Promise<string> => {
    let url = this.baseUrl + "/api/v1/vendors/{vendorId}/statuses";
    url = url.replace("{vendorId}", encodeURIComponent("" + vendorId));
    url = url.replace(/[?&]$/, "");

    const content_ = JSON.stringify(body);

    const options: AxiosRequestConfig = {
      data: content_,
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      cancelToken
    };
    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  updateVendorMinorityStatus = (
    vendorId: string,
    statusId: string,
    body?: UpdateVendorMinorityStatusDto | undefined,
    cancelToken?: CancelToken | undefined
  ): Promise<void> => {
    let url = this.baseUrl + "/api/v1/vendors/{vendorId}/statuses/{statusId}";
    url = url.replace("{vendorId}", encodeURIComponent("" + vendorId));
    url = url.replace("{statusId}", encodeURIComponent("" + statusId));
    url = url.replace(/[?&]$/, "");

    const content_ = JSON.stringify(body);

    const options: AxiosRequestConfig = {
      data: content_,
      method: "PUT",
      url: url,
      headers: {
        "Content-Type": "application/json"
      },
      cancelToken
    };
    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };
  deleteVendorMinorityStatus = (
    vendorId: string,
    statusId: string,
    cancelToken?: CancelToken | undefined
  ): Promise<void> => {
    let url = this.baseUrl + "/api/v1/vendors/{vendorId}/statuses/{statusId}";
    url = url.replace("{vendorId}", encodeURIComponent("" + vendorId));
    url = url.replace("{statusId}", encodeURIComponent("" + statusId));
    url = url.replace(/[?&]$/, "");

    const options: AxiosRequestConfig = {
      method: "DELETE",
      url: url,
      headers: {},
      cancelToken
    };
    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  getCompanyTypes = (
    cancelToken?: CancelToken | undefined
  ): Promise<CompanyTypeDto[]> => {
    let url = this.baseUrl + "/api/v1/companyTypes";
    url = url.replace(/[?&]$/, "");

    const options: AxiosRequestConfig = {
      method: "GET",
      url: url,
      headers: {
        Accept: "application/json"
      },
      cancelToken
    };
    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  getLocationsByVendorId = (
    vendorId: string,
    cancelToken?: CancelToken | undefined
  ): Promise<IVendorLocationDto[]> => {
    let url = this.baseUrl + "/api/v2/vendors/{vendorId}/locations";
    url = url.replace("{vendorId}", encodeURIComponent("" + vendorId));
    url = url.replace(/[?&]$/, "");

    const options: AxiosRequestConfig = {
      method: "GET",
      url: url,
      headers: {},
      cancelToken
    };
    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  createVendorLocation = (
    vendorId: string,
    body?: IVendorLocationDto | undefined,
    cancelToken?: CancelToken | undefined
  ): Promise<string> => {
    let url = this.baseUrl + "/api/v2/vendors/{vendorId}/location";
    url = url.replace("{vendorId}", encodeURIComponent("" + vendorId));
    url = url.replace(/[?&]$/, "");
    const content_ = JSON.stringify(body);

    const options: AxiosRequestConfig = {
      data: content_,
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      cancelToken
    };
    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  createVendorLocations = (
    vendorId: string,
    body?: IVendorLocationDto[] | undefined,
    cancelToken?: CancelToken | undefined
  ): Promise<{ message: string }> => {
    let url = this.baseUrl + "/api/v2/vendors/{vendorId}/locations";
    url = url.replace("{vendorId}", encodeURIComponent("" + vendorId));
    url = url.replace(/[?&]$/, "");
    const content_ = JSON.stringify(body);

    const options: AxiosRequestConfig = {
      data: content_,
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      cancelToken
    };
    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  createMinorityType = (
    body?: IMinorityTypeDto | undefined,
    cancelToken?: CancelToken | undefined
  ): Promise<string> => {
    let url = this.baseUrl + "/api/v1/minorityTypes";
    url = url.replace(/[?&]$/, "");
    const content_ = JSON.stringify(body);

    const options: AxiosRequestConfig = {
      data: content_,
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      cancelToken
    };

    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  updateMinorityType = (
    id: string,
    body?: IMinorityTypeDto | undefined,
    cancelToken?: CancelToken | undefined
  ): Promise<string> => {
    let url = this.baseUrl + "/api/v1/minorityTypes/{id}";
    url = url.replace("{id}", encodeURIComponent("" + id));
    url = url.replace(/[?&]$/, "");
    const content_ = JSON.stringify(body);

    const options: AxiosRequestConfig = {
      data: content_,
      method: "PUT",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      cancelToken
    };

    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  createProductType = (
    body?: IProductTypeDto | undefined,
    cancelToken?: CancelToken | undefined
  ): Promise<string> => {
    let url = this.baseUrl + "/api/v1/productTypes";
    url = url.replace(/[?&]$/, "");
    const content_ = JSON.stringify(body);

    const options: AxiosRequestConfig = {
      data: content_,
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      cancelToken
    };

    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };

  updateProductType = (
    id: string,
    body?: IProductTypeDto | undefined,
    cancelToken?: CancelToken | undefined
  ): Promise<string> => {
    let url = this.baseUrl + "/api/v1/productTypes/{id}";
    url = url.replace("{id}", encodeURIComponent("" + id));
    url = url.replace(/[?&]$/, "");
    const content_ = JSON.stringify(body);

    const options: AxiosRequestConfig = {
      data: content_,
      method: "PUT",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      cancelToken
    };

    return this.instance
      .request(options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  };
}
