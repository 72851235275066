import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { selectors as contactsSelectors } from "modules/contacts";

export function useGetSelectedVendor() {
  const vendorDetailsMatch = useRouteMatch<{ vendorId: string }>({
    path: "/contacts/:vendorId"
  });
  const vendorList = useSelector(contactsSelectors.getVendorList);
  const currentSelectedVendor = vendorList.find(
    v => v.id === vendorDetailsMatch?.params.vendorId
  );
  return currentSelectedVendor;
}
