import { Badge } from "core";
import { Item, MenuSection } from "core/components/hcss-navigation";
import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { paths } from "../../../../estimates/views/paths";
import { HeaderSectionWithBackButton } from "../../common/header-section-with-back-button";
import { strings } from "localization";

export const CustomizeReportingContext = () => {
  const path = useLocation().pathname;

  return (
    <div
      className="estimate-navigation-context"
      data-testid="customize-reporting-context"
    >
      <HeaderSectionWithBackButton backPath={paths.base}>
        <div>{strings.layout.menu.estimating.customize}</div>
      </HeaderSectionWithBackButton>
      <MenuSection>
        {menuStyle => (
          <ItemContainer style={menuStyle}>
            <Item
              data-testid="navitem-activity-totals"
              key="activity-totals"
              text={strings.layout.menu.estimating.activityTotals}
              isSelected={path === paths.customizeReporting}
              to={paths.customizeReporting}
              after={
                <Badge type="info" style={{ padding: "4px 8px" }}>
                  {strings.featureStatus.beta}
                </Badge>
              }
            />
          </ItemContainer>
        )}
      </MenuSection>
    </div>
  );
};

const ItemContainer = styled.div`
  font-size: 14px;
  margin-right: 14px;
  color: ${props => props.theme.colors.neutral[0]};
`;
