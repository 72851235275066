import { IJsonPatchDocument } from "../models";
import { isDict } from "./types";

export const patchDocument = (
  changes: Record<string, any>,
  path: string = ""
): IJsonPatchDocument => {
  const patch = Object.entries(changes).flatMap(([key, val]) => {
    if (isDict(val)) return patchDocument(val, `${path}/${key}`);
    else {
      return {
        op: "replace",
        path: `${path}/${key}`,
        value: val
      };
    }
  });
  return patch;
};
