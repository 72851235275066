import { isLocal } from "config";

function track(
  eventName: string,
  properties?: any | undefined,
  callback?: (() => void) | undefined
) {
  if (!isLocal) {
    //TODO: need to add to gainsight
    // return mixpanel.track(eventName, properties, callback);
  }

  return callback?.();
}

export { track };
