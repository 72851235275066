import React from "react";
import Clipboard from "react-clipboard.js";
import styled from "styled-components";
import config from "config";
import moment from "moment";
import { Calendar } from "api";
import { WithId } from "core";
import { strings } from "localization";

import {
  FormControl,
  Icon,
  InputGroup,
  notify,
  OverlayTrigger,
  Tooltip
} from "hcss-components";

export interface ReadOnlyCalendarContentProps {
  templateName: string;
  readonlyCalendar?: WithId<Calendar>;
}

export const ReadOnlyCalendarContent: React.FC<ReadOnlyCalendarContentProps> = ({
  templateName,
  readonlyCalendar
}) => {
  const link = readonlyCalendar
    ? `${config.endpoints.PRECON}/api/cal/${readonlyCalendar.token}`
    : "";

  const handleCopy = () => {
    notify(
      "info",
      strings.calendars.card.copy.title,
      strings.formatString(
        strings.calendars.card.copy.message,
        templateName
      ) as string
    );
  };

  const handleFocus = (event: any) => event.target.select();

  return (
    <>
      <ContentWrapper>
        <div className="fieldTitle">
          {strings.calendars.card.readlonlyLinkLabel}
        </div>
        <InputGroup style={{ width: "100%" }}>
          <LinkFormControl
            type="text"
            value={link}
            onFocus={handleFocus}
            readOnly
            data-testid="calendar-card-link-input"
          />

          <ClipboardButton
            data-testid="calendar-card-link-copy"
            component={InputGroup.Addon}
            data-clipboard-text={link}
            onSuccess={handleCopy}
          >
            <Icon name="clipboard" />
          </ClipboardButton>
        </InputGroup>
      </ContentWrapper>
      {readonlyCalendar && (
        <OverlayTrigger
          overlay={
            <Tooltip
              style={{ zIndex: 9999999 }}
              id={`${readonlyCalendar.id}-sync-tooltip`}
            >
              {strings.calendars.card.syncWarning}
            </Tooltip>
          }
          placement="left"
        >
          <LastSync>
            <Icon name="info-circle" margin="right" />
            {strings.formatString(
              strings.calendars.card.lastSync,
              readonlyCalendar.lastPing
                ? moment(readonlyCalendar.lastPing).format("l LT")
                : strings.calendars.card.none
            )}
          </LastSync>
        </OverlayTrigger>
      )}
    </>
  );
};

const ClipboardButton = styled(Clipboard)<{ disabled?: boolean }>`
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;

const LinkFormControl = styled(FormControl)`
  background-color: #fff;
`;

const LastSync = styled.div`
  margin-top: 0;
  margin-left: 5px;
`;

const ContentWrapper = styled.div`
  margin: 15px 0 10px 5px;

  div.fieldTitle {
    margin: 5px 0 5px 0;
    font-weight: bold;
    font-size: 14px;
  }
`;
