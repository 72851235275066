import React from "react";
import styled from "styled-components";
import { ConcreteColors, Button, Icon } from "hcss-components";
import { strings } from "localization";
import { CodebooksIcon } from "./CodebooksIcon";
import {
  PageWrapper,
  Jumbotron,
  ContentWrapper,
  ImageContainer,
  defaultIconStyle
} from "../../../../core/components/empty-state/index";

export const CodebooksLimitedView = () => {
  return (
    <PageWrapper>
      <Jumbotron>
        <ContentWrapper data-testid="no-estimates-found-message">
          <ImageContainer style={defaultIconStyle}>
            <div style={{ height: "180px", width: "180px" }}>
              <CodebooksIcon />
            </div>
          </ImageContainer>
          <Blurb>
            <h1>
              {strings.layout.menu.estimating.codebookslimitedPlaceholder.title}
            </h1>
            <h1>
              {strings.layout.menu.estimating.codebookslimitedPlaceholder.text}
            </h1>
            <p style={{ marginTop: "45px" }}>
              {
                strings.layout.menu.estimating.codebookslimitedPlaceholder
                  .fullFeaturesText
              }
            </p>
            <Button
              bsSize={"xs"}
              style={{ alignItems: "center" }}
              onClick={() =>
                window.open(
                  "https://help.hcss.com/s/article/What-Are-the-Differences-Between-a-Limited-Features-Account-and-a-Full-Features-Account-in-HeavyBid-Pre-Construction",
                  "_blank"
                )
              }
            >
              Click Here&nbsp;
              <Icon name="arrow-right" margin="left" />
            </Button>
            <p style={{ marginTop: "45px" }}>
              {
                strings.layout.menu.estimating.codebookslimitedPlaceholder
                  .moreInfoText
              }
            </p>
            <Button
              bsSize={"xs"}
              style={{ alignItems: "center" }}
              onClick={() =>
                window.open(
                  "https://help.hcss.com/s/article/What-is-the-HeavyBid-Pre-Construction-Codebooks-Dashboard",
                  "_blank"
                )
              }
            >
              Click Here&nbsp;
              <Icon name="arrow-right" margin="left" />
            </Button>
          </Blurb>
        </ContentWrapper>
      </Jumbotron>
    </PageWrapper>
  );
};

const Blurb = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 30px;
  align-items: center;
  text-align: center;

  h1 {
    color: ${ConcreteColors.gray700};
    font-size: 18px;
    font-weight: 600;
    line-height: 1.075;
    margin-bottom: 10px;
    padding: 0px 110px 0px 110px;
  }

  p {
    color: ${ConcreteColors.gray600};
    font-size: 14px;
    font-weight: 400;
    max-width: 600px;
    margin: 20px auto 10px auto;
  }

  div > button {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    font-size: small;
    padding-top: 8px;
    padding-bottom: 6px;
  }
`;
