import React from "react";
import styled from "styled-components";
import { Box } from "hcss-core";
import { TypedDataColumn, DataColumnType } from "hcss-tables";
import { strings } from "localization";
import { Grid as MuiGrid } from "@mui/material";
import { useCodebooksContext } from "../../context/CodebooksContext";

interface TimestampsProps {
  codebooksTimestamp: string;
  estimateTimestamp: string;
}

export const Timestamps = () => {
  const { estimateTimestamp, codebooksTimestamp } = useCodebooksContext();
  return (
    <MuiGrid container direction={"column"} justifyContent={"space-between"}>
      {codebooksTimestamp && (
        <MuiGrid item>
          <SectionSubText>
            {`${strings.estimates.codebooks.lastUpdateCodebook} ${codebooksTimestamp}`}
          </SectionSubText>
        </MuiGrid>
      )}
      {estimateTimestamp && (
        <MuiGrid item>
          <SectionSubText>
            {`${strings.estimates.codebooks.lastUpdateEstimate} ${estimateTimestamp}`}
          </SectionSubText>
        </MuiGrid>
      )}
    </MuiGrid>
  );
};

export const Section = styled(Box).attrs(() => ({ as: "section" }))`
  border-radius: 4px;
  padding: 5px 12px 10px 12px;
  margin-bottom: 32px;
  border-left: 4px solid transparent;
  height: calc(100vh - 185px);
  @media print {
    & {
      page-break-inside: avoid;
    }
  }
`;

export const SectionHeader = styled.div`
  padding-bottom: 10px;
`;

export const SectionTitle = styled.h2`
  display: inline-block;
  margin: 2px 0 0 0;
  font-size: 1em;
  font-weight: 500;
  vertical-align: middle;
`;

export const SectionSubText = styled.div`
  float: right;
  font-size: 12.5px;
`;
const activityColumn: TypedDataColumn = {
  title: strings.estimates.codebooks.columnNames.activityCode,
  type: DataColumnType.ShortText,
  name: "code"
};
const codebookCodeColumn: TypedDataColumn = {
  title: strings.estimates.codebooks.columnNames.codebookCode,
  type: DataColumnType.ShortText,
  name: "code"
};
const descColumn: TypedDataColumn = {
  title: strings.estimates.codebooks.columnNames.description,
  type: DataColumnType.ShortText,
  name: "description"
};
const levelColumn: TypedDataColumn = {
  title: strings.estimates.codebooks.columnNames.level,
  type: DataColumnType.ShortText,
  name: "level"
};
const unitColumn: TypedDataColumn = {
  title: strings.estimates.codebooks.columnNames.unit,
  type: DataColumnType.ShortText,
  name: "units"
};
const estimateColumn: TypedDataColumn = {
  title: strings.estimates.codebooks.columnNames.estimateCode,
  type: DataColumnType.ShortText,
  name: "estimate"
};
const biditemColumn: TypedDataColumn = {
  title: strings.estimates.codebooks.columnNames.biditemCode,
  type: DataColumnType.ShortText,
  name: "biditem"
};
const lastUpdateColumn: TypedDataColumn = {
  title: strings.estimates.codebooks.columnNames.lastUpdate,
  type: DataColumnType.Date,
  groupingEnabled: false,
  name: "lastUpdate"
};
const lastModifiedColumn: TypedDataColumn = {
  title: strings.estimates.codebooks.columnNames.lastModified,
  type: DataColumnType.DateTime,
  groupingEnabled: false,
  name: "lastModified"
};
const timesUsedColumn: TypedDataColumn = {
  title: "Times Used",
  type: DataColumnType.Number,
  name: "timesUsed"
};
const timesModifiedColumn: TypedDataColumn = {
  title: "Times Modified",
  type: DataColumnType.Number,
  name: "timesModified"
};
const codebookValueColumn: TypedDataColumn = {
  title: "Codebook Value",
  type: DataColumnType.ShortText,
  name: "codebookValue"
};
const modifiedValueColumn: TypedDataColumn = {
  title: "Modified Value",
  type: DataColumnType.ShortText,
  name: "modifiedValue"
};
const modificationColumn: TypedDataColumn = {
  title: "Modification",
  type: DataColumnType.ShortText,
  name: "modification"
};
export const unusedColumns = [
  codebookCodeColumn,
  descColumn,
  levelColumn,
  unitColumn,
  lastUpdateColumn
];
export const missingColumns = [
  activityColumn,
  descColumn,
  unitColumn,
  estimateColumn,
  biditemColumn,
  lastModifiedColumn
];
export const utilizedColumns = [
  codebookCodeColumn,
  descColumn,
  levelColumn,
  timesUsedColumn,
  timesModifiedColumn
];
export const utilizedDetailsColumns = [
  modificationColumn,
  codebookValueColumn,
  modifiedValueColumn,
  estimateColumn,
  biditemColumn,
  lastModifiedColumn
];
