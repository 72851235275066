import { SchemaFieldType } from "../../../api";
import { strings } from "localization";

const fieldNames = strings.schemas.setup.examples;

export const orderedSchemaFieldTypes = [
  SchemaFieldType.ShortText,
  SchemaFieldType.LongText,
  SchemaFieldType.Number,
  SchemaFieldType.Currency,
  SchemaFieldType.Date,
  SchemaFieldType.DateTime,
  SchemaFieldType.List,
  SchemaFieldType.Boolean,
  SchemaFieldType.Links,
  SchemaFieldType.Checklist,
  SchemaFieldType.Location,
  SchemaFieldType.Company,
  SchemaFieldType.Contact,
  SchemaFieldType.Table,
  SchemaFieldType.Calculated
];

export const schemaFieldTypeOptions: Record<number, SchemaFieldTypeOptions> = {
  [SchemaFieldType.ShortText]: {
    id: SchemaFieldType.ShortText,
    name: fieldNames.text.name,
    icon: "font",
    showInSetup: true,
    isAvailableToBasic: true
  },
  [SchemaFieldType.LongText]: {
    id: SchemaFieldType.LongText,
    name: fieldNames.note.name,
    icon: "align-justify",
    showInSetup: true,
    isAvailableToBasic: true
  },
  [SchemaFieldType.Number]: {
    id: SchemaFieldType.Number,
    name: fieldNames.number.name,
    icon: "hashtag",
    showInSetup: true,
    isAvailableToBasic: true
  },
  [SchemaFieldType.Currency]: {
    id: SchemaFieldType.Currency,
    name: fieldNames.currency.name,
    icon: "usd",
    showInSetup: true,
    isAvailableToBasic: true
  },
  [SchemaFieldType.Date]: {
    id: SchemaFieldType.Date,
    name: fieldNames.date.name,
    icon: "calendar",
    showInSetup: true,
    isAvailableToBasic: true
  },
  [SchemaFieldType.DateTime]: {
    id: SchemaFieldType.DateTime,
    name: fieldNames.dateTime.name,
    icon: "clock-o",
    showInSetup: true,
    isAvailableToBasic: true
  },
  [SchemaFieldType.List]: {
    id: SchemaFieldType.List,
    name: fieldNames.selectionList.name,
    icon: "list",
    showInSetup: true,
    isAvailableToBasic: true
  },
  [SchemaFieldType.Boolean]: {
    id: SchemaFieldType.Boolean,
    name: fieldNames.yesNo.name,
    icon: "check-square-o",
    showInSetup: true,
    isAvailableToBasic: true
  },
  [SchemaFieldType.States]: {
    id: SchemaFieldType.States,
    name: fieldNames.states.name,
    icon: "globe",
    showInSetup: false,
    isAvailableToBasic: true
  },
  [SchemaFieldType.Custom]: {
    id: SchemaFieldType.Custom,
    name: fieldNames.custom.name,
    icon: "table",
    showInSetup: false,
    isAvailableToBasic: true
  },

  [SchemaFieldType.BidResults]: {
    id: SchemaFieldType.BidResults,
    name: fieldNames.bidResults.name,
    icon: "table",
    showInSetup: false,
    isAvailableToBasic: true
  },
  [SchemaFieldType.Estimates]: {
    id: SchemaFieldType.Estimates,
    name: fieldNames.estimates.name,
    icon: "product-heavybid",
    iconColor: "rgb(0, 150, 57)",
    showInSetup: false,
    isAvailableToBasic: true
  },
  [SchemaFieldType.Links]: {
    id: SchemaFieldType.Links,
    name: fieldNames.hyperlink.name,
    icon: "link",
    showInSetup: true,
    isAvailableToBasic: false
  },
  [SchemaFieldType.PhoneNumber]: {
    id: SchemaFieldType.PhoneNumber,
    name: "phoneNumber", // TODO: translate..?
    icon: "phone",
    showInSetup: false,
    isAvailableToBasic: true
  },
  [SchemaFieldType.Checklist]: {
    id: SchemaFieldType.Checklist,
    name: fieldNames.checklist.name,
    icon: "check",
    showInSetup: true,
    isAvailableToBasic: false
  },
  [SchemaFieldType.Location]: {
    id: SchemaFieldType.Location,
    name: fieldNames.location.name,
    icon: "compass",
    showInSetup: true,
    isAvailableToBasic: false
  },
  [SchemaFieldType.MultiSelectList]: {
    id: SchemaFieldType.MultiSelectList,
    name: fieldNames.selectionList.name,
    icon: "list",
    showInSetup: true,
    isAvailableToBasic: true
  },
  [SchemaFieldType.PreConId]: {
    id: SchemaFieldType.PreConId,
    name: fieldNames.preconId.name,
    icon: "meter",
    showInSetup: false,
    isAvailableToBasic: false
  },
  [SchemaFieldType.Company]: {
    id: SchemaFieldType.Company,
    name: fieldNames.company.name,
    icon: "building",
    showInSetup: true,
    isAvailableToBasic: true
  },
  [SchemaFieldType.Contact]: {
    id: SchemaFieldType.Contact,
    name: fieldNames.contact.name,
    icon: "user-profile",
    showInSetup: true,
    isAvailableToBasic: true
  },

  [SchemaFieldType.Table]: {
    id: SchemaFieldType.Table,
    name: fieldNames.table.name,
    icon: "table",
    showInSetup: true,
    isAvailableToBasic: false
  },
  [SchemaFieldType.Calculated]: {
    id: SchemaFieldType.Calculated,
    name: fieldNames.calculated.name,
    icon: "calculator",
    showInSetup: true,
    isAvailableToBasic: false
  }
};

export interface SchemaFieldTypeOptions {
  id: SchemaFieldType;
  name: string;
  icon: string;
  iconColor?: string;
  showInSetup: boolean;
  isAvailableToBasic: boolean;
}
