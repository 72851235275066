import numeral from "numeral";
import * as React from "react";
import MaskedInput from "react-text-mask";
import styled from "styled-components";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { SchemaFieldType } from "../../../../api";
import { FilterControlProps } from "./common";
import { strings } from "../../../../localization";
import { formatPercent } from "core";

export const NumberFilter: React.FunctionComponent<FilterControlProps> = ({
  field,
  filter,
  onChange
}) => {
  const isCurrency = field.type === SchemaFieldType.Currency;
  const isPercentage = field.config?.showAsPercent;

  const numberMask = React.useMemo(
    () =>
      createNumberMask({
        allowDecimal: true,
        allowNegative: true,
        prefix: isCurrency ? "$" : "",
        suffix: isPercentage ? "%" : "",
        decimalLimit: isCurrency || isPercentage ? 2 : 5,
        integerLimit: 10,
        allowLeadingZeroes: true
      }),
    [isCurrency]
  );

  const handleInputChange = (e: any) => {
    e.persist();
    const value: string = e.target.value;
    const numberValue = numeral(value).value();
    return value.trim() !== "" && !isNaN(numberValue) ? numberValue : undefined;
  };

  const updateLowerValue = (value: number | undefined) => {
    onChange({
      ...filter,
      value: { ...filter.value, from: value }
    });
  };

  const updateUpperValue = (value: number | undefined) => {
    onChange({
      ...filter,
      value: { ...filter.value, to: value }
    });
  };

  const values = {
    from:
      filter.value && (filter.value.from || filter.value.from === 0)
        ? filter.value.from
        : "",
    to:
      filter.value && (filter.value.to || filter.value.to === 0)
        ? filter.value.to
        : ""
  };
  if (isPercentage) {
    values.from = formatPercent(values.from);
    values.to = formatPercent(values.to);
  }

  return (
    <div>
      <NumberFilterContainer>
        <NumberInputContainer>
          <MaskedInput
            className="form-control"
            value={values.from}
            onChange={e => {
              const value = handleInputChange(e);
              updateLowerValue(value);
            }}
            mask={numberMask}
            placeholder={strings.core.filters.numMin}
          />
        </NumberInputContainer>

        <span>-</span>

        <NumberInputContainer>
          <MaskedInput
            className="form-control"
            value={values.to}
            onChange={e => {
              const value = handleInputChange(e);
              updateUpperValue(value);
            }}
            mask={numberMask}
            placeholder={strings.core.filters.numMax}
          />
        </NumberInputContainer>
      </NumberFilterContainer>
    </div>
  );
};

const NumberFilterContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NumberInputContainer = styled.div`
  width: 150px;
  margin: 4px;
`;
