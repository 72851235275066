import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UndoEstimateLinkingStatus,
  actions,
  selectors as projectSelectors
} from "../state";
export interface UndoEstimatesLinkingOptions {
  whenUndid?: (projectId: string, ids: string[]) => void;
  whenFails?: (error?: Error) => void;
}

export const useUndoEstimatesLinking = (
  init: UndoEstimatesLinkingOptions | undefined = undefined
) => {
  const [undoOptions, setUndoOptions] = useState(init);
  const [undoLoading, setUndoLoading] = useState(false);
  const state = useSelector(projectSelectors.getUndoEstimatesLinkingState);

  const dispatch = useDispatch();

  const resetUndo = useCallback(() => {
    if (state.status) {
      dispatch(actions.resetUndoEstimatesLinking());
    }
  }, [state, dispatch]);

  const undoEstimateLinking = useCallback(() => {
    if (undoOptions && state.projectId && state.linkedEstimatesIds?.length) {
      dispatch(actions.undoEstimatesLinking());
      setUndoLoading(true);
    }
  }, [dispatch, undoOptions, state]);

  useEffect(() => {
    if (
      undoOptions &&
      undoOptions.whenFails &&
      state.status === UndoEstimateLinkingStatus.Fails
    ) {
      undoOptions.whenFails(state.error);
      setUndoLoading(false);
    }
  }, [undoOptions, state]);

  useEffect(() => {
    if (
      undoOptions &&
      undoOptions.whenUndid &&
      state.linkedEstimatesIds &&
      state.projectId &&
      state.status === UndoEstimateLinkingStatus.Success
    ) {
      undoOptions.whenUndid(state.projectId, state.linkedEstimatesIds);
      setUndoLoading(false);
    }
  }, [undoOptions, state]);

  const canUndoStatuses = [
    UndoEstimateLinkingStatus.Ready,
    UndoEstimateLinkingStatus.Fails,
    UndoEstimateLinkingStatus.OptimisticallyReady
  ];

  return {
    canUndo: !!(
      undoOptions &&
      state.projectId &&
      state.linkedEstimatesIds?.length &&
      state.status &&
      canUndoStatuses.includes(state.status)
    ),
    resetUndo,
    undoEstimateLinking,
    setUndoOptions,
    error: state.error,
    loading: undoLoading,
    projectId: state.projectId
  };
};
