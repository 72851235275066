import { useRouteMatch } from "react-router-dom";
import { ProjectTrackingModule } from "./project-tracking";
import { EstimatingModule } from "./estimating";
import { ContactsModule } from "./contacts";
import { useAuthorization } from "modules/account";

export const useModuleNavigation = () => {
  const auth = useAuthorization();
  const projectsModuleMatch = useRouteMatch({ path: "/projects" });
  const estimatesModuleMatch = useRouteMatch({ path: "/estimates" });
  const contactsModuleMatch = useRouteMatch({ path: "/contacts" });

  if (projectsModuleMatch) {
    return { module: ProjectTrackingModule };
  }

  if (estimatesModuleMatch) {
    return { module: EstimatingModule };
  }

  if (contactsModuleMatch) {
    return { module: ContactsModule };
  }

  return { module: undefined };
};
