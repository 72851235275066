import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Flex } from "hcss-core";
import { Icon } from "hcss-components";
import { EmailField, formatFirstNameLastName, TextOverflow } from "core";
import { useSelector } from "react-redux";
import { selectors as contactsSelectors } from "modules/contacts";
import { PhoneNumber } from "modules/contacts/components/vendor-details";
import { usePermissions, useAuthorization } from "modules/account";
import { DropdownList } from "react-widgets";
import { SchemaFormFieldInputProps } from "./common";
import { strings } from "localization";
import styled from "styled-components";
import { UserAvatar } from "../../../../core/components/avatar";
import { Overlay } from "hcss-components";
import { ContactPopover } from "modules/contacts/components/contact-popover";
import { intersection, sortBy } from "lodash-es";
import {
  IContactDto,
  IVendorDtoV1Response
} from "api/GeneratedClients/ContactsClient";
import { IContactFieldData } from "modules/contacts/interfaces/IContactFieldData";
import { IContactListOption } from "modules/contacts/interfaces/IContactListOption";
import { useGetSelectedVendor } from "modules/contacts/hooks/use-get-selected-vendor";
import { ContactListModal } from "modules/contacts/components/contact-list-modal";
import { SchemaField } from "api";
import { IconButton } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import ConfirmDelete from "../../../../core/components/modals/ConfirmDelete";

const toContactFieldData = (
  contact: IContactDto,
  vendor: IVendorDtoV1Response
): IContactFieldData => ({
  id: contact.id,
  firstName: contact.firstName,
  lastName: contact.lastName,
  companyId: vendor.id,
  companyCode: vendor.code,
  companyName: vendor.name,
  email: contact.emailAddress,
  phoneNumber: contact.phoneNumber,
  products: contact.products,
  office: vendor.locations?.find(l => l.id === contact.vendorLocationId)
});

const newContact = (vendor?: IVendorDtoV1Response): IContactDto => ({
  id: "",
  companyId: vendor?.id,
  firstName: "",
  lastName: "",
  title: "",
  phoneNumber: "",
  faxNumber: "",
  cellPhoneNumber: "",
  emailAddress: "",
  note: "",
  isMainContact: false
});
export interface StaticContactFieldInputProps {
  editable?: boolean;
  value?: IContactFieldData | string | null;
  onDelete: (item: IContactFieldData) => void;
}
export const StaticContactFieldInput = memo(
  (props: StaticContactFieldInputProps) => {
    const { editable, value, onDelete } = props;

    const vendorDataOptions = useSelector(
      contactsSelectors.getVendorListSortedByCode
    );
    const selectedContact = useMemo(() => {
      if (typeof value === "string") {
        for (const vendor of vendorDataOptions) {
          const contact = vendor.contacts.find(x => x.id === value);

          if (!contact) {
            continue;
          }
          const contactDto = toContactFieldData(contact, vendor);

          return contactDto;
        }

        return;
      }

      return value;
    }, [value, vendorDataOptions]);

    const handleDelete = useCallback(() => {
      if (selectedContact) onDelete(selectedContact);
    }, [onDelete, selectedContact]);

    const fullname = formatFirstNameLastName(
      selectedContact?.firstName,
      selectedContact?.lastName
    );

    return (
      <Flex alignItems="center" justifyContent="flex-start">
        <UserAvatar
          name={fullname}
          id={selectedContact?.id ?? ""}
          size="57.5px"
        />
        <Flex
          flexDirection="column"
          marginLeft="12px"
          color="rgb(64, 64, 64)"
          style={{ width: "100%", zIndex: "999", lineHeight: "2rem" }}
        >
          <Flex fontSize="1.6rem" fontWeight={600}>
            <TextOverflow style={{ maxWidth: "80%", whiteSpace: "nowrap" }}>
              {fullname || "-"}
            </TextOverflow>
          </Flex>
          <Flex fontSize="1.3rem">
            <TextOverflow style={{ maxWidth: "80%", whiteSpace: "nowrap" }}>
              {selectedContact?.companyName ? selectedContact.companyName : ""}
            </TextOverflow>
          </Flex>
          {selectedContact?.email && (
            <Flex style={{ maxWidth: "80%" }}>
              <StyledIcon name="envelope" />
              <div style={{ overflow: "hidden" }}>
                <EmailField emailAddress={selectedContact.email} />
              </div>
            </Flex>
          )}
          {selectedContact?.phoneNumber && (
            <Flex style={{ maxWidth: "80%" }}>
              <div style={{ overflow: "hidden" }}>
                <PhoneNumber phoneNumber={selectedContact.phoneNumber} />
              </div>
            </Flex>
          )}
        </Flex>
        {editable && value && (
          <IconButton onClick={handleDelete}>
            <Cancel />
          </IconButton>
        )}
      </Flex>
    );
  }
);

interface MultiContactListProps {
  value?: IContactFieldData | IContactFieldData[] | string | null;
  editable?: boolean;
  onDelete: (item: IContactFieldData) => void;
}
const MultiContactList = memo((props: MultiContactListProps) => {
  const { editable, value, onDelete } = props;

  if (Array.isArray(value)) {
    if (value.length > 0) {
      return (
        <StaticInputContainer>
          {value?.map((selectedVendor: IContactFieldData) => (
            <StaticContactFieldInput
              key={selectedVendor.id}
              value={selectedVendor}
              editable={editable}
              onDelete={onDelete}
            />
          ))}
        </StaticInputContainer>
      );
    } else {
      return (
        <StaticContactFieldInput
          value={null}
          editable={editable}
          onDelete={onDelete}
        />
      );
    }
  }

  return (
    <StaticContactFieldInput
      value={value}
      editable={editable}
      onDelete={onDelete}
    />
  );
});

const ItemComponent = ({ item }: { item: IContactListOption }) =>
  item?.value ? <ContactOption {...item.value} /> : <div>{`${"(clear)"}`}</div>;

export interface MultiContactsFieldInputProps {
  value?: IContactFieldData | string | IContactFieldData[] | string[];
  onChange: (value?: IContactFieldData[]) => void;
  schemaField: SchemaField;
  editable?: boolean;
  allowCreate?: boolean;
  hasError?: boolean;
  keepInvalidValues?: boolean;
}
export const MultiContactsFieldInput = memo(
  (props: MultiContactsFieldInputProps) => {
    const {
      value,
      schemaField,
      editable,
      onChange,
      allowCreate,
      hasError,
      keepInvalidValues
    } = props;
    const [showModal, setShowModal] = useState(false);
    const vendorDataOptions = useSelector(
      contactsSelectors.getVendorListSortedByCode
    );
    const config = schemaField.config;
    const vendor = useGetSelectedVendor();

    const convertToContact = (val: string | IContactFieldData) => {
      if (typeof val !== "string") {
        return val;
      }

      const vendor = vendorDataOptions.find(v =>
        v.contacts.find(c => c.id === val)
      );

      if (vendor) {
        const contact = vendor.contacts.find(c => c.id === val);

        if (contact) {
          return toContactFieldData(contact, vendor);
        }
      }
    };

    const valueArray = useMemo(() => {
      if (!value) {
        return [];
      }

      if (Array.isArray(value)) {
        const contactsData = value
          .map(convertToContact)
          .filter(x => !!x) as IContactFieldData[];

        return [...contactsData];
      }

      const contact = convertToContact(value);
      return contact ? [contact] : [];
    }, [value, vendorDataOptions]);

    const filteredData: IContactListOption[] = useMemo(() => {
      let options: IContactListOption[] = [];
      vendorDataOptions.forEach(vendor => {
        const contacts = vendor.contacts.map(contact => {
          const fullname = formatFirstNameLastName(
            contact.firstName,
            contact.lastName
          );
          return {
            display: fullname,
            value: toContactFieldData(contact, vendor),
            id: contact.id
          };
        });
        options = options.concat(contacts);
      });

      if (config?.filterEnabled && config.filters?.length > 0) {
        options = options.filter(op => {
          if (!op.value?.products || op.value?.products.length === 0)
            return false;
          return (
            intersection(
              config.filters,
              op.value?.products.map((p: any) => p.productTypeId)
            ).length > 0
          );
        });
      }

      options = options.filter(option => {
        const validOption =
          option.display &&
          option.value?.firstName &&
          option.value?.lastName &&
          option.value?.companyCode;
        if (validOption) {
          return true;
        }
        return false;
      });

      options = sortBy(options, [
        c => c.value?.companyCode?.toLowerCase(),
        c => c.value?.firstName?.toLowerCase(),
        c => c.value?.lastName?.toLowerCase()
      ]);

      return options;
    }, [vendorDataOptions, config]);

    const data: IContactListOption[] = useMemo(() => {
      let options = filteredData;

      const existIds = valueArray.map(v => v.id);
      options = options.filter(op => {
        return !existIds.includes(op.id);
      });

      return options;
    }, [filteredData, valueArray]);

    useEffect(() => {
      if (keepInvalidValues || allowCreate || !valueArray) {
        return;
      }

      const filtered = valueArray.filter(v =>
        filteredData.some(d => d.id === v.id)
      );
      if (filtered.length != valueArray.length) onChange(filtered);
    }, [filteredData, allowCreate, valueArray, onChange, keepInvalidValues]);

    const [addValue, setAddValue] = useState<IContactListOption | null>();
    const handleAdd = useCallback(
      (option?: IContactListOption) => {
        const data = option?.value;
        if (data) {
          onChange([...valueArray, data]);
          setAddValue(null);
        }
      },
      [onChange, valueArray]
    );

    const addFieldValue = useCallback(() => {
      setShowModal(true);
    }, []);

    const setShowCreateContactModal = useCallback((showModal: boolean) => {
      setShowModal(showModal);
    }, []);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [confirmDeleteItem, setConfirmDeleteItem] = useState<
      IContactFieldData | undefined
    >();
    const openDeleteModal = useCallback(
      (item: IContactFieldData) => {
        setConfirmDeleteItem(item);
        setShowConfirmDeleteModal(true);
      },
      [setConfirmDeleteItem, setShowConfirmDeleteModal]
    );

    const handleDelete = useCallback(
      (id: string) => {
        onChange(valueArray.filter(v => v.id !== id));
      },
      [onChange, valueArray]
    );

    return (
      <>
        <ContactListModal
          editingContact={newContact(vendor)}
          vendor={vendor}
          selectNewContactCallback={handleAdd}
          setShowCreateContactModal={setShowCreateContactModal}
          showCreateContactModal={showModal}
        />
        {editable && (
          <MultiDropdownContainer>
            <MultiDividerLine />
            <ArrowDropdownContainer id="arrow-dropdown-container">
              <DropdownContainer hasErrors={hasError}>
                <DropdownList
                  filter={(option, search) =>
                    ContactDropdownFilter(option, search)
                  }
                  textField="display"
                  valueField="id"
                  value={addValue}
                  data={data}
                  onChange={handleAdd}
                  allowCreate={allowCreate}
                  onCreate={allowCreate ? addFieldValue : undefined}
                  id="arrow-dropdown"
                  messages={{
                    emptyList: allowCreate
                      ? ""
                      : strings.projects.setup.message.emptyContactList,
                    createOption: strings.projects.setup.message.createOption
                  }}
                  itemComponent={ItemComponent}
                />
              </DropdownContainer>
            </ArrowDropdownContainer>
          </MultiDropdownContainer>
        )}

        <MultiContactList
          editable={editable}
          value={valueArray}
          onDelete={openDeleteModal}
        />

        <ConfirmDelete
          show={showConfirmDeleteModal}
          handleClose={() => setShowConfirmDeleteModal(false)}
          handleDelete={() => {
            if (confirmDeleteItem?.id) handleDelete(confirmDeleteItem.id);
            setShowConfirmDeleteModal(false);
          }}
          title={schemaField.name}
        >
          <p>
            {strings.formatString(
              strings.core.modals.confirmDelete.message,
              formatFirstNameLastName(
                confirmDeleteItem?.firstName,
                confirmDeleteItem?.lastName
              )
            )}
          </p>
        </ConfirmDelete>
      </>
    );
  }
);

export interface SingleContactFieldInputProps {
  value?: IContactFieldData | string;
  onChange: (value?: IContactFieldData) => void;
  schemaField: SchemaField;
  editable?: boolean;
  allowCreate?: boolean;
  hasError?: boolean;
  keepInvalidValues?: boolean;
}
export const SingleContactFieldInput = memo(
  (props: SingleContactFieldInputProps) => {
    const {
      value,
      schemaField,
      editable,
      onChange,
      allowCreate,
      hasError,
      keepInvalidValues
    } = props;
    const [showModal, setShowModal] = useState(false);
    const vendorDataOptions = useSelector(
      contactsSelectors.getVendorListSortedByCode
    );
    const config = schemaField.config;
    const vendor = useGetSelectedVendor();
    const selectedContact = useMemo(() => {
      if (typeof value === "string") {
        for (const vendor of vendorDataOptions) {
          const contact = vendor.contacts.find(x => x.id === value);

          if (!contact) {
            continue;
          }
          const contactDto = toContactFieldData(contact, vendor);

          return contactDto;
        }

        return;
      }

      return value;
    }, [value, vendorDataOptions]);

    const data: IContactListOption[] = useMemo(() => {
      let options: IContactListOption[] = [];
      vendorDataOptions.forEach(vendor => {
        const contacts = vendor.contacts.map(contact => {
          const fullname = formatFirstNameLastName(
            contact.firstName,
            contact.lastName
          );
          return {
            display: fullname,
            value: toContactFieldData(contact, vendor),
            id: contact.id
          };
        });
        options = options.concat(contacts);
      });

      if (config?.filterEnabled && config.filters?.length > 0) {
        options = options.filter(op => {
          if (!op.value?.products || op.value?.products.length === 0)
            return false;
          return (
            intersection(
              config.filters,
              op.value?.products.map((p: any) => p.productTypeId)
            ).length > 0
          );
        });
      }

      options = options.filter(option => {
        const validOption =
          option.display &&
          option.value?.firstName &&
          option.value?.lastName &&
          option.value?.companyCode;
        if (validOption) {
          return true;
        }
        return false;
      });

      options = sortBy(options, [
        c => c.value?.companyCode?.toLowerCase(),
        c => c.value?.firstName?.toLowerCase(),
        c => c.value?.lastName?.toLowerCase()
      ]);

      if (selectedContact) {
        options.push({ id: "", display: "(clear)", value: null });
      }

      return options;
    }, [vendorDataOptions, config, selectedContact]);

    const handleChange = useCallback(
      (opt?: IContactListOption) => {
        const data = opt?.value;
        if (data) {
          onChange(data);
        } else {
          onChange();
        }
      },
      [onChange]
    );

    useEffect(() => {
      if (keepInvalidValues || allowCreate || !selectedContact) {
        return;
      }

      const existSelectedOptions = data.filter(
        d => d.id === selectedContact.id
      );

      if (!existSelectedOptions) {
        handleChange();
      }
    }, [data, allowCreate, selectedContact, handleChange, keepInvalidValues]);

    const addFieldValue = useCallback(() => {
      setShowModal(true);
    }, []);

    const setShowCreateContactModal = useCallback((showModal: boolean) => {
      setShowModal(showModal);
    }, []);
    const fullname = formatFirstNameLastName(
      selectedContact?.firstName,
      selectedContact?.lastName
    );

    return (
      <>
        <ContactListModal
          editingContact={newContact(vendor)}
          vendor={vendor}
          selectNewContactCallback={handleChange}
          setShowCreateContactModal={setShowCreateContactModal}
          showCreateContactModal={showModal}
        />
        <Flex alignItems="center" justifyContent="flex-start">
          <UserAvatar
            name={fullname}
            id={selectedContact?.id ?? ""}
            size="57.5px"
          />
          <Flex
            flexDirection="column"
            marginLeft="12px"
            color="rgb(64, 64, 64)"
            style={{ width: "100%", zIndex: "999", lineHeight: "2rem" }}
          >
            <Flex fontSize="1.6rem" fontWeight={600}>
              <TextOverflow style={{ maxWidth: "80%", whiteSpace: "nowrap" }}>
                {fullname ? fullname : "-"}
              </TextOverflow>
            </Flex>
            <Flex fontSize="1.3rem">
              <TextOverflow style={{ maxWidth: "80%", whiteSpace: "nowrap" }}>
                {selectedContact?.companyName
                  ? selectedContact.companyName
                  : ""}
              </TextOverflow>
            </Flex>
            {selectedContact?.email && (
              <Flex style={{ maxWidth: "80%" }}>
                <StyledIcon name="envelope" />
                <div style={{ overflow: "hidden" }}>
                  <EmailField emailAddress={selectedContact.email} />
                </div>
              </Flex>
            )}
            {selectedContact?.phoneNumber && (
              <Flex style={{ maxWidth: "80%" }}>
                <div style={{ overflow: "hidden" }}>
                  <PhoneNumber phoneNumber={selectedContact.phoneNumber} />
                </div>
              </Flex>
            )}
          </Flex>
          {editable && (
            <div id="arrow-dropdown-container">
              <DropdownContainer hasErrors={hasError}>
                <DropdownList
                  filter={(option, search) =>
                    ContactDropdownFilter(option, search)
                  }
                  textField="display"
                  valueField="id"
                  value={selectedContact}
                  data={data}
                  onChange={handleChange}
                  allowCreate={allowCreate}
                  onCreate={allowCreate ? addFieldValue : undefined}
                  id="arrow-dropdown"
                  messages={{
                    emptyList: allowCreate
                      ? ""
                      : strings.projects.setup.message.emptyContactList,
                    createOption: strings.projects.setup.message.createOption
                  }}
                  itemComponent={ItemComponent}
                />
              </DropdownContainer>
            </div>
          )}
        </Flex>
      </>
    );
  }
);

export const ContactInput = memo((props: SchemaFormFieldInputProps) => {
  const { form, field, schemaField, isLocked } = props;
  const permissions = usePermissions();
  const authorization = useAuthorization();
  const adminFailure = !permissions.admin && schemaField.config.adminOnly;
  const fieldIsEditable =
    permissions.write && authorization.canAccessLimitedPrecon && !adminFailure;
  const config = schemaField.config;

  const handleSingleChange = useCallback(
    (data?: IContactFieldData) => {
      form.setFieldValue(field.name, data);
    },
    [form.setFieldValue]
  );

  const handleMultiChange = useCallback(
    (data?: IContactFieldData[]) => {
      form.setFieldValue(field.name, data);
    },
    [form.setFieldValue]
  );

  if (config?.multiSelect) {
    return (
      <MultiContactsFieldInput
        schemaField={schemaField}
        value={field.value}
        allowCreate={permissions.contactWrite}
        onChange={handleMultiChange}
        hasError={!!(form.errors[field.name] && form.touched[field.name])}
        editable={fieldIsEditable && !isLocked}
        keepInvalidValues
        data-testid="multi-contact-field"
      />
    );
  } else {
    return (
      <SingleContactFieldInput
        schemaField={schemaField}
        value={field.value}
        allowCreate={permissions.contactWrite}
        onChange={handleSingleChange}
        hasError={!!(form.errors[field.name] && form.touched[field.name])}
        editable={fieldIsEditable && !isLocked}
        keepInvalidValues
        data-testid="single-contact-field"
      />
    );
  }
});

export const ContactOption = memo((props: IContactFieldData) => {
  const { companyCode, companyName, firstName, lastName, email, id } = props;
  const fullname = formatFirstNameLastName(firstName, lastName);
  const optionRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  if (!props.id) return <div>{`${"(clear)"}`}</div>;
  return (
    <OptionContainer
      ref={optionRef}
      onMouseEnter={() => {
        setShow(true);
      }}
      onMouseLeave={() => setShow(false)}
      onClick={e => {
        setShow(false);
      }}
    >
      <OptionHeader>
        <TextOverflow className="option-name">{fullname}</TextOverflow>
        <TextOverflow className="option-code">{companyCode}</TextOverflow>
      </OptionHeader>
      <Overlay
        rootClose
        show={show}
        placement="right"
        target={optionRef.current ?? undefined}
      >
        <ContactPopover
          name={fullname}
          email={email}
          id={id}
          companyName={companyName}
          setShow={setShow}
        />
      </Overlay>
    </OptionContainer>
  );
});

export const ContactDropdownFilter = (
  { value }: IContactListOption,
  search: string
) => {
  if (!value) return false;
  return (
    value.companyCode?.toLowerCase().includes(search.toLowerCase()) ||
    (value.companyName
      ? value.companyName.toLowerCase().includes(search.toLowerCase())
      : false) ||
    (value.firstName
      ? value.firstName.toLowerCase().includes(search.toLowerCase())
      : false) ||
    (value.lastName
      ? value.lastName.toLowerCase().includes(search.toLowerCase())
      : false)
  );
};
const DropdownContainer = styled.div<{ hasErrors?: boolean }>`
  & .rw-widget-input {
    height: 34px;
    ${props => (props.hasErrors ? "border: 1px solid #a94442;" : "")};
  }
}
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionHeader = styled.div`
  display: flex;

  .option-name {
    white-space: nowrap;
    flex: 2;
    font-size: 13px;
    font-weight: 700;
    margin-right: 3px;
  }

  .option-code {
    white-space: nowrap;
    flex: 1;
    font-size: 13px;
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: 1rem;
  font-size: 1.5rem;
  width: 1em;
`;

const MultiDropdownContainer = styled.div<{ hasErrors?: boolean }>`
  height: 40px;
  display: flex;
  ${props => (props.hasErrors ? "border: 1px solid #a94442;" : "")};
`;

const ArrowDropdownContainer = styled.div`
  flex: 1;
`;

const MultiDividerLine = styled.hr`
  flex: 1;
`;

const StaticInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
