import React, { ReactNode } from "react";
import styled from "styled-components";

export interface CalendarSubscriptionCardProps {
  templateName: string;
  OptionsButton: ReactNode;
  CardContent?: ReactNode;
}

export const CalendarSubscriptionCard: React.FC<CalendarSubscriptionCardProps> = ({
  templateName,
  OptionsButton,
  CardContent
}) => {
  return (
    <LinkContainer data-testid="calendar-card">
      <LinkHeader>
        <label title={templateName} className="template-wrap">
          {templateName}
        </label>
        {OptionsButton}
      </LinkHeader>
      {CardContent}
    </LinkContainer>
  );
};

const LinkContainer = styled.div`
  padding: 24px;
  margin: 20px 12px;
  background-color: #f6f6f6;
  border-radius: 12px;
`;

const LinkHeader = styled.div`
  .template-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
