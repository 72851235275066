import FullPageLoading from "modules/layout/components/FullPageLoading";
import React, { FC, lazy, Suspense } from "react";
import { shouldLoadDevTools } from "./util";

const DevToolsCore = lazy(() =>
  import(/* webpackChunkName: "dev-tools" */ "./dev-tools")
);

export const DevTools: FC = ({ children }) => {
  return shouldLoadDevTools() ? (
    <Suspense fallback={<FullPageLoading loading showTitle />}>
      <DevToolsCore>{children}</DevToolsCore>
    </Suspense>
  ) : (
    <>{children}</>
  );
};
