import { Button, Icon } from "hcss-components";
import * as React from "react";
import { Link } from "react-router-dom";
import { paths } from "../views/paths";
import { strings } from "localization";

// this is for creating an "empty" id new project (that is only used for front-end local) when clicking on the ADD PROJECT button
// on the projects table page. The reason we need this is because the field metadata (used for lockable field)
// required the project to have an ID to work properly

export const LOCAL_PROJECT_ID = "local-new-project";
export const AddProjectButton = () => {
  return (
    <Link to={paths.new}>
      <Button>
        <Icon name="plus" margin="right" /> {strings.projects.list.header.add}
      </Button>
    </Link>
  );
};
