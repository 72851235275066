import { call, put, select, takeEvery } from "redux-saga/effects";
import { getType, ActionType } from "typesafe-actions";
import * as notify from "../../../core/components/notify";
import { actions, StateSlice } from "../state";
import { strings } from "localization";
import { apiSagaFactory } from "api/api-saga-factory";
import { UserConfigDto as UserPreferences } from "api/GeneratedClients/precon";

const genericSagas = apiSagaFactory({
  apiPath: "/user",
  isBusinessUnitApi: true
});

function* updatePreferences(
  action: ActionType<typeof actions.updatePreferences.request>
) {
  const preferences: UserPreferences = yield select(
    ({ account }: StateSlice) => account.preferences
  );
  if (preferences) {
    try {
      const api = yield call(genericSagas.getApi);
      const data = yield call([api, api.update], action.payload);
      yield put(actions.updatePreferences.success(data.data));
      notify.save(strings.users.messages.saved.success);
    } catch (error) {
      console.error(error);
      yield put(actions.updatePreferences.failure(error));
      notify.error(strings.users.messages.saved.error);
    }
  }
}

function* setTutorialCompleted(
  action: ActionType<typeof actions.setTutorialCompleted>
) {
  const preferences = yield select(
    ({ account }: StateSlice) => account.preferences
  );
  if (preferences) {
    try {
      const api = yield call(genericSagas.getApi);
      yield call([api, api.update], {
        ...preferences,
        tutorials: {
          ...preferences.tutorials,
          [action.payload.tutorialId]: true
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
}

export const sagas = [
  takeEvery(getType(actions.updatePreferences.request), updatePreferences),
  takeEvery(getType(actions.setTutorialCompleted), setTutorialCompleted)
];
