import { Select } from "hcss-components";
import * as React from "react";
import {
  FilterOperation,
  SchemaFieldType,
  SchemaViewFilter
} from "../../../../api";
import {
  allFilterOperations,
  defaultFilterOperation,
  filterOptionLookup
} from "./common";

export interface FilterTypeSelectProps {
  type: SchemaFieldType;
  filter: SchemaViewFilter;
  onChange: (filterOperation: FilterOperation) => any;
}

export const FilterTypeSelect: React.FunctionComponent<FilterTypeSelectProps> = ({
  type,
  filter,
  onChange
}) => {
  const options = React.useMemo(
    () => allFilterOperations.filter(option => option.types.includes(type)),
    [type]
  );

  const value =
    filter.filterOperation || filter.filterOperation === 0
      ? filter.filterOperation
      : defaultFilterOperation[type];

  return (
    <div data-testid="select-filter">
      <Select
        value={filterOptionLookup[value]}
        onChange={({ value }: any) => {
          onChange(Number(value));
        }}
        options={options}
      />
    </div>
  );
};
