export const paths = {
  base: "/projects",
  calendar: "/projects/calendar",
  new: "/projects/create",
  duplicate: "/projects/copy/:id",
  detail: "/projects/:id",
  setup: "/projects/setup",
  setupHBIntegration: "/projects/setup/heavybid",
  subscriptionSetup: "/projects/setup/subscription",
  import: "/projects/import",
  importProjects: "/projects/import-projects",
  importBidResults: "/projects/import-bid-results",
  estimateMapping: "/projects/estimate-mapping",
  proposalCreate: "/projects/:id/proposals/create",
  proposal: "/projects/proposals/:proposalId",
  quickPrice: {
    payItemLibrary: "/projects/:id/quick-price/pay-item-library",
    estimateHistory: "/projects/:id/quick-price/estimate-history",
    payItemLibraryDetail:
      "/projects/:id/quick-price/pay-item-library/:quickPriceId",
    estimateHistoryDetail:
      "/projects/:id/quick-price/estimate-history/:quickPriceId"
  },
  payItemLibrary: "/projects/setup/pay-item-library"
};
