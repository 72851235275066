import { useLocalStorage } from "hcss-hooks";
import { filterConstants } from "modules/schemas/components/filters/common";
import moment from "moment";
import { calculateValuesFromRange } from "modules/schemas/components/filters/dateTimeUtils";
import { useSelectedBusinessUnitId } from "modules/account";

export interface CodebooksDateRange {
  range: string;
  from: string | undefined;
  to: string | undefined;
}

const defaultCodebooksDateRange: CodebooksDateRange = {
  range: filterConstants.dates.nowM6month,
  from: undefined,
  to: undefined
};

export const useCodebooksDateRange = () => {
  const businessUnitId = useSelectedBusinessUnitId();
  const [dateRange, setDateRange] = useLocalStorage<CodebooksDateRange>(
    `${businessUnitId}-codebooks-date-range`,
    defaultCodebooksDateRange
  );

  return { dateRange, setDateRange };
};

export const GetDateValues = (settings?: CodebooksDateRange) => {
  if (!settings) settings = defaultCodebooksDateRange;
  const values = {
    range: settings.range ? settings.range : undefined,
    from: settings.from ? moment(settings.from).toDate() : undefined,
    to: settings.to ? moment(settings.to).toDate() : undefined
  };

  const calcValuesFromRange = settings.range !== filterConstants.dates.custom;

  if (calcValuesFromRange && settings.range) {
    const calculatedValues = calculateValuesFromRange(settings.range);
    values.from = calculatedValues?.from.startOf("day").toDate();
    values.to = calculatedValues?.to.endOf("day").toDate();
  }

  return values;
};
