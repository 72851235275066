import React from "react";
import styled from "styled-components";
import { selectors as accountSelectors, useHcssUser } from "modules/account";
import { Dropdown } from "./dropdown";
import { DropdownMenu } from "./dropdown/dropdown-menu";
import { ConcreteColors } from "hcss-components";
import { DropdownButton } from "./dropdown/dropdown-button";
import { DropdownIcon } from "./dropdown/dropdown-icon";
import { useSelector } from "react-redux";
import { TextOverflow, UserType } from "core";
import { strings } from "localization";

export interface ProfileMenuProps {
  className?: string;
  /** MenuItem, Divider, or equivalent. */
  children: any;
  firstName: string;
  lastName?: string;
  /** Depending on product this could be business unit or company. */
  subtext: string;
  guest?: boolean;
}

export const ProfileMenu = ({
  firstName,
  lastName,
  subtext,
  children,
  guest
}: ProfileMenuProps) => {
  const selectedBU = useSelector(accountSelectors.getSelectedBusinessUnit);
  const user = useHcssUser();

  const showCurrentBU = !guest && user.type !== UserType.Subcontractor;

  const fullName =
    lastName !== undefined ? `${firstName} ${lastName[0]}.` : `${firstName}`;
  return (
    <Dropdown>
      <DropdownButton>
        <DropdownIcon className="fa fa-user-circle-o" />
      </DropdownButton>
      <ProfileDropdownMenu right data-testid="top-nav-profile">
        <ProfileMenuUserInfo className="profile-menu-userinfo">
          <div className="profile-menu-maintext">{fullName}</div>
          <div className="profile-menu-subtext">{subtext}</div>
          {showCurrentBU && (
            <div className="profile-menu-subtext">
              <div style={{ maxWidth: "200px" }}>
                <TextOverflow>
                  <b>{strings.layout.profile.currentBU}</b>: {selectedBU.code}
                </TextOverflow>
              </div>
            </div>
          )}
        </ProfileMenuUserInfo>
        {children}
      </ProfileDropdownMenu>
    </Dropdown>
  );
};

const ProfileMenuUserInfo = styled("div")`
  background: ${ConcreteColors.gray100};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 12px 12px;
  margin-bottom: 16px;
  line-height: normal;

  & > .profile-menu-maintext {
    width: 100px;
    overflow: hidden;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 2px;
  }

  & > .profile-menu-subtext {
    font-size: 12px;
    color: ${ConcreteColors.gray600};
    overflow: hidden;
    display: flex;
    margin-top: 6px;
  }
`;

const ProfileDropdownMenu = styled(DropdownMenu)`
  padding: 20px 16px 12px 16px;

  & .dropdown-menu-item > a {
    padding: 10px 0;
  }
`;
