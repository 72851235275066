import React from "react";
import { OAuthConfigurationPanel as HOAuthConfigurationPanel } from "hcss-outbox";
import { useSelector, useDispatch } from "react-redux";
import { notify } from "hcss-components";
import { OAuthInfoModel } from "hcss-outbox/dist/types/models";
import config from "config";
import { selectors, actions } from "../state";
import styled from "styled-components";
import { strings } from "localization";
import { useHcssUser } from "modules/account";

export const OAuthConfigurationPanel = () => {
  const dispatch = useDispatch();
  const isActive = useSelector(selectors.getEmailPanelActive);
  const onHide = () => dispatch(actions.toggleEmailPanel());
  const currentUser = useHcssUser();
  const handleUpdate = (settings: OAuthInfoModel) => {
    return;
  };

  const handleGetUserAuthInfoFailed = (error?: any) => {
    console.error(error);
    notify(
      "danger",
      strings.users.oAuthConfig.getUserError.title,
      strings.users.oAuthConfig.getUserError.message
    );
  };

  const handleSendTestMessageSuccess = () => {
    notify(
      "success",
      strings.users.oAuthConfig.testMessageSuccess.title,
      strings.formatString(
        strings.users.oAuthConfig.testMessageSuccess.message,
        currentUser.email
      ) as string
    );
  };

  const handleSendTestMessageFailure = (error?: any) => {
    console.error(error);
    notify(
      "danger",
      strings.users.oAuthConfig.testMessageError.title,
      strings.users.oAuthConfig.testMessageError.message
    );
  };

  if (!currentUser?.idsrvAccessToken) {
    return null;
  }

  return (
    <StyledOAuthConfigurationPanel>
      <HOAuthConfigurationPanel
        isActive={isActive}
        onHide={onHide}
        userId={currentUser.hcssUserId}
        baseApiUrl={window.location.origin}
        baseOutboxAuthUrl={config.endpoints.OUTBOXAUTH}
        onGetUserOAuthInfoFailed={handleGetUserAuthInfoFailed}
        onSendTestMessageSuccess={handleSendTestMessageSuccess}
        onSendTestMessageFailure={handleSendTestMessageFailure}
        onUpdate={handleUpdate}
        getAccessToken={() => currentUser.idsrvAccessToken!}
      />
    </StyledOAuthConfigurationPanel>
  );
};

const StyledOAuthConfigurationPanel = styled.div`
  & .modal-panel.email-settings-panel {
    z-index: 2002;
  }
`;
