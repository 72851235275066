import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where
} from "firebase/firestore";
import { firebaseApp } from "../../../firebase-app";
import { isUndefined, omitBy, omit } from "lodash-es";
import {
  MasterSolicitationEntity,
  VendorSolicitation,
  VendorSolicitationEntity
} from "core";

const db = getFirestore(firebaseApp);
const collectionId = "vendorSolicitations";

export const VendorSolicitationRepo = {
  generateId: () => {
    return doc(collection(db, collectionId)).id;
  },

  getById: async (id: string) => {
    const ref = doc(collection(db, collectionId), id);

    try {
      const snap = await getDoc(ref);
      const data = snap.data() as VendorSolicitation;
      const entity: VendorSolicitationEntity = { ...data, id: ref.id };
      return entity;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  },

  getAll: async (companyId: string, estimateId?: string) => {
    const solicitations: VendorSolicitationEntity[] = [];

    const whereClauses = [];
    whereClauses.push(where("senderCompanyId", "==", companyId));

    if (estimateId) {
      whereClauses.push(where("estimateId", "==", estimateId));
    }

    try {
      const q = query(collection(db, collectionId), ...whereClauses);
      const snap = await getDocs(q);
      for (const doc of snap.docs) {
        const data = doc.data() as VendorSolicitation;
        solicitations.push({ ...data, id: doc.id });
      }
    } catch (error) {
      console.error(error);
    }

    return solicitations;
  },

  getOutboundSubscription: (companyId: string, estimateId?: string) => {
    const whereClauses = [];
    whereClauses.push(where("senderCompanyId", "==", companyId));

    if (estimateId) {
      whereClauses.push(where("estimateId", "==", estimateId));
    }

    const q = query(collection(db, collectionId), ...whereClauses);
    return q;
  },

  getInboundSubscription: (companyId: string) => {
    const whereClauses = [];
    whereClauses.push(where("receiverCompanyId", "==", companyId));

    const q = query(collection(db, collectionId), ...whereClauses);
    return q;
  },

  save: async (
    solicitation: VendorSolicitationEntity | MasterSolicitationEntity
  ) => {
    const id = solicitation.id;
    const ref = doc(collection(db, collectionId), id);

    try {
      let doc = omitBy(solicitation, isUndefined);
      doc = omit(doc, ["id"]);
      await setDoc(ref, doc);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
};
