import React, { useEffect } from "react";
import { CalendarSubscribePanel } from "modules/calendars/components/CalendarSubscribePanel";
import { selectors as viewSelectors } from "modules/views";
import { useSelector, useDispatch } from "react-redux";
import * as calendarModule from "modules/calendars";

export const ProjectsCalendarSubscribeModal = () => {
  const dispatch = useDispatch();
  const views = useSelector(viewSelectors.getProjectViewsSorted);
  const isActive = useSelector(calendarModule.selectors.getCalendarPanelActive);

  useEffect(() => {
    if (isActive) {
      dispatch(calendarModule.actions.loadCalendars.request());
    }
  }, [dispatch, isActive]);

  return (
    <CalendarSubscribePanel
      isActive={isActive}
      onHide={() => dispatch(calendarModule.actions.toggleSideModal(false))}
      views={views}
    />
  );
};
