export const provincesEnum = {
  enum: [
    "AB",
    "BC",
    "MB",
    "NB",
    "NL",
    "NT",
    "NS",
    "NU",
    "ON",
    "PE",
    "QC",
    "SK",
    "YT"
  ],
  enumNames: [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon"
  ]
};

export const provincesObjectList = provincesEnum.enum.map((abbr, index) => {
  return { value: abbr, display: provincesEnum.enumNames[index] };
});

export default provincesEnum;
