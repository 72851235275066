import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { strings } from "localization";
import { useCodebooksContext } from "../../context/CodebooksContext";
import { UnusedActivity } from "../../state";
import {
  Section,
  SectionHeader,
  SectionTitle,
  unusedColumns,
  Timestamps
} from "./CodebooksShared";

import {
  Grid,
  Toolbar,
  SearchPanel,
  DragDropProvider
} from "@devexpress/dx-react-grid-bootstrap3";
import {
  IntegratedSorting,
  GroupingState,
  IntegratedGrouping,
  SearchState,
  AllRows,
  GroupKeys,
  ColumnReordering,
  ColumnResizing,
  ColumnVisibility,
  SortingState,
  TableGroupRow,
  TableHeaderRow,
  Root,
  OtherOptionsButton,
  TableContainer,
  TableWrapper
} from "hcss-tables";
import { Grid as MuiGrid } from "@mui/material";
import { ExtendedIntegratedFiltering } from "modules/tables/search";
import { PanelLoadingOverlay } from "core";
import ExportRows from "modules/tables/plugins/export-rows";
import { CustomColumnChooserModal } from "modules/tables/column-chooser-modal";
import { DateFormatProvider } from "modules/tables/columns/column-providers";
import { useLocalStorage } from "hcss-hooks";
import { SchemaFieldType, SchemaViewFilter } from "api";
import { selectors as schemaSelectors } from "modules/schemas";
import {
  FiltersNoView,
  FiltersNoViewProvider
} from "core/components/filters-noview";
import { get, isEqual } from "lodash-es";
import { filterField } from "modules/schemas/filters";
import { EmptyState } from "core/components/empty-state";
import { CodebooksIcon } from "./CodebooksIcon";
import { useSelectedBusinessUnitId } from "modules/account";
import LegacyVirtualTable from "core/components/bundle-fixes/LegacyVirtualTable";
import { LegacyGroupingPanel } from "core/components/bundle-fixes/LegacyGroupingPanel";

interface CodebooksUnusedPageProps {
  data: UnusedActivity[];
}

export const CodebooksUnusedPage = memo(
  ({ data }: CodebooksUnusedPageProps) => {
    const businessUnitId = useSelectedBusinessUnitId();
    const { unusedLoaded } = useCodebooksContext();
    const codebooksFields = useSelector(
      schemaSelectors.getActivityCodebooksSchemaFields
    );

    const [unusedTableData, setUnusedTableData] = useState<UnusedActivity[]>([
      ...data
    ]);
    const [filters, setFilters] = useLocalStorage<
      Record<string, SchemaViewFilter>
    >(`${businessUnitId}-codebooks-unused-active-filters`, {});

    useEffect(() => {
      const filterArray = Object.values(filters);

      const filteredUnused = Object.values(data).filter(activity => {
        const mappedActivity = {
          codebook: {
            ...activity
          }
        };

        for (const filter of filterArray) {
          const field = codebooksFields[filter.columnName];
          let value = get(mappedActivity, filter.columnName);

          if (field.type === SchemaFieldType.ShortText) {
            value = value.trim();
          }

          if (!filterField(field.type, filter, value)) {
            return false;
          }
        }
        return true;
      });

      if (!isEqual(filteredUnused, unusedTableData)) {
        setUnusedTableData(filteredUnused);
      }
    }, [filters, data, unusedTableData, codebooksFields]);

    const getPlaceholderText = () => {
      if (data.length <= 0)
        return strings.estimates.codebooks.unused.noUnusedCodes;
      return strings.estimates.codebooks.unused.noMatchingFilters;
    };

    if (!unusedLoaded) {
      return <PanelLoadingOverlay />;
    }

    return (
      <div data-testid="codebooks-unused-table">
        <Section>
          <SectionHeader>
            <MuiGrid
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <MuiGrid item>
                <SectionTitle>
                  {strings.estimates.codebooks.unused.pageTitle}
                </SectionTitle>
              </MuiGrid>
              <MuiGrid item>
                <Timestamps />
              </MuiGrid>
            </MuiGrid>
          </SectionHeader>
          <FiltersNoViewProvider filters={filters} setFilters={setFilters}>
            <FiltersNoView
              schemaId={"activityCodebooks"}
              selectedFields={[
                "codebook.code",
                "codebook.description",
                "codebook.level",
                "codebook.units",
                "codebook.lastUpdate"
              ]}
              pageId={`${businessUnitId}-codebooks-unused`}
            />
            {unusedTableData.length <= 0 ? (
              <EmptyState
                data-testid="codebooks-unused-no-data"
                title={getPlaceholderText()}
                text1=""
                mainIcon={
                  <div style={{ height: "180px", width: "180px" }}>
                    <CodebooksIcon />
                  </div>
                }
              />
            ) : (
              <CodebooksUnusedTable tableData={unusedTableData} />
            )}
          </FiltersNoViewProvider>
        </Section>
      </div>
    );
  }
);

interface CodebooksUnusedTableProps {
  tableData: UnusedActivity[];
}

const CodebooksUnusedTable = memo(
  ({ tableData }: CodebooksUnusedTableProps) => {
    return (
      <TableContainer
        templateDisplayName="CodebooksUnusedTable"
        templateName="Codebooks Unused Table"
        columns={unusedColumns}
      >
        <TableWrapper>
          <Grid rows={tableData} columns={unusedColumns} rootComponent={Root}>
            <DateFormatProvider />
            <SearchState />
            <DragDropProvider />
            <SortingState />
            <IntegratedSorting />
            <GroupingState />
            <IntegratedGrouping />
            <ExtendedIntegratedFiltering columns={unusedColumns} />
            <AllRows />
            <GroupKeys />
            <ExportRows
              templateDisplayName={strings.estimates.codebooks.unused.pageTitle}
            />
            <LegacyVirtualTable />
            <ColumnReordering />
            <ColumnVisibility />
            <ColumnResizing />
            <TableHeaderRow
              tableMenuMessages={{
                showHideColumns: strings.tables.rightClickColumn.showColumns
              }}
              headerMenuMessages={{
                hide: strings.tables.rightClickColumn.hide,
                group: strings.tables.rightClickColumn.group,
                highlight: strings.tables.rightClickColumn.highlight,
                sortAscending: strings.tables.rightClickColumn.sort.asc,
                sortDescending: strings.tables.rightClickColumn.sort.desc
              }}
              showSortingControls
            />
            <TableGroupRow />
            <Toolbar />
            <SearchPanel
              messages={{
                searchPlaceholder: strings.tables.search.placeholder
              }}
            />
            <LegacyGroupingPanel
              showGroupingControls
              messages={{ groupByColumn: strings.tables.group.placeholder }}
            />
            <OtherOptionsButton
              messages={{
                collapseAll: strings.tables.options.collapseAll,
                expandAll: strings.tables.options.expandAll,
                exportCsv: strings.tables.options.exportCSVFull,
                exportPdf: strings.tables.options.exportPDFFull,
                showHideColumns: strings.tables.options.showColumns
              }}
            />
          </Grid>
          <CustomColumnChooserModal />
        </TableWrapper>
      </TableContainer>
    );
  }
);
