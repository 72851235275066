import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { strings } from "localization";
import { useCodebooksContext } from "../../context/CodebooksContext";
import { MissingActivity } from "../../state";
import {
  Section,
  SectionHeader,
  SectionTitle,
  missingColumns,
  Timestamps
} from "./CodebooksShared";

import {
  Grid,
  Toolbar,
  SearchPanel,
  DragDropProvider
} from "@devexpress/dx-react-grid-bootstrap3";
import {
  IntegratedSorting,
  GroupingState,
  IntegratedGrouping,
  SearchState,
  AllRows,
  GroupKeys,
  ColumnReordering,
  ColumnResizing,
  ColumnVisibility,
  SortingState,
  TableGroupRow,
  TableHeaderRow,
  Root,
  OtherOptionsButton,
  TableContainer,
  TableWrapper
} from "hcss-tables";
import { ExtendedIntegratedFiltering } from "modules/tables/search";
import { PanelLoadingOverlay } from "core";
import ExportRows from "modules/tables/plugins/export-rows";
import { CustomColumnChooserModal } from "modules/tables/column-chooser-modal";
import { DateFormatProvider } from "modules/tables/columns/column-providers";
import { useLocalStorage } from "hcss-hooks";
import { SchemaFieldType, SchemaViewFilter } from "api";
import { selectors as schemaSelectors } from "modules/schemas";
import {
  FiltersNoView,
  FiltersNoViewProvider
} from "core/components/filters-noview";
import { get, isEqual } from "lodash-es";
import { filterField } from "modules/schemas/filters";
import { EmptyState } from "core/components/empty-state";
import { CodebooksIcon } from "./CodebooksIcon";
import { Grid as MuiGrid } from "@mui/material";
import { useSelectedBusinessUnitId } from "modules/account";
import LegacyVirtualTable from "core/components/bundle-fixes/LegacyVirtualTable";
import { LegacyGroupingPanel } from "core/components/bundle-fixes/LegacyGroupingPanel";

interface CodebooksMissingPageProps {
  data: MissingActivity[];
}

export const CodebooksMissingPage = memo(
  ({ data }: CodebooksMissingPageProps) => {
    const { missingLoaded } = useCodebooksContext();
    const codebooksFields = useSelector(
      schemaSelectors.getActivityCodebooksSchemaFields
    );
    const [missingTableData, setMissingTableData] = useState<MissingActivity[]>(
      [...data]
    );
    const businessUnitId = useSelectedBusinessUnitId();
    const [filters, setFilters] = useLocalStorage<
      Record<string, SchemaViewFilter>
    >(`${businessUnitId}-codebooks-missing-active-filters`, {});

    useEffect(() => {
      const filteredMissing = data.filter(activity => {
        const filterArray = Object.values(filters);
        //Map activity so that the field keys match the schema (i.e. activity.code)
        const mappedActivity = {
          activity: {
            ...activity
          }
        };

        for (const filter of filterArray) {
          const field = codebooksFields[filter.columnName];
          let value = get(mappedActivity, filter.columnName);

          if (field.type === SchemaFieldType.ShortText) {
            value = value.trim();
          }

          if (!filterField(field.type, filter, value)) {
            return false;
          }
        }
        return true;
      });

      if (!isEqual(filteredMissing, missingTableData)) {
        setMissingTableData(filteredMissing);
      }
    }, [filters, data, missingTableData, codebooksFields]);

    const getPlaceholderText = () => {
      if (data.length <= 0)
        return strings.estimates.codebooks.missing.noMissingActivities;
      return strings.estimates.codebooks.missing.noMatchingFilters;
    };

    if (!missingLoaded) {
      return <PanelLoadingOverlay />;
    }

    return (
      <div data-testid="codebooks-missing-table">
        <Section>
          <SectionHeader>
            <MuiGrid
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <MuiGrid item>
                <SectionTitle>
                  {strings.estimates.codebooks.missing.pageTitle}
                </SectionTitle>
              </MuiGrid>
              <MuiGrid item>
                <Timestamps />
              </MuiGrid>
            </MuiGrid>
          </SectionHeader>
          <FiltersNoViewProvider filters={filters} setFilters={setFilters}>
            <FiltersNoView
              schemaId={"activityCodebooks"}
              selectedFields={[
                "activity.code",
                "activity.description",
                "activity.estimate",
                "activity.biditem"
              ]}
              pageId={"codebooks-missing"}
            />
            {missingTableData.length <= 0 ? (
              <EmptyState
                data-testid="codebooks-missing-no-data"
                title={getPlaceholderText()}
                text1=""
                mainIcon={
                  <div style={{ height: "180px", width: "180px" }}>
                    <CodebooksIcon />
                  </div>
                }
              />
            ) : (
              <CodebooksMissingTable tableData={missingTableData} />
            )}
          </FiltersNoViewProvider>
        </Section>
      </div>
    );
  }
);

interface CodebooksMissingTableProps {
  tableData: MissingActivity[];
}

const CodebooksMissingTable = memo(
  ({ tableData }: CodebooksMissingTableProps) => {
    return (
      <TableContainer
        templateDisplayName="CodebooksMissingTable"
        templateName="Codebooks Missing Table"
        columns={missingColumns}
      >
        <TableWrapper>
          <Grid rows={tableData} columns={missingColumns} rootComponent={Root}>
            <DateFormatProvider />
            <SearchState />
            <DragDropProvider />
            <SortingState />
            <IntegratedSorting />
            <GroupingState />
            <IntegratedGrouping />
            <ExtendedIntegratedFiltering columns={missingColumns} />
            <AllRows />
            <GroupKeys />
            <ExportRows templateDisplayName="Activity Codes Missing" />
            <LegacyVirtualTable />
            <ColumnReordering />
            <ColumnVisibility />
            <ColumnResizing />
            <TableHeaderRow showSortingControls />
            <TableGroupRow />
            <Toolbar />
            <LegacyGroupingPanel showGroupingControls />
            <SearchPanel />
            <OtherOptionsButton
              messages={{
                collapseAll: strings.tables.options.collapseAll,
                expandAll: strings.tables.options.expandAll,
                exportCsv: strings.tables.options.exportCSVFull,
                exportPdf: strings.tables.options.exportPDFFull,
                showHideColumns: strings.tables.options.showColumns
              }}
            />
          </Grid>
          <CustomColumnChooserModal />
        </TableWrapper>
      </TableContainer>
    );
  }
);
