import axios, { AxiosInstance, AxiosPromise } from "axios";
import { ModelMappingService } from "core/services/model-mapping-service";
import { UsableAccount, HcssCalendarAccount } from "../models";
import { CreateAccountResponse } from "../models/CreateAccountResponse";
import { NylasAccount } from "../models/NylasAccount";
import { NylasCalendar } from "../models/NylasCalendar";

export class HcssConnectAccountService {
  instance: AxiosInstance;
  baseUrl: string;
  token: string;
  client: AxiosInstance;
  mapperService: ModelMappingService;

  constructor(baseUrl: string, token: string) {
    this.instance = axios.create();
    this.baseUrl = baseUrl;
    this.token = token;
    this.client = axios.create();
    this.mapperService = new ModelMappingService();

    this.client.interceptors.request.use(config => {
      config.headers = { "X-Kloudless-Raw-Data": true };
      config.headers.Authorization = `Bearer ${this.token}`;
      return config;
    });

    this.instance.interceptors.response.use(
      response => response,
      error => {
        console.error(error);
        if (error.response.status === 401) {
          window.location.reload();
        }

        return Promise.reject(error);
      }
    );
  }

  async getNylasAccounts(hcssCalendarAccounts: HcssCalendarAccount[]) {
    const promises = [] as AxiosPromise<NylasAccount>[];
    const validAccounts: UsableAccount[] = [];
    const inValidAccounts: UsableAccount[] = [];

    hcssCalendarAccounts.forEach(account => {
      promises.push(this.getNylasAccountDetails(account.id));
    });
    const responses = await Promise.allSettled(promises);

    for (let i = 0; i < responses.length; i++) {
      const response = responses[i];
      if (!response) continue;
      if (response.status === "rejected") {
        const nylasAccount = await this.getNylasAccountInfo(
          hcssCalendarAccounts[i].id
        );
        const invalidAccount = this.mapperService.mapNylasAccountToUsableAccount(
          nylasAccount.data,
          true
        );
        inValidAccounts.push(invalidAccount);
      } else {
        const validAccount = this.mapperService.mapNylasAccountToUsableAccount(
          response.value.data,
          false
        );
        validAccounts.push(validAccount);
      }
    }

    return validAccounts.concat(inValidAccounts);
  }

  async getNylasCalendarsForNylasAccounts(accounts: UsableAccount[]) {
    const promises: Promise<NylasCalendar[]>[] = [];
    let calendars: NylasCalendar[] = [];
    accounts.forEach(account => {
      promises.push(this.getNylasAccountCalendars(account.id));
    });
    const responses = await Promise.allSettled(promises);
    responses.forEach(response => {
      if (response.status === "fulfilled") {
        const cals = response.value;
        calendars = [...calendars, ...cals];
      }
    });
    return calendars;
  }

  async connectAccount(redirectUrl: string) {
    const response = await this.client.post<CreateAccountResponse>(
      `${this.baseUrl}/api/v2/accounts/`,
      { redirect: redirectUrl }
    );
    return response.data.connectionUrl;
  }

  private async getNylasAccountCalendars(accountId: string) {
    const response = await this.client.get<NylasCalendar[]>(
      `${this.baseUrl}/api/v2/nylas/accounts/${accountId}/calendars`
    );
    return response.data;
  }

  async getHcssCalendarAccounts() {
    return this.client.get<HcssCalendarAccount[]>(
      `${this.baseUrl}/api/v2/accounts`
    );
  }

  private getNylasAccountDetails(accountId: string) {
    return this.client.get<NylasAccount>(
      `${this.baseUrl}/api/v2/nylas/accounts/${accountId}/account`
    );
  }

  // this endpoint is used to get invalid accounts info. Since we do not store invalid account's email, we need to use nylas client credentials to get the info.
  // The reason we cannot use the getNylasAccountDetails method because it tries to use the account invalid token to get the data. Look into the kloudless repo for more info.
  private getNylasAccountInfo(accountId: string) {
    return this.client.get<NylasAccount>(
      `${this.baseUrl}/api/v2/applicationAccounts/${accountId}/`
    );
  }

  async disconnectNylasAccount(accountId: string) {
    return await this.client.delete(
      `${this.baseUrl}/api/v2/accounts/${accountId}`
    );
  }
}
