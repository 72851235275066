import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  query,
  where
} from "firebase/firestore";
import { firebaseApp } from "../../../firebase-app";
import { isUndefined, omitBy } from "lodash-es";
import { CommunicationLog, CommunicationLogEntity } from "core";

const db = getFirestore(firebaseApp);

export const CommunicationLogRepo = {
  getLogs: async (companyId: string, estimateId: string) => {
    const logs: CommunicationLogEntity[] = [];
    const q = query(
      collection(db, "communicationLogs"),
      where("companyId", "==", companyId),
      where("estimateId", "==", estimateId)
    );

    try {
      const snap = await getDocs(q);
      for (const doc of snap.docs) {
        const data = doc.data() as CommunicationLog;
        logs.push({ ...data, id: doc.id });
      }
    } catch (error) {
      console.error(error);
    }

    return logs;
  },

  getLogsForVendor: async (
    companyId: string,
    estimateId: string,
    vendorCode: string
  ) => {
    const logs: CommunicationLogEntity[] = [];

    const q = query(
      collection(db, "communicationLogs"),
      where("companyId", "==", companyId),
      where("estimateId", "==", estimateId),
      where("vendorCode", "==", vendorCode)
    );

    try {
      const snap = await getDocs(q);
      for (const doc of snap.docs) {
        const data = doc.data() as CommunicationLog;
        logs.push({ ...data, id: doc.id });
      }
    } catch (error) {
      console.error(error);
    }

    return logs;
  },

  saveLogRecord: async (record: CommunicationLog) => {
    const ref = collection(db, "communicationLogs");

    try {
      const doc = omitBy(record, isUndefined);
      await addDoc(ref, doc);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  subscribe: (companyId: string, estimateId?: string, projectId?: string) => {
    const whereClauses = [];
    whereClauses.push(where("companyId", "==", companyId));
    if (estimateId) {
      whereClauses.push(where("estimateId", "==", estimateId));
    }
    if (projectId) {
      whereClauses.push(where("projectId", "==", projectId));
    }

    const q = query(collection(db, "communicationLogs"), ...whereClauses);
    return q;
  }
};
