import React from "react";
import { copyToClipboard } from "core/util/clipboard";
import { formatPhoneNumber, TextOverflow } from "core";

interface PhoneFieldProps {
  phoneNumber: string;
}

export const PhoneField = ({ phoneNumber }: PhoneFieldProps) => {
  return (
    <div>
      <a
        href={`tel:${encodeURIComponent(phoneNumber)}`}
        onClick={() => copyToClipboard(phoneNumber)}
      >
        <TextOverflow textAlign="right" style={{ whiteSpace: "nowrap" }}>
          {formatPhoneNumber(phoneNumber)}
        </TextOverflow>
      </a>
    </div>
  );
};
