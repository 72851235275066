import {
  VirtualTable as DVirtualTable,
  VirtualTableProps
} from "@devexpress/dx-react-grid-bootstrap3";
import { VirtualTable as BundleVirtualTable } from "hcss-tables";
import * as React from "react";

const LegacyVirtualTable = (props: VirtualTableProps) => {
  return (
    //Fix for a breaking change in https://github.com/DevExpress/devextreme-reactive/blob/master/CHANGELOG.md#300-2022-01-31 regarding colspan calculation for stubcell
    <BundleVirtualTable stubCellComponent={DVirtualTable.StubCell} {...props} />
  );
};

export default LegacyVirtualTable;
