import { auth } from "./firebase-app";
import * as projects from "./modules/projects";
import * as estimates from "./modules/estimates";
import * as views from "./modules/views";
import * as schemas from "./modules/schemas";
import * as configurationSettings from "./modules/configurationSettings";
import * as signalR from "./modules/signalr";
import * as account from "./modules/account";
import * as profile from "./modules/profile";
import * as contacts from "./modules/contacts";
import * as payItemLibraries from "./modules/quick-pricing";
import { actions as calendarActions } from "./modules/calendars";
import React, { useEffect, useState } from "react";
import momentLocalizer from "react-widgets-moment";
import SiteLayout from "./modules/layout/components/site-layout";
import FullPageLoading from "./modules/layout/components/FullPageLoading";
import { useSelector, useDispatch } from "react-redux";
import { NotFound } from "core/components/not-found";
import { GenericError } from "core/components/generic-error";
import { createSelector } from "reselect";
import { Views as EstimatesViews } from "./modules/estimates/views";
import { Views as ProjectsViews } from "./modules/projects/views";
import { Views as ContactsViews } from "./modules/contacts/views";
import { Views as SettingsViews } from "./modules/settings/views";
import { Views as AccountViews, OidcRoutes } from "./modules/account/views";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { PrivateRoute } from "modules/account/components/private-route";
import { AuthenticationMethod, FeatureFlag, UserType } from "core";
import { AccountAuthorization } from "./modules/account";
import { useOidcContext } from "modules/account/services/oidc-context";
import { Unauthorized } from "modules/account/components/unauthorized";
import { AppProviders } from "./app-providers";
import { UnsupportedBrowserBanner } from "core/components/unsupported-browser-banner";
import { useHcssUser } from "modules/account";
import { getSiteBreakingErrors } from "modules/selectors";
import { isSandboxEnabled } from "core/util/sandbox";
import { initializePrintHelper } from "print-helper";
import { Notification } from "hcss-components";
import { useFeatureFlags } from "modules/configurationSettings/use-feature-flags";

export const localizer = momentLocalizer();
initializePrintHelper();

export const App = () => {
  const user = useHcssUser();
  if (user)
    document.title =
      user.type === UserType.Subcontractor || user.type === UserType.Guest
        ? `ProSubs Quoting`
        : `HeavyBid | Pre-Con`;

  return (
    <Switch>
      <Route path="/oidc" component={OidcRoutes} />
      <Route path="/account" component={AccountViews} />
      <Route
        path="/subsignup"
        render={() => <Redirect to="/account/landing" />}
      />
      <Route exact path="/error" component={GenericError} />
      <Route exact path="/notfound" component={NotFound} />
      <Route path="/" component={MainApp} />
    </Switch>
  );
};

interface StartupActionResults {
  appIsReady: boolean;
  isAuthenticated: boolean;
  hasErrors: boolean;
}

export const MainApp = () => {
  const { getAccount } = account.selectors;
  const { appIsReady, hasErrors } = useStartupActions();
  const { user, isAuthenticated, loading, authorization } = useSelector(
    getAccount
  );

  const isLoadingUser = () => {
    let isLoading = loading || !isAuthenticated || !user;

    if (!isSandboxEnabled()) {
      isLoading = isLoading || !auth.currentUser;
    }

    return isLoading;
  };

  // The order of the following statements matter

  // 1. The user is loaded & authenticated
  if (isLoadingUser() && !hasErrors) {
    return <FullPageLoading loading={true} showTitle />;
  }

  // 2. The user is authorized
  if (!isAuthorized(authorization, user?.type)) {
    return <Unauthorized />;
  }

  // 3. The app is initialized
  if (!appIsReady) {
    return <FullPageLoading loading={true} showTitle />;
  }

  // 4. Check for errors during load
  if (hasErrors) {
    return <Redirect to="/error" />;
  }

  // All Hcss Users should have authorization to at least one of these.
  const redirect =
    user?.type === UserType.HcssSubscription
      ? "/projects"
      : user?.type === UserType.Subcontractor
        ? "/quotes"
        : "/estimates";

  return (
    <AppProviders>
      <Notification />
      <SiteLayout>
        <UnsupportedBrowserBanner />
        <Switch>
          <PrivateRoute path="/estimates" component={EstimatesViews} />
          <PrivateRoute path="/projects" component={ProjectsViews} />
          <PrivateRoute path="/settings" component={SettingsViews} />
          <PrivateRoute path="/contacts" component={ContactsViews} />
          <Route path="/error" component={GenericError} />
          <Route exact path="/" component={() => <Redirect to={redirect} />} />
          <Route path="/" component={() => <Redirect to="/notfound" />} />
        </Switch>
      </SiteLayout>
    </AppProviders>
  );
};

const useStartupActions = (): StartupActionResults => {
  const getAccount = account.selectors.getAccount;
  const location = useLocation();
  const dispatch = useDispatch();
  const appIsLoaded = useSelector(getLoadedState);
  const errors = useSelector(getSiteBreakingErrors);
  const [hasPreFetched, setHasPreFetched] = useState(false);
  const { oidcService } = useOidcContext();
  const {
    isAuthenticated,
    authenticationMethod,
    authorization,
    user
  } = useSelector(getAccount);
  const { isFeatureEnabled: isCodebooksFeatureEnabled } = useFeatureFlags(
    "Codebooks"
  );
  const { isFeatureEnabled: isLinkToHeavyJobFeatureEnabled } = useFeatureFlags(
    FeatureFlag.LinkToHeavyJob
  );
  const { isFeatureEnabled: isQuickPricingEnabledPhase1 } = useFeatureFlags(
    FeatureFlag.QuickPricingPhase1
  );
  const enableQuickPricingPhase1 = (authorization.canAccessFullPrecon && isQuickPricingEnabledPhase1);
  const enableCodebooks =
    (authorization.canAccessFullPrecon ||
      authorization.canAccessFullReadonlyPrecon) &&
    isCodebooksFeatureEnabled;

  useEffect(() => {
    const events = oidcService.getEvents();

    events.addSilentRenewError(error => {
      if (authenticationMethod === AuthenticationMethod.OneClickLink) return;

      console.error("[OIDC Client] Renewal Event:", error);
      void oidcService.signinRedirect();
    });

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    events.addAccessTokenExpiring(async () => {
      if (authenticationMethod === AuthenticationMethod.OneClickLink) return;

      try {
        console.info("[OIDC Client] Starting Renewal");
        const user = await oidcService.signinSilent();
        dispatch(account.actions.updateOidcUser(user));
      } catch (error) {
        console.error("[OIDC Client] Renewal:", error);
        void oidcService.signinRedirect();
      }
    });

    return () => {
      events.removeAccessTokenExpiring(() => { });
      events.removeSilentRenewError(() => { });
    };
  }, [dispatch, oidcService, authenticationMethod]);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(account.actions.getUser.request(location.pathname));
    }
  }, [dispatch, isAuthenticated, location.pathname]);

  useEffect(() => {
    if (hasPreFetched && enableCodebooks) {
      dispatch(estimates.actions.loadHeavybidDivisions.request());
      dispatch(estimates.actions.loadLastProcessedActivityCodebooks.request());
      dispatch(estimates.actions.loadActivityCodebookMissing.request());
      dispatch(estimates.actions.loadActivityCodebookUnused.request());
      dispatch(estimates.actions.loadActivityCodebookUtilized.request());
    }
  }, [dispatch, hasPreFetched, enableCodebooks]);

  useEffect(() => {
    if (hasPreFetched && isLinkToHeavyJobFeatureEnabled) {
      dispatch(projects.actions.loadHJJobCostTypeCosts.request({}));
    }
  }, [dispatch, isLinkToHeavyJobFeatureEnabled, hasPreFetched]);

  useEffect(() => {
    if (hasPreFetched && enableQuickPricingPhase1) {
      dispatch(payItemLibraries.actions.loadPayItemLibraries.request());
    }
  }, [dispatch, enableQuickPricingPhase1, hasPreFetched]);

  useEffect(() => {
    if (
      !appIsLoaded &&
      !hasPreFetched &&
      isAuthenticated &&
      isAuthorized(authorization) &&
      authenticationMethod === AuthenticationMethod.HcssCredentials &&
      user?.type !== UserType.Guest
    ) {
      setHasPreFetched(true);
      if (
        user?.type === UserType.HcssSubscription ||
        user?.type === UserType.Subcontractor
      ) {
        dispatch(account.actions.subscribeToAuthState());
        dispatch(
          configurationSettings.actions.loadConfigurationSettings.request()
        );
        dispatch(schemas.actions.loadSchemas.request());
        dispatch(views.actions.loadViews.request());
        // As of the Activity Totals work, the estimates need to be
        // loaded after the schemas are loaded. See loadInitialEstimates in schemas/sagas
        // dispatch(estimates.actions.loadEstimates.request());
        dispatch(estimates.actions.loadQuoteFolders.request());
        dispatch(estimates.actions.loadEstimateFilters.request());
        dispatch(estimates.actions.loadUserDefinedLabels.request());
        dispatch(estimates.actions.loadHiddenUnlinkedEstimateIds.request());
        dispatch(projects.actions.loadProjects.request());
        dispatch(projects.actions.loadHJJobs.request());
        dispatch(contacts.actions.loadContactsState.request());
        if (!isSandboxEnabled()) {
          dispatch(signalR.actions.startSignalRConnection.request());
          dispatch(calendarActions.loadCalendars.request());
        }
      }
      dispatch(profile.actions.loadProfile.request());
      dispatch(estimates.actions.loadHeavybidDivisions.request());
    }
  }, [
    dispatch,
    appIsLoaded,
    isAuthenticated,
    hasPreFetched,
    authenticationMethod,
    authorization,
    user
  ]);

  const checkReadiness = () => {
    const cameFromEmail =
      authenticationMethod === AuthenticationMethod.OneClickLink;
    const subWithoutData =
      user?.type === UserType.Subcontractor &&
      !authorization.canAccessFullReadonlyPrecon;
    const canSkipLoading = cameFromEmail || subWithoutData;

    if (isAuthenticated && (appIsLoaded || canSkipLoading)) return true;
    return false;
  };

  const appIsReady = checkReadiness();
  return { appIsReady, isAuthenticated, hasErrors: errors.length > 0 };
};

const isAuthorized = (
  auth: AccountAuthorization,
  userType: number | undefined = 0
) => {
  return (
    auth.canAccessFullPrecon ||
    auth.canAccessFullReadonlyPrecon ||
    auth.canAccessLimitedPrecon ||
    userType === UserType.Subcontractor ||
    userType === UserType.Guest
  );
};

const getLoadedState = createSelector(
  [
    configurationSettings.selectors.getLoaded,
    projects.selectors.getLoaded,
    schemas.selectors.getLoaded,
    views.selectors.getLoaded
  ],
  (configLoaded, projectsLoaded, schemasLoaded, viewsLoaded) => {
    return configLoaded && projectsLoaded && schemasLoaded && viewsLoaded;
  }
);
