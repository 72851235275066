import { formatPercent } from "core/util/format";
import { numberCompare } from "hcss-tables";
import { strings } from "localization";

export const bidResultsSchema = () => ({
  id: "bidresults",
  name: strings.bidResults.title,
  schemaName: "bidresults",
  fields: {
    winner: {
      id: "winner",
      name: strings.bidResults.table.winner,
      type: 6,
      config: {},
      readOnly: false,
      required: true,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    name: {
      id: "name",
      name: strings.bidResults.table.companyName,
      type: 0,
      config: {},
      readOnly: false,
      required: true,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    amount: {
      id: "amount",
      name: strings.bidResults.table.amount,
      type: 8,
      config: {},
      readOnly: false,
      required: false,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    fromLowBid: {
      id: "fromLowBid",
      name: strings.bidResults.table.fromLowBid,
      type: 8,
      config: {},
      readOnly: true,
      required: false,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    leftOnTable: {
      id: "leftOnTable",
      name: strings.bidResults.table.leftOnTable,
      type: 8,
      config: {},
      readOnly: true,
      required: false,
      standard: true,
      protected: true,
      hiddenInTable: true,
      hiddenInForm: false,
      lookup: false
    },
    percentFromLowBid: {
      id: "percentFromLowBid",
      name: strings.bidResults.table.percentFromLowBid,
      type: 7,
      config: {
        showAsPercent: true
      },
      readOnly: true,
      required: false,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    }
  },
  sections: {
    "efac79ed-2b22-4381-897d-39bf0ef88d6f": {
      id: "efac79ed-2b22-4381-897d-39bf0ef88d6f",
      name: "Bid Results",
      fields: [
        "winner",
        "name",
        "amount",
        "fromLowBid",
        "leftOnTable",
        "percentFromLowBid"
      ]
    }
  },
  orderedSections: ["efac79ed-2b22-4381-897d-39bf0ef88d6f"]
});
