import { createBrowserHistory } from "history";
import { track } from "./usage-tracking";
import { isNullOrWhitespace } from "core";
import { isLocal } from "config";
export const history = createBrowserHistory();

const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
const firRegex = /^[0-9A-Za-z]{20}$/i;
// Listen for changes to the current location.
history.listen(location => {
  try {
    if (!isLocal) {
      // replace ids with placeholder
      const normalizedPath = normalizePath(location.pathname);
      track(`PAGE CHANGE - ${normalizedPath.toUpperCase()}`, {
        redux: false,
        router: true
      });
    }
  } catch (error) {
    console.error(error);
  }
});

function normalizePath(pathname: string) {
  const segments = pathname.split("/");
  return segments.reduce((prev, segment) => {
    if (isNullOrWhitespace(segment)) {
      return prev;
    }

    if (guidRegex.test(segment) || firRegex.test(segment)) {
      return `${prev}/:id`;
    }

    return `${prev}/${segment}`;
  }, "");
}
