import * as React from "react";
import { ReactourStep, ReactourStepContentArgs } from "reactour";
import { Tutorials } from "../../api";
import { strings } from "localization";
import { useNavigationControllerContext } from "../../core/components/hcss-navigation/contexts/navigation-controller-context";

export const tutorialSteps: Record<keyof Tutorials, ReactourStep[]> = {
  projectsList: [
    {
      content: strings.tutorials.projectTutorial.create,
      selector: '[data-testid="projects-header-new"]'
    },
    {
      content: strings.tutorials.projectTutorial.details,
      selector: "table:nth-child(2) tr td:nth-child(2)",
      position: "bottom"
    },

    {
      content: strings.tutorials.projectTutorial.rearrange,
      selector: "table:nth-child(1)"
    },

    {
      content: strings.tutorials.projectTutorial.group,
      selector: ".panel-heading div:nth-child(1)"
    },

    {
      content: props => (
        <UpdateStep {...props}>
          {strings.tutorials.projectTutorial.other}
        </UpdateStep>
      ),
      selector: '[data-testid="projectsmenu-options"] ul',
      action: node => {
        if (node.clientHeight === 0) {
          node.parentElement.firstChild.click();
        }
      }
    },

    {
      content: strings.tutorials.projectTutorial.save,
      selector: '[data-tut="templates"]'
    },

    {
      selector: '[data-testid="nav-projects"]',
      content: props => {
        return (
          <div>
            <div style={{ marginBottom: "24px" }}>
              <UpdateStep {...props}>
                {strings.tutorials.projectTutorial.customizable}
              </UpdateStep>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button className="btn btn-default" onClick={props.close}>
                {strings.tutorials.projectTutorial.complete}
              </button>
            </div>
          </div>
        );
      }
    }
  ]
};

export const tutorialStepsNoAddEdit: Record<keyof Tutorials, ReactourStep[]> = {
  projectsList: [
    {
      content:
        "Clicking on the project name allows you to view project details after it has already been created.",
      selector: "table:nth-child(2) tr td:nth-child(2)",
      position: "bottom"
    },
    {
      content:
        "Columns can be rearranged by clicking and dragging the column headers left or right.",
      selector: "table:nth-child(1)"
    },
    {
      content:
        "Click and drag the column header into this area to group results by that column.",
      selector: ".panel-heading div:nth-child(1)"
    },
    {
      content: props => (
        <UpdateStep {...props}>
          Other customizations and reports are available from the options menu
          or by right clicking a column header.
        </UpdateStep>
      ),
      selector: '[data-testid="projectsmenu-options"] ul',
      action: node => {
        if (node.clientHeight === 0) {
          node.parentElement.firstChild.click();
        }
      }
    },
    {
      selector: '[data-tut="templates"]',
      content: ({ close }) => {
        return (
          <div>
            <div style={{ marginBottom: "24px" }}>
              Once you have the table setup in the way you like, you can save it
              as a template. Use the options menu to save new templates, or to
              select a default one.
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button className="btn btn-default" onClick={close}>
                Complete Tutorial!
              </button>
            </div>
          </div>
        );
      }
    }
  ]
};

// this code is a super hack to get the mask to update whenever a dropdown is clicked
const UpdateStep: React.FunctionComponent<ReactourStepContentArgs> = ({
  step,
  goTo,
  children
}) => {
  const controller = useNavigationControllerContext();
  const { setFlyoutIsOpen } = controller;
  const [prevRendered, setPrevRendered] = React.useState(false);

  if (step === 7) {
    setFlyoutIsOpen(true);
  } else {
    setFlyoutIsOpen(false);
  }

  React.useLayoutEffect(() => {
    if (!prevRendered) {
      goTo(step - 2);
      goTo(step - 1);
      setPrevRendered(true);
    }
  }, [goTo, step, prevRendered]);
  React.useLayoutEffect(() => {
    if (prevRendered) {
      goTo(step - 1);
    }
  }, [goTo, step, prevRendered]);
  return <span>{children}</span>;
};
