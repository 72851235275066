import { sortVendorLocations } from "./../components/vendor-edit-details";
import { IVendorLocationDto } from "api/contacts-api-dto-interfaces";
import { IVendorDtoV1Response } from "api/GeneratedClients/ContactsClient";
import { isNullOrWhitespace } from "core";
import { strings } from "localization";
import { sortBy } from "lodash-es";
import { useEffect, useState } from "react";

export interface VendorLocationOption {
  display: string;
  value: IVendorLocationDto;
  id: string;
}
export function useVendorLocationDropDown(
  vendorParam: IVendorDtoV1Response | undefined
) {
  const [localVendor, setLocalVendor] = useState<
    IVendorDtoV1Response | undefined
  >(vendorParam);
  const [vendorLocation, setVendorLocation] = useState<IVendorLocationDto>();
  const [editingLocationId, setEditingLocationId] = useState<string>();
  const [isVendorLocationFormValid, setIsVendorLocationFormValid] = useState<
    boolean
  >(true);
  const [locationOptions, setLocationOptions] = useState<
    VendorLocationOption[]
  >();

  useEffect(() => {
    setLocalVendor(vendorParam);
  }, [vendorParam]);

  useEffect(() => {
    if (!localVendor?.locations) return;
    const options = createVendorLocationDropdownOptions(localVendor.locations);
    setLocationOptions(options);
  }, [localVendor, vendorLocation]);

  useEffect(() => {
    if (!localVendor?.locations) return;
    const vendorLocation = localVendor.locations.find(
      location => location.id === editingLocationId
    );
    setVendorLocation(vendorLocation);
  }, [localVendor, editingLocationId]);

  useEffect(() => {
    if (!localVendor) return;
    if (!localVendor.locations) return;
    if (
      vendorLocation === undefined &&
      localVendor.locations.length > 0 &&
      editingLocationId === undefined
    ) {
      const location = getFirstLocation(localVendor.locations);
      setEditingLocationId(location?.id);
      setVendorLocation(location);
    }
  }, [vendorLocation, localVendor, editingLocationId]);

  return {
    localVendor,
    vendorLocation,
    editingLocationId,
    isVendorLocationFormValid,
    locationOptions,
    setLocalVendor,
    setVendorLocation,
    setEditingLocationId,
    setIsVendorLocationFormValid,
    setLocationOptions
  };
}

const createVendorLocationDropdownOptions = (
  vendorLocations: IVendorLocationDto[] | undefined
): VendorLocationOption[] | undefined => {
  if (!vendorLocations) return;
  const filteredNullNickname = vendorLocations.filter(
    loc => !isNullOrWhitespace(loc.nickname)
  );
  let options: VendorLocationOption[] | undefined = filteredNullNickname?.map(
    location => {
      if (location.isPrimary) {
        return {
          display:
            location.nickname +
            " " +
            strings.contactManagement.vendorDetails.HBPrimaryOffice,
          value: location,
          id: location.id
        };
      }
      if (location.isAlternate) {
        return {
          display:
            location.nickname +
            " " +
            strings.contactManagement.vendorDetails.HBAlternateOffice,
          value: location,
          id: location.id
        };
      }
      return {
        display: location.nickname,
        value: location,
        id: location.id
      };
    }
  );
  options = sortBy(options, [
    function (option) {
      return option.display.toLowerCase();
    }
  ]);
  return options;
};

const getFirstLocation = (
  locations: IVendorLocationDto[]
): IVendorLocationDto | undefined => {
  const sortedLocations = sortVendorLocations(locations);
  if (!sortedLocations) return;
  const notNullLocation = sortedLocations.find(
    loc => !isNullOrWhitespace(loc.nickname)
  );
  return notNullLocation ? notNullLocation : locations[0];
};
