import {
  DataTypeProvider,
  IntegratedSelection,
  TableColumnWidthInfo
} from "@devexpress/dx-react-grid";
import {
  Grid,
  SearchPanel,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection,
  Toolbar
} from "@devexpress/dx-react-grid-bootstrap3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BusinessUnit } from "api/GeneratedClients/precon";
import { isNullOrWhitespace } from "core";
import { ConfirmSubmitModal } from "core/components/modals/confirm-submit";
import { useLocalStorage } from "hcss-hooks";
import {
  BooleanFormatProvider,
  getRowId,
  IntegratedFiltering,
  Root,
  SearchState,
  SelectionState,
  SortingState,
  TableContainer,
  TableWrapper,
  // TableHeaderRow,
  IntegratedSorting
} from "hcss-tables";
import { selectors as accountSelectors } from "modules/account";
import { StyledSelectionRow } from "modules/tables/selection-table";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ModalPanel } from "../../../core/components/modals/SideModal";
import { actions, selectors } from "../state";
import { businessUnitColumns } from "./BusinessUnitColumns";

export const BusinessUnitPanel = () => {
  const dispatch = useDispatch();
  const isActive = useSelector(selectors.getBUPanelActive);
  const BUs = useSelector(accountSelectors.getAccessibleBusinessUnits);
  const homeBU = useSelector(accountSelectors.getHomeBusinessUnit);
  const selectedBU = useSelector(accountSelectors.getSelectedBusinessUnit);
  const hcssUser = useSelector(accountSelectors.getHcssUser);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [chosenRowId, setChosenRowId] = useState<string | number>("");

  const [_, setLocalStorageBU] = useLocalStorage<{
    id: string | undefined;
    code: string | undefined;
  }>(`user.business.unit-${hcssUser?.hcssUserId}`, homeBU);

  const businessUnits = useMemo(() => {
    return (
      BUs?.map(bu => {
        if (isNullOrWhitespace(selectedBU.id) && bu.code === "MANAGER")
          return { ...bu, selected: true };

        if (bu.id === selectedBU.id) return { ...bu, selected: true };

        return { ...bu, selected: false };
      }).map(bu => {
        if (bu.id === homeBU.id) return { ...bu, home: true };
        return { ...bu, home: false };
      }) ?? []
    );
  }, [BUs, homeBU.id, selectedBU.id]);

  const onHide = () => {
    setShowModal(false);
    dispatch(actions.toggleBUPanel());
  };

  const handleSelection = (change: (string | number)[]) => {
    const selectedRowId = change[change.length - 1];
    if (change.length > 0) {
      setChosenRowId(selectedRowId);
      setShowModal(true);
    }
  };

  const handleConfirm = () => {
    const selectedBU = businessUnits.find(bu => bu.id === chosenRowId);
    const businessUnit = {
      id: selectedBU?.id ?? "",
      code: selectedBU?.code ?? ""
    };
    setLocalStorageBU(businessUnit);
    setShowModal(false);
    window.location.reload();
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div>
      <StyledPanel
        className="business-unit-panel"
        isActive={isActive}
        onHide={onHide}
      >
        <ModalPanel.Header onHide={onHide} panelTitle={<ModalHeader />} />
        <ModalPanel.Body>
          <BUTable
            businessUnits={businessUnits}
            selection={[selectedBU.id ?? ""]}
            handleSelection={handleSelection}
          />
        </ModalPanel.Body>
      </StyledPanel>
      <ConfirmSubmitModal
        show={showModal}
        title={"Reload site?"}
        handleConfirm={handleConfirm}
        handleClose={handleClose}
      >
        The site will reload with your newly selected business unit. Any unsaved
        work will be lost.
      </ConfirmSubmitModal>
    </div>
  );
};

const ModalHeader = () => {
  return <div>Change Business Unit</div>;
};

interface BUTableProps {
  businessUnits: BusinessUnit[] | undefined;
  selection: (string | number)[] | undefined;
  handleSelection: (change: (string | number)[]) => void;
}

const BUTable = ({
  businessUnits,
  selection,
  handleSelection
}: BUTableProps) => {
  const [columnWidths, setColumnWidths] = useState<TableColumnWidthInfo[]>(
    defaultWidths
  );

  return (
    <div>
      <TableContainer
        columns={businessUnitColumns}
        sections={[]}
        templateName="business.unit.table"
      >
        <TableWrapper
          height="100vh"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <Grid
            rootComponent={Root}
            getRowId={getRowId}
            rows={businessUnits ?? []}
            columns={businessUnitColumns}
          >
            <SortingState />
            <SearchState />
            <SelectionState
              selection={selection}
              onSelectionChange={handleSelection}
            />

            <IntegratedSelection />
            <IntegratedFiltering />
            <IntegratedSorting />

            <BooleanFormatProvider />
            <HomeBUProvider />

            <Table />
            <TableColumnResizing
              columnWidths={columnWidths}
              onColumnWidthsChange={setColumnWidths}
            />
            <TableHeaderRow showSortingControls />
            <TableSelection
              highlightRow
              selectByRowClick
              showSelectionColumn={false}
              //@ts-ignore
              rowComponent={StyledSelectionRow}
            />
            <Toolbar />
            <SearchPanel />
          </Grid>
        </TableWrapper>
      </TableContainer>
    </div>
  );
};

const HomeBUFormatter = ({ value }: { value: any }) => {
  return (
    <div style={{ display: "flex" }}>
      {value.code}
      <div style={{ width: "10%", marginLeft: "12px" }}>
        {value.home && <FontAwesomeIcon icon={["far", "home"]} size="sm" />}
      </div>
    </div>
  );
};

const HomeBUProvider = (props: any) => (
  <DataTypeProvider
    formatterComponent={HomeBUFormatter}
    for={["code"]}
    {...props}
  />
);

const defaultWidths = [
  { columnName: "code", width: 150 },
  { columnName: "description", width: 315 }
];

const StyledPanel = styled(ModalPanel)`
  z-index: 2002;

  .modal-panel-content {
    .modal-panel-body {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      overflow: hidden;
      padding: 0;

      .panel-instructions {
        position: relative;
        margin: 10px 0;
        padding: 0 20px;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 22px;
        color: #1e1e1e;
      }
    }
  }
`;
