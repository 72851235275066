import { getType } from "typesafe-actions";
import { takeEvery, takeLatest } from "redux-saga/effects";
import { actions } from "../state";
import { signOut, signInWithHcss, signInWithEmailLink } from "./auth-signin";
import { startAuthListener, updateOidcUserState } from "./auth-listeners";
import { getUser } from "./auth-user";
import { declineInivitation } from "./auth-decline";

export const sagas = [
  takeLatest(getType(actions.signOut), signOut),
  takeLatest(getType(actions.subscribeToAuthState), startAuthListener),
  takeLatest(getType(actions.getUser.request), getUser),
  takeLatest(getType(actions.updateOidcUser), updateOidcUserState),
  takeEvery(getType(actions.declineInvitation.request), declineInivitation),
  takeEvery(getType(actions.signInWithHcssCredentials.request), signInWithHcss),
  takeEvery(getType(actions.signInWithEmailLink.request), signInWithEmailLink)
];
