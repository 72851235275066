import { View } from "api";

// TODO: Update this

export const keyIndicators: View = {
  schemaId: "estimatesextended",
  filters: {
    "values.isActiveEstimate": {
      columnName: "values.isActiveEstimate",
      filterOperation: 10,
      value: false
    }
  },
  grouping: [],
  columnSummaries: [],
  hiddenColumns: [
    "values.description",
    "values.isActiveEstimate",
    "values.division",
    "values.filters.projectName",
    "values.filters.projectNumber",
    "values.filters.engineer",
    "values.filters.startDate",
    "values.filters.reviewDate",
    "values.filters.createdDate",
    "values.filters.takeoffAmount",
    "values.filters.profitBidquan",
    "values.filters.profitTakeoff",
    "values.filters.estimateTag1",
    "values.filters.estimateTag2",
    "values.filters.estimateTag3",
    "values.filters.estimateTag4",
    "values.filters.estimateTag5",
    "values.filters.estimateTag6",
    "values.filters.userDefinedText1",
    "values.filters.userDefinedText2",
    "values.filters.userDefinedText3",
    "values.filters.userDefinedText4",
    "values.filters.userDefinedText5",
    "values.filters.userDefinedText6",
    "values.filters.userDefinedText7",
    "values.filters.userDefinedText8",
    "values.filters.userDefinedText9",
    "values.filters.userDefinedText10",
    "values.filters.userDefinedText11",
    "values.filters.userDefinedText12",
    "values.filters.userDefinedText13",
    "values.filters.userDefinedText14",
    "values.filters.userDefinedText15",
    "values.filters.userDefinedText16",
    "values.filters.userDefinedText17",
    "values.filters.userDefinedText18",
    "values.filters.userDefinedText19",
    "values.filters.userDefinedText20",
    "values.filters.userDefinedInteger1",
    "values.filters.userDefinedInteger2",
    "values.filters.userDefinedInteger3",
    "values.filters.userDefinedInteger4",
    "values.filters.userDefinedInteger5",
    "values.filters.userDefinedNumber1",
    "values.filters.userDefinedNumber2",
    "values.filters.userDefinedNumber3",
    "values.filters.userDefinedNumber4",
    "values.filters.userDefinedNumber5",
    "values.filters.userDefinedNumber6",
    "values.filters.userDefinedNumber7",
    "values.filters.userDefinedNumber8",
    "values.filters.userDefinedNumber9",
    "values.filters.userDefinedNumber10",
    "values.filters.userDefinedNumber11",
    "values.filters.userDefinedNumber12",
    "values.filters.userDefinedNumber13",
    "values.filters.userDefinedNumber14",
    "values.filters.userDefinedNumber15",
    "values.filters.userDefinedNumber16",
    "values.filters.userDefinedNumber17",
    "values.filters.userDefinedNumber18",
    "values.filters.userDefinedNumber19",
    "values.filters.userDefinedNumber20",
    "values.filters.userDefinedDate1",
    "values.filters.userDefinedDate2",
    "values.filters.userDefinedDate3",
    "values.filters.userDefinedDate4",
    "values.filters.userDefinedDate5",
    "values.filters.address",
    "values.filters.latitude",
    "values.filters.longitude",
    "values.totals.baseLabor_Direct",
    "values.totals.baseLabor_Indirect",
    "values.totals.baseLabor_Total",
    "values.totals.burden_Direct",
    "values.totals.burden_Indirect",
    "values.totals.burden_Total",
    "values.totals.totalLabor_Direct",
    "values.totals.totalLabor_Indirect",
    "values.totals.totalLabor_Total",
    "values.totals.permanentMaterial_Direct",
    "values.totals.permanentMaterial_Indirect",
    "values.totals.permanentMaterial_Total",
    "values.totals.constructionMaterial_Direct",
    "values.totals.constructionMaterial_Indirect",
    "values.totals.constructionMaterial_Total",
    "values.totals.subcontract_Direct",
    "values.totals.subcontract_Indirect",
    "values.totals.subcontract_Total",
    "values.totals.equipmentOperatingExpense_Direct",
    "values.totals.equipmentOperatingExpense_Indirect",
    "values.totals.equipmentOperatingExpense_Total",
    "values.totals.companyEquipment_Direct",
    "values.totals.companyEquipment_Indirect",
    "values.totals.companyEquipment_Total",
    "values.totals.rentedEquipment_Direct",
    "values.totals.rentedEquipment_Indirect",
    "values.totals.rentedEquipment_Total",
    "values.totals.misc1_Direct",
    "values.totals.misc1_Indirect",
    "values.totals.misc1_Total",
    "values.totals.misc2_Direct",
    "values.totals.misc2_Indirect",
    "values.totals.misc2_Total",
    "values.totals.misc3_Direct",
    "values.totals.misc3_Indirect",
    "values.totals.misc3_Total",
    "values.totals.totalEntryCost_Takeoff_Direct",
    "values.totals.totalEntryCost_Takeoff_Indirect",
    "values.totals.totalEntryCost_Takeoff_Total",
    "values.totals.totalEntryCost_Bid_Direct",
    "values.totals.totalEntryCost_Bid_Indirect",
    "values.totals.totalEntryCost_Bid_Total",
    "values.totals.balMarkup_Bid",
    "values.totals.actualMarkup_Bid",
    "values.totals.balancedBid_Bid",
    "values.totals.bidTotal_Bid",
    "values.totals.addonBondTotal",
    "values.totals.totalEqp_Direct",
    "values.totals.totalEqp_Indirect",
    "values.totals.totalEqp_Total",
    "values.totals.manhours_Direct",
    "values.totals.manhours_Indirect",
    "values.totals.manhours_Total",
    "values.totals.addon_Cost",
    "values.totals.bond",
    "values.totals.addon_Cost",
    "values.totals.addon_Markup",
    "values.totals.addon_Total",
    "values.totals.totalCost_Takeoff",
    "values.totals.job_Duration",
    "values.totals.balMarkup_Takeoff",
    "values.totals.actualMarkup_Takeoff",
    "values.totals.balancedBid_Takeoff",
    "values.totals.passThrough_Cost",
    "values.totals.passThrough_Adjustment",
    "values.totals.passThrough_Total",
    "values.totals.totalCost_Bid_LessPassThrough",
    "values.totals.totalCost_Bid",
    "values.totals.total_User_Adjustments",
    "values.totals.total_User_Adjustments_Cost",
    "values.totals.desired_Bid",
    "values.totals.desired_Bid_Diff",
    "values.totals.bidTotal_Takeoff",
    "values.totals.totalCost_Takeoff_LessPassThrough",
    "values.totals.markupPercentCost_Takeoff",
    "values.totals.markupPercentCost_Bid",
    "values.totals.markupPercentSales_Takeoff",
    "values.totals.markupPercentSales_Bid",
    "values.totals.totalCost_Takeoff_LessPassThroughAndAdjustments",
    "values.totals.totalCost_Bid_LessPassThroughAndAdjustments",
    "values.totals.totalEntryCost_Bid_Spread_Indirect",
    "values.totals.addonBond_Cost_Bid_Spread_Total",
    "values.totals.totalCost_Bid_Spread",
    "values.totals.balMarkup_Bid_Spread",
    "values.totals.actualMarkup_Bid_Spread",
    "values.totals.actv_User_Quan1",
    "values.totals.actv_User_Quan2",
    "values.totals.actv_User_Quan3",
    "values.totals.actv_User_Quan4",
    "values.totals.open_Biditems",
    "values.totals.minority_Goal_Percent",
    "values.totals.minority_Goal_Total",
    "values.totals.minority_Current_Percent",
    "values.totals.minority_Current_Total"
  ],
  id: "keyindicators",
  name: "Key Indicators",
  ordering: [
    "values.code",
    "values.name",
    "values.lastModified",
    "values.filters.bidDate",
    "values.filters.typeOfWork",
    "values.filters.estimator",
    "values.totals.totalCost_Takeoff",
    "values.totals.bidTotal_Bid",
    "values.filters.status",
    "values.filters.owner",
    "values.filters.state"
  ],
  displayedColumns: [
    "values.code",
    "values.name",
    "values.lastModified",
    "values.filters.bidDate",
    "values.filters.typeOfWork",
    "values.filters.estimator",
    "values.totals.totalCost_Takeoff",
    "values.totals.bidTotal_Bid",
    "values.filters.status",
    "values.filters.owner",
    "values.filters.state"
  ],
  sorting: [
    {
      columnName: "values.lastModified",
      direction: "desc"
    }
  ],
  chartConfigs: [],
  chartConfig: {},
  calendarConfig: {}
};
