import React from "react";
import styled from "styled-components";

interface BadgeProps {
  type: "success" | "danger" | "warning" | "info";
  children?: React.ReactChild;
  style?: React.CSSProperties;
}
export const Badge = ({ children, type, style }: BadgeProps) => {
  return (
    <ContentWrapper style={style} className={`badge badge-pill badge-${type}`}>
      {children}
    </ContentWrapper>
  );
};

const ContentWrapper = styled.span`
  &.badge-info {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }

  &.badge-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &.badge-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  &.badge-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
`;
