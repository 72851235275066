import { getAuth } from "firebase/auth";
import { ActionType } from "typesafe-actions";
import { AxiosResponse } from "axios";
import { actions } from "../state";
import { call, put } from "redux-saga/effects";
import { languages, regions, switchLanguage, switchRegion } from "localization";
import { getUserClaimsFromProfile } from "core";
import { isSandboxEnabled } from "core/util/sandbox";
import { accountService } from "../services/account-service";
import {
  CredentialsUserDetailsDto,
  UserDto
} from "api/GeneratedClients/precon";
import { actions as schemaActions } from "modules/schemas";
import { oidcService } from "../services/oidc-service";

import {
  createGuestUser,
  createGuestUserPayload,
  signinWithFirebase,
  getUserInfo,
  createHcssUser,
  createHcssUserPayload
} from "./helpers";

export function* signInWithEmailLink(
  action: ActionType<typeof actions.signInWithEmailLink.request>
) {
  try {
    if (action.payload.mode) {
      const checkExistingResponse: AxiosResponse<CredentialsUserDetailsDto> = yield call(
        [accountService, accountService.checkExistingUser],
        action.payload.id,
        action.payload.email,
        action.payload.token
      );
      // If they have an existing subcontractor account, direct them to login.
      // If they have an existing GC account, make sure they have access to the site before directing them to login,
      // if they're a GC user without access to the site, send them to the guest portal so they don't see access denied.
      if (
        checkExistingResponse.data.userId &&
        (checkExistingResponse.data.isSubcontractor ||
          checkExistingResponse.data.hasQuotesApplication ||
          checkExistingResponse.data.hasPreConApplication ||
          checkExistingResponse.data.hasInsightsApplication)
      ) {
        if (action.payload.mode === "proposal") {
          yield call(
            [oidcService, oidcService.signinRedirect],
            `/quotes/proposals/${action.payload.id}/review`
          );
        } else {
          yield call(
            [oidcService, oidcService.signinRedirect],
            `/quotes/solicitations/${action.payload.id}/requests`
          );
        }
      }
    }

    const response: AxiosResponse<string> = yield call(
      [accountService, accountService.confirmInvitation],
      action.payload.id,
      action.payload.email,
      action.payload.token
    );

    const { firUser, claims } = yield call(
      signinWithFirebase,
      response.data,
      true
    );

    const user = createGuestUser(claims);
    const payload = createGuestUserPayload(user, firUser, claims);
    switchRegion("en-us");
    switchLanguage("en");
    yield put(actions.signInWithEmailLink.success(payload));

    if (action.payload.fingerPrint) {
      try {
        yield call(
          [accountService, accountService.logEulaAcceptance],
          action.payload.fingerPrint,
          action.payload.email
        );
      } catch (logError) {
        console.error(logError);
      }
    }
  } catch (error) {
    console.error(error);
    yield put(actions.signInWithEmailLink.failure(error));
  }
}

export function* signInWithHcss(
  action: ActionType<typeof actions.signInWithHcssCredentials.request>
) {
  const claims = getUserClaimsFromProfile(action.payload.profile);
  const accessToken = action.payload.access_token;
  const homeBu = claims.businessUnitName;

  try {
    const userInfo: UserDto = yield call(
      getUserInfo,
      accessToken,
      claims.hcssUserId,
      homeBu
    );
    const region = userInfo.preferences.region || "en-us";
    const language = userInfo.preferences.language || "en";

    switchRegion(region as keyof typeof regions);
    switchLanguage(language as keyof typeof languages);

    if (region !== "en-us") {
      yield put(schemaActions.reloadStandardSchemas());
    }

    const firUser = undefined;
    if (!isSandboxEnabled()) {
      const { firUser } = yield call(
        signinWithFirebase,
        userInfo.firebaseToken,
        false
      );
    }

    const user = createHcssUser(
      accessToken,
      claims,
      userInfo.businessUnit,
      userInfo.accessibleBusinessUnits,
      userInfo.userName
    );
    const payload = createHcssUserPayload(user, firUser, userInfo, claims);
    yield put(actions.signInWithHcssCredentials.success(payload));
  } catch (error) {
    console.error(error);
    yield put(actions.signInWithHcssCredentials.failure(error));
  }
}

export function* signOut() {
  const auth = getAuth();
  yield call([auth, auth.signOut]);
}
