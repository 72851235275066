import React from "react";
import { Transition } from "react-transition-group";
import { ComponentType, Fragment, ReactNode } from "react";
import { ModuleNavigationWrapper } from "./module-navigation-wrapper";
import { ContextNavigationWrapper } from "./context-navigation-wrapper";
import { TRANSITION_DURATION_MS } from "../../common/constants";
import { useIsMounted } from "../../common/use-is-mounted";

interface DynamicNavigationProps {
  isVisible: boolean;
  hideNavVisuallyOnCollapse: boolean;
  contextNavigation?: ComponentType<{}>;
  moduleNavigation: ComponentType<{}>;
}
export const DynamicNavigation = ({
  isVisible,
  contextNavigation,
  moduleNavigation,
  hideNavVisuallyOnCollapse
}: DynamicNavigationProps) => {
  const isMounted = useIsMounted();

  const shouldRenderContext = contextNavigation ? true : false;
  const ContextNavigation = contextNavigation || Fragment;
  const ModuleNavigation = moduleNavigation;

  return (
    <Fragment>
      <ModuleNavigationWrapper isVisible={isVisible}>
        <ToggleContent
          isVisible={isVisible}
          hideNavVisuallyOnCollapse={hideNavVisuallyOnCollapse}
        >
          <ModuleNavigation />
        </ToggleContent>
      </ModuleNavigationWrapper>
      <Transition
        in={shouldRenderContext}
        timeout={isMounted ? TRANSITION_DURATION_MS : 0}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        {state => (
          <ContextNavigationWrapper
            isEntering={state === "entering"}
            isExiting={state === "exiting"}
            isVisible={isVisible}
          >
            <ToggleContent
              isVisible={isVisible}
              hideNavVisuallyOnCollapse={hideNavVisuallyOnCollapse}
            >
              <ContextNavigation />
            </ToggleContent>
          </ContextNavigationWrapper>
        )}
      </Transition>
    </Fragment>
  );
};

interface ToggleContentProps {
  isVisible: boolean;
  hideNavVisuallyOnCollapse: boolean;
  children: ReactNode;
}
const ToggleContent = ({
  isVisible,
  hideNavVisuallyOnCollapse,
  children
}: ToggleContentProps) => {
  if (!hideNavVisuallyOnCollapse && !isVisible) return null;
  return <Fragment>{children}</Fragment>;
};
