import React from "react";
import styled from "styled-components";
import { strings } from "localization";

import { Button, Icon } from "hcss-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const NotFound = () => {
  return (
    <Container className="container-fluid" data-testid="not-found-container">
      <div className="icon">
        <FontAwesomeIcon
          style={iconStyle}
          size="10x"
          icon={["fad", "telescope"]}
        />
      </div>
      <div className="content">
        <p className="title">{strings.notFound.title}</p>
        <p className="message">{strings.notFound.message}</p>
        <Link to="/">
          <Button>
            <Icon name="arrow-left" margin="right" />
            {strings.notFound.back}
          </Button>
        </Link>
      </div>
    </Container>
  );
};

const iconStyle = {
  "--fa-primary-color": "#ee9e58",
  "--fa-secondary-color": "#7070708c"
} as React.CSSProperties;

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    margin-right: 36px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .error {
    color: #5a5c69;
    font-size: 10rem;
  }

  .title {
    text-transform: uppercase;
    font-size: 2.5rem;
    letter-spacing: 1.2px;
  }

  .message {
    max-width: 450px;
    text-align: center;
  }
`;
