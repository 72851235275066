import React, { FC, ReactChild } from "react";
import styled from "styled-components";
import { Button, ConcreteColors, Icon } from "hcss-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  SizeProp,
  IconName,
  IconProp
} from "@fortawesome/fontawesome-svg-core";

export interface NoDataPlaceholderProps {
  onClick?: () => void;
  isAddEditDisplay?: boolean;
  mainIconName?: IconName;
  mainIconSize?: SizeProp;
  iconName?: string;
  helpText?: { title?: ReactChild; body?: ReactChild };
  buttonText: string;
  isReadOnly?: boolean;
  showButton?: boolean;
  buttonDataTestId?: string;
}

export const NoDataPlaceholder: FC<NoDataPlaceholderProps> = ({
  onClick,
  isAddEditDisplay,
  mainIconName,
  mainIconSize,
  iconName,
  helpText,
  buttonText,
  isReadOnly,
  showButton = true,
  buttonDataTestId = "Button"
}: NoDataPlaceholderProps) => {
  return (
    <Container>
      <div className="icon">
        {mainIconName && (
          <FontAwesomeIcon
            style={defaultIconStyle}
            icon={["fad", mainIconName] as IconProp}
            size={mainIconSize ?? "5x"}
          />
        )}
      </div>
      {!isReadOnly && helpText && (
        <>
          <div className="title">{helpText.title}</div>
          <div className="body">{helpText.body} </div>
          {showButton && <div className="divider" />}
        </>
      )}

      {showButton && (
        <div className="action">
          <Button
            onClick={onClick}
            disabled={isAddEditDisplay === false}
            data-testid={buttonDataTestId}
          >
            {!isReadOnly && <Icon name={iconName || "plus"} margin="right" />}
            {buttonText}
          </Button>
        </div>
      )}
    </Container>
  );
};

const defaultIconStyle = {
  "--fa-primary-color": ConcreteColors.green200,
  "--fa-secondary-color": "#7070708c"
} as React.CSSProperties;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;

  .icon {
    color: ${ConcreteColors.blue400};
  }

  div.title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    color: ${ConcreteColors.gray700};
    margin-top: 25px;
    margin-bottom: 10px;
  }

  div.body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    max-width: 550px;
    margin-top: 20px;
    color: ${ConcreteColors.gray600};
  }

  div.divider {
    width: 80%;
    border-top-width: 1px;
    border-top-color: ${ConcreteColors.gray300};
    border-top-style: solid;
    margin-top: 25px;
  }

  div.action {
    flex-direction: row;
    justify-content: center;
    text-align: center;
    width: 550px;
    margin-top: 25px;
  }
`;
