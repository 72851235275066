/* eslint-disable no-unexpected-multiline */
import styled from "styled-components";
import React, { FC, ComponentProps, useState, ReactNode } from "react";
import { Icon, ConcreteColors } from "hcss-components";
import { Box, BoxProps } from "hcss-core";

export const Section = styled(Box).attrs(() => ({ as: "section" }))`
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 24px 24px 24px;
  margin-bottom: 32px;
  border-left: 4px solid transparent;

  @media print {
    & {
      page-break-inside: avoid;
    }
  }
`;

export const SectionHeader = styled.div`
  padding-bottom: 20px;
`;

export const SectionTitle = styled.h2`
  display: inline-block;
  margin: 2px 0 0 0;
  font-size: 1.2em;
  font-weight: 500;
  vertical-align: middle;
`;

export const CollapsibleSectionHeader = styled(SectionHeader)<{
  expanded?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${props => !props.expanded && "padding-bottom: 0;"}
`;

export const CollapseIcon = styled(Icon).attrs(_ => ({
  name: "chevron-right"
}))<{
  expanded: boolean;
}>`
  transform: rotate(${props => (props.expanded ? "90" : "0")}deg);
  transition: all 0.2s ease 0s;
  align-self: center;
  margin-right: 12px;
  ${CollapsibleSectionHeader}:hover & {
    color: ${ConcreteColors.blue200};
  }
`;

// TODO: Update styled-components and styled-system so that we can get cleaner defs
export type CollapsibleSectionProps = {
  initialExpanded?: boolean;
  header: ReactNode;
  onToggle?: (isExpanded: boolean) => void;
  expanded?: boolean;
} & Omit<ComponentProps<"div">, "ref"> &
  BoxProps;

export const CollapsibleSection: FC<CollapsibleSectionProps> = ({
  children,
  initialExpanded,
  onToggle,
  header,
  expanded,
  ...other
}) => {
  const [_isExpanded, _setIsExpanded] = useState(initialExpanded ?? false);

  const isExpanded = expanded ?? _isExpanded;

  const setIsExpanded = () => {
    if (expanded === undefined) {
      _setIsExpanded(!isExpanded);
    }
    if (onToggle) onToggle(!isExpanded);
  };

  return (
    <Section {...other} style={{ position: "relative" }}>
      <CollapsibleSectionHeader expanded={isExpanded} onClick={setIsExpanded}>
        <CollapseIcon expanded={isExpanded} />
        {header}
      </CollapsibleSectionHeader>
      {isExpanded && (
        <CollapsibleSectionBody isExpanded={isExpanded}>
          {children}
        </CollapsibleSectionBody>
      )}
    </Section>
  );
};

export const CollapsibleSectionBody = styled.div<{ isExpanded?: boolean }>`
  display: ${props => (props.isExpanded ? "block" : "none")};
  height: 100%;

  @media print {
    & {
      display: block;
    }
  }
`;

export const SectionList = styled.div`
  padding: 12px 16px;
  min-height: 100%;
`;
