import React from "react";
import * as qs from "querystring";

export function useQueryParam<T>(name: string, search: string): T | undefined {
  const rawQuery = React.useMemo(() => qs.parse(search.replace(/^(\?)/, "")), [
    search
  ]);

  // read in the raw string value
  const rawValue = rawQuery[name];

  // cast if the raw value has changed, otherwise re-use memoized value
  const value = React.useMemo(() => {
    if (rawValue == null) {
      return undefined;
    }
    return (rawValue as unknown) as T;
  }, [rawValue]);

  return value;
}
