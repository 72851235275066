import { Select } from "hcss-components";
import { keyBy } from "lodash-es"
import * as React from "react";
import { FilterControlProps } from "./common";

const booleanOptions: any[] = [
  { label: "Checked", value: "true" },
  { label: "Not Checked", value: "false" }
];
const booleanOptionsLookup = keyBy(booleanOptions, "value");

export const BooleanFilter: React.FunctionComponent<FilterControlProps> = ({
  field,
  filter,
  onChange
}) => {
  const currentValue =
    filter.value !== undefined ? booleanOptionsLookup[filter.value] : null;
  return (
    <div style={{ width: "300px" }} key={field.id}>
      <Select
        isClearable
        value={currentValue}
        onChange={(option: any) => {
          let value: boolean | undefined;
          if (option) {
            value = option.value === "true";
          }
          onChange({
            ...filter,
            value
          });
        }}
        options={booleanOptions}
      />
    </div>
  );
};
