import { EstimateEntity } from "api";
import constate from "constate";
import { WithId } from "core";
import { useLocalStorage } from "hcss-hooks";
import { useSelectedBusinessUnitId } from "modules/account";
import { selectors } from "modules/estimates";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

export function useRecentEstimates() {
  const businessUnitId = useSelectedBusinessUnitId();
  const maxEstimates = 10;
  const [recentEstimateIds, setRecentEstimateIds] = useLocalStorage<string[]>(
    `${businessUnitId}-recent-estimates`,
    []
  );
  const workingCopy = useSelector(selectors.getWorkingCopy);

  const recentEstimates = useMemo(() => {
    return recentEstimateIds.reduce(
      (list: WithId<EstimateEntity>[], currId: string) => {
        if (workingCopy[currId]) list.push(workingCopy[currId]);
        return list;
      },
      []
    );
  }, [recentEstimateIds, workingCopy]);

  const addEstimate = useCallback(
    (estimateId: string) => {
      // @ts-ignore
      setRecentEstimateIds((prevEstimates: string[]) => {
        const newRecentList = [...prevEstimates];
        const currentIndex = newRecentList.findIndex(e => e === estimateId);
        if (currentIndex > -1) {
          newRecentList.splice(currentIndex, 1);
        }

        newRecentList.unshift(estimateId);
        return newRecentList.slice(0, maxEstimates);
      });
    },
    [setRecentEstimateIds]
  );

  return { recentEstimateIds, recentEstimates, addEstimate };
}

const [provider, context] = constate(useRecentEstimates);
export const RecentEstimateProvider = provider;
export const useRecentEstimatesContext = context;
