import React from "react";
import styled from "styled-components";
import { strings } from "localization";
import { Button, Icon } from "hcss-components";
import { useSelector } from "react-redux";
import { getErrorsState } from "modules/selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface GenericErrorProps {
  errors: string[];
}

export const GenericError = () => {
  const errors: string[] = useSelector(getErrorsState);

  const onBtnClick = () => {
    window.location.href = "/";
  };

  return (
    <Container
      className="container-fluid"
      data-testid="generic-error-container"
    >
      <div className="icon">
        <FontAwesomeIcon
          style={iconStyle}
          size="10x"
          icon={["fad", "construction"]}
        />
      </div>
      <div className="content">
        <p className="title">{strings.genericError.title}</p>
        <p className="message">{strings.genericError.message}</p>
        <br />
        {errors &&
          errors.length > 0 &&
          errors.map(e => <p className="message">{e}</p>)}
        <Button onClick={() => onBtnClick()}>
          <Icon name="arrow-left" margin="right" />
          {strings.genericError.back}
        </Button>
      </div>
    </Container>
  );
};

const iconStyle = {
  "--fa-primary-color": "#ee9e58",
  "--fa-secondary-color": "#7070708c"
} as React.CSSProperties;

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    margin-right: 36px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .error {
    color: #5a5c69;
    font-size: 10rem;
  }

  .title {
    text-transform: uppercase;
    font-size: 2.5rem;
    letter-spacing: 1.2px;
  }

  .message {
    max-width: 450px;
    text-align: center;
  }
`;
