import {
  ProductDto,
  IVendorDtoV1Response,
  ProductTypeDto,
  IProductTypeDto,
  IContactProduct
} from "api/GeneratedClients/ContactsClient";

export const newProductDtoFactory = (
  vendor: IVendorDtoV1Response,
  existingProducts: any,
  newProduct: IContactProduct
) => {
  const vendorId = vendor.id;
  const foundProduct = existingProducts.find(
    (existingProduct: IProductTypeDto) =>
      existingProduct.code === newProduct.code
  );
  const newProductType: ProductTypeDto = new ProductTypeDto({
    id: foundProduct ? foundProduct.id : newProduct.productTypeId,
    code: foundProduct ? foundProduct.code : newProduct.code,
    description: foundProduct
      ? foundProduct.description
      : newProduct.code.toLowerCase()
  });
  const newVendorProduct: ProductDto = new ProductDto({
    id: undefined,
    companyId: vendorId,
    product: newProductType,
    region: undefined,
    date: undefined
  });

  return newVendorProduct;
};
