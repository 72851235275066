import React from "react";
import styled from "styled-components";
import { Section } from "../sections/base";
import { ConcreteColors } from "hcss-components";

export const PlaceholderDynamicMenu = () => {
  return (
    <Container>
      <Section>
        {baseStyles => {
          return (
            <HeaderContainer style={baseStyles}>
              <PlaceHolderHeaderContainer hasBefore />
            </HeaderContainer>
          );
        }}
      </Section>
      <Section>
        {baseStyles => {
          return (
            <div style={baseStyles}>
              <PlaceHolderItem hasBefore />
              <PlaceHolderItem hasBefore />
              <PlaceHolderItem hasBefore />
              <PlaceHolderItem hasBefore />
              <PlaceHolderItem hasBefore />
            </div>
          );
        }}
      </Section>
    </Container>
  );
};

interface PlaceHolderProps {
  hasBefore?: boolean;
  wrapperStyles?: React.CSSProperties;
  beforeStyles?: React.CSSProperties;
  contentStyles?: React.CSSProperties;
}
const PlaceHolderHeaderContainer = (props: PlaceHolderProps) => {
  return (
    <PlaceHolderItem
      hasBefore
      wrapperStyles={{
        ...props.wrapperStyles,
        height: 48,
        paddingLeft: 4,
        paddingRight: 4
      }}
      beforeStyles={{
        ...props.beforeStyles,
        borderRadius: 3,
        height: 40,
        marginRight: 8,
        width: 40
      }}
      contentStyles={{ ...props.contentStyles, height: 40 }}
    />
  );
};

export const PlaceHolderItem = ({
  hasBefore = false,
  wrapperStyles,
  beforeStyles,
  contentStyles
}: PlaceHolderProps) => {
  return (
    <ItemWrapper style={wrapperStyles}>
      {hasBefore && <ItemBefore style={beforeStyles} />}
      <ItemContent style={contentStyles} />
    </ItemWrapper>
  );
};

const Container = styled.div.attrs(_ => ({
  className: "dynamic-menu-placeholder-container"
}))``;

const HeaderContainer = styled.div.attrs(_ => ({
  className: "dynamic-menu-placeholder-header-container"
}))`
  padding: 20px 18px;
`;

const ItemWrapper = styled.div.attrs(_ => ({
  className: "dynamic-menu-placeholder-item-wrapper"
}))`
  align-items: center;
  display: flex;
  height: 40px;
  padding: 12px;
  opacity: 0.5;
`;

const ItemBefore = styled.div.attrs(_ => ({
  className: "dynamic-menu-placeholder-item-before"
}))`
  background-color: ${ConcreteColors.gray300};
  border-radius: 3px;
  flex-shrink: 0;
  height: 24px;
  margin-right: 16px;
  width: 24px;
`;

const ItemContent = styled.div.attrs(_ => ({
  className: "dynamic-menu-placeholder-item-content"
}))`
  border-radius: 4px;
  flex-grow: 1;
  height: 20px;
  background-color: ${ConcreteColors.gray300};
`;
