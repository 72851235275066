import {
  CurrentUser,
  UserType,
  WithId,
  formatCompanyField,
  formatContactField,
  formatHJJobField,
  formatLatLong,
  getFormatMultiSelect,
  getSortMultiSelectFn,
  sortCompany,
  sortContact,
  sortLatLong,
  statesObjectList
} from "core";
import { DataColumnType, TypedDataColumn } from "hcss-tables";
import { strings } from "localization";
import { flatten, keyBy, merge } from "lodash-es";
import memoize from "memoize-one";
import { StateSlice as UserStateSlice } from "modules/account";
import {
  preconIdToString,
  sortPreConId
} from "modules/projects/common/helpers";
import { createSelector } from "reselect";
import { Schema, SchemaField, SchemaFieldType, SchemaSection } from "../../api";
import { calculatedFieldService } from "./services/calculated-field.service";
import { StateSlice } from "./state";

export type SelectorState = StateSlice & UserStateSlice;

// Selectors
export const getAllIds = (state: SelectorState) => state.schemas.allIds;
export const getLoaded = ({ schemas }: SelectorState) => schemas.loaded;
export const getErrors = ({ schemas }: SelectorState) => schemas.errors;
export const getSaved = ({ schemas }: SelectorState) => schemas.saved;
export const getSaving = ({ schemas }: SelectorState) => schemas.saving;
export const getIsAdmin = ({ account }: SelectorState) =>
  account.permissions?.admin ?? false;
export const getSchemaHash = (state: SelectorState) =>
  state.schemas.workingCopy;
export const getSchemaHashPristine = (state: SelectorState) =>
  state.schemas.original;

export const getAllSchemas = createSelector(
  [getAllIds, getSchemaHash],
  (ids, schemas) => {
    return ids.map(id => schemas[id]);
  }
);

export const getAllSchemasPristine = createSelector(
  [getAllIds, getSchemaHashPristine],
  (ids, schemas) => {
    return ids.map(id => schemas[id]);
  }
);

const filterEstimateSection = memoize(
  (schema: WithId<Schema>, user?: CurrentUser) => {
    const estimateSectionId = "ae9478b8-a74c-4da6-979b-16eb096210f8";
    if (user?.type === UserType.Subcontractor) {
      return {
        ...schema,
        orderedSections: schema.orderedSections.filter(
          sectionId => sectionId !== estimateSectionId
        )
      };
    }
    return schema;
  }
);

export const getEstimateSchema = ({ schemas }: SelectorState) => schemas;
export const getEstimateReportingSchema = createSelector(
  [getEstimateSchema],
  schemas => {
    if (!schemas || !schemas.workingCopy) return undefined;

    return Object.values(schemas.workingCopy).find(
      schema => schema.schemaName === "estimateReporting"
    );
  }
);

export const getAllEstimateReportingSchemaFields = createSelector(
  [getEstimateReportingSchema],
  schema => {
    if (!schema) return undefined;
    return flattenTableFields(
      schema.orderedSections,
      schema.sections,
      schema.fields
    );
  }
);

export const getBrokenOutActivityTotalFields = createSelector(
  [getAllEstimateReportingSchemaFields],
  fields => {
    if (!fields) return [];

    const mappedFields: SchemaField[] = [];

    fields.forEach(field => {
      const baseTotalField: SchemaField = {
        id: "",
        name: "",
        type: SchemaFieldType.Currency,
        config: {},
        readOnly: true,
        required: false,
        adminOnly: false,
        standard: false,
        protected: true,
        hiddenInTable: false,
        hiddenInForm: true,
        lookup: true,
        isLockable: false,
        useDefaultValue: false,
        characterLengthLimited: false
      };

      const { key } = field.config;

      const quantityTotalForField = {
        ...baseTotalField,
        type: SchemaFieldType.Number,
        name: `${field.title} - Quantity`,
        id: `${key}.quantity`
      };
      const costTotalForField = {
        ...baseTotalField,
        name: `${field.title} - Cost`,
        id: `${key}.cost`
      };

      mappedFields.push(quantityTotalForField, costTotalForField);
    });
    return mappedFields;
  }
);

export const getBrokenOutActivityTotalFieldsSimple = createSelector(
  [getAllEstimateReportingSchemaFields],
  fields => {
    if (!fields) return undefined;
    return fields.map(field => {
      const { expression, unit, match } = field.config["formula"];
      const key = field.config?.key;
      return { match, expression, unit, key };
    });
  }
);

export const getSchemas = ({ schemas }: SelectorState) => schemas;
export const getAccount = ({ account }: SelectorState) => account;

export const getProjectSchema = createSelector(
  [getSchemas, getAccount],
  (schemas, account) =>
    schemas.projectSchemaId
      ? filterEstimateSection(
          schemas.workingCopy[schemas.projectSchemaId],
          account.user
        )
      : undefined
);

export const getCompleteProjectSchema = createSelector(
  [getProjectSchema, getBrokenOutActivityTotalFields],
  (projectSchemaOriginal, activityTotalFields) => {
    if (!projectSchemaOriginal) return undefined;
    const projectSchema = { ...projectSchemaOriginal };

    const oldFields = { ...projectSchema.fields };
    const fields = merge(oldFields, keyBy(activityTotalFields, "id"));

    const sections = { ...projectSchema.sections };
    const oldSectionWithEstimates = Object.values(sections).find(section =>
      section.fields.includes("estimates")
    );

    if (oldSectionWithEstimates) {
      const sectionWithEstimates = { ...oldSectionWithEstimates };
      const fields = [...sectionWithEstimates.fields];
      const filteredActivityTotalFields = activityTotalFields.flatMap(field =>
        !fields.includes(field.id) ? [field.id] : []
      );
      const indexOfLastEstimatesField = fields.findIndex(
        field => field === "values.totals.actualMarkup_Takeoff"
      );
      fields.splice(
        indexOfLastEstimatesField + 1,
        0,
        ...filteredActivityTotalFields
      );
      sectionWithEstimates.fields = fields;
      sections[sectionWithEstimates.id] = sectionWithEstimates;
    }

    return {
      ...projectSchema,
      sections,
      fields
    } as Schema;
  }
);

export const getProjectSchemaPristine = createSelector(
  [
    ({ schemas }: SelectorState) => schemas,
    ({ account }: SelectorState) => account
  ],
  (schemas, account) =>
    schemas.projectSchemaId
      ? filterEstimateSection(
          schemas.original[schemas.projectSchemaId],
          account.user
        )
      : undefined
);

export const getProjectSchemaFieldsBySection = createSelector(
  [getProjectSchema],
  schema => {
    if (!schema) {
      return undefined;
    }
    return schema.orderedSections.map(sectionId => {
      return schema.sections[sectionId];
    });
  }
);

export const getProjectSchemaOrderedSections = createSelector(
  [getProjectSchema],
  schema => {
    if (!schema) {
      return undefined;
    }
    return schema.orderedSections;
  }
);

export const getProjectSchemaSections = createSelector(
  [getProjectSchema],
  schema => {
    if (!schema) {
      return undefined;
    }
    return schema.sections;
  }
);

export const getProjectSchemaSectionsWithFields = createSelector(
  [getProjectSchemaFieldsBySection],
  sections => {
    if (!sections) {
      return undefined;
    }
    return sections.filter(s => s.fields.length > 0);
  }
);

export const getProjectSchemaFields = createSelector(
  [getProjectSchema],
  schema => {
    if (!schema) {
      return undefined;
    }
    return schema.fields;
  }
);

export const getOrderedProjectSchemaFields = createSelector(
  [getProjectSchema],
  schema => {
    if (!schema) {
      return [];
    }

    const orderedFieldIds = schema.orderedSections
      .map(sectionId => {
        const section = schema.sections[sectionId];

        if (!section) {
          return [];
        }

        return section.fields;
      })
      .reduce((acc, next) => [...acc, ...next], []);

    return orderedFieldIds
      .map(fieldId => schema.fields[fieldId])
      .filter(f => !!f);
  }
);

const filterTableFields = <TField extends SchemaField>(fields: TField[]) => {
  return fields.filter(field => {
    if (field.hiddenInTable) return false;

    switch (field.type) {
      case SchemaFieldType.Custom:
        if (field.id === "percentFromLowBid") return true;
        return false;
      case SchemaFieldType.BidResults:
        return false;
      case SchemaFieldType.Estimates:
        return false;
      default:
        return true;
    }
  });
};

const flattenTableFields = (
  orderedSections: string[],
  sections: Record<string, SchemaSection>,
  fields: Record<string, SchemaField>
) => {
  return flatten(
    orderedSections.map(sectionId => {
      return sections[sectionId].fields.map(fieldId => ({
        ...fields[fieldId],
        name: fieldId,
        title: fields[fieldId]?.name,
        editable: !fields[fieldId]?.readOnly
      }));
    })
  );
};

export const getAllProjectSchemaFields = createSelector(
  [
    getProjectSchemaOrderedSections,
    getProjectSchemaSections,
    getProjectSchemaFields
  ],
  (orderedSections, sections, fields) => {
    if (!orderedSections || !sections || !fields) {
      return undefined;
    }
    return flattenTableFields(orderedSections, sections, fields);
  }
);

export const getProjectSchemaTableFields = createSelector(
  [getAllProjectSchemaFields],
  fields => {
    if (!fields) {
      return undefined;
    }
    return filterTableFields(fields);
  }
);

export const getDateFields = createSelector(
  [getAllProjectSchemaFields],
  fields => {
    return fields?.filter(
      field =>
        field.type === SchemaFieldType.Date ||
        field.type === SchemaFieldType.DateTime
    );
  }
);

export const getBidResultsSchema = createSelector([getAllSchemas], schemas => {
  const schema = schemas.find(schema => schema.schemaName === "bidresults");
  return schema;
});

export const getAllBidResultsSchemaFields = createSelector(
  [getBidResultsSchema],
  schema => {
    if (!schema) {
      return undefined;
    }
    return flattenTableFields(
      schema.orderedSections,
      schema.sections,
      schema.fields
    );
  }
);

export const getBidResultsSchemaTableFields = createSelector(
  [getAllBidResultsSchemaFields],
  fields => {
    if (!fields) {
      return undefined;
    }
    return filterTableFields(fields);
  }
);

export const getActivityCodebooksSchema = createSelector(
  [getAllSchemas],
  schemas => {
    const activityCodebooksSchema = schemas.find(
      schema => schema.schemaName === "activityCodebooks"
    );
    return activityCodebooksSchema;
  }
);

export const getActivityCodebooksSchemaFields = createSelector(
  [getActivityCodebooksSchema],
  schema => {
    if (schema) {
      return schema.fields;
    }
    return {};
  }
);

export const getEstimatesSchema = createSelector([getAllSchemas], schemas => {
  const estimatesSchema = schemas.find(
    schema => schema.schemaName === "estimates"
  );
  return estimatesSchema;
});

export const getEstimatesExtendedSchema = createSelector(
  [getAllSchemas],
  schemas => {
    const estimatesExtendedSchema = schemas.find(
      schema => schema.schemaName === "estimatesExtended"
    );
    return estimatesExtendedSchema;
  }
);

export const getEstimatesExtendedSchemaFields = createSelector(
  [getEstimatesExtendedSchema],
  schema => {
    if (schema) {
      return schema.fields;
    }
    return {};
  }
);

export const getEstimatesExtendedSchemaFieldLabels = createSelector(
  [getEstimatesExtendedSchema],
  schema => {
    if (!schema) return null;
    const schemaFieldLabelDict: Record<string, string> = {};
    const schemaFieldKeys = Object.keys(schema.fields);
    schemaFieldKeys.forEach(field => {
      const currentField = { ...schema.fields[field] };
      schemaFieldLabelDict[field] = currentField.name;
    });
    return schemaFieldLabelDict;
  }
);

export const getCompleteEstimatesExtendedSchema = createSelector(
  [getEstimatesExtendedSchema, getBrokenOutActivityTotalFields],
  (estimatesSchema, activityTotalFields) => {
    if (!estimatesSchema) return undefined;

    if (activityTotalFields.length === 0) return { ...estimatesSchema };

    const fields = {
      ...estimatesSchema.fields,
      ...keyBy(activityTotalFields, "id")
    };

    const sections = { ...estimatesSchema.sections };
    const orderedSections = [...estimatesSchema.orderedSections];

    sections["activityTotals"] = {
      id: "activityTotals",
      name: strings.tables.options.activityTotals,
      fields: activityTotalFields.map(field => field.id)
    };
    orderedSections.push("activityTotals");

    return {
      ...estimatesSchema,
      sections,
      orderedSections,
      fields
    };
  }
);

export const getAllEstimatesSchemaFields = createSelector(
  [getEstimatesSchema],
  schema => {
    if (!schema) {
      return undefined;
    }
    return flattenTableFields(
      schema.orderedSections,
      schema.sections,
      schema.fields
    );
  }
);

export const getEstimatesSchemaTableFields = createSelector(
  [getAllEstimatesSchemaFields],
  fields => {
    if (!fields) {
      return undefined;
    }
    return filterTableFields(fields);
  }
);

export const getProjectTableColumns = createSelector(
  [getCompleteProjectSchema],
  schema => {
    if (schema) {
      const { fields, sections, orderedSections } = schema;
      return flatten(
        orderedSections.map(sectionId => {
          const section = sections[sectionId];
          return section.fields.map(fieldId => fields[fieldId]);
        })
      );
    }
  }
);

export const getFilteredProjectTableColumns = createSelector(
  [getProjectTableColumns],
  columns => {
    if (columns) {
      return columns.filter(column => !column.hiddenInTable);
    }
  }
);

export const isPreConIdEnabled = createSelector(
  [getCompleteProjectSchema],
  schema => {
    if (schema) {
      return (
        !schema.fields?.["preconId"]?.hiddenInForm &&
        !schema.fields?.["preconId"]?.hiddenInTable
      );
    }
  }
);

export const isHJJobEnabled = createSelector(
  [getCompleteProjectSchema],
  schema => {
    if (schema) {
      return !schema.fields?.["hJJob"]?.hiddenInForm;
    }
  }
);

export const getPreConIdField = createSelector(
  [getCompleteProjectSchema],
  schema => {
    if (schema) {
      return schema.fields?.["preconId"];
    }
    return undefined;
  }
);

export const getDevExpressColumnsForProjectTable = createSelector(
  [getFilteredProjectTableColumns, getIsAdmin],
  (filteredColumns, isAdmin) => {
    if (filteredColumns) {
      return filteredColumns.map(column => {
        const readOnly = isColumnReadOnly(column, isAdmin);
        const groupingEnabled = isGroupingEnabled(column);

        // this logic (manually changing the column type at run time) might need to grow in the future
        // if what hcss-tables is wanting to support diverges further
        // from data types we want represented in the PreCon projects table,
        // as up until this point the two types have been in sync

        // this is essentially a way of "mapping" a SchemaField type (something creatable in PreCon Customize Setup)
        // to a DataColumnType (something hcss-tables is equipped to handle).

        // Right now, a Location field is stored in PreCon backend as SchemaFieldType.Location (15),
        // but in order to behave properly in the Projects table needs to have it's type changed
        // to Custom (SchemaFieldType.Custom = TypedDataColumn.Custom = 3) in order to pass in a custom sorting algorithm,
        // and a custom "valueAsString" function that gets run when searching for values in the table. Both of these functions are
        // automatically run by the hcss-tables code
        const mapColumnType = (t: SchemaFieldType) => {
          switch (t) {
            case SchemaFieldType.PreConId:
            case SchemaFieldType.MultiSelectList:
            case SchemaFieldType.Location:
            case SchemaFieldType.Company:
            case SchemaFieldType.Contact:
            case SchemaFieldType.HJJob:
            case SchemaFieldType.Table:
              return DataColumnType.Custom;

            case SchemaFieldType.Calculated:
              return DataColumnType.Number;

            default:
              return t;
          }
        };

        const type = mapColumnType(column.type);

        const config = generateColumnConfig(column.type, column.config);

        return ({
          ...column,
          name: column.id,
          title: column.name,
          groupingEnabled,
          readOnly,
          type,
          getCellValue: (row: any) => {
            if (column.id === "quickPriceSheet.TotalPrice") {
              return roundQuickPriceTotalPriceValue(row, column);
            }
            if (row && row.fields) {
              return row.fields[column.id];
            }
            return row[column.id];
          },
          config
        } as unknown) as TypedDataColumn;
      });
    }
  }
);
const roundQuickPriceTotalPriceValue = (row: any, column: SchemaField) => {
  if (row?.fields) {
    return row.fields[column.id]
      ? parseFloat(row.fields[column.id].toFixed(2))
      : row.fields[column.id];
  }
  return row[column.id]
    ? parseFloat(row[column.id].toFixed(2))
    : row[column.id];
};

const isColumnReadOnly = (column: SchemaField, isAdmin: boolean) => {
  return (
    (!isAdmin && column.adminOnly) ||
    column.readOnly ||
    column.type === SchemaFieldType.Location ||
    column.type === SchemaFieldType.Table ||
    column.type === SchemaFieldType.Calculated
  );
};

const isGroupingEnabled = (column: SchemaField) => {
  if (
    column.id === "name" ||
    column.type === SchemaFieldType.PreConId ||
    column.type === SchemaFieldType.Location
  )
    return false;
  return true;
};

export const generateColumnConfig = (
  type: SchemaFieldType,
  configOverride: { [key: string]: any } = {}
) => {
  let config;
  switch (type) {
    case SchemaFieldType.States:
      config = {
        preConType: SchemaFieldType.States,
        listValues: statesObjectList
      };
      break;
    case SchemaFieldType.List:
      config = {
        preConType: SchemaFieldType.List,
        listValues: []
      };
      break;
    case SchemaFieldType.Location:
      config = {
        preConType: SchemaFieldType.Location,
        latLong: true,
        sortingFunction: (
          a: { lat: number; long: number } | null,
          b: { lat: number; long: number } | null
        ) => sortLatLong(a, b),
        valueAsString: formatLatLong
      };
      break;
    case SchemaFieldType.MultiSelectList:
      const sortMultiSelect = getSortMultiSelectFn(configOverride);
      const formatMultiSelect = getFormatMultiSelect(configOverride);
      config = {
        preConType: SchemaFieldType.MultiSelectList,
        sortingFunction: sortMultiSelect,
        valueAsString: formatMultiSelect
      };
      break;
    case SchemaFieldType.PreConId:
      config = {
        preConType: SchemaFieldType.PreConId,
        valueAsString: preconIdToString,
        sortingFunction: sortPreConId
      };
      break;
    case SchemaFieldType.Company:
      config = {
        preConType: SchemaFieldType.Company,
        company: true,
        valueAsString: formatCompanyField,
        sortingFunction: sortCompany
      };
      // This is to fix the bug in the HBW-4830 card.
      // The reason why we are deleting the config.contact = true here is because
      // when we switching from the contact field to company field, we forgot to run a migration
      // to update the old config.contact to be config.company. Therefore, for some customers that was in
      // the middle of this change, there company field still has the config.contact = true which makes the column provider
      // displays it as a contact field which it is not.
      // This line of code is to prevent that for happening ONLY in the UI. The config data in cosmos could still
      // be in a wrong state.
      delete configOverride.contact;
      break;
    case SchemaFieldType.Contact:
      config = {
        preConType: SchemaFieldType.Contact,
        contact: true,
        valueAsString: formatContactField,
        sortingFunction: sortContact
      };
      break;
    case SchemaFieldType.HJJob:
      config = {
        preConType: SchemaFieldType.HJJob,
        valueAsString: formatHJJobField
      };
      break;
    case SchemaFieldType.Table:
      config = {
        preConType: SchemaFieldType.Table
      };
      break;
    case SchemaFieldType.Calculated:
      const calculatedFieldConfig = calculatedFieldService.getConfig({
        config: configOverride
      });
      config = {
        preConType: SchemaFieldType.Calculated,
        requiresEstimatesAccess: calculatedFieldService.hasEstimatesVariables(
          calculatedFieldConfig
        )
      };
      delete configOverride.requiresEstimatesAccess;
      break;
    default:
      config = {};
  }
  return { ...config, ...configOverride };
};
