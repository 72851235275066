import React, { useEffect, useState } from "react";
import { usePreferences, actions } from "modules/account";
import { useDispatch } from "react-redux";
import { regions, strings } from "localization";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";

export interface LanguageListItem {
  value: string;
  label: string;
}

export const RegionMenuItem: React.FC = () => {
  const dispatch = useDispatch();
  const userPreferences = usePreferences();
  const [region, setRegion] = useState(userPreferences.region);

  useEffect(() => {
    if (region !== userPreferences.region) {
      window.location.reload();
    }
  }, [region, userPreferences]);

  const handleInputChange = (value: string) => {
    setRegion(value);
    dispatch(
      actions.updatePreferences.request({
        ...userPreferences,
        region: value
      })
    );
  };

  const options: LanguageListItem[] = Object.values(regions).map(region => ({
    value: region.id,
    label: region.name
  }));

  const customSingleValue = ({ data }: any) => (
    <div className="input-select">
      <div className="input-select__single-value">
        {data && (
          <span className="input-select__icon fa fa-location-arrow">
            &nbsp;
          </span>
        )}
        <span>{data.label}</span>
      </div>
    </div>
  );

  const menuItems = options.map((option: LanguageListItem) => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ));

  const selectedOption = options.find(
    lan => lan.value === (region ? region : "en")
  );

  return (
    <div data-testid="top-nav-profile-region" style={{ marginBottom: "15px" }}>
      <FormControl fullWidth size="small">
        <InputLabel id="language-select">
          {strings.layout.profile.dateTimeFormat}
        </InputLabel>
        <Select
          startAdornment={
            <InputAdornment position="start">
              <LanguageIcon />
            </InputAdornment>
          }
          labelId="language-select"
          id="language-select"
          value={selectedOption?.value}
          label={strings.layout.profile.dateTimeFormat}
          onChange={event => handleInputChange(event.target.value)}
        >
          {menuItems}
        </Select>
      </FormControl>
    </div>
  );
};

export default RegionMenuItem;
