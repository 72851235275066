import { CurrentUser } from "core";
import {
  ICreateProfileDto,
  CreateProfileDto,
  IAddress,
  Address,
  CommunicationMethod
} from "api/GeneratedClients/ContactsClient";

export class ProfileService {
  initializeAddress = () => {
    const address: IAddress = {
      addressLine1: "",
      addressLine2: "",
      building: "",
      city: "",
      stateProvince: "",
      countryRegion: "",
      postalCode: ""
    };
    return new Address(address);
  };

  createProfileFromCompanyInfo = (currentUser: CurrentUser) => {
    const tempProfile: ICreateProfileDto = {
      name: currentUser.companyName,
      primaryAddress: this.initializeAddress(),
      alternateAddress: this.initializeAddress(),
      phoneNumber: "",
      faxNumber: "",
      webAddress: "",
      communicationMethod: CommunicationMethod.None,
      profileAccentColor: ""
    };

    const profile = new CreateProfileDto({ ...tempProfile });

    return profile;
  };
}
