import { Button, Icon, Modal } from "hcss-components";
import React from "react";
import { useSmartDriveContext } from "hcss-smartdrive";
import styled from "styled-components";
import { strings } from "localization";

export const SmartDriveDeleteConfirmation = () => {
  const {
    api: { deleteFile },
    fileOperations: { fileToDelete, setFileToDelete },
    allSelectedRowsOperations: { selectedRowIds, setSelectedRowIds }
  } = useSmartDriveContext();

  const showModal = fileToDelete !== undefined;

  return (
    <Modal show={showModal} onHide={() => setFileToDelete(undefined)}>
      <Modal.Header closeButton>
        <Modal.Title>{strings.smartdrive.deleteFile}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          {showModal &&
            `${strings.smartdrive.deleteMessage} ${fileToDelete!.fileName}?`}
          <Warning>{strings.smartdrive.warning}</Warning>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            if (fileToDelete) {
              deleteFile(fileToDelete.id)
                .then(() => {
                  const newSelectedRowIds = selectedRowIds.filter(
                    rowId => rowId !== fileToDelete.id
                  );
                  setSelectedRowIds(newSelectedRowIds);
                })
                .catch(err => {
                  console.error(err);
                });
              setFileToDelete(undefined);
            }
          }}
          hcssStyle="Delete"
        >
          <Icon name="trash-o" />
          &nbsp;{strings.smartdrive.confirm}
        </Button>
        <Button
          hcssStyle="ThemeInverted"
          onClick={() => setFileToDelete(undefined)}
        >
          <Icon name="times" />
          &nbsp;{strings.smartdrive.cancel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Warning = styled.div`
  display: block;
  font-size: 12px;
  font-style: italic;
  margin-top: 4px;
  font-weight: 600;
`;
