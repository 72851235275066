import React, { useState } from "react";
import moment from "moment";
import { DateTimePicker as RWDateTimePicker } from "react-widgets";
import { parseDate } from "core";
import { isYearIn100Range } from "modules/schemas/components/filters/dateTimeUtils";

export const DateTimePicker = ({
  time: useTime,
  value,
  onChange,
  ...props
}: RWDateTimePicker.DateTimePickerProps) => {
  const [_currentDate, setCurrentDate] = useState<Date>();

  const handleChange = (date?: Date, dateStr?: string) => {
    if (!date || isYearIn100Range(date)) {
      setCurrentDate(undefined);
    }
    if (onChange) onChange(date, dateStr);
  };

  const currentDate =
    useTime && !_currentDate
      ? moment().set("hour", 12).set("minute", 0).toDate()
      : moment(_currentDate).set("hour", 12).set("minute", 0).toDate();

  const format = useTime ? "L LT" : "L";
  return (
    <RWDateTimePicker
      time={useTime}
      value={value}
      currentDate={currentDate}
      onCurrentDateChange={d => setCurrentDate(d)}
      onChange={handleChange}
      format={format}
      parse={parseDate as any}
      {...props}
    />
  );
};
