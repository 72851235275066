import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { FullPageLoading } from "modules/layout/components/FullPageLoading";
import { PaneledPage, Button, Icon, Link as HLink } from "hcss-components";
import {
  formatPhoneNumber,
  isNullOrWhitespace,
  PanelLoadingOverlay,
  Section,
  TextOverflow
} from "core";
import { strings } from "localization";
import ContactList from "./contact-list";
import { ConcreteColors } from "hcss-components";
import {
  IVendorDtoV1Response,
  IAddress
} from "api/GeneratedClients/ContactsClient";
import { useSelector } from "react-redux";
import { selectors as contactsSelectors } from "modules/contacts";
import { VendorLocationOption } from "./vendor-edit-details";
import { DropdownList } from "react-widgets";
import { useVendorLocationDropDown } from "modules/contacts/hooks/use-vendor-location-drop-down";
import { useGetSelectedVendor } from "../hooks/use-get-selected-vendor";
import { useAuthorization, usePermissions } from "modules/account";
import { sortBy } from "lodash-es";

export const VendorDetails = () => {
  const history = useHistory();
  const vendor = useGetSelectedVendor();
  const permissions = usePermissions();
  const authorization = useAuthorization();

  const isLoading = useSelector(contactsSelectors.getLoading);

  if (!vendor) {
    return <FullPageLoading loading={true} />;
  }

  const handleEditVendor = () => {
    history.push(`/contacts/${vendor.id}/edit`);
  };

  return (
    <PaneledPage>
      <PaneledPage.Header>
        <PaneledPage.Header.Title>
          {strings.contactManagement.vendorDetails.title}
        </PaneledPage.Header.Title>
        <PaneledPage.Header.Tools>
          <HLink
            onClick={() => history.replace("/contacts")}
            style={{ marginRight: "2rem" }}
          >
            <Icon name="arrow-left" /> {strings.projects.details.header.back}
          </HLink>
          {permissions.contactWrite && authorization.canAccessLimitedPrecon && (
            <Button onClick={() => handleEditVendor()}>
              <span>
                <Icon name="pencil" margin="right" />
                {strings.contactManagement.vendorDetails.edit}
              </span>
            </Button>
          )}
        </PaneledPage.Header.Tools>
      </PaneledPage.Header>
      <PaneledPage.Content>
        {isLoading && (
          <PanelLoadingOverlay
            message={strings.contactManagement.contactList.loading}
          />
        )}
        <Section
          style={{
            display: "flex",
            flexWrap: "nowrap",
            borderRadius: "2rem",
            wordBreak: "break-word",
            justifyContent: "space-between",
            gap: "2rem"
          }}
        >
          <div
            style={{
              flex: "1 1 0px",
              display: "flex",
              flexDirection: "column",
              gap: "1rem"
            }}
          >
            <span style={{ lineHeight: "1em" }}>
              <strong>{vendor.code}</strong> - {vendor.name}
              {vendor.type?.code && (
                <span style={{ lineHeight: "1em" }}>
                  <CompanyType>{` [${vendor.type?.code}]`}</CompanyType>
                </span>
              )}
            </span>
            <VendorContactInfo vendor={vendor} />
          </div>
          <div style={{ flex: "1 1 0px" }}>
            <div>
              <VendorProducts vendor={vendor} />
            </div>
            <div>
              <MinorityStatuses vendor={vendor} />
            </div>
            <div style={{ display: "flex", columnGap: "8rem" }}>
              <div>
                <h5>{strings.contactManagement.vendorDetails.isBonded}</h5>
                <div>
                  {vendor.isBonded ? (
                    <i className="fa fa-check" />
                  ) : (
                    <i className="fa fa-times" />
                  )}
                </div>
              </div>
              <div>
                <h5>{strings.contactManagement.vendorDetails.bondRate}</h5>
                <div>{vendor.bondRate}</div>
              </div>
            </div>
            {vendor.accountingCode && (
              <div>
                <h5 style={{ flexBasis: "15rem" }}>
                  {
                    strings.contactManagement.vendorDetails
                      .accountingCodeHeading
                  }
                </h5>
                <div style={{ flexBasis: "15rem" }}>
                  {vendor.accountingCode}
                </div>
              </div>
            )}
          </div>
          <div style={{ flex: "1 1 0px" }}>
            <div>
              <h5>{strings.contactManagement.vendorDetails.notesHeading}</h5>
              {vendor.note}
            </div>
          </div>
        </Section>
        <Section>
          <ContactList />
        </Section>
      </PaneledPage.Content>
    </PaneledPage>
  );
};

export default VendorDetails;

const VendorContactInfo = ({ vendor }: { vendor: IVendorDtoV1Response }) => {
  const {
    localVendor,
    locationOptions,
    vendorLocation,
    setVendorLocation
  } = useVendorLocationDropDown(vendor);
  const handleVendorLocationChange = (data: VendorLocationOption) => {
    setVendorLocation(data.value);
  };
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>{strings.contactManagement.vendorDetails.officeHeading}:</div>
        <div id="office-location-dropdown" style={{ width: "80%" }}>
          <DropdownList
            filter="contains"
            textField="display"
            valueField="id"
            data={locationOptions}
            value={
              !isNullOrWhitespace(vendorLocation?.nickname)
                ? { id: vendorLocation?.id }
                : null
            }
            onChange={handleVendorLocationChange}
            messages={{
              emptyList:
                strings.contactManagement.contactListModal.emptyLocationsText
            }}
          />
        </div>
      </div>
      {vendorLocation && (
        <VendorLocationAddress address={vendorLocation.address} />
      )}
      {localVendor && <WebAddress webAddress={localVendor.webAddress} />}
      {vendorLocation && (
        <PhoneNumber phoneNumber={vendorLocation.phoneNumber} />
      )}
      {vendorLocation && <FaxNumber faxNumber={vendorLocation.faxNumber} />}
    </div>
  );
};

const MinorityStatuses = ({ vendor }: { vendor: IVendorDtoV1Response }) => {
  if (vendor.minorityStatuses.length === 0) return null;
  return (
    <div>
      <h5>{strings.contactManagement.vendorDetails.minorityStatusesHeading}</h5>
      {vendor.minorityStatuses.map(dto => (
        <Pill key={dto.id} fillPercentage={Number(dto?.percent)}>
          <span style={{ verticalAlign: "middle" }}>
            <strong>{dto.type?.code}</strong> {dto.percent}&#37;
          </span>
        </Pill>
      ))}
    </div>
  );
};

const FaxNumber = ({ faxNumber }: { faxNumber: string | undefined }) => {
  if (!faxNumber) return null;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <StyledIcon name="fax" />
      {formatPhoneNumber(faxNumber)}
    </div>
  );
};

export const WebAddress = ({
  webAddress
}: {
  webAddress: string | undefined;
}) => {
  if (!webAddress) return null;
  let url = webAddress;
  if (!webAddress.startsWith("http") && !webAddress.startsWith("https")) {
    url = `https://${webAddress}`;
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        overflow: "hidden"
      }}
    >
      <StyledIcon name="link" />
      <TextOverflow>
        <a
          style={{
            lineHeight: "auto",
            wordBreak: "break-word"
          }}
          target="_blank"
          rel="noopener noreferrer"
          href={url}
        >
          {webAddress}
        </a>
      </TextOverflow>
    </div>
  );
};

export const PhoneNumber = ({
  phoneNumber
}: {
  phoneNumber: string | undefined;
}) => {
  if (!phoneNumber) return null;
  return (
    <div style={{ display: "flex", alignItems: "center", overflow: "hidden" }}>
      <StyledIcon name="phone" />
      <a
        style={{
          lineHeight: "auto",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
        href={`tel:${encodeURIComponent(phoneNumber)}`}
      >
        {formatPhoneNumber(phoneNumber)}
      </a>
    </div>
  );
};

const VendorProducts = ({ vendor }: { vendor: IVendorDtoV1Response }) => {
  if (vendor.products.length === 0) return null;
  const sortedProducts = sortBy(vendor.products, p => p.product?.code);
  return (
    <div>
      <h5 style={{ marginTop: "0" }}>
        {strings.contactManagement.vendorDetails.scopesHeading}
      </h5>
      {sortedProducts.map(dto => (
        <Pill>
          <strong>{dto.product?.code}</strong>
        </Pill>
      ))}
    </div>
  );
};

export const VendorLocationAddress = ({
  address
}: {
  address: IAddress | undefined;
}) => {
  const displayCityStateZip = (address: IAddress) => {
    if (
      !address?.city &&
      !address?.stateProvince &&
      !address?.postalCode &&
      !address?.countryRegion
    ) {
      return null;
    }

    return (
      <div>
        {address.city ?? ""}
        {address.city && (address.stateProvince || address.postalCode) && ", "}
        {address.stateProvince ?? ""}
        {address.postalCode && ` ${address.postalCode}`}
        {address.countryRegion &&
          (address.city || address.stateProvince || address.postalCode) &&
          ", "}
        {address.countryRegion && `${address.countryRegion}`}
      </div>
    );
  };

  if (
    !address?.addressLine1 &&
    !address?.addressLine2 &&
    !address?.city &&
    !address?.stateProvince &&
    !address?.postalCode &&
    !address?.countryRegion
  ) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
      }}
    >
      <div>
        <StyledIcon name="compass" />
      </div>
      <a
        href={`http://maps.google.com/?q=${encodeURIComponent(
          `${address.addressLine1} ${address.addressLine2} ${address.city} ${address.stateProvince} ${address.postalCode} ${address.countryRegion}`
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div>{address.addressLine1}</div>
        {address.addressLine2 && <div>{address.addressLine2}</div>}
        {displayCityStateZip(address)}
      </a>
    </div>
  );
};

const StyledIcon = styled(Icon)`
  margin-right: 1rem;
  font-size: 1.5rem;
  width: 1em;
`;

const Pill = styled.span<{ fillPercentage?: number }>`
  display: inline-block;
  padding: 0 0.7rem;
  margin: 0 0.5rem 0.5rem 0;
  border-radius: 2rem;
  border-color: ${props => props.color || props.theme.colors.primary};
  background: linear-gradient(
    to right,
    ${ConcreteColors.blue150}
      ${props =>
        typeof props.fillPercentage === "number"
          ? props.fillPercentage + "%"
          : "100%"},
    ${ConcreteColors.gray200}
      ${props => (typeof props.fillPercentage === "number" ? "0%" : "100%")}
  );
  color: ${ConcreteColors.blue400};
`;

const CompanyType = styled.span`
  font-style: italic;
`;
