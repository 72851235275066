import styled from "styled-components";
import { ConcreteColors } from "hcss-components";

export const HeaderContainer = styled.div`
  padding-bottom: 26px;
  padding-left: 12px;
  padding-top: 8px;
`;

export const ItemContainer = styled.div`
  font-size: 14px;
  color: ${ConcreteColors.gray600};
`;

export const ModuleTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${ConcreteColors.gray600};
`;
