import React from "react";
import styled from "styled-components";
import ConfirmDelete from "core/components/modals/ConfirmDelete";
import { Calendar } from "api";
import { WithId } from "core";
import { useState } from "react";
import { strings } from "localization";

import {
  Button,
  ConcreteColors,
  Icon,
  MenuItem,
  DropdownButton
} from "hcss-components";

const { red400, blue400, gray600 } = ConcreteColors;

export interface ActiveOptionsButtonProps {
  templateId: string;
  readonlyCalendar?: WithId<Calendar>;
  nylasCalendar?: WithId<Calendar>;
  onDelete: (calendarId: string) => void;
  onCreateReadonlyCalendar: (viewId: string) => void;
  onCreateNylasLink: (viewId: string) => void;
}

export const ActiveOptionsButton: React.FC<ActiveOptionsButtonProps> = ({
  templateId,
  readonlyCalendar,
  nylasCalendar,
  onDelete,
  onCreateReadonlyCalendar,
  onCreateNylasLink
}) => {
  const [showReadonlyDeleteModal, setShowReadonlyDeleteModal] = useState(false);
  const [showNylasDeleteModal, setShowNylasDeleteModal] = useState(false);
  const [showGenerateReadonlyModal, setShowGenerateReadonlyModal] = useState(
    false
  );
  const readonlyLinkExists = readonlyCalendar !== undefined;
  const nylaslinkExists = nylasCalendar !== undefined;
  // @ts-ignore
  return (
    <>
      <OptionsButton
        title={strings.calendars.card.options}
        id="bg-nested-dropdown"
        inverted
        pullRight
      >
        {!nylaslinkExists && (
          <MenuItem onClick={() => onCreateNylasLink(templateId)}>
            {strings.calendars.card.createNylasLinkk}
          </MenuItem>
        )}
        {nylaslinkExists && (
          <MenuItem onClick={() => setShowNylasDeleteModal(true)}>
            {strings.calendars.card.deleteNylasLink}
          </MenuItem>
        )}
        {!readonlyLinkExists && (
          <MenuItem onClick={() => onCreateReadonlyCalendar(templateId)}>
            {strings.calendars.card.createReadonlyLink}
          </MenuItem>
        )}
        {readonlyLinkExists && (
          <MenuItem onClick={() => setShowReadonlyDeleteModal(true)}>
            {strings.calendars.card.deleteReadonlyLink}
          </MenuItem>
        )}
      </OptionsButton>
      {readonlyCalendar && (
        <ConfirmDelete
          show={showReadonlyDeleteModal}
          handleClose={() => setShowReadonlyDeleteModal(false)}
          title={strings.calendars.card.deleteTitle}
          style={{ zIndex: 9999 }}
          footer={
            <div>
              <Button
                onClick={() => {
                  onDelete(readonlyCalendar.id);
                  setShowReadonlyDeleteModal(false);
                }}
                hcssStyle="Theme"
              >
                <Icon name="trash-o" />
                &nbsp;{strings.calendars.card.continue}
              </Button>
              <Button
                onClick={() => setShowReadonlyDeleteModal(false)}
                hcssStyle="ThemeInverted"
              >
                <Icon name="times" />
                &nbsp;{strings.calendars.card.cancel}
              </Button>
            </div>
          }
        >
          <p>{strings.calendars.card.deleteReadonlyConfirmation}</p>
        </ConfirmDelete>
      )}
      {nylasCalendar && (
        <ConfirmDelete
          show={showNylasDeleteModal}
          handleClose={() => setShowNylasDeleteModal(false)}
          title={strings.calendars.card.deleteTitle}
          style={{ zIndex: 9999 }}
          footer={
            <div>
              <Button
                onClick={() => {
                  onDelete(nylasCalendar.id);
                  setShowNylasDeleteModal(false);
                }}
                hcssStyle="Theme"
              >
                <Icon name="trash-o" />
                &nbsp;{strings.calendars.card.continue}
              </Button>
              <Button
                onClick={() => setShowNylasDeleteModal(false)}
                hcssStyle="ThemeInverted"
              >
                <Icon name="times" />
                &nbsp;{strings.calendars.card.cancel}
              </Button>
            </div>
          }
        >
          <p>{strings.calendars.card.deleteHcssConnectConfirmation}</p>
        </ConfirmDelete>
      )}
      <ConfirmDelete
        show={showGenerateReadonlyModal}
        handleClose={() => setShowGenerateReadonlyModal(false)}
        title={strings.calendars.card.createLink}
        style={{ zIndex: 9999 }}
        footer={
          <div>
            <Button
              onClick={() => {
                onCreateReadonlyCalendar(templateId);
                setShowGenerateReadonlyModal(false);
              }}
              hcssStyle="Theme"
            >
              <Icon name="trash-o" />
              &nbsp;{strings.calendars.card.continue}
            </Button>
            <Button
              onClick={() => setShowGenerateReadonlyModal(false)}
              hcssStyle="ThemeInverted"
            >
              <Icon name="times" />
              &nbsp;{strings.calendars.card.cancel}
            </Button>
          </div>
        }
      >
        <p>{strings.calendars.card.deleteReadonlyWarning}</p>
        <p>{strings.calendars.card.deleteNewReadonlyLink}</p>
      </ConfirmDelete>
    </>
  );
};

const OptionsButton = styled(DropdownButton)`
  font-size: 1.15rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.06rem;
  box-shadow: none;
  padding: 5px 20px;
  color: ${gray600};

  &:hover:not(:disabled) {
    box-shadow: none;
  }
`;
