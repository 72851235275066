import React from "react";
import styled from "styled-components";
import { strings } from "localization";
import { ConcreteColors, Button, Icon } from "hcss-components";
import { useOidcContext } from "modules/account/services/oidc-context";
import { InfoCard, InfoCardProps } from "./info-card";
import { Roadblock } from "./roadblock";

const addPreconAccessLink =
  "https://help.hcss.com/s/article/How-to-Add-Pre-Construction-Access-to-a-Subscription-Group";

export const Unauthorized = () => {
  const { oidcService } = useOidcContext();

  // HACK: Required to be here for translations.
  const cardData: InfoCardProps[] = [
    {
      icon: ["far", "box"],
      title: strings.userAdmin.unauthorized.sandbox.title,
      body: strings.userAdmin.unauthorized.sandbox.body,
      help: strings.userAdmin.unauthorized.sandbox.help,
      helpLocation: "/?sandbox=true",
      helpLinkTestId: "try-sanbox-button"
    },
    {
      icon: ["far", "smile"],
      title: strings.userAdmin.unauthorized.supportPortal.title,
      body: strings.userAdmin.unauthorized.supportPortal.body,
      help: strings.userAdmin.unauthorized.supportPortal.help,
      helpLocation: "https://support.hcss.com/"
    }
  ];

  return (
    <PageWrapper data-testid="unauthorized-page">
      <Jumbotron>
        <ContentWrapper>
          <Blurb>
            <StyledSiteTitle>
              <Icon
                name="product-heavybid"
                style={{
                  marginRight: "0.5em",
                  color: "rgb(0, 150, 57)",
                  fontSize: "35px"
                }}
              />
              <span style={{ fontSize: "35px" }} className={"heavyBidClr"}>
                Heavy<b style={{ fontFamily: "ProximaNova Heavy" }}>Bid</b> |
                Pre-Construction
              </span>
            </StyledSiteTitle>
            <h3>{strings.userAdmin.unauthorized.jumbotron.title}</h3>
            <br />
            <p>
              <b>{strings.userAdmin.unauthorized.jumbotron.heavyBid}</b>
              {strings.userAdmin.unauthorized.jumbotron.text1}
            </p>
            <ul>
              <li>
                <b>{strings.userAdmin.unauthorized.projectTracking.title} </b>
                {strings.userAdmin.unauthorized.jumbotron.listItem1}
              </li>
              <li>
                <b>{strings.userAdmin.unauthorized.heavybidInsights.title} </b>
                {strings.userAdmin.unauthorized.jumbotron.listItem2}
              </li>
              <li>
                {strings.userAdmin.unauthorized.jumbotron.listItem3}
                <b>{strings.userAdmin.unauthorized.contactManagement.title} </b>
              </li>
            </ul>
            <br />
            <p>
              {strings.userAdmin.unauthorized.jumbotron.text2}
              <a
                href={addPreconAccessLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {strings.userAdmin.unauthorized.jumbotron.text3}
              </a>
              {strings.userAdmin.unauthorized.jumbotron.text4}
            </p>
            <br />
            <p>{strings.userAdmin.unauthorized.jumbotron.text5}</p>
            <div>
              <Button
                data-testid="unauthorized-page-logout-button"
                onClick={_ => oidcService.signoutRedirect()}
              >
                <Icon name="power-off" margin="right" />
                {strings.userAdmin.unauthorized.jumbotron.logout}
              </Button>
            </div>
          </Blurb>
          <ImageContainer>
            <div style={{ height: "100%", width: "90%" }}>
              <Roadblock />
            </div>
          </ImageContainer>
        </ContentWrapper>
      </Jumbotron>

      <CardContainer>
        {cardData.map(d => (
          <InfoCard key={d.title} {...d} />
        ))}
      </CardContainer>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: white; */
`;

const Jumbotron = styled.div`
  display: flex;
  margin: 70px 15% 60px 15%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

const Blurb = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
  margin-top: 30px;
  margin-bottom: 40px;
  padding-right: 0px;

  h1 {
    color: ${ConcreteColors.gray700};
    font-size: 38px;
    font-weight: 700;
    line-height: 1.075;
    margin-bottom: 10px;
    width: 100%;
  }

  p {
    color: ${ConcreteColors.gray600};
    font-size: 18px;
    font-weight: 400;
  }

  div > button {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    font-size: small;
    margin-top: 30px;
    padding-top: 8px;
    padding-bottom: 6px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 37%;
  flex-direction: row-reverse;

  @media (max-width: 300px) {
    display: none;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 20px 100px;
`;

const StyledSiteTitle = styled.span`
  font-size: 18px;
  font-family: "ProximaNova";
  display: flex;
  align-items: center;

  .heavyBidClr {
    color: hsla(150, 95%, 26%, 1);
  }

  .headerLogo {
    width: 20%;
  }

  .heavyBidClr:hover {
    color: hsla(150, 95%, 22%, 1);
  }

  .heavyBidClr:focus {
    color: hsla(150, 95%, 22%, 1);
  }

  a {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    text-decoration-line: none;
  }

  a:hover,
  a:focus {
    color: inherit;
  }

  & strong {
    font-family: "ProximaNova Heavy";
  }

  & .darktext {
    color: #444;
  }
`;
