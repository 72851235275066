import React from "react";
import {
  GroupingPanel as DGroupingPanel,
  GroupingPanelProps
} from "@devexpress/dx-react-grid-bootstrap3";
import styled from "styled-components";
import { Dropdown, MenuItem } from "hcss-components";
import moment from "moment";
import { capitalize } from "lodash";
import {
  DataColumnType,
  useTableContext,
  VirtualDateColumn
} from "hcss-tables";

export const LegacyGroupingPanel = (props: GroupingPanelProps) => {
  return <DGroupingPanel {...props} itemComponent={GroupingPanelItem} />;
};

export const GroupingPanelItem = ({
  showSortingControls,
  showGroupingControls,
  sortingDirection,
  onSort,
  onGroup,
  item,
  ...other
}) => {
  const column = item.column as VirtualDateColumn;
  return (
    <ButtonGroup className="btn-group" ref={other.forwardedRef}>
      <span
        className="btn btn-default"
        onClick={() => {
          if (showSortingControls) {
            onSort({
              direction: sortingDirection !== "asc" ? "asc" : "desc"
            });
          }
        }}
      >
        {item.column.title}
        {showSortingControls && sortingDirection && (
          <span>
            &nbsp;
            <ButtonIcon
              className={`glyphicon glyphicon-arrow-${
                sortingDirection === "asc" ? "up" : "down"
              }`}
            />
          </span>
        )}
      </span>
      {(column.type === DataColumnType.DateTime ||
        column.type === DataColumnType.Date) && (
        <Dropdown id={`group-${column.name}`}>
          <Dropdown.Toggle noCaret>
            <ButtonIcon className="glyphicon glyphicon-calendar" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <DateOptions
              column={column}
              activeItem={column.config?.dateGrouping}
            />
          </Dropdown.Menu>
        </Dropdown>
      )}
      {showGroupingControls && (
        <span
          style={{ fontSize: "14px" }}
          className="btn btn-default"
          onClick={() => onGroup()}
        >
          <ButtonIcon className="glyphicon glyphicon-remove" />
        </span>
      )}
    </ButtonGroup>
  );
};

const DateOptions = ({
  activeItem,
  column,
  keys = ["year", "quarter", "month", "week", "day"]
}: {
  activeItem?: moment.unitOfTime.StartOf;
  column: VirtualDateColumn;
  keys?: moment.unitOfTime.StartOf[];
}) => {
  const {
    grouping: { setGrouping },
    extendedGroups
  } = useTableContext();

  const updateDateType = (interval: moment.unitOfTime.StartOf) => {
    const existingGroupIndex = extendedGroups.findIndex(
      group => group.columnName === column.name
    );
    const newGroup = {
      ...extendedGroups[existingGroupIndex],
      config: { dateGrouping: interval }
    };
    const newGroups = [...extendedGroups];
    newGroups.splice(existingGroupIndex, 1, newGroup);
    setGrouping(newGroups);
  };

  return (
    <>
      {keys.map(key => (
        <MenuItem
          key={key as string}
          active={key === activeItem}
          onClick={() => updateDateType(key)}
        >
          {capitalize(key as string)}
        </MenuItem>
      ))}
    </>
  );
};

const ButtonGroup = styled.div`
  margin-right: 5px;
  margin-bottom: 5px;
`;

const ButtonIcon = styled.i`
  top: 0px;
  font-size: 9px;
`;
