import appconfig from "../config";
import getDefaultInstance from "./AxiosInstance";
import { ContactsClient } from "./GeneratedClients/ContactsClient";

const baseUrl: string = appconfig.endpoints.CONTACTS;

export default class ContactsApi extends ContactsClient {
  constructor(token: string, selectedBusinessUnitId: string) {
    const instance = getDefaultInstance(token);
    instance.interceptors.request.use(config => {
      config.headers["X-Requested-Business-Unit-Id"] = selectedBusinessUnitId;
      return config;
    });

    super(baseUrl, instance);
  }
}
