import { Checkbox } from "hcss-components";
import * as React from "react";
import styled from "styled-components";
import Modal from "core/components/modals";
import { SchemaField } from "api";
import { strings } from "localization";
import { constantSections } from "modules/projects";
import { useUserAndCompanyLicense } from "modules/account";

export interface FiltersSelectProps {
  show: boolean;
  onHide: () => void;
  selection: { [key: string]: boolean };
  onChange: (newFilters: { [key: string]: boolean }) => void;
  fieldsBySection: Array<{
    name: string;
    id: string;
    fields: SchemaField[];
  }>;
  activeFilters: string[];
}

export const FilterSelect: React.FunctionComponent<FiltersSelectProps> = ({
  fieldsBySection,
  show,
  onHide,
  selection,
  onChange,
  activeFilters
}) => {
  const { companyLicense } = useUserAndCompanyLicense();

  return (
    <Modal
      style={{ wordWrap: "break-word" }}
      handleClose={onHide}
      show={show}
      columns={{ columns: 3, width: "200px" }}
      title={strings.projects.list.filters.select}
    >
      <div data-testid="filterchooser-modal">
        <ul>
          {fieldsBySection.map(section => {
            const filteredSections = constantSections.filter(
              s => s !== "operations" && s !== "quickPrice"
            );
            if (filteredSections.includes(section.id)) return null;
            if (section.id === "operations")
              section.name = strings.projects.heavyJob.title;
            const isHJSectionInLimitedCompany =
              companyLicense.isLimitedLicense && section.id === "operations";
            if (section.id === "quickPrice")
              section.name = strings.projects.quickPricing.title;
            const isQPSectionInLimitedCompany =
              companyLicense.isLimitedLicense && section.id === "quickPrice";
            return (
              <div
                key={section.name}
                style={{
                  background:
                    isHJSectionInLimitedCompany || isQPSectionInLimitedCompany
                      ? "rgb(236, 236, 236)"
                      : "white",
                  padding: "3px",
                  borderRadius: "5px"
                }}
              >
                <SectionColumnList key={section.name}>
                  <SectionLabel>{section.name}</SectionLabel>
                  {isHJSectionInLimitedCompany && (
                    <div className="body" style={{ marginBottom: "10px" }}>
                      {strings.projects.heavyJob.limitedPreConDescription}
                    </div>
                  )}
                  {isQPSectionInLimitedCompany && (
                    <div className="body" style={{ marginBottom: "10px" }}>
                      {
                        strings.projects.quickPricing
                          .limitedDescriptionColumnChooser
                      }
                    </div>
                  )}
                  {section.fields.map(field => {
                    if (
                      section.id === "quickPrice" &&
                      field.name === "Total Cost"
                    ) {
                      return null; // Remove this check when we add the Total Cost to the Project List Tabel and Filters
                    }
                    return (
                      <div key={field.name}>
                        <BlockCheckbox
                          data-testid={`filter-checkbox-${field.name}`}
                          key={field.id}
                          checked={selection[field.id] ?? false}
                          onChange={e =>
                            onChange({
                              ...selection,
                              [field.id]: (e.target as HTMLInputElement).checked
                            })
                          }
                          disabled={
                            activeFilters.includes(field.id) ||
                            isHJSectionInLimitedCompany ||
                            isQPSectionInLimitedCompany
                          }
                        >
                          {field.name}
                        </BlockCheckbox>
                      </div>
                    );
                  })}
                </SectionColumnList>
              </div>
            );
          })}
        </ul>
      </div>
    </Modal>
  );
};

const BlockCheckbox = styled(Checkbox)`
  display: inline-block;
`;

const SectionColumnList = styled.div`
  break-inside: avoid;
`;

const SectionLabel = styled.label`
  margin-top: 16px;
`;
