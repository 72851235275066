import React from "react";
import styled from "styled-components";

export const CodebooksIcon = () => {
  return (
    <StyledIcon
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 44"
    >
      <g>
        <g>
          <g>
            <path
              className="st0"
              d="M8.44,34.7c0,1.28,1.04,2.31,2.31,2.31h20.78v-4.62H10.75C9.48,32.39,8.44,33.42,8.44,34.7z"
            />
          </g>
          <path
            className="st1"
            d="M37.3,28.93V6.99c0-1.91-1.55-3.46-3.46-3.46H10.75c-3.18,0-5.77,2.59-5.77,5.77v25.4
			c0,3.18,2.59,5.77,5.77,5.77h24.82c0.96,0,1.73-0.78,1.73-1.73s-0.78-1.73-1.73-1.73h-0.58v-4.83
			C36.33,31.7,37.3,30.43,37.3,28.93z M31.53,37.01H10.75c-1.27,0-2.31-1.03-2.31-2.31c0-1.28,1.04-2.31,2.31-2.31h20.78V37.01z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="st2"
            d="M31.84,17.61c0,0.42-0.11,0.66-0.31,0.87c-0.87,0.86-1.72,1.73-2.6,2.59c-0.38,0.37-0.76,0.74-1.13,1.11
			c-1.22,1.25-2.47,2.48-3.71,3.72c-0.84,0.84-1.68,1.69-2.53,2.53c-0.42,0.42-1.05,0.43-1.48,0c-1.02-1.01-2.03-2.03-3.05-3.05
			c-0.96-0.96-1.92-1.92-2.88-2.87c-0.55-0.54-1.09-1.09-1.63-1.63c-0.79-0.79-1.57-1.59-2.36-2.37c-0.51-0.51-0.41-1.19,0-1.59
			c1.1-1.07,2.18-2.17,3.27-3.26c0.97-0.97,1.93-1.94,2.9-2.91c1.14-1.14,2.28-2.28,3.43-3.42c0.13-0.13,0.25-0.25,0.38-0.37
			c0.17-0.15,0.35-0.28,0.6-0.22c0.11,0.03,0.21-0.06,0.34-0.02c0.19,0.07,0.37,0.15,0.51,0.3c0.9,0.91,1.81,1.82,2.72,2.72
			c1.15,1.14,2.29,2.29,3.44,3.43c1.06,1.06,2.12,2.12,3.17,3.18c0.21,0.21,0.41,0.42,0.62,0.62c0.21,0.2,0.3,0.45,0.3,0.61V17.61z
			 M20.83,28.49c0.23-0.02,0.42-0.11,0.58-0.28c0.89-0.9,1.79-1.79,2.68-2.69c2.41-2.41,4.82-4.82,7.23-7.22
			c0.35-0.35,0.35-0.8,0-1.15c-0.92-0.93-1.85-1.85-2.77-2.78c-2.37-2.37-4.74-4.74-7.11-7.11c-0.4-0.4-0.83-0.4-1.23,0
			c-1.47,1.47-2.93,2.93-4.4,4.4c-1.83,1.83-3.67,3.67-5.5,5.5c-0.33,0.33-0.33,0.78-0.01,1.11c0.2,0.21,0.41,0.42,0.62,0.62
			c1.89,1.88,3.77,3.77,5.66,5.65c1.23,1.23,2.46,2.46,3.68,3.69C20.42,28.39,20.61,28.46,20.83,28.49L20.83,28.49z"
          />
          <path
            className="st2"
            d="M20.83,28.5c-0.22-0.01-0.42-0.09-0.58-0.25c-1.23-1.23-2.45-2.46-3.68-3.69c-1.89-1.89-3.77-3.77-5.66-5.65
			c-0.21-0.21-0.42-0.41-0.62-0.62c-0.32-0.34-0.32-0.78,0.01-1.11c1.83-1.83,3.67-3.67,5.5-5.5c1.47-1.47,2.93-2.93,4.4-4.4
			c0.4-0.4,0.83-0.4,1.23,0c2.37,2.37,4.74,4.74,7.11,7.11c0.93,0.93,1.85,1.85,2.77,2.78c0.35,0.35,0.35,0.8,0,1.15
			c-2.41,2.41-4.82,4.82-7.23,7.22c-0.9,0.89-1.79,1.79-2.68,2.69C21.24,28.39,21.05,28.49,20.83,28.5L20.83,28.5z M31.14,17.74
			c0-0.08-0.02-0.16-0.08-0.22c-0.09-0.09-0.18-0.18-0.27-0.27c-1.43-1.44-2.87-2.87-4.3-4.31c-1.33-1.33-2.65-2.65-3.98-3.98
			c-0.47-0.47-0.93-0.93-1.4-1.4c-0.2-0.2-0.37-0.2-0.57,0c-0.04,0.04-0.09,0.09-0.13,0.13c-2.9,2.9-5.79,5.8-8.69,8.69
			c-0.36,0.36-0.72,0.72-1.08,1.08c-0.2,0.2-0.19,0.37,0,0.57c0.04,0.04,0.09,0.09,0.13,0.13c0.92,0.92,1.84,1.84,2.76,2.76
			c1.15,1.15,2.3,2.3,3.45,3.45c1.17,1.17,2.34,2.33,3.5,3.5c0.26,0.26,0.42,0.27,0.68,0c2.13-2.13,4.26-4.25,6.38-6.38
			c1.14-1.14,2.28-2.28,3.43-3.43c0.09-0.09,0.19-0.18,0.18-0.33L31.14,17.74z"
          />
          <path
            className="st2"
            d="M31.14,17.73c0,0.15-0.09,0.23-0.18,0.33c-1.14,1.14-2.28,2.28-3.43,3.43c-2.13,2.13-4.26,4.25-6.38,6.38
			c-0.26,0.26-0.42,0.26-0.68,0c-1.17-1.17-2.33-2.34-3.5-3.5c-1.15-1.15-2.3-2.3-3.45-3.45c-0.92-0.92-1.84-1.84-2.76-2.76
			c-0.04-0.04-0.09-0.09-0.13-0.13c-0.19-0.2-0.2-0.37,0-0.57c0.36-0.36,0.72-0.72,1.08-1.08c2.9-2.9,5.79-5.8,8.69-8.69
			c0.04-0.04,0.09-0.09,0.13-0.13c0.21-0.19,0.37-0.19,0.57,0c0.47,0.47,0.93,0.93,1.4,1.4c1.33,1.33,2.66,2.65,3.98,3.98
			c1.44,1.44,2.87,2.87,4.3,4.31c0.09,0.09,0.18,0.18,0.27,0.27c0.06,0.06,0.08,0.13,0.08,0.22L31.14,17.73z M22.26,19.58
			c0.12,0.5,0.23,0.95,0.34,1.4c0.24,0.95,0.48,1.91,0.7,2.86c0.11,0.46,0.1,0.45,0.57,0.45c0.13,0,0.17-0.03,0.16-0.17
			c-0.06-0.83-0.12-1.66-0.17-2.49c-0.12-1.82-0.23-3.64-0.35-5.45c-0.01-0.17,0.03-0.21,0.19-0.2c0.8,0.07,1.6,0.12,2.39,0.19
			c0.15,0.01,0.23-0.02,0.26-0.19c0.1-0.46,0.22-0.91,0.33-1.37c0.11-0.48,0.11-0.47-0.36-0.56c-0.12-0.02-0.17,0.01-0.21,0.13
			c-0.09,0.27-0.19,0.54-0.28,0.81c-0.03,0.1-0.07,0.12-0.17,0.1c-0.34-0.07-0.68-0.15-1.02-0.21c-0.48-0.08-0.95-0.23-1.44-0.23
			c-1.85,0-3.71,0-5.56,0c-0.31,0-0.47,0.16-0.46,0.48c0.01,0.48,0,0.02,0,0.04c0,0.09,0.03,0.13,0.12,0.15
			c0.13,0.02,0.27,0.04,0.4,0.07c0.97,0.19,1.93,0.38,2.9,0.56c0.16,0.03,0.21,0.08,0.21,0.24c0,1.12,0,2.24,0.02,3.36
			c0.02,1.43,0.02,2.86,0.03,4.29c0,0.57-0.03,0.42,0.45,0.44c0.11,0,0.16-0.04,0.18-0.15c0.19-1.15,0.38-2.29,0.57-3.44
			c0.06-0.36,0.1-0.72,0.2-1.13L22.26,19.58z M14.24,16.35c0,0.51,0,1.01,0,1.52c0,0.13,0.02,0.19,0.18,0.19c0.79,0,1.58,0,2.37,0
			c0.14,0,0.18-0.04,0.18-0.18c0-1.01,0-2.03,0-3.04c0-0.14-0.05-0.17-0.18-0.17c-0.79,0-1.58,0-2.37,0c-0.14,0-0.18,0.04-0.18,0.18
			C14.24,15.35,14.24,15.85,14.24,16.35z M20.66,12.98c0,0.74,0.58,1.34,1.31,1.34c0.74,0,1.34-0.58,1.34-1.31
			c0-0.74-0.58-1.34-1.32-1.34s-1.33,0.58-1.34,1.32L20.66,12.98z"
          />
          <g>
            <g>
              <path
                className="st0"
                d="M22.27,19.58c-0.1,0.41-0.14,0.77-0.2,1.13c-0.19,1.15-0.38,2.29-0.57,3.44c-0.02,0.12-0.06,0.16-0.18,0.15
					c-0.48-0.03-0.45,0.12-0.45-0.44c0-1.43,0-2.86-0.03-4.29c-0.02-1.12-0.02-2.24-0.02-3.36c0-0.16-0.05-0.21-0.21-0.24
					c-0.97-0.18-1.93-0.37-2.9-0.56c-0.13-0.03-0.26-0.05-0.4-0.07c-0.09-0.01-0.13-0.06-0.12-0.15c0-0.01,0-0.02,0-0.04
					c-0.01-0.32,0.15-0.48,0.46-0.48c1.85,0,3.71,0,5.56,0c0.5,0,0.96,0.16,1.44,0.23c0.34,0.06,0.68,0.13,1.02,0.21
					c0.1,0.02,0.14,0,0.17-0.1c0.09-0.27,0.19-0.54,0.28-0.81c0.04-0.12,0.09-0.15,0.21-0.13c0.47,0.09,0.47,0.09,0.36,0.56
					c-0.11,0.46-0.23,0.91-0.33,1.37c-0.04,0.17-0.11,0.2-0.26,0.19c-0.8-0.07-1.6-0.12-2.39-0.19c-0.17-0.01-0.21,0.03-0.19,0.2
					c0.12,1.82,0.23,3.64,0.35,5.45c0.05,0.83,0.11,1.66,0.17,2.49c0,0.13-0.03,0.17-0.16,0.17c-0.47,0-0.46,0-0.57-0.45
					c-0.23-0.96-0.47-1.91-0.7-2.86c-0.11-0.45-0.22-0.9-0.34-1.4L22.27,19.58z"
              />
            </g>
            <g>
              <path
                className="st0"
                d="M14.25,16.36c0-0.5,0-1,0-1.5c0-0.14,0.04-0.18,0.18-0.18c0.79,0,1.58,0,2.37,0c0.13,0,0.18,0.03,0.18,0.17
					c0,1.01,0,2.03,0,3.04c0,0.13-0.04,0.18-0.18,0.18c-0.79,0-1.58,0-2.37,0c-0.16,0-0.18-0.06-0.18-0.19
					C14.25,17.37,14.25,16.87,14.25,16.36z"
              />
            </g>
            <g>
              <path
                className="st0"
                d="M20.67,12.99c0-0.73,0.6-1.32,1.34-1.32c0.73,0,1.32,0.6,1.32,1.34c0,0.73-0.6,1.31-1.34,1.31
					c-0.73,0-1.31-0.59-1.31-1.34L20.67,12.99z"
              />
            </g>
          </g>
        </g>
      </g>
    </StyledIcon>
  );
};

const StyledIcon = styled.svg`
  .st0 {
    fill: #ffffff;
  }
  .st1 {
    fill: #d4d4d4;
  }
  .st2 {
    fill: #14af80;
  }
`;
