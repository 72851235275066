import React, { useEffect, useState, ReactElement } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../state";
import {
  Button,
  ConcreteColors,
  Icon,
  Link,
  OverlayTrigger,
  Popover
} from "hcss-components";
import { Box } from "hcss-core";
import moment from "moment";
import { strings } from "../../../localization";
import { ModalPanel } from "../../../core/components/modals/SideModal";
import {
  Badge,
  ContentLoadingOverlay,
  TextOverflow
} from "../../../core/components";
import ConfirmDelete from "../../../core/components/modals/ConfirmDelete";
import {
  useHcssConnectContext,
  UsableAccount
} from "core/services/hcss-connect";
import { selectors as calendarSelectors } from "modules/calendars";

const { gray600 } = ConcreteColors;

export const HcssConnectConfigurationPanel = () => {
  const {
    nylasAccounts,
    loading: calendarLoading,
    deleteNylasAccount,
    nylasCalendars,
    connectAccount
  } = useHcssConnectContext();
  const dispatch = useDispatch();
  const calendarsLoaded = useSelector(calendarSelectors.getLoaded);
  const loading = calendarLoading && !calendarsLoaded;
  const isActive = useSelector(selectors.getCalendarPanelActive);
  const redirectQueryArg = "kloudlessRedirect=true";
  const calendarsByAccountId = useSelector(
    calendarSelectors.getCalendarsByAccountId
  );

  const onHide = () => dispatch(actions.toggleCalendarPanel());

  useEffect(() => {
    if (window.location.href.includes(redirectQueryArg)) {
      dispatch(actions.toggleCalendarPanel(true));
    }
  }, [dispatch]);

  return (
    <div>
      <StyledPanel
        className="email-settings-panel"
        isActive={isActive}
        onHide={onHide}
      >
        <ModalPanel.Header onHide={onHide} panelTitle={<ModalHeader />} />
        <ModalPanel.Body>
          <TitleLabel>
            <NewAccountButton onClick={connectAccount}>
              {strings.hcssConnect.subscribePanel.connectAccountButton}
            </NewAccountButton>
            <br />
            {nylasAccounts.length > 0 && (
              <div style={{ marginTop: "6px" }}>
                {strings.hcssConnect.subscribePanel.linkedAccounts}
              </div>
            )}
          </TitleLabel>
          {!loading && (
            <StyledDivWrapper>
              {nylasAccounts
                .sort(account => (account.isBad ? 1 : -1))
                .map(account => {
                  const accountCalendars = calendarsByAccountId[account.id];
                  return (
                    <CalendarAccountCard
                      key={account.id}
                      account={account}
                      onDelete={deleteNylasAccount}
                      hasExistingCalendars={
                        // need to handle the situation where a calendar was deleted outlook side
                        accountCalendars?.filter(c =>
                          nylasCalendars?.find(nc => nc.id === c.calendarId)
                        ).length > 0
                      }
                      isBad={account.isBad}
                    />
                  );
                })}
            </StyledDivWrapper>
          )}
          {loading && <ContentLoadingOverlay />}
        </ModalPanel.Body>
      </StyledPanel>
    </div>
  );
};

const ModalHeader = () => {
  return (
    <div>
      <div>{strings.hcssConnect.subscribePanel.title}</div>
      <Badge type="info" style={{ marginTop: "8px", padding: "4px 8px" }}>
        {strings.featureStatus.beta}
      </Badge>
    </div>
  );
};

interface CalendarAccountCardProps {
  account: UsableAccount;
  onDelete: (accountId: string) => void;
  isBad?: boolean;
  hasExistingCalendars?: boolean;
}

export interface CalendarAccountCardWrapperProps {
  hasExistingCalendars?: boolean;
  children: ReactElement;
}

const CalendarAccountCardWrapper = ({
  hasExistingCalendars,
  children
}: CalendarAccountCardWrapperProps) => {
  if (!hasExistingCalendars) return children;
  return (
    <OverlayTrigger
      trigger="hover"
      placement="left"
      overlay={
        <Popover
          title="Account Error"
          style={{ minWidth: "400px", zIndex: 10000 }}
        >
          <div
            style={{
              zIndex: 10000,
              display: "flex",
              flexDirection: "column"
            }}
          >
            {strings.hcssConnect.card.deletePopover}
          </div>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

const CalendarAccountCard = ({
  account,
  onDelete,
  isBad,
  hasExistingCalendars
}: CalendarAccountCardProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const CardContainer = isBad
    ? BadLinkContainer
    : moment().diff(account.created, "minutes") <= 1
    ? NewLinkContainer
    : LinkContainer;

  return (
    <CardContainer>
      <LinkHeader>
        <label title={account.serviceName} className="template-wrap">
          {account.serviceName}
          {isBad && <HelpTrigger serviceName={account.serviceName} />}
        </label>
        <CalendarAccountCardWrapper hasExistingCalendars={hasExistingCalendars}>
          <Box p={1}>
            <DeleteButton
              title={strings.hcssConnect.card.delete}
              id="account-delete-btn"
              disabled={hasExistingCalendars}
              hcssStyle="DeleteInverted"
              onClick={() => {
                setShowDeleteModal(true);
              }}
            >
              <Icon name="trash-o" />
            </DeleteButton>
          </Box>
        </CalendarAccountCardWrapper>

        <ConfirmDelete
          show={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          title={strings.hcssConnect.card.deleteTitle}
          style={{ zIndex: 9999 }}
          footer={
            <div>
              <Button
                onClick={() => {
                  onDelete(account.id);
                  setShowDeleteModal(false);
                }}
                hcssStyle="Delete"
              >
                <Icon name="trash-o" />
                &nbsp;{strings.hcssConnect.card.continue}
              </Button>
              <Button
                onClick={() => setShowDeleteModal(false)}
                hcssStyle="ThemeInverted"
              >
                <Icon name="times" />
                &nbsp;{strings.hcssConnect.card.cancel}
              </Button>
            </div>
          }
        >
          <p>{strings.hcssConnect.card.deleteConfirmation}</p>
        </ConfirmDelete>
      </LinkHeader>
      <TextOverflow>{account.emailAddress}</TextOverflow>
    </CardContainer>
  );
};

const HelpTrigger = ({ serviceName }: { serviceName: string }) => {
  return (
    <OverlayTrigger
      trigger="hover"
      placement="left"
      overlay={
        <Popover
          title="Account Error"
          style={{ minWidth: "400px", zIndex: 10000 }}
        >
          <div
            style={{
              zIndex: 10000,
              display: "flex",
              flexDirection: "column"
            }}
          >
            {strings.formatString(
              strings.hcssConnect.card.helper.error,
              serviceName
            )}
          </div>
          <br />
          <span>{strings.hcssConnect.card.helper.recommendDelete}</span>
        </Popover>
      }
    >
      <Link style={{ marginLeft: "8px" }}>
        <Icon
          name="question-circle"
          style={{ fontSize: "1.2em", color: ConcreteColors.gray500 }}
        />
      </Link>
    </OverlayTrigger>
  );
};

const StyledPanel = styled(ModalPanel)`
  z-index: 2002;

  .modal-panel-content {
    .modal-panel-body {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      overflow: hidden;
      padding: 0;

      .panel-instructions {
        position: relative;
        margin: 10px 0;
        padding: 0 20px;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 22px;
        color: #1e1e1e;
      }
    }
  }
`;

const TitleLabel = styled.label`
  padding-left: 30px;
  margin-top: 16px;

  .warning {
    padding-left: 0px;
  }
`;
const StyledDivWrapper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;
const NewAccountButton = styled(Link)`
  background: none;
  border: none;
  padding: 0;
  color: #069;
  cursor: pointer;
`;
const LinkContainer = styled.div`
  padding: 24px;
  margin: 20px 12px;
  background-color: #f6f6f6;
  border-radius: 12px;
`;

const NewLinkContainer = styled.div`
  padding: 24px;
  margin: 20px 12px;
  background-color: #e0ffe0;
  border-radius: 12px;
`;

const BadLinkContainer = styled.div`
  padding: 24px;
  margin: 20px 12px;
  background-color: #f8d7da;
  border-radius: 12px;
`;

const LinkHeader = styled.div`
  .template-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const DeleteButton = styled(Button)`
  font-size: 1.15rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.06rem;
  box-shadow: none;
  padding: 5px 20px;
  color: ${gray600};

  &:hover:not(:disabled) {
    box-shadow: none;
  }
`;
