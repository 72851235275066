import React from "react";
import { Calendar } from "api";
import { Badge, WithId } from "core";
import { InputGroup } from "hcss-components";
import styled from "styled-components";
import { NylasSelect } from "./NylasSelect";
import { strings } from "../../../localization";
import { NylasCalendar } from "core/services/hcss-connect/models/NylasCalendar";

export interface NylasCalendarContentProps {
  viewId: string;
  nylasCalendar?: WithId<Calendar>;
  nylasCalendars: NylasCalendar[];
}

export const NylasCalendarContent: React.FC<NylasCalendarContentProps> = ({
  viewId,
  nylasCalendar,
  nylasCalendars
}) => {
  return (
    <>
      <ContentWrapper>
        <div className="fieldTitle">
          {strings.calendars.card.nylasLinkLabel}
          <Badge
            type="info"
            style={{ alignSelf: "center", padding: "4px 8px" }}
          >
            {strings.featureStatus.beta}
          </Badge>
        </div>
        <InputGroup style={{ width: "100%" }}>
          <NylasSelect
            viewId={viewId}
            calendars={nylasCalendars}
            selectedCalendar={nylasCalendar}
          />
        </InputGroup>
      </ContentWrapper>
    </>
  );
};

const ContentWrapper = styled.div`
  margin: 5px 0 10px 5px;

  div.fieldTitle {
    display: flex;
    justify-content: space-between;
    margin: 5px 0 5px 0;
    font-weight: bold;
    font-size: 14px;
  }
`;
