import axios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import { WithId } from "core";
import { notify } from "hcss-components";
import { QueryOptions } from ".";

export default class RestApi<T> {
  baseUrl = "/api";
  instance: AxiosInstance;

  constructor(path: string, token: string) {
    this.baseUrl = this.baseUrl + path;
    this.instance = axios.create();

    this.instance.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });

    this.instance.interceptors.response.use(
      response => response,
      error => {
        if (!error.constructor || error.constructor.name !== "Cancel") {
          console.error(error);
        }

        if (error.response && error.response.status === 401) {
          notify(
            "danger",
            "Something went wrong. Please reload this page and try again.",
            "",
            0
          );
        }

        return Promise.reject(error);
      }
    );
  }

  get(path = "", options?: QueryOptions): AxiosPromise<PagedResults<T>> {
    return this.instance.get(`${this.baseUrl}/${path}`, {
      params: formatOptions(options)
    });
  }

  getOne(id: string): AxiosPromise<T> {
    return this.instance.get(`${this.baseUrl}/${id}`);
  }

  create(item: Partial<T>): AxiosPromise<T> {
    return this.instance.post(`${this.baseUrl}/`, item);
  }

  createRange(
    item: Partial<T>,
    skipInvalidFields: boolean,
    options: AxiosRequestConfig = {}
  ): AxiosPromise<T> {
    return this.instance.post(
      `${this.baseUrl}/range?skipInvalidFields=${skipInvalidFields}`,
      item,
      options
    );
  }

  validateRange<TResponse, TEntry>(
    item: TEntry,
    options: AxiosRequestConfig = {}
  ): AxiosPromise<TResponse> {
    return this.instance.post(`${this.baseUrl}/validate-range`, item, options);
  }

  update(item: WithId<Partial<T>>): AxiosPromise<T> {
    return this.instance.put(`${this.baseUrl}/${item.id}`, item);
  }

  delete(id: string): AxiosPromise<string> {
    return this.instance.delete(`${this.baseUrl}/${id}`);
  }

  deleteRange(ids: string[]): AxiosPromise<string> {
    return this.instance.delete(`${this.baseUrl}/range`, { data: ids });
  }

  archive(id: string): AxiosPromise<string> {
    return this.instance.patch(`${this.baseUrl}/archive/${id}`);
  }

  archiveRange(ids: string[]): AxiosPromise<string> {
    return this.instance.patch(`${this.baseUrl}/archive-range`, { data: ids });
  }
}

function formatOptions(options?: QueryOptions) {
  if (options) {
    const { continuationToken, top, ...other } = options;
    return {
      continuationToken,
      top,
      "orderBy.ascending": other.orderBy ? other.orderBy.ascending : undefined,
      "orderBy.fieldId": other.orderBy ? other.orderBy.fieldId : undefined
    };
  }
}

export interface PagedResults<T> {
  results?: T[] | undefined;
  nextPageToken?: string | undefined;
  hasNextPage?: boolean;
}
