import constate from "constate";
import { useMemo, useState, useEffect } from "react";
import { Estimate } from "api/GeneratedClients/insights";
import { useRouteMatch } from "react-router-dom";
import { selectors } from "../state";
import { useSelector } from "react-redux";
import { WithId } from "core";

export interface CurrentEstimateContext {
  currentEstimateId?: string;
  setCurrentEstimateId: (id: string | undefined) => void;
  currentEstimate?: WithId<Estimate>;
  existsInBUError: boolean;
  loading: boolean;
}
const useCurrentEstimate = (): CurrentEstimateContext => {
  const estimatePathMatch = useRouteMatch<{ estimateId: string }>({
    path: "/estimates/:estimateId"
  });
  const quotePathMatch = !!useRouteMatch({
    path: "/quotes/solicitations",
    exact: true
  });

  const quoteMasterPathMatch = !!useRouteMatch({
    path: "/quotes/solicitations/master",
    exact: false
  });

  const quoteDraftsPathMatch = !!useRouteMatch({
    path: "/quotes/solicitations/drafts",
    exact: true
  });

  const idFromParams = estimatePathMatch
    ? estimatePathMatch.params.estimateId
    : undefined;
  const [currentEstimateId, setCurrentEstimateId] = useState<
    string | undefined
  >(idFromParams);

  useEffect(() => {
    //if we navigate to an estimate in the estimate module, then we want to use that estimate
    if (idFromParams) setCurrentEstimateId(idFromParams);
    //if we navigate to the quotes module, we want to initally use no estimate
    else if (quotePathMatch || quoteMasterPathMatch || quoteDraftsPathMatch)
      setCurrentEstimateId(undefined);
  }, [
    idFromParams,
    quoteMasterPathMatch,
    quotePathMatch,
    quoteDraftsPathMatch
  ]);

  const loading = !useSelector(selectors.getLoaded);
  const workingCopy = useSelector(selectors.getWorkingCopy);
  const existsInBUError = !!idFromParams && !workingCopy[idFromParams];

  const currentEstimate = useMemo(() => {
    return !loading && currentEstimateId && workingCopy[currentEstimateId]
      ? (workingCopy[currentEstimateId].values as WithId<Estimate>)
      : undefined;
  }, [loading, currentEstimateId, workingCopy]);

  return {
    currentEstimateId: currentEstimate?.id,
    setCurrentEstimateId,
    currentEstimate,
    existsInBUError,
    loading
  };
};

const [provider, context] = constate(useCurrentEstimate);
export const CurrentEstimateProvider = provider;
export const useCurrentEstimateContext = context;
