import { useEffect } from "react";

export function useDebouncedEffect(
  fn: React.EffectCallback,
  deps: React.DependencyList | undefined,
  delay: number
) {
  useEffect(() => {
    let destructor: ReturnType<React.EffectCallback>;

    const handler = setTimeout(() => {
      destructor = fn();
    }, delay);

    return () => {
      clearTimeout(handler);

      if (destructor?.call) {
        destructor();
      }
    };
  }, [delay, ...(deps ?? [])]);
}
