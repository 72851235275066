import React from "react";
import styled from "styled-components";
import { ConcreteColors } from "hcss-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface InfoCardProps {
  icon: IconProp;
  title: string;
  body: string;
  help: string;
  helpLocation: string;
  helpLinkTestId?: string;
}
export const InfoCard = ({
  icon,
  title,
  body,
  help,
  helpLocation,
  helpLinkTestId
}: InfoCardProps) => {
  return (
    <Card>
      <div className="card-info-header">
        <FontAwesomeIcon icon={icon} size="2x" />
        <span>{title}</span>
      </div>

      <div className="card-info-body">
        <p>{body}</p>
      </div>

      <div className="card-info-footer">
        <a data-testid={helpLinkTestId} href={helpLocation}>
          {help}&nbsp;&nbsp;
          <FontAwesomeIcon icon={["far", "long-arrow-right"]} size="lg" />
        </a>
      </div>
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  min-height: 220px;
  min-width: 35%;
  max-width: 35%;
  padding: 20px;
  margin: 40px 25px 0px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  background: white;
  border-radius: 10px;
  border: 0.5px solid #fafafa;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  .card-info-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${ConcreteColors.gray700};

    .fa-2x {
      color: ${ConcreteColors.gray600};
    }

    span {
      margin-left: 12px;
      font-size: 17px;
      font-weight: 600;
    }
  }

  .card-info-body {
    display: flex;
    flex-direction: row;
    margin-top: 15px;

    p {
      font-size: 15px;
      font-weight: 400;
      color: ${ConcreteColors.gray500};
    }
  }

  .card-info-footer {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: flex-end;

    a {
      font-weight: 500;
      font-size: 15px;
      color: ${ConcreteColors.blue200};
      text-decoration-line: none;
      transition: 0.3s;

      :hover {
        font-size: 16px;
      }
    }
  }
`;
