import React from "react";
import { NavigationControllerProvider } from "../contexts/navigation-controller-context";
import {
  LayoutManager,
  LayoutManagerProps
} from "../components/layout-manager";

type HcssNavigationProps = LayoutManagerProps;

export const HcssNavigation = (props: HcssNavigationProps) => {
  return (
    <NavigationControllerProvider>
      <LayoutManager {...props} />
    </NavigationControllerProvider>
  );
};
