import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { SchemaFieldType } from "api";
import { IMinorityTypeDto } from "api/GeneratedClients/ContactsClient";
import { colorbrewer } from "colorConfigurations";
import config from "config";
import {
  DateTimePicker,
  TextOverflow,
  formatCurrency,
  formatLatLong,
  formatPercent,
  formatPhoneNumber,
  isValidString
} from "core";
import { statesObjectList as states } from "core/util/states";
import { ConcreteColors, Creatable, Icon, notify } from "hcss-components";
import {
  BaseTypedDataColumn,
  BooleanEditor,
  DataColumnType,
  DateContainer,
  DateTimeContainer,
  DropDownFormatter,
  NumberFormatter,
  TextEditor,
  TypedDataColumn,
  normalizeDate,
  useDebouncedGridValue,
  useTableContext
} from "hcss-tables";
import { strings } from "localization";
import { intersection, some, sortBy } from "lodash-es";
import { usePermissions } from "modules/account";
import { selectors as ContactsSelectors } from "modules/contacts";
import { CreateMinorityTypeModal } from "modules/contacts/components/create-minority-type-modal";
import { formatMinorityStatusPercent } from "modules/contacts/components/minority-status-list";
import { preconIdToString, useProjectFieldsMetadata } from "modules/projects";
import {
  CompanyDropdownFilter,
  CompanyListOption,
  CompanyOption
} from "modules/schemas/components/fields/CompanyInput";
import {
  ContactDropdownFilter,
  ContactListOption,
  ContactOption
} from "modules/schemas/components/fields/ContactInput";
import { PreConIdInputBase } from "modules/schemas/components/fields/project-id-input";
import moment from "moment";
import numeral from "numeral";
import {
  FC,
  default as React,
  memo,
  useCallback,
  useMemo,
  useState
} from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MaskedInput from "react-text-mask";
import { DropdownList } from "react-widgets";
import styled from "styled-components";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { DropDownEditor } from "./single-select-list-editor";
const DateTimeEditorContainer = styled.div`
  & .rw-widget-picker {
    height: 34px;
  }
  & .rw-cell.rw-state-selected {
    color: black !important;
  }
`;

const DateEditor = (props: DataTypeProvider.ValueEditorProps) => {
  const { value, onValueChange } = props;
  const column = props.column as TypedDataColumn;
  const includeTime = column.type === DataColumnType.DateTime;
  const dateValue = value ? moment(value).toDate() : undefined;

  return (
    <DateTimeEditorContainer>
      <DateTimePicker
        time={includeTime}
        value={dateValue}
        disabled={column.readOnly}
        readOnly={column.readOnly}
        onChange={d => onValueChange(d && d.toISOString())}
      />
    </DateTimeEditorContainer>
  );
};

export const DateFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  const { value } = props;
  const column = props.column as BaseTypedDataColumn<DataColumnType.DateTime>;
  if (value) {
    const dateValue = normalizeDate(
      value,
      column.config && column.config.utc,
      column.config && column.config.ignoreTimezone
    );

    if (dateValue.isValid()) {
      return (
        <DateTimeContainer>
          <DateContainer>
            <div>{dateValue.format("L")}</div>
          </DateContainer>
          {column.type === DataColumnType.DateTime && (
            <div>{dateValue.format("LT")}</div>
          )}
        </DateTimeContainer>
      );
    }
  }

  return null;
};

export const DateFormatProvider = () => {
  const { columns } = useTableContext();

  return React.useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={DateFormatter}
        editorComponent={props => LockableWrapper(DateEditor, props)}
        for={columns
          .filter(
            column =>
              column.type === DataColumnType.Date ||
              column.type === DataColumnType.DateTime
          )
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};

const NoteFormatter = ({
  value,
  column
}: DataTypeProvider.ValueFormatterProps) => {
  const popover = (
    <Popover id="note-popover-trigger-hover" title={column.title}>
      <TextOverflow style={{ wordBreak: "break-word" }}>{value}</TextOverflow>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="bottom"
      overlay={popover}
    >
      <div style={{ maxWidth: "fit-content" }}>
        <TextOverflow>{value}</TextOverflow>
      </div>
    </OverlayTrigger>
  );
};
export const NoteProvider = () => {
  const { columns } = useTableContext();

  return React.useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={NoteFormatter}
        for={columns
          .filter(column => column.type === DataColumnType.LongText)
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};
const CurrencyFormatter: FC<DataTypeProvider.ValueFormatterProps> = ({
  value
}) => {
  if (value || value === 0) {
    return <span>{formatCurrency(value as number, 2)}</span>;
  } else {
    return <span>-</span>;
  }
};

export const CurrencyFormatProvider = () => {
  const { columns } = useTableContext();
  return useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={CurrencyFormatter}
        for={columns
          .filter(column => column.type === DataColumnType.Currency)
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};

const PhoneNumberFormatter: FC<DataTypeProvider.ValueFormatterProps> = ({
  value
}) => {
  return <span>{formatPhoneNumber(value)}</span>;
};

export const PhoneNumberFormatProvider = () => {
  const { columns } = useTableContext();
  return useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={PhoneNumberFormatter}
        for={columns
          .filter(column => column.type === DataColumnType.PhoneNumber)
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};

const PercentageFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  const { value } = props;
  const column = props.column as BaseTypedDataColumn<DataColumnType.Number>;
  if (column.config && (value || value === 0)) {
    return <span>{formatPercent(value)}</span>;
  }
  return <span />;
};

export const PercentageProvider = () => {
  const { columns } = useTableContext();
  return useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={PercentageFormatter}
        for={columns
          .filter(
            column =>
              column.config?.showAsPercent &&
              (column.type === DataColumnType.Number ||
                column.type === DataColumnType.Currency)
          )
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};

const DetailLinkFormatter = ({
  column,
  row,
  value
}: DataTypeProvider.ValueFormatterProps) => {
  const { coloring, columns } = useTableContext();

  if (!row) {
    return value;
  }

  const typedColumn = column as TypedDataColumn;
  if (typedColumn && typedColumn.config && typedColumn.config.getCellRoute) {
    return <Link to={typedColumn.config.getCellRoute(row)}>{value}</Link>;
  }

  if (!coloring) {
    return <Link to={"/projects/" + row.id}>{value}</Link>;
  }

  const columnUConfig = columns.filter(
    x => x.name === coloring.coloringColumnName
  )[0];
  if (columnUConfig && columnUConfig.type === DataColumnType.States) {
    const stateIndex = states.findIndex(
      x => x.value === row.fields[columnUConfig.name]
    );
    if (-1 !== stateIndex) {
      return getProjectLinkWithHighLight(stateIndex, row, value);
    }
  } else if (
    columnUConfig &&
    columnUConfig.config &&
    columnUConfig.config.listValues &&
    Object.keys(columnUConfig.config.listValues).length > 0
  ) {
    let listValues = [];
    if (columnUConfig.config.customSort) {
      listValues = columnUConfig.config.listValues;
    } else {
      listValues = sortBy(columnUConfig.config.listValues, opt =>
        opt.display.toLocaleLowerCase()
      );
    }
    const index: number = listValues.findIndex(
      (lisVal: { value: string }) =>
        lisVal.value === row.fields[columnUConfig.name]
    );
    if (-1 !== index) {
      return getProjectLinkWithHighLight(index, row, value);
    }
  }

  return <Link to={"/projects/" + row.id}>{value}</Link>;
};

export const LinkProvider = () => {
  const { columns } = useTableContext();
  return useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={DetailLinkFormatter}
        for={columns
          .filter(column => column.config && column.config.link)
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};

function getProjectLinkWithHighLight(
  index: number,
  row: { id: string },
  value: string
) {
  const randCol = getHighlightColor(index);
  return (
    <Link
      style={{ padding: "4px", borderBottom: "4px solid " + randCol }}
      to={"/projects/" + row.id}
    >
      {value}
    </Link>
  );
}

export const getHighlightColor = (index: number) => {
  const arrColorCodes = colorbrewer.Set3[12];
  const reminder =
    index >= arrColorCodes.length ? index % arrColorCodes.length : index;
  const finIndex = reminder > 0 ? Math.abs(reminder) : reminder;
  return arrColorCodes[finIndex];
};

const HyperLinksFormatter = ({
  value
}: DataTypeProvider.ValueFormatterProps) => {
  if (!value || value === "") {
    return <div />;
  }
  return (
    <a
      href={value.indexOf("://") === -1 ? "https://" + value : value}
      target="_blank"
      rel="noopener noreferrer"
    >
      {value}
    </a>
  );
};

export interface HyperLinksProviderProps {
  overrideColumns?: string[];
}

export const HyperLinksProvider = ({
  overrideColumns
}: HyperLinksProviderProps) => {
  const { columns } = useTableContext();
  return useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={HyperLinksFormatter}
        for={
          overrideColumns ??
          columns
            .filter(column => column.type === DataColumnType.Links)
            .map(column => column.name)
        }
      />
    ),
    [overrideColumns, columns]
  );
};

const FenixLinkFormatter = ({
  row,
  value
}: DataTypeProvider.ValueFormatterProps) => {
  const url = `${config.endpoints.FENIX}/estimates/${row.id}/pricing/proposal`;
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {value}
    </a>
  );
};

export const FenixLinksProvider = () => {
  const { columns } = useTableContext();
  return useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={FenixLinkFormatter}
        for={columns
          .filter(column => column.config && column.config.link)
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};

export const LatLongFormatter = ({
  value
}: DataTypeProvider.ValueFormatterProps) => {
  if (typeof value === "string") return <div>{value}</div>;
  return <div>{formatLatLong(value)}</div>;
};

export const LatLongEditor = ({
  value,
  onValueChange,
  column
}: DataTypeProvider.ValueEditorProps) => {
  const [inputValue, setInputValue] = useState(() => formatLatLong(value));
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
      onValueChange(e.target.value);
    },
    [onValueChange]
  );

  const permissions = usePermissions();
  //@ts-ignore
  const adminFailure = !permissions.admin && column.adminOnly;
  const fieldIsEditable = permissions.write && !adminFailure;

  return (
    <input
      value={inputValue}
      onChange={handleChange}
      type="text"
      className="form-control"
      disabled={!fieldIsEditable}
    />
  );
};

const LatLongLockableComponent = (props: DataTypeProvider.ValueEditorProps) =>
  LockableWrapper(LatLongEditor, props);
export const LatLongProvider = () => {
  const { columns } = useTableContext();

  return (
    <DataTypeProvider
      for={columns
        .filter(column => column?.config?.["latLong"])
        .map(column => column.name)}
      formatterComponent={LatLongFormatter}
      editorComponent={LatLongLockableComponent}
    />
  );
};

export const PreConIdFormatter = ({
  value
}: DataTypeProvider.ValueFormatterProps) => (
  <div>{preconIdToString(value)}</div>
);

export const PreConIdEditor = (props: DataTypeProvider.ValueEditorProps) => {
  const { columns } = useTableContext();
  const { onValueChange, value: initialValue } = props;
  const [value, setValue] = useDebouncedGridValue(initialValue, onValueChange);
  const preconIdColumn = columns.find(col => col.id === "preconId");
  const isDisabled = preconIdColumn?.readOnly ?? false;

  return (
    <PreConIdInputBase
      value={value}
      onChange={setValue}
      disabled={isDisabled}
    />
  );
};

export const PreConIdProvider = () => {
  const { columns } = useTableContext();
  return useMemo(
    () => (
      <DataTypeProvider
        for={columns
          .filter(column => column?.id === "preconId")
          .map(column => column.name)}
        formatterComponent={PreConIdFormatter}
        editorComponent={props => LockableWrapper(PreConIdEditor, props)}
      />
    ),
    [columns]
  );
};

const CompanyFormatter = ({ value }: DataTypeProvider.ValueFormatterProps) => {
  return (
    <TextOverflow>
      {Array.isArray(value) ? value.map(v => v.code).join(", ") : value?.code}
    </TextOverflow>
  );
};

const ContactFormatter = ({ value }: DataTypeProvider.ValueFormatterProps) => {
  if (value === null || value === undefined) return <TextOverflow />;

  return (
    <TextOverflow>
      {Array.isArray(value)
        ? value
            .map(v => `${v?.firstName} ${v?.lastName} [${v.companyName}]`)
            .join(", ")
        : `${value?.firstName} ${value?.lastName} [${value.companyName}]`}
    </TextOverflow>
  );
};

const CompanyEditor = (props: DataTypeProvider.ValueEditorProps) => {
  const { value, onValueChange } = props;
  const column = props.column as BaseTypedDataColumn<DataColumnType.List>;
  const filters: string[] = column?.config?.filters;
  const vendorDataOptions = useSelector(ContactsSelectors.getVendorDataOptions);
  type MappedOption = Partial<CompanyListOption & { code: string }>;
  let options: MappedOption[] = vendorDataOptions.map(data => {
    return { display: data.code, value: data, code: data.code };
  });

  const companiesExist: boolean = options.length > 0;
  const filtersSelected: boolean = filters?.length > 0;

  if (filters) {
    options = options.filter(op => {
      return column?.config?.filters.includes(op.value?.type?.code);
    });
  }
  const handleChange = (nextValue: CompanyListOption) => {
    onValueChange(nextValue.value);
  };

  if (value) {
    options.push({ display: "(clear)", value: null });
  }
  return (
    <CompanyInputContainer>
      <DropdownList
        filter={(option, search) => CompanyDropdownFilter(option, search)}
        textField="display"
        valueField="code"
        value={
          Array.isArray(value) ? value.map(v => v.code).join(", ") : value?.code
        }
        data={options}
        onChange={handleChange}
        disabled={
          (column?.readOnly ?? false) || (column?.config?.multiSelect ?? false)
        }
        messages={{
          emptyList:
            companiesExist && filtersSelected
              ? strings.projects.setup.message.emptyCompanyListWithFilter
              : strings.projects.setup.message.emptyCompanyList
        }}
        itemComponent={({ item }) => <CompanyOption {...item.value} />}
      />
    </CompanyInputContainer>
  );
};

const ContactEditor = (props: DataTypeProvider.ValueEditorProps) => {
  const { value, onValueChange } = props;
  const column = props.column as BaseTypedDataColumn<DataColumnType.List>;
  const filters: string[] = column?.config?.filters;
  const vendorDataOptions = useSelector(
    ContactsSelectors.getVendorListSortedByCode
  );
  type MappedOption = Partial<ContactListOption & { id: string }>;

  let options: MappedOption[] = [];
  vendorDataOptions.forEach(vendor => {
    const contacts = vendor.contacts.map(contact => {
      const display =
        value?.id === contact.id
          ? `${value?.firstName} ${value?.lastName} [${value?.companyName}]`
          : `${contact.firstName} ${contact.lastName} [${vendor.name}]`;
      return {
        display: display,
        value: {
          id: contact.id,
          firstName: contact.firstName,
          lastName: contact.lastName,
          companyId: vendor.id,
          companyCode: vendor.code,
          companyName: vendor.name,
          email: contact.emailAddress,
          phoneNumber: contact.phoneNumber,
          products: contact.products
        },
        id: contact.id
      };
    });
    options = options.concat(contacts);
  });
  if (filters) {
    options = options.filter(op => {
      if (!op.value?.products || op.value.products.length === 0) return false;
      const contactProductIds = op.value?.products.map(p => p.productTypeId);
      return (
        intersection(column?.config?.filters, contactProductIds).length > 0
      );
    });
  }
  options = sortBy(options, [
    c => c.value?.companyCode?.toLowerCase(),
    c => c.value?.firstName?.toLowerCase(),
    c => c.value?.lastName?.toLowerCase()
  ]);

  const handleChange = (nextValue: ContactListOption) => {
    onValueChange(nextValue.value);
  };
  const getContactDropdownValue = (value: any) => {
    if (!value) return;
    if (Array.isArray(value)) {
      return value
        .map(v => `${v.firstName} ${v.lastName} [${v.companyName}]`)
        .join(", ");
    } else {
      if (some(options, op => op?.value?.id === value.id)) {
        return value.id;
      }
      return `${value.firstName} ${value.lastName} [${value.companyName}]`;
    }
  };
  if (value) {
    options.push({ display: "(clear)", value: null });
  }
  return (
    <ContactInputContainer>
      <DropdownList
        filter={(option, search) => ContactDropdownFilter(option, search)}
        textField="display"
        valueField="id"
        value={getContactDropdownValue(value)}
        data={options}
        onChange={handleChange}
        disabled={
          (column?.readOnly ?? false) || (column?.config?.multiSelect ?? false)
        }
        messages={{
          emptyList: strings.projects.setup.message.emptyContactList
        }}
        itemComponent={({ item }) => <ContactOption {...item.value} />}
      />
    </ContactInputContainer>
  );
};

export const CompanyProvider = () => {
  const { columns } = useTableContext();
  return useMemo(() => {
    return (
      <DataTypeProvider
        for={columns
          .filter(column => column?.config?.["company"])
          .map(column => column.name)}
        formatterComponent={CompanyFormatter}
        editorComponent={props => LockableWrapper(CompanyEditor, props)}
      />
    );
  }, [columns]);
};

export const ContactProvider = () => {
  const { columns } = useTableContext();
  return useMemo(() => {
    return (
      <DataTypeProvider
        for={columns
          .filter(column => column?.config?.["contact"])
          .map(column => column.name)}
        formatterComponent={ContactFormatter}
        editorComponent={props => LockableWrapper(ContactEditor, props)}
      />
    );
  }, [columns]);
};

const MinorityStatusFormatter = ({
  value
}: DataTypeProvider.ValueFormatterProps) => {
  return <TextOverflow>{value?.code}</TextOverflow>;
};

export interface IMinorityTypeOption {
  value: IMinorityTypeDto;
  label: string;
}

const MinorityStatusEditor = (props: DataTypeProvider.ValueEditorProps) => {
  const { value, onValueChange } = props;
  const minorityTypeOptions = useSelector(
    ContactsSelectors.getMinorityStatusOptions
  );
  const [
    showCreateMinorityTypeModal,
    setShowCreateMinorityTypeModal
  ] = useState<boolean>(false);
  const [prefillValue, setPrefillValue] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [mappedOptions, setMappedOptions] = useState<IMinorityTypeOption[]>(
    minorityTypeOptions.map(mt => ({
      value: mt.value,
      label: mt.display
    }))
  );
  const mappedValue = mappedOptions.find(mt => mt.label === value);

  const handleChange = (nextValue: {
    value: IMinorityTypeDto;
    label: string;
  }) => {
    onValueChange(nextValue.value);
  };

  const handleInputChange = (inputValue: any) => {
    if (inputValue === null || inputValue === undefined) return;
    setInputValue(inputValue);
  };

  const addMinorityType = (newMinorityType: IMinorityTypeOption) => {
    if (
      mappedOptions.map(o => o.value.code).includes(newMinorityType.value.code)
    ) {
      notify(
        "danger",
        `Minority type with code '${newMinorityType.value.code}' already exists.`
      );
      return;
    }
    const newOptions = mappedOptions;
    newOptions.push(newMinorityType);
    newOptions.sort((a, b) => a.label.localeCompare(b.label));
    setMappedOptions(newOptions);
    handleChange(newMinorityType);
  };

  const formatCreateLabel = (inputValue: string) =>
    isValidString(inputValue)
      ? `Create "${inputValue.toUpperCase()}"`
      : "To add a new option: type a value, press Enter";

  const isValidNewOption = (inputValue: string, options: any): boolean => {
    const optionLabels = options?.map((o: any) => o.label) ?? [];
    return !optionLabels.includes(inputValue.toUpperCase());
  };

  return (
    <>
      <CreateMinorityTypeModal
        visible={showCreateMinorityTypeModal}
        setVisible={setShowCreateMinorityTypeModal}
        prefillValue={prefillValue}
        addMinorityType={addMinorityType}
      />
      <StyledSelect
        classNamePrefix="react-select"
        className="react-select-container"
        onCreateOption={() => {
          setPrefillValue(inputValue);
          setShowCreateMinorityTypeModal(true);
        }}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={mappedOptions}
        value={mappedValue}
        inputValue={inputValue}
        isValidNewOption={isValidNewOption}
        formatCreateLabel={formatCreateLabel}
      />
    </>
  );
};

export const MinorityTypeProvider = () => {
  const { columns } = useTableContext();
  return useMemo(() => {
    return (
      <DataTypeProvider
        for={columns
          .filter(column => column?.config?.["minorityType"])
          .map(column => column.name)}
        formatterComponent={MinorityStatusFormatter}
        editorComponent={MinorityStatusEditor}
      />
    );
  }, [columns]);
};

const MinorityPercentEditor = (props: DataTypeProvider.ValueEditorProps) => {
  const { value, onValueChange } = props;

  const handleChange = (props: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange(props.target.value);
  };
  const handleBlur = () => {
    const formatted = formatMinorityStatusPercent(value);
    onValueChange(formatted);
  };
  return (
    <input
      type="text"
      className="form-control"
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
    />
  );
};

export const MinorityPercentProvider = () => {
  const { columns } = useTableContext();
  return useMemo(() => {
    return (
      <DataTypeProvider
        for={columns
          .filter(column => column?.name === "percent")
          .map(column => column.name)}
        editorComponent={MinorityPercentEditor}
      />
    );
  }, [columns]);
};

export const MinorityTypeCodeProvider = () => {
  const { columns } = useTableContext();
  return useMemo(() => {
    return (
      <DataTypeProvider
        for={columns
          .filter(column => column?.name === "code")
          .map(column => column.name)}
        formatterComponent={MinorityTypeCodeFormatter}
        editorComponent={MinorityTypeCodeEditor}
      />
    );
  }, [columns]);
};

const MinorityTypeCodeFormatter = ({
  value
}: DataTypeProvider.ValueFormatterProps) => {
  return <TextOverflow>{value}</TextOverflow>;
};

const MinorityTypeCodeEditor = (props: DataTypeProvider.ValueEditorProps) => {
  const { value, onValueChange } = props;

  const handleChange = (props: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange(props.target.value);
  };

  return (
    <input
      type="text"
      className="form-control"
      onChange={handleChange}
      value={value}
      style={{ textTransform: "uppercase" }}
    />
  );
};

export const PreConTextProvider = () => {
  const { columns } = useTableContext();
  return React.useMemo(
    () => (
      <DataTypeProvider
        editorComponent={props => LockableWrapper(TextEditor, props)}
        for={columns
          .filter(
            column =>
              column.type === DataColumnType.ShortText ||
              column.type === DataColumnType.LongText
          )
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};

const BooleanFormatter = ({ value }: DataTypeProvider.ValueFormatterProps) => {
  const checked = value === true ? true : false;
  return (
    <div style={{ textAlign: "center" }}>
      {checked && <Icon name="check" />}
    </div>
  );
};

export const PreConBooleanFormatProvider = () => {
  const { columns } = useTableContext();

  return useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={BooleanFormatter}
        editorComponent={props => LockableWrapper(BooleanEditor, props)}
        for={columns
          .filter(column => column.type === DataColumnType.Boolean)
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};

export const PreConDropDownFormatProvider = () => {
  const { columns } = useTableContext();

  return useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={DropDownFormatter}
        editorComponent={props => LockableWrapper(DropDownEditor, props)}
        for={columns
          .filter(
            column =>
              column.type === DataColumnType.List ||
              column.type === DataColumnType.States
          )
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};

export const NumericInputEditor = (
  props: DataTypeProvider.ValueEditorProps
) => {
  const { onValueChange, value, autoFocus, disabled } = props;
  const column = props.column as TypedDataColumn;
  const isPercentage = column.config?.showAsPercent;
  let valueState = value?.toString() || "";
  if (isPercentage && valueState != "") {
    valueState = formatPercent(value);
  }
  const [stringNumber, setStringNumber] = useState(valueState);
  const isCalculatedField =
    column.config?.preConType === SchemaFieldType.Calculated;

  if (isCalculatedField && !isFinite(value)) {
    return (
      <input
        value={stringNumber}
        type="text"
        className="form-control"
        readOnly
        disabled
        style={{ textAlign: "end" }}
      />
    );
  }

  const isCurrency = column.type === DataColumnType.Currency;
  const numberMask = createNumberMask({
    allowDecimal: true,
    allowNegative: true,
    prefix: isCurrency ? "$" : "",
    suffix: isPercentage ? "%" : "",
    decimalLimit: isCurrency || isPercentage ? 2 : 5,
    integerLimit: 10,
    allowLeadingZeroes: true
  });

  const handleChange = (e: any) => {
    const value = e.target.value;
    const numberValue = numeral(value).value();
    onValueChange(numberValue);
    setStringNumber(value);
  };
  const handleBlur = (e: any) => {
    const value = e.target.value;
    setStringNumber(value);
  };
  return (
    <MaskedInput
      disabled={disabled || column.readOnly}
      style={{ textAlign: "end" }}
      className="form-control"
      value={stringNumber}
      onChange={handleChange}
      onBlur={handleBlur}
      mask={numberMask}
      autoFocus={autoFocus}
    />
  );
};
export const extendedNumberFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  if (props.value === Infinity || props.value === -Infinity) {
    return <span style={{ float: "right" }}> {props.value} </span>;
  }

  return NumberFormatter(props);
};

export const PreConNumberFormatProvider = () => {
  const { columns } = useTableContext();

  return useMemo(
    () => (
      <DataTypeProvider
        formatterComponent={extendedNumberFormatter}
        editorComponent={props => LockableWrapper(NumericInputEditor, props)}
        for={columns
          .filter(
            column =>
              column.type === DataColumnType.Number ||
              column.type === DataColumnType.Currency
          )
          .map(column => column.name)}
      />
    ),
    [columns]
  );
};
const LockedPopover = memo(({ locked }: { locked: boolean }) => {
  if (locked) {
    return (
      <Popover id="note-popover-trigger-hover">
        <TextOverflow style={{ wordBreak: "break-word" }}>Locked</TextOverflow>
      </Popover>
    );
  }

  return <></>;
});

export const LockableWrapper = (
  FormatterComponent: React.FC<DataTypeProvider.ValueEditorProps>,
  props: DataTypeProvider.ValueEditorProps
) => {
  const { checkIfFieldIsLocked } = useProjectFieldsMetadata(props.row.id);
  const isFieldLocked = checkIfFieldIsLocked(props.column.name);
  const isDisabled =
    //@ts-ignore
    props.column.readOnly || props.disabled || isFieldLocked;
  const newColumn = { ...props.column };
  //@ts-ignore
  newColumn.readOnly = isDisabled;
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      delayShow={1000}
      placement="bottom"
      overlay={<LockedPopover locked={isFieldLocked} />}
    >
      <div style={{ maxWidth: "100%" }}>
        <FormatterComponent {...props} column={newColumn} />
      </div>
    </OverlayTrigger>
  );
};

export const CompanyInputContainer = styled.div`
  & .rw-widget-input {
    height: 34px;
  }
`;

export const ContactInputContainer = styled.div`
  & .rw-widget-input {
    height: 34px;
  }
`;

const StyledSelect = styled(Creatable)`
  .react-select__control {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    max-height: 34px !important;
    min-height: 34px !important;
  }

  .react-select__input {
    height: 15px;
  }

  .react-select__single-value {
    padding-bottom: 5px;
    padding-left: 7px;
  }
  .react-select__multi-value {
    background-color: ${ConcreteColors.gray200};
  }

  .react-select__value-container {
    padding: 2px;
  }
`;
