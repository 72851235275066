import config from "../../../config";

export interface Environment {
  oidc: {
    authority: string;
    client_id: string;
    redirect_uri: string;
    silent_redirect_uri: string;
    post_logout_redirect_uri: string;
    response_type: string;
    scope: string;
  };
  baseUrl: string;
  idsrvBaseUrl: string;
  firebaseBaseUrl: string;
}

class EnvService implements Environment {
  oidc: {
    authority: string;
    client_id: string;
    redirect_uri: string;
    silent_redirect_uri: string;
    post_logout_redirect_uri: string;
    response_type: string;
    scope: string;
  };
  baseUrl: string;
  firebaseBaseUrl: string;
  idsrvBaseUrl: string;

  constructor() {
    this.oidc = config.oidc;
    this.baseUrl = config.endpoints.PRECON;
    this.idsrvBaseUrl = config.oidc.authority;
    this.firebaseBaseUrl = config.firebaseConfig.baseURL;
  }
}

export const envService = new EnvService();
