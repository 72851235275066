import React, { ReactElement } from "react";
import styled from "styled-components";
import { ConcreteColors, Button, Icon } from "hcss-components";

interface EmptyStateProps {
  title: string;
  text1: string;
  text2?: string;
  showButton?: boolean;
  buttonIconName?: string;
  buttonIconTitle?: string;
  mainIcon?: ReactElement;
  onClick?: (event: any) => void;
}

export const EmptyState = ({
  title,
  text1,
  text2,
  buttonIconName = "arrow-right",
  buttonIconTitle,
  showButton,
  mainIcon: MainIcon,
  onClick
}: EmptyStateProps) => {
  return (
    <PageWrapper>
      <Jumbotron>
        <ContentWrapper data-testid="no-estimates-found-message">
          {MainIcon && (
            <ImageContainer style={defaultIconStyle}>{MainIcon}</ImageContainer>
          )}
          <Blurb>
            <h1>{title}</h1>
            <p>{text1}</p>
            {text2 && <p>{text2}</p>}
            {showButton && (
              <ButtonContainer>
                <Button style={{ alignItems: "center" }} onClick={onClick}>
                  {buttonIconTitle}&nbsp;
                  <Icon name={buttonIconName} margin="left" />
                </Button>
              </ButtonContainer>
            )}
          </Blurb>
        </ContentWrapper>
      </Jumbotron>
    </PageWrapper>
  );
};

export const defaultIconStyle = {
  "--fa-primary-color": ConcreteColors.green200,
  "--fa-secondary-color": "#7070708c"
} as React.CSSProperties;

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Jumbotron = styled.div`
  display: flex;
  width: 100%;
  margin: 70px auto 60px auto;
  max-width: 1100px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Blurb = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 30px;
  align-items: center;
  text-align: center;

  h1 {
    color: ${ConcreteColors.gray700};
    font-size: 18px;
    font-weight: 600;
    line-height: 1.075;
    margin-bottom: 10px;
  }

  p {
    color: ${ConcreteColors.gray600};
    font-size: 14px;
    font-weight: 400;
    max-width: 600px;
  }

  div > button {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    font-size: small;
    margin-top: 30px;
    padding-top: 8px;
    padding-bottom: 6px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div``;
