/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, Getter, Plugin } from "@devexpress/dx-react-core";
import moment from "moment";
import numeral from "numeral";
import * as React from "react";
import { SchemaFieldType } from "../../../api";
import { DataColumnType } from "hcss-tables";
import { statesObjectList as states } from "../../../core/util/states";
import { getHighlightColor } from "../columns/column-providers";
import { sortBy } from "lodash-es";
import { strings } from "localization";
import { formatCompanyField, formatContactField, formatLatLong } from "core";
import { preconIdToString } from "modules/projects";

export const ExportRows: React.FunctionComponent<{
  templateDisplayName: string;
}> = ({ templateDisplayName }) => {
  return (
    <Plugin>
      <Getter name="templateDisplayName" value={templateDisplayName} />
      <Action name="exportCSV" action={exportCSV} />
      <Action name="exportBidResultsCSV" action={exportBidResultsCSV} />
      <Action name="exportPDF" action={exportPDF} />
      <Action name="highlightProj" action={highlightProjects} />
    </Plugin>
  );
};

export default ExportRows;

export const exportPDF = async (
  { tableColumns, exportRows, getCellValue, columnUConfig }: any,
  { templateDisplayName }: any
) => {
  if (tableColumns === undefined) {
    return;
  }
  const dataBody = formatExportRows(
    tableColumns,
    exportRows,
    getCellValue,
    true
  );

  await import(/* webpackChunkName: "jspdf-autotable" */ "jspdf-autotable");
  const jspdf = await import(/* webpackChunkName: "jspdf" */ "jspdf");

  let options = {};

  const columns = tableColumns.filter((c: any) => {
    return c.column?.name?.indexOf?.("|VIRTUAL|") === -1;
  });

  if (columns.length >= 8) {
    options = {
      orientation: "l",
      unit: "mm",
      format: "a3", // a3, a4 (default), a5, letter, legal
      compress: true
    };
  } else {
    options = {
      orientation: "p",
      unit: "mm",
      format: "a4",
      compress: true
    };
  }

  const jsPDF = jspdf.default;
  const doc: any = new jsPDF(options);

  const totalPagesExp = "{total_pages_count_string}";

  const headers: any[] = [];
  const columnStyles: any = {};
  if (dataBody[0]) {
    Object.keys(dataBody[0]).forEach(headerName => {
      if (headerName !== "id") {
        const header = { dataKey: headerName, header: headerName };
        headers.push(header);

        const wrapCol = columns.find(
          (x: any) =>
            x.column !== undefined &&
            x.column !== null &&
            x.column.title === headerName &&
            x.width >= 250
        );
        if (wrapCol) {
          // Create columnStyles
          columnStyles[headerName] = { halign: "left", cellWidth: 40 };
        }

        const rightCol = columns.find(
          (x: any) =>
            x.column !== undefined &&
            x.column !== null &&
            x.column.title === headerName &&
            x.align === "right"
        );
        if (rightCol) {
          // Create columnStyles
          columnStyles[headerName] = { halign: "right" };
        }

        // center date & time colums data 'X' for true
        const centerCol = columns.find(
          (x: any) =>
            x.column !== undefined &&
            x.column !== null &&
            x.column.title === headerName &&
            x.column.type === SchemaFieldType.Boolean
        );
        if (centerCol) {
          // Create columnStyles
          columnStyles[headerName] = { halign: "center" };
        }
      }
    });
  }

  const headStyles = {
    font: "helvetica",
    fontStyle: "bold",
    overflow: "linebreak",
    fillColor: [255, 255, 255],
    textColor: [0, 0, 0],
    halign: "left",
    valign: "middle",
    fontSize: 11,
    cellPadding: 1.5,
    lineColor: [128, 128, 128],
    lineWidth: 0.1,
    cellWidth: "auto",
    minCellHeight: 12
  };

  const bodyStyles = {
    font: "helvetica",
    fontStyle: "normal",
    overflow: "linebreak",
    fillColor: [255, 255, 255],
    textColor: [0, 0, 0],
    halign: "left",
    valign: "middle",
    fontSize: 9,
    cellPadding: 1.5,
    lineColor: [221, 221, 221],
    lineWidth: 0.25,
    cellWidth: "auto",
    minCellHeight: 10
  };

  const alternateRowStyles = {
    font: "helvetica",
    fontStyle: "normal",
    overflow: "linebreak",
    fillColor: [255, 255, 255],
    textColor: [0, 0, 0],
    halign: "left",
    valign: "middle",
    fontSize: 9,
    cellPadding: 1.5,
    lineColor: [221, 221, 221],
    lineWidth: 0.25,
    cellWidth: "auto",
    minCellHeight: 10
  };

  const pdfObject = {
    columns: headers,
    body: dataBody,
    startY: 30,
    columnStyles,
    theme: "striped",
    pageBreak: "auto",
    tableWidth: "auto",
    showHead: "everyPage",
    showFoot: "everyPage",
    margin: { top: 30, left: 20, right: 20, bottom: 30 },
    bodyStyles,
    headStyles,
    alternateRowStyles,
    allSectionHooks: true,
    didDrawCell: (data: any) => {
      // First check Name column is visible in Table
      const dataColumns = columns.filter(
        (tableColumn: any) => "column" in tableColumn
      );
      const nameColumn = dataColumns
        .map((cell: any) => cell.column)
        .filter((col: any) => col.name === "name")[0];
      if (!nameColumn) {
        return;
      }

      if (
        data.column.dataKey === nameColumn.title &&
        data.row.section === "body"
      ) {
        if (
          !columnUConfig ||
          (columnUConfig.id !== "state" &&
            !columnUConfig.config &&
            !columnUConfig.config.listValues &&
            columnUConfig.config.listValues.length <= 0)
        ) {
          return;
        }

        let listValues = [];
        if (columnUConfig.type === DataColumnType.States) {
          listValues = states;
        } else if (columnUConfig.config.customSort) {
          listValues = columnUConfig.config.listValues;
        } else {
          listValues = sortBy(columnUConfig.config.listValues, (opt: any) =>
            opt.display.toLocaleLowerCase()
          );
        }
        const row = exportRows.filter(
          (row: any) => row.id === data.row.raw.id
        )[0];
        const rowVal = row.fields[columnUConfig.name];
        if (!rowVal) {
          return;
        }
        const index = listValues.findIndex(
          (lisVal: any) => lisVal.value === rowVal
        );
        if (index > -1) {
          const hexColor = getHighlightColor(index);
          const rgbColor: number[] = hexToRgb(hexColor);
          const maxY = data.cell.y + data.cell.height;
          doc.setDrawColor(...rgbColor);
          doc.setLineWidth(0.75);
          doc.line(
            data.cell.textPos.x,
            maxY - 2,
            data.cell.textPos.x + data.cell.wrappedWidth,
            maxY - 2,
            "F"
          );
        }
      }
    },
    didDrawPage: (data: any) => {
      // HEADER
      doc.setFontSize(14);
      doc.setTextColor(40);
      doc.setFontStyle("bold");
      const headerPageWidth = doc.internal.pageSize.getWidth() - 55;
      const options = { maxWidth: data.settings.margin.left + headerPageWidth };
      doc.text(templateDisplayName, data.settings.margin.left, 16, options); // set your margins

      // Footer
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.setFontStyle("normal");

      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();

      const currentPage = strings.formatString(
        strings.tables.export.pdf.currentPage,
        doc.internal.getNumberOfPages(),
        totalPagesExp
      );
      doc.text(currentPage, data.settings.margin.left, pageHeight - 18);
      doc.text(moment().format("L LT"), data.table.width - 20, pageHeight - 18);
    }
  };

  doc.autoTable(pdfObject);

  // Total page number plugin only available in jspdf v1.0+
  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }

  doc.save(
    strings.formatString(
      strings.tables.export.pdf.filename,
      templateDisplayName,
      new Date().toISOString()
    )
  );
};

export const exportCSV = async (
  { tableColumns, exportRows, getCellValue }: any,
  { templateDisplayName }: any
) => {
  if (tableColumns === undefined) {
    return;
  }
  const data = formatExportRows(tableColumns, exportRows, getCellValue);
  const Papa = await import(/* webpackChunkName: "papaparse" */ "papaparse");
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
  const FileSaver = await import(
    /* webpackChunkName: "file-saver" */ "file-saver"
  );
  FileSaver.saveAs(
    blob,
    strings.formatString(
      strings.tables.export.csv.filename,
      templateDisplayName,
      new Date().toISOString()
    ) as string
  );
};

export const exportBidResultsCSV = async (
  { tableColumns, exportRows, getCellValue }: any,
  { templateDisplayName }: any
) => {
  if (tableColumns === undefined) {
    return;
  }
  const data = formatExportBidResultsRows(exportRows, false);
  const Papa = await import(/* webpackChunkName: "papaparse" */ "papaparse");
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
  const FileSaver = await import(
    /* webpackChunkName: "file-saver" */ "file-saver"
  );
  FileSaver.saveAs(
    blob,
    strings.formatString(
      strings.tables.export.csv.filename,
      templateDisplayName,
      new Date().toISOString()
    ) as string
  );
};

export const highlightProjects = ({
  tableColumns,
  exportRows,
  getCellValue
}: any) => {
  if (tableColumns === undefined) {
    return;
  }
};

const formatNumber = (value: any) => {
  return value.toString().indexOf(".") !== -1
    ? numeral(value).format("0,0.00")
    : numeral(value).format("0,0.");
};

const formatPercent = (value: any) => {
  return value.toString().indexOf(".") !== -1
    ? numeral(value).format("0,0.00%")
    : numeral(value).format("0,0.%");
};

export const formatExportRows = (
  tableColumns: any,
  exportRows: any[],
  getCellValue: any,
  isPDFExport = false
) => {
  const dataColumns = tableColumns.filter((c: any) => {
    return c.column?.name?.indexOf?.("|VIRTUAL|") === -1;
  });

  return exportRows.map((row: any) => {
    const dataRow: any = isPDFExport ? { id: row.id } : {};
    dataColumns.forEach(({ column }: any) => {
      const keyExists = column.title in dataRow;
      const key = `${column.title}${keyExists ? " (2)" : ""}`;
      let value = getCellValue(row, column.name);
      if (column.type === SchemaFieldType.Date && value && value !== "") {
        const dateValue = moment(value);
        if (dateValue.isValid()) {
          value = isPDFExport
            ? dateValue.format("L")
            : dateValue.format("YYYY-MM-DD");
        }
      } else if (column.id === "preconId") {
        value = preconIdToString(value);
      } else if (
        column.type === SchemaFieldType.DateTime &&
        value &&
        value !== ""
      ) {
        const dateValue = moment(value);
        if (dateValue.isValid()) {
          value = isPDFExport
            ? dateValue.format("L LT")
            : dateValue.format("YYYY-MM-DD HH:mm");
        }
      } else if (isPDFExport && column.type === SchemaFieldType.Currency) {
        if (value || value === 0) {
          value = numeral(value).format(`$0,0.${"0".repeat(2)}`);
        }
      } else if (isPDFExport && column.type === SchemaFieldType.Number) {
        if (value && column?.config?.["showAsPercent"]) {
          value = formatPercent(value);
        } else if (value && isFinite(value)) {
          value = formatNumber(value);
        }
      } else if (column.type === SchemaFieldType.Boolean) {
        if (value && value !== "") {
          value = "X";
        } else {
          value = "";
        }
      } else if (column.type === SchemaFieldType.Links) {
        value = getLinks(value);
      } else if (column.type === SchemaFieldType.Checklist) {
        value = getCheckList(value);
      } else if (column?.config?.["latLong"]) {
        value = formatLatLong(value);
      } else if (column?.config?.["company"]) {
        value = formatCompanyField(value);
      } else if (column?.config?.["contact"]) {
        value = formatContactField(value);
      }
      dataRow[key] = value;
    });
    return dataRow;
  });
};

export const formatExportBidResultsRows = (
  exportRows: any[],
  isPDFExport = false
) => {
  const parsedRows: any[] = [];
  const winningValues: any = {};

  exportRows.forEach((row: any) => {
    row.fields?.bidResults?.forEach((bidResult: any) => {
      const result: any = isPDFExport ? { id: row.id } : {};
      result["Project Name"] = row.fields?.name;
      result["Winner?"] = bidResult.winner ? "Yes" : "No";
      if (bidResult.winner === true) {
        winningValues[result["Project Name"]] = bidResult.amount;
      }
      result["Company Name"] = bidResult.name;
      result["Amount"] = bidResult.amount;

      parsedRows.push(result);
    });
  });

  console.log(winningValues);

  parsedRows.forEach((row: any) => {
    const winningValue = winningValues[row["Project Name"]];
    if (winningValue !== null) {
      row["From Low Bid"] = formatNumber(row["Amount"] - winningValue);
    }
    row["Amount"] = formatNumber(row["Amount"]);
  });
  console.log(parsedRows);
  return parsedRows;
};

const getLinks = (value: any) => {
  let link = "";
  if (value && value.length > 0 && Object.keys(value).length > 0) {
    value.forEach((val: any) => {
      if (val.url) {
        link += val.url + "; ";
      }
    });
  }
  return link;
};

const getCheckList = (value: any) => {
  let checkedString = "";
  let unCheckedString = "";
  const totalChecked = value && value.filter((x: any) => x.checked).length > 1;
  const totalUnchecked =
    value && value.filter((x: any) => !x.checked).length > 1;
  if (value && value.length > 0 && Object.keys(value).length > 0) {
    value.forEach((val: any) => {
      if (val.checked) {
        checkedString += val.description;
        checkedString += totalChecked ? ", " : "";
      } else {
        unCheckedString += val.description;
        unCheckedString += totalUnchecked ? ", " : "";
      }
    });
  }

  if (checkedString !== "") {
    checkedString =
      `${strings.tables.export.checklist.true} - ` +
      (totalChecked
        ? checkedString.slice(0, checkedString.length - 2) + "; "
        : checkedString + "; ");
  }

  if (unCheckedString !== "") {
    checkedString +=
      `${strings.tables.export.checklist.false} - ` +
      (totalUnchecked
        ? unCheckedString.slice(0, unCheckedString.length - 2) + "; "
        : unCheckedString + "; ");
  }

  return checkedString;
};

function hexToRgb(hex: string): number[] {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : [255, 255, 255];
}
