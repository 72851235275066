import {
  IContactProduct,
  IContactDto
} from "api/GeneratedClients/ContactsClient";

const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

export const separateNewAndExistingProducts = (contactParams: IContactDto) => {
  const newProducts: IContactProduct[] = contactParams.products?.filter(
    product => product.productTypeId === EMPTY_GUID
  ) as IContactProduct[];
  const existingContactProducts = contactParams.products?.filter(
    product => product.productTypeId !== EMPTY_GUID
  ) as IContactProduct[];
  contactParams.products = existingContactProducts;
  return { originalContactParams: contactParams, newProducts: newProducts };
};
