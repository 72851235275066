import {
  EstimateEntity,
  JobDto,
  LocationDto,
  Project,
  SchemaField,
  SchemaFieldType
} from "api";
import {
  CredentialsUserDto,
  IdentityClientsDto
} from "api/GeneratedClients/precon";
import { CurrentUser, WithId } from "core";
import { get, meanBy, reduce, sortBy, sumBy } from "lodash-es";
import { LOCAL_PROJECT_ID } from "../components/NewProjectLink";

export const parsePreConId = (stringpreconId: string): PreConId | null => {
  const regex = /(.*?)(0*)(\d+)?$/g;
  const [_, prefix, leadingZeros, number] = [
    ...stringpreconId.matchAll(regex)
  ][0];

  if (!prefix && !number && !leadingZeros) {
    return null;
  }
  const parsedNumber = parseInt(number, 10);
  let numLeadingZeros = leadingZeros.length;
  let finalNumber = isNaN(parsedNumber) ? null : parsedNumber;
  //this could likely be done in regex. but id rather be explicit for now
  // If there are leading zeros, and number is undefined, make number 0, and subtract one from number of leading zeros
  if (numLeadingZeros > 0 && !finalNumber) {
    finalNumber = 0;
    numLeadingZeros = numLeadingZeros - 1;
  }
  const paddedNumberLength =
    numLeadingZeros + (finalNumber?.toString()?.length ?? 0);
  return {
    prefix: prefix ? prefix.toUpperCase() : null,
    number: finalNumber,
    paddedNumberLength,
    completedString: stringpreconId?.toUpperCase()
  };
};

export const preconIdToString = (preconId: PreConId | null): string => {
  if (!preconId) return "";
  return `${preconId.prefix?.toUpperCase() ?? ""}${(preconId.number ?? "")
    .toString()
    .padStart(preconId.paddedNumberLength, "0")}`;
};

export const trueUpCompletedProjectIdString = (preconId: PreConId) => {
  const completedString = preconIdToString(preconId);
  return { ...preconId, completedString };
};

export const sortPreConId = (a: PreConId | null, b: PreConId | null) => {
  if (!a) {
    if (!b) return 0;
    return -1;
  }
  if (!b) {
    return 1;
  }
  if (a && b) {
    if (!a.prefix && !b.prefix) return (a?.number ?? 0) - (b?.number ?? 0);
    if (a.prefix === b.prefix) return (a?.number ?? 0) - (b?.number ?? 0);
    if (!a.prefix && b.prefix) return -1;
    if (!b.prefix && a.prefix) return 1;
    if (a.prefix && b.prefix) {
      if (a.prefix < b.prefix) return -1;
      if (a.prefix > b.prefix) return 1;
    }
  }
  return 0;
};

export interface PreConId {
  prefix: string | null;
  paddedNumberLength: number;
  number: number | null;
  completedString: string;
}

export const validProjectName = (
  name: string,
  id?: string,
  projects?: Project[]
): boolean => {
  const formattedName = name.toLowerCase().trim();
  if (projects && formattedName.length > 0) {
    const existingProjectsWithName = projects.filter(
      project =>
        project.id !== LOCAL_PROJECT_ID &&
        project.fields.name.toLowerCase().trim() === formattedName
    );

    if (
      !existingProjectsWithName.length ||
      id === existingProjectsWithName[0].id
    ) {
      return true;
    }
  }

  return false;
};

export const linkableLocationId = (
  locationId: string,
  currentLocationId: string | undefined,
  projects: Project[]
): boolean => {
  const isSameLocationId = locationId === currentLocationId;
  const hasLinkedToAnotherProject = projects.some(
    project => project.locationId === locationId
  );

  const isLinkable = !isSameLocationId && !hasLinkedToAnotherProject;
  return isLinkable;
};

export const populateProjectWithEstimateValues = (
  project: Pick<Project, "fields">,
  estimateField: SchemaField,
  selectedEstimates: WithId<EstimateEntity>[]
) => {
  if (
    estimateField.type === SchemaFieldType.Number ||
    estimateField.type === SchemaFieldType.Currency
  ) {
    if (selectedEstimates.length > 0) {
      project.fields[estimateField.id] = estimateField.config?.showAsPercent
        ? meanBy(selectedEstimates, estimateField.id)
        : sumBy(selectedEstimates, estimateField.id);
    } else {
      project.fields[estimateField.id] = null;
    }
  } else if (
    estimateField.type === SchemaFieldType.ShortText ||
    estimateField.type === SchemaFieldType.List
  ) {
    project.fields[estimateField.id] = reduce(
      selectedEstimates,

      (combined, n) => {
        if (combined === "") {
          return `${get(n, estimateField.id)}`;
        }
        return `${combined}, ${get(n, estimateField.id)}`;
      },
      ""
    );
  }
};

export const populateHJJobFieldsWithValues = (
  project: WithId<Project>,
  jobs: (LocationDto | JobDto)[]
) => {
  const job = jobs?.find(j => j.id === project.fields["hJJob"]);
  if (project.fields["hJJob"] && !job) {
    project.fields["hJJob.Code"] = "----";
    project.fields["hJJob.Description"] = "----";
    return;
  }
  if (!job) {
    return;
  }
  project.fields["hJJob.Code"] = job.code;
  project.fields["hJJob.Description"] = job.description;
};

export const filterProjectFieldsBeforeSave = (projectFields: any) => {
  const fieldsToBeExcluded = [
    "values.code",
    "values.totals.totalCost_Takeoff",
    "values.totals.bidTotal_Bid",
    "values.totals.totalLabor_Total",
    "values.totals.permanentMaterial_Total",
    "values.totals.constructionMaterial_Total",
    "values.totals.subcontract_Total",
    "values.totals.totalEqp_Total",
    "values.totals.bond",
    "values.totals.addon_Total",
    "values.totals.manhours_Total",
    "values.totals.markupPercentCost_Takeoff",
    "values.totals.markupPercentCost_Bid",
    "values.totals.markupPercentSales_Takeoff",
    "values.totals.balMarkup_Bid",
    "values.totals.balMarkup_Takeoff",
    "values.totals.actualMarkup_Takeoff",
    "values.totals.actualMarkup_Bid",
    "values.totals.markupPercentSales_Bid",
    "hJJob.Code",
    "hJJob.Description"
  ];
  fieldsToBeExcluded.forEach(field => {
    delete projectFields[field];
  });
};
interface BidResult {
  winner?: boolean;
  amount: number;
  name: string;
}
interface WinningBid {
  company: string | null;
  amount: number | null;
}

export const getWinningBid = (row: { bidResults?: BidResult[] }) => {
  const rows = row["bidResults"];
  if (!rows) {
    return;
  }

  const result: WinningBid = { company: null, amount: null };
  const winningRow = rows.find((bidRows: BidResult) => bidRows.winner);
  if (winningRow) {
    result.company = winningRow.name;
    result.amount = winningRow.amount;
  }

  return result;
};

export const getDifferenceWinnerCellValue = (
  row: { bidResults?: BidResult[] },
  companyName: string
) => {
  const rows = row["bidResults"];
  if (!rows || rows.length <= 1) {
    return;
  }
  const winningRow = rows.find((bidRows: BidResult) => bidRows.winner);
  if (!winningRow || winningRow.name === companyName) {
    return;
  }

  const companyRow = rows.find(
    (bidRow: BidResult) => bidRow.name === companyName
  );

  if (!companyRow) {
    return;
  }
  const fromLowBid = companyRow.amount - winningRow.amount;
  const percentFromLowBid = fromLowBid / winningRow.amount;

  return { fromLowBid, percentFromLowBid };
};

export const getDifferenceFromSecondValue = (
  row: { bidResults?: BidResult[] },
  companyName: string
) => {
  const rows = row["bidResults"];
  if (!rows || rows.length <= 1) {
    return;
  }
  const sortedRows = sortBy(rows, "amount");
  const winningRowIndex = sortedRows.findIndex(bidRows => bidRows.winner);
  if (
    winningRowIndex === -1 ||
    sortedRows.length === winningRowIndex ||
    sortedRows[winningRowIndex].name !== companyName
  ) {
    return;
  }

  if (
    !sortedRows[winningRowIndex + 1] ||
    !sortedRows[winningRowIndex + 1].amount
  ) {
    return;
  }

  const winningAmount = sortedRows[winningRowIndex].amount;
  const secondPlaceAmount = sortedRows[winningRowIndex + 1].amount;

  const leftOnTable = secondPlaceAmount - winningAmount;
  const percentLeftOnTable = leftOnTable / winningAmount;
  return { leftOnTable, percentLeftOnTable };
};

export const populateProjectWithBidResults = (
  project: WithId<Project>,
  currentUser: Readonly<CurrentUser>
) => {
  const leftOnTableResult = getDifferenceFromSecondValue(
    project.fields,
    currentUser.companyName
  );
  const fromLowBidResult = getDifferenceWinnerCellValue(
    project.fields,
    currentUser.companyName
  );

  const winningBid = getWinningBid(project.fields);
  const fields = project.fields;
  fields.fromLowBid = fromLowBidResult?.fromLowBid;
  fields.percentFromLowBid = fromLowBidResult?.percentFromLowBid;
  fields.percentLeftOnTable = leftOnTableResult?.percentLeftOnTable;
  fields.leftOnTable = leftOnTableResult?.leftOnTable;
  fields.winningBidCompany = winningBid?.company;
  fields.winningBidAmount = winningBid?.amount;
};

export const getLastModifiedByName = (
  lastModifiedByUserId: string,
  user: CredentialsUserDto | undefined
) => {
  switch (lastModifiedByUserId) {
    case "heavyBid":
      return "HeavyBid";

    case "developerPortal":
      return "Developer Portal";

    case "hcssSupport":
      return "HCSS Support";

    default:
      return user ? `${user.firstName} ${user.lastName}` : "Deleted User";
  }
};

export const getLastModifiedByClientName = (
  lastModifiedByClientId: string,
  client: IdentityClientsDto | undefined
) => `Via API: ${client?.clientName ?? lastModifiedByClientId}`;

export const populateProjectWithLastModifiedBy = (
  project: WithId<Project>,
  users: Record<string, CredentialsUserDto>,
  clients: Record<string, IdentityClientsDto>
) => {
  if (project.lastModifiedBySystemUser && project.lastModifiedByClientId) {
    const client = clients[project.lastModifiedByClientId];

    project.fields.lastModifiedBy = getLastModifiedByClientName(
      project.lastModifiedByClientId,
      client
    );
  } else if (project.lastModifiedByUserId) {
    const currentUser = users[project.lastModifiedByUserId];

    project.fields.lastModifiedBy = getLastModifiedByName(
      project.lastModifiedByUserId,
      currentUser
    );
  }
};

export const constantSections = ["operations", "materials", "quickPrice"];

export const nonEstimatesLookupFieldIds = [
  "fromLowBid",
  "leftOnTable",
  "percentFromLowBid",
  "percentLeftOnTable",
  "winningBidAmount",
  "winningBidCompany",
  "lastModified",
  "lastModifiedBy",
  "hJJob.Code",
  "hJJob.Description"
];

export const selectEstimatesFields = (fields: SchemaField[]) =>
  fields.filter(
    field =>
      field.lookup &&
      !field.config.tableLookupField &&
      !nonEstimatesLookupFieldIds.includes(field.id)
  );
