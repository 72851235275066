import React from "react";
import styled from "styled-components";
import { Icon, ConcreteColors, Link } from "hcss-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { strings } from "localization";

export const AppErrorRedirect = () => {
  return (
    <Container>
      <FontAwesomeIcon
        style={errorIconStyle}
        icon={["fad", "exclamation-triangle"]}
        size={"6x"}
      />
      <p className="title">{strings.errorBoundary.title}</p>
      <p className="body">{strings.errorBoundary.body}</p>
      <Link href="/" style={{ fontSize: "20px", marginTop: "40px" }}>
        <Icon name="arrow-left" /> {strings.errorBoundary.button}
      </Link>
    </Container>
  );
};

const baseIconStyle: Record<string, string> = {
  "--fa-primary-color": "#7070708c",
  "padding-left": "25px",
  "margin-top": "20px"
};

const errorIconStyle: Record<string, string> = {
  ...baseIconStyle,
  "--fa-secondary-color": ConcreteColors.red200
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;

  .title {
    font-size: 35px;
    color: #5a5c69;
  }

  .body {
    font-size: 20px;
    margin-top: 6px;
    max-width: 650px;
    text-align: center;
  }
  .button {
    background-color: #0370f5;
    border-color: #0370f5;
    color: white;
  }
`;
