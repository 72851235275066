import { SchemaFieldType } from "api";
import {
  VendorSolicitationStatus,
  SolicitationDashboardFilter
} from "core/models/enums";

export const quoteDashboardSchema = () => ({
  id: "quoteDashboard",
  name: "Quote Dashboard",
  schemaName: "quoteDashboard",
  orderedSections: ["project"],
  fields: {
    name: {
      id: "name",
      name: "Project Name",
      type: SchemaFieldType.ShortText,
      lookup: false,
      standard: true,
      protected: true,
      readOnly: true,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {},
      required: false
    },
    lastModified: {
      id: "lastModified",
      name: "Last Updated",
      type: SchemaFieldType.DateTime,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: true,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {},
      required: false
    },
    quoteFolders: {
      id: "quoteFolders",
      name: "Quote Folders",
      type: SchemaFieldType.List,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: true,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        listValues: []
      },
      required: false
    },
    status: {
      id: "status",
      name: "Status",
      type: SchemaFieldType.List,
      lookup: false,
      standard: true,
      protected: true,
      readOnly: true,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        listValues: filterListValues
      },
      required: false
    }
  },
  sections: {
    project: {
      id: "project",
      name: "Project",
      fields: ["name", "lastModified"]
    }
  }
});

export const filterListValues: Array<{
  display: string;
  value: SolicitationDashboardFilter;
}> = [
  {
    display: "Awaiting Response",
    value: VendorSolicitationStatus.SentToSub
  },
  {
    display: "Bidding",
    value: VendorSolicitationStatus.SubAccepted
  },
  {
    display: "Manually Entered",
    value: VendorSolicitationStatus.GCManuallyEntered
  },
  {
    display: "New Prices Received",
    value: VendorSolicitationStatus.SentToGC
  },
  {
    display: "Not Bidding",
    value: VendorSolicitationStatus.SubRejected
  },
  { display: "Queued for Sync", value: VendorSolicitationStatus.GCApproved },
  { display: "Rejected", value: VendorSolicitationStatus.GCIgnored },
  { display: "Draft", value: "draft" }
];
