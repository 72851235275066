import { ActionType } from "typesafe-actions";
import { call, put } from "redux-saga/effects";
import { actions } from "../state";
import { accountService } from "../services/account-service";

export function* declineInivitation(
  action: ActionType<typeof actions.declineInvitation.request>
) {
  try {
    yield call(
      [accountService, accountService.declineInvitation],
      action.payload.id,
      action.payload.token
    );

    yield put(actions.declineInvitation.success());
  } catch (error) {
    console.error(error);
    yield put(actions.declineInvitation.failure(error));
  }
}
