import { strings } from "localization";

export const estimateExtendedSchema = () => ({
  fields: {
    "values.code": {
      id: "values.code",
      name: strings.heavyBid.code,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: true,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {},
      required: false
    },
    "values.name": {
      id: "values.name",
      name: strings.heavyBid.name,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: true,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {},
      required: false
    },
    "values.lastModified": {
      id: "values.lastModified",
      name: strings.heavyBid.lastActivity,
      type: 9,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: true,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        utc: true,
        ignoreTimezone: false
      },
      required: false
    },
    "values.isActiveEstimate": {
      id: "values.isActiveEstimate",
      name: strings.heavyBid.activeEstimate,
      type: 6,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: true,
      hiddenInForm: true,
      hiddenInTable: true,
      config: {},
      required: false
    },
    "values.isExcludedEstimate": {
      id: "values.isExcludedEstimate",
      name: strings.heavyBid.excludedEstimate,
      type: 6,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: true,
      hiddenInForm: true,
      hiddenInTable: false,
      hiddenInEdit: true,
      config: {},
      required: false
    },
    "values.processedStatus": {
      id: "values.processedStatus",
      name: strings.heavyBid.processedStatus.title,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: true,
      hiddenInForm: true,
      hiddenInTable: false,
      hiddenInEdit: true,
      config: {},
      required: false
    },
    "values.heavyBidDivision": {
      id: "values.heavyBidDivision",
      name: strings.heavyBid.division,
      type: 3,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: true,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        listValues: []
      },
      required: false
    },
    "values.filters.projectName": {
      id: "values.filters.projectName",
      name: strings.heavyBid.projectName,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.projectNumber": {
      id: "values.filters.projectNumber",
      name: strings.heavyBid.projectNumber,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 20
      },
      required: false
    },
    "values.filters.status": {
      id: "values.filters.status",
      name: strings.heavyBid.status,
      type: 3,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        listValues: []
      },
      required: false
    },
    "values.filters.engineer": {
      id: "values.filters.engineer",
      name: strings.heavyBid.engineer,
      type: 3,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        listValues: []
      },
      required: false
    },
    "values.filters.estimator": {
      id: "values.filters.estimator",
      name: strings.heavyBid.estimator,
      type: 3,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        listValues: []
      },
      required: false
    },
    "values.filters.owner": {
      id: "values.filters.owner",
      name: strings.heavyBid.owner,
      type: 3,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        listValues: []
      },
      required: false
    },
    "values.filters.bidDate": {
      id: "values.filters.bidDate",
      name: strings.heavyBid.bidDate,
      type: 2,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        utc: false,
        ignoreTimezone: true
      },
      required: false
    },
    "values.filters.startDate": {
      id: "values.filters.startDate",
      name: strings.heavyBid.startDate,
      type: 2,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        utc: false,
        ignoreTimezone: true
      },
      required: false
    },
    "values.filters.reviewDate": {
      id: "values.filters.reviewDate",
      name: strings.heavyBid.reviewDate,
      type: 2,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        utc: false,
        ignoreTimezone: true
      },
      required: false
    },
    "values.filters.createdDate": {
      id: "values.filters.createdDate",
      name: strings.heavyBid.createdDate,
      type: 2,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: true,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        utc: false,
        ignoreTimezone: true
      },
      required: false
    },
    "values.totals.bidTotal_Bid": {
      id: "values.totals.bidTotal_Bid",
      name: strings.heavyBid.bidTotal,
      type: 8,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: true,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {},
      required: false
    },
    "values.totals.totalCost_Takeoff": {
      id: "values.totals.totalCost_Takeoff",
      name: strings.heavyBid.totalCost,
      type: 8,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: true,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {},
      required: false
    },
    "values.filters.state": {
      id: "values.filters.state",
      name: strings.heavyBid.state,
      type: 3,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        listValues: []
      },
      required: false
    },
    "values.filters.typeOfWork": {
      id: "values.filters.typeOfWork",
      name: strings.heavyBid.typeOfWork,
      type: 3,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        listValues: []
      },
      required: false
    },
    "values.filters.estimateTag1": {
      id: "values.filters.estimateTag1",
      name: strings.heavyBid.filter1,
      type: 3,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        listValues: []
      },
      required: false
    },
    "values.filters.estimateTag2": {
      id: "values.filters.estimateTag2",
      name: strings.heavyBid.filter2,
      type: 3,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        listValues: []
      },
      required: false
    },
    "values.filters.estimateTag3": {
      id: "values.filters.estimateTag3",
      name: strings.heavyBid.filter3,
      type: 3,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        listValues: []
      },
      required: false
    },
    "values.filters.estimateTag4": {
      id: "values.filters.estimateTag4",
      name: strings.heavyBid.filter4,
      type: 3,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        listValues: []
      },
      required: false
    },
    "values.filters.estimateTag5": {
      id: "values.filters.estimateTag5",
      name: strings.heavyBid.filter5,
      type: 3,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        listValues: []
      },
      required: false
    },
    "values.filters.estimateTag6": {
      id: "values.filters.estimateTag6",
      name: strings.heavyBid.filter6,
      type: 3,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        listValues: []
      },
      required: false
    },
    "values.filters.userDefinedText1": {
      id: "values.filters.userDefinedText1",
      name: strings.heavyBid.text1,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText2": {
      id: "values.filters.userDefinedText2",
      name: strings.heavyBid.text2,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText3": {
      id: "values.filters.userDefinedText3",
      name: strings.heavyBid.text3,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText4": {
      id: "values.filters.userDefinedText4",
      name: strings.heavyBid.text4,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText5": {
      id: "values.filters.userDefinedText5",
      name: strings.heavyBid.text5,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText6": {
      id: "values.filters.userDefinedText6",
      name: strings.heavyBid.text6,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText7": {
      id: "values.filters.userDefinedText7",
      name: strings.heavyBid.text7,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText8": {
      id: "values.filters.userDefinedText8",
      name: strings.heavyBid.text8,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText9": {
      id: "values.filters.userDefinedText9",
      name: strings.heavyBid.text9,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText10": {
      id: "values.filters.userDefinedText10",
      name: strings.heavyBid.text10,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText11": {
      id: "values.filters.userDefinedText11",
      name: strings.heavyBid.text11,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText12": {
      id: "values.filters.userDefinedText12",
      name: strings.heavyBid.text12,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText13": {
      id: "values.filters.userDefinedText13",
      name: strings.heavyBid.text13,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText14": {
      id: "values.filters.userDefinedText14",
      name: strings.heavyBid.text14,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText15": {
      id: "values.filters.userDefinedText15",
      name: strings.heavyBid.text15,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText16": {
      id: "values.filters.userDefinedText16",
      name: strings.heavyBid.text16,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText17": {
      id: "values.filters.userDefinedText17",
      name: strings.heavyBid.text17,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText18": {
      id: "values.filters.userDefinedText18",
      name: strings.heavyBid.text18,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText19": {
      id: "values.filters.userDefinedText19",
      name: strings.heavyBid.text19,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedText20": {
      id: "values.filters.userDefinedText20",
      name: strings.heavyBid.text20,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 50
      },
      required: false
    },
    "values.filters.userDefinedInteger1": {
      id: "values.filters.userDefinedInteger1",
      name: strings.heavyBid.integer1,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999,
        max: 999999999999,
        decimalScale: 0,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedInteger2": {
      id: "values.filters.userDefinedInteger2",
      name: strings.heavyBid.integer2,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999,
        max: 999999999999,
        decimalScale: 0,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedInteger3": {
      id: "values.filters.userDefinedInteger3",
      name: strings.heavyBid.integer3,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999,
        max: 999999999999,
        decimalScale: 0,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedInteger4": {
      id: "values.filters.userDefinedInteger4",
      name: strings.heavyBid.integer4,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999,
        max: 999999999999,
        decimalScale: 0,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedInteger5": {
      id: "values.filters.userDefinedInteger5",
      name: strings.heavyBid.integer5,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999,
        max: 999999999999,
        decimalScale: 0,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber1": {
      id: "values.filters.userDefinedNumber1",
      name: strings.heavyBid.number1,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber2": {
      id: "values.filters.userDefinedNumber2",
      name: strings.heavyBid.number2,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber3": {
      id: "values.filters.userDefinedNumber3",
      name: strings.heavyBid.number3,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber4": {
      id: "values.filters.userDefinedNumber4",
      name: strings.heavyBid.number4,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber5": {
      id: "values.filters.userDefinedNumber5",
      name: strings.heavyBid.number5,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber6": {
      id: "values.filters.userDefinedNumber6",
      name: strings.heavyBid.number6,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber7": {
      id: "values.filters.userDefinedNumber7",
      name: strings.heavyBid.number7,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber8": {
      id: "values.filters.userDefinedNumber8",
      name: strings.heavyBid.number8,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber9": {
      id: "values.filters.userDefinedNumber9",
      name: strings.heavyBid.number9,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber10": {
      id: "values.filters.userDefinedNumber10",
      name: strings.heavyBid.number10,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber11": {
      id: "values.filters.userDefinedNumber11",
      name: strings.heavyBid.number11,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber12": {
      id: "values.filters.userDefinedNumber12",
      name: strings.heavyBid.number12,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber13": {
      id: "values.filters.userDefinedNumber13",
      name: strings.heavyBid.number13,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber14": {
      id: "values.filters.userDefinedNumber14",
      name: strings.heavyBid.number14,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber15": {
      id: "values.filters.userDefinedNumber15",
      name: strings.heavyBid.number15,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber16": {
      id: "values.filters.userDefinedNumber16",
      name: strings.heavyBid.number16,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber17": {
      id: "values.filters.userDefinedNumber17",
      name: strings.heavyBid.number17,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber18": {
      id: "values.filters.userDefinedNumber18",
      name: strings.heavyBid.number18,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber19": {
      id: "values.filters.userDefinedNumber19",
      name: strings.heavyBid.number19,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedNumber20": {
      id: "values.filters.userDefinedNumber20",
      name: strings.heavyBid.number20,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        min: -99999999999.9999,
        max: 999999999999.9999,
        decimalScale: 4,
        setCellValue: (value: number | null) => value ?? 0
      },
      required: false
    },
    "values.filters.userDefinedDate1": {
      id: "values.filters.userDefinedDate1",
      name: strings.heavyBid.date1,
      type: 2,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        utc: false,
        ignoreTimezone: true
      },
      required: false
    },
    "values.filters.userDefinedDate2": {
      id: "values.filters.userDefinedDate2",
      name: strings.heavyBid.date2,
      type: 2,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        utc: false,
        ignoreTimezone: true
      },
      required: false
    },
    "values.filters.userDefinedDate3": {
      id: "values.filters.userDefinedDate3",
      name: strings.heavyBid.date3,
      type: 2,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        utc: false,
        ignoreTimezone: true
      },
      required: false
    },
    "values.filters.userDefinedDate4": {
      id: "values.filters.userDefinedDate4",
      name: strings.heavyBid.date4,
      type: 2,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        utc: false,
        ignoreTimezone: true
      },
      required: false
    },
    "values.filters.userDefinedDate5": {
      id: "values.filters.userDefinedDate5",
      name: strings.heavyBid.date5,
      type: 2,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        utc: false,
        ignoreTimezone: true
      },
      required: false
    },
    "values.filters.address": {
      id: "values.filters.address",
      name: strings.heavyBid.address,
      type: 0,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {
        maxLength: 250
      },
      required: false
    },
    "values.filters.latitude": {
      id: "values.filters.latitude",
      name: strings.heavyBid.latitude,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      hiddenInEdit: false,
      config: {},
      required: false
    },
    "values.filters.longitude": {
      id: "values.filters.longitude",
      name: strings.heavyBid.longitude,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: false,
      hiddenInForm: true,
      hiddenInTable: false,
      hiddenInEdit: false,
      config: {},
      required: false
    },
    "values.totals.manhours_Total": {
      id: "values.totals.manhours_Total",
      name: strings.heavyBid.manhours,
      type: 7,
      lookup: true,
      standard: true,
      protected: true,
      readOnly: true,
      hiddenInForm: true,
      hiddenInTable: false,
      config: {},
      required: false
    }
  },
  id: "estimatesextended",
  name: "EstimatesExtended",
  schemaName: "estimatesExtended",
  orderedSections: [
    "info",
    "tags",
    "userDefinedText",
    "userDefinedInts",
    "userDefinedNumbers",
    "userDefinedDates"
  ],
  sections: {
    info: {
      id: "info",
      name: strings.heavyBid.estimateInfo,
      fields: [
        "values.code",
        "values.name",
        "values.lastModified",
        "values.isActiveEstimate",
        "values.heavyBidDivision",
        "values.filters.projectName",
        "values.filters.projectNumber",
        "values.filters.status",
        "values.filters.engineer",
        "values.filters.estimator",
        "values.filters.owner",
        "values.filters.bidDate",
        "values.filters.startDate",
        "values.filters.reviewDate",
        "values.filters.createdDate",
        "values.totals.bidTotal_Bid",
        "values.totals.totalCost_Takeoff",
        "values.filters.state",
        "values.filters.typeOfWork",
        "values.processedStatus",
        "values.isExcludedEstimate",
        "values.filters.address",
        "values.filters.latitude",
        "values.filters.longitude",
        "values.totals.manhours_Total"
      ]
    },
    tags: {
      id: "tags",
      name: strings.heavyBid.tags,
      fields: [
        "values.filters.estimateTag1",
        "values.filters.estimateTag2",
        "values.filters.estimateTag3",
        "values.filters.estimateTag4",
        "values.filters.estimateTag5",
        "values.filters.estimateTag6"
      ]
    },
    userDefinedText: {
      id: "userDefinedText",
      name: strings.heavyBid.userDefinedText,
      fields: [
        "values.filters.userDefinedText1",
        "values.filters.userDefinedText2",
        "values.filters.userDefinedText3",
        "values.filters.userDefinedText4",
        "values.filters.userDefinedText5",
        "values.filters.userDefinedText6",
        "values.filters.userDefinedText7",
        "values.filters.userDefinedText8",
        "values.filters.userDefinedText9",
        "values.filters.userDefinedText10",
        "values.filters.userDefinedText11",
        "values.filters.userDefinedText12",
        "values.filters.userDefinedText13",
        "values.filters.userDefinedText14",
        "values.filters.userDefinedText15",
        "values.filters.userDefinedText16",
        "values.filters.userDefinedText17",
        "values.filters.userDefinedText18",
        "values.filters.userDefinedText19",
        "values.filters.userDefinedText20"
      ]
    },
    userDefinedInts: {
      id: "userDefinedInts",
      name: strings.heavyBid.userDefinedIntegers,
      fields: [
        "values.filters.userDefinedInteger1",
        "values.filters.userDefinedInteger2",
        "values.filters.userDefinedInteger3",
        "values.filters.userDefinedInteger4",
        "values.filters.userDefinedInteger5"
      ]
    },
    userDefinedNumbers: {
      id: "userDefinedNumbers",
      name: strings.heavyBid.userDefinedNumbers,
      fields: [
        "values.filters.userDefinedNumber1",
        "values.filters.userDefinedNumber2",
        "values.filters.userDefinedNumber3",
        "values.filters.userDefinedNumber4",
        "values.filters.userDefinedNumber5",
        "values.filters.userDefinedNumber6",
        "values.filters.userDefinedNumber7",
        "values.filters.userDefinedNumber8",
        "values.filters.userDefinedNumber9",
        "values.filters.userDefinedNumber10",
        "values.filters.userDefinedNumber11",
        "values.filters.userDefinedNumber12",
        "values.filters.userDefinedNumber13",
        "values.filters.userDefinedNumber14",
        "values.filters.userDefinedNumber15",
        "values.filters.userDefinedNumber16",
        "values.filters.userDefinedNumber17",
        "values.filters.userDefinedNumber18",
        "values.filters.userDefinedNumber19",
        "values.filters.userDefinedNumber20"
      ]
    },
    userDefinedDates: {
      id: "userDefinedDates",
      name: strings.heavyBid.userDefinedDates,
      fields: [
        "values.filters.userDefinedDate1",
        "values.filters.userDefinedDate2",
        "values.filters.userDefinedDate3",
        "values.filters.userDefinedDate4",
        "values.filters.userDefinedDate5"
      ]
    }
  }
});
