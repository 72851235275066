import React, { ReactNode } from "react";
import {
  HeaderSection,
  Item,
  MenuSection
} from "core/components/hcss-navigation";
import { ConcreteColors, Icon, Link } from "hcss-components";
import styled from "styled-components";
import { strings } from "localization";

interface HeaderSectionWithBackButtonProps {
  backPath: string;
  children?: ReactNode;
}
export const HeaderSectionWithBackButton = ({
  backPath,
  children
}: HeaderSectionWithBackButtonProps) => {
  return (
    <HeaderSection>
      {headerStyle => {
        return (
          <div style={headerStyle}>
            <HeaderContainer data-testid="navitem-header">
              <BackLink to={backPath}>
                <Icon name="arrow-left" /> {strings.core.buttons.back}
              </BackLink>
              {children}
            </HeaderContainer>
          </div>
        );
      }}
    </HeaderSection>
  );
};

const HeaderContainer = styled.div`
  padding-bottom: 26px;
  padding-left: 12px;
  padding-top: 8px;
  font-size: 16px;
  font-weight: 700;
  color: ${ConcreteColors.gray600};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BackLink = styled(Link)`
  font-size: 12px;
  font-weight: 400;
  color: ${ConcreteColors.gray600};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
