import { useRouteMatch, useLocation } from "react-router-dom";
import { EstimateDetailsContext } from "./estimate-details";
import { VendorDetailsContext } from "./vendor-details";
import { useAuthorization, usePermissions } from "modules/account";
import { ProfileDetailsContext } from "./profile-details";
import { Imports } from "./imports";
import { CustomizeSetupContext } from "./customize-setup";
import { CustomizeReportingContext } from "./customize-reporting";
import { paths } from "modules/estimates/views/paths";

export const useContextNavigation = () => {
  const path = useLocation().pathname;
  const auth = useAuthorization();
  const permissions = usePermissions();

  const enableCustomizeReporting =
    auth.canAccessFullPrecon && permissions.admin;

  const estimateDetailsMatch = useRouteMatch({
    path: "/estimates/:estimateId"
  });
  const estimateCodebooksMatch = useRouteMatch({
    path: "/estimates/codebooks"
  });

  const customizeReportingMatch = useRouteMatch({
    path: "/estimates/customize"
  });

  const vendorDetailsMatch = useRouteMatch<VendorDetailsMatchParams>({
    path: "/contacts/:vendorId"
  });

  const profileDetailsMatch = useRouteMatch({
    path: "/settings/profile"
  });

  const importProjectsMatch = useRouteMatch({
    path: "/projects/import-projects"
  });

  const importBidResultsMatch = useRouteMatch({
    path: "/projects/import-bid-results"
  });

  const customizeSetupMatch = useRouteMatch({
    path: "/projects/setup"
  });

  if (customizeReportingMatch && enableCustomizeReporting) {
    return { context: CustomizeReportingContext };
  }

  if (
    estimateDetailsMatch &&
    path !== paths.base &&
    path !== paths.edit &&
    !estimateCodebooksMatch
  ) {
    return { context: EstimateDetailsContext };
  }

  if (
    vendorDetailsMatch &&
    path !== "/contacts/import" &&
    path !== "/contacts/setup"
  ) {
    return {
      context: () => VendorDetailsContext(vendorDetailsMatch.params?.vendorId)
    };
  }

  if (profileDetailsMatch) {
    return { context: ProfileDetailsContext };
  }

  if (importProjectsMatch || importBidResultsMatch) {
    return { context: Imports };
  }

  if (customizeSetupMatch) {
    return { context: CustomizeSetupContext };
  }

  return { context: undefined };
};

interface VendorDetailsMatchParams {
  vendorId: string;
}
