import React, { useEffect } from "react";
import styled from "styled-components";
import { strings } from "localization";
import {
  CommunicationMethod,
  IVendorDtoV1Response
} from "api/GeneratedClients/ContactsClient";
import { formatPhoneNumber, statesEnum, provincesEnum } from "core";
import {
  ControlLabel,
  FormGroup,
  FormControl,
  Select,
  ConcreteColors,
  Button,
  Icon,
  HelpBlock
} from "hcss-components";
import produce from "immer";
import { IVendorLocationDto } from "api/contacts-api-dto-interfaces";
import { DropdownList } from "react-widgets";

interface DetailInfoProps {
  vendor: IVendorDtoV1Response;
  vendorLocation: IVendorLocationDto;
  setVendor: React.Dispatch<
    React.SetStateAction<IVendorDtoV1Response | undefined>
  >;
  isValidNickname: boolean;
  setEditingLocationId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setVendorLocation: React.Dispatch<
    React.SetStateAction<IVendorLocationDto | undefined>
  >;
  setVendorNickname: React.Dispatch<React.SetStateAction<string>>;
}

interface AdminDivisionOption {
  label: string;
  value: string;
  group: string;
}

interface CommMethodOption {
  label: string;
  value: CommunicationMethod;
}

const commMethodOptions = [
  { value: CommunicationMethod.Email, label: "Email" },
  { value: CommunicationMethod.Fax, label: "Fax" },
  { value: CommunicationMethod.Print, label: "Print" },
  { value: CommunicationMethod.None, label: "(None)" }
];

export const VendorLocationDetailInfo = ({
  vendor,
  vendorLocation: location,
  setVendor,
  isValidNickname,
  setEditingLocationId,
  setVendorLocation,
  setVendorNickname
}: DetailInfoProps) => {
  useEffect(() => {
    if (location) {
      setVendorNickname(location.nickname);
    }
  }, [location, setVendorNickname]);

  const stateOptions: AdminDivisionOption[] = statesEnum.enumNames.map(
    state => {
      return {
        value: state,
        label: state,
        group: strings.contact.detailInfo.administrativeDivisions.states
      };
    }
  );
  const provinceOptions: AdminDivisionOption[] = provincesEnum.enumNames.map(
    province => {
      return {
        value: province,
        label: province,
        group: strings.contact.detailInfo.administrativeDivisions.provinces
      };
    }
  );

  const handleChange = (e: any, fieldName?: string) => {
    const nextState = produce(location, draft => {
      switch (fieldName) {
        case "nickname": {
          const nickname = e.currentTarget.value;
          setVendorNickname(nickname);
          draft.nickname = nickname;
          break;
        }
        case "phoneNumber": {
          const phoneNumber = e.currentTarget.value;
          draft.phoneNumber = phoneNumber;
          break;
        }

        case "faxNumber": {
          const faxNumber = e.currentTarget.value;
          draft.faxNumber = faxNumber;
          break;
        }
        case "addressLine1": {
          const addressLine1 = e.currentTarget.value;
          draft.address.addressLine1 = addressLine1;
          break;
        }
        case "addressLine2": {
          const addressLine2 = e.currentTarget.value;
          draft.address.addressLine2 = addressLine2;
          break;
        }
        case "postalCode": {
          const postalCode = e.currentTarget.value;
          draft.address.postalCode = postalCode;
          break;
        }
        case "countryRegion": {
          const countryRegion = e.currentTarget.value;
          draft.address.countryRegion = countryRegion;
          break;
        }
        case "city": {
          const city = e.currentTarget.value;
          draft.address.city = city;
          break;
        }
        case "communicationMethod": {
          const communicationMethod = e.value;
          draft.communicationMethod = communicationMethod;
          break;
        }
        case "state": {
          const state = e.value;
          draft.address.stateProvince = state;
          break;
        }
      }
    });
    setVendorLocation(nextState);

    if (vendor.locations) {
      const filtered = vendor.locations?.filter(loc => loc.id !== nextState.id);
      const updateLocations = [...filtered, nextState];
      setVendor({ ...vendor, locations: updateLocations });
    }
  };

  const handleDeleteLocation = () => {
    setEditingLocationId(undefined);
    if (vendor.locations) {
      const filtered = vendor.locations?.filter(loc => loc.id !== location.id);
      setVendor({ ...vendor, locations: filtered });
    }
  };

  const adminDivisionList = ({ item }: { item: AdminDivisionOption }) => {
    return <>{item.label}</>;
  };
  const adminDivisionHeaders = ({ item }: { item: string }) => {
    return <h4>{item}</h4>;
  };
  const adminDivisionFilter = (option: AdminDivisionOption, search: string) => {
    return option.label.toLowerCase().indexOf(search) !== -1;
  };

  return (
    <DetailInfo>
      <div className="detailInfo">
        <div className="nickname">
          <FormGroup
            required
            validationState={!isValidNickname ? "error" : null}
          >
            <ControlLabel>
              {strings.contactManagement.vendorLocationDetails.nickname}
            </ControlLabel>
            <FormControl
              type="text"
              name="nickname"
              autoComplete="none"
              value={location.nickname}
              onChange={(e: any) => handleChange(e, e.currentTarget.name)}
              onBlur={(e: any) => {
                handleChange(e, e.currentTarget.name);
              }}
            />
            <FormControl.Feedback />
            {!isValidNickname && (
              <HelpBlock>
                {strings.formatString(
                  strings.schemas.form.required,
                  strings.contactManagement.vendorLocationDetails.nickname
                )}
              </HelpBlock>
            )}
          </FormGroup>
        </div>
        <div className="addressLine1">
          <FormGroup>
            <ControlLabel>
              {strings.contact.detailInfo.addressLine1}
            </ControlLabel>
            <FormControl
              type="text"
              name="addressLine1"
              autoComplete="none"
              value={location.address?.addressLine1}
              onChange={(e: any) => handleChange(e, e.currentTarget.name)}
            />
          </FormGroup>
        </div>
        <div className="addressLine2">
          <FormGroup>
            <ControlLabel>
              {strings.contact.detailInfo.addressLine2}
            </ControlLabel>
            <FormControl
              type="text"
              name="addressLine2"
              autoComplete="none"
              value={location.address?.addressLine2}
              onChange={(e: any) => handleChange(e, e.currentTarget.name)}
            />
          </FormGroup>
        </div>
        <div className="city">
          <FormGroup>
            <ControlLabel>{strings.contact.detailInfo.city}</ControlLabel>
            <FormControl
              type="text"
              name="city"
              autoComplete="none"
              value={location.address?.city}
              onChange={(e: any) => handleChange(e, e.currentTarget.name)}
            />
          </FormGroup>
        </div>
        <div className="state">
          <FormGroup>
            <ControlLabel>
              {strings.contact.detailInfo.stateProvince}
            </ControlLabel>
            <DropdownList
              value={{ value: location?.address?.stateProvince }}
              data={[
                ...stateOptions,
                ...provinceOptions,
                {
                  value: "",
                  label: strings.contact.detailInfo.clear
                }
              ]}
              itemComponent={adminDivisionList}
              textField="value"
              groupBy={option => option.group}
              groupComponent={adminDivisionHeaders}
              filter={adminDivisionFilter}
              onChange={(opt: AdminDivisionOption) =>
                handleChange(opt, "state")
              }
            />
          </FormGroup>
        </div>
        <div className="postalCode">
          <FormGroup>
            <ControlLabel>{strings.contact.detailInfo.postalCode}</ControlLabel>
            <FormControl
              type="text"
              name="postalCode"
              autoComplete="none"
              value={location.address?.postalCode}
              onChange={(e: any) => handleChange(e, e.currentTarget.name)}
            />
          </FormGroup>
        </div>
        <div className="countryRegion">
          <FormGroup>
            <ControlLabel>
              {strings.contact.detailInfo.countryRegion}
            </ControlLabel>
            <FormControl
              type="text"
              name="countryRegion"
              autoComplete="none"
              value={location.address?.countryRegion}
              onChange={(e: any) => handleChange(e, e.currentTarget.name)}
            />
          </FormGroup>
        </div>
        <div className="phoneNumber">
          <FormGroup>
            <ControlLabel>{strings.contact.mainInfo.phoneNumber}</ControlLabel>
            <FormControl
              type="text"
              name="phoneNumber"
              value={formatPhoneNumber(
                location.phoneNumber ? location.phoneNumber : ""
              )}
              onChange={(e: any) => handleChange(e, e.currentTarget.name)}
            />
          </FormGroup>
        </div>
        <div className="faxNumber">
          <FormGroup>
            <ControlLabel>{strings.contact.detailInfo.faxNumber}</ControlLabel>
            <FormControl
              type="text"
              name="faxNumber"
              value={formatPhoneNumber(
                location.faxNumber ? location.faxNumber : ""
              )}
              onChange={(e: any) => handleChange(e, e.currentTarget.name)}
            />
          </FormGroup>
        </div>
        <div className="communicationMethod">
          <FormGroup>
            <ControlLabel>
              {strings.contact.mainInfo.communicationMethod}
            </ControlLabel>
            <StyledSelect
              classNamePrefix="react-select"
              className="react-select-container"
              options={commMethodOptions}
              value={{
                value: location.communicationMethod,
                label: location.communicationMethod
              }}
              onChange={(opt: CommMethodOption) =>
                handleChange(opt, "communicationMethod")
              }
            />
          </FormGroup>
        </div>
      </div>
      <div className="location-delete-button">
        {location.nickname && (
          <Button
            hcssStyle="Delete"
            className="deleteVendorLocationBtn"
            onClick={handleDeleteLocation}
          >
            <Icon name="trash-o" margin="right" />
            Delete
          </Button>
        )}
      </div>
    </DetailInfo>
  );
};

const DetailInfo = styled.div`
  div.detailInfo {
    display: grid;
    background-color: #ffffff;
    grid-template-columns: 25% 25% 25% 20%;
    column-gap: 10px;
    row-gap: 10px;
    grid-template-areas:
      "nickname . . ."
      "address1 address1 address2  address2"
      "city state postalCode countryRegion"
      "phoneNumber faxNumber communicationMethod ."
      "deleteLocationBtn . . .";
    .form-group[required] label:after {
      content: " *";
      color: ${ConcreteColors.red200};
    }
  }

  div.nickname {
    grid-area: nickname;
  }

  div.addressLine1 {
    grid-area: address1;
  }

  div.addressLine2 {
    grid-area: address2;
  }
  div.faxNumber {
    grid-area: faxNumber;
  }
  div.phoneNumber {
    grid-area: phoneNumber;
  }
  div.city {
    grid-area: city;
  }
  div.state {
    grid-area: state;
  }
  div.postalCode {
    grid-area: postalCode;
  }
  div.countryRegion {
    grid-area: countryRegion;
  }
  div.communicationMethod {
    grid-area: communicationMethod;
  }
  div.location-delete-button {
    grid-area: deleteLocationBtn;
    padding-top: 1rem;
  }
`;

const StyledSelect = styled(Select)`
  .react-select__control {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    max-height: 34px !important;
    min-height: 34px !important;
  }

  .react-select__input {
    height: 15px;
  }

  .react-select__single-value {
    padding-bottom: 5px;
    padding-left: 7px;
  }
  .react-select__multi-value {
    background-color: ${ConcreteColors.gray200};
  }

  .react-select__value-container {
    padding: 2px;
  }
`;
