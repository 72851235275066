import { DataColumnType, TypedDataColumn } from "hcss-tables";
import { MinorityStatusDto } from "api/GeneratedClients/ContactsClient";

const CertNumberColumn: TypedDataColumn = {
  name: "certificationNumber",
  title: "Certification #",
  type: DataColumnType.ShortText
};
const PercentColumn: TypedDataColumn = {
  name: "percent",
  title: "%",
  type: DataColumnType.Number,
  getCellValue: (row: MinorityStatusDto) => row.percent ?? ""
};
const DescriptionColumn: TypedDataColumn = {
  name: "description",
  title: "Description",
  type: DataColumnType.ShortText,
  readOnly: true,
  getCellValue: (row: MinorityStatusDto) => row.type?.description ?? ""
};

export const minorityStatusColumns: TypedDataColumn[] = [
  DescriptionColumn,
  CertNumberColumn,
  PercentColumn
];
