import numeral from "numeral";
import moment from "moment";
import { Timestamp } from "firebase/firestore";
import { getFieldValueAsArray, sortValues } from "core";
import { CompanyFieldData } from "modules/schemas/components/fields/CompanyInput";
import { IContactFieldData as ContactFieldData } from "modules/contacts/interfaces/IContactFieldData";
import { isString } from "lodash-es";
import { isYearIn100Range } from "modules/schemas/components/filters/dateTimeUtils";

export const formatNumber = (num: number, decimals = 2): string => {
  return numeral(num).format(`0,0.${"0".repeat(decimals)}`);
};
export const formatNumberFixed = (num: number, decimals = 2): string => {
  const re = new RegExp("^-?\\d+(?:.\\d{0," + (decimals || -1) + "})?");
  const match = re.exec(num.toString());
  const fixed = match ? match[0] : NaN;
  return formatNumber(Number(fixed), decimals);
};
export const formatBoolean = (val: boolean): string => {
  return val ? "✔️" : "❌";
};

export const formatPercent = (num: number | string, decimals = 2): string => {
  return numeral(num).format(`0,0.${"0".repeat(decimals)}%`);
};

export const formatCurrency = (num: number, decimals = 2) => {
  return numeral(num).format(`$0,0.${"0".repeat(decimals)}`);
};

export const formatCurrencyTruncate = (num: number, decimals = 2): string => {
  const re = new RegExp("^-?\\d+(?:.\\d{0," + (decimals || -1) + "})?");
  const match = re.exec(num.toString());
  const fixed = match ? match[0] : NaN;
  return formatCurrency(Number(fixed), decimals);
};

export const formatDate = (
  date: string | Date | Timestamp | undefined | null,
  accountForTimezone?: boolean
): string => {
  if (!date) {
    return "";
  }

  const d =
    typeof date === "string"
      ? new Date(date)
      : date.hasOwnProperty("nanoseconds")
      ? (date as Timestamp).toDate()
      : date;

  if (accountForTimezone) {
    return moment(d).format("L");
  }

  /* .utc() is included here because we only want the date, but the JS Date
   * representation automatically includes time and (by consequence) timezone
   * information. In the case where accountForTimezone is false/undefined, the
   * caller wants a timezone-agnostic date, such as for a bid's due date. */
  return moment(d).utc().format("L");
};

export const formatTime = (date: Date | Timestamp | undefined): string => {
  if (!date) {
    return "";
  }

  const d = date.hasOwnProperty("nanoseconds")
    ? (date as Timestamp).toDate()
    : date;
  return moment(d).format("LT");
};

export const formatDateTime = (
  date: string | Date | Timestamp | undefined,
  split?: boolean
): string => {
  if (!date) {
    return "";
  }

  const d =
    typeof date === "string"
      ? new Date(date)
      : date.hasOwnProperty("nanoseconds")
      ? (date as Timestamp).toDate()
      : date;
  return split ? moment(d).format("L[\n]LT") : moment(d).format("L LT");
};

export const parseDate = (stringValue: string): Date | null => {
  if (isNaN(new Date(stringValue).valueOf())) return null;
  if (!isYearIn100Range(new Date(stringValue))) return new Date(stringValue);
  const dateValue = moment(stringValue, "L LT");
  if (dateValue.isValid()) {
    if (dateValue.hour() === 0 && !stringValue.toLowerCase().includes("a")) {
      dateValue.set("hour", 12);
    }

    return dateValue.toDate();
  }
  return null;
};

// Tries to parse common phone number format
// If the attempt fails, return the original string
// If more advanced parsing is needed see libphonenumber from google.
// Format func was inconsisent in saving/display phone #s. Will decide how to handle in the future
export const formatPhoneNumber = (num: string) => {
  // const cleaned = ("" + num).replace(/\D/g, "");
  // const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  // if (match) {
  //   return ["(", match[2], ") ", match[3], "-", match[4]].join("");
  // }

  return num;
};

export const formatString = function (value: string, ...args: string[]) {
  return value.replace(/{(\d+)}/g, (match, number) =>
    args[number] === undefined ? match : args[number]
  );
};

export const formatFirstNameLastName = (
  firstName: string | undefined,
  lastName: string | undefined
) => {
  return `${firstName || ""}${firstName && lastName ? " " : ""}${
    lastName || ""
  }`;
};

export const sortLatLong = (
  a: { lat: number; long: number } | null,
  b: { lat: number; long: number } | null
) => {
  if (!a) {
    if (!b) return 0;
    return -1;
  }
  if (!b) {
    return 1;
  }
  if (a.lat > b.lat) return 1;
  if (a.lat < b.lat) return -1;
  if (a.long > b.long) return 1;
  if (a.long < b.long) return -1;
  return 0;
};
export const sortCompany = (
  a: CompanyFieldData | CompanyFieldData[],
  b: CompanyFieldData | CompanyFieldData[]
) => {
  const aCompanyFieldDisplay = formatCompanyField(a);
  const bCompanyFieldDisplay = formatCompanyField(b);
  if (!aCompanyFieldDisplay) {
    if (!bCompanyFieldDisplay) return 0;
    return -1;
  }
  if (!bCompanyFieldDisplay) return 1;

  return aCompanyFieldDisplay.localeCompare(bCompanyFieldDisplay);
};
export const sortContact = (
  a: ContactFieldData | ContactFieldData[],
  b: ContactFieldData | ContactFieldData[]
) => {
  const aContactFieldDisplay = formatContactField(a);
  const bContactFieldDisplay = formatContactField(b);

  if (!aContactFieldDisplay) {
    if (!bContactFieldDisplay) return 0;
    return -1;
  }
  if (!bContactFieldDisplay) return 1;

  return aContactFieldDisplay.localeCompare(bContactFieldDisplay);
};

export const formatLatLong = (
  coords: { lat: number; long: number } | string | null
) => {
  if (isString(coords)) {
    return coords;
  }

  if (!coords?.lat && !coords?.long) return "";
  return `${coords.lat}, ${coords.long}`;
};

export const formatCompanyField = (
  company: CompanyFieldData | CompanyFieldData[]
): string => {
  if (!company) return "";
  return Array.isArray(company)
    ? company.map(c => c.code).join(", ")
    : company.code;
};

export const formatContactField = (
  contact: ContactFieldData | ContactFieldData[]
): string => {
  if (!contact) return "";
  return Array.isArray(contact)
    ? contact
        .map(c => `${c.firstName} ${c.lastName} [${c.companyName}]`)
        .join(", ")
    : `${contact.firstName} ${contact.lastName} [${contact.companyName}]`;
};

export const formatHJJobField = (job: any): string => {
  if (!job) return "";
  return job;
};

export const getSortMultiSelectFn = (config: any) => {
  return (a, b) => {
    if (!a) {
      if (!b) return 0;
      return -1;
    }
    if (!b) {
      return 1;
    }

    const sortedA = sortValues(
      getFieldValueAsArray(a),
      config.listValues,
      config.customSort
    );
    const sortedB = sortValues(
      getFieldValueAsArray(b),
      config.listValues,
      config.customSort
    );

    // if alphabetical sort
    if (!config.customSort) {
      const val1 = sortedA.join(", ");
      const val2 = sortedB.join(", ");
      return val1.toLowerCase() < val2.toLowerCase() ? -1 : 1;
    }

    // if custom sort we need to respect the custom sort order
    const newA = sortedA;
    const newB = sortedB;

    const maxItems = Math.max(newA.length, newB.length);
    for (let i = 0; i < maxItems; i++) {
      const aIndex = config.listValues.findIndex(c => c.value === newA[i]);
      const bIndex = config.listValues.findIndex(c => c.value === newB[i]);
      if (aIndex === -1) {
        if (bIndex === -1)
          return newA[i] &&
            newB[i] &&
            newA[i].toLowerCase() < newB[i].toLowerCase()
            ? -1
            : 1;
        return 1;
      }
      if (bIndex === -1) return -1;
      if (aIndex === bIndex) continue;

      return aIndex < bIndex ? -1 : 1;
    }

    return 0;
  };
};

export const getFormatMultiSelect = (config: any) => {
  return (values: string[] | null) => {
    if (!values) return "";
    if (typeof values === "string") return values; // values may potentially be a string when converting from single selection -> multi selection
    return sortValues(
      getFieldValueAsArray(values),
      config.listValues,
      config.customSort
    ).join(", ");
  };
};

export const capitalizeWords = (str: string): string => {
  return str
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
