import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import { Calendar } from "api";
import notify from "../../core/components/notify";
import { WithId } from "core";
import { apiSagaFactory } from "../../api/api-saga-factory";
import { actions, StateSlice } from "./state";

const genericSagas = apiSagaFactory<Calendar>({
  isBusinessUnitApi: true,
  apiPath: "/calendars",
  stateKey: "calendars",
  onLoadSuccess: data => actions.loadCalendars.success(data),
  onLoadFail: err => actions.loadCalendars.failure(err)
});

function* deleteCalendar(
  action: ActionType<typeof actions.deleteCalendar.request>
) {
  try {
    const api = yield genericSagas.getApi();
    const data = yield call([api, api.delete], action.payload);
    const calendarId = data.data;
    yield put(actions.deleteCalendar.success(calendarId));
    notify("warning", "Calendar Link Deleted");
  } catch (error) {
    console.error(error);
    yield put(actions.deleteCalendar.failure(error));
  }
}

function* saveCalendar(
  action: ActionType<typeof actions.saveCalendar.request>
) {
  const originalCalendar = action.payload;
  const existingCalendars: Record<string, WithId<Calendar>> = yield select(
    ({ calendars }: StateSlice) => calendars.workingCopy
  );

  //creating new connections to Kloudless is impossible, so we are okay to swap to just checking for Nylas here
  const existingCalendar = Object.values(existingCalendars).find(
    calendar =>
      calendar.viewId === originalCalendar.viewId &&
      calendar.isNylas === originalCalendar.isNylas
  );

  try {
    const api = yield call(genericSagas.getApi);
    const data = yield call([api, api.create], action.payload);
    const calendar = data.data as WithId<Calendar>;

    if (existingCalendar) {
      yield put(actions.deleteCalendar.success(existingCalendar.id));
      if (calendar.isNylas) {
        notify("success", "Calendar Connected");
      } else {
        notify(
          "success",
          "Calendar Link Created",
          "The previous link is no longer valid."
        );
      }
    } else if (!calendar.isNylas) {
      // Since they haven't selected a calendar yet, not showing a notify for kloudless
      notify("success", "Calendar Link Created");
    }

    yield put(actions.saveCalendar.success(calendar));
  } catch (error) {
    console.error(error);
    notify("danger", "Error", "Error creating calendar link");
    yield put(actions.saveCalendar.failure(originalCalendar));
  }
}

export const sagas = [
  takeLatest(getType(actions.loadCalendars.request), genericSagas.load),
  takeEvery(getType(actions.saveCalendar.request), saveCalendar),
  takeEvery(getType(actions.deleteCalendar.request), deleteCalendar)
];
