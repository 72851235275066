import { Button, Modal as CModal } from "hcss-components";
import * as React from "react";
import { Sizes } from "react-bootstrap";
import styled from "styled-components";
import { strings } from "localization";

export interface ModalProps {
  title?: string;
  children?: React.ReactNode;
  show: boolean;
  handleClose: () => void;
  footer?: React.ReactNode;
  size?: Sizes;
  columns?: ModalColumnConfig;
  style?: React.CSSProperties;
  isScrollable?: boolean;
}

export interface ModalColumnConfig {
  columns: number;
  width: string;
}

export default function Modal(props: ModalProps) {
  const body =
    props.isScrollable === false ? (
      <CModal.Body>
        <ModalColumns config={props.columns}>{props.children}</ModalColumns>
      </CModal.Body>
    ) : (
      <ScrollableModalBody>
        <ModalColumns config={props.columns}>{props.children}</ModalColumns>
      </ScrollableModalBody>
    );

  return (
    <CModal
      bsSize={props.size}
      show={props.show}
      onHide={props.handleClose}
      style={props.style}
      className="drag-ignore"
    >
      <CModal.Header closeButton>
        <CModal.Title>{props.title}</CModal.Title>
      </CModal.Header>
      {body}
      <CModal.Footer>
        {props.footer || (
          <Button onClick={props.handleClose}>
            {strings.core.modals.close}
          </Button>
        )}
      </CModal.Footer>
    </CModal>
  );
}
const ScrollableModalBody = styled(CModal.Body)`
  max-height: 70vh;
  overflow-y: auto;
`;

const ModalColumns = styled.div<{ config?: ModalColumnConfig }>`
  ${props =>
    props.config
      ? `columns: ${props.config.width} ${props.config.columns};`
      : ""}
`;
