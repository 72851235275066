export interface GuestUserClaims {
  email: string;
  resourceId: string;
}

export interface UserClaims {
  companyId: string;
  companyName: string;
  businessUnitName: string;
  hcssUserId: string;
  firstName: string;
  lastName: string;
  email: string;
  isFullUser: boolean;
  isLimitedUser: boolean;
  isBetaUser: boolean;
  isSubcontractorUser: boolean;
  isCredentialAdmin: boolean;
}

  /**
   * For claims to be available on the client, 
   * they must be configured on the identity scope.
   * For this client app the identity scope is heavybid:insights.
   * @param {*} profile
   * @returns UserClaims
   */
  export function getUserClaimsFromProfile(profile: Record<string, string>) {
    const hcssNamespace = "http://credentials.hcssapps.com/schemas/2015/09/identity/claims";
    const msftNamespace = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims";

    // const isSubcontractorAdmin = profile[msftNamespace + "/role"] === "Subcontractor Admin";
    const betaUser = !!profile[hcssNamespace + "/appaccess/PreConstruction"];
    const betaCompany = profile[hcssNamespace + "/estimatemanagement"] === "True";
      
    const claims: UserClaims = {
      companyId: profile[hcssNamespace + "/companyid"],
      companyName: profile[hcssNamespace + "/companyname"],
      businessUnitName: profile[hcssNamespace + "/businessunitname"],
      hcssUserId: profile[hcssNamespace + "/userid"],
      firstName: profile[msftNamespace + "/givenname"],
      lastName: profile[msftNamespace + "/surname"],
      email: profile[hcssNamespace + "/emailcontact"],
      isFullUser: !!profile[hcssNamespace + "/appaccess/HeavyBidAddOnsPreConPT"],
      isLimitedUser: !!profile[hcssNamespace + "/appaccess/HeavyBidFreeInsights"],
      isBetaUser: betaCompany && betaUser,
      isSubcontractorUser: profile[hcssNamespace + "/issubcontractor"] === "True",
      isCredentialAdmin: profile["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] === "Admin"
    }


    return claims;
  }
