import { Button, ConcreteColors, Icon } from "hcss-components";
import * as React from "react";
import styled from "styled-components";
import Modal from ".";
import { strings } from "localization";

export interface ConfirmBackProps {
  title?: string;
  children?: React.ReactNode;
  show: boolean;
  disabled?: boolean;
  handleSave?: () => void;
  handleClose: () => void;
  handleBack?: () => void;
}

export default class ConfirmBack extends React.PureComponent<ConfirmBackProps> {
  render() {
    const props = this.props;
    return (
      <Modal
        show={props.show}
        handleClose={props.handleClose}
        footer={this.footer()}
        title={this.props.title || strings.core.modals.confirmBack.title}
      >
        {props.children}
      </Modal>
    );
  }
  footer = () => {
    return (
      <div>
        <CancelButton className="cancel" onClick={this.props.handleBack}>
          <Icon name="trash-o" margin="right" />
          {strings.core.modals.confirmBack.discardButton}
        </CancelButton>
        {this.props.handleSave && (
          <Button
            onClick={this.props.handleSave}
            disabled={this.props.disabled}
          >
            <Icon name="check" margin="right" />
            {strings.core.modals.confirmBack.saveButton}
          </Button>
        )}
        <Button hcssStyle="ThemeInverted" onClick={this.props.handleClose}>
          {strings.core.modals.confirmBack.cancelButton}
        </Button>
      </div>
    );
  };
}

const CancelButton = styled(Button)`
  &.cancel {
    color: ${ConcreteColors.red200};
    background-color: #fff;
    border-color: ${ConcreteColors.red200};
  }
  &.cancel:hover,
  &.cancel:active,
  &.cancel:focus {
    color: ${ConcreteColors.red300};
    border-color: ${ConcreteColors.red300};
    background-color: ${ConcreteColors.red100};
  }
`;
