import React from "react";
import styled from "styled-components";

import { Button, ConcreteColors } from "hcss-components";
import { useOidcContext } from "modules/account/services/oidc-context";

export const SubSignup = () => {
  const { oidcService } = useOidcContext();

  const onSignupClick = () => {
    oidcService.signinRedirect(undefined, {
      acr_values: "tenant:subcontractor redirect:signup"
    });
  };

  const onLoginClick = () => {
    oidcService.signinRedirect(undefined, {
      acr_values: "tenant:subcontractor"
    });
  };

  return (
    <Container className="container-fluid" data-testid="not-found-container">
      <MainContent>
        <Header>
          <img src={"/assets/img/hcss_logo_small.png"} alt="Your logo here" />
          <div className="btnGrp">
            <a className="login" onClick={onLoginClick}>
              Log In
            </a>
            <Button
              className="signUpButton"
              onClick={onSignupClick}
              value="singup"
            >
              Sign Up for Free
            </Button>
          </div>
        </Header>
        <Body>
          <Brand>
            What are the advantages of signing up for a <b>FREE</b> HCSS
            Subcontractor account?
          </Brand>

          <Title>
            Great question! HCSS wants to provide you with <b>ONE</b>{" "}
            <b>website</b> where you can:
          </Title>
          <br />
          <ul className="benifits-ul">
            <li>Track all of your company’s leads, upcoming & active jobs.</li>
            <li>Reply to quote solicitations received from GCs.</li>
            <li>
              {" "}
              Create professional quote proposals with your company logo.
            </li>
            <li>Estimate your prices & add markup.</li>
            <li>
              Send quotes to ANY general contractor. They do not need to be
              HeavyBid users.
            </li>
            <li>Access previously sent quotes & re-use them for new quotes.</li>
            <li>Manage your contacts.</li>
            <li>
              Work together with your co-workers. They would be able to see the
              same solicitation dashboard too!
            </li>
            <li>Create one quote proposal and send to multiple contractors.</li>
            <li>Access your quotes online anytime.</li>
          </ul>
        </Body>
        <ContentFooter>
          <Button className={"signUpButton"} onClick={onSignupClick}>
            Sign Up for Free
          </Button>
          <div className={"floatRight"}>
            <b>ProSubs</b> Quoting{" "}
            <a
              style={{ marginLeft: "13px", color: "#36baed", fontWeight: 600 }}
              target="_blank"
              href="https://hcssdev.atlassian.net/wiki/spaces/EXHBSUB/overview?homepageId=351076652"
            >
              Learn more
            </a>
          </div>
        </ContentFooter>
      </MainContent>
    </Container>
  );
};

const Header = styled.div`
  .login {
    color: black;
    font-weight: 600;
    top: 10px;
    right: 10px;
    position: relative;
  }

  .login:hover {
    cursor: pointer;
  }

  .btnGrp {
    float: right !important;
    position: relative;
    top: 15px;
    height: 45px;
  }

  .signUpButton,
  .signUpButton:hover {
    background-color: #009639 !important;
    border-color: green;
    position: relative;
    top: 10px;
  }
`;

const ContentFooter = styled.div`
  margin-top: 30px;

  .signUpButton,
  .signUpButton:hover {
    background-color: #009639 !important;
    border-color: green;
    position: relative;
    left: 35%;
  }

  .floatRight {
    float: right;
  }
`;

const Body = styled.div`
  padding-left: 50px;
  padding-top: 30px;
`;

const MainContent = styled.div`
  height: 100%;
  padding: 30px;
  ul.benifits-ul {
    font-family: "ProximaNova";
    font-weight: 900;
    font-size: 16px;
  }
`;

const Brand = styled.div`
  font-family: "ProximaNova Heavy";
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;

  b {
    color: green;
    font: bold;
  }
`;

const Title = styled.div`
  font-family: "ProximaNova";
  font-size: 18px;
  font-weight: 900;

  b {
    font-family: "ProximaNova Heavy";
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
