import { Button, Icon } from "hcss-components";
import * as React from "react";
import { strings } from "localization";

export default function BackButton({ buttonLabel, ...props }: any) {
  return (
    <Button hcssStyle="ThemeInverted" {...props}>
      <Icon name="arrow-left" color="white" margin="right" />
      {buttonLabel ?? strings.core.buttons.back}
    </Button>
  );
}
