import React, { useEffect, useMemo } from "react";
import {
  IContactProduct,
  ProductDto
} from "api/GeneratedClients/ContactsClient";
import {
  FormControl,
  Autocomplete,
  TextField,
  FilterOptionsState,
  createFilterOptions
} from "@mui/material";
import { strings } from "localization";
import { sortBy } from "lodash-es";
import { isValidString } from "core";

const inputId = "scopes-multiselect-select";
const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

interface ScopeOfWorkProps {
  disabled: boolean;
  vendorProducts: ProductDto[];
  contactProducts: IContactProduct[];
  setContactProducts: React.Dispatch<React.SetStateAction<IContactProduct[]>>;
  newCodes: (string | undefined)[];
  setNewCodes: React.Dispatch<React.SetStateAction<(string | undefined)[]>>;
}

export const ContactScopeOfWork = ({
  disabled,
  vendorProducts,
  contactProducts,
  setContactProducts,
  newCodes,
  setNewCodes
}: ScopeOfWorkProps) => {
  const vendorProductsProduct = useMemo(
    () =>
      vendorProducts.map(vp => ({
        ...vp.product,
        productTypeId: vp.product?.id,
        vendorProductId: vp.id
      })),
    [vendorProducts]
  );

  const [options, setOptions] = React.useState<(string | undefined)[]>([]);
  const [createValue, setCreateValue] = React.useState<string>("");
  const [selectedOptions, setSelectedOptions] = React.useState<
    (string | undefined)[]
  >([]);
  const [
    selectedOptionsWithNewCodes,
    setSelectedOptionsWithNewCodes
  ] = React.useState<(string | undefined)[]>([]);

  useEffect(() => {
    let contactProductCodes: string[] = [];
    if (selectedOptionsWithNewCodes.length > 0) {
      contactProductCodes = selectedOptionsWithNewCodes as string[];
    } else {
      contactProductCodes = contactProducts.map(p => p.code);
    }

    const vendorProductCodes = vendorProductsProduct
      ?.map(vp => vp?.code)
      .filter(vp => !contactProductCodes.includes(vp as string));
    const sortedSelectedOptions = sortBy(contactProductCodes);
    const sortedOptions = sortBy(vendorProductCodes);
    setSelectedOptions(sortedSelectedOptions);
    setOptions(sortedOptions);
  }, [
    contactProducts,
    vendorProducts,
    vendorProductsProduct,
    selectedOptionsWithNewCodes
  ]);

  const handleChange = (
    _: React.SyntheticEvent<Element, Event>,
    values: unknown
  ) => {
    const selectedCodes = handleAddNewOptionText(
      values as (string | undefined)[]
    );
    const formattedCreateValue = createValue.trim();
    const selectedCreateOption = `Create "${formattedCreateValue}"`;
    const newCodesList = determineNewCodesList(selectedCodes);
    const selectedExistingCodes = selectedCodes.filter(
      code => !newCodesList.includes(code) && code !== selectedCreateOption
    );
    const selectedCodesWithNewCodes = [
      ...selectedExistingCodes,
      ...newCodesList
    ];
    setSelectedOptionsWithNewCodes(selectedCodesWithNewCodes);
    const sortedSelectedOptions = sortBy(selectedCodesWithNewCodes);
    setSelectedOptions(sortedSelectedOptions);

    const accumulatedContactProducts = getAccumulatedContactProducts(
      selectedExistingCodes,
      newCodesList
    );
    setContactProducts(accumulatedContactProducts as IContactProduct[]);
    setCreateValue("");
  };

  const handleAddNewOptionText = (values: (string | undefined)[]) => {
    const selectedCodes: (string | undefined)[] = values.filter(
      value =>
        value !==
        strings.contactManagement.contactListModal.emptyScopeOfWorksText
    );
    return selectedCodes;
  };

  const determineNewCodesList = (selectedCodes: (string | undefined)[]) => {
    const selectedOption = selectedCodes[selectedCodes.length - 1];
    const formattedCreateValue = createValue.trim();
    const selectedCreateOption = `Create "${formattedCreateValue}"`;
    const createdCode = selectedOption === selectedCreateOption;
    if (createdCode) {
      const newCodesList: (string | undefined)[] = [
        ...newCodes,
        formattedCreateValue
      ];
      setNewCodes(newCodesList);
      return newCodesList;
    }
    const selectedNewCodes = newCodes.filter(code =>
      selectedCodes.includes(code)
    );
    setNewCodes(selectedNewCodes);
    return selectedNewCodes;
  };

  const getAccumulatedContactProducts = (
    selectedCodes: (string | undefined)[],
    newCodesList: (string | undefined)[]
  ) => {
    const existingContactProducts = vendorProductsProduct
      .filter(vp => selectedCodes.includes(vp.code as string))
      .map(vp => ({
        vendorProductId: vp.vendorProductId,
        code: vp.code,
        productTypeId: vp.productTypeId
      }));
    const newProducts = newCodesList.map(code => ({
      productTypeId: EMPTY_GUID,
      code: code?.toUpperCase(),
      vendorProductId: EMPTY_GUID
    }));
    const accumulatedContactProducts = [
      ...existingContactProducts,
      ...newProducts
    ];
    return accumulatedContactProducts;
  };

  const filterOptions = (
    options: unknown[],
    params: FilterOptionsState<unknown>
  ) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params) as string[];

    const { inputValue } = params;
    const formattedInputValue = inputValue.trim();

    const isExistingOption = options.some(
      option => formattedInputValue === option
    );
    const isExistingSelectedOption = selectedOptions.some(
      option => formattedInputValue === option
    );
    const validNewOption =
      isValidString(formattedInputValue) &&
      !isExistingOption &&
      !isExistingSelectedOption;

    if (validNewOption) {
      const addValue = `Create "${formattedInputValue}"`;
      filtered.push(addValue);
    } else {
      filtered.push(
        strings.contactManagement.contactListModal.emptyScopeOfWorksText
      );
    }

    return filtered;
  };

  const onInputChange = (
    _: React.SyntheticEvent<Element, Event>,
    input: string
  ) => {
    const formattedInput = input.toUpperCase().trimStart().replace(/ +/g, " ");
    setCreateValue(formattedInput);
  };

  return (
    <FormControl fullWidth style={{ marginTop: "2.4rem" }}>
      <Autocomplete
        disabled={disabled}
        id={inputId}
        multiple
        value={selectedOptions}
        options={options}
        noOptionsText={
          strings.contactManagement.contactListModal.emptyScopeOfWorksText
        }
        filterOptions={filterOptions}
        inputValue={createValue}
        onInputChange={onInputChange}
        autoHighlight
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        disableCloseOnSelect
        onChange={handleChange}
        renderInput={params => (
          <TextField
            {...params}
            label={strings.contactManagement.contactListModal.scopesLabel}
          />
        )}
      />
    </FormControl>
  );
};
