import { useMemo, useState } from "react";
import { difference } from "lodash-es";
import {
  isFilterActive,
  TypedSchemaViewFilter
} from "../../../schemas/filters";
import { SelectorState } from "modules/schemas";
import { useSelector } from "react-redux";
import { SchemaFieldType, SchemaViewFilter } from "api";
import { useLocalStorage } from "hcss-hooks";

export function useSelectedFilters(
  selectedFields: string[],
  schemaId: string,
  filterLookup: Record<string, SchemaViewFilter>,
  pageId: string
) {
  const [showFilters, setShowFilters] = useState(false);
  const [showFilterSelect, setShowFilterSelect] = useState(false);

  const { fields = {}, orderedSections = [], sections = {} } =
    useSelector((state: SelectorState) => {
      const rawSchema = state.schemas.workingCopy[schemaId];
      const selectedSchemaFields = Object.fromEntries(
        Object.entries(rawSchema.fields).filter(([key]) =>
          selectedFields.includes(key)
        )
      );
      const selectedSections = Object.fromEntries(
        Object.entries(rawSchema.sections).filter(([_, value]) =>
          value.fields.some(f => Object.keys(selectedSchemaFields).includes(f))
        )
      );
      const selectedOrderedSections = rawSchema.orderedSections.filter(s =>
        Object.keys(selectedSections).includes(s)
      );
      return {
        ...rawSchema,
        fields: selectedSchemaFields,
        sections: selectedSections,
        orderedSections: selectedOrderedSections
      };
    }) ?? {};

  const typedFilters = useMemo(() => {
    return Object.values(filterLookup)
      .filter(filter => fields[filter.columnName])
      .map(filter => {
        const typedFilter: TypedSchemaViewFilter = {
          ...filter,
          fieldType: fields[filter.columnName].type
        };
        return typedFilter;
      });
  }, [fields, filterLookup]);

  const activeFilters = useMemo(
    () => typedFilters.filter(isFilterActive).map(filter => filter.columnName),
    [typedFilters]
  );

  const [displayedFilters, setDisplayedFilters] = useLocalStorage<string[]>(
    `${pageId}-displayed-filters`,
    []
  );

  const missingFilters = difference(activeFilters, displayedFilters);
  const allFilters = displayedFilters.concat(missingFilters);
  const validFilters = allFilters.filter(filterId => filterId in fields);
  if (
    allFilters.length !== displayedFilters.length ||
    validFilters.length !== allFilters.length
  ) {
    setDisplayedFilters(validFilters);
  }

  if (showFilters && displayedFilters.length === 0) {
    setShowFilterSelect(true);
  }

  const currentFilterSelect: Record<
    string,
    { isDisplayed: boolean; isActive: boolean }
  > = {};
  displayedFilters.forEach(filter => {
    currentFilterSelect[filter] = {
      isDisplayed: true,
      isActive: activeFilters.find(f => f === filter) ? true : false
    };
  });

  const fieldsBySection = useMemo(() => {
    return orderedSections.map(sectionId => {
      return {
        name: sections[sectionId].name,
        id: sections[sectionId].id,
        fields: sections[sectionId].fields
          .filter(
            fieldId =>
              Object.keys(fields).includes(fieldId) &&
              fields[fieldId].type !== SchemaFieldType.Location
          )
          .map(fieldId => fields[fieldId])
          .sort(
            (a, b) =>
              selectedFields.indexOf(a.id) - selectedFields.indexOf(b.id)
          )
      };
    });
  }, [fields, orderedSections, sections]);

  return {
    fieldsBySection,
    showFilters,
    setShowFilters,
    showFilterSelect,
    setShowFilterSelect,
    activeFilters,
    fields,
    displayedFilters,
    setDisplayedFilters
  };
}
