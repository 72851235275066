import * as account from "modules/account";
import appconfig from "config";
import axios, { AxiosInstance } from "axios";
import {
  call,
  put,
  select,
  takeLatest,
  takeEvery,
  all
} from "redux-saga/effects";
import { getType, ActionType } from "typesafe-actions";
import {
  actions,
  selectors,
  DatabasePartition,
  UtilizedActivityCodebookCode,
  UtilizedActivityCodebookCodeDetail,
  UnusedActivity,
  MissingActivity,
  SystemBackup
} from "./state";
import {
  ActivityFormulaDto,
  ActivityTotal,
  Estimate,
  QuoteFolderDto,
  UniqueEstimateFilters
} from "api/GeneratedClients/insights";
import { WithId, IJsonPatchDocument, patchDocument } from "core";
import {
  actions as schemaActions,
  selectors as schemaSelectors
} from "modules/schemas";
import { Schema } from "api";
import { flatten, uniqBy } from "lodash-es";
import * as notify from "../../core/components/notify";
import { determineFilterKeyForLabelKey } from "./utils";
import { strings } from "localization";
import { GetDateValues } from "./hooks/use-codebooks-daterange";
import {
  CalculatedPriceDto,
  CalculatePriceRequestDto
} from "modules/quick-pricing/models";

// We decided to go with this page size because when we have < 3000 estimates, loading sequentially with 500 estimate/call is faster than doing it in parallel.
// We will load estimate in parallel (6 request at a time 500*6 = 3000) if the total estimate is > 3000.
const ESTIMATE_PAGE_SIZE = 500;
const CODEBOOK_PAGE_SIZE = 1000;
const CODEBOOK_MAX_PAGES = 15;
export class EstimatesApi {
  instance: AxiosInstance;
  businessUnitId: string;

  constructor(accessToken: string, businessUnitId: string) {
    this.instance = axios.create();
    this.instance.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    });
    this.businessUnitId = businessUnitId;
  }

  getEstimates = (top: number, skip: number) => {
    return this.instance.get<Estimate>(
      `${appconfig.endpoints.HBINSIGHTS}/api/v2/hbprecon/businessunits/${this.businessUnitId}/estimates?$top=${top}&$skip=${skip}&$orderby=LastModified desc&includeExcludedEstimates=true&$count=true`
    );
  };

  getLinkedEstimates = (ids: string[]) => {
    const filterString = `Id in (${ids.join(", ")})`;
    return this.instance.get<Estimate>(
      `${appconfig.endpoints.HBINSIGHTS}/api/v2/hbprecon/businessunits/${this.businessUnitId}/estimates?$filter=${filterString}&includeDeletedEstimates=false&includeExcludedEstimates=true`
    );
  };

  getEstimatesForIds = (ids: string[]) => {
    const filterString = `Id in (${ids.join(", ")})`;
    return this.instance.get<Estimate>(
      `${appconfig.endpoints.HBINSIGHTS}/api/v2/integration/businessunits/${this.businessUnitId}/estimates?$filter=${filterString}&includeDeletedEstimates=true&includeExcludedEstimates=true`
    );
  };

  getHeavybidPartitions = () => {
    return this.instance.get<DatabasePartition[]>(
      `/api/v1/businessUnits/${this.businessUnitId}/heavybid/registrations`
    );
  };

  getActivityCodebookLastProcessed = () => {
    const url = `${appconfig.endpoints.HBINSIGHTS}/api/v2/integration/businessUnits/${this.businessUnitId}/systembackups/activitycodebook/lastprocessed`;
    return this.instance.get<SystemBackup[]>(url);
  };

  getActivityCodebookUnused = (
    limit: number,
    cursor: string,
    beginDate?: string,
    endDate?: string
  ) => {
    let url = `${
      appconfig.endpoints.HBINSIGHTS
    }/api/v2/integration/businessUnits/${
      this.businessUnitId
    }/activitycodebook/unused?beginDate=${beginDate ?? ""}&endDate=${
      endDate ?? ""
    }&limit=${limit}`;
    if (cursor !== "") url += `&cursor=${cursor}`;
    return this.instance.get<UnusedActivity[]>(url);
  };

  getActivityCodebookMissing = (
    limit: number,
    cursor: string,
    beginDate?: string,
    endDate?: string
  ) => {
    let url = `${
      appconfig.endpoints.HBINSIGHTS
    }/api/v2/integration/businessUnits/${
      this.businessUnitId
    }/activitycodebook/missing?beginDate=${beginDate ?? ""}&endDate=${
      endDate ?? ""
    }&limit=${limit}`;
    if (cursor !== "") url += `&cursor=${cursor}`;
    return this.instance.get<MissingActivity[]>(url);
  };

  getActivityCodebookUtilized = (
    limit: number,
    cursor: string,
    beginDate?: string,
    endDate?: string
  ) => {
    let url = `${
      appconfig.endpoints.HBINSIGHTS
    }/api/v2/integration/businessUnits/${
      this.businessUnitId
    }/activitycodebook/utilized?beginDate=${beginDate ?? ""}&endDate=${
      endDate ?? ""
    }&limit=${limit}`;
    if (cursor !== "") url += `&cursor=${cursor}`;
    return this.instance.get<UtilizedActivityCodebookCode[]>(url);
  };

  getActivityCodebookUtilizedDetails = (
    partitionId: string,
    activityCode: string,
    beginDate?: string,
    endDate?: string
  ) => {
    const url = `${
      appconfig.endpoints.HBINSIGHTS
    }/api/v2/integration/businessUnits/${
      this.businessUnitId
    }/activitycodebook/modified/${partitionId}/${activityCode}?beginDate=${
      beginDate ?? ""
    }&endDate=${endDate ?? ""}`;
    return this.instance.get<UtilizedActivityCodebookCodeDetail[]>(url);
  };

  getQuoteFolders = (estimateId?: string) => {
    let url = `${appconfig.endpoints.HBINSIGHTS}/api/v1/quoteFolders`;
    if (estimateId) {
      url += `?estimateId=${estimateId}`;
    }
    return this.instance.get<QuoteFolderDto[]>(url);
  };

  getEstimateFilters = () => {
    const url = `${appconfig.endpoints.HBINSIGHTS}/api/v2/integration/businessUnits/${this.businessUnitId}/metadata/filters`;
    return this.instance.get<{ data: UniqueEstimateFilters }>(url);
  };

  getActivityTotals = (formulas: ActivityFormulaDto[]) => {
    const url = `${appconfig.endpoints.HBINSIGHTS}/api/v2/integration/businessunits/${this.businessUnitId}/totals/activities`;
    return this.instance.post<ActivityTotal[]>(url, formulas);
  };

  getUserDefinedLabels = () => {
    const url = `${appconfig.endpoints.HBINSIGHTS}/api/v2/integration/businessunits/${this.businessUnitId}/metadata/userDefinedLabels`;
    return this.instance.get<Record<string, string>>(url);
  };

  patchEstimate = (estimateId: string, patchDocument: IJsonPatchDocument) => {
    return this.instance.patch(
      `${appconfig.endpoints.HBINSIGHTS}/api/v2/integration/businessunits/${this.businessUnitId}/estimates/${estimateId}`,
      patchDocument
    );
  };

  getHiddenEstimateIds = () => {
    return this.instance.get(
      `/api/v1/businessUnits/${this.businessUnitId}/hiddenEstimateIds`
    );
  };

  patchHiddenEstimateIds = (patchIds: string[]) => {
    return this.instance.patch(
      `/api/v1/businessUnits/${this.businessUnitId}/hiddenEstimateIds`,
      patchIds
    );
  };

  loadQuickPriceClientNumbers = () => {
    return this.instance.get(
      `${appconfig.endpoints.HBINSIGHTS}/api/v2/integration/businessunits/${this.businessUnitId}/quickpricing/clientnumbers`
    );
  };

  getQuickPriceFilterOptions = () => {
    const url = `${appconfig.endpoints.HBINSIGHTS}/api/v2/integration/businessunits/${this.businessUnitId}/quickpricing/filteroptions`;
    return this.instance.get(url);
  };

  getQuickPriceCalculatedPrice = (
    calculatePriceRequest: CalculatePriceRequestDto
  ) => {
    const url = `${appconfig.endpoints.HBINSIGHTS}/api/v2/integration/businessunits/${this.businessUnitId}/quickpricing/calculatePrices`;
    return this.instance.post<CalculatedPriceDto[]>(url, calculatePriceRequest);
  };
}

function* loadHeavybidDivisions() {
  const hcssToken: string | undefined = yield select(
    account.selectors.getHcssToken
  );
  const businessUnitId: string | undefined = yield select(
    account.selectors.getSelectedBusinessUnitId
  );
  try {
    if (hcssToken && businessUnitId) {
      const api = new EstimatesApi(hcssToken, businessUnitId);
      const response = yield call(api.getHeavybidPartitions);
      yield put(actions.loadHeavybidDivisions.success(response.data));
    }
  } catch (error) {
    yield put(actions.loadHeavybidDivisions.failure(error));
  }
}

function* loadActivityCodebookUnused() {
  const hcssToken: string | undefined = yield select(
    account.selectors.getHcssToken
  );
  const businessUnitId: string | undefined = yield select(
    account.selectors.getSelectedBusinessUnitId
  );
  const dateRangeStr = window.localStorage.getItem("codebooks-date-range");
  const dateRange = dateRangeStr
    ? GetDateValues(JSON.parse(dateRangeStr))
    : GetDateValues();

  try {
    if (hcssToken && businessUnitId) {
      const api = new EstimatesApi(hcssToken, businessUnitId);
      let hasNextPage = true;
      let cursor = "";
      let pageNumber = 0;
      while (hasNextPage) {
        if (pageNumber >= CODEBOOK_MAX_PAGES) {
          yield put(
            actions.loadActivityCodebookUnused.failure({ overflow: true })
          );
          return;
        }
        const pageSize =
          pageNumber < 5 ? CODEBOOK_PAGE_SIZE : CODEBOOK_PAGE_SIZE * 5;
        const response = yield call(
          api.getActivityCodebookUnused,
          pageSize,
          cursor,
          dateRange.from?.toISOString(),
          dateRange.to?.toISOString()
        );
        const data = response.data;
        yield put(actions.loadActivityCodebookUnused.success(data.data));
        cursor = data.nextCursor;
        hasNextPage = !!cursor;
        pageNumber++;
      }
    }
  } catch (error) {
    yield put(
      actions.loadActivityCodebookUnused.failure({ axiosError: error })
    );
  }
}
function* loadLastProcessedActivityCodebooks() {
  const hcssToken: string | undefined = yield select(
    account.selectors.getHcssToken
  );
  const businessUnitId: string | undefined = yield select(
    account.selectors.getSelectedBusinessUnitId
  );
  try {
    if (hcssToken && businessUnitId) {
      const api = new EstimatesApi(hcssToken, businessUnitId);
      const response = yield call(api.getActivityCodebookLastProcessed);
      yield put(
        actions.loadLastProcessedActivityCodebooks.success(response.data)
      );
    }
  } catch (error) {
    yield put(actions.loadLastProcessedActivityCodebooks.failure(error));
  }
}

function* loadActivityCodebookMissing() {
  const hcssToken: string | undefined = yield select(
    account.selectors.getHcssToken
  );
  const businessUnitId: string | undefined = yield select(
    account.selectors.getSelectedBusinessUnitId
  );
  const dateRangeStr = window.localStorage.getItem("codebooks-date-range");
  const dateRange = dateRangeStr
    ? GetDateValues(JSON.parse(dateRangeStr))
    : GetDateValues();

  try {
    if (hcssToken && businessUnitId) {
      const api = new EstimatesApi(hcssToken, businessUnitId);
      let hasNextPage = true;
      let cursor = "";
      let pageNumber = 0;
      while (hasNextPage) {
        if (pageNumber >= CODEBOOK_MAX_PAGES) {
          yield put(
            actions.loadActivityCodebookMissing.failure({ overflow: true })
          );
          return;
        }
        const pageSize =
          pageNumber < 5 ? CODEBOOK_PAGE_SIZE : CODEBOOK_PAGE_SIZE * 5;
        const response = yield call(
          api.getActivityCodebookMissing,
          pageSize,
          cursor,
          dateRange.from?.toISOString(),
          dateRange.to?.toISOString()
        );
        const responseData = response.data;
        yield put(
          actions.loadActivityCodebookMissing.success(responseData.data)
        );
        cursor = responseData.nextCursor;
        hasNextPage = !!cursor;
        pageNumber++;
      }
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.loadActivityCodebookMissing.failure({ axiosError: error })
    );
  }
}

function* loadActivityCodebookUtilized() {
  const hcssToken: string | undefined = yield select(
    account.selectors.getHcssToken
  );
  const businessUnitId: string | undefined = yield select(
    account.selectors.getSelectedBusinessUnitId
  );
  const dateRangeStr = window.localStorage.getItem("codebooks-date-range");
  const dateRange = dateRangeStr
    ? GetDateValues(JSON.parse(dateRangeStr))
    : GetDateValues();
  try {
    if (hcssToken && businessUnitId) {
      const api = new EstimatesApi(hcssToken, businessUnitId);
      let hasNextPage = true;
      let cursor = "";
      let pageNumber = 0;
      while (hasNextPage) {
        if (pageNumber >= CODEBOOK_MAX_PAGES) {
          yield put(
            actions.loadActivityCodebookUtilized.failure({ overflow: true })
          );
          return;
        }
        const pageSize =
          pageNumber < 5 ? CODEBOOK_PAGE_SIZE : CODEBOOK_PAGE_SIZE * 5;
        const response = yield call(
          api.getActivityCodebookUtilized,
          pageSize,
          cursor,
          dateRange.from?.toISOString(),
          dateRange.to?.toISOString()
        );
        const data = response.data;
        yield put(actions.loadActivityCodebookUtilized.success(data.data));
        cursor = data.nextCursor;
        hasNextPage = !!cursor;
        pageNumber++;
      }
    }
  } catch (error) {
    yield put(
      actions.loadActivityCodebookUtilized.failure({ axiosError: error })
    );
  }
}

function* loadActivityCodebookUtilizedDetails(
  action: ActionType<typeof actions.loadActivityCodebookUtilizedDetails.request>
) {
  const hcssToken: string | undefined = yield select(
    account.selectors.getHcssToken
  );
  const businessUnitId: string | undefined = yield select(
    account.selectors.getSelectedBusinessUnitId
  );
  const dateRangeStr = window.localStorage.getItem("codebooks-date-range");
  const dateRange = dateRangeStr
    ? GetDateValues(JSON.parse(dateRangeStr))
    : GetDateValues();
  try {
    if (hcssToken && businessUnitId) {
      const api = new EstimatesApi(hcssToken, businessUnitId);
      const response = yield call(
        api.getActivityCodebookUtilizedDetails,
        action.payload.partitionId,
        action.payload.activityCode,
        dateRange.from?.toISOString(),
        dateRange.to?.toISOString()
      );
      yield put(
        actions.loadActivityCodebookUtilizedDetails.success(response.data.data)
      );
    }
  } catch (error) {
    notify.error("Error fetching modifications.");
    yield put(actions.loadActivityCodebookUtilizedDetails.failure(error));
  }
}

function* saveEstimate(
  action: ActionType<typeof actions.saveEstimate.request>
) {
  const hcssToken: string | undefined = yield select(
    account.selectors.getHcssToken
  );
  const businessUnitId: string | undefined = yield select(
    account.selectors.getSelectedBusinessUnitId
  );
  const id = action.payload.id;
  if (action.payload.values) {
    try {
      if (hcssToken && businessUnitId) {
        const patch = patchDocument(action.payload.values);
        const api = new EstimatesApi(hcssToken, businessUnitId);
        const response = yield call(api.patchEstimate, id, patch);
        const updatedEstimate: WithId<Estimate> = response.data;
        yield put(
          actions.saveEstimate.success({
            id: updatedEstimate.id,
            values: updatedEstimate
          })
        );
        notify.save(action.payload.code);
      }
    } catch (error) {
      notify.error(
        `Error Saving ${action.payload.code}`,
        `${error?.response?.data?.message}`
      );
      console.error(error);
      yield put(actions.saveEstimate.failure({ id, error }));
    }
  }
}

function* loadEstimatesInitial() {
  yield put(actions.loadEstimates.request());
}

function* onSchemaSave(
  action: ActionType<typeof schemaActions.saveSchema.success>
) {
  const { schemaName } = action.payload;
  if (schemaName === "estimateReporting")
    yield put(actions.loadActivityTotals.request());
}

function* updateEstimates(
  action: ActionType<typeof actions.updateEstimates.request>
) {
  const hcssToken: string | undefined = yield select(
    account.selectors.getHcssToken
  );
  const businessUnitId: string | undefined = yield select(
    account.selectors.getSelectedBusinessUnitId
  );
  try {
    if (hcssToken && businessUnitId) {
      const api = new EstimatesApi(hcssToken, businessUnitId);
      const response = yield call(
        api.getEstimatesForIds,
        action.payload.map(est => est.estimateId)
      );
      const data = response.data;
      const updatedEstimates: WithId<Estimate>[] = data.data;
      yield put(
        actions.updateEstimates.success(
          updatedEstimates.map(est => ({
            values: est,
            id: est.id
          })) || []
        )
      );

      yield all(
        updatedEstimates.map(est => call(loadQuoteFoldersForEstimate, est.id))
      );
    }
  } catch (error) {
    console.error(error);
    yield put(actions.loadEstimates.failure(error));
  }
}

function* loadEstimates() {
  const hcssToken: string | undefined = yield select(
    account.selectors.getHcssToken
  );
  const businessUnitId: string | undefined = yield select(
    account.selectors.getSelectedBusinessUnitId
  );

  try {
    if (hcssToken && businessUnitId) {
      const api = new EstimatesApi(hcssToken, businessUnitId);
      let hasNextPage = true;
      let skip = 0;
      while (hasNextPage) {
        const response = yield call(api.getEstimates, ESTIMATE_PAGE_SIZE, skip);
        const data = response.data;
        let estimates: WithId<Estimate>[] = data.data;
        if (response.status === 204) {
          estimates = [];
        }
        yield put(
          actions.loadEstimates.success(
            estimates.map(estimate => ({
              values: estimate,
              id: estimate.id
            })) || []
          )
        );
        hasNextPage = estimates.length === ESTIMATE_PAGE_SIZE;
        skip = data.nextSkipValue;
        if (data.count > 3000) {
          const newTop = Math.floor((data.count - ESTIMATE_PAGE_SIZE) / 6);
          const skips = [0, 1, 2, 3, 4, 5].map(
            s => s * newTop + ESTIMATE_PAGE_SIZE
          );
          const results = yield all([
            call(api.getEstimates, newTop, skips[0]),
            call(api.getEstimates, newTop, skips[1]),
            call(api.getEstimates, newTop, skips[2]),
            call(api.getEstimates, newTop, skips[3]),
            call(api.getEstimates, newTop, skips[4]),
            call(api.getEstimates, newTop, skips[5])
          ]);
          const estimates: WithId<Estimate>[] = flatten(
            results.map(r => r.data.data)
          );
          yield put(
            actions.loadEstimates.success(
              estimates.map(estimate => ({
                values: estimate,
                id: estimate.id
              })) || []
            )
          );
          break;
        }
      }
      yield put(actions.setLoading({ loading: false }));
    }
  } catch (error) {
    console.error(error);
    yield put(actions.loadEstimates.failure(error));
    yield put(actions.loadedAllEstimates());
  }
}

function* loadQuoteFoldersForEstimate(estimateId: string) {
  const hcssToken: string | undefined = yield select(
    account.selectors.getHcssToken
  );
  const businessUnitId: string | undefined = yield select(
    account.selectors.getSelectedBusinessUnitId
  );

  try {
    if (hcssToken && businessUnitId) {
      const api = new EstimatesApi(hcssToken, businessUnitId);
      const response = yield call(api.getQuoteFolders, estimateId);
      const data = response.data;
      const quoteFolders: QuoteFolderDto[] = data;
      yield put(actions.loadQuoteFolders.success(quoteFolders));
    }
  } catch (error) {
    console.error(error);
    yield put(actions.loadQuoteFolders.failure(error));
  }
}

function* loadActivityTotals() {
  const hcssToken: string | undefined = yield select(
    account.selectors.getHcssToken
  );
  const businessUnitId: string | undefined = yield select(
    account.selectors.getSelectedBusinessUnitId
  );

  const formulas: ActivityFormulaDto[] = yield select(
    schemaSelectors.getBrokenOutActivityTotalFieldsSimple
  );

  try {
    if (hcssToken && businessUnitId) {
      const api = new EstimatesApi(hcssToken, businessUnitId);
      const response = yield call(api.getActivityTotals, formulas);
      const { data } = response;
      yield put(actions.loadActivityTotals.success([data, formulas]));
      yield put(actions.loadedAllEstimates());
    }
  } catch (error) {
    console.error(error);
    yield put(actions.loadActivityTotals.failure(error));
    yield put(actions.loadedAllEstimates());
  }
}

function* loadQuoteFolders() {
  const hcssToken: string | undefined = yield select(
    account.selectors.getHcssToken
  );
  const businessUnitId: string | undefined = yield select(
    account.selectors.getSelectedBusinessUnitId
  );

  try {
    if (hcssToken && businessUnitId) {
      const api = new EstimatesApi(hcssToken, businessUnitId);
      const response = yield call(api.getQuoteFolders);
      const data = response.data;
      const quoteFolders: QuoteFolderDto[] = data;
      yield put(actions.loadQuoteFolders.success(quoteFolders));
    }
  } catch (error) {
    console.error(error);
    yield put(actions.loadQuoteFolders.failure(error));
  }
}

function* updateQuoteFolderList(
  action: ActionType<typeof actions.loadQuoteFolders.success>
) {
  // reading from store because this could triggered by an estimate update
  const allQuoteFolders: Record<string, QuoteFolderDto> = yield select(
    selectors.getQuoteFolders
  );
  const listValues = uniqBy(
    Object.values(allQuoteFolders),
    qf => qf.folder
  ).map(qf => ({ display: qf.folder, value: qf.folder }));

  const schemas: Record<string, WithId<Schema>> = yield select(
    schemaSelectors.getSchemaHash
  );

  yield put(
    schemaActions.saveSchemaField(schemas.quoteDashboard.id, {
      ...schemas.quoteDashboard.fields.quoteFolders,
      config: { listValues }
    })
  );
}

function* loadEstimateFilters() {
  const hcssToken: string | undefined = yield select(
    account.selectors.getHcssToken
  );
  const businessUnitId: string | undefined = yield select(
    account.selectors.getSelectedBusinessUnitId
  );

  try {
    if (hcssToken && businessUnitId) {
      const api = new EstimatesApi(hcssToken, businessUnitId);
      const response = yield call(api.getEstimateFilters);
      const { data } = response.data;
      const estimateFilters: UniqueEstimateFilters = data;

      yield put(actions.loadEstimateFilters.success(estimateFilters));
    }
  } catch (error) {
    console.error(error);
    yield put(actions.loadEstimateFilters.failure(error));
  }
}

function* loadUserDefinedLabels() {
  const hcssToken: string | undefined = yield select(
    account.selectors.getHcssToken
  );
  const businessUnitId: string | undefined = yield select(
    account.selectors.getSelectedBusinessUnitId
  );

  try {
    if (hcssToken && businessUnitId) {
      const api = new EstimatesApi(hcssToken, businessUnitId);
      const response = yield call(api.getUserDefinedLabels);
      const { data } = response.data;
      const userDefinedLabels: Record<string, string> = data;

      yield put(actions.loadUserDefinedLabels.success(userDefinedLabels));
    }
  } catch (error) {
    console.error(error);
    yield put(actions.loadUserDefinedLabels.failure(error));
  }
}

function* updateEstimateFilterList(
  action: ActionType<typeof actions.loadEstimateFilters.success>
) {
  const allEstimateFilters = action.payload;
  const schemas: Record<string, WithId<Schema>> = yield select(
    schemaSelectors.getSchemaHash
  );

  const filters = [
    { fieldName: "values.filters.owner", filterName: "owner" },
    { fieldName: "values.filters.status", filterName: "status" },
    { fieldName: "values.filters.estimator", filterName: "estimator" },
    { fieldName: "values.filters.engineer", filterName: "engineer" },
    { fieldName: "values.filters.typeOfWork", filterName: "typeWork" },
    { fieldName: "values.filters.state", filterName: "wcState" },
    { fieldName: "values.heavyBidDivision", filterName: "businessUnitCode" },
    { fieldName: "values.filters.estimateTag1", filterName: "filter1" },
    { fieldName: "values.filters.estimateTag2", filterName: "filter2" },
    { fieldName: "values.filters.estimateTag3", filterName: "filter3" },
    { fieldName: "values.filters.estimateTag4", filterName: "filter4" },
    { fieldName: "values.filters.estimateTag5", filterName: "filter5" },
    { fieldName: "values.filters.estimateTag6", filterName: "filter6" }
  ];

  for (const filter of filters) {
    yield put(
      schemaActions.saveSchemaField(schemas.estimatesextended.id, {
        ...schemas.estimatesextended.fields[filter.fieldName],
        config: {
          listValues: allEstimateFilters[filter.filterName]
            ?.filter(v => v && v !== "")
            .map((o: string) => ({
              display: o,
              value: o
            }))
        }
      })
    );
  }
}

function* updateEstimateUserDefinedLabels(
  action: ActionType<typeof actions.loadUserDefinedLabels.success>
) {
  const allUserDefinedLabels = action.payload;
  const estimatesExtendedSchema: WithId<Schema> = yield select(
    schemaSelectors.getEstimatesExtendedSchema
  );

  const estimatesExtendedSchemaCopy = { ...estimatesExtendedSchema };
  const estimatesExtendedSchemaFieldsCopy = {
    ...estimatesExtendedSchema.fields
  };

  const userDefinedLabelKeys = Object.keys(allUserDefinedLabels);
  userDefinedLabelKeys.forEach(labelKey => {
    const filterKey = determineFilterKeyForLabelKey(labelKey);
    const filterCopy =
      estimatesExtendedSchemaFieldsCopy[`values.filters.${filterKey}`];
    filterCopy.name = allUserDefinedLabels[labelKey];
  });

  estimatesExtendedSchemaCopy.fields = estimatesExtendedSchemaFieldsCopy;

  yield put(schemaActions.updateSchemas([{ ...estimatesExtendedSchemaCopy }]));
}

function* loadHiddenUnlinkedEstimateIds(
  action: ActionType<typeof actions.loadHiddenUnlinkedEstimateIds.request>
) {
  const hcssToken: string | undefined = yield select(
    account.selectors.getHcssToken
  );
  const businessUnitId: string | undefined = yield select(
    account.selectors.getSelectedBusinessUnitId
  );
  try {
    if (hcssToken && businessUnitId) {
      const api = new EstimatesApi(hcssToken, businessUnitId);
      const result = yield call(api.getHiddenEstimateIds);
      yield put(actions.loadHiddenUnlinkedEstimateIds.success(result.data));
    }
  } catch (error) {
    notify.error(
      strings.projects.estimateMapping.sagas.errors.loadHiddenEstimateIds
    );
    console.error(error);
    yield put(actions.loadHiddenUnlinkedEstimateIds.failure(error));
  }
}

function* patchHiddenUnlinkedEstimateIds(
  action: ActionType<typeof actions.patchHiddenUnlinkedEstimateIds.request>
) {
  const hcssToken: string | undefined = yield select(
    account.selectors.getHcssToken
  );
  const businessUnitId: string | undefined = yield select(
    account.selectors.getSelectedBusinessUnitId
  );
  try {
    if (hcssToken && businessUnitId) {
      const api = new EstimatesApi(hcssToken, businessUnitId);
      const result = yield call(api.patchHiddenEstimateIds, action.payload.ids);
      yield put(actions.patchHiddenUnlinkedEstimateIds.success(result.data));
      const message =
        action.payload.action === "Hide"
          ? strings.projects.estimateMapping.sagas.success.hide
          : strings.projects.estimateMapping.sagas.success.unhide;
      if (action.payload.action) notify.success(message);
    }
  } catch (error) {
    notify.error(
      strings.projects.estimateMapping.sagas.errors.updateHiddenEstimateIds
    );
    console.error(error);
    yield put(actions.loadHiddenUnlinkedEstimateIds.failure(error));
  }
}
export const sagas = [
  //Activity totals should only load once the estimates are done loading,
  //and the estimates should only load once the schemas are done loading
  // (activity totals depend on both)
  takeEvery(getType(actions.setLoading), loadActivityTotals),
  takeEvery(getType(actions.loadActivityTotals.request), loadActivityTotals),
  takeLatest(getType(schemaActions.saveSchema.success), onSchemaSave),
  takeLatest(getType(actions.loadEstimates.request), loadEstimates),
  takeEvery(getType(actions.saveEstimate.request), saveEstimate),
  //FIX ME: Checking for both schema failure and sucess is a temporary fix
  // to allow estimates to still be loaded even if the schemas call fails
  // This can be changed when the schemas call isn't forced to throw
  // a 403 for users without access to project tracking
  takeEvery(getType(schemaActions.loadSchemas.success), loadEstimatesInitial),
  takeEvery(getType(schemaActions.loadSchemas.failure), loadEstimatesInitial),
  takeEvery(getType(actions.updateEstimates.request), updateEstimates),
  takeLatest(getType(actions.loadQuoteFolders.request), loadQuoteFolders),
  takeEvery(getType(actions.loadQuoteFolders.success), updateQuoteFolderList),
  takeLatest(getType(actions.loadEstimateFilters.request), loadEstimateFilters),
  takeLatest(
    getType(actions.loadUserDefinedLabels.request),
    loadUserDefinedLabels
  ),
  takeLatest(
    getType(actions.loadUserDefinedLabels.success),
    updateEstimateUserDefinedLabels
  ),
  takeEvery(
    getType(actions.loadEstimateFilters.success),
    updateEstimateFilterList
  ),
  takeEvery(
    getType(actions.loadHiddenUnlinkedEstimateIds.request),
    loadHiddenUnlinkedEstimateIds
  ),
  takeEvery(
    getType(actions.patchHiddenUnlinkedEstimateIds.request),
    patchHiddenUnlinkedEstimateIds
  ),
  takeLatest(
    getType(actions.loadHeavybidDivisions.request),
    loadHeavybidDivisions
  ),
  takeLatest(
    getType(actions.loadActivityCodebookUnused.request),
    loadActivityCodebookUnused
  ),
  takeLatest(
    getType(actions.loadActivityCodebookMissing.request),
    loadActivityCodebookMissing
  ),
  takeLatest(
    getType(actions.loadActivityCodebookUtilized.request),
    loadActivityCodebookUtilized
  ),
  takeLatest(
    getType(actions.loadActivityCodebookUtilizedDetails.request),
    loadActivityCodebookUtilizedDetails
  ),
  takeLatest(
    getType(actions.loadLastProcessedActivityCodebooks.request),
    loadLastProcessedActivityCodebooks
  )
];
