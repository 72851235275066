import config from "config";

export const buildFenixURL = (
  projectId: string,
  projectName: string,
  preconIdSeed: string,
  businessUnitCode: string
) =>
  encodeURI(
    `${config.endpoints.FENIX}/estimates?code=${preconIdSeed}&description=${projectName}&projectId=${projectId}&buCode=${businessUnitCode}`
  );
