import { HubConnectionBuilder } from "@microsoft/signalr";

export const createConnection = (
  token: string,
  requestedBusinessUnitId: string
) => {
  return new HubConnectionBuilder()
    .withUrl(`/entityhub?businessUnit=${requestedBusinessUnitId}`, {
      accessTokenFactory: () => {
        return token;
      }
    })
    .build();
};
