import { SchemaFieldType } from "api";
import { strings } from "localization";

export const activityCodebooksSchema = () => ({
  id: "activityCodebooks",
  name: strings.estimates.codebooks.schema.name,
  schemaName: "activityCodebooks",
  fields: {
    "codebook.code": {
      id: "codebook.code",
      name: strings.estimates.codebooks.schema.codebookCode,
      type: SchemaFieldType.ShortText,
      config: {},
      readOnly: false,
      required: true,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    "codebook.description": {
      id: "codebook.description",
      name: strings.estimates.codebooks.schema.codebookDescription,
      type: SchemaFieldType.ShortText,
      config: {},
      readOnly: false,
      required: true,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    "codebook.level": {
      id: "codebook.level",
      name: strings.estimates.codebooks.schema.codebookLevel,
      type: SchemaFieldType.ShortText,
      config: {},
      readOnly: false,
      required: true,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    "codebook.units": {
      id: "codebook.units",
      name: strings.estimates.codebooks.schema.codebookUnits,
      type: SchemaFieldType.ShortText,
      config: {},
      readOnly: false,
      required: true,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    "codebook.lastUpdate": {
      id: "codebook.lastUpdate",
      name: strings.estimates.codebooks.schema.codebookLastUpdate,
      type: SchemaFieldType.Date,
      config: {},
      readOnly: false,
      required: true,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    "codebook.timesUsed": {
      id: "codebook.timesUsed",
      name: strings.estimates.codebooks.schema.codebookTimesUsed,
      type: SchemaFieldType.Number,
      config: {},
      readOnly: false,
      required: true,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    "codebook.timesModified": {
      id: "codebook.timesModified",
      name: strings.estimates.codebooks.schema.codebookTimesModified,
      type: SchemaFieldType.Number,
      config: {},
      readOnly: false,
      required: true,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    "codebook.partitionId": {
      id: "codebook.partitionId",
      name: strings.estimates.codebooks.schema.codebookPartitionId,
      type: SchemaFieldType.ShortText,
      config: {},
      readOnly: false,
      required: true,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    "codebook.modifiedCount": {
      id: "codebook.modifiedCount",
      name: strings.estimates.codebooks.schema.codebookModifiedCount,
      type: SchemaFieldType.Number,
      config: {},
      readOnly: false,
      required: true,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    "activity.code": {
      id: "activity.code",
      name: strings.estimates.codebooks.schema.activityCode,
      type: SchemaFieldType.ShortText,
      config: {},
      readOnly: false,
      required: true,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    "activity.description": {
      id: "activity.description",
      name: strings.estimates.codebooks.schema.activityDescription,
      type: SchemaFieldType.ShortText,
      config: {},
      readOnly: false,
      required: true,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    "activity.units": {
      id: "activity.units",
      name: strings.estimates.codebooks.schema.activityUnits,
      type: SchemaFieldType.ShortText,
      config: {},
      readOnly: false,
      required: true,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    "activity.biditem": {
      id: "activity.biditem",
      name: strings.estimates.codebooks.schema.biditem,
      type: SchemaFieldType.ShortText,
      config: {},
      readOnly: false,
      required: true,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    "activity.estimate": {
      id: "activity.estimate",
      name: strings.estimates.codebooks.schema.estimate,
      type: SchemaFieldType.ShortText,
      config: {},
      readOnly: false,
      required: true,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    "activity.lastModified": {
      id: "activity.lastModified",
      name: strings.estimates.codebooks.schema.activityLastModified,
      type: SchemaFieldType.DateTime,
      config: {},
      readOnly: false,
      required: true,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    },
    "activity.partitionId": {
      id: "activity.partitionId",
      name: strings.estimates.codebooks.schema.activityPartitionId,
      type: SchemaFieldType.ShortText,
      config: {},
      readOnly: false,
      required: true,
      standard: true,
      protected: true,
      hiddenInTable: false,
      hiddenInForm: false,
      lookup: false
    }
  },
  sections: {
    codebookInfo: {
      id: "codebookInfo",
      name: "Codebook Info",
      fields: [
        "codebook.code",
        "codebook.description",
        "codebook.units",
        "codebook.level",
        "codebook.lastUpdate",
        "codebook.partitionId",
        "codebook.modifiedCount",
        "codebook.timesUsed",
        "codebook.timesModified",
        "activity.code",
        "activity.description",
        "activity.units",
        "activity.biditem",
        "activity.estimate",
        "activity.lastModified",
        "activity.partitionId"
      ]
    }
  },
  orderedSections: ["codebookInfo"]
});
