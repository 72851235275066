import React from "react";
import styled from "styled-components";
import { StandardColors, ConcreteColors } from "hcss-components";

export const DeclinePanel = () => {
  return (
    <DeclineContainer
      className="decline-container"
      data-testid="decline-container"
    >
      <div className="success-container">
        <i className="fa fa-check" />
      </div>

      <div className="title">Response Updated</div>
      <div className="subtitle">
        You can change the status and see quote details at any time. Just click
        'See Details' in the email.
      </div>
    </DeclineContainer>
  );
};

const DeclineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 420px;
  max-width: 500px;
  padding: 50px;
  margin-bottom: 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.2);

  .title {
    font-size: 26px;
    font-weight: 600;
    color: ${ConcreteColors.gray600};
  }

  .subtitle {
    margin-top: 5px;
    font-size: 13px;
    font-weight: 500;
    color: ${ConcreteColors.gray500};
    text-align: center;
  }

  .success-container {
    color: ${StandardColors.hcssHeavyBid};
    margin-bottom: 40px;
  }

  .signin-link {
    margin-top: 60px;
  }
`;
