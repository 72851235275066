import * as React from "react";
import styled, { withTheme } from "styled-components";
import { PaneledPageExtendedProps } from "./ExtendedProps";
import {
  PaneledPageContext,
  PaneledPageContextState
} from "./PaneledPageContext";
import ContentAside from "./ContentAside";
import { SectionListModel } from "./ContentSectionDataModels";
import StatusFlag from "./StatusFlag";

export const StyledTableOfContents = styled("div")`
  @media print {
    display: none;
  }

  position: relative;
  z-index: 2;

  & > ul {
    list-style: none;
    margin: 0 0 0 2px;
    padding: 0;

    & > li {
      height: 28px;

      & > a {
        display: inline-block;
        max-width: calc(100% - 20px);
        font-size: 13px;
        line-height: 28px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: 0.2s;
        color: #1e1e1e;
        cursor: pointer;

        &:hover {
          color: #0370f5;
        }
      }

      & > .section-flag-container {
        display: inline-block;
        position: absolute;
        height: 28px;
        right: 0;
        line-height: 28px;

        & * {
          display: inline-block;
        }
      }

      &[data-current-section="true"] {
        & > a {
          font-weight: 600;
          font-size: 14.5px;
          color: #0370f5;
        }
      }
    }
  }

  & .selected-section-indicator {
    position: absolute;
    margin-left: -12px;
    transition: 0.5s;

    &:before {
      content: "";
      display: block;
      position: absolute;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 8px solid #0370f5;
    }
  }
`;

export const isTableOfContentsTemplate = (
  node: any
): node is SectionTableOfContentsTemplate => {
  if (
    node &&
    node.props &&
    node.type.componentType === "SectionTableOfContentsTemplate" &&
    (node as SectionTableOfContentsTemplate).props.sectionFlag !== undefined
  )
    return true;
  else return false;
};

export interface SectionTableOfContentsTemplateProps
  extends PaneledPageExtendedProps {
  /** Optional "flag" displayed in table of contents next to the name of any section that is "flagged". Defaults to an orange asterisk. */
  sectionFlag?: any;
  /** Automation testing id for the ContentSection component. Defaults to "paneledpage-content-section-{sectionIndex}".
   * Available wildcards:
   * %CURRENTINDEX% - the index of the current section  */
  testId?: string;
}
export class SectionTableOfContentsTemplate extends React.PureComponent<
  SectionTableOfContentsTemplateProps
> {
  static componentType = "SectionTableOfContentsTemplate";

  static defaultProps: SectionTableOfContentsTemplateProps = {
    sectionFlag: <StatusFlag />
  };
}

export interface SectionTableOfContentsProps
  extends SectionTableOfContentsTemplateProps {
  allSections: SectionListModel[];
  currentSectionIndex: number | null;
  onSelectSection: (sectionIndex: number) => void;
  theme?: any;
}
class SectionTableOfContents extends React.PureComponent<
  SectionTableOfContentsProps
> {
  static componentType = "SectionTableOfContents";

  moveIndicatorToCurrentSection = (
    currentSectionIndex: number | null
  ): string => {
    const getOffsetCompensationAmount = (): number => {
      if (this.props.theme) {
        return this.props.theme.baseTheme === "Concrete" ? 19 : 20;
      } else return 20;
    };
    if (currentSectionIndex !== null) {
      const offsetFactor = 28;
      return `${
        offsetFactor * (currentSectionIndex + 1) - getOffsetCompensationAmount()
      }px`;
    } else return "-500px";
  };

  renderTestId = (): string => {
    if (this.props.testId)
      return this.props.testId.replace(
        /%(CURRENTINDEX)%/g,
        `${this.props.currentSectionIndex}`
      );
    else return "paneledpage-content-tableofcontents";
  };

  // The Table of Contents is rendered inside a ContentAside which acts as a wrapper
  // This allows for more consistent styling and layout rules.

  render() {
    return (
      <PaneledPageContext.Consumer>
        {(pageContext: PaneledPageContextState) => (
          <ContentAside
            pageContext={pageContext}
            testId="content-aside-TOC-wrapper"
            fillContentHeight={false}
          >
            <StyledTableOfContents
              className="section-table-of-contents"
              data-testid={this.renderTestId()}
              {...this.props.passThroughProps}
            >
              <ul className="section-list">
                {this.props.allSections && this.props.allSections.length > 0
                  ? this.props.allSections.map((section, index) => {
                      const sectionFlagged: boolean =
                        section.sectionFlagged || false;
                      return (
                        <li
                          key={index}
                          className="section-list-item"
                          data-current-section={
                            section.sectionIndex ===
                            this.props.currentSectionIndex
                          }
                        >
                          {/* eslint-disable-next-line */}
                          <a
                            onClick={() =>
                              this.props.onSelectSection(section.sectionIndex)
                            }
                            title={`Go to section "${section.sectionTitle}"`}
                          >
                            {section.sectionTitle}
                          </a>
                          {sectionFlagged && (
                            <div
                              className="section-flag-container"
                              onClick={() =>
                                this.props.onSelectSection(section.sectionIndex)
                              }
                            >
                              {this.props.sectionFlag}
                            </div>
                          )}
                        </li>
                      );
                    })
                  : ""}
              </ul>
              <div
                className="selected-section-indicator"
                style={{
                  top: this.moveIndicatorToCurrentSection(
                    this.props.currentSectionIndex
                  )
                }}
              />
            </StyledTableOfContents>
          </ContentAside>
        )}
      </PaneledPageContext.Consumer>
    );
  }
}

export default withTheme(SectionTableOfContents);
