import styled from "styled-components";
import { ControlLabel } from "hcss-components";

export const ToolGrid = styled.div`
  max-width: 100vw;

  @supports (display: grid) {
    display: grid;
    grid-column-gap: 32px;
    grid-row-gap: 24px;
    align-items: center;
    grid-template-areas:
      "buttonsLeft buttonsRight"
      "template template";
    grid-template-columns: 1/2fr 1/2fr;
    grid-template-rows: auto auto;

    @media (min-width: 800px) {
      justify-items: center;
      grid-template-columns: 20rem 1fr auto;
      grid-template-rows: 36px;
      grid-template-areas: "buttonsLeft template buttonsRight";
    }
  }
`;

export const GridContainer = styled.div<{ area: string }>`
  float: left;
  width: 100%;
  @supports (display: grid) {
    float: none;
    grid-area: ${props => props.area};
  }
`;

export const TemplateContainer = styled(GridContainer)`
  grid-area: template;
  display: grid;
  grid-gap: 10px;

  align-items: center;
  width: 100%;
  max-width: 900px;

  grid-template-columns: auto auto 1fr auto;
  grid-template-rows: auto;
  grid-template-areas: "label access input options";
`;

export const TemplateLabel = styled(ControlLabel)`
  text-align: right;
`;
