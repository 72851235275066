import produce from "immer";
import { User } from "oidc-client";
import { Tutorials } from "api";
import {
  CurrentUser,
  AuthenticationMethod,
  GuestUserClaims,
  UserType
} from "core";
import { BrowserFingerPrint } from "./components/confirm/confirm-panel";

import {
  ActionType,
  createAsyncAction,
  getType,
  createAction
} from "typesafe-actions";

import {
  PreConPermissions,
  UserConfigDto as UserPreferences,
  CredentialsUserDto,
  IdentityClientsDto
} from "api/GeneratedClients/precon";

export const STATE_KEY = "account";

export interface StateSlice {
  [STATE_KEY]: State;
}

export interface State {
  confirming: boolean;
  declining: boolean;
  loading: boolean;
  isAuthenticated: boolean;
  firUser?: firebase.User;
  user?: Readonly<CurrentUser>;
  company?: Readonly<CompanyDetails>;
  businessUnit?: Readonly<CompanyDetails>;
  companyClients?: CompanyClientsDetails;
  authenticationMethod: AuthenticationMethod;
  authenticationError?: Error;
  authorization: AccountAuthorization;
  authorizedResource?: string;
  permissions?: PreConPermissions;
  preferences?: UserPreferences;
  errors: string[];
}

export interface CompanyDetails {
  id: string;
  users: CredentialsUserDto[];
  loading: boolean;
}

export interface CompanyClientsDetails {
  companyClients: IdentityClientsDto[];
  loading: boolean;
}

export interface BUDetails {
  id: string;
  users: CredentialsUserDto[];
  loading: boolean;
}

export interface CompanyPayload {
  id: string;
  users: CredentialsUserDto[];
}

export interface CompanyClientsPayload {
  clients: IdentityClientsDto[];
}

export interface AccountAuthorization {
  canAccessHeavyBidInsights: boolean;
  canAccessQuoteManagement: boolean;
  canAccessHcssConnect: boolean;
  canAccessFullPrecon: boolean;
  canAccessFullReadonlyPrecon: boolean;
  canAccessLimitedPrecon: boolean;
}

export interface DeclinePayload {
  id: string;
  token: string;
}

export interface SignInPayload {
  id: string;
  email: string;
  token: string;
  fingerPrint?: BrowserFingerPrint;
  mode?: string;
}

export interface GuestUserPayload {
  user: CurrentUser;
  firUser: firebase.User;
  claims: GuestUserClaims;
  authorization: AccountAuthorization;
  type: AuthenticationMethod;
}

export interface UserPayload {
  firUser: firebase.User;
  user: CurrentUser;
  type: AuthenticationMethod;
  authorization: AccountAuthorization;
  permissions?: PreConPermissions;
  preferences?: UserPreferences;
}

export const actions = {
  signInWithEmailLink: createAsyncAction(
    "ACCOUNT/AUTH/SIGNIN_EMAIL_LINK_REQUEST",
    "ACCOUNT/AUTH/SIGNIN_EMAIL_LINK_SUCCESS",
    "ACCOUNT/AUTH/SIGNIN_EMAIL_LINK_FAILURE"
  )<SignInPayload, GuestUserPayload, Error>(),

  signInWithHcssCredentials: createAsyncAction(
    "ACCOUNT/AUTH/SIGNIN_HCSS_REQUEST",
    "ACCOUNT/AUTH/SIGNIN_HCSS_SUCCESS",
    "ACCOUNT/AUTH/SIGNIN_HCSS_FAILURE"
  )<User, UserPayload, Error>(),

  getCompanyUsers: createAsyncAction(
    "ACCOUNT/AUTH/GET_COMPANY_USERS_REQUEST",
    "ACCOUNT/AUTH/GET_COMPANY_USERS_SUCCESS",
    "ACCOUNT/AUTH/GET_COMPANY_USERS_FAILURE"
  )<undefined, CompanyPayload, Error>(),

  getCompanyClients: createAsyncAction(
    "ACCOUNT/AUTH/GET_COMPANY_CLIENTS_REQUEST",
    "ACCOUNT/AUTH/GET_COMPANY_CLIENTS_SUCCESS",
    "ACCOUNT/AUTH/GET_COMPANY_CLIENTS_FAILURE"
  )<undefined, CompanyClientsPayload, Error>(),

  getBusinessUnitUsers: createAsyncAction(
    "ACCOUNT/AUTH/GET_BU_USERS_REQUEST",
    "ACCOUNT/AUTH/GET_BU_USERS_SUCCESS",
    "ACCOUNT/AUTH/GET_BU_USERS_FAILURE"
  )<undefined, CompanyPayload, Error>(),

  getUser: createAsyncAction(
    "ACCOUNT/AUTH/GET_USER_REQUEST",
    "ACCOUNT/AUTH/GET_USER_SUCCESS",
    "ACCOUNT/AUTH/GET_USER_FAILURE"
  )<string, void, Error>(),

  declineInvitation: createAsyncAction(
    "ACCOUNT/AUTH/DECLINE_INVITE_REQUEST",
    "ACCOUNT/AUTH/DECLINE_INVITE_SUCCESS",
    "ACCOUNT/AUTH/DECLINE_INVITE_FAILURE"
  )<DeclinePayload, undefined, Error>(),

  signIn: createAction("ACCOUNT/AUTH/SIGNIN", action => () => action()),
  signOut: createAction("ACCOUNT/AUTH/SIGNOUT", action => () => action()),

  subscribeToAuthState: createAction(
    "ACCOUNT/AUTH/SUBSCRIBE_TO_AUTH_STATE",
    action => () => action()
  ),

  unsubscribeFromAuthState: createAction(
    "ACCOUNT/AUTH/UNSUBSCRIBE_FROM_AUTH_STATE",
    action => () => action()
  ),

  updateGuestAuthState: createAction(
    "ACCOUNT/AUTH/UPDATE_GUEST_AUTH_STATE",
    action => (payload?: GuestUserPayload) => action(payload)
  ),

  updateUserAuthState: createAction(
    "ACCOUNT/AUTH/UPDATE_USER_AUTH_STATE",
    action => (payload?: UserPayload) => action(payload)
  ),

  updateOidcUser: createAction(
    "ACCOUNT/AUTH/UPDATE_OIDC_USER",
    action => (payload: User) => action(payload)
  ),

  updatePreferences: createAsyncAction(
    "ACCOUNT/USER/UPDATE_PREFERENCES_REQUEST",
    "ACCOUNT/USER/UPDATE_PREFERENCES_SUCCESS",
    "ACCOUNT/USER/UPDATE_PREFERENCES_FAILURE"
  )<Partial<UserPreferences>, UserPreferences, Error>(),

  setTutorialCompleted: createAction(
    "ACCOUNT/USER/SET_TUTORIAL_COMPLETED",
    resolve => (tutorialId: keyof Tutorials) => resolve({ tutorialId })
  ),

  updateAppAccess: createAction(
    "ACCOUNT/AUTH/APP_ACCESS",
    action => (payload?: keyof AccountAuthorization) => action(payload)
  ),

  updatePermissions: createAction(
    "ACCOUNT/AUTH/PERMISSIONS",
    action => (payload?: keyof PreConPermissions) => action(payload)
  ),

  updateAccountType: createAction(
    "ACCOUNT/AUTH/TYPE",
    action => (payload: UserType) => action(payload)
  )
};

export type UserActions = ActionType<typeof actions>;

const initialState: State = {
  confirming: false,
  declining: false,
  loading: false,
  isAuthenticated: false,
  authorization: {
    canAccessHeavyBidInsights: false,
    canAccessQuoteManagement: false,
    canAccessHcssConnect: false,
    canAccessLimitedPrecon: false,
    canAccessFullPrecon: false,
    canAccessFullReadonlyPrecon: false
  },
  authenticationMethod: AuthenticationMethod.None,
  errors: []
};

export const reducer = (state = initialState, action: UserActions) => {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getUser.request): {
        draft.loading = true;
        break;
      }

      case getType(actions.getUser.success):
      case getType(actions.getUser.failure): {
        draft.loading = false;
        break;
      }

      case getType(actions.signInWithEmailLink.request): {
        draft.confirming = true;
        break;
      }

      case getType(actions.signInWithEmailLink.success): {
        draft.confirming = false;
        draft.isAuthenticated = true;
        draft.authorizedResource = action.payload.claims.resourceId;
        draft.user = action.payload.user;
        draft.firUser = action.payload.firUser;
        draft.authenticationMethod = AuthenticationMethod.OneClickLink;
        draft.authorization = action.payload.authorization;
        draft.preferences = {
          tablePreferences: null,
          tutorials: null,
          region: "en-us"
        };
        draft.permissions = {
          admin: false,
          write: false,
          heavyBidNumbers: false,
          estimateInsights: false
        };
        break;
      }

      case getType(actions.signInWithEmailLink.failure): {
        draft.confirming = false;
        draft.authenticationError = action.payload;
        draft.isAuthenticated = false;
        draft.authenticationMethod = AuthenticationMethod.None;
        break;
      }

      case getType(actions.signInWithHcssCredentials.request): {
        draft.loading = true;
        break;
      }

      case getType(actions.signInWithHcssCredentials.success): {
        draft.loading = false;
        draft.isAuthenticated = true;
        draft.firUser = action.payload.firUser;
        draft.user = action.payload.user;
        draft.authenticationMethod = AuthenticationMethod.HcssCredentials;
        draft.authorization = action.payload.authorization;
        draft.permissions = action.payload.permissions;
        draft.preferences = action.payload.preferences;
        break;
      }

      case getType(actions.getCompanyUsers.success): {
        draft.company = {
          ...action.payload,
          loading: false
        };
        break;
      }

      case getType(actions.getCompanyUsers.failure): {
        draft.company = {
          id: "",
          users: [],
          loading: false
        };
        break;
      }

      case getType(actions.getCompanyClients.request): {
        draft.companyClients = {
          companyClients: [],
          loading: true
        };
        break;
      }

      case getType(actions.getCompanyClients.success): {
        draft.companyClients = {
          companyClients: action.payload.clients,
          loading: false
        };
        break;
      }

      case getType(actions.getCompanyClients.failure): {
        draft.companyClients = {
          companyClients: [],
          loading: false
        };
        break;
      }

      case getType(actions.getBusinessUnitUsers.success): {
        draft.businessUnit = {
          ...action.payload,
          loading: false
        };
        break;
      }

      case getType(actions.getBusinessUnitUsers.failure): {
        draft.businessUnit = {
          id: "",
          users: [],
          loading: false
        };
        break;
      }

      case getType(actions.signInWithHcssCredentials.failure): {
        draft.loading = false;
        draft.isAuthenticated = false;
        draft.firUser = undefined;
        draft.user = undefined;
        draft.authenticationMethod = AuthenticationMethod.None;
        draft.authenticationError = action.payload;
        draft.errors.push(`Error during sign in: ${action.payload.message}`);
        break;
      }

      case getType(actions.declineInvitation.request): {
        draft.declining = true;
        break;
      }

      case getType(actions.declineInvitation.success): {
        draft.declining = false;
        break;
      }

      case getType(actions.declineInvitation.failure): {
        draft.declining = false;
        break;
      }

      case getType(actions.updateGuestAuthState): {
        draft.loading = false;
        draft.isAuthenticated = action.payload ? true : false;
        draft.firUser = action.payload ? action.payload.firUser : undefined;
        draft.authenticationMethod = action.payload
          ? action.payload.type
          : AuthenticationMethod.None;
        draft.authorizedResource = action.payload
          ? action.payload.claims.resourceId
          : undefined;
        break;
      }

      case getType(actions.updateUserAuthState): {
        draft.loading = false;
        draft.isAuthenticated = action.payload ? true : false;
        draft.firUser = action.payload ? action.payload.firUser : undefined;
        draft.user = action.payload
          ? { ...draft.user, ...action.payload.user }
          : undefined;
        draft.authenticationMethod = action.payload
          ? action.payload.type
          : AuthenticationMethod.None;
        draft.authorizedResource = undefined;
        break;
      }

      case getType(actions.signOut): {
        draft.loading = true;
        break;
      }

      case getType(actions.updatePreferences.success): {
        draft.preferences = action.payload;
        break;
      }

      case getType(actions.setTutorialCompleted): {
        if (draft.preferences?.tutorials) {
          draft.preferences.tutorials[action.payload.tutorialId] = true;
        }
        break;
      }

      case getType(actions.updateAppAccess): {
        draft.authorization[action.payload] = !draft.authorization[
          action.payload
        ];
        break;
      }
      case getType(actions.updatePermissions): {
        if (draft.permissions) {
          draft.permissions[action.payload] = !draft.permissions[
            action.payload
          ];
        }
        break;
      }
      case getType(actions.updateAccountType): {
        if (draft.user) {
          draft.user.type = action.payload;
        }
        break;
      }
    }
  });
};

const getAccessibleBusinessUnits = ({ account }: StateSlice) =>
  account.user?.accessibleBusinessUnits;
const getHomeBusinessUnit = ({ account }: StateSlice) => {
  return {
    code: account.user?.homeBusinessUnitName,
    id: account.user?.homeBusinessUnitId
  };
};
const getSelectedBusinessUnit = ({ account }: StateSlice) => {
  return {
    code: account.user?.selectedBusinessUnitName,
    id: account.user?.selectedBusinessUnitId
  };
};
const getSelectedBusinessUnitId = ({ account }: StateSlice) => {
  return account.user?.selectedBusinessUnitId;
};
const getAccount = ({ account }: StateSlice) => account;
const getHcssUser = ({ account }: StateSlice) => account.user;
const getHcssToken = ({ account }: StateSlice) =>
  account.user?.idsrvAccessToken;
const getPreferences = ({ account }: StateSlice) => account.preferences;
const getPermissions = ({ account }: StateSlice) => account.permissions;
const getAuthorization = ({ account }: StateSlice) => account.authorization;
const getErrors = ({ account }: StateSlice) => account.errors;
const getCompanyUsers = ({ account }: StateSlice) =>
  account.company?.users ?? [];
const getCompanyClients = ({ account }: StateSlice) =>
  account.companyClients?.companyClients ?? [];
const getBusinessUnitUsers = ({ account }: StateSlice) =>
  account.businessUnit?.users ?? [];

export const createUserDictFromUserList = (users: CredentialsUserDto[]) => {
  const userDict: Record<string, CredentialsUserDto> = {};
  users.forEach(user => {
    const firstName = user.firstName;
    const lastName = user.lastName;
    const username = user.username;
    const isDeleted = user.isDeleted;
    const email = user.email;

    if (user.id)
      userDict[user.id] = {
        id: user.id,
        firstName,
        lastName,
        username,
        isDeleted,
        email
      };
  });
  return userDict;
};

export const selectors = {
  getAccessibleBusinessUnits,
  getAccount,
  getHomeBusinessUnit,
  getHcssUser,
  getHcssToken,
  getPreferences,
  getPermissions,
  getSelectedBusinessUnitId,
  getSelectedBusinessUnit,
  getAuthorization,
  getErrors,
  getCompanyUsers,
  getCompanyClients,
  getBusinessUnitUsers
};
