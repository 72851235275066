import { EstimateEntity } from "api";
import {
  ActivityFormulaDto,
  ActivityTotal,
  FormulaMatchOptions
} from "api/GeneratedClients/insights";
import { WithId } from "core";

export function normalizeDate(dateString?: string | null) {
  if (dateString && dateString.indexOf("+00:00")) {
    return dateString.substring(0, 19);
  }
  return dateString;
}

export function normalizeEstimateDates(estimate: WithId<EstimateEntity>) {
  if (estimate.values.filters) {
    estimate.values.filters.bidDate = normalizeDate(
      estimate.values.filters.bidDate
    );
    estimate.values.filters.startDate = normalizeDate(
      estimate.values.filters.startDate
    );
    estimate.values.filters.reviewDate = normalizeDate(
      estimate.values.filters.reviewDate
    );
    estimate.values.filters.createdDate = normalizeDate(
      estimate.values.filters.createdDate
    );
    estimate.values.filters.userDefinedDate1 = normalizeDate(
      estimate.values.filters.userDefinedDate1
    );
    estimate.values.filters.userDefinedDate2 = normalizeDate(
      estimate.values.filters.userDefinedDate2
    );
    estimate.values.filters.userDefinedDate3 = normalizeDate(
      estimate.values.filters.userDefinedDate3
    );
    estimate.values.filters.userDefinedDate4 = normalizeDate(
      estimate.values.filters.userDefinedDate4
    );
    estimate.values.filters.userDefinedDate5 = normalizeDate(
      estimate.values.filters.userDefinedDate5
    );
  }
  if (estimate.values.totals) {
    estimate.values.totals.summary_Date = normalizeDate(
      estimate.values.totals.summary_Date
    );
    estimate.values.totals.spread_Date = normalizeDate(
      estimate.values.totals.spread_Date
    );
  }
  return estimate;
}

export const shouldGenerateKV = (
  formula: ActivityFormulaDto,
  item: ActivityTotal
) => {
  const itemCode = item.activityCode.toLocaleUpperCase();
  const formulaExpression = formula.expression.toLocaleUpperCase();
  const itemUnit = item.units.toLocaleUpperCase();
  const formulaUnit = formula.unit.toLocaleUpperCase();
  switch (formula.match) {
    case FormulaMatchOptions.StartsWith:
      if (itemCode.startsWith(formulaExpression) && itemUnit === formulaUnit)
        return true;
      return false;
    case FormulaMatchOptions.Exact:
      if (itemCode === formulaExpression && itemUnit === formulaUnit)
        return true;
      return false;
  }
};

export const generateActivityTotalFormulaKey = (
  match: FormulaMatchOptions,
  expression: string,
  unit: string
) => {
  //"." is the real issue here. there doesn't seem to be a good native
  // way to encode ".". I think replacing it is sufficient and is unlikely to cause
  // users issues
  return encodeURIComponent(
    `${match}-${expression.toLocaleUpperCase()}-${unit.toLocaleUpperCase()}`.replace(
      /\./g,
      ""
    )
  );
};

export const mapActivityTotalsBackToFormulas = (
  activityTotals: ActivityTotal[],
  formulas: ActivityFormulaDto[]
) => {
  const estWithFormulasAndTotals: Record<string, EstimateToFormulaDict> = {};

  activityTotals.forEach(activityTotal => {
    const { estimateId, cost, quantity } = activityTotal;
    formulas.forEach(formula => {
      const match = formula.match;
      const expression = formula.expression;
      const unit = formula.unit;
      const uniqueKey = generateActivityTotalFormulaKey(
        match,
        expression,
        unit
      );
      const generateKV = shouldGenerateKV(formula, activityTotal);
      if (!estWithFormulasAndTotals[estimateId])
        estWithFormulasAndTotals[estimateId] = {};
      if (generateKV) {
        const currentCost =
          estWithFormulasAndTotals[estimateId][uniqueKey]?.totals?.cost ?? 0;

        const currentQuantity =
          estWithFormulasAndTotals[estimateId][uniqueKey]?.totals?.quantity ??
          0;

        estWithFormulasAndTotals[estimateId][uniqueKey] = {
          totals: {
            cost: currentCost + cost,
            quantity: currentQuantity + quantity
          },
          expression,
          unit,
          match
        };
      }
    });
  });

  return estWithFormulasAndTotals;
};

export interface EstimateToFormulaDict {
  [key: string]: {
    match: FormulaMatchOptions;
    expression: string;
    unit: string;
    totals: {
      cost: number;
      quantity: number;
    };
  };
}

//This method is used to map estimate filter labels from their EH key, to their PreCon key,
// "tag2" -> "estimateTag2"
export const determineFilterKeyForLabelKey = (labelKey: string) => {
  if (labelKey.includes("tag")) {
    return labelKey.replace("tag", "estimateTag");
  }
  if (labelKey.includes("integer")) {
    return labelKey.replace("integer", "userDefinedInteger");
  }
  if (labelKey.includes("date")) {
    return labelKey.replace("date", "userDefinedDate");
  }
  if (labelKey.includes("text")) {
    return labelKey.replace("text", "userDefinedText");
  }
  if (labelKey.includes("number")) {
    return labelKey.replace("number", "userDefinedNumber");
  }
  return labelKey;
};

//This method is used to map estimate filter labels from their HBFields.ts key, to their PreCon key,
// "estimateFilter1" -> "estimateTag1"
export const determineHBFieldKeyForLabelKey = (labelKey: string) => {
  if (labelKey.includes("estimateFilter")) {
    return labelKey.replace("estimateFilter", "estimateTag");
  }
  if (labelKey.includes("userInteger")) {
    return labelKey.replace("userInteger", "userDefinedInteger");
  }
  if (labelKey.includes("userDate")) {
    return labelKey.replace("userDate", "userDefinedDate");
  }
  if (labelKey.includes("userAscii")) {
    return labelKey.replace("userAscii", "userDefinedText");
  }
  if (labelKey.includes("userNumber")) {
    return labelKey.replace("userNumber", "userDefinedNumber");
  }
  return labelKey;
};
