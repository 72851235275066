import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "@mui/material";
import { FeatureFlag } from "core";
import {
  HeaderSection,
  Item,
  MenuSection
} from "core/components/hcss-navigation/";
import { ConcreteColors, OverlayTrigger, Tooltip } from "hcss-components";
import { strings } from "localization";
import { useAuthorization, usePermissions } from "modules/account";
import { useFeatureFlags } from "modules/configurationSettings/use-feature-flags";
import { actions } from "modules/email-subscriptions";
import { selectors as estimateSelectors } from "modules/estimates";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { FailedSubscriptionsBadge } from "../context/customize-setup";
import { HeaderContainer, ItemContainer, ModuleTitle } from "./styles";

export const ProjectTrackingModule = () => {
  const {
    isFeatureEnabled: isFrontEndEmailSubscriptionEnabled
  } = useFeatureFlags(FeatureFlag.FrontEndEmailSubscription);
  const dispatch = useDispatch();
  const path = useLocation().pathname;
  const history = useHistory();
  const permissions = usePermissions();
  const auth = useAuthorization();

  const unlinkedEstimateCount = useSelector(
    estimateSelectors.getUnlinkedAndUnhiddenEstimatesCount
  );

  const canImport =
    auth.canAccessLimitedPrecon && (permissions.admin || permissions.write);

  const isFreeCompany =
    auth.canAccessLimitedPrecon && !auth.canAccessFullPrecon;

  const canCustomize = isFreeCompany || permissions.admin;
  const headerText = strings.layout.menu.projectTracking.customize;

  useEffect(() => {
    if (!isFrontEndEmailSubscriptionEnabled) {
      return;
    }

    dispatch(actions.loadSubscriptions.request());
  }, [dispatch, isFrontEndEmailSubscriptionEnabled]);

  return (
    <div
      className="project-tracking-navigation-module"
      data-testid="nav-projects"
    >
      <HeaderSection>
        {headerStyle => {
          return (
            <div style={headerStyle}>
              <HeaderContainer>
                <ModuleTitle>
                  {strings.layout.menu.projectTracking.title}
                </ModuleTitle>
              </HeaderContainer>
            </div>
          );
        }}
      </HeaderSection>
      <MenuSection>
        {menuStyle => (
          <ItemContainer style={menuStyle}>
            <Item
              key="project-list"
              before={<FontAwesomeIcon icon={["far", "stream"]} size="1x" />}
              text={strings.layout.menu.projectTracking.list}
              isSelected={path === "/projects"}
              onClick={() => history.push("/projects")}
              data-testid="nav-projects-list"
            />

            <Item
              key="estimate-mapping"
              before={<FontAwesomeIcon icon={["far", "link"]} size="1x" />}
              text={strings.layout.menu.projectTracking.estimateMapping.title}
              isSelected={path === "/projects/estimate-mapping"}
              onClick={() => history.push("/projects/estimate-mapping")}
              data-testid="nav-projects-estimate-mapping"
              after={
                <OverlayTrigger
                  placement="right"
                  delayShow={400}
                  overlay={
                    <Tooltip id="tool-tip">
                      <TooltipStyling />
                      {
                        strings.layout.menu.projectTracking.estimateMapping
                          .hover
                      }
                    </Tooltip>
                  }
                >
                  <Badge
                    data-testid="nav-projects-estimate-mapping-badge"
                    color={"error"}
                    badgeContent={unlinkedEstimateCount}
                    max={999}
                    sx={{
                      ".MuiBadge-badge": { transform: "translate(20%, -50%)" }
                    }}
                  />
                </OverlayTrigger>
              }
            />
            {canImport && (
              <Item
                key="import-projects"
                before={
                  <FontAwesomeIcon icon={["far", "file-import"]} size="1x" />
                }
                text={strings.layout.menu.projectTracking.import}
                isSelected={path === "/projects/import-projects"}
                onClick={() => history.push("/projects/import-projects")}
                data-testid="nav-projects-import"
              />
            )}
            {canCustomize && (
              <Item
                key="customize-projects"
                before={
                  <FontAwesomeIcon icon={["far", "sliders-v"]} size="1x" />
                }
                text={headerText}
                isSelected={path === "/projects/setup"}
                onClick={() => history.push("/projects/setup")}
                after={
                  isFrontEndEmailSubscriptionEnabled && (
                    <FailedSubscriptionsBadge />
                  )
                }
                data-testid="nav-projects-customize"
              />
            )}
          </ItemContainer>
        )}
      </MenuSection>
    </div>
  );
};

const TooltipStyling = createGlobalStyle`
  .tooltip {
    background-color: ${ConcreteColors.gray700};
    margin: 0;
    border-radius: 4px;
    pointer-events:none;
    .tooltip-arrow {
      display: none;
    }
    
    .tooltip-inner {
      background-color: transparent;
      font-weight: 500;
      font-size: 13px;
    }
  }
`;
