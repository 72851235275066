import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FullPageLoading from "modules/layout/components/FullPageLoading";
import { ConcreteColors, StandardColors } from "hcss-components";
import { Redirect } from "react-router";
import {
  useLocation,
  useParams,
  useHistory,
  useRouteMatch
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useOidcContext } from "modules/account/services/oidc-context";
import { actions, selectors } from "../../state";
import { ConfirmationPanel, BrowserFingerPrint } from "./confirm-panel";
import { DeclinePanel } from "./decline-panel";
import { useQueryParam } from "core/hooks/use-query-params";
import { AuthenticationMethod, SharedResourceType } from "core/models/enums";
import { isValidEmail } from "core/util/strings";
import { strings } from "localization";
import { accountService } from "modules/account/services/account-service";

const init = { show: false, message: "" };
type VerifyMode = "confirm" | "decline" | "proposal" | undefined;

export const ConfirmPage = () => {
  const signInWithEmailLink = actions.signInWithEmailLink.request;
  const declineInvitation = actions.declineInvitation.request;

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const account = useSelector(selectors.getAccount);
  const legacyMode = useQueryParam<VerifyMode>("m", location.search);
  const newMode = useRouteMatch<{ id: string; mode: string }>({
    path: "/account/verify/:id/:mode",
    exact: false
  })?.params.mode;
  const mode = legacyMode || newMode;
  const token = useQueryParam<string>("t", location.search)!;
  const { id } = useParams<{ id: string }>();
  const { oidcService } = useOidcContext();
  const [email, setEmail] = useState("");
  const [autoLogonLoading, setAutoLogonLoading] = useState(true);
  const [displayErrors, setDisplayErrors] = useState(init);

  document.title = "ProSubs Quoting";

  useEffect(() => {
    const autoLogon = async () => {
      try {
        let attemptFailed = true;
        if (mode === "confirm" || mode === "proposal") {
          const user = await oidcService.getUser();
          if (user && !user.expired) {
            const { data: hasAccess } = await accountService.canAccessResource(
              id,
              mode === "confirm"
                ? SharedResourceType.VendorSolicitation
                : SharedResourceType.VendorProposal,
              user.access_token
            );

            if (hasAccess) {
              attemptFailed = false;

              if (mode === "proposal") {
                history.replace(`/quotes/proposals/${id}/review`);
              } else {
                history.replace(`/quotes/solicitations/${id}/requests`);
              }
            }
          }
        }

        if (attemptFailed) setAutoLogonLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    autoLogon();
  }, [dispatch, signInWithEmailLink, history, oidcService, id, token, mode]);

  useEffect(() => {
    if (account.authenticationError) {
      setDisplayErrors({
        show: true,
        message: strings.account.confirm.invalidMessage
      });
    }
  }, [account.authenticationError]);

  useEffect(() => {
    if (mode === "decline") {
      dispatch(declineInvitation({ id, token }));
    }
  }, [mode, dispatch, declineInvitation, id, token]);

  const handleConfirm = (fingerPrint?: BrowserFingerPrint) => {
    if (account.confirming) return;

    if (isValidEmail(email)) {
      setDisplayErrors({ show: false, message: "" });
      dispatch(signInWithEmailLink({ id, email, token, fingerPrint, mode }));
    } else {
      setDisplayErrors({
        show: true,
        message: strings.account.confirm.invalidEmail
      });
    }
  };

  const userHasAccess = (): boolean => {
    if (
      account.isAuthenticated &&
      account.authenticationMethod === AuthenticationMethod.OneClickLink
    ) {
      return id === account.authorizedResource;
    }

    return false;
  };

  if (!token || !mode) {
    return <Redirect to="/notfound" />;
  }

  if (userHasAccess()) {
    return mode === "proposal" ? (
      <Redirect to={`/quotes/proposals/${id}/review`} />
    ) : (
      <Redirect to={`/quotes/solicitations/${id}/requests`} />
    );
  }

  if (account.loading || autoLogonLoading) {
    return <FullPageLoading loading={true} showTitle={true} />;
  }

  return (
    <PageWrapper data-testid="account-verification-page-wrapper">
      <MainContent>
        <Container className="container">
          <TitleContainer>
            <Title>
              <strong>ProSubs</strong> Quoting
            </Title>
            {mode === "confirm" && (
              <Subtitle>{strings.account.confirm.verifyTitleRfq}</Subtitle>
            )}
            {mode === "proposal" && (
              <Subtitle>{strings.account.confirm.verifyTitleProposal}</Subtitle>
            )}
            {mode !== "confirm" && mode !== "proposal" && (
              <LearnMoreLink>
                <a href="https://prosubs.hcssapps.com">Learn More</a>
              </LearnMoreLink>
            )}
          </TitleContainer>

          {mode === "confirm" || mode === "proposal" ? (
            <ConfirmationPanel
              email={email}
              onEmailChange={email => setEmail(email)}
              onConfirm={handleConfirm}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onSignIn={() => {}}
              errorMessage={displayErrors.message}
              validationState={displayErrors.show ? "error" : null}
            />
          ) : (
            <DeclinePanel />
          )}
        </Container>
      </MainContent>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 40px;
  padding-top: 80px;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  margin-bottom: 10px;
`;

const Title = styled.div`
  font-family: "ProximaNova";
  font-size: 32px;
  font-weight: 400;
  padding-bottom: 8px;

  & strong {
    font-family: "ProximaNova Heavy";
    font-weight: 800;
  }

  & .darktext {
    color: #444;
  }
`;

const Subtitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: ${ConcreteColors.gray500};
`;

const LearnMoreLink = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${ConcreteColors.gray500};
`;
