import { Button, Col, Icon, LoadingContainer } from "hcss-components";
import React, { useCallback } from "react";
import { useSmartDriveContext, SmartDriveGrid } from "hcss-smartdrive";
import { NoDataPlaceholder } from "../containers/no-data-placeholder";
import { strings } from "localization";
import styled from "styled-components";
import { Flex } from "hcss-core";
import { DisableInSandbox } from "core/util/sandbox";

export const SmartDriveTable: React.FunctionComponent = () => {
  const {
    api: { files, loading, downloadFile },
    allSelectedRowsOperations: { selectedRowIds, setSelectedRowIds },
    delModalOperations: { setModalShowUp },
    uppy: {
      ui: { toggleFileModal }
    },
    isReadOnlyUser
  } = useSmartDriveContext();

  const handleUploadFilesClick = useCallback(() => {
    toggleFileModal(true);
  }, [toggleFileModal]);

  return (
    <LoadingContainer isLoading={loading}>
      {files.length === 0 ? (
        <Flex
          justifyContent="center"
          height="320px"
          bg="neutral.6"
          borderRadius={3}
        >
          <DisableInSandbox>
            <NoDataPlaceholder
              buttonText={
                isReadOnlyUser
                  ? strings.smartdrive.table.noFiles
                  : strings.smartdrive.table.uploadFiles
              }
              onClick={handleUploadFilesClick}
              isReadOnly={isReadOnlyUser}
              isAddEditDisplay={!isReadOnlyUser}
              iconName="upload"
            />
          </DisableInSandbox>
        </Flex>
      ) : (
        <Wrapper>
          <Col sm={12}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between"
              }}
            >
              <SmallerButton
                onClick={handleUploadFilesClick}
                style={{ marginBottom: "10px" }}
                disabled={isReadOnlyUser}
              >
                <Icon name="upload" style={{ marginRight: 5 }} />
                {strings.smartdrive.table.uploadFiles}
              </SmallerButton>
              <div>
                <SmallerButton
                  hcssStyle="ThemeInverted"
                  onClick={handleDownloadAllFilesClick}
                  style={{
                    marginBottom: "10px",
                    marginRight: "10px",
                    marginLeft: "10px"
                  }}
                >
                  {strings.smartdrive.table.download}{" "}
                  {selectedRowIds && selectedRowIds.length > 0
                    ? strings.smartdrive.table.selected
                    : strings.smartdrive.table.all}
                </SmallerButton>
                <SmallerButton
                  hcssStyle="ThemeInverted"
                  onClick={handleDeleteAllFilesClick}
                  style={{ marginBottom: "10px" }}
                  disabled={isReadOnlyUser}
                >
                  {strings.smartdrive.table.delete}{" "}
                  {selectedRowIds && selectedRowIds.length > 0
                    ? strings.smartdrive.table.selected
                    : strings.smartdrive.table.all}
                </SmallerButton>
              </div>
            </div>
          </Col>
          <SmartDriveGrid
            files={files}
            selection={selectedRowIds || []}
            setSelection={setSelectedRowIds}
          />
        </Wrapper>
      )}
    </LoadingContainer>
  );

  function handleDownloadAllFilesClick() {
    if (selectedRowIds && selectedRowIds.length > 0) {
      selectedRowIds.forEach(id => {
        downloadFile(files.filter(file => file.id === id)[0]);
      });
    } else {
      files.forEach(file => {
        downloadFile(file);
      });
    }
  }

  function handleDeleteAllFilesClick() {
    setModalShowUp(true);
  }
};

const SmallerButton = styled(Button)`
  padding: 5px 10px 5px 10px;
  font-size: 1rem;
`;

const Wrapper = styled.span`
  div.table-responsive {
    /* 
      WORKAROUND: This fixes an issue with Windows/Chrome where
      the the height is overwritten during window resizes, 
      causing the table continuously shrink.
     */
    height: 400px !important;
  }
`;
