import { IntegratedSelection } from "@devexpress/dx-react-grid";
import {
  Grid,
  SearchPanel,
  TableHeaderRow,
  TableSelection,
  Toolbar
} from "@devexpress/dx-react-grid-bootstrap3";
import * as React from "react";
import styled from "styled-components";
import {
  TypedDataColumn,
  SearchState,
  SelectionState,
  IntegratedSorting,
  FilteringState,
  ColumnVisibility,
  ColumnResizing,
  BooleanFormatProvider,
  DateFormatProvider,
  LinkProvider,
  NumberFormatProvider,
  SortingState,
  getRowId,
  Root,
  TableWrapper
} from "hcss-tables";
import { strings } from "localization";
import { ExtendedIntegratedFiltering } from "./search";
import LegacyVirtualTable from "core/components/bundle-fixes/LegacyVirtualTable";

export interface SelectionTableProps {
  columns: TypedDataColumn[];
  rows: any[];
}

export const SelectionTable = ({ columns, rows }: SelectionTableProps) => {
  if (rows.length <= 0) {
    return (
      <div style={{ marginTop: "24px" }}>
        <span>
          {strings.projects.details.estimateSelectionTable.noEstimates}
          <span style={{ margin: 8 }}>
            <a
              href="https://help.hcss.com/s/article/Overview-Estimate-History"
              target="_blank"
              rel="noopener noreferrer"
            >
              {strings.projects.details.estimateSelectionTable.noEstimatesLink}
            </a>
          </span>
        </span>
      </div>
    );
  }
  return (
    <TableWrapper height="60vh">
      <Grid
        rootComponent={Root}
        rows={rows}
        columns={columns}
        getRowId={getRowId}
      >
        <LinkProvider />
        <NumberFormatProvider />
        <DateFormatProvider />
        <BooleanFormatProvider />
        <SortingState />
        <IntegratedSorting />
        <SearchState />
        <FilteringState />
        <ExtendedIntegratedFiltering columns={columns} />
        <SelectionState />
        <IntegratedSelection />
        <LegacyVirtualTable height="auto" estimatedRowHeight={60} />
        <ColumnVisibility />
        <ColumnResizing />
        <TableHeaderRow showSortingControls />
        <TableSelection
          highlightRow
          selectByRowClick
          // @ts-ignore
          rowComponent={StyledSelectionRow}
        />
        <Toolbar />
        <SearchPanel />
      </Grid>
    </TableWrapper>
  );
};

// @ts-ignore
// https://github.com/DevExpress/devextreme-reactive/issues?utf8=%E2%9C%93&q=is%3Aissue+is%3Aopen+rowcomponent
export const StyledSelectionRow = styled<any>(TableSelection.Row)`
  cursor: pointer;

  &:hover,
  &:hover td {
    background-color: #eee !important;
  }

  &.active,
  &.active td {
    background-color: #e3f1f8 !important;
  }
`;
