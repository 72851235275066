import { select, call, put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { actions, STATE_KEY, StateSlice } from "./state";
import { apiSagaFactory } from "api/api-saga-factory";

const genericSagas = apiSagaFactory({
  apiPath: "/configuration",
  stateKey: STATE_KEY
});

function* loadConfigurationSettings() {
  const loaded = yield select(
    ({ configurationSettings }: StateSlice) => configurationSettings.loaded
  );
  if (!loaded) {
    try {
      const api = yield call(genericSagas.getApi);
      const { data } = yield call([api, api.get]);
      yield put(actions.loadConfigurationSettings.success(data));
    } catch (error) {
      console.error(error);
      yield put(actions.loadConfigurationSettings.failure(error));
    }
  }
}

export const sagas = [
  takeLatest(
    getType(actions.loadConfigurationSettings.request),
    loadConfigurationSettings
  )
];
