import { notify } from "hcss-components";

export const copyToClipboard = (text: string) => {
  void navigator.clipboard.writeText(text);
  notify("success", "Copied to clipboard");
};

export const copyTableToClipBoard = (tableId: string) => {
  const range = document.createRange();
  const selection = window.getSelection();
  const tableNode = document.getElementById(tableId);

  if (tableNode) {
    range.selectNodeContents(tableNode);
  }

  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand("copy");
    selection.removeAllRanges();
    notify("success", "Copied to clipboard");
  }
};
