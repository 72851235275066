import React from "react";
import styled from "styled-components";
import { strings } from "localization";
import { Table } from "@devexpress/dx-react-grid-bootstrap3";
import { Icon } from "hcss-components";
import { formatFileSize, getIconForFile, formatDate, Document } from "core";

export const TableFileCell = ({ value, row }: Table.DataCellProps) => {
  const documentRow: Document = row as Document;
  return (
    <td>
      <FileCell>
        <TableFlex style={{ gridArea: "icon" }}>
          <FileIcon name={getIconForFile(documentRow.mime)} />
        </TableFlex>
        <TableFlex
          style={{ gridArea: "fileInfo", justifyContent: "flex-start" }}
        >
          <div className="details">{value}</div>
          <div>
            <span className="size">
              {strings.smartdrive.size}&nbsp;
              {formatFileSize(Number(documentRow.size))}
            </span>
            <span className="date">
              {strings.smartdrive.uploaded}&nbsp;
              {formatDate(documentRow.modified || documentRow.created)}
            </span>
          </div>
        </TableFlex>
      </FileCell>
    </td>
  );
};

const FileCell = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 30px auto;
  grid-template-areas: "icon fileInfo";
  grid-gap: 12px;
  padding: 4px;

  .details {
    width: 100%;
  }

  .size,
  .date {
    margin-right: 5px;
    display: inline-block;
    font-size: 0.9em;
    color: #404040;
  }

  .size {
    width: 8em;
  }

  .date {
    width: 10em;
  }
`;

const FileIcon = styled(Icon)`
  font-size: 2em;
`;

const TableFlex = styled.div`
  display: flex;
  grid-area: area;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
