import produce from "immer";
import { ActionType, createAsyncAction, getType } from "typesafe-actions";
import { ConfigurationSettings } from "../../api";

export const STATE_KEY = "configurationSettings";

// Models
export interface State {
  info?: ConfigurationSettings;
  loading: boolean;
  loaded: boolean;
  errors: string[];
}

export interface StateSlice {
  [STATE_KEY]: State;
}

// Actions
export const actions = {
  loadConfigurationSettings: createAsyncAction(
    "CONFIGURATION_SETTINGS/LOAD_REQUEST",
    "CONFIGURATION_SETTINGS/LOAD_SUCCESS",
    "CONFIGURATION_SETTINGS/LOAD_FAILURE"
  )<void, ConfigurationSettings, Error>()
};

export type ConfigurationSettingsActions = ActionType<typeof actions>;

export const initialState: State = {
  loading: false,
  loaded: false,
  errors: []
};

// Reducer
export const reducer = (
  state = initialState,
  action: ConfigurationSettingsActions
) => {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.loadConfigurationSettings.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }
      case getType(actions.loadConfigurationSettings.success): {
        draft.info = action.payload;
        draft.loading = false;
        draft.loaded = true;
        break;
      }
      case getType(actions.loadConfigurationSettings.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(
          `Error loading configuration settings: ${action.payload.message}`
        );
        break;
      }
    }
  });
};

// Selectors
const getConfigurationSettings = ({ configurationSettings }: StateSlice) =>
  configurationSettings.info;
const getLoaded = ({ configurationSettings }: StateSlice) =>
  configurationSettings.loaded;
const getErrors = ({ configurationSettings }: StateSlice) =>
  configurationSettings.errors;

export const selectors = {
  getConfigurationSettings,
  getLoaded,
  getErrors
};
