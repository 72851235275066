import React from "react";
import styled from "styled-components";
import { SideNavigationHeader } from "./Header";
import { ExpandableContentStatus } from "../../models/enums";

export * from "./use-side-navigation";

interface Props {
  panelStatus: ExpandableContentStatus;
  panelActive: boolean;
  className?: string;
  onClick?: (event: any) => void;
  alignRight?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
}
export class SideNavigation extends React.PureComponent<Props> {
  static Header = SideNavigationHeader;
  // static Toolbar = SideNavigationToolbar;
  // static ToolbarMenu = SideNavigationToolbarMenu;
  // static Content = SideNavigationContent;

  render() {
    return (
      <Container
        className={`${this.props.className || ""}`}
        data-panel-status={this.props.panelStatus}
        data-panel-active={this.props.panelActive}
        {...this.props}
      >
        {this.props.children}
      </Container>
    );
  }
}

const BaseContainer = styled.div<{ alignRight?: boolean }>`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  padding: 12px 14px 16px 0px;
  background: #ffffff;
  border-right: 2px solid #ececec;
  transition: 0.5s;

  &[data-panel-status="expanded"] {
    overflow: visible;
    &:after {
      z-index: -2;
    }
  }
  &[data-panel-status="expanding"] {
    overflow: hidden;
  }
  &[data-panel-status="collapsed"] {
    overflow: hidden;
  }
  &[data-panel-status="collapsing"] {
    overflow: hidden;
  }

  &[data-panel-status="expanded"],
  &[data-panel-status="expanding"],
  &[data-panel-status="collapsing"] {
    & > header {
      .panel-collapsed-title {
        color: transparent;
        z-index: -2;
      }
    }

    &:after {
      opacity: 0;
    }
  }

  &[data-panel-status="expanding"],
  &[data-panel-status="expanded"] {
    .panel-display-toggle-button {
      transform: rotate(180deg);
      bottom: 9px;
    }
  }

  &[data-panel-status="collapsing"],
  &[data-panel-status="collapsed"] {
    padding: 12px 0 16px 0;

    & > header {
      .panel-title {
        color: transparent;
      }
      .panel-desc {
        color: transparent;
      }
      .panel-collapsed-title {
        cursor: pointer;
        transition: 0.4s;
        z-index: 5;
      }
      .panel-display-toggle-button {
      }
    }

    &:after {
      opacity: 1;
    }
  }

  &:after {
    content: "";
    position: absolute;
    background: #ffffff;
    top: 0;
    ${props => (props.alignRight ? `right` : `left`)}: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s;
    z-index: 4;
  }
`;

const Container = styled(BaseContainer)<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  ${props => props.fullWidth && "width: 100%;"}

  &[data-panel-active="false"] {
    width: 0px;
    margin-left: -40px;
    opacity: 0.5;
  }

  &[data-panel-status="collapsed"],
  &[data-panel-status="collapsing"] {
    max-width: 34px;
  }
  &[data-panel-status="expanded"],
  &[data-panel-status="expanding"] {
    ${props => !props.fullWidth && "max-width: 272px;"};
  }

  /* Compatibility fixes for IE 11 */
  body.ie-compatibility-mode &[data-panel-status="expanded"],
  body.ie-compatibility-mode &[data-panel-status="expanding"] {
    ${props => !props.fullWidth && "max-width: 272px;"};
  }

  body.ie-compatibility-mode &[data-panel-status="collapsed"],
  body.ie-compatibility-mode &[data-panel-status="collapsing"] {
    max-width: 34px;
  }
`;

export const SideNavigationWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: auto;
  background: #ffffff;
  height: calc(100vh - 45px);

  @media print {
    display: none;
  }

  /* Compatibility fixes for IE 11 */
  body.ie-compatibility-mode & {
    flex-shrink: 0;
  }
`;
