import { TableEditColumn } from "@devexpress/dx-react-grid";
import React from "react";
import styled from "styled-components";

export const CustomEditCellComponent = (props: TableEditColumn.CellProps) => {
  return (
    <td {...props}>
      <WrapperDiv>
        <InnerDiv>
          {React.Children.map(props.children as any, (child: any) =>
            React.isValidElement(child)
              ? React.cloneElement<any>(child, {
                  ...child.props,
                  tableRow: props.tableRow
                } as any)
              : child
          )}
        </InnerDiv>
      </WrapperDiv>
    </td>
  );
};

export const CustomEditCellHeaderComponent = (
  props: TableEditColumn.HeaderCellProps
) => {
  return (
    <th style={{ padding: "4px" }} {...props}>
      <WrapperDiv>
        <InnerDiv>{props.children}</InnerDiv>
      </WrapperDiv>
    </th>
  );
};

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

const InnerDiv = styled.div`
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
`;
