import { TextOverflow } from "core";
import { Flex } from "hcss-core";
import React from "react";
import { Link } from "react-router-dom";
import { actions } from "modules/contacts";
import { useDispatch } from "react-redux";
import { CompanyAvatar } from "../../../../core/components/avatar";

interface CompanyCardProps {
  companyId: string;
  companyName: string;
  companyCode: string;
}
export const CompanyCard = ({
  companyId,
  companyName,
  companyCode
}: CompanyCardProps) => {
  const dispatch = useDispatch();
  return (
    <Flex flex={1} alignItems="center">
      <CompanyAvatar id={companyId} name={companyName} size="40px" />
      <Flex
        flexDirection="column"
        justifyContent="space-evenly"
        marginLeft="4px"
      >
        <TextOverflow style={{ whiteSpace: "nowrap", fontSize: "medium" }}>
          <Link
            to={`/contacts/${companyId}`}
            onClick={() => dispatch(actions.setShowSearchModal(false))}
          >
            {companyName}
          </Link>
        </TextOverflow>

        <TextOverflow style={{ whiteSpace: "nowrap", fontSize: "small" }}>
          {companyCode}
        </TextOverflow>
      </Flex>
    </Flex>
  );
};
