import React from "react";
import { DropdownContext } from "./dropdown-context";

export const DropdownMenuManager = (WrappedComponent: any) => (props: any) => {
  return (
    <DropdownContext.Consumer>
      {value => <WrappedComponent onClick={value} {...props} />}
    </DropdownContext.Consumer>
  );
};
