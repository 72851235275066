import styled from "styled-components";
import { ConcreteColors } from "hcss-components";

export const SectionHeading = styled.div`
  display: flex;
  align-items: center;
  color: inherit;
  flex-shrink: 0;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${ConcreteColors.gray600};
  height: 44px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 8px;
`;
