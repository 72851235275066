import React from "react";
import FullPageLoading from "../../layout/components/FullPageLoading";
import { ProtectedRoute } from "../../account/components/protected-route";

import { Route, withRouter, Switch } from "react-router-dom";

const SMTPSettingsPage = React.lazy(() =>
  import(
    /* webpackChunkName: "settings-smtp" */ "../components/SMTPSettingsPage"
  )
);

const AdminSettingsPage = React.lazy(() =>
  import(/* webpackChunkName: "settings-admin" */ "../Admin/AdminSettingsPage")
);

const ProfilePage = React.lazy(() =>
  import(
    /* webpackChunkName: "settings-profile" */ "../../profile/components/profile-details"
  )
);
const paths = {
  profile: "/settings/profile",
  permissions: "/settings/permissions",
  smtp: "/settings/smtp",
  base: "/settings"
};

const SettingsRouter = withRouter(() => {
  return (
    <React.Suspense fallback={<FullPageLoading loading />}>
      <Switch>
        <ProtectedRoute
          isAdmin
          path={paths.permissions}
          render={() => <AdminSettingsPage />}
        />
        <Route path={paths.profile} render={() => <ProfilePage />} />
        <Route exact path={paths.base} render={() => <SMTPSettingsPage />} />
      </Switch>
    </React.Suspense>
  );
});

export const Views: React.FC = () => (
  <Route path={paths.base} component={SettingsRouter} />
);
