import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { strings } from "localization";
import { HeaderContainer, ModuleTitle, ItemContainer } from "./styles";
import { faBooks } from "@fortawesome/pro-light-svg-icons";
import {
  HeaderSection,
  MenuSection,
  Item
} from "core/components/hcss-navigation/";
import { RecentEstimateList } from "modules/estimates/components/recent-estimates/recent-estimate-list";
import { useAuthorization, usePermissions } from "modules/account";
import { DisableInSandbox, HideInSandbox } from "core/util/sandbox";
import { useFeatureFlags } from "modules/configurationSettings/use-feature-flags";

export const EstimatingModule = () => {
  const path = useLocation().pathname;
  const history = useHistory();
  const permissions = usePermissions();
  const auth = useAuthorization();
  const enableCustomizeReporting =
    permissions.admin && auth.canAccessFullPrecon;
  const { isFeatureEnabled: isCodebooksFeatureEnabled } = useFeatureFlags(
    "Codebooks"
  );

  const iconStyle = { width: "10px" };
  return (
    <div className="estimating-navigation-module">
      <HeaderSection>
        {headerStyle => {
          return (
            <div style={headerStyle}>
              <HeaderContainer>
                <ModuleTitle>
                  {strings.layout.menu.estimating.title}
                </ModuleTitle>
              </HeaderContainer>
            </div>
          );
        }}
      </HeaderSection>
      <MenuSection>
        {menuStyle => (
          <ItemContainer style={menuStyle}>
            <Item
              key="estimate-list"
              before={<FontAwesomeIcon icon={["far", "stream"]} size="1x" />}
              text={strings.layout.menu.estimating.list}
              isSelected={path === "/estimates"}
              onClick={() => history.push("/estimates")}
              data-testid="navitem-estimating-list"
              beforeWrapperStyle={iconStyle}
            />
            {isCodebooksFeatureEnabled && (
              <HideInSandbox>
                <Item
                  key="estimate-codebook"
                  before={<FontAwesomeIcon icon={faBooks} size="1x" />}
                  text={strings.layout.menu.estimating.codebooks}
                  isSelected={path === "/estimates/codebooks"}
                  onClick={() => history.push("/estimates/codebooks")}
                  data-testid="navitem-estimating-codebooks"
                  beforeWrapperStyle={iconStyle}
                />
              </HideInSandbox>
            )}
            <DisableInSandbox>
              <Item
                key="estimate-edit"
                before={
                  <FontAwesomeIcon
                    icon={["far", "file-invoice-dollar"]}
                    size="1x"
                  />
                }
                text={strings.layout.menu.estimating.edit}
                isSelected={path === "/estimates/edit"}
                onClick={() => history.push("/estimates/edit")}
                data-testid="navitem-estimating-edit"
                beforeWrapperStyle={iconStyle}
              />
            </DisableInSandbox>
            {enableCustomizeReporting && (
              <Item
                key="customize-reporting"
                before={
                  <FontAwesomeIcon icon={["far", "sliders-v"]} size="1x" />
                }
                text={strings.layout.menu.estimating.customize}
                isSelected={path === "/estimates/customize"}
                onClick={() => history.push("/estimates/customize")}
                data-testid="navitem-customize-reporting"
                beforeWrapperStyle={iconStyle}
              />
            )}
            <RecentEstimateList />
          </ItemContainer>
        )}
      </MenuSection>
    </div>
  );
};
