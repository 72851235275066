export const isNullOrWhitespace = (input: string | undefined | null) => {
  return !input || !input.trim();
};

export const isValidEmail = (email: string | undefined | null) => {
  if (!email) return false;
  // eslint-disable-next-line no-useless-escape
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const isValidString = (
  input: string | undefined | null
): input is string => {
  if (input === undefined) return false;
  if (input === null) return false;
  return typeof input === "string" && input.trim().length > 0;
};
