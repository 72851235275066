import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { strings } from "localization";
import {
  PaneledPage,
  OverlayTrigger,
  Tooltip,
  ConcreteColors
} from "hcss-components";
import styled, { createGlobalStyle } from "styled-components";
import { DropdownList } from "react-widgets";
import { selectors, HeavybidDivision } from "../state";
import FullPageLoading from "modules/layout/components/FullPageLoading";
import { Grid as MuiGrid } from "@mui/material";
import { EmptyState } from "core/components/empty-state";
import { CodebooksLimitedView } from "./codebooks/CodebooksLimitedView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBooks } from "@fortawesome/pro-light-svg-icons";
import { useCodebooksContext } from "../context/CodebooksContext";
import { CodebooksUtilizedPage } from "./codebooks/CodebooksUtilizedTable";
import { CodebooksUnusedPage } from "./codebooks/CodebooksUnusedTable";
import { CodebooksMissingPage } from "./codebooks/CodebooksMissingTable";
import { getExpertChatAvailability } from "../../layout/components/navigation/top-navigation";
import { CodebooksDateRangeDisplay } from "./CodebooksDateRangeDisplay";
import { useCodebooksDateRange } from "../hooks/use-codebooks-daterange";
import { useAuthorization } from "modules/account";
import { TextOverflow } from "core";

export enum ReportTypeEnum {
  Unused,
  Missing,
  Utilized
}
export interface ReportTypeOption {
  name: string;
  type: ReportTypeEnum;
  description: string;
}
export interface ReportTypeOptions {
  [key: string]: ReportTypeOption;
}
export const reportTypeOptions: ReportTypeOptions = {
  Unused: {
    name: strings.estimates.codebooks.unused.subtitle,
    type: ReportTypeEnum.Unused,
    description: strings.estimates.codebooks.unused.text
  },
  Missing: {
    name: strings.estimates.codebooks.missing.subtitle,
    type: ReportTypeEnum.Missing,
    description: strings.estimates.codebooks.missing.text
  },
  Utilized: {
    name: strings.estimates.codebooks.utilized.subtitle,
    type: ReportTypeEnum.Utilized,
    description: strings.estimates.codebooks.utilized.text
  }
};

export const CodebooksPage = () => {
  const auth = useAuthorization();
  const divisions = useSelector(selectors.getHeavybidDivisions);
  const divisionsLoaded = useSelector(selectors.getHeavybidDivisionsLoaded);
  const {
    currentDivisionId,
    setCurrentDivisionId,
    reportType,
    unusedData,
    missingData,
    setReportType,
    isCurrentReportOverflow,
    hasNoCodebooksData,
    unusedLoaded,
    utilizedLoaded
  } = useCodebooksContext();
  const { dateRange, setDateRange } = useCodebooksDateRange();
  const isFreeCompany =
    auth.canAccessLimitedPrecon && !auth.canAccessFullPrecon;
  useEffect(() => {
    let defaultDiv = divisions.find(
      d => d.partitionId === localStorage.getItem("codebooksSelectedDivision")
    );
    defaultDiv = defaultDiv
      ? defaultDiv
      : divisions.length > 0
      ? divisions[0]
      : undefined;
    setCurrentDivisionId(defaultDiv?.partitionId);
  }, [divisions, setCurrentDivisionId]);

  const handleChange = (division: HeavybidDivision) => {
    setCurrentDivisionId(division.partitionId);
    localStorage.setItem("codebooksSelectedDivision", division.partitionId);
  };
  const handleReportChange = (report: ReportTypeOption) =>
    setReportType(report);

  if (isFreeCompany) {
    return <CodebooksLimitedView />;
  }
  if (!divisionsLoaded) {
    return <FullPageLoading loading={true} />;
  }

  if (divisions.length === 0) {
    const chatAvailable = getExpertChatAvailability();
    return (
      <EmptyState
        title={strings.layout.menu.estimating.divToBuCodebooksErrorTitle}
        text1={
          chatAvailable
            ? strings.layout.menu.estimating.divToBuErrorBody
            : strings.layout.menu.estimating.contactSupport
        }
        showButton={chatAvailable}
        buttonIconTitle={"Chat with an Expert"}
        onClick={() => window?.embedded_svc?.bootstrapEmbeddedService?.()}
        mainIcon={<FontAwesomeIcon icon={faBooks} size="10x" />}
      />
    );
  }
  const renderTable = () => {
    if (utilizedLoaded && unusedLoaded && hasNoCodebooksData) {
      return (
        <EmptyState
          title={strings.layout.menu.estimating.noCodebooksData.title}
          text1={strings.layout.menu.estimating.noCodebooksData.body}
          showButton
          buttonIconTitle={"Click Here"}
          onClick={() =>
            window.open(
              "https://help.hcss.com/s/article/How-to-Send-HeavyBid-Data-to-Insights-for-KPIs",
              "_blank",
              "noopener,noreferrer"
            )
          }
          mainIcon={<FontAwesomeIcon icon={faBooks} size="10x" />}
        />
      );
    } else if (reportType.type === ReportTypeEnum.Unused) {
      return <CodebooksUnusedPage data={unusedData} />;
    } else if (reportType.type === ReportTypeEnum.Missing) {
      return <CodebooksMissingPage data={missingData} />;
    } else if (reportType.type === ReportTypeEnum.Utilized) {
      return <CodebooksUtilizedPage />;
    }
  };
  const renderReportTypeOption = ({ item }: any) => (
    <ReportTypeOptionItem {...item} />
  );
  const renderDivisionOption = ({ item }: any) => (
    <DivisionOptionItem {...item} />
  );
  return (
    <PaneledPage>
      <PaneledPage.Header>
        <PaneledPage.Header.Title>
          {strings.estimates.codebooks.title}
        </PaneledPage.Header.Title>
        <PaneledPage.Header.Subtitle>
          <MuiGrid container direction={"row"} justifyContent={"space-between"}>
            <MuiGrid item>
              <CodebooksDateRangeDisplay
                settings={dateRange}
                onChange={setDateRange}
                showOverflowIcon={isCurrentReportOverflow()}
              />
            </MuiGrid>
            <MuiGrid item>
              <MuiGrid
                container
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <DropdownLabel>
                  {strings.estimates.codebooks.reportTitle}
                </DropdownLabel>
                <StyledDropdownButtonContainer style={{ minWidth: "10rem" }}>
                  <DropdownList
                    data-testid="codebooks-report-dropdown"
                    value={reportType}
                    data={Object.values(reportTypeOptions)}
                    textField="name"
                    onChange={handleReportChange}
                    itemComponent={renderReportTypeOption}
                  />
                </StyledDropdownButtonContainer>
              </MuiGrid>
            </MuiGrid>
            <MuiGrid item>
              <MuiGrid
                container
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <DropdownLabel>
                  {strings.estimates.codebooks.division}
                </DropdownLabel>
                <StyledDropdownButtonContainer>
                  <DropdownList
                    data-testid="codebooks-division-dropdown"
                    value={divisions.find(
                      d => d.partitionId === currentDivisionId
                    )}
                    data={divisions}
                    textField="code"
                    valueField="divisionId"
                    onChange={handleChange}
                    itemComponent={renderDivisionOption}
                    disabled={divisions.length <= 1}
                  />
                </StyledDropdownButtonContainer>
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
        </PaneledPage.Header.Subtitle>
      </PaneledPage.Header>
      <PaneledPage.Content>{renderTable()}</PaneledPage.Content>
    </PaneledPage>
  );
};

export const ReportTypeOptionItem = (props: ReportTypeOption) => {
  const { name, description } = props;
  const tool = (
    <Tooltip style={{ position: "absolute", zIndex: 10000 }} id="id">
      <TooltipStyling />
      {description}
    </Tooltip>
  );
  return (
    <div data-testid={"codebooks-report-dropdown-" + name.toLowerCase()}>
      <OverlayTrigger placement="right" overlay={tool}>
        <div style={{ minWidth: "8rem" }}>{name}</div>
      </OverlayTrigger>
    </div>
  );
};

const DivisionOptionItem = (props: HeavybidDivision) => {
  const { code, description } = props;

  const tooltip = (
    <Tooltip
      style={{ position: "absolute", zIndex: 10000 }}
      id="division_tooltip"
    >
      <TooltipStyling />
      <TextOverflow>{description}</TextOverflow>
    </Tooltip>
  );

  return (
    <div>
      <OverlayTrigger placement="left" overlay={tooltip}>
        <div style={{ minWidth: "8rem" }}>{code}</div>
      </OverlayTrigger>
    </div>
  );
};

const TooltipStyling = createGlobalStyle`
  .tooltip {
    background-color: ${ConcreteColors.gray600};
    margin: 0;
    border-radius: 7px;
    pointer-events:none;
    left:100;
    margin-left:50px;
    .tooltip-arrow {
      display: none;
    }
    
    .tooltip-inner {
      background-color: transparent;
      font-weight: 500;
      font-size: 13px;
    }
  }
`;

const DropdownLabel = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-right: 1rem;
`;
const StyledDropdownButtonContainer = styled.div`
  max-width: 20rem;
  min-width: 20rem;
  font-weight: normal;
`;
export default CodebooksPage;
