import React, { useCallback, ElementType } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { SchemaFieldType } from "../../../../api";
import { BooleanFilter } from "./BooleanFilter";
import { ChecklistFilter } from "./ChecklistFilter";
import { FilterControlProps } from "./common";
import { DateTimeFilter } from "./DateTimeFilter";
import { FilterTypeSelect } from "./FilterTypeSelect";
import { ListFilter } from "./ListFilter";
import { NumberFilter } from "./NumberFilter";
import { TextFilter } from "./TextFilter";
import { ConcreteColors } from "hcss-components";
import { isFilterActive } from "../../filters";
import { selectors as accountSelectors } from "modules/account";
import { ProjectIdFilter } from "./ProjectIdFilter";
import { calculatedFieldService } from "modules/schemas/services/calculated-field.service";

export const SchemaFieldFilter = (props: FilterControlProps) => {
  const hasEstimateInsightsAccess = useSelector(accountSelectors.getPermissions)
    ?.estimateInsights;
  const {
    filter,
    field: { type, name, id },
    onChange
  } = props;
  const onChangeFilter = useCallback(
    selectedValue => {
      onChange({
        ...filter,
        filterOperation: selectedValue
      });
    },
    [filter, onChange]
  );
  const calculatedFieldsWithEstimates =
    type === SchemaFieldType.Calculated &&
    calculatedFieldService.hasEstimatesVariables(
      calculatedFieldService.getConfig(props.field)
    );
  const canUseFilter =
    hasEstimateInsightsAccess ||
    (!id.includes("values.totals") &&
      !id.includes("values.customTotals") &&
      !calculatedFieldsWithEstimates);
  const showFilterTypeSelect =
    type !== SchemaFieldType.Boolean &&
    type !== SchemaFieldType.Date &&
    type !== SchemaFieldType.DateTime &&
    type !== SchemaFieldType.Number &&
    type !== SchemaFieldType.Checklist &&
    type !== SchemaFieldType.Currency &&
    type !== SchemaFieldType.Calculated;

  return (
    <FilterContainer
      onClick={e => e.stopPropagation()}
      isActive={isFilterActive({ ...filter, fieldType: type })}
      canUseFilter={canUseFilter}
      data-testid={`schema-field-filter-${name}`}
    >
      <FilterHeader>
        <label style={{ wordWrap: "break-word", maxWidth: "800px" }}>
          {name}
        </label>
        <FilterOptionsContainer>
          {showFilterTypeSelect && (
            <FilterTypeSelect
              type={type}
              filter={filter}
              onChange={onChangeFilter}
            />
          )}
        </FilterOptionsContainer>
      </FilterHeader>
      <SchemaFieldFilterControl {...props} />
    </FilterContainer>
  );
};

export interface SchemaFieldFilterControlProps extends FilterControlProps {
  overrides?: Record<string, ElementType<FilterControlProps>>;
}

export const SchemaFieldFilterControl = (
  props: SchemaFieldFilterControlProps
) => {
  const { field, overrides } = props;

  if (overrides && field.id in overrides) {
    const Component = overrides[field.id];
    return <Component {...props} />;
  }

  switch (field.type) {
    case SchemaFieldType.ShortText:
    case SchemaFieldType.LongText:
    case SchemaFieldType.Links:
      return <TextFilter {...props} />;
    case SchemaFieldType.Checklist:
      return <ChecklistFilter {...props} />;
    case SchemaFieldType.Number:
    case SchemaFieldType.Currency:
    case SchemaFieldType.Calculated:
      return <NumberFilter {...props} />;
    case SchemaFieldType.List:
    case SchemaFieldType.MultiSelectList:
    case SchemaFieldType.States:
    case SchemaFieldType.Company:
    case SchemaFieldType.Contact:
      return <ListFilter {...props} />;
    case SchemaFieldType.Boolean:
      return <BooleanFilter {...props} />;
    case SchemaFieldType.Date:
    case SchemaFieldType.DateTime:
      return <DateTimeFilter {...props} />;
    case SchemaFieldType.PreConId:
      return <ProjectIdFilter {...props} />;
    default:
      return null;
  }
};

const FilterContainer = styled.div<{
  isActive?: boolean;
  canUseFilter?: boolean;
}>`
  padding: 1rem 1.5rem;
  margin: 0.7rem;
  background-color: ${props =>
    !props.canUseFilter
      ? "#DB1010"
      : props.isActive
      ? ConcreteColors.blue100
      : "#f6f6f6"};
  border-radius: 12px;
`;

const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const FilterOptionsContainer = styled.div`
  width: 160px;
  margin-left: 24px;
`;
