import { notify } from "hcss-components";
import React from "react";
import styled from "styled-components";
import { strings } from "localization";
import { isSandboxEnabled } from "core/util/sandbox";

export const save = (name: string) => {
  notify(
    "success",
    strings.formatString(strings.core.notifications.saved, name) as string
  );
};

export const success = (name: string) => {
  notify("success", name);
};

export const add = (name: string) => {
  notify(
    "success",
    strings.formatString(strings.core.notifications.added, name) as string
  );
};

export const error = (headline: string, description?: React.ReactChild) => {
  const body = isSandboxEnabled() ? (
    <>
      <div>{description}</div>
      <span style={{ wordWrap: "break-word" }}>
        Some features are disabled in sandbox mode.
      </span>
    </>
  ) : (
    <div style={{ wordBreak: "break-word", wordWrap: "break-word" }}>
      {description}
    </div>
  );
  // @ts-ignore
  notify("danger", headline, body);
};

export const warning = (title: string, body: React.ReactChild) => {
  // @ts-ignore
  notify("warning", title, body);
};

export const remove = (name: string) => {
  notify(
    "warning",
    strings.formatString(strings.core.notifications.removed, name) as string
  );
};

export default (
  type: "success" | "info" | "warning" | "danger",
  title: string,
  body?: string | string[] | React.ReactChild
) => {
  if (Array.isArray(body)) {
    const Body = (
      <>
        {body.map((text: string, index) => (
          <p style={{ color: "#FFF" }} key={index}>
            {text}
          </p>
        ))}
      </>
    );
    // @ts-ignore
    notify(type, title, <Body />);
  }
  // @ts-ignore
  notify(type, title, body);
};

export const NotificationBody = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
`;

export const NotificationFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
`;
