import {
  ProductDto,
  IContactProduct
} from "api/GeneratedClients/ContactsClient";

export const contactProductsListFactory = (
  createdProductsList: ProductDto[],
  updatedVendorProducts: ProductDto[]
) => {
  const newContactsProductsList: IContactProduct[] = [];
  for (const createdProduct of createdProductsList) {
    const foundMatchingVendorProduct = updatedVendorProducts.find(
      (vendorProduct: ProductDto) =>
        vendorProduct.product?.code === createdProduct.product?.code
    );
    if (foundMatchingVendorProduct) {
      const createdContactProduct: IContactProduct = {
        productTypeId: foundMatchingVendorProduct.product?.id as string,
        code: foundMatchingVendorProduct.product?.code as string,
        vendorProductId: foundMatchingVendorProduct.id as string
      };
      newContactsProductsList.push(createdContactProduct);
    }
  }
  return newContactsProductsList;
};
