import React, { useEffect } from "react";
import { Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../../state";
import { useOidcContext } from "../../services/oidc-context";
import FullPageloading from "modules/layout/components/FullPageLoading";

export const OidcCallback = () => {
  const signInHcss = actions.signInWithHcssCredentials.request;
  const dispatch = useDispatch();
  const { oidcService } = useOidcContext();
  const { isAuthenticated } = useSelector(selectors.getAccount);
  const errors = useSelector(selectors.getErrors);

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const user = await oidcService.signinRedirectCallback();
        dispatch(signInHcss(user));
      } catch (error) {
        console.error("[OIDC Client] Signin Callback", error);
        dispatch(actions.signInWithHcssCredentials.failure(error));
      }
    };

    handleCallback();
  }, [dispatch, oidcService, signInHcss]);

  if (errors && errors.length > 0) {
    return <Redirect to="/error" />;
  }

  if (isAuthenticated) {
    const redirectPath = oidcService.getRedirect();
    return <Redirect to={redirectPath} />;
  }

  return <FullPageloading loading={true} showTitle />;
};
