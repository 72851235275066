import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest
} from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import * as notify from "../../core/components/notify";
import { apiSagaFactory } from "../../api/api-saga-factory";
import { actions, StateSlice } from "./state";
import { strings } from "localization";
import { Schema } from "api";
import { WithId, UserType } from "core";
import { SelectorState } from "./selectors";

const genericSagas = apiSagaFactory<Schema>({
  isBusinessUnitApi: true,
  apiPath: "/schemas",
  stateKey: "schemas",
  onBeforeLoadSuccess: modifySchemaOnLoad,
  onLoadSuccess: data => actions.loadSchemas.success(data),
  onLoadFail: err => actions.loadSchemas.failure(err)
});

function* modifySchemaOnLoad(schemas?: WithId<Schema>[]) {
  const isSubcontractor = yield select(
    ({ account }: SelectorState) =>
      account.user?.type === UserType.Subcontractor
  );

  // move estimate section to the end since this is going to be hidden
  if (isSubcontractor) {
    const projectSchema = schemas?.find(s => s.schemaName === "projects");
    const estimateSectionId = "ae9478b8-a74c-4da6-979b-16eb096210f8";
    if (projectSchema) {
      projectSchema.orderedSections = projectSchema.orderedSections.filter(
        id => id !== estimateSectionId
      );
      projectSchema.orderedSections.push(estimateSectionId);
    }
  }

  // TODO: delete from cosmos and remove this check
  return schemas?.filter(s => s.id !== "bidresults");
}

function* saveSchema(action: ActionType<typeof actions.saveSchema.request>) {
  const meta = action.payload.meta;
  const silent = meta?.silent ?? false;
  const msg = meta?.message ?? "";
  const fetchSchemaFromState = action.payload.meta?.inRedux ?? false;
  try {
    const schema = fetchSchemaFromState
      ? yield select(
          ({ schemas }: StateSlice) =>
            schemas.workingCopy[action.payload.schema.id]
        )
      : action.payload.schema;
    const api = yield call(genericSagas.getApi);
    const data = yield call([api, api.update], schema);
    yield put(actions.saveSchema.success(data.data));
    if (meta && !silent) {
      switch (meta.type) {
        case "success": {
          notify.save(msg);
          break;
        }
        case "warning": {
          notify.remove(msg);
          break;
        }
        case "danger": {
          notify.error(msg);
        }
      }
    }
  } catch (error) {
    console.error(error);
    const exceptionMessage = error.response.data.message;
    yield put(actions.saveSchema.failure(error));
    if (!silent)
      notify.error(
        exceptionMessage ? exceptionMessage : strings.schemas.errors.save
      );
  }
}

function* debounceAutosave(action: ActionType<typeof actions.saveSchemaField>) {
  const autosave = action.payload.meta?.autosave ?? false;
  if (autosave) {
    yield delay(1000);
    const schema = yield select(
      ({ schemas }: StateSlice) => schemas.workingCopy[action.payload.schemaId]
    );
    yield put(actions.saveSchema.request({ schema, meta: { silent: true } }));
  }
}

function* addSchemaFieldOption(
  action: ActionType<typeof actions.addSchemaFieldOption>
) {
  if (action.meta.saveSchema) {
    yield call(delay, 500);
    const schema: WithId<Schema> = yield select(
      ({ schemas }: StateSlice) => schemas.workingCopy[action.payload.schemaId]
    );
    yield put(actions.saveSchema.request({ schema }));
  }
}

export const sagas = [
  takeLatest(getType(actions.loadSchemas.request), genericSagas.load),
  takeEvery(getType(actions.saveSchema.request), saveSchema),
  takeLatest(getType(actions.saveSchemaField), debounceAutosave),
  takeLatest(getType(actions.saveDataSyncPreference), debounceAutosave),
  takeEvery(getType(actions.addSchemaFieldOption), addSchemaFieldOption)
];
