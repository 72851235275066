import React from "react";
import styled from "styled-components";
import { QuickSelect } from "core/components/sidenavigation/quick-select";
import { strings } from "localization";
import {
  HeaderSection,
  Item,
  MenuSection,
  SectionHeading
} from "core/components/hcss-navigation";
import { useLocation } from "react-router-dom";
import { useGetSelectedVendor } from "modules/contacts/hooks/use-get-selected-vendor";

export const VendorDetailsContext = (vendorId: string) => {
  const path = useLocation().pathname;
  const currentSelectedVendor = useGetSelectedVendor();

  return (
    <div className="estimate-navigation-context">
      <HeaderSection>
        {headerStyle => {
          return (
            <div style={headerStyle}>
              <HeaderContainer>
                <QuickSelect>
                  {currentSelectedVendor && (
                    <>
                      <ContextTitle>{currentSelectedVendor.code}</ContextTitle>
                      <ContextSubtitle>
                        {currentSelectedVendor.name}
                      </ContextSubtitle>
                    </>
                  )}
                </QuickSelect>
              </HeaderContainer>
            </div>
          );
        }}
      </HeaderSection>
      <MenuSection>
        {menuStyle => (
          <ItemContainer style={menuStyle}>
            <SectionHeading>
              {strings.layout.menu.contacts.title}
            </SectionHeading>
            <Item
              data-testid="navitem-companyInformation-mainDetails"
              key="vendor-details"
              text={strings.layout.menu.contacts.mainDetails}
              isSelected={path === `/contacts/${vendorId}`}
              to={`/contacts/${vendorId}`}
              isDisabled={vendorId ? false : true}
            />
          </ItemContainer>
        )}
      </MenuSection>
    </div>
  );
};

const HeaderContainer = styled.div`
  height: 55px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .quick-select-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ItemContainer = styled.div`
  font-size: 14px;
  margin-right: 14px;
  color: ${props => props.theme.colors.neutral[0]};
`;

const ContextTitle = styled.span`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #1e1e1e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ContextSubtitle = styled.span`
  font-size: 12px;
  display: inline-block;
  position: relative;
  width: 220px;
  color: ${props => props.theme.colors.neutral[1]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
