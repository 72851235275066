import React, { useState, useEffect } from "react";
import numeral from "numeral";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { formatCurrency, formatNumber } from "core";

interface NumericInputProps {
  decimal: number;
  value: number;
  isCurrency: boolean;
  onChange: (value: number) => any;
  isReadOnly?: boolean;
  className?: string;
}

export const NumericInput = ({
  decimal,
  isCurrency,
  value,
  onChange,
  isReadOnly,
  className
}: NumericInputProps) => {
  const format = isCurrency ? formatCurrency : formatNumber;
  const formattedValue = format(value, decimal);

  const [fieldValue, setFieldValue] = useState<string>(formattedValue);

  useEffect(() => {
    setFieldValue(formattedValue);
  }, [formattedValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const value: string = e.target.value;
    const numberValue = numeral(value).value();
    if (!isNaN(numberValue)) {
      setFieldValue(value);
    }
  };

  const handleBlur = () => {
    const updateVal = fieldValue === "" ? 0 : numeral(fieldValue).value();
    setFieldValue(format(updateVal, decimal));
    onChange(updateVal);
  };

  const handleFocus = () => {
    if (numeral(fieldValue).value() === 0) {
      setFieldValue("");
    }
  };

  const numberMask = createNumberMask({
    allowDecimal: true,
    allowNegative: true,
    prefix: isCurrency ? "$" : "",
    decimalLimit: isCurrency ? 4 : 2,
    integerLimit: 10,
    allowLeadingZeroes: true
  });

  return (
    <MaskedInput
      className={`form-control ${className ?? ""}`}
      value={fieldValue}
      onChange={handleChange}
      mask={numberMask}
      style={{ textAlign: "right" }}
      onBlur={handleBlur}
      onFocus={handleFocus}
      disabled={isReadOnly}
    />
  );
};

export default NumericInput;
