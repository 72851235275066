import React from "react";
import { useLocation, Redirect } from "react-router-dom";
import { useQueryParam } from "core";
import { useHcssToken } from "modules/account";
import { useCompareEstimatesDirect } from "api/GeneratedClients/insights";
import { getPathForIndicators, getPathForEstimate, paths } from "./paths";
import FullPageLoading from "modules/layout/components/FullPageLoading";

export const EstimateLookup = () => {
  const location = useLocation();
  const estimateCode = useQueryParam<string>("estimateCode", location.search);
  const partitionId = useQueryParam<string>("partitionId", location.search);
  const view = useQueryParam<"kpi">("view", location.search);
  const token = useHcssToken();

  const { data, loading } = useCompareEstimatesDirect({
    queryParams: { estimateCode, partitionId },
    requestOptions: { headers: { Authorization: `BEARER ${token}` } }
  });

  if (loading) {
    return <FullPageLoading loading />;
  }

  if (data) {
    if (view === "kpi") {
      return <Redirect to={getPathForIndicators(data)} />;
    }
    return <Redirect to={getPathForEstimate(data)} />;
  }

  return <Redirect to={paths.base} />;
};
