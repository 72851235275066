import * as React from "react";
import styled from "styled-components";
import { Icon } from "hcss-components";

export const StyledStatusFlag = styled("div")`
  display: inline-block;
  cursor: pointer;

  & > i {
    font-size: 0.92em;
  }

  & > .icon-spacer {
    display: inline-block;
    margin-right: 8px;
  }
`;

export interface StatusFlagProps {
  additionalClassNames?: string;
  /** FontAwesome code (e.g. "asterisk") for the Status Flag's icon. Default value is "asterisk" */
  flagIcon?: string;
  /** Color of the Status Flag. Default value is #ff3f1f */
  flagIconColor?: string;
}
export default class StatusFlag extends React.PureComponent<
  StatusFlagProps,
  any
> {
  render() {
    const combinedClassNames = this.props.additionalClassNames
      ? `status-flag ${this.props.additionalClassNames}`
      : "status-flag";
    const flagIconColor = this.props.flagIconColor || "#ff3f1f";
    return (
      <StyledStatusFlag className={combinedClassNames}>
        <Icon
          name={this.props.flagIcon || "asterisk"}
          style={{ color: flagIconColor }}
        />
        {this.props.children ? <div className="icon-spacer" /> : ""}
        {this.props.children}
      </StyledStatusFlag>
    );
  }
}
