import { Environment, envService } from "./env-service";

import {
  User,
  UserManager,
  WebStorageStateStore,
  UserManagerSettings
} from "oidc-client";
import { disableSandbox } from "core/util/sandbox";

export class OidcService {
  private readonly userManager: UserManager;

  constructor(oidcEnv: Environment) {
    const settings: UserManagerSettings = {
      ...oidcEnv.oidc,
      automaticSilentRenew: false,
      accessTokenExpiringNotificationTime: 300,
      userStore: new WebStorageStateStore({ store: window.localStorage })
    };

    this.userManager = new UserManager(settings);
  }

  async getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  async signinRedirect(successRedirect?: string, args?: any) {
    if (successRedirect) {
      window.localStorage.setItem("redirect", successRedirect);
    }
    return this.userManager.signinRedirect(args);
  }

  async signinRedirectCallback(): Promise<User> {
    return this.userManager.signinRedirectCallback();
  }

  async signinSilent(): Promise<User> {
    return this.userManager.signinSilent();
  }

  async signinSilentCallback() {
    return this.userManager.signinSilentCallback();
  }

  async signoutRedirect() {
    this.clearRedirect();
    disableSandbox();
    return this.userManager.signoutRedirect();
  }

  async signoutRedirectCallback() {
    return this.userManager.signoutRedirectCallback();
  }

  getRedirect() {
    const redirect = window.localStorage.getItem("redirect") ?? "/";
    this.clearRedirect();
    return redirect;
  }

  clearRedirect() {
    window.localStorage.removeItem("redirect");
  }

  getSettings() {
    return this.userManager.settings;
  }

  getEvents() {
    return this.userManager.events;
  }
}

export const oidcService = new OidcService(envService);
