import { Button, Icon } from "hcss-components";
import * as React from "react";
import Modal from "./";
import { strings } from "localization";

export interface ConfirmDeleteProps {
  title?: string;
  children?: React.ReactNode;
  show: boolean;
  handleClose: () => void;
  handleDelete?: () => void;
  style?: React.CSSProperties;
  footer?: React.ReactChild;
}

export default class ConfirmDelete extends React.PureComponent<
  ConfirmDeleteProps
> {
  render() {
    const props = this.props;
    return (
      <Modal
        show={props.show}
        handleClose={props.handleClose}
        footer={props.footer ? props.footer : this.footer()}
        title={this.props.title}
        style={this.props.style}
      >
        {props.children}
      </Modal>
    );
  }
  footer = () => {
    return (
      <div>
        <Button onClick={this.props.handleDelete} hcssStyle="Delete">
          <Icon name="trash-o" />
          &nbsp;{strings.core.modals.confirmDelete.confirm}
        </Button>
        <Button hcssStyle="ThemeInverted" onClick={this.props.handleClose}>
          <Icon name="times" />
          &nbsp;{strings.core.modals.cancel}
        </Button>
      </div>
    );
  };
}
