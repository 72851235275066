import { set } from "lodash-es";
import { isDict } from "./types";

export const setNested = (
  src: Record<string, any>,
  dest: Record<string, any>
) => {
  Object.entries(src).forEach(([key, val]) => {
    if (isDict(val)) setNested(src[key], dest[key]);
    else set(dest, key, val);
  });
};
