import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where
} from "firebase/firestore";
import axios from "axios";
import config from "../../../config";
import { firebaseApp } from "../../../firebase-app";
import { isUndefined, omitBy, omit } from "lodash-es";
import { MasterProposal, MasterProposalEntity } from "core";

const db = getFirestore(firebaseApp);
const collectionId = "masterProposals";

export const MasterProposalRepo = {
  generateId: () => {
    return doc(collection(db, collectionId)).id;
  },

  getById: async (id: string) => {
    const ref = doc(collection(db, collectionId), id);

    try {
      const snap = await getDoc(ref);
      const data = snap.data() as MasterProposal;
      const entity: MasterProposalEntity = { ...data, id: ref.id };
      return entity;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  },

  /**
   * CompanyId or Email is required for filtering purposes.
   * CompanyId takes precendence.
   */
  getByRequestId: async (
    requestId: string,
    companyId?: string,
    email?: string
  ) => {
    const whereClauses = [];
    whereClauses.push(where("requestId", "==", requestId));

    if (companyId) {
      whereClauses.push(where("companyId", "==", companyId));
    } else if (email) {
      whereClauses.push(where("email", "==", email));
    } else {
      throw new Error("Invalid Arguments: companyId or email is required.");
    }

    try {
      const q = query(collection(db, collectionId), ...whereClauses);
      const snap = await getDocs(q);
      if (snap.docs.length === 0) {
        return undefined;
      }

      if (snap.docs.length > 1) {
        console.warn("Invalid state, taking first.");
      }

      const doc = snap.docs[0];
      const data = doc.data() as MasterProposal;
      const entity: MasterProposalEntity = { ...data, id: doc.id };
      return entity;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  },

  getAll: async (companyId: string, estimateId?: string) => {
    const proposals: MasterProposalEntity[] = [];

    const whereClauses = [];
    whereClauses.push(where("companyId", "==", companyId));

    if (estimateId) {
      whereClauses.push(where("estimateId", "==", estimateId));
    }

    try {
      const q = query(collection(db, collectionId), ...whereClauses);
      const snap = await getDocs(q);
      for (const doc of snap.docs) {
        const data = doc.data() as MasterProposal;
        proposals.push({ ...data, id: doc.id });
      }
    } catch (error) {
      console.error(error);
    }

    return proposals;
  },

  subscribe: (companyId: string, estimateId?: string) => {
    const whereClauses = [];
    whereClauses.push(where("companyId", "==", companyId));

    if (estimateId) {
      whereClauses.push(where("estimateId", "==", estimateId));
    }

    const q = query(collection(db, collectionId), ...whereClauses);
    return q;
  },

  save: async (proposal: MasterProposalEntity) => {
    const id = proposal.id;
    const ref = doc(collection(db, collectionId), id);

    try {
      let doc = omitBy(proposal, isUndefined);
      doc = omit(doc, ["id"]);
      await setDoc(ref, doc);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  delete: async (proposal: MasterProposalEntity, token: string) => {
    const deleteFile = async (fileId: string) => {
      await axios.delete(config.endpoints.SMARTDRIVE + "Files", {
        headers: {
          Authorization: "Bearer " + token
        },
        params: {
          appId: config.keys.SMARTDRIVEAPPID,
          Id: fileId
        }
      });
    };

    const ref = doc(collection(db, collectionId), proposal.id);

    try {
      const promises = proposal.attachments.map(a => deleteFile(a.id));
      promises.push(deleteDoc(ref));
      await Promise.all(promises);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
};
