import React, { lazy } from "react";
import FullPageLoading from "../../layout/components/FullPageLoading";
import { paths } from "./paths";
import { useSelector } from "react-redux";
import { StateSlice } from "../state";
import { Switch, Route, Redirect } from "react-router-dom";
import { useAuthorization, usePermissions } from "modules/account";
import { EstimatingGetStarted } from "../components/common/estimating-get-started";
import { EstimateLookup } from "./estimate-lookup";
import { useFeatureFlags } from "modules/configurationSettings/use-feature-flags";
import { isSandboxEnabled } from "core/util/sandbox";

const EstimatesPage = lazy(() =>
  import(/* webpackChunkName: "estimates-page" */ "../components/EstimatesPage")
);

const CodebooksPage = lazy(() =>
  import(/* webpackChunkName: "codebooks-page" */ "../components/CodebooksPage")
);

const EstimateEdit = lazy(() =>
  import(/* webpackChunkName: "estimate-edit" */ "../components/EstimateEdit")
);

const CustomizeReportingPage = lazy(() =>
  import(
    /* webpackChunkName: "estimates-customize-reporting" */ "../components/customize-reporting/activity-totals/activity-totals-page"
  )
);

const EstimateDetails = lazy(() =>
  import(
    /* webpackChunkName: "estimates-sub-module" */ "../components/EstimateDetailsSubModule"
  )
);

const EstimatesRouter = () => {
  const loaded = useSelector(({ estimates }: StateSlice) => estimates.loaded);
  const authorization = useAuthorization();
  const permissions = usePermissions();
  const { isFeatureEnabled: isCodebooksFeatureEnabled } = useFeatureFlags(
    "Codebooks"
  );
  const enableCustomizeReporting =
    authorization.canAccessFullPrecon && permissions.admin;

  if (!permissions.estimateInsights) {
    return <EstimatingGetStarted />;
  } else {
    if (!authorization.canAccessHeavyBidInsights) {
      return <EstimatingGetStarted />;
    }
  }

  if (!loaded) {
    return <FullPageLoading loading />;
  }

  return (
    <React.Suspense fallback={<FullPageLoading loading />}>
      <Switch>
        <Redirect from={paths.list} to={paths.base} />
        <Route exact path={paths.base} component={EstimatesPage} />
        {!isSandboxEnabled() && isCodebooksFeatureEnabled && (
          <Route path={paths.codebooks} component={CodebooksPage} />
        )}
        <Route path={paths.edit} component={EstimateEdit} />
        {enableCustomizeReporting && (
          <Route
            path={paths.customizeReporting}
            component={CustomizeReportingPage}
          />
        )}
        <Route path={paths.detail} component={EstimateDetails} />
        <Route path={paths.lookup} component={EstimateLookup} />
      </Switch>
    </React.Suspense>
  );
};

export const Views = () => (
  <Route path={paths.base} component={EstimatesRouter} />
);
