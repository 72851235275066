import LocalizedStrings, { LocalizedStringsMethods } from "react-localization";
import { en } from "./english";
import fr from "./fr-ca";

export type IStrings = typeof en & LocalizedStringsMethods;

export const strings = new LocalizedStrings(
  {
    en,
    fr
  }
  // { pseudo: true } //for testing out what will be translated
) as IStrings;
