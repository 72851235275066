import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmptyState } from "core/components/empty-state";
import { strings } from "localization";

export const EstimatingGetStarted = () => {
  return (
    <EmptyState
      title={strings.estimates.gettingStarted.title}
      text1={strings.estimates.gettingStarted.instructions}
      showButton={false}
      mainIcon={
        <FontAwesomeIcon icon={["fad", "file-invoice-dollar"]} size="10x" />
      }
    />
  );
};
