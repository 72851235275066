import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { BaseTypedDataColumn, DataColumnType } from "hcss-tables";
import { sortBy } from "lodash-es";
import React, { FC, useState, useMemo, useCallback } from "react";
import { strings } from "localization";
import styled from "styled-components";
import { DropdownList } from "react-widgets";

export interface DropdownListOption {
  display: string;
  value?: string;
}

const CLEAR_TEXT = strings.core.dropdown.clear;

export const DropDownEditor: FC<DataTypeProvider.ValueEditorProps> = props => {
  const { value, onValueChange } = props;
  const column = props.column as BaseTypedDataColumn<DataColumnType.List>;
  const [options, setOptions] = useState<DropdownListOption[]>(
    column.config?.listValues ?? []
  );

  const data: DropdownListOption[] = options;

  let sortedData = useMemo(() => {
    if (!column.config?.customSort) {
      return sortBy(data, opt => opt.display.toLocaleLowerCase());
    }
    return data;
  }, [column.config, data]);

  if (value) {
    sortedData = sortedData.concat([{ display: CLEAR_TEXT, value: undefined }]);
  }

  const allowCreate = column.config?.dynamic ?? false;

  const addFieldValue = useCallback(
    (newValue: string) => {
      if (newValue?.trim?.() !== "") {
        newValue = newValue.trimEnd();
        const includesValue = options.some(option => {
          return option.value?.toLowerCase() === newValue.toLowerCase();
        });
        if (!includesValue)
          setOptions([...options, { display: newValue, value: newValue }]);
      }
    },
    [options]
  );

  return useMemo(
    () => (
      <DropdownContainer>
        <DropdownList
          value={value}
          disabled={column.readOnly ?? false}
          filter="contains"
          data={sortedData}
          textField="display"
          valueField="value"
          onChange={newValue =>
            onValueChange(
              newValue && newValue.value ? newValue.value : undefined
            )
          }
          allowCreate={allowCreate}
          onCreate={
            allowCreate
              ? newValue => {
                  addFieldValue(newValue);
                  if (newValue) {
                    onValueChange(newValue.trimEnd());
                  }
                }
              : undefined
          }
          messages={{
            emptyList: "",
            createOption: "To add a new option: type a value, press Enter"
          }}
        />
      </DropdownContainer>
    ),
    [
      addFieldValue,
      allowCreate,
      column.readOnly,
      onValueChange,
      sortedData,
      value
    ]
  );
};
const DropdownContainer = styled.div<{ hasErrors?: boolean }>`
  & .rw-widget-input {
    height: 34px;
    ${props => (props.hasErrors ? "border: 1px solid #a94442;" : "")};
  }
`;
