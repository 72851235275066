import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import React from "react";
import { createGlobalStyle } from "styled-components";

const TooltipStyling = createGlobalStyle<{ $maxWidth?: string }>`
.styled-material-tooltip__popper {
  max-width: ${props => props.$maxWidth || "475px !important"};
  min-width: 200px !important;
}
  .styled-material-tooltip {
    max-width: none!important ;
    background: #FFF !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 0!important;
    padding: 0 !important;
    border-radius: 0 !important;

    color: #000 !important;
    font-family: Inter !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;

    &__arrow {
      background: #FFF !important;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  
    }
    &__header {
      background: #ECECEC;
      padding: 5px 12px;
      width: 100%;
      color: #000;
    }

    &__body {
      padding: 5px 12px;
    }
  }

  .styled-material-tooltip-arrow {
    &::before {
    background: #FFF !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    }
  }
`;

export interface StyledMaterialTooltipProps extends TooltipProps {
  title: string;
  hideHeader?: boolean;
  body: React.ReactNode | React.ReactNode[] | string;
  bodyStyles?: React.CSSProperties;
  maxWidth?: string;
}
export const StyledMaterialTooltip = (props: StyledMaterialTooltipProps) => {
  return (
    <>
      <TooltipStyling $maxWidth={props.maxWidth} />
      <Tooltip
        {...props}
        title={
          <React.Fragment>
            {!props.hideHeader && (
              <div className="styled-material-tooltip__header">
                {props.title}
              </div>
            )}
            <div
              style={props.bodyStyles}
              className="styled-material-tooltip__body"
            >
              {props.body}
            </div>
          </React.Fragment>
        }
        arrow
        classes={{
          popper: "styled-material-tooltip__popper",
          popperInteractive: "styled-material-tooltip__popper-interactive",
          popperArrow: "styled-material-tooltip__popper__arrow",
          popperClose: "styled-material-tooltip__close",
          tooltip: "styled-material-tooltip",
          tooltipArrow: "styled-material-tooltip__arrow",
          arrow: "styled-material-tooltip-arrow",
          touch: "styled-material-tooltip-touch"
        }}
      >
        {props.children}
      </Tooltip>
    </>
  );
};
