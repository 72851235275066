import memoize from "memoize-one";
import moment from "moment";
import { filterConstants } from "./common";

export const calculateValuesFromRange = memoize((range: string) => {
  const from = moment().startOf("day");
  const to = moment().startOf("day");

  switch (range) {
    case filterConstants.dates.nowM7: {
      return {
        to: to,
        from: from.add(-7, "day")
      };
    }
    case filterConstants.dates.nowM30: {
      return {
        from: from.add(-30, "day"),
        to: to
      };
    }
    case filterConstants.dates.nowP7: {
      return {
        to: to.add(7, "day"),
        from: from
      };
    }
    case filterConstants.dates.nowP30: {
      return {
        to: to.add(30, "day"),
        from: from
      };
    }
    case filterConstants.dates.nowM3month: {
      return {
        to: to,
        from: from.add(-3, "month")
      };
    }
    case filterConstants.dates.nowM6month: {
      return {
        to: to,
        from: from.add(-6, "month")
      };
    }
    case filterConstants.dates.nowM365: {
      return {
        to: to.add(0, "day"),
        from: from.add(-1, "year")
      };
    }
    case filterConstants.dates.nowP365: {
      return {
        from: from.add(0, "day"),
        to: to.add(1, "year")
      };
    }

    default: {
      return undefined;
    }
  }
});

export const isYearIn100Range = (date: Date): boolean => {
  if (!date) return false;
  const currentYear = new Date().getFullYear();
  const year = date.getFullYear();
  return year >= currentYear - 100 && year <= currentYear + 100;
};
