// tslint:disable:max-classes-per-file

import React from "react";
import styled from "styled-components";
import { strings } from "localization";

interface LoadingSpinnerProps {
  size: "large" | "medium" | "small";
}
export class LoadingSpinner extends React.PureComponent<
  LoadingSpinnerProps,
  any
> {
  renderSpinnerClass = (): string => {
    switch (this.props.size) {
      case "large":
        return "spinner-large-above";
      case "medium":
        return "spinner-medium-inline";
      case "small":
        return "spinner-small-inline";
      default:
        return "spinner-medium-inline";
    }
  };

  render() {
    return (
      <StyledLoadingSpinner
        className={`loading-message-spinner ${this.renderSpinnerClass()}`}
      />
    );
  }
}

export interface LoadingOverlayProps {
  message?: string;
}

export class PageLoadingOverlay extends React.PureComponent<
  LoadingOverlayProps,
  any
> {
  render() {
    return (
      <PageLoadingOverlayContainer className="page-loading-overlay">
        <div className="loading-message">
          <LoadingSpinner size="large" />
          <div className="loading-message-text">
            {this.props.message || strings.core.loaders.loading}
          </div>
        </div>
      </PageLoadingOverlayContainer>
    );
  }
}

export class ContentLoadingOverlay extends React.PureComponent<
  LoadingOverlayProps & { hideMessage?: boolean },
  any
> {
  render() {
    return (
      <ContentLoadingOverlayContainer className="content-loading-overlay">
        <div className="loading-message">
          <LoadingSpinner size="medium" />
          {!this.props.hideMessage && (
            <div className="loading-message-text">
              {this.props.message || strings.core.loaders.loading}
            </div>
          )}
        </div>
      </ContentLoadingOverlayContainer>
    );
  }
}
export class SectionLoadingOverlay extends React.PureComponent<
  LoadingOverlayProps & { hideMessage?: boolean; height: number },
  any
> {
  render() {
    return (
      <SectionLoadingOverlayContainer
        className="section-loading-overlay"
        style={{ height: this.props.height }}
      >
        <div className="loading-message">
          <LoadingSpinner size="medium" />
          {!this.props.hideMessage && (
            <div className="loading-message-text">
              {this.props.message || strings.core.loaders.loading}
            </div>
          )}
        </div>
      </SectionLoadingOverlayContainer>
    );
  }
}

export class PanelLoadingOverlay extends React.PureComponent<
  LoadingOverlayProps,
  any
> {
  render() {
    return (
      <PanelLoadingOverlayContainer className="panel-loading-overlay">
        <div className="loading-message">
          <LoadingSpinner size="medium" />
          <div className="loading-message-text">
            {this.props.message || strings.core.loaders.loading}
          </div>
        </div>
      </PanelLoadingOverlayContainer>
    );
  }
}

export class DialogLoadingOverlay extends React.PureComponent<
  LoadingOverlayProps,
  any
> {
  render() {
    return (
      <DialogLoadingOverlayContainer className="dialog-loading-overlay">
        <div className="loading-message">
          <LoadingSpinner size="medium" />
          <div className="loading-message-text">
            {this.props.message || strings.core.loaders.updating}
          </div>
        </div>
      </DialogLoadingOverlayContainer>
    );
  }
}

export class ListLoadingOverlay extends React.PureComponent<
  LoadingOverlayProps,
  any
> {
  render() {
    return (
      <ListLoadingOverlayContainer className="list-loading-notifier">
        <div className="loading-message">
          <LoadingSpinner size="small" />
          <div className="loading-message-text">
            {this.props.message || strings.core.loaders.loading}
          </div>
        </div>
      </ListLoadingOverlayContainer>
    );
  }
}

export class TableLoadingOverlay extends React.PureComponent<
  LoadingOverlayProps,
  any
> {
  render() {
    return (
      <TableLoadingOverlayContainer className="table-loading-overlay">
        <div className="loading-message">
          <LoadingSpinner size="medium" />
          <div className="loading-message-text">
            {this.props.message || strings.core.loaders.loading}
          </div>
        </div>
      </TableLoadingOverlayContainer>
    );
  }
}

export interface TableInnerLoadingOverlayProps extends LoadingOverlayProps {
  noFooterPanel?: boolean;
}
export class TableInnerLoadingOverlay extends React.PureComponent<
  TableInnerLoadingOverlayProps,
  any
> {
  render() {
    return (
      <TableInnerLoadingOverlayContainer
        className="table-inner-loading-overlay"
        data-no-footer-panel={this.props.noFooterPanel || false}
      >
        <div className="loading-message">
          <LoadingSpinner size="small" />
          <div className="loading-message-text">
            {this.props.message || strings.core.loaders.loading}
          </div>
        </div>
      </TableInnerLoadingOverlayContainer>
    );
  }
}

export const BaseLoadingOverlayContainer = styled.div`
  display: flex;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  text-align: center;

  & > .loading-message {
    margin: auto;
    color: #404040;
  }
`;

const ContentLoadingOverlayContainer = styled(BaseLoadingOverlayContainer)`
  position: absolute;
  opacity: 0.98;
  z-index: 10;

  & > .loading-message > .loading-message-text {
    display: inline-block;
    margin: 0 0 0 16px;
    font-size: 16px;
    vertical-align: middle;
  }
`;

const SectionLoadingOverlayContainer = styled(BaseLoadingOverlayContainer)`
  position: relative;
  opacity: 0.98;
  z-index: 10;

  & > .loading-message > .loading-message-text {
    display: inline-block;
    margin: 0 0 0 16px;
    font-size: 16px;
    vertical-align: middle;
  }
`;

const PageLoadingOverlayContainer = styled(BaseLoadingOverlayContainer)`
  position: absolute;
  background: #f6f6f6;
  opacity: 1;
  z-index: 15;

  & > .loading-message > .loading-message-text {
    margin: -40px 0 0 6px;
    font-size: 18px;
  }
`;

const PanelLoadingOverlayContainer = styled(ContentLoadingOverlayContainer)`
  & > .loading-message {
    margin: 80px auto;
  }
`;

const DialogLoadingOverlayContainer = styled(ContentLoadingOverlayContainer)`
  border-radius: 6px;

  & > .loading-message > .loading-message-text {
    font-size: 16px;
  }
`;

const ListLoadingOverlayContainer = styled(ContentLoadingOverlayContainer)`
  position: relative;
  height: auto;
  text-align: left;
  opacity: 1;

  & > .loading-message {
    margin: 0 0 4px 12px;

    & > .loading-message-text {
      font-size: 13px;
      margin: 5px 0 0 24px;
      vertical-align: text-top;
    }
  }
`;

const TableLoadingOverlayContainer = styled(ContentLoadingOverlayContainer)`
  /* This loader covers the entire table, including the header and footer panels */
  opacity: 1;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #ffffff;
`;

const TableInnerLoadingOverlayContainer = styled(
  ContentLoadingOverlayContainer
)`
  /* This loader covers only the data rows, leaving the header and footer panels visible */
  opacity: 1;
  top: 87.5px;
  left: auto;
  border: 1px solid #dddddd;
  width: 100%;

  &[data-no-footer-panel="false"] {
    height: calc(100% - 88px);
    border-radius: 0;
  }

  &[data-no-footer-panel="true"] {
    height: calc(100% - 93.5px);
    border-radius: 0 0 3px 3px;
  }

  & > .loading-message > .loading-message-text {
    margin: 3px 0 0 24px;
    font-size: 15px;
    vertical-align: text-top;
  }
`;

const StyledLoadingSpinner = styled.div`
  &.spinner-large-above {
    position: relative;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    margin: 60px auto;
    margin-top: -40px;
    font-size: 10px;
    text-indent: -9999em;
    border-top: 3px solid #cccccc;
    border-right: 3px solid #cccccc;
    border-bottom: 3px solid #cccccc;
    border-left: 3px solid #1e1e1e;
    transform: translateZ(0);
    animation: rollSpinner 1.1s infinite linear;

    &:after {
      border-radius: 50%;
    }
  }

  &.spinner-medium-inline {
    display: inline-block;
    position: relative;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    font-size: 10px;
    vertical-align: middle;
    text-indent: -9999em;
    border-top: 3px solid #cccccc;
    border-right: 3px solid #cccccc;
    border-bottom: 3px solid #cccccc;
    border-left: 3px solid #1e1e1e;
    transform: translateZ(0);
    animation: rollSpinner 1.1s infinite linear;

    &:after {
      border-radius: 50%;
    }
  }

  &.spinner-small-inline {
    display: inline-block;
    position: relative;
    width: 3em;
    height: 3em;
    color: #1e1e1e;
    font-size: 3px;
    text-indent: -9999em;
    animation: threeDotSpinner 1.1s infinite ease-in-out;
    animation-delay: -0.16s;
    animation-fill-mode: both;
    vertical-align: top;
    transform: translateZ(0);

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      width: 3em;
      height: 3em;
      animation: threeDotSpinner 1.4s infinite ease-in-out;
      animation-fill-mode: both;
    }
    &:before {
      left: -12px;
      animation-delay: -0.32s;
    }
    &:after {
      left: 12px;
    }
  }

  @keyframes rollSpinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes threeDotSpinner {
    0%,
    20%,
    80%,
    100% {
      box-shadow: 0 4em 0 -0.95em #ececec;
    }
    50% {
      box-shadow: 0 4em 0 -0.2em #aaaaaa;
    }
  }
`;
