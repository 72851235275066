import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { strings } from "localization";
import { useCodebooksContext } from "../../context/CodebooksContext";
import styled from "styled-components";
import { selectors, actions, UtilizedActivityCodebookCode } from "../../state";
import {
  Grid,
  Toolbar,
  SearchPanel,
  DragDropProvider,
  TableSelection,
  VirtualTable,
  Table,
  TableHeaderRow as TableHeaderRowDx
} from "@devexpress/dx-react-grid-bootstrap3";
import { Box } from "hcss-core";
import {
  IntegratedSorting,
  GroupingState,
  IntegratedGrouping,
  SearchState,
  AllRows,
  GroupKeys,
  ColumnReordering,
  ColumnResizing,
  ColumnVisibility,
  SortingState,
  TableGroupRow,
  TableHeaderRow,
  Root,
  OtherOptionsButton,
  TableContainer
} from "hcss-tables";
import { ExtendedIntegratedFiltering } from "modules/tables/search";
import { StyledSelectionRow } from "modules/tables/selection-table";
import { PanelLoadingOverlay } from "core";
import ExportRows from "modules/tables/plugins/export-rows";
import { CustomColumnChooserModal } from "modules/tables/column-chooser-modal";
import { DateFormatProvider } from "modules/tables/columns/column-providers";
import { Icon, Link } from "hcss-components";
import {
  SectionHeader,
  SectionTitle,
  utilizedColumns,
  utilizedDetailsColumns,
  Timestamps
} from "./CodebooksShared";
import { SelectionState, IntegratedSelection } from "@devexpress/dx-react-grid";
import { useLocalStorage } from "hcss-hooks";
import { SchemaViewFilter, SchemaFieldType } from "api";
import { selectors as schemaSelectors } from "modules/schemas";
import {
  FiltersNoView,
  FiltersNoViewProvider
} from "core/components/filters-noview";
import { get, isEqual } from "lodash-es";
import { filterField } from "modules/schemas/filters";
import { CircularProgress, Grid as MuiGrid } from "@mui/material";
import { EmptyState } from "core/components/empty-state";
import { CodebooksIcon } from "./CodebooksIcon";
import { useSelectedBusinessUnitId } from "modules/account";
import LegacyVirtualTable from "core/components/bundle-fixes/LegacyVirtualTable";
import { LegacyGroupingPanel } from "core/components/bundle-fixes/LegacyGroupingPanel";

export const CodebooksUtilizedPage = () => {
  const dispatch = useDispatch();
  const codebooksFields = useSelector(
    schemaSelectors.getActivityCodebooksSchemaFields
  );
  const utilized = useSelector(selectors.getActivityCodebookUtilized);
  const businessUnitId = useSelectedBusinessUnitId();
  const [filters, setFilters] = useLocalStorage<
    Record<string, SchemaViewFilter>
  >(`${businessUnitId}-codebooks-utilized-active-filters`, {});
  const {
    currentDivisionId,
    utilizedLoaded,
    setSelectedUtilizedCode
  } = useCodebooksContext();
  const [utilizedTableData, setUtilizedTableData] = useState<
    UtilizedActivityCodebookCode[]
  >([]);
  const [selection, _setSelection] = useState<Array<string | number>>([]);

  useEffect(() => {
    const filteredUtilized = Object.values(utilized).filter(activity => {
      if (activity.partitionId !== currentDivisionId) return false;
      const filterArray = Object.values(filters);
      //Map codebook so that the field keys match the schema (i.e. codebook.code)
      const mappedCodebookCode = {
        codebook: {
          ...activity
        }
      };
      for (const filter of filterArray) {
        const field = codebooksFields[filter.columnName];
        let value = get(mappedCodebookCode, filter.columnName);
        if (field.type === SchemaFieldType.ShortText) {
          value = value.trim();
        }
        if (!filterField(field.type, filter, value)) {
          return false;
        }
      }
      return true;
    });
    if (!isEqual(filteredUtilized, utilizedTableData)) {
      setUtilizedTableData(filteredUtilized);
    }
  }, [
    filters,
    utilized,
    currentDivisionId,
    utilizedTableData,
    codebooksFields
  ]);

  useEffect(() => {
    _setSelection([]);
  }, []);

  useEffect(() => {
    if (selection.length > 0 && currentDivisionId) {
      const selectedCode = utilized[selection[0]];
      if (selectedCode && selectedCode.timesModified > 0) {
        dispatch(
          actions.loadActivityCodebookUtilizedDetails.request({
            partitionId: currentDivisionId,
            activityCode: selectedCode.code
          })
        );
      }
    }
    if (utilizedLoaded) {
      setSelectedUtilizedCode(
        selection.length > 0 ? utilized[selection[0]].code : ""
      );
    } else {
      _setSelection([]);
      setSelectedUtilizedCode("");
    }
  }, [
    currentDivisionId,
    dispatch,
    selection,
    setSelectedUtilizedCode,
    utilized,
    utilizedLoaded
  ]);

  if (!utilizedLoaded) {
    return <PanelLoadingOverlay />;
  }
  return (
    <div data-testid="codebooks-utilized-table">
      <SectionHeader>
        <MuiGrid container direction={"row"} justifyContent={"space-between"}>
          <MuiGrid item>
            <SectionTitle>
              {strings.estimates.codebooks.utilized.pageTitle}
            </SectionTitle>
          </MuiGrid>
          <MuiGrid item>
            <div style={{ fontSize: "12.5px", marginTop: "5px" }}>
              {strings.estimates.codebooks.utilized.tip}
            </div>
          </MuiGrid>
          <MuiGrid item>
            <Timestamps />
          </MuiGrid>
        </MuiGrid>
      </SectionHeader>
      <FiltersNoViewProvider filters={filters} setFilters={setFilters}>
        <CodebooksUtilizedTable
          tableData={utilizedTableData}
          selection={selection}
          _setSelection={_setSelection}
          utilized={utilized}
        />
      </FiltersNoViewProvider>
    </div>
  );
};
interface CodebooksUtilizedTableProps {
  tableData: UtilizedActivityCodebookCode[];
  selection: (string | number)[];
  _setSelection: (s: (string | number)[]) => void;
  utilized: Record<string, UtilizedActivityCodebookCode>;
}
export const CodebooksUtilizedTable = ({
  tableData,
  selection,
  _setSelection,
  utilized
}: CodebooksUtilizedTableProps) => {
  const vtRef = useRef();
  const businessUnitId = useSelectedBusinessUnitId();

  const setSelection = (codes: Array<string | number>) => {
    const prevSelected = codes.length > 1;
    const lastSelected = codes.find(
      selected => selection.indexOf(selected) === -1
    );
    if (lastSelected !== undefined) {
      _setSelection([lastSelected]);
      if (!prevSelected) {
        scrollToRow(lastSelected);
      }
    } else {
      _setSelection([]);
    }
  };

  const scrollToRow = useCallback(
    rowId => {
      vtRef.current.scrollToRow(rowId);
    },
    [vtRef]
  );

  const getPlaceholderText = () => {
    if (Object.values(utilized).length === 0) {
      return strings.estimates.codebooks.utilized.noUtilized;
    }
    return strings.estimates.codebooks.utilized.noMatchingFilters;
  };

  return (
    <>
      <FiltersNoView
        schemaId={"activityCodebooks"}
        selectedFields={[
          "codebook.code",
          "codebook.description",
          "codebook.timesModified",
          "codebook.timesUsed"
        ]}
        pageId={`${businessUnitId}-codebooks-utilized`}
      />
      <Section
        height={selection.length >= 1 ? "calc(25vh)" : "calc(100vh - 260px)"}
      >
        {tableData.length <= 0 ? (
          <EmptyState
            data-testid="codebooks-utilized-no-data"
            title={getPlaceholderText()}
            text1=""
            mainIcon={
              <div style={{ height: "180px", width: "180px" }}>
                <CodebooksIcon />
              </div>
            }
          />
        ) : (
          <TableContainer
            templateDisplayName="UtilizedActivityTable"
            templateName="Utilized Activity Codebook Codes Table"
            columns={utilizedColumns}
            defaults={{
              sorting: {
                defaultSorting: [{ columnName: "code", direction: "asc" }]
              },
              sizing: {
                defaultSizing: [{ columnName: "description", width: 375 }]
              }
            }}
          >
            <Grid
              rows={tableData}
              columns={utilizedColumns}
              rootComponent={Root}
              getRowId={r => r.id}
            >
              <DateFormatProvider />
              <SearchState />
              <DragDropProvider />
              <SortingState />
              <IntegratedSorting />
              <ExtendedIntegratedFiltering columns={utilizedColumns} />
              <SelectionState
                selection={selection}
                onSelectionChange={setSelection}
              />
              <IntegratedSelection />
              <GroupingState />
              <IntegratedGrouping />
              <AllRows />
              <GroupKeys />
              <ExportRows
                templateDisplayName={
                  strings.estimates.codebooks.utilized.pageTitle
                }
              />
              <VirtualTable ref={vtRef} />
              <ColumnReordering />
              <ColumnResizing />
              <TableHeaderRowDx showSortingControls />
              <TableSelection
                selectByRowClick
                highlightRow
                showSelectionColumn={false}
                //@ts-ignore
                rowComponent={StyledSelectionRow}
              />
              <TableGroupRow />
              <Toolbar />
              <LegacyGroupingPanel showGroupingControls />
              <SearchPanel />
              <OtherOptionsButton
                messages={{
                  exportCsv: strings.tables.options.exportCSVFull,
                  exportPdf: strings.tables.options.exportPDFFull,
                  showHideColumns: strings.tables.options.showColumns
                }}
                options={{
                  showColumnVisibility: false
                }}
              />
            </Grid>
            <CustomColumnChooserModal />
          </TableContainer>
        )}
      </Section>
      {selection.length > 0 && utilized[selection[0]] && (
        <CodebooksUtilizedDetailsTable
          utilizedCode={utilized[selection[0]]}
          _setSelection={_setSelection}
        />
      )}
    </>
  );
};

interface CodebooksUtilizedDetailsTableProps {
  utilizedCode: UtilizedActivityCodebookCode;
  _setSelection: (s: (string | number)[]) => void;
}

export const CodebooksUtilizedDetailsTable = ({
  utilizedCode,
  _setSelection
}: CodebooksUtilizedDetailsTableProps) => {
  const { utilizedDetailsLoaded } = useCodebooksContext();
  let utilizedDetails = useSelector(
    selectors.getActivityCodebookUtilizedDetails
  );
  if (!localStorage.getItem("Utilized Activity Details Table.sorting")) {
    utilizedDetails = utilizedDetails.sort((a, b) => {
      if (!a.lastModified) return -1;
      if (!b.lastModified) return 1;
      if (a.lastModified < b.lastModified) return -1;
      if (a.lastModified > b.lastModified) return 1;
      if (a.estimate < b.estimate) return -1;
      if (a.estimate > b.estimate) return 1;
      return 0;
    });
  }

  const getNoDataMessage = () => {
    if (utilizedCode.timesModified === 0) {
      return strings.estimates.codebooks.utilized.noModifications;
    } else if (!utilizedDetailsLoaded) {
      return <CircularProgress size={28} />;
    }
    return strings.estimates.codebooks.utilized.noMatchingSearch;
  };

  const noDataComponent = (rowProps: Table.RowProps) => {
    return (
      <Table.NoDataRow {...rowProps}>
        <td colSpan={7}>
          <div
            className="text-muted"
            style={{
              padding: "50px 20px",
              fontSize: "larger",
              textAlign: "center"
            }}
          >
            {getNoDataMessage()}
          </div>
        </td>
      </Table.NoDataRow>
    );
  };

  return (
    <BottomSection>
      <SectionHeader>
        <Link style={{ marginLeft: "5px" }} onClick={() => _setSelection([])}>
          <BackIcon name="arrow-left" />
        </Link>
        <SectionTitle>
          {`${strings.estimates.codebooks.utilized.pageTitle2} ${utilizedCode.code}`}
        </SectionTitle>
      </SectionHeader>
      <TableContainer
        templateDisplayName="UtilizedActivityDetailsTable"
        templateName="Utilized Activity Details Table"
        columns={utilizedDetailsColumns}
        defaults={{
          sorting: {
            defaultSorting: [{ columnName: "lastModified", direction: "desc" }]
          }
        }}
      >
        <Grid
          rows={utilizedCode.timesModified > 0 ? utilizedDetails : []}
          columns={utilizedDetailsColumns}
          rootComponent={Root}
        >
          <DateFormatProvider />
          <SearchState />
          <DragDropProvider />
          <SortingState />
          <IntegratedSorting />
          <GroupingState />
          <IntegratedGrouping />
          <ExtendedIntegratedFiltering columns={utilizedDetailsColumns} />
          <AllRows />
          <GroupKeys />
          <ExportRows
            templateDisplayName={`${strings.estimates.codebooks.utilized.pageTitle2} ${utilizedCode.code}`}
          />
          <LegacyVirtualTable
            messages={{
              noData: strings.estimates.codebooks.utilized.noModifications
            }}
            noDataRowComponent={noDataComponent}
          />
          <ColumnReordering />
          <ColumnVisibility />
          <ColumnResizing />
          <TableHeaderRow showSortingControls />
          <TableGroupRow />
          <Toolbar />
          <LegacyGroupingPanel showGroupingControls />
          <SearchPanel />
          <OtherOptionsButton
            messages={{
              collapseAll: strings.tables.options.collapseAll,
              expandAll: strings.tables.options.expandAll,
              exportCsv: strings.tables.options.exportCSVFull,
              exportPdf: strings.tables.options.exportPDFFull,
              showHideColumns: strings.tables.options.showColumns
            }}
          />
        </Grid>
        <CustomColumnChooserModal />
      </TableContainer>
    </BottomSection>
  );
};

const BackIcon = styled(Icon)`
  color: black;
  margin-right: 10px;
`;

export const Section = styled(Box).attrs(() => ({ as: "section" }))`
  margin-bottom: 10px;
  @media print {
    & {
      page-break-inside: avoid;
    }
  }
`;

export const BottomSection = styled(Section).attrs(() => ({ as: "section" }))`
  height: calc(60vh - 170px);
`;
