import * as React from "react";
import styled from "styled-components";
import { PaneledPageExtendedProps } from "./ExtendedProps";
import { PaneledPageContextState } from "./PaneledPageContext";

export const defaultAsideWidth = 200;

export const isContentAsideTemplate = (
  node: any
): node is ContentAsideTemplate => {
  if (
    node &&
    node.props &&
    node.type.componentType === "ContentAsideTemplate" &&
    (node as ContentAsideTemplate).props.width !== undefined
  )
    return true;
  else return false;
};

export interface ContentAsideTemplateProps extends PaneledPageExtendedProps {
  /** Automation testing id for the PaneledPage.Aside component. Defaults to "paneledpage-content-aside". */
  testId?: string;
  /** Custom width, in pixels, for the PaneledPage.Aside component. Defaults to 200. */
  width?: number;
  /** When set to true, PaneledPage.Aside height is set to the height of the container.
   * When set to false, the content determines the height, up to the height of the container.
   * In both cases, once the container's height is reached, scroll overflow is activated.
   * Defaults to "true". */
  fillContentHeight?: boolean;
}
export class ContentAsideTemplate extends React.PureComponent<
  ContentAsideTemplateProps
> {
  static defaultProps: ContentAsideTemplateProps = {
    testId: "paneledpage-content-aside",
    width: 200,
    fillContentHeight: true
  };
  static componentType = "ContentAsideTemplate";
}

interface ContentAsideProps extends ContentAsideTemplateProps {
  pageContext: PaneledPageContextState;
}
export default class ContentAside extends React.PureComponent<
  ContentAsideProps
> {
  static componentType = "ContentAside";

  componentDidMount() {
    if (this.props.pageContext.setAsideWidth) {
      this.props.pageContext.setAsideWidth(this.props.width);
    }
  }

  componentDidUpdate(prevProps: ContentAsideProps) {
    if (
      this.props.width !== prevProps.width &&
      this.props.pageContext.setAsideWidth
    ) {
      this.props.pageContext.setAsideWidth(this.props.width);
    }
  }

  render() {
    return (
      <StyledContentAside
        className="page-content-aside"
        data-testid={this.props.testId}
        data-fill-content-height={this.props.fillContentHeight}
        expandedwidth={this.props.pageContext.asideWidth}
        {...this.props.passThroughProps}
      >
        {this.props.children}
      </StyledContentAside>
    );
  }
}

/* eslint-disable-next-line */
export const StyledContentAside = styled.aside<{
  expandedwidth: number;
}>`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  max-height: 100%;
  width: ${props => `${props.expandedwidth}px`};
  padding: 12px 16px;
  overflow: auto;
  z-index: 2;

  &[data-fill-content-height="true"] {
    height: 100%;
  }
`;
