import { IVendorLocationDto } from "api/contacts-api-dto-interfaces";
import { IVendorDtoV1Response } from "api/GeneratedClients/ContactsClient";
import { isNullOrWhitespace, isValidString } from "core";
import { isEqual } from "lodash-es";
import {
  actions as contactActions,
  selectors as contactsSelectors
} from "modules/contacts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
interface UseSaveVendorDetailsProps {
  vendorNickname: string;
}

export interface ValidationFlagOptions {
  isNullOrWhitespace: boolean;
  isDuplicateVendorCode: boolean;
  isValidVendorNickname: boolean;
  validationFailed: boolean;
}

export function useSaveVendorDetails({
  vendorNickname
}: UseSaveVendorDetailsProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const vendorList: IVendorDtoV1Response[] = useSelector(
    contactsSelectors.getVendorListSortedByCode
  );

  const handleSaveChanges = (
    vendor: IVendorDtoV1Response,
    noOfficeSelected: boolean,
    unsavedVendorLocation: IVendorLocationDto | undefined = undefined
  ) => {
    let updatedVendor: IVendorDtoV1Response;
    const code = vendor.code.trim();

    if (unsavedVendorLocation) {
      updatedVendor = includeUnsavedVendorLocation(
        vendor,
        unsavedVendorLocation
      );
    } else {
      updatedVendor = vendor;
    }

    updatedVendor = { ...updatedVendor, code };

    const validationFlags = validateLocalVendor(
      updatedVendor,
      noOfficeSelected
    );
    if (validationFlags.validationFailed) return validationFlags;
    dispatch(
      contactActions.updateVendor.request({
        vendor: updatedVendor,
        history: history
      })
    );
  };

  const validateLocalVendor = (
    vendor: IVendorDtoV1Response,
    noOfficeSelected: boolean
  ) => {
    let validationFlags: ValidationFlagOptions = {
      isNullOrWhitespace: false,
      isDuplicateVendorCode: false,
      isValidVendorNickname: true,
      validationFailed: false
    };

    if (!vendor || isNullOrWhitespace(vendor.code)) {
      validationFlags = {
        ...validationFlags,
        isNullOrWhitespace: true,
        validationFailed: true
      };
    }
    if (vendor && isDuplicateVendorCode(vendor)) {
      validationFlags = {
        ...validationFlags,
        isDuplicateVendorCode: true,
        validationFailed: true
      };
    }
    if (vendor && !validateNickName(vendorNickname, noOfficeSelected)) {
      validationFlags = {
        ...validationFlags,
        isValidVendorNickname: false,
        validationFailed: true
      };
    }

    return validationFlags;
  };

  const isDuplicateVendorCode = (vendor: IVendorDtoV1Response) => {
    return vendorList.some(
      existingVendor =>
        existingVendor.code.trim() === vendor.code.toUpperCase().trim() &&
        existingVendor.id !== vendor.id
    );
  };

  const validateNickName = (
    vendorNickname: string,
    noOfficeSelected: boolean
  ) => {
    if (!noOfficeSelected) return true;
    return isValidString(vendorNickname);
  };

  return { handleSaveChanges };
}

const includeUnsavedVendorLocation = (
  vendor: IVendorDtoV1Response,
  unsavedLocation: IVendorLocationDto
): IVendorDtoV1Response => {
  let updatedVendor: IVendorDtoV1Response = vendor;
  const unsavedLocationInRedux = vendor.locations?.find(
    loc => loc.id === unsavedLocation.id
  );
  if (
    unsavedLocationInRedux &&
    !isNullOrWhitespace(unsavedLocation.nickname) &&
    !isEqual(unsavedLocation, unsavedLocationInRedux)
  ) {
    const updatedLocations = vendor.locations?.map(loc => {
      if (loc.id === unsavedLocation.id) {
        return unsavedLocation;
      }
      return loc;
    });
    updatedVendor = { ...vendor, locations: updatedLocations };
  }
  const filteredNullNicknameLocations = updatedVendor.locations?.filter(
    loc => !isNullOrWhitespace(loc.nickname)
  );
  return { ...updatedVendor, locations: filteredNullNicknameLocations };
};
