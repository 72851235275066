import { keyBy } from "lodash-es";
import {
  FilterOperation,
  SchemaField,
  SchemaFieldType,
  SchemaViewFilter
} from "../../../../api";

export interface FilterControlProps {
  field: SchemaField;
  filter: SchemaViewFilter;
  onChange: (filter: SchemaViewFilter) => void;
}

export const defaultFilterOperation: Record<number, FilterOperation> = {
  [SchemaFieldType.ShortText]: FilterOperation.Contains,
  [SchemaFieldType.LongText]: FilterOperation.Contains,
  [SchemaFieldType.Links]: FilterOperation.Contains,
  [SchemaFieldType.Checklist]: FilterOperation.Contains,
  [SchemaFieldType.Number]: FilterOperation.Between,
  [SchemaFieldType.Currency]: FilterOperation.Between,
  [SchemaFieldType.Calculated]: FilterOperation.Between,
  [SchemaFieldType.Date]: FilterOperation.Between,
  [SchemaFieldType.DateTime]: FilterOperation.Between,
  [SchemaFieldType.List]: FilterOperation.Any,
  [SchemaFieldType.Boolean]: FilterOperation.Equals,
  [SchemaFieldType.States]: FilterOperation.Any,
  [SchemaFieldType.MultiSelectList]: FilterOperation.Any,
  [SchemaFieldType.PreConId]: FilterOperation.Contains,
  [SchemaFieldType.Company]: FilterOperation.Any,
  [SchemaFieldType.Contact]: FilterOperation.Any
};

export const allFilterOperations = [
  {
    label: "Between",
    value: FilterOperation.Between.toString(),
    operation: FilterOperation.Between,
    types: [
      SchemaFieldType.Number,
      SchemaFieldType.Currency,
      SchemaFieldType.Date,
      SchemaFieldType.DateTime
    ]
  },
  {
    label: "Greater Than",
    value: FilterOperation.GreaterThan.toString(),
    operation: FilterOperation.GreaterThan,
    types: [
      SchemaFieldType.Number,
      SchemaFieldType.Currency,
      SchemaFieldType.Date,
      SchemaFieldType.DateTime
    ]
  },
  {
    label: "Greater Than or Equal To",
    value: FilterOperation.GreaterThanOrEqualTo.toString(),
    operation: FilterOperation.GreaterThanOrEqualTo,
    types: [
      SchemaFieldType.Number,
      SchemaFieldType.Currency,
      SchemaFieldType.Date,
      SchemaFieldType.DateTime
    ]
  },
  {
    label: "Less Than",
    value: FilterOperation.LessThan.toString(),
    operation: FilterOperation.LessThan,
    types: [
      SchemaFieldType.Number,
      SchemaFieldType.Currency,
      SchemaFieldType.Date,
      SchemaFieldType.DateTime
    ]
  },
  {
    label: "Less Than or Equal To",
    value: FilterOperation.LessThanOrEqualTo.toString(),
    operation: FilterOperation.LessThanOrEqualTo,
    types: [
      SchemaFieldType.Number,
      SchemaFieldType.Currency,
      SchemaFieldType.Date,
      SchemaFieldType.DateTime
    ]
  },
  {
    label: "Include",
    value: FilterOperation.Any.toString(),
    operation: FilterOperation.Any,
    types: [SchemaFieldType.List, SchemaFieldType.States]
  },
  {
    label: "Exclude",
    value: FilterOperation.None.toString(),
    operation: FilterOperation.None,
    types: [SchemaFieldType.List, SchemaFieldType.States]
  },
  {
    label: "Contains",
    value: FilterOperation.Contains.toString(),
    operation: FilterOperation.Contains,
    types: [
      SchemaFieldType.ShortText,
      SchemaFieldType.LongText,
      SchemaFieldType.Links,
      SchemaFieldType.Checklist,
      SchemaFieldType.PreConId
    ]
  },
  {
    label: "Starts With",
    value: FilterOperation.StartsWith.toString(),
    operation: FilterOperation.StartsWith,
    types: [
      SchemaFieldType.ShortText,
      SchemaFieldType.LongText,
      SchemaFieldType.Links,
      SchemaFieldType.Checklist,
      SchemaFieldType.PreConId
    ]
  },
  {
    label: "Ends With",
    value: FilterOperation.EndsWith.toString(),
    operation: FilterOperation.EndsWith,
    types: [
      SchemaFieldType.ShortText,
      SchemaFieldType.LongText,
      SchemaFieldType.Links,
      SchemaFieldType.Checklist,
      SchemaFieldType.PreConId
    ]
  },
  {
    label: "Equals",
    value: FilterOperation.Equals.toString(),
    operation: FilterOperation.Equals,
    types: [
      SchemaFieldType.ShortText,
      SchemaFieldType.LongText,
      SchemaFieldType.Links,
      SchemaFieldType.Checklist,
      SchemaFieldType.Boolean,
      SchemaFieldType.Number,
      SchemaFieldType.Currency,
      SchemaFieldType.Date,
      SchemaFieldType.DateTime,
      SchemaFieldType.PreConId
    ]
  },
  {
    label: "Does Not Equal",
    value: FilterOperation.NotEquals.toString(),
    operation: FilterOperation.NotEquals,
    types: [
      SchemaFieldType.ShortText,
      SchemaFieldType.LongText,
      SchemaFieldType.Links,
      SchemaFieldType.Checklist,
      SchemaFieldType.Boolean,
      SchemaFieldType.Number,
      SchemaFieldType.Currency,
      SchemaFieldType.Date,
      SchemaFieldType.DateTime,
      SchemaFieldType.PreConId
    ]
  },
  {
    label: "Include Any",
    value: FilterOperation.Any.toString(),
    operation: FilterOperation.Any,
    types: [
      SchemaFieldType.MultiSelectList,
      SchemaFieldType.Company,
      SchemaFieldType.Contact
    ]
  },
  {
    label: "Exclude Any",
    value: FilterOperation.None.toString(),
    operation: FilterOperation.None,
    types: [
      SchemaFieldType.MultiSelectList,
      SchemaFieldType.Company,
      SchemaFieldType.Contact
    ]
  },
  {
    label: "Include All",
    value: FilterOperation.Equals.toString(),
    operation: FilterOperation.Equals,
    types: [
      SchemaFieldType.MultiSelectList,
      SchemaFieldType.Company,
      SchemaFieldType.Contact
    ]
  },
  {
    label: "Exclude All",
    value: FilterOperation.NotEquals.toString(),
    operation: FilterOperation.NotEquals,
    types: [
      SchemaFieldType.MultiSelectList,
      SchemaFieldType.Company,
      SchemaFieldType.Contact
    ]
  }
];

export const filterOptionLookup = keyBy(allFilterOperations, "operation");

export const filterConstants: { dates: { [key: string]: string } } = {
  dates: {
    nowM7: "nowM7",
    nowM30: "nowM30",
    nowP7: "nowP7",
    nowP30: "nowP30",
    nowM3month: "nowM3month",
    nowM6month: "nowM6month",
    nowM365: "nowM365",
    nowP365: "nowP365",
    custom: "custom"
  }
};

export const dateStandardRanges = {
  nowM7: { start: -7, end: 0 },
  nowM30: { start: -30, end: 0 },
  nowP7: { start: 0, end: 7 },
  nowP30: { start: 0, end: 30 },
  nowM365: { start: -365, end: 0 },
  nowP365: { start: 0, end: 365 }
};
