import { HBFields } from "./components/preconhbmap/HBFields";
import { useSelector } from "react-redux";
import { getEstimatesExtendedSchemaFieldLabels } from "./selectors";
import { determineHBFieldKeyForLabelKey } from "modules/estimates/utils";
import { useMemo } from "react";

export const useHBFields = () => {
  const fieldLabels = useSelector(getEstimatesExtendedSchemaFieldLabels);
  const hbFieldsWithLabels = useMemo(() => {
    return HBFields.map(section => {
      const currentSectionOptions = [...section.options];
      return {
        ...section,
        options: currentSectionOptions.map(option => {
          const mappedKey = determineHBFieldKeyForLabelKey(option.value);
          const label =
            fieldLabels?.[`values.filters.${mappedKey}`] ?? option.label;
          return {
            ...option,
            label
          };
        })
      };
    });
  }, [fieldLabels]);
  return hbFieldsWithLabels;
};
