import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useQueryParam } from "core/hooks/use-query-params";
import { useLocation, Link } from "react-router-dom";
import { accountService } from "modules/account/services/account-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConcreteColors, Button } from "hcss-components";
import FullPageLoading from "modules/layout/components/FullPageLoading";
import { strings } from "localization";

export const EmailVerificationPage = () => {
  const location = useLocation();
  const token = useQueryParam<string>("t", location.search)!;
  const [loading, setloading] = useState(true);
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    const completeVerification = async () => {
      try {
        await accountService.completeEmailVerification(token);
      } catch (error) {
        console.error(error);
        setIsInvalid(true);
      }

      setloading(false);
    };

    completeVerification();
  }, [token]);

  return (
    <>
      {loading ? (
        <FullPageLoading loading={true} showTitle={true} />
      ) : isInvalid ? (
        <FailVerification />
      ) : (
        <SuccessfulVerification />
      )}
    </>
  );
};

const SuccessfulVerification = () => {
  return (
    <Container>
      <FontAwesomeIcon
        style={defaultIconStyle}
        icon={["fad", "user-check"]}
        size={"6x"}
      />
      <div className="title">{strings.account.emailVerification.success}</div>
      <div className="body">{strings.account.emailVerification.successMsg}</div>
      <Link style={{ marginTop: "40px" }} to="/">
        <Button style={{ fontSize: "14px" }}>
          <div>{strings.account.emailVerification.successBtn}</div>
        </Button>
      </Link>
    </Container>
  );
};

const FailVerification = () => {
  return (
    <Container>
      <FontAwesomeIcon
        style={errorIconStyle}
        icon={["fad", "user-times"]}
        size={"6x"}
      />
      <div className="title">{strings.account.emailVerification.fail}</div>
      <div className="body">{strings.account.emailVerification.failMsg}</div>
    </Container>
  );
};

const baseIconStyle: object = {
  "--fa-primary-color": "#7070708c",
  "padding-left": "25px",
  "margin-top": "20px"
};

const defaultIconStyle: object = {
  ...baseIconStyle,
  "--fa-secondary-color": ConcreteColors.green200
};

const errorIconStyle: object = {
  ...baseIconStyle,
  "--fa-secondary-color": ConcreteColors.red200
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;

  .title {
    font-size: 35px;
    color: #5a5c69;
  }

  .body {
    font-size: 20px;
    margin-top: 6px;
    max-width: 650px;
    text-align: center;
  }
`;
