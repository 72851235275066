import moment from "moment";

export function parseDate(stringValue: string): Date | null {
  const dateValue = moment(stringValue, "L LT");
  if (dateValue.isValid()) {
    return dateValue.toDate();
  }
  return null;
}

export function formatDate(date: Date, includeTime = true): string {
  if (includeTime) {
    return moment(date).format("L LT");
  }

  return moment(date).format("L");
}
