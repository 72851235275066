import moment from "moment";
import { FilterOperation, SchemaFieldType, SchemaViewFilter } from "../../api";
import { filterConstants } from "./components/filters/common";
import { calculateValuesFromRange } from "./components/filters/dateTimeUtils";
import { checklistReducer } from "./components/fields/CheckList";
import { intersection } from "lodash-es";
import { getFieldValueAsArray } from "core/util/multi-select-helpers";
import { preconIdToString } from "modules/projects";

export function filterField(
  type: SchemaFieldType,
  filter: SchemaViewFilter,
  value: any
): boolean {
  if (filter.value === undefined) {
    return true;
  }

  switch (type) {
    case SchemaFieldType.ShortText:
    case SchemaFieldType.LongText:
      return filterText(filter, value);
    case SchemaFieldType.Links:
      return filterHyperlinks(filter, value);
    case SchemaFieldType.Checklist:
      return filterChecklist(filter, value);
    case SchemaFieldType.List:
    case SchemaFieldType.States:
      return filterList(filter, value);
    case SchemaFieldType.Company:
      return filterCompanyList(filter, value);
    case SchemaFieldType.Contact:
      return filterContactList(filter, value);
    case SchemaFieldType.MultiSelectList:
      return filterMultiSelectList(filter, value);
    case SchemaFieldType.Number:
    case SchemaFieldType.Currency:
    case SchemaFieldType.Calculated:
      return filterNumeric(filter, value);
    case SchemaFieldType.Boolean:
      return filterBoolean(filter, value);
    case SchemaFieldType.Date:
    case SchemaFieldType.DateTime:
      return filterDateTime(filter, value);
    case SchemaFieldType.PreConId:
      return filterProjectId(filter, preconIdToString(value));
    default:
      return true;
  }
}

export const linkToString = (
  value?: Array<{ description?: string; url?: string }>
) => {
  return (
    value?.map(v => `${v.description ?? ""} ${v.url ?? ""}`).join(" ") ?? ""
  );
};

export const checkListToString = (value: any) => {
  let retString = "";
  if (value) {
    value.forEach((list: any, index: number) => {
      retString += index > 0 ? ", " : "";
      retString += list.checked ? "Checked" : "NotChecked";
      retString += list.description;
    });
  }
  return retString;
};

export interface TypedSchemaViewFilter extends SchemaViewFilter {
  fieldType: SchemaFieldType;
}

function isDateOrTimeFilterActive(filter: TypedSchemaViewFilter) {
  const validFromValue =
    filter.value.from !== undefined && filter.value.from !== null;
  const validToValue =
    filter.value.to !== undefined && filter.value.to !== null;
  switch (filter.filterOperation) {
    case FilterOperation.Between:
      return filter.value && (validFromValue || validToValue);
    case FilterOperation.LessThan:
    case FilterOperation.LessThanOrEqualTo:
      return filter.value && validToValue;
    default:
      return filter.value && validFromValue;
  }
}

export function isFilterActive(filter: TypedSchemaViewFilter) {
  if (filter.value === undefined || filter.value === null) {
    return false;
  }
  if (
    filter.fieldType === SchemaFieldType.Date ||
    filter.fieldType === SchemaFieldType.DateTime
  ) {
    if (
      filter.value &&
      filter.value.range &&
      filter.value.range !== filterConstants.dates.custom
    ) {
      return true;
    }
    if (isDateOrTimeFilterActive(filter)) {
      return true;
    }
  } else if (
    filter.fieldType === SchemaFieldType.Number ||
    filter.fieldType === SchemaFieldType.Currency ||
    filter.fieldType === SchemaFieldType.Calculated
  ) {
    if (isDateOrTimeFilterActive(filter)) {
      return true;
    }
  } else {
    return true;
  }

  return false;
}

export function calculateActiveFilters(filters: TypedSchemaViewFilter[]) {
  return filters.reduce(
    (sum, filter) => (isFilterActive(filter) ? sum + 1 : sum),
    0
  );
}

export function filterNumeric(filter: SchemaViewFilter, value: any): boolean {
  const fromValue =
    filter.value && validateNumber(filter.value.from)
      ? filter.value.from
      : undefined;
  const toValue =
    filter.value && validateNumber(filter.value.to)
      ? filter.value.to
      : undefined;

  const validFromValue = validateNumber(fromValue);
  const validToValue = validateNumber(toValue);
  const validValue = validateNumber(value);

  if (!validFromValue && !validToValue) {
    return true;
  }

  if (validFromValue || validToValue) {
    if (!validValue) {
      return false;
    }
  }

  switch (filter.filterOperation) {
    case FilterOperation.Equals: {
      if (validFromValue && value !== fromValue) {
        return false;
      }
      break;
    }
    case FilterOperation.NotEquals: {
      if (validFromValue && value === fromValue) {
        return false;
      }
      break;
    }
    case FilterOperation.GreaterThan: {
      if (validFromValue && value <= fromValue) {
        return false;
      }
      break;
    }
    case FilterOperation.GreaterThanOrEqualTo: {
      if (validFromValue && value < fromValue) {
        return false;
      }
      break;
    }
    case FilterOperation.LessThan: {
      if (validToValue && value >= toValue) {
        return false;
      }
      break;
    }
    case FilterOperation.LessThanOrEqualTo: {
      if (validToValue && value > toValue) {
        return false;
      }
      break;
    }
    case FilterOperation.Between: {
      if (
        (validFromValue && value < fromValue) ||
        (validToValue && value > toValue)
      ) {
        return false;
      }
      break;
    }
  }

  return true;
}

function validateNumber(value?: number) {
  if (value === undefined || value === null || isNaN(value)) {
    return false;
  }
  return true;
}

export function filterList(filter: SchemaViewFilter, value: any) {
  if (filter.value && filter.value.length) {
    const validText = validateText(value);
    if (!validText && filter.filterOperation === FilterOperation.Any) {
      return false;
    }
    if (!validText && filter.filterOperation === FilterOperation.None) {
      return true;
    }

    const values = filter.value.map((option: any) => option.value);
    const index = values.indexOf(value);
    if (filter.filterOperation === FilterOperation.Any && index === -1) {
      return false;
    }
    if (filter.filterOperation === FilterOperation.None && index !== -1) {
      return false;
    }
  }
  return true;
}

export function filterCompanyList(filter: SchemaViewFilter, value: any) {
  if (filter.value && filter.value.length) {
    const filterValues = filter.value.map((option: any) => option.value);
    const valueArray = Array.isArray(value)
      ? value.map(v => v.code)
      : [value?.code ?? null];
    const intersectionOfValues = intersection(
      filterValues,
      getFieldValueAsArray(valueArray)
    );
    const hasCommonValues = intersectionOfValues.length > 0;
    const filterContainsAllValues =
      filterValues.length === intersectionOfValues.length;

    if (filter.filterOperation === FilterOperation.Any && !hasCommonValues) {
      return false;
    }
    if (filter.filterOperation === FilterOperation.None && hasCommonValues) {
      return false;
    }
    if (
      filter.filterOperation === FilterOperation.Equals &&
      !filterContainsAllValues
    ) {
      return false;
    }
    if (
      filter.filterOperation === FilterOperation.NotEquals &&
      filterContainsAllValues
    ) {
      return false;
    }
  }
  return true;
}

export function filterContactList(filter: SchemaViewFilter, value: any) {
  if (filter.value && filter.value.length) {
    const filterValues = filter.value.map((option: any) => option.value);

    const valueArray = Array.isArray(value)
      ? value.map(v => v.id)
      : [value?.id ?? null];
    const intersectionOfValues = intersection(
      filterValues,
      getFieldValueAsArray(valueArray)
    );
    const hasCommonValues = intersectionOfValues.length > 0;
    const filterContainsAllValues =
      filterValues.length === intersectionOfValues.length;

    if (filter.filterOperation === FilterOperation.Any && !hasCommonValues) {
      return false;
    }
    if (filter.filterOperation === FilterOperation.None && hasCommonValues) {
      return false;
    }
    if (
      filter.filterOperation === FilterOperation.Equals &&
      !filterContainsAllValues
    ) {
      return false;
    }
    if (
      filter.filterOperation === FilterOperation.NotEquals &&
      filterContainsAllValues
    ) {
      return false;
    }
  }
  return true;
}

export function filterMultiSelectList(
  filter: SchemaViewFilter,
  projectValue: string[]
) {
  if (filter.value && filter.value.length) {
    const filterValues = filter.value.map((option: any) => option.value);
    const intersectionOfValues = intersection(
      filterValues,
      getFieldValueAsArray(projectValue)
    );
    const hasCommonValues = intersectionOfValues.length > 0;
    const filterContainsAllValues =
      filterValues.length === intersectionOfValues.length;

    if (filter.filterOperation === FilterOperation.Any && !hasCommonValues) {
      return false;
    }
    if (filter.filterOperation === FilterOperation.None && hasCommonValues) {
      return false;
    }
    if (
      filter.filterOperation === FilterOperation.Equals &&
      !filterContainsAllValues
    ) {
      return false;
    }
    if (
      filter.filterOperation === FilterOperation.NotEquals &&
      filterContainsAllValues
    ) {
      return false;
    }
  }
  return true;
}

function validateText(value: any) {
  if (!value || value.trim() === "") {
    return false;
  }
  return true;
}

export function filterText(filter: SchemaViewFilter, value: any) {
  if (filter.value && filter.value.length && Array.isArray(filter.value)) {
    const validText = validateText(value);
    if (!validText) {
      return filter.filterOperation === FilterOperation.NotEquals;
    }

    value = value.toLowerCase();

    const values: string[] = filter.value.map((option: any) =>
      option.value.toLowerCase()
    );

    if (filter.filterOperation === FilterOperation.Contains) {
      return values.some(valueToCheck => value.indexOf(valueToCheck) > -1);
    }

    if (filter.filterOperation === FilterOperation.StartsWith) {
      return values.some(valueToCheck => value.startsWith(valueToCheck));
    }

    if (filter.filterOperation === FilterOperation.EndsWith) {
      return values.some(valueToCheck => value.endsWith(valueToCheck));
    }

    if (filter.filterOperation === FilterOperation.Equals) {
      return values.some(valueToCheck => value === valueToCheck);
    }

    if (filter.filterOperation === FilterOperation.NotEquals) {
      return values.every(valueToCheck => value !== valueToCheck);
    }
  }
  return true;
}

export function filterProjectId(filter: SchemaViewFilter, value: any) {
  if (filter.value && filter.value.length && Array.isArray(filter.value)) {
    const validText = validateText(value);
    if (!validText) {
      return filter.filterOperation === FilterOperation.NotEquals;
    }

    value = value.toLowerCase();

    const values: string[] = filter.value.map((option: any) =>
      preconIdToString(option.value).toLowerCase()
    );

    if (filter.filterOperation === FilterOperation.Contains) {
      return values.some(valueToCheck => value.indexOf(valueToCheck) > -1);
    }

    if (filter.filterOperation === FilterOperation.StartsWith) {
      return values.some(valueToCheck => value.startsWith(valueToCheck));
    }

    if (filter.filterOperation === FilterOperation.EndsWith) {
      return values.some(valueToCheck => value.endsWith(valueToCheck));
    }

    if (filter.filterOperation === FilterOperation.Equals) {
      return values.some(valueToCheck => value === valueToCheck);
    }

    if (filter.filterOperation === FilterOperation.NotEquals) {
      return values.every(valueToCheck => value !== valueToCheck);
    }
  }
  return true;
}

export function filterBoolean(filter: SchemaViewFilter, value: any) {
  if (filter.value !== undefined && filter.value !== null) {
    const boolValue = normalizeBoolean(value);

    if (
      boolValue !== filter.value &&
      filter.filterOperation === FilterOperation.Equals
    ) {
      return false;
    }

    if (
      boolValue === filter.value &&
      filter.filterOperation === FilterOperation.NotEquals
    ) {
      return false;
    }
  }
  return true;
}

export function filterHyperlinks(
  filter: SchemaViewFilter,
  value?: Array<{ description?: string; url?: string }>
) {
  let descFound = false;
  let urlFound = false;
  if (!value) {
    if (filter.filterOperation === FilterOperation.NotEquals) return true;
    return false;
  }
  for (let index = 0; index < value.length; index++) {
    const element = value[index];
    const description = element.description?.toLowerCase();
    const url = element.url?.toLowerCase();
    descFound = filterText(filter, description);
    urlFound = filterText(filter, url);
    if (filter.filterOperation === FilterOperation.NotEquals) {
      if ((descFound && !urlFound) || (urlFound && !descFound)) return false;
      if (descFound && urlFound) return true;
    }
    if (descFound || urlFound) {
      return true;
    }
  }
  return filter.filterOperation === FilterOperation.NotEquals ? true : false;
}

export function filterChecklist(filter: SchemaViewFilter, value: any) {
  if (filter.value !== undefined) {
    const items = checklistReducer(value);
    const percentComplete =
      items.checked + items.notChecked > 0
        ? items.checked / (items.checked + items.notChecked)
        : 0;
    if (filter.value && percentComplete < 1) {
      return false;
    }

    if (!filter.value && percentComplete === 1) {
      return false;
    }
  }
  return true;
}

function normalizeBoolean(value: any) {
  if (value === true) {
    return true;
  }

  return false;
}

export function calculateDateFilterValues(filter: SchemaViewFilter) {
  const dateFilterValues = {
    fromValue:
      filter.value && filter.value.from ? moment(filter.value.from) : undefined,
    toValue:
      filter.value && filter.value.to ? moment(filter.value.to) : undefined
  };

  if (
    filter.value &&
    filter.value.range &&
    filter.value.range !== filterConstants.dates.custom
  ) {
    const rangeValues = calculateValuesFromRange(filter.value.range);
    dateFilterValues.fromValue = rangeValues!.from;
    dateFilterValues.toValue = rangeValues!.to;
  }

  return dateFilterValues;
}

export function filterDateTime(filter: SchemaViewFilter, value: any) {
  if (filter.value !== undefined) {
    const { fromValue, toValue } = calculateDateFilterValues(filter);

    if (fromValue || toValue) {
      if (!value) {
        return false;
      }
    }

    const currentValue = moment(value);

    switch (filter.filterOperation) {
      case FilterOperation.Equals: {
        if (fromValue) {
          return currentValue.isSame(fromValue, "day");
        }
        break;
      }
      case FilterOperation.NotEquals: {
        if (fromValue) {
          return !currentValue.isSame(fromValue, "day");
        }
        break;
      }
      case FilterOperation.GreaterThan: {
        if (fromValue) {
          return currentValue.isAfter(fromValue, "day");
        }
        break;
      }
      case FilterOperation.GreaterThanOrEqualTo: {
        if (fromValue) {
          return currentValue.isSameOrAfter(fromValue, "day");
        }
        break;
      }
      case FilterOperation.LessThan: {
        if (toValue) {
          return currentValue.isBefore(toValue, "day");
        }
        break;
      }
      case FilterOperation.LessThanOrEqualTo: {
        if (toValue) {
          return currentValue.isSameOrBefore(toValue, "day");
        }
        break;
      }
      case FilterOperation.Between: {
        if (fromValue && toValue) {
          return currentValue.isBetween(fromValue, toValue, "day", "[]");
        }
        if (fromValue) {
          return currentValue.isSameOrAfter(fromValue, "day");
        }
        if (toValue) {
          return currentValue.isSameOrBefore(toValue, "day");
        }
        break;
      }
    }
  }
  return true;
}
