import { Avatar, List, ListItem, Box } from "@mui/material";
import React, { ReactNode } from "react";
import { EmailField, PhoneField, NoDataPlaceholder, TextOverflow } from "core";
import { Flex } from "hcss-core";
import { LocalPhone, PhoneAndroid, Email } from "@mui/icons-material";
import { blue, green } from "@mui/material/colors";
import { CompanyCard } from "./company-card";
import { IGetContactSearchDto } from "api/GeneratedClients/ContactsClient";
import { strings } from "localization";
export interface ContactListProps {
  contacts: IGetContactSearchDto[];
}

export const ContactList = ({ contacts }: ContactListProps) => {
  return (
    <List
      sx={{
        width: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center"
      }}
    >
      <>
        {contacts.length === 0 && (
          <NoDataPlaceholder
            isAddEditDisplay={true}
            buttonText={""}
            showButton={false}
            helpText={{
              title: strings.contactManagement.contactSearchModal.noData.title,
              body: strings.contactManagement.contactSearchModal.noData.body
            }}
          />
        )}
        {contacts.length > 0 &&
          contacts.map(contact => (
            <ListItem disablePadding key={contact.id}>
              <ContactCard contact={contact} />
            </ListItem>
          ))}
      </>
    </List>
  );
};

export interface ContactListItemProps {
  contact: IGetContactSearchDto;
}

const ContactCard = ({ contact }: ContactListItemProps) => {
  return (
    <Flex width="100%">
      <Flex
        flexDirection="row"
        borderRadius="4"
        padding="4"
        width="100%"
        justifyContent="space-between"
        borderBottom="1px solid lightgray"
      >
        <Flex flexDirection="column" justifyContent="space-between" flex={1}>
          <Flex marginBottom={1} fontSize="1.7rem" fontWeight={600}>
            <TextOverflow style={{ whiteSpace: "nowrap" }}>
              {contact.firstName || "--"} {contact.lastName || "--"}
            </TextOverflow>
          </Flex>

          <CompanyCard
            companyId={contact.companyId ?? ""}
            companyName={contact.companyName ?? ""}
            companyCode={contact.companyCode ?? ""}
          />
        </Flex>
        <Flex flexDirection="column" justifyContent="space-evenly" flex={1}>
          <ContactInfoField
            fieldInfo={
              contact.phoneNumber ? (
                <PhoneField phoneNumber={contact.phoneNumber} />
              ) : (
                "--"
              )
            }
            icon={<LocalPhone sx={{ width: 12, height: 12 }} />}
            iconColor={green[400]}
          />
          <ContactInfoField
            fieldInfo={
              contact.cellPhoneNumber ? (
                <PhoneField phoneNumber={contact.cellPhoneNumber} />
              ) : (
                "--"
              )
            }
            icon={<PhoneAndroid sx={{ width: 12, height: 12 }} />}
            iconColor={green[600]}
          />
          <ContactInfoField
            fieldInfo={
              contact.emailAddress && contact.emailAddress !== "@" ? (
                <EmailField emailAddress={contact.emailAddress} />
              ) : (
                "--"
              )
            }
            icon={<Email sx={{ width: 12, height: 12 }} />}
            iconColor={blue[400]}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

interface ContactInfoFieldProps {
  fieldInfo: ReactNode;
  icon: ReactNode;
  iconColor: string;
}

const ContactInfoField = ({
  fieldInfo,
  icon,
  iconColor
}: ContactInfoFieldProps) => {
  return (
    <Flex
      justifyContent="flex-end"
      alignItems="center"
      fontSize="1.2rem"
      opacity={0.8}
    >
      <TextOverflow textAlign="right" style={{ whiteSpace: "nowrap" }}>
        {fieldInfo}
      </TextOverflow>
      <Avatar
        sx={{
          width: 20,
          height: 20,
          marginRight: "2px",
          marginBottom: "4px",
          marginTop: "4px",
          marginLeft: "2px",
          bgcolor: iconColor
        }}
      >
        {icon}
      </Avatar>
    </Flex>
  );
};
