import produce from "immer";
import { map, values, sortBy, keyBy } from "lodash-es";
import { createSelector } from "reselect";
import {
  ActionType,
  createAction,
  createAsyncAction,
  getType
} from "typesafe-actions";
import {
  ColumnSummary,
  SchemaViewFilter,
  SchemaViewGrouping,
  SchemaViewSorting,
  View
} from "api";
import * as account from "modules/account";
import * as schemas from "modules/schemas";
import { editArchivedEstimateList } from "./standard-views/edit-estimate-list";
import { keyIndicators } from "./standard-views/key-indicators";
import { quoteDashboard } from "./standard-views/quote-dashboard";
import * as config from "modules/configurationSettings";
import { getAllSchemas } from "../schemas/selectors";
import uuid from "uuid";

export const STATE_KEY = "views";

// Models
export interface State {
  allIds: string[];
  workingCopy: { [key: string]: View };
  original: { [key: string]: View };
  loading: boolean;
  loaded: boolean;
  errors: string[];
  selectedViews: { [key: string]: string };
}

export interface StateSlice {
  [STATE_KEY]: State;
}

// Actions
export const actions = {
  loadViews: createAsyncAction(
    "VIEWS/LOAD_REQUEST",
    "VIEWS/LOAD_SUCCESS",
    "VIEWS/LOAD_FAILURE"
  )<void, View[], Error>(),

  saveView: createAsyncAction(
    "VIEWS/SAVE_REQUEST",
    "VIEWS/SAVE_SUCCESS",
    "VIEWS/SAVE_FAILURE"
  )<
    {
      view: View;
      meta?: {
        type?: string;
        message?: string;
        setAsSelected?: boolean;
        isNewView?: boolean;
        tableId?: string;
        userId?: string;
      };
    },
    View,
    View
  >(),

  deleteView: createAsyncAction(
    "VIEWS/DELETE_REQUEST",
    "VIEWS/DELETE_SUCCESS",
    "VIEWS/DELETE_FAILURE"
  )<{ viewId: string; meta?: { tableId?: string } }, string, string>(),

  claimView: createAsyncAction(
    "VIEWS/CLAIM_REQUEST",
    "VIEWS/CLAIM_SUCCESS",
    "VIEWS/CLAIM_FAILURE"
  )<{ viewId: string; userId: string }, View, string>(),

  updateViews: createAction("VIEWS/UPDATE", resolve => {
    return (views: View[]) => resolve({ views });
  }),

  resetView: createAction("VIEWS/RESET", resolve => {
    return (viewId: string) => resolve({ viewId });
  }),

  updateViewAccess: createAction("VIEWS/UPDATE_ACCESS", resolve => {
    return (viewId: string, isPublic: boolean) => resolve({ viewId, isPublic });
  }),

  updateViewOrdering: createAction("VIEWS/UPDATE_ORDERING", resolve => {
    return (viewId: string, ordering: string[]) =>
      resolve({ viewId, ordering });
  }),

  updateViewGrouping: createAction("VIEWS/UPDATE_GROUPING", resolve => {
    return (viewId: string, grouping: SchemaViewGrouping[]) =>
      resolve({ viewId, grouping });
  }),

  updateViewSorting: createAction("VIEWS/UPDATE_SORTING", resolve => {
    return (viewId: string, sorting: SchemaViewSorting[]) =>
      resolve({ viewId, sorting });
  }),

  updateViewSummary: createAction("VIEWS/UPDATE_SUMMARY", resolve => {
    return (viewId: string, columnSummaries: ColumnSummary[]) =>
      resolve({ viewId, columnSummaries });
  }),

  updateViewFilters: createAction("VIEWS/UPDATE_FILTERS", resolve => {
    return (viewId: string, filters: View["filters"]) =>
      resolve({ viewId, filters });
  }),

  updateViewFilter: createAction("VIEWS/UPDATE_FILTER", resolve => {
    return (viewId: string, filter: SchemaViewFilter) =>
      resolve({ viewId, filter });
  }),

  updateViewDisplayedColumns: createAction(
    "VIEWS/UPDATE_DISPLAYED_COLUMNS",
    resolve => {
      return (viewId: string, displayedColumns: string[]) =>
        resolve({ viewId, displayedColumns });
    }
  ),

  updateViewColoring: createAction("VIEWS/UPDATE_COLORING", resolve => {
    return (viewId: string, coloringColumnName: string) =>
      resolve({ viewId, coloringColumnName });
  }),

  updateViewType: createAction("VIEWS/UPDATE_TYPE", resolve => {
    return (
      viewId: string,
      type: {
        calendar: boolean;
        list: boolean;
        charts: boolean;
        map: boolean;
      }
    ) => resolve({ viewId, type });
  }),

  createChartConfig: createAction("VIEWS/COPY_CHART_CONFIG", resolve => {
    return (viewId: string, chartConfigToCopy: Record<string, unknown>) =>
      resolve({ viewId, chartConfigToCopy });
  }),

  deleteChartConfig: createAction("VIEWS/DELETE_CHART_CONFIG", resolve => {
    return (viewId: string, chartConfigIdToDelete: Record<string, unknown>) =>
      resolve({ viewId, chartConfigIdToDelete });
  }),

  updateChartConfig: createAction("VIEWS/UPDATE_CHART_CONFIG", resolve => {
    return (
      viewId: string,
      chartId: string,
      key: string,
      value: string | string[]
    ) => resolve({ viewId, chartId, key, value });
  }),

  updateLayout: createAction("VIEWS/UPDATE_LAYOUT", resolve => {
    return (viewId: string, layout: { [key: string]: unknown }) =>
      resolve({ viewId, layout });
  }),
  updateCalendarConfig: createAction(
    "VIEWS/UPDATE_CALENDAR_CONFIG",
    resolve => {
      return (viewId: string, calendarConfig: { [key: string]: unknown }) =>
        resolve({ viewId, calendarConfig });
    }
  ),

  setSelectedView: createAction("VIEWS/SET_SELECTED_VIEW", resolve => {
    return (tableId: string, viewId: string) => resolve({ tableId, viewId });
  }),

  updateMapStyle: createAction("VIEWS/UPDATE_MAP_STYLE", resolve => {
    return (viewId: string) => resolve({ viewId });
  }),

  setDefaultView: createAction("VIEWS/SET_DEFAULT_VIEW", resolve => {
    return (view: View, tableId: string) => resolve({ view, tableId });
  }),

  hideView: createAction("VIEWS/HIDE_VIEW", resolve => {
    return (viewId: string) => resolve({ viewId });
  })
};

export type ViewActions = ActionType<typeof actions>;

const inMemoryViews = normalizeViews([
  editArchivedEstimateList,
  keyIndicators,
  quoteDashboard
]);
const inMemoryViewsLookup = keyBy(inMemoryViews, v => v.id);
const initialState: State = {
  allIds: inMemoryViews.map(v => v.id),
  workingCopy: inMemoryViewsLookup,
  original: inMemoryViewsLookup,
  loading: false,
  loaded: false,
  selectedViews: {},
  errors: []
};

// Reducer
export const reducer = (state = initialState, action: ViewActions) => {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.loadViews.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.loadViews.success): {
        const views = normalizeViews(action.payload);
        views.forEach(view => {
          if (!(view.id in draft.workingCopy)) {
            draft.allIds.push(view.id);
          }
          //TODO: THIS BLOCK CAN BE REMOVED AS PART OF FUTURE TECH DEBT
          // It is necessary for HBW-3088, and is written to avoid any migrations
          // and prevent downtime between the preprod->prod swap
          if (
            Object.keys(view.chartConfig).length > 0 &&
            view.chartConfigs.length === 0
          ) {
            const existingChartConfig = { ...view.chartConfig };
            view.chartConfigs = [{ ...existingChartConfig, id: "chart" }];
          }
          draft.workingCopy[view.id] = view;
          draft.original[view.id] = view;
        });
        draft.loading = false;
        draft.loaded = true;
        break;
      }
      case getType(actions.loadViews.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(`Error loading views: ${action.payload.message}`);
        break;
      }

      case getType(actions.saveView.request): {
        const view = action.payload.view;
        draft.workingCopy[view.id] = view;
        if (!draft.allIds.includes(view.id)) {
          draft.allIds.push(view.id);
        }
        break;
      }

      case getType(actions.saveView.success): {
        draft.original[action.payload.id] = action.payload;
        draft.workingCopy[action.payload.id] = action.payload;
        draft.allIds = Object.keys(draft.original).map(
          key => draft.original[key].id
        );
        break;
      }

      case getType(actions.saveView.failure): {
        const view = action.payload;

        if (draft.original[view.id]) {
          draft.workingCopy[view.id] = draft.original[view.id];
        } else {
          delete draft.workingCopy[view.id];
          draft.allIds = Object.values(draft.workingCopy).map(v => v.id);
        }
        break;
      }

      case getType(actions.deleteView.request): {
        delete draft.workingCopy[action.payload.viewId];
        const index = draft.allIds.indexOf(action.payload.viewId);
        if (index > -1) {
          draft.allIds.splice(index, 1);
        }
        break;
      }

      case getType(actions.deleteView.success): {
        delete draft.original[action.payload];
        break;
      }

      case getType(actions.deleteView.failure): {
        draft.workingCopy[action.payload] = draft.original[action.payload];
        draft.allIds.push(action.payload);
        break;
      }

      case getType(actions.claimView.request): {
        draft.workingCopy[action.payload.viewId].createdByUserId =
          action.payload.userId;
        break;
      }

      case getType(actions.claimView.success): {
        const viewId = action.payload.id;
        draft.original[viewId] = action.payload;
        draft.workingCopy[viewId] = action.payload;
        draft.original[viewId].layout = normalizeViewLayout(
          draft.original[viewId].layout,
          getViewSections(draft.original[viewId])
        );
        draft.workingCopy[viewId].layout = normalizeViewLayout(
          draft.workingCopy[viewId].layout,
          getViewSections(draft.workingCopy[viewId])
        );
        break;
      }

      case getType(actions.claimView.failure): {
        draft.workingCopy[action.payload].createdByUserId =
          draft.original[action.payload].createdByUserId;
        break;
      }

      case getType(actions.updateViews): {
        const { views } = action.payload;
        views.forEach(view => {
          const currentIndex = draft.allIds.indexOf(view.id);
          if (view.deleted) {
            if (currentIndex > -1) {
              draft.allIds.splice(currentIndex, 1);
            }
            if (view.id in draft.workingCopy) {
              delete draft.workingCopy[view.id];
            }
            if (view.id in draft.original) {
              delete draft.original[view.id];
            }
            draft.allIds = map(draft.workingCopy, v => v.id);
          } else {
            view.layout = normalizeViewLayout(
              view.layout,
              getViewSections(view)
            );
            draft.workingCopy[view.id] = view;
            draft.original[view.id] = view;
            if (currentIndex === -1) {
              draft.allIds.push(view.id);
            }
          }
        });
        break;
      }

      case getType(actions.resetView): {
        draft.workingCopy[action.payload.viewId] =
          state.original[action.payload.viewId];
        break;
      }

      case getType(actions.updateViewAccess): {
        const { viewId, isPublic } = action.payload;
        draft.workingCopy[viewId].isPublic = isPublic;
        break;
      }

      case getType(actions.updateViewOrdering): {
        const { viewId, ordering } = action.payload;
        draft.workingCopy[viewId].ordering = ordering;
        break;
      }

      case getType(actions.updateViewGrouping): {
        const { viewId, grouping } = action.payload;
        draft.workingCopy[viewId].grouping = grouping;
        break;
      }

      case getType(actions.updateViewSorting): {
        const { viewId, sorting } = action.payload;
        draft.workingCopy[viewId].sorting = sorting;
        break;
      }

      case getType(actions.updateViewSummary): {
        const { viewId, columnSummaries } = action.payload;
        draft.workingCopy[viewId].columnSummaries = columnSummaries;
        break;
      }

      case getType(actions.updateViewFilters): {
        const { viewId, filters } = action.payload;
        draft.workingCopy[viewId].filters = filters;
        break;
      }

      case getType(actions.updateViewFilter): {
        const { viewId, filter } = action.payload;
        draft.workingCopy[viewId].filters[filter.columnName] = filter;
        break;
      }

      case getType(actions.updateViewDisplayedColumns): {
        const { viewId, displayedColumns } = action.payload;
        draft.workingCopy[viewId].displayedColumns = displayedColumns;
        break;
      }

      case getType(actions.updateViewColoring): {
        const { viewId, coloringColumnName } = action.payload;
        draft.workingCopy[viewId].coloringColumnName = coloringColumnName;
        break;
      }

      case getType(actions.updateViewType): {
        const { viewId, type } = action.payload;
        draft.workingCopy[viewId].listActive = type.list;
        draft.workingCopy[viewId].calendarActive = type.calendar;
        draft.workingCopy[viewId].chartsActive = type.charts;
        if (
          type.charts &&
          draft.workingCopy[viewId].chartConfigs.length === 0
        ) {
          const chartId = uuid();
          const newChartConfig = {
            id: chartId,
            barOptions: "totProjects",
            chartType: "bar"
          };
          draft.workingCopy[viewId].chartConfigs.push(newChartConfig);
        }
        draft.workingCopy[viewId].mapActive = type.map;

        draft.workingCopy[viewId].layout = normalizeViewLayout(
          draft.workingCopy[viewId].layout,
          getViewSections(draft.workingCopy[viewId])
        );

        break;
      }

      case getType(actions.updateChartConfig): {
        const { viewId, chartId, key, value } = action.payload;
        const chartConfig = draft.workingCopy[viewId].chartConfigs.find(
          config => config["id"] === chartId
        );
        if (chartConfig) chartConfig[key] = value;
        break;
      }

      case getType(actions.createChartConfig): {
        const { viewId, chartConfigToCopy } = action.payload;
        const chartId = uuid();
        const newChartConfig = {
          ...chartConfigToCopy,
          id: chartId
        };
        draft.workingCopy[viewId].chartConfigs.push(newChartConfig);
        draft.workingCopy[viewId].layout = normalizeViewLayout(
          draft.workingCopy[viewId].layout,
          getViewSections(draft.workingCopy[viewId])
        );
        break;
      }

      case getType(actions.deleteChartConfig): {
        const { viewId, chartConfigIdToDelete } = action.payload;
        const indexToDelete = draft.workingCopy[viewId].chartConfigs.findIndex(
          chartConfig => chartConfig.id === chartConfigIdToDelete
        );
        if (indexToDelete > -1) {
          const chartConfigCopy = [...draft.workingCopy[viewId].chartConfigs];
          chartConfigCopy.splice(indexToDelete, 1);
          draft.workingCopy[viewId].chartConfigs = chartConfigCopy;
          if (draft.workingCopy[viewId].chartConfigs.length === 0) {
            draft.workingCopy[viewId].chartsActive = false;
            draft.workingCopy[viewId].listActive = true;
          }
          draft.workingCopy[viewId].layout = normalizeViewLayout(
            draft.workingCopy[viewId].layout,
            getViewSections(draft.workingCopy[viewId]),
            true
          );
        }
        break;
      }

      case getType(actions.updateLayout): {
        const { viewId, layout } = action.payload;
        draft.workingCopy[viewId].layout = normalizeViewLayout(
          layout,
          getViewSections(draft.workingCopy[viewId])
        );
        break;
      }
      case getType(actions.updateCalendarConfig): {
        const { viewId, calendarConfig } = action.payload;
        draft.workingCopy[viewId].calendarConfig = calendarConfig;
        break;
      }
      case getType(actions.setSelectedView): {
        const { viewId, tableId } = action.payload;
        draft.selectedViews[tableId] = viewId;
        break;
      }

      case getType(actions.updateMapStyle): {
        const { viewId } = action.payload;
        draft.workingCopy[viewId].satelliteMapStyleActive = !draft.workingCopy[
          viewId
        ].satelliteMapStyleActive;
        break;
      }

      case getType(actions.hideView): {
        const { viewId } = action.payload;
        delete draft.workingCopy[viewId];
        delete draft.original[viewId];
        const index = draft.allIds.indexOf(action.payload.viewId);
        if (index > -1) {
          draft.allIds.splice(index, 1);
        }
      }
    }
  });
};

export type SelectorState = StateSlice &
  schemas.StateSlice &
  config.StateSlice &
  account.StateSlice;

// Selectors
const getAllIds = ({ views }: SelectorState) => views.allIds;
const getLoaded = ({ views }: SelectorState) => views.loaded;
const getWorkingCopy = ({ views }: SelectorState) => views.workingCopy;
const getOriginal = ({ views }: SelectorState) => views.original;
const getSelectedViews = ({ views }: SelectorState) => views.selectedViews;
const getErrors = ({ views }: SelectorState) => views.errors;

const getAllViews = createSelector(
  [getAllIds, getWorkingCopy],
  (ids, views) => {
    return ids.map(id => views[id]);
  }
);

const getProjectViewsLookup = createSelector(
  [getAllViews, getAllSchemas],
  (views, schemas) => {
    const projectViews: Record<string, View> = {};
    views.forEach(view => {
      if (!view || !schemas) return;
      const schema = schemas.find(s => s.id === view.schemaId);
      if (schema && schema.schemaName === "projects") {
        projectViews[view.id] = view;
      }
    });
    return projectViews;
  }
);
const getEstimateViewsLookup = createSelector(
  [getAllViews, getAllSchemas],
  (views, schemas) => {
    const projectViews: Record<string, View> = {};
    views.forEach(view => {
      if (!view || !schemas) return;
      const schema = schemas.find(s => s.id === view.schemaId);
      if (schema && schema.schemaName === "estimatesExtended") {
        projectViews[view.id] = view;
      }
    });
    return projectViews;
  }
);

const getProjectViews = createSelector([getProjectViewsLookup], viewLookup => {
  return viewLookup ? values(viewLookup) : [];
});

const getAllEstimateViews = createSelector(
  [getEstimateViewsLookup],
  viewLookup => {
    return viewLookup ? values(viewLookup) : [];
  }
);

const getEstimateViews = createSelector([getAllEstimateViews], views => {
  return views.filter(
    view =>
      view.id !== editArchivedEstimateList.id && view.id !== keyIndicators.id
  );
});

const getEstimateViewsSorted = createSelector([getEstimateViews], views => {
  return sortBy(views, ["isPublic", data => data.name.toLowerCase()]);
});

const getProjectViewsSorted = createSelector([getProjectViews], views => {
  return sortBy(views, ["isPublic", data => data.name.toLowerCase()]);
});

const getDefaultProjectView = createSelector(
  [
    getProjectViewsLookup,
    getProjectViewsSorted,
    account.selectors.getPreferences
  ],
  (viewLookup, views, userPreferences) => {
    const savedSelectedViewId =
      userPreferences?.tablePreferences?.project?.selectedView;

    if (savedSelectedViewId && viewLookup[savedSelectedViewId]) {
      return viewLookup[savedSelectedViewId];
    }
    return views.filter(v => v.isPublic)[0];
  }
);
const getDefaultEstimateView = createSelector(
  [
    getEstimateViewsLookup,
    getEstimateViewsSorted,
    account.selectors.getPreferences
  ],
  (viewLookup, views, userPreferences) => {
    const savedSelectedViewId =
      userPreferences?.tablePreferences?.estimates?.selectedView;

    if (savedSelectedViewId && viewLookup[savedSelectedViewId]) {
      return viewLookup[savedSelectedViewId];
    }
    return views.filter(v => v.isPublic)[0];
  }
);

const getCurrentEstimateView = createSelector(
  [getEstimateViewsLookup, getSelectedViews, getDefaultEstimateView],
  (viewLookup, selectedViews, defaultView) => {
    const selectedViewId = selectedViews["estimates"];
    return viewLookup[selectedViewId] ?? defaultView;
  }
);

const getCurrentEditArchivedEstimateView = createSelector(
  [getEstimateViewsLookup],
  viewLookup => {
    return viewLookup["editarchivedestimateslist"];
  }
);

const getCurrentProjectView = createSelector(
  [getProjectViewsLookup, getSelectedViews, getDefaultProjectView],
  (viewLookup, selectedViews, defaultView) => {
    const selectedViewId = selectedViews["project"];
    const selectedView = viewLookup[selectedViewId] ?? defaultView;
    return selectedView;
  }
);

const getCurrentProjectViewClean = createSelector(
  [getCurrentProjectView, getOriginal],
  (currentView, originalViews) => {
    return originalViews[currentView.id];
  }
);

//this will work because there is only one view for the estimate schema
const getProjectEstimateView = createSelector(
  [getAllViews, schemas.selectors.getEstimatesSchema],
  (views, estimateSchema) => {
    return views.find(view => view.schemaId === estimateSchema?.id);
  }
);

export const selectors = {
  getWorkingCopy,
  getOriginal,
  getSelectedViews,
  getErrors,
  getAllIds,
  getAllViews,
  getLoaded,
  getProjectViews,
  getProjectViewsLookup,
  getProjectViewsSorted,
  getCurrentProjectView,
  getCurrentProjectViewClean,
  getDefaultProjectView,
  getDefaultEstimateView,
  getProjectEstimateView,
  getEstimateViewsLookup,
  getEstimateViews,
  getEstimateViewsSorted,
  getCurrentEstimateView,
  getCurrentEditArchivedEstimateView
};

function validateLayout(
  currentViews: Array<{ key: string; active?: boolean }>,
  existingLayout: {
    [key: string]: {
      i: string;
      w: number;
      h: number;
      y: number;
      x: number;
    }[];
  }
) {
  return currentViews.every(view => {
    if (view.active) {
      return existingLayout.lg.find(layout => layout.i === view.key);
    } else {
      return !existingLayout.lg.find(layout => layout.i === view.key);
    }
  });
}

function getViewSections(view: View): Array<{ key: string; active?: boolean }> {
  return [
    { key: "list", active: view.listActive },
    { key: "calendar", active: view.calendarActive },
    ...view.chartConfigs.map(chartConfig => {
      return { key: chartConfig.id, active: view.chartsActive };
    }),
    { key: "map", active: view.mapActive }
  ];
}

function getNumRows(numSections: number) {
  if (numSections % 3 === 0) return numSections / 3;
  return Math.floor(numSections / 3) + 1;
}

function normalizeViewLayout(
  layout: View["layout"],
  viewSections: Array<{ key: string; active?: boolean }>,
  chartDeleted = false
) {
  if (!layout || !validateLayout(viewSections, layout) || chartDeleted) {
    const activeSections = viewSections.filter(section => section.active);
    const numRows = getNumRows(activeSections.length);
    let currentRow = 0;

    layout = {
      xs: activeSections.map((viewSection, index) => ({
        i: viewSection.key,
        w: 1,
        h: 12,
        y: 0,
        x: 0
      })),
      lg: activeSections.map((viewSection, index) => {
        const sectionPositionInRow = index % 3;
        if (sectionPositionInRow === 0) {
          currentRow = currentRow + 1;
        }
        const isFullRow =
          currentRow < numRows ||
          (activeSections.length > 0 && activeSections.length % 3 === 0);
        const width = isFullRow ? 4 : 12 / (activeSections.length % 3);
        const height = 12 / numRows < 4 ? 4 : 12 / numRows;
        return {
          i: viewSection.key,
          w: width,
          h: height,
          y: currentRow * 12,
          x: sectionPositionInRow * width
        };
      })
    };
  }
  return layout;
}

function normalizeViews(data: View[]) {
  return data.map(view => {
    const viewSections = getViewSections(view);
    view.layout = normalizeViewLayout(view.layout, viewSections);
    return view;
  });
}
