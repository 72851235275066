import React, { FC, Suspense, lazy } from "react";
import queryString from "query-string";
import { isSandboxEnabled } from "core/util/sandbox";
import FullPageLoading from "modules/layout/components/FullPageLoading";

const SandboxCore = lazy(() =>
  import(/* webpackChunkName: "sandbox" */ "./mirage")
);

export const SandboxLoader: FC = ({ children }) => {
  const checkQueryString = () => {
    const { sandbox } = queryString.parse(window.location.search);
    if (sandbox === "true") {
      localStorage.setItem("sandbox", "true");
      window.location = window.location.origin;
    }
  };

  checkQueryString();

  return isSandboxEnabled() ? (
    <Suspense fallback={<FullPageLoading loading showTitle />}>
      <SandboxCore>{children}</SandboxCore>
    </Suspense>
  ) : (
    <>{children}</>
  );
};
