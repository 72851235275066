import { SchemaFieldFilter } from "modules/schemas/components/filters/SchemaFieldFilter";
import * as React from "react";
import styled from "styled-components";
import { createTheme, Popover, ThemeProvider } from "@mui/material";
import { SchemaField, SchemaViewFilter } from "api";
import { isFilterActive as isFilterActiveCheck } from "../../modules/schemas/filters";
import { ConcreteColors, Icon } from "hcss-components";

export interface FilterControlProps {
  filterId: string;
  displayFilters: string[];
  setDisplayedFilters: React.Dispatch<React.SetStateAction<string[]>>;
  field: SchemaField;
  filter: SchemaViewFilter;
  onChange: (filter: SchemaViewFilter) => void;
  onReset: (filter: SchemaViewFilter) => void;
}
export interface FilterPillButtonProps {
  isActive: boolean;
  onDelete: (e: any) => void;
  onReset: (e: any) => void;
}

const FilterPillButton = ({
  isActive,
  onDelete,
  onReset
}: FilterPillButtonProps) => {
  return (
    <StyledFilterPillButton
      isActive={isActive}
      onClick={e => {
        e.stopPropagation();
        isActive ? onReset(e) : onDelete(e);
      }}
    >
      {isActive ? <Icon name="rotate-left" /> : <Icon name="trash-o" />}
    </StyledFilterPillButton>
  );
};

export const FilterPillNoView = (props: FilterControlProps) => {
  const [showFilterConfig, setShowFilterConfig] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const isFilterActive = React.useMemo(
    () =>
      isFilterActiveCheck({
        ...props.filter,
        fieldType: props.field.type
      }),
    [props.filter, props.field.type]
  );

  const onPillContainerClickHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setShowFilterConfig((showFilterConfig: any) => !showFilterConfig);
  };
  const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(null);
    setShowFilterConfig(false);
  };

  const resetFilterHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    props.onReset(props.filter);
  };
  const deleteFilterHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    props.setDisplayedFilters(
      props.displayFilters.filter(filter => filter !== props.filterId)
    );
  };
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <FilterPillContainer
        canUseFilter={true}
        isActive={isFilterActive}
        aria-describedby={id}
        onClick={e => onPillContainerClickHandler(e)}
      >
        <FilterPillName>{props.field.name}</FilterPillName>
        <FilterPillButton
          isActive={isFilterActive}
          onReset={resetFilterHandler}
          onDelete={deleteFilterHandler}
        />
      </FilterPillContainer>
      <ThemeProvider theme={customStyles}>
        <Popover
          id={id}
          open={showFilterConfig}
          anchorEl={anchorEl}
          onClose={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            handleClose(e)
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <SchemaFieldFilter {...props} />
        </Popover>
      </ThemeProvider>
    </div>
  );
};

const FilterPillContainer = styled.div<{
  canUseFilter: boolean;
  isActive: boolean;
}>`
  padding: 0.5rem 1.3rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border: solid 2px ${props => (props.isActive ? "#0370f5" : "darkgrey")};
  border-radius: 25px;
  align-item: center;
  color: black;
  cursor: pointer;
  background-color: ${props =>
    !props.canUseFilter
      ? "#DB1010"
      : props.isActive
      ? ConcreteColors.blue150
      : "#f6f6f6"};
  :hover {
    background-color: ${ConcreteColors.blue150};
  }
`;

const FilterPillName = styled.div`
  max-width: 20rem;
  height: 2rem;
  font-size: 15px;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 0.5rem;
  margin-right: 1rem;
`;

const StyledFilterPillButton = styled.button<{
  isActive: boolean;
}>`
  border: none;
  padding: 0;
  height: 2rem;
  background: none;
  color: grey;
  transition: 0.6s ease-out;
  [name="trash-o"]:hover {
    color: red;
  }
  [name="rotate-left"]:hover {
    transition: 0.6s ease-out;
    color: ${ConcreteColors.blue200};
    transform: rotateZ(-360deg);
  }
`;
const customStyles = createTheme({
  components: {
    MuiPopover: {
      styleOverrides: {
        paper: {
          overflow: "initial",
          borderRadius: "12px"
        }
      }
    }
  }
});
