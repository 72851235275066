import React from "react";
import Avatar from "react-avatar";

interface AvatarProps {
  id?: string;
  name: string;
  [x: string]: any;
}

export const CompanyAvatar = ({ id, name, ...rest }: AvatarProps) => {
  const color = id ? getAvatarColor(id) : "#808080";
  return (
    <Avatar
      name={name}
      color={color}
      maxInitials={2}
      initials={getCompanyInitials(name)}
      {...rest}
    />
  );
};

const getCompanyInitials = (name: string) => {
  return `${name?.split("")?.[0]?.toLocaleUpperCase() ?? "-"}${
    name?.split("")?.[1]?.toLocaleUpperCase() ?? "-"
  }`;
};

export const UserAvatar = ({ id, name, ...rest }: AvatarProps) => {
  const color = id ? getAvatarColor(id) : "#808080";
  return (
    <Avatar color={color} name={name} maxInitials={2} round={true} {...rest} />
  );
};

/**
 *  Function that generates a hash from a given userId and uses
 *  that hash to generate a color in HSL string format. This is a
 *  deterministic process.
 *
 *  @param userId The user's id
 */
function getAvatarColor(userId: string): string {
  let hash = 0;
  for (let i = 0; i < userId.length; i += 1) {
    hash = userId.charCodeAt(i) + ((hash << 5) - hash);
  }

  const hue = 0.5 * (hash % 720);
  let lum = 30 + (hash % 21);
  if (hue > 30 && hue < 200) lum -= 10;

  return `hsl(${hue}, 70%, ${lum}%)`;
}

type GetAvatarColorFn = (userId: string) => string;
