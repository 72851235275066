import {
  LayoutConcrete as Layout,
  notify,
  useDropdownMenuManager
} from "hcss-components";
import React from "react";
import { useLocation } from "react-router-dom";
import { useTutorialContext } from "../../../tutorials/use-tutorial";
import { strings } from "localization";
import { useHcssUser } from "modules/account";
import { UserType } from "core";
import config from "config";

export const HelpMenuOverride: React.FC = () => {
  const location = useLocation();
  const { type } = useHcssUser();

  return (
    <Layout.HelpMenu>
      <div data-testid="top-nav-help">
        <Layout.DropdownMenuItem
          href="https://help.hcss.com"
          target="_blank"
          iconClassName="fa fa-file-text-o"
        >
          {strings.layout.help.helpCenter}
        </Layout.DropdownMenuItem>
        <Layout.DropdownMenuItem
          href="https://help.hcss.com/s/contactsupport"
          target="_blank"
          iconClassName="fa fa-envelope"
        >
          {strings.layout.help.contact}
        </Layout.DropdownMenuItem>
        {type === UserType.HcssSubscription && (
          <Layout.DropdownMenuItem
            href="tel:+18552317876"
            iconClassName="fa fa-phone"
          >
            {strings.layout.help.phone}
          </Layout.DropdownMenuItem>
        )}
        <Layout.DropdownDivider />
        {type === UserType.HcssSubscription && (
          <Layout.DropdownMenuItem
            href="http://community.hcss.com/"
            target="_blank"
            iconClassName="fa fa-lightbulb-o"
          >
            {strings.layout.help.suggestion}
          </Layout.DropdownMenuItem>
        )}
        <Layout.DropdownMenuItem
          href="http://www.hcss.com/about-us/"
          target="_blank"
          iconClassName="fa fa-hcss"
        >
          {strings.layout.help.about}
        </Layout.DropdownMenuItem>
        <Layout.DropdownMenuItem
          href="https://www.hcss.com/privacy-policy/"
          target="_blank"
          iconClassName="fa fa-shield"
        >
          {strings.layout.help.privacy}
        </Layout.DropdownMenuItem>
        {location.pathname === "/projects" &&
          !location.search.includes("calendar") && (
            <ConnectedProjectsTutorialMenu>
              {strings.layout.help.tutorial}
            </ConnectedProjectsTutorialMenu>
          )}
      </div>
    </Layout.HelpMenu>
  );
};

// TODO: Specify a type to onClick
const ProjectsTutorialMenu: React.FC<{ onClick: any }> = ({
  onClick: handleClose,
  children
}) => {
  const tutorial = useTutorialContext();

  return (
    <>
      <Layout.DropdownDivider />
      <Layout.DropdownMenuItem
        onClick={() => {
          handleClose();
          tutorial.projectsList.openTutorial();
        }}
        iconClassName="fa fa-question-circle-o"
      >
        {children}
      </Layout.DropdownMenuItem>
    </>
  );
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const ConnectedProjectsTutorialMenu = useDropdownMenuManager(
  ProjectsTutorialMenu
);
