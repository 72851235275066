import React from "react";
import { CurrentEstimateProvider } from "./modules/estimates/context/CurrentEstimateContext";
import { CodebooksProvider } from "./modules/estimates/context/CodebooksContext";
import { SolcitationProvider } from "./modules/quote-management/context/solicitation-context";
import { ProposalProvider } from "modules/quote-management/context/proposal-context";
import { VendorCommunicationProvider } from "modules/quote-management/context/vendor-communication-context";
import { RecentEstimateProvider } from "modules/estimates/components/recent-estimates/use-recent-estimates";
import { TutorialProvider } from "./modules/tutorials/use-tutorial";
import { HcssConnectProvider } from "core/services/hcss-connect";
import { DevTools } from "./dev-tools";
import { RestfulProvider } from "restful-react";
import { useHcssToken } from "modules/account";

interface AppProvidersProps {
  children?: React.ReactNode;
}
export const AppProviders = ({ children }: AppProvidersProps) => {
  return (
    <RestfulReactProvider>
      <CurrentEstimateProvider>
        <RecentEstimateProvider>
          <CodebooksProvider>
            <SolcitationProvider>
              <ProposalProvider>
                <VendorCommunicationProvider>
                  <HcssConnectProvider>
                    <TutorialProvider>
                      <DevTools>{children}</DevTools>
                    </TutorialProvider>
                  </HcssConnectProvider>
                </VendorCommunicationProvider>
              </ProposalProvider>
            </SolcitationProvider>
          </CodebooksProvider>
        </RecentEstimateProvider>
      </CurrentEstimateProvider>
    </RestfulReactProvider>
  );
};

const RestfulReactProvider: React.FC = ({ children }) => {
  const token = useHcssToken();
  return (
    <RestfulProvider
      base=""
      requestOptions={{ headers: { Authorization: `BEARER ${token}` } }}
    >
      {children}
    </RestfulProvider>
  );
};
