import { Select } from "hcss-components";
import { keyBy } from "lodash-es"
import * as React from "react";
import { FilterControlProps } from "./common";

const checklistFilterOptions: any[] = [
  { label: "Complete", value: "true" },
  { label: "Incomplete", value: "false" }
];
const checklistFilterLookup = keyBy(checklistFilterOptions, "value");

export const ChecklistFilter: React.FunctionComponent<FilterControlProps> = ({
  field,
  filter,
  onChange
}) => {
  const currentValue =
    filter.value !== undefined ? checklistFilterLookup[filter.value] : null;
  return (
    <div style={{ width: "300px" }} key={field.id}>
      <Select
        isClearable
        value={currentValue}
        onChange={(option: any) => {
          let value: boolean | undefined;
          if (option) {
            value = option.value === "true";
          }
          onChange({
            ...filter,
            value
          });
        }}
        options={checklistFilterOptions}
      />
    </div>
  );
};
