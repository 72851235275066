import * as account from "../modules/account";
import axios from "axios";
import redux from "../modules/store";
import { isNullOrWhitespace } from "core";

const getDefaultInstance = (accessToken?: string) => {
  // Create instance
  const instance = axios.create();

  // Set the AUTH token for any request
  instance.interceptors.request.use(config => {
    if (isNullOrWhitespace(config.headers.common.Authorization)) {
      const auth = accessToken ? `Bearer ${accessToken}` : "";
      config.headers.common.Authorization = auth;
    }

    return config;
  });

  // Handle 401 errors
  instance.interceptors.response.use(
    response => response,
    async error => {
      const request = error.config;

      if (error.response.status === 401 && !request.__retry) {
        request.__retry = true;

        try {
          const state = redux.store.getState();
          const currentUser = account.selectors.getAccount(state)!;
          const token = currentUser.user?.idsrvAccessToken;
          axios.defaults.headers.common.Authorization = `Bearer ${token}`;
          return axios(request);
        } catch (reason) {
          console.error(reason);
          // TODO: dispatch invalid session action
          // and show reload modal.
          window.location.reload();
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export default getDefaultInstance;
