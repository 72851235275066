import * as React from "react";
import { FilterControlProps } from "./common";
import styled from "styled-components";
import { parsePreConId, preconIdToString } from "modules/projects";
import { CreatableSelect } from "./TextFilter";

export const ProjectIdFilter: React.FunctionComponent<FilterControlProps> = ({
  filter,
  onChange
}) => {
  const value =
    filter.value?.map((filter: any) => ({
      label: filter.label,
      value: preconIdToString(filter.value)
    })) ?? [];
  return (
    <SelectContainer>
      <CreatableSelect
        placeholder={filter.config?.placeholder}
        value={value}
        onChange={(val: any) => {
          const value = val.map((item: any) => ({
            label: item.label,
            value: parsePreConId(item.value)
          }));
          onChange({
            ...filter,
            value: value && value.length ? value : undefined
          });
        }}
      />
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  width: 400px;
  max-width: 800px;
  flex-grow: 1;
  word-wrap: break-word;
`;
