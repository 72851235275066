import styled from "styled-components";

interface FilterWrapperStyleProps {
  isFilterActive: boolean;
}

export const ExpandFilters = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  > button {
    margin: 0;
    font-size: 15px;
    color: #005bca;
    border: none;
    background: none;
    padding: 0;
  }
  & button:hover {
    text-decoration: underline;
  }
`;

export const FiltersWrapper = styled.div<FilterWrapperStyleProps>`
  height: initial;
  vertical-align: middle;
  padding: 10px 16px;
  background-color: #d6d6d6;
  display: flex;
  column-gap: 1rem;
  font-size: 13px;
  box-shadow: none;
  & button.styled-filter-dropdown-button {
    :hover {
      box-shadow: none;
    }
    :active {
      box-shadow: none;
    }
  }
`;

export const FilterControl = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  margin-right: 1rem;
  > span {
    :hover {
      text-decoration: underline;
    }
  }
  :hover {
    box-shadow: none;
  }
  & > svg {
    padding-bottom: 2px;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 1rem;
  > div {
    margin-bottom: 8px;
  }
`;

export const FilterClearOption = styled.div`
  min-width: 19rem;
  font-size: 13px;
  margin-top: 3px;
  color: #005bca;
`;

export const FilterAddEditOption = styled.div`
  min-width: 19rem;
  font-size: 13px;
  margin-top: 3px;
  color: #005bca;
`;

export const dropdownButtonStylings = {
  background: "none",
  color: "black",
  padding: "0px",
  border: "none",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  marginRight: "2rem",
  marginLeft: "1.5rem",
  marginTop: "0.6rem",
  boxShadow: "none"
};
