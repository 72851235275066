import MimeIconMap from "./files.mimemap.json";

export const formatFileSize = (bytesPassed: number) => {
  let suffix: string[] = [];
  let bytes = bytesPassed;
  suffix = ["B", "KB", "MB", "GB"];
  let index = 0;
  while (bytes >= 1000) {
    index++;
    bytes /= 1000;
  }

  return `${bytes.toFixed(2)} ${suffix[index]}`;
};

export const getIconForFile = (mime: string) => {
  const map = MimeIconMap as any;
  if (mime in map) {
    return map[mime];
  }
  return "file-o";
};
