import * as React from "react";
import { useRecentEstimatesContext } from "./use-recent-estimates";
import { useMemo } from "react";
import { Link } from "hcss-components";
import styled from "styled-components";
import { Box } from "hcss-core";
import { getPathForEstimate } from "modules/estimates/views/paths";
import { track } from "logging";
import { SectionHeading } from "core/components/hcss-navigation";
import { TextOverflow } from "core";
import { strings } from "localization";

export const RecentEstimateList = () => {
  const { recentEstimates } = useRecentEstimatesContext();
  const options = useMemo(() => {
    return recentEstimates.slice(0, 5).map(e => {
      return {
        key: e.id ?? "",
        mainText: e.values.code ?? "(no code)",
        subText: e.values.name ?? "(no name)"
      };
    });
  }, [recentEstimates]);
  if (recentEstimates.length === 0) return null;
  return (
    <div>
      <SectionHeading style={{ marginTop: "20px" }}>
        {strings.layout.menu.estimating.recentEstimates}
      </SectionHeading>
      <div data-testid="estimates-recent">
        {options.map(option => (
          <Link
            key={option.key}
            to={getPathForEstimate(option.key)}
            style={{ textDecoration: "none" }}
          >
            <EstimateLink
              py={2}
              px={3}
              onClick={() => track("RECENT ESTIMATE CLICK")}
            >
              <TextOverflow fontWeight={600}>{option.mainText}</TextOverflow>
              <TextOverflow fontSize={0}>{option.subText}</TextOverflow>
            </EstimateLink>
          </Link>
        ))}
      </div>
    </div>
  );
};

const EstimateLink = styled(Box)`
  color: ${props => props.theme.colors.neutral[1]};
  text-decoration: none;
  min-width: 100%;
  font-size: 12px;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  max-width: 300px;

  &:hover {
    color: #0370f5;
    text-decoration: none;
  }
`;
