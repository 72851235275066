import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
  where
} from "firebase/firestore";
import { firebaseApp } from "../../../firebase-app";
import { isUndefined, omitBy, omit } from "lodash-es";
import { VendorProposal, VendorProposalEntity } from "core";

const db = getFirestore(firebaseApp);
const collectionId = "vendorProposals";

export const VendorProposalRepo = {
  generateId: () => {
    return doc(collection(db, collectionId)).id;
  },

  getById: async (id: string) => {
    const ref = doc(collection(db, collectionId), id);

    try {
      const snap = await getDoc(ref);
      const data = snap.data() as VendorProposal;
      const entity: VendorProposalEntity = { ...data, id: ref.id };
      return entity;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  },

  /**
   * Gets last submission for the given request id.
   * CompanyId or Email is required for filtering purposes.
   * CompanyId takes precendence.
   */
  getByRequestId: async (
    requestId: string,
    companyId?: string,
    isSender?: boolean,
    email?: string
  ) => {
    const whereClauses = [];
    whereClauses.push(where("requestId", "==", requestId));
    if (companyId) {
      if (isSender) {
        whereClauses.push(where("senderCompanyId", "==", companyId));
      } else {
        whereClauses.push(where("receiverCompanyId", "==", companyId));
      }
    } else if (email) {
      whereClauses.push(where("senderEmail", "==", email));
    } else {
      throw new Error("Invalid Arguments: companyId or email is required.");
    }

    try {
      const q = query(
        collection(db, collectionId),
        ...whereClauses,
        orderBy("createdDate", "desc")
      );
      const snap = await getDocs(q);
      if (snap.docs.length === 0) {
        return undefined;
      }

      const doc = snap.docs[0];
      const data = doc.data() as VendorProposal;
      const entity: VendorProposalEntity = { ...data, id: doc.id };
      return entity;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  },

  getAll: async (companyId: string, estimateId?: string) => {
    const proposals: VendorProposalEntity[] = [];

    const whereClauses = [];
    whereClauses.push(where("senderCompanyId", "==", companyId));

    if (estimateId) {
      whereClauses.push(where("estimateId", "==", estimateId));
    }

    try {
      const q = query(collection(db, collectionId), ...whereClauses);
      const snap = await getDocs(q);
      for (const doc of snap.docs) {
        const data = doc.data() as VendorProposal;
        proposals.push({ ...data, id: doc.id });
      }
    } catch (error) {
      console.error(error);
    }

    return proposals;
  },

  getOutboundSubscription: (companyId: string, estimateId?: string) => {
    const whereClauses = [];
    whereClauses.push(where("senderCompanyId", "==", companyId));

    if (estimateId) {
      whereClauses.push(where("estimateId", "==", estimateId));
    }

    const q = query(collection(db, collectionId), ...whereClauses);
    return q;
  },

  getInboundSubscription: (companyId: string, estimateId?: string) => {
    const whereClauses = [];
    whereClauses.push(where("receiverCompanyId", "==", companyId));

    if (estimateId) {
      whereClauses.push(where("estimateId", "==", estimateId));
    }

    const q = query(collection(db, collectionId), ...whereClauses);
    return q;
  },

  save: async (proposal: VendorProposalEntity) => {
    const id = proposal.id;
    const ref = doc(collection(db, collectionId), id);

    try {
      let doc = omitBy(proposal, isUndefined);
      doc = omit(doc, ["id"]);
      await setDoc(ref, doc);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
};
