import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { notify } from "hcss-components";
import { useAuthorization, usePermissions } from "modules/account";
import { strings } from "localization";

interface ProtectedRouteProps {
  isAdmin?: boolean;
  isAddEdit?: boolean;
  isFullFeature?: boolean;
  isContactAddEdit?: boolean;
  canAccessFullProjectTracking?: boolean;
  canAccessEstimateInsights?: boolean;
}

export const ProtectedRoute: React.FC<
  RouteProps & ProtectedRouteProps
> = props => {
  const permissions = usePermissions();
  const authorization = useAuthorization();

  if (props.isFullFeature) {
    if (!authorization.canAccessFullPrecon) return <FullPreconRedirect />;
  }

  if (props.isAdmin) {
    if (!permissions.admin) return <AdminRedirect />;
    if (!authorization.canAccessLimitedPrecon) return <AdminRedirect />;
  }

  if (props.isAddEdit) {
    if (!authorization.canAccessLimitedPrecon) return <LimitedPreconRedirect />;
    if (!permissions.write) return <AddEditRedirect />;
  }

  if (props.isContactAddEdit) {
    if (!authorization.canAccessLimitedPrecon) return <LimitedPreconRedirect />;
    if (!permissions.contactWrite) return <ContactAddEditRedirect />;
  }

  if (
    props.canAccessFullProjectTracking &&
    !authorization.canAccessFullPrecon
  ) {
    return <NoAccessToFullProjectTrackingRedirect />;
  }

  if (props.canAccessEstimateInsights && !permissions.estimateInsights) {
    return <NoAccessToEstimateInsightsRedirect />;
  }

  return <Route {...props} />;
};

const AdminRedirect: React.FC = () => {
  React.useEffect(() => {
    notify("danger", strings.userAdmin.adminRequired);
  }, []);
  return <Redirect to={{ pathname: "/projects" }} />;
};

const AddEditRedirect: React.FC = () => {
  React.useEffect(() => {
    notify("danger", strings.userAdmin.writeRequired);
  }, []);
  return <Redirect to={{ pathname: "/projects" }} />;
};

const ContactAddEditRedirect: React.FC = () => {
  React.useEffect(() => {
    notify("danger", strings.userAdmin.contactRequired);
  }, []);
  return <Redirect to={{ pathname: "/contacts" }} />;
};

const FullPreconRedirect: React.FC = () => {
  React.useEffect(() => {
    notify("danger", strings.userAdmin.fullFeatureRequired);
  }, []);
  return <Redirect to={{ pathname: "/projects" }} />;
};

const LimitedPreconRedirect: React.FC = () => {
  React.useEffect(() => {
    notify("danger", strings.userAdmin.fullPreconRequired);
  }, []);
  return <Redirect to={{ pathname: "/projects" }} />;
};

const NoAccessToFullProjectTrackingRedirect: React.FC = () => {
  React.useEffect(() => {
    notify("danger", strings.userAdmin.fullProjectTracking);
  }, []);
  return <Redirect to={{ pathname: "/projects" }} />;
};

const NoAccessToEstimateInsightsRedirect: React.FC = () => {
  React.useEffect(() => {
    notify("danger", strings.userAdmin.estimateInsights);
  }, []);
  return <Redirect to={{ pathname: "/projects" }} />;
};
