import React from "react";
import styled from "styled-components";
export const TermsAndConditions = () => {
  return (
    <div style={{ maxHeight: "550px", overflow: "auto" }}>
      <SpacedSection>
        <strong>Estimating for Subcontractors</strong>
      </SpacedSection>
      <SpacedSection>
        <strong>&nbsp;</strong>
      </SpacedSection>
      <SpacedSection>
        <strong>Terms and Conditions</strong>
      </SpacedSection>
      <SpacedSection>
        <strong>
          PLEASE READ THIS AGREEMENT CAREFULLY BEFORE USING THIS APPLICATION.
        </strong>
      </SpacedSection>
      <SpacedSection>
        <strong>
          BY USING THE APPLICATION OR CLICKING &ldquo;AGREE&rdquo; YOU ARE
          AGREEING TO BE BOUND BY THIS AGREEMENT. IF YOU ARE AGREEING TO THESE
          TERMS ON BEHALF OF OR FOR THE BENEFIT OF YOUR EMPLOYER OR A THIRD
          PARTY, THEN YOU REPRESENT AND WARRANT THAT YOU HAVE THE NECESSARY
          AUTHORITY TO AGREE TO THIS AGREEMENT ON THEIR BEHALF.
        </strong>
      </SpacedSection>
      <SpacedSection>
        <u>
          This Agreement is for the Alpha offering. The Definitive Terms and
          Conditions will be available prior to General Release of the
          Application.
        </u>{" "}
        This agreement is between Heavy Construction Systems Specialists, Inc. a
        Texas corporation (HCSS) and the customer entering into this agreement
        (Customer).
      </SpacedSection>
      <SpacedSection>
        <strong>PROPRIETARY RIGHTS. </strong>The Application is the proprietary
        property of HCSS and its licensors, and all right, title and interest in
        and to the Application, including all associated intellectual property
        rights, remain only with HCSS and its licensors. The Application is
        protected by copyright and other intellectual property laws.&nbsp;
        Customer may not remove any product identification, copyright, trademark
        or other notice from the Application. HCSS reserves all rights not
        expressly granted.
      </SpacedSection>
      <SpacedSection>
        <strong>
          WARRANTY AND DISCLAIMER. CUSTOMER WARRANTS THAT (I) IT COMPLIES WITH
          ALL APPLICABLE LAWS AND REGULATIONS WHILE USING THE APPLICATION
          INCLUDING WHILE OPERATING HEAVY EQUIPMENT, AND (II) IT USES ITS BEST
          JUDGMENT IN CHECKING AND ASSURING THE ACCURACY OF THE
          APPLICATION&rsquo;S RESULTS, AND ASKS HCSS ABOUT, OR LETS HCSS KNOW OF
          ANY RESULT THAT APPEARS SUSPECT. THE APPLICATION IS PROVIDED &lsquo;AS
          IS&rsquo; WITHOUT WARRANTY. HCSS DISCLAIMS ALL IMPLIED WARRANTIES,
          INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTY OF MERCHANTABILITY
          AND FITNESS FOR A PARTICULAR PURPOSE.&nbsp; CUSTOMER UNDERSTANDS THAT
          THE APPLICATION MAY NOT BE ERROR FREE, AND USE MAY BE INTERRUPTED.
          HCSS DISCLAIMS RESPONSIBILITY FOR (I) CUSTOMER&rsquo;S USE OF THE
          APPLICATION AND THE RESULTS OF THE &nbsp;APPLICATION, AND (II) ANY
          INJURIES OR LOSSES OCCURING DURING CUSTOMER&rsquo;S USE OF THE&nbsp;
          APPLICATION.
        </strong>
      </SpacedSection>
      <SpacedSection>
        <strong>LIMIT ON LIABILITY.</strong>
      </SpacedSection>
      <ul>
        <li>
          Exclusion of Indirect Damages. HCSS is not liable for any indirect,
          special, incidental, or consequential damages arising out of or
          related to this agreement (including, without limitation, costs of
          delay; loss of or unauthorized access to data or information; and lost
          profits, revenue, or anticipated cost savings), even if it knows of
          the possibility or foreseeability of such damage or loss.
        </li>
        <li>
          Total Limit on Liability. HCSS's total liability arising out of or
          related to this agreement (whether in contract, tort, or otherwise)
          does not exceed $100.
        </li>
      </ul>
      <SpacedSection>
        <strong>GOVERNING LAW AND EXCLUSIVE FORUM. </strong>This agreement is
        governed by the laws of the State of Texas (without regard to conflicts
        of law principles) for any dispute between the parties or relating in
        any way to the subject matter of this agreement.&nbsp; Any suit or legal
        proceeding must be exclusively brought in the federal or state courts
        for Harris County, Texas, and Customer submits to this personal
        jurisdiction and venue.&nbsp; Nothing in this agreement prevents either
        party from seeking injunctive relief in a court of competent
        jurisdiction.&nbsp; The prevailing party in litigation is entitled to
        recover its attorneys&rsquo; fees and costs from the other party.&nbsp;
      </SpacedSection>
    </div>
  );
};

const SpacedSection = styled.div`
  margin-bottom: 8px;
`;
