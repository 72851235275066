import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faTimes as falTimes,
  faCircle as falCircle,
  faImage as falImage,
  faBuilding as falBuilding,
  faFileCertificate as falFileCertificate
} from "@fortawesome/pro-light-svg-icons";

import {
  faCaretDown as fasCaretDown,
  faFileSignature as fasFileSignature
} from "@fortawesome/free-solid-svg-icons";

import { faDigging as fasDigging } from "@fortawesome/pro-solid-svg-icons";

import {
  faAddressBook as fadAddressBook,
  faArchive as fadArchive,
  faBell as fadBell,
  faCalculator as fadCalculator,
  faChartLine as fadChartLine,
  faCommentAltLines as fadCommentAltLines,
  faDollarSign as fadDollarSign,
  faEnvelopeOpenDollar as fadEnvelopeOpenDollar,
  faFileInvoiceDollar as fadFileInvoiceDollar,
  faFolderOpen as fadFolderOpen,
  faMapMarkedAlt as fadMapMarkedAlt,
  faMoneyBill as fadMoneyBill,
  faSpinnerThird as fadSpinnerThird,
  faTrafficCone as fadTrafficCone,
  faUserCircle as fadUserCircle,
  faStopwatch as fadStopwatch,
  faFileSearch as fadFileSearch,
  faUserAlt as fadUserAlt,
  faExclamationTriangle as fadExclamationTriangle,
  faUserCheck as fadUserCheck,
  faUserTimes as fadUserTimes,
  faBuilding as fadBuilding,
  faPencil as fadPencil,
  faTools as fadTools,
  faAlignSlash as fadAlignSlash,
  faAbacus as fadAbacus,
  faConstruction as fadConstruction,
  faTelescope as fadTelescope,
  faTerminal as fadTerminal,
  faSortNumericUpAlt as fadSortNumericUpAlt
} from "@fortawesome/pro-duotone-svg-icons";

import {
  faAddressBook as farAddressBook,
  faAnalytics as farAnalytics,
  faBell as farBell,
  faCalculator as farCalculator,
  faChartLine as farChartLine,
  faClipboardListCheck as farClipboardListCheck,
  faCogs as farCogs,
  faCommentAlt as farCommentAlt,
  faCommentAltLines as farCommentAltLines,
  faEnvelopeOpenDollar as farEnvelopeOpenDollar,
  faFileImport as farFileImport,
  faFileInvoiceDollar as farFileInvoiceDollar,
  faFolderOpen as farFolderOpen,
  faFolders as farFolders,
  faInboxIn as farInboxIn,
  faHome as farHome,
  faLongArrowRight as farLongArrowRight,
  faInboxOut as farInboxOut,
  faProjectDiagram as farProjectDiagram,
  faRoad as farRoad,
  faSlidersV as farSlidersV,
  faSmile as farSmile,
  faStream as farStream,
  faUsers as farUsers,
  faFileContract as farFileContract,
  faSearchPlus as farSearchPlus,
  faCommentDollar as farCommentDollar,
  faWindow as farWindow,
  faBox as farBox,
  faSortNumericUpAlt as farSortNumericUpAlt,
  faMobile as farMobile,
  faPhone as farPhone,
  faEnvelope as farEnvelope,
  faSearch as farSearch,
  faLink as farLink,
  faUnlink as farUnlink,
  faTimes as farTimes,
  faPlus as farPlus,
  faCheck as farCheck,
  faEyeSlash as farEyeSlash,
  faTable as farTable,
  faLock as farLock,
  faLockOpen as farLockOpen
} from "@fortawesome/pro-regular-svg-icons";

export const initializeFontAwesome = () => {
  // Ad Light
  library.add(falTimes, falCircle, falImage, falBuilding, falFileCertificate);

  // Add Solid
  library.add(fasCaretDown, fasFileSignature, fasDigging);

  // Add duotone icons
  library.add(
    fadAddressBook,
    fadAlignSlash,
    fadArchive,
    fadBell,
    fadCalculator,
    fadChartLine,
    fadCommentAltLines,
    fadDollarSign,
    fadEnvelopeOpenDollar,
    fadFileInvoiceDollar,
    fadFolderOpen,
    fadMapMarkedAlt,
    fadMoneyBill,
    fadSpinnerThird,
    fadTrafficCone,
    fadUserCircle,
    fadStopwatch,
    fadFileSearch,
    fadUserAlt,
    fadExclamationTriangle,
    fadUserCheck,
    fadUserTimes,
    fadBuilding,
    fadPencil,
    fadTools,
    fadAbacus,
    fadConstruction,
    fadTelescope,
    fadTerminal,
    fadSortNumericUpAlt
  );

  // Add regular icons
  library.add(
    farAddressBook,
    farAnalytics,
    farBell,
    farCalculator,
    farChartLine,
    farClipboardListCheck,
    farCogs,
    farCommentAlt,
    farCommentAltLines,
    farEnvelopeOpenDollar,
    farFileImport,
    farFileInvoiceDollar,
    farFolderOpen,
    farFolders,
    farHome,
    farInboxIn,
    farInboxOut,
    farLongArrowRight,
    farProjectDiagram,
    farRoad,
    farSlidersV,
    farSmile,
    farStream,
    farUsers,
    farFileContract,
    farSearchPlus,
    farCommentDollar,
    farWindow,
    farBox,
    farSortNumericUpAlt,
    farMobile,
    farPhone,
    farEnvelope,
    farSearch,
    farLink,
    farUnlink,
    farTimes,
    farPlus,
    farCheck,
    farEyeSlash,
    farTable,
    farLock,
    farLockOpen
  );
};
