import React, { useState } from "react";
import FullPageLoading from "modules/layout/components/FullPageLoading";
import ConfirmDelete from "core/components/modals/ConfirmDelete";
import { strings } from "localization";
import {
  ContactDto,
  IContactDto,
  IVendorDtoV1Response
} from "api/GeneratedClients/ContactsClient";
import { cloneDeep } from "lodash-es";
import {
  defaultColumnOrdering,
  getContactListColumns
} from "./contact-list-columns";
import { EditingState, TableRow } from "@devexpress/dx-react-grid";

import NavigationPrompt from "react-router-navigation-prompt";
import ConfirmBack from "../../../core/components/modals/ConfirmBack";
import { disableCallbackInSandbox } from "core/util/sandbox";

import {
  BooleanFormatProvider,
  ColumnResizing,
  getRowId,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedSorting,
  LinkProvider,
  Root,
  SearchState,
  SortingState,
  TableContainer,
  TableGroupRow,
  TableHeaderRow,
  TableWrapper,
  ColumnVisibility,
  ColumnReordering,
  CommandComponent
} from "hcss-tables";

import {
  Grid,
  Toolbar,
  SearchPanel,
  TableEditColumn,
  DragDropProvider
} from "@devexpress/dx-react-grid-bootstrap3";

import {
  NoDataPlaceholder,
  ContentLoadingOverlay,
  formatFirstNameLastName
} from "core";
import { useDispatch, useSelector } from "react-redux";
import { actions as contactsActions, selectors } from "modules/contacts";
import { NoteProvider } from "modules/tables/columns/column-providers";
import { ContactListModal } from "./contact-list-modal";
import { useGetSelectedVendor } from "../hooks/use-get-selected-vendor";
import { useAuthorization, usePermissions } from "modules/account";
import { ConcreteColors, DropdownButton, Icon } from "hcss-components";
import { MenuItem } from "react-bootstrap";
import styled from "styled-components";
import { MoveContactModal } from "./move-contact-modal";
import { CustomEditCellHeaderComponent } from "modules/tables/cells/custom-cells-components";
import {
  ContactLocationProvider,
  ContactEmailProvider,
  ContactPhoneProvider
} from "./contact-column-providers";
import { CustomColumnChooserModal } from "modules/tables/column-chooser-modal";
import { SyncContactModal } from "./sync-contact-modal";
import LegacyVirtualTable from "core/components/bundle-fixes/LegacyVirtualTable";
import { LegacyGroupingPanel } from "core/components/bundle-fixes/LegacyGroupingPanel";

export const ContactList = () => {
  const dispatch = useDispatch();
  const permissions = usePermissions();
  const authorization = useAuthorization();
  const vendor = useGetSelectedVendor();
  const isLoading = useSelector(selectors.getLoading);

  const newContact: IContactDto = {
    id: "",
    companyId: vendor?.id,
    firstName: "",
    lastName: "",
    title: "",
    phoneNumber: "",
    faxNumber: "",
    cellPhoneNumber: "",
    emailAddress: "",
    note: "",
    isMainContact: false
  };

  const [editingRowIds, setEditingRowIds] = useState<(string | number)[]>([]);
  const [addedRows, setAddedRows] = useState<IContactDto[]>([]);
  const [rowChanges, setRowChanges] = useState<ContactRecord>({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [toDelete, setToDelete] = useState<IContactDto>();
  const [editingContact, setEditingContact] = useState<IContactDto>(newContact);
  const [movingContact, setMovingContact] = useState<IContactDto>(newContact);
  const [contactToSync, setContactToSync] = useState<IContactDto>(newContact);

  const [showSyncContactModal, setShowSyncContactModal] = useState<boolean>(
    false
  );
  const addBlankRow = () => {
    if (vendor) {
      setEditingContact(newContact);
    }
  };

  const changeAddedRows = (value: ContactDto[]) => {
    setAddedRows([...value]);
  };

  const changeEditedRows = (value: Record<string, Partial<ContactDto>>) => {
    setRowChanges(value);
  };

  const handleDelete = () => {
    if (toDelete && vendor) {
      dispatch(
        contactsActions.deleteContact.request({
          contact: toDelete,
          vendorId: vendor.id
        })
      );
    }

    setIsDeleting(false);
  };

  const handleOnExecute = (change: OnHandlerCommitProps) => {
    const { id: command } = change;
    if (!vendor) return;
    if (command === "add") {
      addBlankRow();
      dispatch(contactsActions.setShowModal(true));
    }
  };

  const hasUnsavedChanges = () => {
    if (rowChanges && Object.keys(rowChanges).length > 0) {
      return true;
    } else if (addedRows && Object.keys(addedRows).length > 0) {
      return true;
    }

    return false;
  };

  if (!vendor) {
    return <FullPageLoading loading={true} />;
  }

  return (
    <>
      <ContactListModal editingContact={editingContact} vendor={vendor} />
      <MoveContactModal movingContact={movingContact} vendor={vendor} />
      {showSyncContactModal && contactToSync && (
        <SyncContactModal
          showModal={showSyncContactModal}
          setShowModal={setShowSyncContactModal}
          contactToSync={contactToSync}
        />
      )}
      <TableContainer
        columns={getContactListColumns(vendor)}
        templateName="contacts.vendor.contacts.list"
        defaults={{
          sorting: {
            defaultSorting: [{ columnName: "lastName", direction: "asc" }]
          }
        }}
      >
        <TableWrapper style={{ position: "relative" }}>
          {isLoading && (
            <ContentLoadingOverlay
              message={strings.contactManagement.contactList.loading}
            />
          )}
          {vendor.contacts &&
          (vendor.contacts.length > 0 || addedRows.length > 0) ? (
            <Grid
              rootComponent={Root}
              getRowId={getRowId}
              rows={vendor.contacts}
              columns={getContactListColumns(vendor)}
            >
              <ContactLocationProvider />
              <ContactPhoneProvider />
              <ContactEmailProvider />
              <NoteProvider />
              <EditingState
                editingRowIds={editingRowIds}
                onEditingRowIdsChange={setEditingRowIds}
                rowChanges={rowChanges}
                onRowChangesChange={changeEditedRows}
                addedRows={addedRows}
                onAddedRowsChange={changeAddedRows}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onCommitChanges={() => {}}
              />
              <SearchState />
              <SortingState />
              <GroupingState />
              <IntegratedSorting />
              <IntegratedFiltering />
              <IntegratedGrouping />
              <LinkProvider />
              <BooleanFormatProvider />
              <DragDropProvider />
              <LegacyVirtualTable estimatedRowHeight={40} />
              <ColumnResizing />
              <ColumnVisibility />
              <ColumnReordering defaultOrder={defaultColumnOrdering} />
              <TableHeaderRow showSortingControls />
              <TableEditColumn
                width={100}
                showAddCommand
                showEditCommand
                showDeleteCommand
                messages={{ editCommand: "Edit", deleteCommand: "Delete" }}
                commandComponent={(props: TableEditColumn.CommandProps) => (
                  <CommandComponent
                    {...props}
                    disabled={
                      !permissions.contactWrite ||
                      !authorization.canAccessLimitedPrecon
                    }
                    onExecute={() => {
                      handleOnExecute(props);
                    }}
                  />
                )}
                cellComponent={props => (
                  <CustomCommandCell
                    {...props}
                    vendor={vendor}
                    setIsDeleting={setIsDeleting}
                    setToDelete={setToDelete}
                    setEditingContact={setEditingContact}
                    setMovingContact={setMovingContact}
                    setShowSyncContactModal={setShowSyncContactModal}
                    setContactToSync={setContactToSync}
                  />
                )}
                headerCellComponent={CustomEditCellHeaderComponent}
              />
              <TableGroupRow />
              <Toolbar />
              <CustomColumnChooserModal />
              <LegacyGroupingPanel
                showGroupingControls
                messages={{ groupByColumn: strings.tables.group.placeholder }}
              />
              <SearchPanel
                messages={{
                  searchPlaceholder:
                    strings.contactManagement.contactList.searchPlaceholder
                }}
              />
            </Grid>
          ) : (
            <NoDataPlaceholder
              onClick={() => {
                addBlankRow();
                dispatch(contactsActions.setShowModal(true));
              }}
              mainIconName="user-alt"
              isAddEditDisplay={
                permissions.contactWrite && authorization.canAccessLimitedPrecon
              }
              helpText={{
                title:
                  strings.contactManagement.contactList.noContactsHelpText
                    .title,
                body:
                  strings.contactManagement.contactList.noContactsHelpText.body
              }}
              buttonText={
                strings.contactManagement.contactList.noContactsButtonText
              }
            />
          )}
          {isDeleting && toDelete && vendor.contacts && (
            <ConfirmDelete
              title={strings.contact.contactList.confirmDelete}
              show={isDeleting}
              handleClose={() => setIsDeleting(false)}
              handleDelete={() => handleDelete()}
            >
              {strings.formatString(
                strings.contactManagement.contactList.deleteConfirmation,
                formatFirstNameLastName(toDelete.firstName, toDelete.lastName)
              )}
            </ConfirmDelete>
          )}
        </TableWrapper>
      </TableContainer>
      <NavigationPrompt when={() => hasUnsavedChanges()} allowGoBack>
        {({ onConfirm, onCancel }) => {
          return (
            <ConfirmBack
              show={true}
              handleClose={onCancel}
              handleBack={() => onConfirm()}
            >
              {strings.core.modals.confirmBack.unsavedChanges}
            </ConfirmBack>
          );
        }}
      </NavigationPrompt>
    </>
  );
};

const CustomCommandCell = ({
  children,
  vendor,
  setIsDeleting,
  setToDelete,
  setEditingContact,
  setMovingContact,
  setShowSyncContactModal,
  setContactToSync,
  ...restProps
}: CustomCellComponentProps) => {
  const dispatch = useDispatch();
  const permissions = usePermissions();
  const authorization = useAuthorization();
  const handleEdit = () => {
    if (restProps.tableRow) {
      const changedContactId = restProps.tableRow.rowId;
      const editingVendorContact = vendor?.contacts.find(
        (contact: IContactDto) => contact.id === changedContactId
      );
      if (!editingVendorContact) return;
      const originalContact = cloneDeep<IContactDto>(editingVendorContact);
      dispatch(contactsActions.setShowModal(true));
      setEditingContact(originalContact);
    }
  };

  const handleDelete = () => {
    if (restProps.tableRow) {
      if (vendor?.contacts) {
        const rowIndex = vendor.contacts.findIndex(
          (c: any) => c.id === restProps.tableRow.row.id
        );
        setToDelete({ ...vendor.contacts[rowIndex] });
        setIsDeleting(true);
      }
    }
  };

  const handleMove = () => {
    if (!restProps.tableRow) return;
    const movingContactId = restProps.tableRow.rowId;
    const movingContact = vendor?.contacts.find(
      (contact: IContactDto) => contact.id === movingContactId
    );
    if (!movingContact) return;
    setMovingContact(movingContact);
    dispatch(contactsActions.setShowMoveContactModal(true));
  };

  const handleSync = () => {
    if (!restProps.tableRow) return;
    const contactToSyncId = restProps.tableRow.rowId;
    const contactToSync = vendor?.contacts.find(
      (contact: IContactDto) => contact.id === contactToSyncId
    );
    if (!contactToSync) return;
    setContactToSync(contactToSync);
    setShowSyncContactModal(true);
  };
  return (
    <TableEditColumn.Cell {...restProps}>
      <StyledDropdownButton
        id="contacts-table-move-dropdown-button"
        title={strings.contactManagement.contactList.commandButton.name}
        theme={undefined}
        disabled={
          !permissions.contactWrite || !authorization.canAccessLimitedPrecon
        }
      >
        <MenuItem
          onClick={handleEdit}
          title={strings.contactManagement.contactList.buttonTitle.addEdit}
        >
          <StyledIcon
            name="pencil"
            margin="right"
            style={{ marginRight: "1.1rem" }}
          />
          {strings.contactManagement.contactList.commandButton.edit}
        </MenuItem>
        <MenuItem
          onClick={handleMove}
          title={strings.contactManagement.contactList.buttonTitle.move}
        >
          <StyledIcon
            name="a fa-exchange"
            margin="right"
            style={{ marginRight: "0.9rem" }}
          />
          {strings.contactManagement.contactList.commandButton.move}
        </MenuItem>

        <MenuItem
          onSelect={disableCallbackInSandbox(handleSync)}
          title={strings.contactManagement.contactList.buttonTitle.sync}
          disabled={!permissions.write}
        >
          <StyledIcon name="a fa-refresh" margin="right" />
          {strings.contactManagement.contactList.commandButton.sync}
        </MenuItem>
        <MenuItem
          onClick={handleDelete}
          title={strings.contactManagement.contactList.buttonTitle.delete}
        >
          <StyledIcon name="trash-o" margin="right" className="text-danger" />
          {strings.contactManagement.contactList.commandButton.delete}
        </MenuItem>
      </StyledDropdownButton>
    </TableEditColumn.Cell>
  );
};

interface CustomCellComponentProps extends TableEditColumn.CellProps {
  vendor: IVendorDtoV1Response | undefined;
  setEditingContact: React.Dispatch<React.SetStateAction<IContactDto>>;
  setToDelete: React.Dispatch<React.SetStateAction<IContactDto | undefined>>;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  setMovingContact: React.Dispatch<React.SetStateAction<IContactDto>>;
  setContactToSync: React.Dispatch<React.SetStateAction<IContactDto>>;
  setShowSyncContactModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledIcon = styled(Icon)`
  margin-right: 1.2rem;
`;

const StyledDropdownButton = styled(DropdownButton)`
  background: none !important;
  box-shadow: none !important;
  color: ${props =>
    props.disabled
      ? `grey !important`
      : `${ConcreteColors.blue200} !important`};
  border: 0;
  margin-top: 1px;
  :active,
  :focus,
  :hover {
    box-shadow: none !important;
    color: ${props =>
      props.disabled
        ? `grey !important`
        : `${ConcreteColors.blue200} !important`};
    background-color: white !important;
  }
  :disable {
    color: grey !important;
    background-color: white !important;
  }
`;
export default ContactList;

interface OnHandlerCommitProps extends TableEditColumn.CommandProps {
  tableRow?: TableRow;
}

type ContactRecord = Record<string, Partial<IContactDto>>;
