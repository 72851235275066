import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useOidcContext } from "../../services/oidc-context";
import { selectors, actions } from "../../state";
import FullPageloading from "modules/layout/components/FullPageLoading";

export const OidcLogout = () => {
  const dispatch = useDispatch();
  const { oidcService } = useOidcContext();
  const { isAuthenticated } = useSelector(selectors.getAccount);

  useEffect(() => {
    const handleCallback = async () => {
      try {
        await oidcService.signoutRedirectCallback();
        dispatch(actions.signOut);
      } catch (error) {
        console.error("[OIDC Client] Signout:", error);
      }
    };

    handleCallback();
  }, [dispatch, oidcService]);

  if (!isAuthenticated) {
    oidcService.signinRedirect();
  }

  return <FullPageloading loading={true} showTitle />;
};
