import React from "react";
import styled from "styled-components";
import { ExpandableContentStatus } from "../../models/enums";

interface HeaderProps {
  title: string;
  subtitle?: string;
  onPanelToggleRequested: (event: any) => void;
  alignRight?: boolean;
  activeWrapper?: React.ComponentType;
  panelStatus?: ExpandableContentStatus;
}

export class SideNavigationHeader extends React.PureComponent<HeaderProps> {
  renderCollapsedPanelTitle = (): JSX.Element => {
    return (
      <span
        className="panel-collapsed-title"
        title={`${this.props.title} • ${this.props.subtitle}`}
        onClick={this.props.onPanelToggleRequested}
        data-testid="panel-collapsed-title"
      >
        {this.props.title}
        {this.props.subtitle && (
          <>
            &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
            {this.props.subtitle}
          </>
        )}
      </span>
    );
  };

  isExpanded = () => {
    if (this.props.panelStatus) {
      return (
        this.props.panelStatus === ExpandableContentStatus.expanding ||
        this.props.panelStatus === ExpandableContentStatus.expanded
      );
    }
    return true;
  };

  render() {
    const TitleWrapper = this.props.activeWrapper || DefaultTitleWrapper;

    return (
      <StyledHeader className="sidenav-panel-header">
        {this.isExpanded() && (
          <TitleWrapper>
            <span className="panel-title">{this.props.title}</span>
            {this.props.children}
          </TitleWrapper>
        )}

        {this.renderCollapsedPanelTitle()}
        <div
          className="panel-button-icon panel-display-toggle-button"
          onClick={this.props.onPanelToggleRequested}
        >
          <i
            className={`fa fa-icon ${
              this.props.alignRight ? "fa-chevron-left" : "fa-chevron-right"
            }`}
          />
        </div>
      </StyledHeader>
    );
  }
}

const DefaultTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const BaseHeader = styled.header`
  width: 100%;
  height: auto;
  overflow: visible;
  padding-left: 14px;
  padding-right: 14px
  min-height: 43px;

  .panel-title,
  .panel-desc,
  .panel-collapsed-title {
    transition: 0.2s;
  }

  & .panel-title {
    position: relative;
  }

  & .panel-collapsed-title {
    position: absolute;
  }

  & .panel-title,
  & .panel-collapsed-title {
    float: left;
    /* display: inline-block; */
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #1e1e1e;
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  & .panel-display-toggle-button {
    display: inline-block;
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 5;
    color: #1e1e1e;
    transition: 0.7s;

    & > i {
      font-size: 14px;
      transition: 0.2s;
      height: 14px;
    }
  }

  & .panel-display-toggle-button:hover,
  & .panel-display-toggle-button:active {
    color: #404040;
    & > i {
      color: #0370f5;
    }
  }
`;

const StyledHeader = styled(BaseHeader)`
  margin-bottom: 6px;

  & .panel-title {
    font-size: 14px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .panel-desc {
    font-size: 12px;
    display: inline-block;
    position: relative;
    width: 200px;
    color: ${props => props.theme.colors.neutral[1]};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .panel-collapsed-title {
    width: 248px;
    left: -107px;
    top: 126px;
    text-align: right;
    transform: rotate(-90deg);
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
  }
`;
