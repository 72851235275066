import produce from "immer";
import { keyBy, groupBy } from "lodash-es";
import { createSelector } from "reselect";
import {
  ActionType,
  createAsyncAction,
  createAction,
  getType
} from "typesafe-actions";
import { Calendar } from "api";
import { WithId } from "core";

const STATE_KEY = "calendars";

// Models
export interface State {
  allIds: string[];
  workingCopy: { [key: string]: WithId<Calendar> };
  original: { [key: string]: WithId<Calendar> };
  loading: boolean;
  loaded: boolean;
  sideModalActive: boolean;
}

export interface StateSlice {
  [STATE_KEY]: State;
}

// Actions
export const actions = {
  loadCalendars: createAsyncAction(
    "CALENDARS/LOAD_REQUEST",
    "CALENDARS/LOAD_SUCCESS",
    "CALENDARS/LOAD_FAILURE"
  )<void, WithId<Calendar>[], Error>(),

  saveCalendar: createAsyncAction(
    "CALENDARS/SAVE_REQUEST",
    "CALENDARS/SAVE_SUCCESS",
    "CALENDARS/SAVE_FAILURE"
  )<Calendar, WithId<Calendar>, Calendar>(),

  deleteCalendar: createAsyncAction(
    "CALENDARS/DELETE_REQUEST",
    "CALENDARS/DELETE_SUCCESS",
    "CALENDARS/DELETE_FAILURE"
  )<string, string, string>(),

  updateCalendars: createAction("CALENDARS/UPDATE", resolve => {
    return (calendars: WithId<Calendar>[]) => resolve({ calendars });
  }),

  toggleSideModal: createAction("CALENDARS/TOGGLE_SIDE_MODAL", resolve => {
    return (show?: boolean) => resolve({ show });
  })
};

export type CalendarActions = ActionType<typeof actions>;

const initialState: State = {
  allIds: [],
  workingCopy: {},
  original: {},
  loading: false,
  loaded: false,
  sideModalActive: false
};

// Reducer
export const reducer = (state = initialState, action: CalendarActions) => {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.loadCalendars.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }
      case getType(actions.loadCalendars.success): {
        const newData = keyBy(action.payload, "id");
        draft.workingCopy = newData;
        draft.original = newData;
        draft.allIds = action.payload.map(view => view.id);
        draft.loading = false;
        draft.loaded = true;
        break;
      }
      case getType(actions.saveCalendar.request): {
        break;
      }
      case getType(actions.saveCalendar.success): {
        draft.original[action.payload.id] = action.payload;
        draft.workingCopy[action.payload.id] = action.payload;
        const currentIndex = draft.allIds.indexOf(action.payload.id);
        if (currentIndex === -1) {
          draft.allIds.push(action.payload.id);
        }
        break;
      }
      case getType(actions.saveCalendar.failure): {
        if (action.payload.id) {
          draft.workingCopy[action.payload.id] =
            draft.original[action.payload.id];
        }
        break;
      }
      case getType(actions.deleteCalendar.success): {
        delete draft.workingCopy[action.payload];
        delete draft.original[action.payload];
        draft.allIds = Object.keys(draft.original).map(
          key => draft.original[key].id
        );
        break;
      }
      case getType(actions.deleteCalendar.failure): {
        draft.workingCopy[action.payload] = draft.original[action.payload];
        break;
      }
      case getType(actions.updateCalendars): {
        const { calendars } = action.payload;
        calendars.forEach(calendar => {
          const currentIndex = draft.allIds.indexOf(calendar.id);
          if (calendar.deleted) {
            if (currentIndex > -1) {
              draft.allIds.splice(currentIndex, 1);
            }
            if (calendar.id in draft.workingCopy) {
              delete draft.workingCopy[calendar.id];
            }
            if (calendar.id in draft.original) {
              delete draft.original[calendar.id];
            }
          } else {
            draft.workingCopy[calendar.id] = calendar;
            draft.original[calendar.id] = calendar;
            if (currentIndex === -1) {
              draft.allIds.push(calendar.id);
            }
          }
        });
        break;
      }

      case getType(actions.toggleSideModal): {
        const { show } = action.payload;
        if (show !== undefined) {
          draft.sideModalActive = show;
        } else {
          draft.sideModalActive = !draft.sideModalActive;
        }
      }
    }
  });
};

// Selectors
const getAllIds = ({ calendars }: StateSlice) => calendars.allIds;
const getLoaded = ({ calendars }: StateSlice) => calendars.loaded;
const getWorkingCopy = ({ calendars }: StateSlice) => calendars.workingCopy;
const getCalendarPanelActive = ({ calendars }: StateSlice) =>
  calendars.sideModalActive;

const getAllCalendars = createSelector(
  [getAllIds, getWorkingCopy],
  (ids, calendarLookup) => ids.map(id => calendarLookup[id])
);

const getCalendarsByViewId = createSelector(
  [getAllIds, getWorkingCopy],
  (ids, calendarLookup) => {
    const viewLookup: Record<
      string,
      Record<"iCal" | "nylas", WithId<Calendar> | undefined>
    > = {};
    ids.forEach(id => {
      const calendar = calendarLookup[id];
      const token = calendar.token;
      const isReadOnlyCal = token && token.length > 0;
      const isNylasCal = calendar.isNylas;
      const viewId = calendar.viewId;
      if (viewId) {
        if (!viewLookup[viewId]) {
          viewLookup[viewId] = {
            iCal: undefined,
            nylas: undefined
          };
        }
        if (isReadOnlyCal) viewLookup[viewId].iCal = calendar;
        if (isNylasCal) viewLookup[viewId].nylas = calendar;
      }
    });
    return viewLookup;
  }
);
const getCalendarsByAccountId = createSelector([getAllCalendars], calendars => {
  return groupBy(calendars, c => c.accountId);
});

export const selectors = {
  getAllIds,
  getLoaded,
  getWorkingCopy,
  getCalendarsByViewId,
  getCalendarPanelActive,
  getCalendarsByAccountId
};
