import React, { ReactNode, Fragment, CSSProperties } from "react";
import styled from "styled-components";
import { ConcreteColors, OverlayTrigger, Tooltip } from "hcss-components";
import { Link } from "react-router-dom";
import { FullProjectTrackingOnlyTooltip } from "modules/schemas/components/full-project-tracking-only-tooltip";

interface ItemProps {
  text: ReactNode;
  subText?: string;
  before?: ReactNode;
  after?: ReactNode;
  isSelected?: boolean;
  to?: string;
  onClick?: () => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  "data-testid"?: string;
  isDisabled?: boolean;
  isLimitedCompany?: boolean;
  isTempDisabled?: boolean;
  beforeWrapperStyle?: CSSProperties;
}
export const Item = (props: ItemProps) => {
  const {
    text,
    subText,
    before,
    after,
    isSelected = false,
    to,
    onClick,
    onMouseEnter,
    onMouseLeave,
    isDisabled,
    isLimitedCompany,
    isTempDisabled,
    beforeWrapperStyle
  } = props;
  const renderInternal = () => {
    return (
      <Fragment>
        {before && (
          <BeforeWrapper style={beforeWrapperStyle ?? beforeWrapperStyle}>
            {before}
          </BeforeWrapper>
        )}

        <ContentWrapper>
          <TextWrapper>{text}</TextWrapper>
          {subText && <SubTextWrapper>{subText}</SubTextWrapper>}
        </ContentWrapper>

        {after && <AfterWrapper>{after}</AfterWrapper>}
      </Fragment>
    );
  };

  if (isTempDisabled) {
    return (
      <OverlayTrigger
        rootClose
        trigger={["hover", "focus"]}
        placement="right"
        overlay={
          <Tooltip id="temp-disable" style={{ zIndex: 9999999 }}>
            Temporarily disabled due to maintenance. Please try again later.
          </Tooltip>
        }
      >
        <ItemDisabledLink>{renderInternal()}</ItemDisabledLink>
      </OverlayTrigger>
    );
  }

  if (onClick || !to) {
    return (
      <InteractiveStateManager
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-testid={props["data-testid"]}
      >
        <ItemBaseButton
          data-selected={isSelected}
          onClick={onClick}
          disabled={isDisabled}
        >
          {renderInternal()}
        </ItemBaseButton>
      </InteractiveStateManager>
    );
  }
  if (isLimitedCompany) {
    return (
      <OverlayTrigger
        rootClose
        trigger={["hover", "focus"]}
        placement="right"
        overlay={FullProjectTrackingOnlyTooltip}
      >
        <ItemDisabledLink>{renderInternal()}</ItemDisabledLink>
      </OverlayTrigger>
    );
  }
  return (
    <InteractiveStateManager
      onMouseEnter={isDisabled ? () => {} : onMouseEnter}
      onMouseLeave={isDisabled ? () => {} : onMouseLeave}
      data-testid={props["data-testid"]}
    >
      {!isDisabled ? (
        <ItemBaseLink data-selected={isSelected} to={to}>
          {renderInternal()}
        </ItemBaseLink>
      ) : (
        <ItemDisabledLink>{renderInternal()}</ItemDisabledLink>
      )}
    </InteractiveStateManager>
  );
};

const InteractiveStateManager = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const ItemBaseButton = styled.button`
  align-items: center;
  border: none;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  outline: none;
  text-align: left;
  text-decoration: none;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  background-color: transparent;

  &:hover {
    background-color: ${ConcreteColors.gray100};
  }

  &[data-selected="true"] {
    color: ${ConcreteColors.blue200};
    background-color: #e2effa;

    &:focus,
    &:hover {
      color: ${ConcreteColors.blue200};
      background-color: #e2effa;
    }
  }
`;

const ItemBaseLink = styled(Link)`
  align-items: center;
  border: none;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  outline: none;
  text-align: left;
  text-decoration: none;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  color: inherit;
  background-color: transparent;

  &:hover {
    color: inherit;
    background-color: ${ConcreteColors.gray100};
    text-decoration: none;
  }

  &[data-selected="true"] {
    color: ${ConcreteColors.blue200};
    background-color: #e2effa;

    &:focus,
    &:hover {
      color: ${ConcreteColors.blue200};
      background-color: #e2effa;
    }
  }
`;

const ItemDisabledLink = styled.div`
  align-items: center;
  border: none;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: not-allowed;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  outline: none;
  text-align: left;
  text-decoration: none;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  color: ${ConcreteColors.gray500} !important;
  background-color: ${ConcreteColors.gray300} !important;
`;

const BeforeWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 16px;
`;

const AfterWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 8px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
`;

const TextWrapper = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: calc(16 / 14);
`;

const SubTextWrapper = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: calc(14 / 12);
`;
