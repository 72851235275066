import React from "react";
import { ConcreteColors } from "hcss-components";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { useSessionStorage } from "hcss-hooks";
import { isSupportedBrowser } from "core/util/is-supported-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { strings } from "localization";

export const UnsupportedBrowserBanner = () => {
  const [showBanner, setShowBanner] = useSessionStorage(
    `browserWarning`,
    !isSupportedBrowser()
  );

  return (
    <CSSTransition
      in={showBanner}
      timeout={300}
      classNames="alert"
      unmountOnExit
    >
      <Banner role="alert">
        <MainInfo>
          <WarningIconContainer>
            <FontAwesomeIcon
              style={warningIconStyle}
              icon={["fad", "exclamation-triangle"]}
              size="lg"
            />
          </WarningIconContainer>
          <Title className="">{strings.unsupportedBrowser.bannerTitle}</Title>
          <CloseIconContainer
            onClick={() => setShowBanner(false)}
            aria-expanded="false"
            type="button"
          >
            <FontAwesomeIcon
              style={closeIconStyle}
              icon={["fal", "times"]}
              size="lg"
            />
          </CloseIconContainer>
        </MainInfo>
        <DetailInfo>
          <div className="wrapper" aria-hidden="false">
            <>
              <div>
                {strings.unsupportedBrowser.bannerDetail}{" "}
                <a
                  href="https://help.hcss.com/s/article/What-Browsers-Are-Recommended-for-HCSS-Web-Apps"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {strings.unsupportedBrowser.here}.
                </a>
              </div>

              <div className="action-container ">
                <ActionButtonWrapper>
                  <button onClick={() => setShowBanner(false)} type="button">
                    <span>
                      <span>{strings.unsupportedBrowser.dismiss}</span>
                    </span>
                  </button>
                </ActionButtonWrapper>
              </div>
            </>
          </div>
        </DetailInfo>
      </Banner>
    </CSSTransition>
  );
};

const warningIconStyle: object = {
  "--fa-primary-color": "#2c4369"
};

const closeIconStyle: object = {
  "--fa-primary-color": "#2c4369"
};

const Banner = styled.div`
  background-color: #f8d7da;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: 0 0 1px rgba(9, 30, 66, 0.31),
    0 20px 32px -8px rgba(9, 30, 66, 0.25);
  color: #2c4369;
  padding: 16px;
  transition: background-color 200ms;
  width: 100%;
  z-index: 600;
  margin-bottom: 50px;
  min-height: 120px;
`;

const MainInfo = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

const WarningIconContainer = styled.div`
  flex: 0 0 auto;
  width: 40px;
  height: 24px;
`;

const Title = styled.span`
  font-weight: 600;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CloseIconContainer = styled.button`
  appearance: none;
  background: none;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  flex: 0 0 auto;
  margin-left: 8px;
  padding: 2px 3px;
  white-space: nowrap;

  :focus {
    outline: none;
    box-shadow: 0 0 0 2px #4c9aff;
  }
`;

const DetailInfo = styled.div`
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
  padding: 0 0 0 40px;

  .wrapper {
    max-height: 150px;
    opacity: 1;
    overflow: visible;
    transition: max-height 0.3s, opacity 0.3s;

    .blurb {
      /* color: #42526e; */
      word-wrap: break-word;
      margin-bottom: 8px;
    }

    .action-container {
      display: flex;
      flex-wrap: wrap;
      padding-top: 8px;
      transform: translateX(-4px);
    }
  }
`;

const ActionButtonWrapper = styled.div`
  margin-right: 10px;

  button {
    font-weight: 500;
    padding: 0 0px !important;
    align-items: baseline;
    border-width: 0;
    box-sizing: border-box;
    display: inline-flex;
    max-width: 100%;
    outline: none !important;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background: none;
    border-radius: 3px;
    box-shadow: 0 0 0 2px inherit;
    color: ${ConcreteColors.blue300};
    cursor: pointer;
    padding: 0 8px;
    transition: background 0.1s ease-out,
      box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);
    transition-duration: 0.1s, 0.15s;
    vertical-align: middle;
    width: auto;

    :hover {
      text-decoration: underline;
    }

    span {
      align-self: center;
      display: inline-flex;
      flex-wrap: nowrap;
      max-width: 100%;
      position: relative;

      span {
        align-items: center;
        align-self: center;
        flex: 1 1 auto;
        margin: 0 4px;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: opacity 0.3s;
        opacity: 1;
      }
    }
  }
`;
