import { HubConnection } from "@microsoft/signalr";
import { END, eventChannel } from "redux-saga";
import { call, delay, put, select, take, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import * as estimates from "../../modules/estimates";
import * as projects from "../../modules/projects";
import * as schemas from "../../modules/schemas";
import * as views from "../views";
import * as calendars from "../calendars";
import * as subscriptions from "../email-subscriptions";
import { createConnection } from "./init";
import { actions, StateSlice } from "./state";
import {
  getSelectedBusinessUnitIdSaga,
  getTokenSaga
} from "api/api-saga-factory";

function createSocketChannel(connection: HubConnection) {
  // `eventChannel` takes a subscriber function
  // the subscriber function takes an `emit` argument to put messages onto the channel
  return eventChannel(emit => {
    const projectHandler = (changes: unknown[]) => {
      // puts event payload into the channel
      // this allows a Saga to take this payload from the returned channel
      emit({ type: "PROJECTS", value: changes });
    };

    const schemaHandler = (changes: unknown[]) => {
      emit({ type: "SCHEMAS", value: changes });
    };

    const viewHandler = (changes: unknown[]) => {
      emit({ type: "VIEWS", value: changes });
    };

    const calendarHandler = (changes: unknown[]) => {
      emit({ type: "CALENDARS", value: changes });
    };

    const estimateHandler = (changes: string[]) => {
      const payload = changes.map(change => JSON.parse(change));
      emit({ type: "ESTIMATES", value: payload });
    };

    const subscriptionHandler = (message: any) => {
      emit({ type: "SUBSCRIPTIONS", value: message });
    };

    // setup the subscription
    connection.on("UpdateProjects", projectHandler);
    connection.on("UpdateEstimates", estimateHandler);
    connection.on("UpdateSchemas", schemaHandler);
    connection.on("UpdateViews", viewHandler);
    connection.on("UpdateCalendars", calendarHandler);
    connection.on("UpdateSubscription", subscriptionHandler);
    connection
      .start()
      .then(() => {
        emit({ type: "SIGNALR/CONNECT_SUCCESS" });
      })
      .catch(err => {
        console.error("signalrerror", err);
        emit({ type: "SIGNALR/CONNECT_FAILURE" });
        emit(END);
      });

    connection.onclose(() => {
      emit({ type: "SIGNALR/RECONNECT" });
    });

    // the subscriber must return an unsubscribe function
    // this will be invoked when the saga calls `channel.close` method
    const unsubscribe = () => {
      connection.stop();
    };

    return unsubscribe;
  });
}
function* socketSaga() {
  const token = yield call(getTokenSaga);
  const selectedBusinessUnitId = yield call(getSelectedBusinessUnitIdSaga);
  const socket = yield call(createConnection, token, selectedBusinessUnitId);
  const socketChannel = yield call(createSocketChannel, socket);

  while (true) {
    const payload = yield take(socketChannel);
    const { type, value } = payload;
    switch (type) {
      case "PROJECTS": {
        yield put(projects.actions.updateProjects(value));
        break;
      }
      case "ESTIMATES": {
        yield put(estimates.actions.updateEstimates.request(value));
        break;
      }
      case "SCHEMAS": {
        yield put(schemas.actions.updateSchemas(value));
        break;
      }
      case "VIEWS": {
        yield put(views.actions.updateViews(value));
        break;
      }
      case "CALENDARS": {
        yield put(calendars.actions.updateCalendars(value));
        break;
      }
      case "SUBSCRIPTIONS": {
        yield put(
          subscriptions.actions.updateStatus(value.subscriptionId, value.status)
        );
        break;
      }
      case "SIGNALR/RECONNECT": {
        yield put(actions.startSignalRConnection.failure());
        break;
      }
      case "SIGNALR/CONNECT_FAILURE": {
        yield put(actions.startSignalRConnection.failure());
        break;
      }
      case "SIGNALR/CONNECT_SUCCESS": {
        yield put(actions.startSignalRConnection.success());
        break;
      }
    }
  }
}
function* restartSocketSaga() {
  const reconnectCount = yield select(
    ({ signalR }: StateSlice) => signalR.reconnectCount
  );
  yield delay(1000 * (reconnectCount * 2));
  yield call(socketSaga);
}

export const sagas = [
  takeLatest(getType(actions.startSignalRConnection.request), socketSaga),
  takeLatest(getType(actions.startSignalRConnection.failure), restartSocketSaga)
];
